<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div">
    <form @submit.prevent="handleSubmit(submit)">
      <ValidationProvider
        v-if="mode === 'email'"
        v-slot="{ errors }"
        name="E-mail"
        :rules="{ required: true, email: true }"
        slim
      >
        <v-text-field v-model="email" label="กรอก e-mail*" :error-messages="errors" class="mb-2" />
      </ValidationProvider>
      <v-radio-group v-else v-model="type" hide-details>
        <v-radio value="11" label="LINE OA Summit Capital" />
        <v-radio value="10" label="E-mail" />
        <ValidationProvider
          v-if="type === '10'"
          v-slot="{ errors }"
          name="E-mail"
          :rules="{ required: type === '10', email: true }"
          slim
        >
          <v-text-field
            v-model="email"
            label="กรอก e-mail*"
            :error-messages="errors"
            class="mb-2"
          />
        </ValidationProvider>
        <v-radio value="12" label="ไปรษณีย์ ที่อยู่จัดส่งเอกสาร" />
        <template v-if="type === '12' && !hideEditAddress">
          <template v-if="disableEditAddress">
            <v-card v-if="address" class="mt-2">
              <v-card-text> {{ address }} </v-card-text>
            </v-card>
          </template>
          <template v-else>
            <v-textarea v-model="address" readonly no-resize auto-grow hide-details :rows="1" />
            <p class="body-2 my-4 text-center" :style="{ fontFamily: 'inherit !important' }">
              หากต้องการแก้ไขที่อยู่ในการจัดส่งเอกสาร<br />กรุณาคลิก
              <v-btn small text color="primary" @click="$emit('edit:address')">แก้ไขที่อยู่</v-btn>
            </p>
            <p class="body-2 my-4 red--text" :style="{ fontFamily: 'inherit !important' }">
              หมายเหตุ: การจัดส่งไปรษณีย์ มีระยะเวลาในการจัดส่ง 10-15 วันทำการ
            </p>
          </template>
        </template>
      </v-radio-group>

      <div class="mt-8">
        <v-btn
          :loading="isLoading"
          block
          dark
          :disabled="!type"
          class="my-4 primary-btn mx-auto"
          type="submit"
          >{{ submitButtonText }}</v-btn
        >
        <v-btn
          v-if="$listeners.cancel"
          block
          outlined
          color="grey"
          :disabled="isLoading"
          class="my-4 mx-auto"
          @click="$emit('cancel')"
          >ยกเลิก</v-btn
        >
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import axios from 'axios'
import { sendErrorLog } from '@/utils/sendErrorLog'

export default {
  props: {
    mode: String,
    token: String,
    transactionId: String,
    lineId: String,
    requestNumber: String,
    contractId: String,
    idCardNumber: String,
    mobileNumber: String,
    onlyAction: Boolean,
    disableEditAddress: Boolean,
    hideEditAddress: Boolean
  },
  data() {
    return {
      type: this.mode === 'email' ? '10' : undefined,
      email: '',
      oldEmail: '',
      address: '',
      isLoading: false
    }
  },
  computed: {
    isSameEmail() {
      return this.email === this.oldEmail
    },
    isEmptyEmail() {
      return !this.email
    },
    submitButtonText() {
      if (this.type === '10' && (this.isEmptyEmail || !this.isSameEmail)) return 'ถัดไป'
      return 'ยืนยัน'
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showErrorAlert({ traceId }) {
      this.$swal.fire({
        icon: 'error',
        html: `กรุณาติดต่อเจ้าหน้าที่<br /><small>(Trace ID: ${traceId})</small>`
      })
    },
    async fetchData() {
      const requestno = this.requestNumber ? this.requestNumber : '0'
      const contractid = this.contractId ? this.contractId : '0'
      const payload = {
        token: this.token,
        transid: this.transactionId,
        lineid: this.lineId,
        requestno,
        contractid,
        idcard: this.idCardNumber,
        doctype: '2'
      }

      try {
        const { statement_type, emailAddress, customer_address } = await axios
          .post(this.$store.state.summitServer + 'API/Statement/GetChannelStatement', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (message_code !== '200') throw new Error(message_name)
            return response.data
          })

        if (this.mode !== 'email') {
          this.type = statement_type
        }
        this.email = emailAddress
        this.oldEmail = emailAddress
        this.address = customer_address
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'GetChannelStatement',
          error,
          payload
        })
        this.showErrorAlert({ traceId })
      }
    },
    async confirmStatementChannel() {
      const payload = {
        token: this.token,
        transid: this.transactionId,
        lineid: this.lineId,
        idcard: this.idCardNumber,
        contractid: this.contractId,
        mobileno: this.mobileNumber,
        statementtype: this.type,
        emailaddress: this.type === '10' ? this.email : '',
        doctype: '2',
        lineidwelcomekit: '',
        emailaddresswelcomekit: '',
        existflag: '1'
      }

      try {
        return await axios
          .post(this.$store.state.summitServer + 'API/Statement/ConfirmChannelStatement', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (message_code !== '200') throw new Error(message_name)
            return true
          })
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'ConfirmChannelStatement',
          error,
          payload
        })
        this.showErrorAlert({ traceId })
      }
    },
    async submit() {
      try {
        if (this.submitButtonText === 'ถัดไป') {
          this.$emit('verify:email', this.email, '10')
          return
        }

        this.isLoading = true
        if (this.onlyAction) {
          this.$emit('complete', this.type)
        } else {
          await this.confirmStatementChannel()
          this.$emit('complete')
        }
      } catch (error) {
        this.showErrorAlert()
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
