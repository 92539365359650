export default [
    {
        "district_id": "1",
        "province_id": "1",
        "district_name": "พระนคร"
    },
    {
        "district_id": "2",
        "province_id": "1",
        "district_name": "ดุสิต"
    },
    {
        "district_id": "3",
        "province_id": "1",
        "district_name": "หนองจอก"
    },
    {
        "district_id": "4",
        "province_id": "1",
        "district_name": "บางรัก"
    },
    {
        "district_id": "5",
        "province_id": "1",
        "district_name": "บางเขน"
    },
    {
        "district_id": "6",
        "province_id": "1",
        "district_name": "บางกะปิ"
    },
    {
        "district_id": "7",
        "province_id": "1",
        "district_name": "ปทุมวัน"
    },
    {
        "district_id": "8",
        "province_id": "1",
        "district_name": "ป้อมปราบศัตรูพ่าย"
    },
    {
        "district_id": "9",
        "province_id": "1",
        "district_name": "พระโขนง"
    },
    {
        "district_id": "10",
        "province_id": "1",
        "district_name": "มีนบุรี"
    },
    {
        "district_id": "11",
        "province_id": "1",
        "district_name": "ลาดกระบัง"
    },
    {
        "district_id": "12",
        "province_id": "1",
        "district_name": "ยานนาวา"
    },
    {
        "district_id": "13",
        "province_id": "1",
        "district_name": "สัมพันธวงศ์"
    },
    {
        "district_id": "14",
        "province_id": "1",
        "district_name": "พญาไท"
    },
    {
        "district_id": "15",
        "province_id": "1",
        "district_name": "ธนบุรี"
    },
    {
        "district_id": "16",
        "province_id": "1",
        "district_name": "บางกอกใหญ่"
    },
    {
        "district_id": "17",
        "province_id": "1",
        "district_name": "ห้วยขวาง"
    },
    {
        "district_id": "18",
        "province_id": "1",
        "district_name": "คลองสาน"
    },
    {
        "district_id": "19",
        "province_id": "1",
        "district_name": "ตลิ่งชัน"
    },
    {
        "district_id": "20",
        "province_id": "1",
        "district_name": "บางกอกน้อย"
    },
    {
        "district_id": "21",
        "province_id": "1",
        "district_name": "บางขุนเทียน"
    },
    {
        "district_id": "22",
        "province_id": "1",
        "district_name": "ภาษีเจริญ"
    },
    {
        "district_id": "23",
        "province_id": "1",
        "district_name": "หนองแขม"
    },
    {
        "district_id": "24",
        "province_id": "1",
        "district_name": "ราษฎร์บูรณะ"
    },
    {
        "district_id": "25",
        "province_id": "1",
        "district_name": "บางพลัด"
    },
    {
        "district_id": "26",
        "province_id": "1",
        "district_name": "ดินแดง"
    },
    {
        "district_id": "27",
        "province_id": "1",
        "district_name": "บึงกุ่ม"
    },
    {
        "district_id": "28",
        "province_id": "1",
        "district_name": "สาทร"
    },
    {
        "district_id": "29",
        "province_id": "1",
        "district_name": "บางซื่อ"
    },
    {
        "district_id": "30",
        "province_id": "1",
        "district_name": "จตุจักร"
    },
    {
        "district_id": "31",
        "province_id": "1",
        "district_name": "บางคอแหลม"
    },
    {
        "district_id": "32",
        "province_id": "1",
        "district_name": "ประเวศ"
    },
    {
        "district_id": "33",
        "province_id": "1",
        "district_name": "คลองเตย"
    },
    {
        "district_id": "34",
        "province_id": "1",
        "district_name": "สวนหลวง"
    },
    {
        "district_id": "35",
        "province_id": "1",
        "district_name": "จอมทอง"
    },
    {
        "district_id": "36",
        "province_id": "1",
        "district_name": "ดอนเมือง"
    },
    {
        "district_id": "37",
        "province_id": "1",
        "district_name": "ราชเทวี"
    },
    {
        "district_id": "38",
        "province_id": "1",
        "district_name": "ลาดพร้าว"
    },
    {
        "district_id": "39",
        "province_id": "1",
        "district_name": "วัฒนา"
    },
    {
        "district_id": "40",
        "province_id": "1",
        "district_name": "บางแค"
    },
    {
        "district_id": "41",
        "province_id": "1",
        "district_name": "หลักสี่"
    },
    {
        "district_id": "42",
        "province_id": "1",
        "district_name": "สายไหม"
    },
    {
        "district_id": "43",
        "province_id": "1",
        "district_name": "คันนายาว"
    },
    {
        "district_id": "44",
        "province_id": "1",
        "district_name": "สะพานสูง"
    },
    {
        "district_id": "45",
        "province_id": "1",
        "district_name": "วังทองหลาง"
    },
    {
        "district_id": "46",
        "province_id": "1",
        "district_name": "คลองสามวา"
    },
    {
        "district_id": "47",
        "province_id": "1",
        "district_name": "บางนา"
    },
    {
        "district_id": "48",
        "province_id": "1",
        "district_name": "ทวีวัฒนา"
    },
    {
        "district_id": "49",
        "province_id": "1",
        "district_name": "ทุ่งครุ"
    },
    {
        "district_id": "50",
        "province_id": "1",
        "district_name": "บางบอน"
    },
    {
        "district_id": "51",
        "province_id": "2",
        "district_name": "เมืองสมุทรปราการ"
    },
    {
        "district_id": "52",
        "province_id": "2",
        "district_name": "บางบ่อ"
    },
    {
        "district_id": "53",
        "province_id": "2",
        "district_name": "บางพลี"
    },
    {
        "district_id": "54",
        "province_id": "2",
        "district_name": "พระประแดง"
    },
    {
        "district_id": "55",
        "province_id": "2",
        "district_name": "พระสมุทรเจดีย์"
    },
    {
        "district_id": "56",
        "province_id": "2",
        "district_name": "บางเสาธง"
    },
    {
        "district_id": "57",
        "province_id": "3",
        "district_name": "เมืองนนทบุรี"
    },
    {
        "district_id": "58",
        "province_id": "3",
        "district_name": "บางกรวย"
    },
    {
        "district_id": "59",
        "province_id": "3",
        "district_name": "บางใหญ่"
    },
    {
        "district_id": "60",
        "province_id": "3",
        "district_name": "บางบัวทอง"
    },
    {
        "district_id": "61",
        "province_id": "3",
        "district_name": "ไทรน้อย"
    },
    {
        "district_id": "62",
        "province_id": "3",
        "district_name": "ปากเกร็ด"
    },
    {
        "district_id": "63",
        "province_id": "4",
        "district_name": "เมืองปทุมธานี"
    },
    {
        "district_id": "64",
        "province_id": "4",
        "district_name": "คลองหลวง"
    },
    {
        "district_id": "65",
        "province_id": "4",
        "district_name": "ธัญบุรี"
    },
    {
        "district_id": "66",
        "province_id": "4",
        "district_name": "หนองเสือ"
    },
    {
        "district_id": "67",
        "province_id": "4",
        "district_name": "ลาดหลุมแก้ว"
    },
    {
        "district_id": "68",
        "province_id": "4",
        "district_name": "ลำลูกกา"
    },
    {
        "district_id": "69",
        "province_id": "4",
        "district_name": "สามโคก"
    },
    {
        "district_id": "70",
        "province_id": "5",
        "district_name": "พระนครศรีอยุธยา"
    },
    {
        "district_id": "71",
        "province_id": "5",
        "district_name": "ท่าเรือ"
    },
    {
        "district_id": "72",
        "province_id": "5",
        "district_name": "นครหลวง"
    },
    {
        "district_id": "73",
        "province_id": "5",
        "district_name": "บางไทร"
    },
    {
        "district_id": "74",
        "province_id": "5",
        "district_name": "บางบาล"
    },
    {
        "district_id": "75",
        "province_id": "5",
        "district_name": "บางปะอิน"
    },
    {
        "district_id": "76",
        "province_id": "5",
        "district_name": "บางปะหัน"
    },
    {
        "district_id": "77",
        "province_id": "5",
        "district_name": "ผักไห่"
    },
    {
        "district_id": "78",
        "province_id": "5",
        "district_name": "ภาชี"
    },
    {
        "district_id": "79",
        "province_id": "5",
        "district_name": "ลาดบัวหลวง"
    },
    {
        "district_id": "80",
        "province_id": "5",
        "district_name": "วังน้อย"
    },
    {
        "district_id": "81",
        "province_id": "5",
        "district_name": "เสนา"
    },
    {
        "district_id": "82",
        "province_id": "5",
        "district_name": "บางซ้าย"
    },
    {
        "district_id": "83",
        "province_id": "5",
        "district_name": "อุทัย"
    },
    {
        "district_id": "84",
        "province_id": "5",
        "district_name": "มหาราช"
    },
    {
        "district_id": "85",
        "province_id": "5",
        "district_name": "บ้านแพรก"
    },
    {
        "district_id": "86",
        "province_id": "6",
        "district_name": "เมืองอ่างทอง"
    },
    {
        "district_id": "87",
        "province_id": "6",
        "district_name": "ไชโย"
    },
    {
        "district_id": "88",
        "province_id": "6",
        "district_name": "ป่าโมก"
    },
    {
        "district_id": "89",
        "province_id": "6",
        "district_name": "โพธิ์ทอง"
    },
    {
        "district_id": "90",
        "province_id": "6",
        "district_name": "แสวงหา"
    },
    {
        "district_id": "91",
        "province_id": "6",
        "district_name": "วิเศษชัยชาญ"
    },
    {
        "district_id": "92",
        "province_id": "6",
        "district_name": "สามโก้"
    },
    {
        "district_id": "93",
        "province_id": "7",
        "district_name": "เมืองลพบุรี"
    },
    {
        "district_id": "94",
        "province_id": "7",
        "district_name": "พัฒนานิคม"
    },
    {
        "district_id": "95",
        "province_id": "7",
        "district_name": "โคกสำโรง"
    },
    {
        "district_id": "96",
        "province_id": "7",
        "district_name": "ชัยบาดาล"
    },
    {
        "district_id": "97",
        "province_id": "7",
        "district_name": "ท่าวุ้ง"
    },
    {
        "district_id": "98",
        "province_id": "7",
        "district_name": "บ้านหมี่"
    },
    {
        "district_id": "99",
        "province_id": "7",
        "district_name": "ท่าหลวง"
    },
    {
        "district_id": "100",
        "province_id": "7",
        "district_name": "สระโบสถ์"
    },
    {
        "district_id": "101",
        "province_id": "7",
        "district_name": "โคกเจริญ"
    },
    {
        "district_id": "102",
        "province_id": "7",
        "district_name": "ลำสนธิ"
    },
    {
        "district_id": "103",
        "province_id": "7",
        "district_name": "หนองม่วง"
    },
    {
        "district_id": "104",
        "province_id": "8",
        "district_name": "เมืองสิงห์บุรี"
    },
    {
        "district_id": "105",
        "province_id": "8",
        "district_name": "บางระจัน"
    },
    {
        "district_id": "106",
        "province_id": "8",
        "district_name": "ค่ายบางระจัน"
    },
    {
        "district_id": "107",
        "province_id": "8",
        "district_name": "พรหมบุรี"
    },
    {
        "district_id": "108",
        "province_id": "8",
        "district_name": "ท่าช้าง"
    },
    {
        "district_id": "109",
        "province_id": "8",
        "district_name": "อินทร์บุรี"
    },
    {
        "district_id": "110",
        "province_id": "9",
        "district_name": "เมืองชัยนาท"
    },
    {
        "district_id": "111",
        "province_id": "9",
        "district_name": "มโนรมย์"
    },
    {
        "district_id": "112",
        "province_id": "9",
        "district_name": "วัดสิงห์"
    },
    {
        "district_id": "113",
        "province_id": "9",
        "district_name": "สรรพยา"
    },
    {
        "district_id": "114",
        "province_id": "9",
        "district_name": "สรรคบุรี"
    },
    {
        "district_id": "115",
        "province_id": "9",
        "district_name": "หันคา"
    },
    {
        "district_id": "116",
        "province_id": "9",
        "district_name": "หนองมะโมง"
    },
    {
        "district_id": "117",
        "province_id": "9",
        "district_name": "เนินขาม"
    },
    {
        "district_id": "118",
        "province_id": "10",
        "district_name": "เมืองสระบุรี"
    },
    {
        "district_id": "119",
        "province_id": "10",
        "district_name": "แก่งคอย"
    },
    {
        "district_id": "120",
        "province_id": "10",
        "district_name": "หนองแค"
    },
    {
        "district_id": "121",
        "province_id": "10",
        "district_name": "วิหารแดง"
    },
    {
        "district_id": "122",
        "province_id": "10",
        "district_name": "หนองแซง"
    },
    {
        "district_id": "123",
        "province_id": "10",
        "district_name": "บ้านหมอ"
    },
    {
        "district_id": "124",
        "province_id": "10",
        "district_name": "ดอนพุด"
    },
    {
        "district_id": "125",
        "province_id": "10",
        "district_name": "หนองโดน"
    },
    {
        "district_id": "126",
        "province_id": "10",
        "district_name": "พระพุทธบาท"
    },
    {
        "district_id": "127",
        "province_id": "10",
        "district_name": "เสาไห้"
    },
    {
        "district_id": "128",
        "province_id": "10",
        "district_name": "มวกเหล็ก"
    },
    {
        "district_id": "129",
        "province_id": "10",
        "district_name": "วังม่วง"
    },
    {
        "district_id": "130",
        "province_id": "10",
        "district_name": "เฉลิมพระเกียรติ"
    },
    {
        "district_id": "131",
        "province_id": "11",
        "district_name": "เมืองชลบุรี"
    },
    {
        "district_id": "132",
        "province_id": "11",
        "district_name": "บ้านบึง"
    },
    {
        "district_id": "133",
        "province_id": "11",
        "district_name": "หนองใหญ่"
    },
    {
        "district_id": "134",
        "province_id": "11",
        "district_name": "บางละมุง"
    },
    {
        "district_id": "135",
        "province_id": "11",
        "district_name": "พานทอง"
    },
    {
        "district_id": "136",
        "province_id": "11",
        "district_name": "พนัสนิคม"
    },
    {
        "district_id": "137",
        "province_id": "11",
        "district_name": "ศรีราชา"
    },
    {
        "district_id": "138",
        "province_id": "11",
        "district_name": "เกาะสีชัง"
    },
    {
        "district_id": "139",
        "province_id": "11",
        "district_name": "สัตหีบ"
    },
    {
        "district_id": "140",
        "province_id": "11",
        "district_name": "บ่อทอง"
    },
    {
        "district_id": "141",
        "province_id": "11",
        "district_name": "เกาะจันทร์"
    },
    {
        "district_id": "142",
        "province_id": "12",
        "district_name": "เมืองระยอง"
    },
    {
        "district_id": "143",
        "province_id": "12",
        "district_name": "บ้านฉาง"
    },
    {
        "district_id": "144",
        "province_id": "12",
        "district_name": "แกลง"
    },
    {
        "district_id": "145",
        "province_id": "12",
        "district_name": "วังจันทร์"
    },
    {
        "district_id": "146",
        "province_id": "12",
        "district_name": "บ้านค่าย"
    },
    {
        "district_id": "147",
        "province_id": "12",
        "district_name": "ปลวกแดง"
    },
    {
        "district_id": "148",
        "province_id": "12",
        "district_name": "เขาชะเมา"
    },
    {
        "district_id": "149",
        "province_id": "12",
        "district_name": "นิคมพัฒนา"
    },
    {
        "district_id": "150",
        "province_id": "13",
        "district_name": "เมืองจันทบุรี"
    },
    {
        "district_id": "151",
        "province_id": "13",
        "district_name": "ขลุง"
    },
    {
        "district_id": "152",
        "province_id": "13",
        "district_name": "ท่าใหม่"
    },
    {
        "district_id": "153",
        "province_id": "13",
        "district_name": "โป่งน้ำร้อน"
    },
    {
        "district_id": "154",
        "province_id": "13",
        "district_name": "มะขาม"
    },
    {
        "district_id": "155",
        "province_id": "13",
        "district_name": "แหลมสิงห์"
    },
    {
        "district_id": "156",
        "province_id": "13",
        "district_name": "สอยดาว"
    },
    {
        "district_id": "157",
        "province_id": "13",
        "district_name": "แก่งหางแมว"
    },
    {
        "district_id": "158",
        "province_id": "13",
        "district_name": "นายายอาม"
    },
    {
        "district_id": "159",
        "province_id": "13",
        "district_name": "เขาคิชฌกูฏ"
    },
    {
        "district_id": "160",
        "province_id": "14",
        "district_name": "เมืองตราด"
    },
    {
        "district_id": "161",
        "province_id": "14",
        "district_name": "คลองใหญ่"
    },
    {
        "district_id": "162",
        "province_id": "14",
        "district_name": "เขาสมิง"
    },
    {
        "district_id": "163",
        "province_id": "14",
        "district_name": "บ่อไร่"
    },
    {
        "district_id": "164",
        "province_id": "14",
        "district_name": "แหลมงอบ"
    },
    {
        "district_id": "165",
        "province_id": "14",
        "district_name": "เกาะกูด"
    },
    {
        "district_id": "166",
        "province_id": "14",
        "district_name": "เกาะช้าง"
    },
    {
        "district_id": "167",
        "province_id": "15",
        "district_name": "เมืองฉะเชิงเทรา"
    },
    {
        "district_id": "168",
        "province_id": "15",
        "district_name": "บางคล้า"
    },
    {
        "district_id": "169",
        "province_id": "15",
        "district_name": "บางน้ำเปรี้ยว"
    },
    {
        "district_id": "170",
        "province_id": "15",
        "district_name": "บางปะกง"
    },
    {
        "district_id": "171",
        "province_id": "15",
        "district_name": "บ้านโพธิ์"
    },
    {
        "district_id": "172",
        "province_id": "15",
        "district_name": "พนมสารคาม"
    },
    {
        "district_id": "173",
        "province_id": "15",
        "district_name": "ราชสาส์น"
    },
    {
        "district_id": "174",
        "province_id": "15",
        "district_name": "สนามชัยเขต"
    },
    {
        "district_id": "175",
        "province_id": "15",
        "district_name": "แปลงยาว"
    },
    {
        "district_id": "176",
        "province_id": "15",
        "district_name": "ท่าตะเกียบ"
    },
    {
        "district_id": "177",
        "province_id": "15",
        "district_name": "คลองเขื่อน"
    },
    {
        "district_id": "178",
        "province_id": "16",
        "district_name": "เมืองปราจีนบุรี"
    },
    {
        "district_id": "179",
        "province_id": "16",
        "district_name": "กบินทร์บุรี"
    },
    {
        "district_id": "180",
        "province_id": "16",
        "district_name": "นาดี"
    },
    {
        "district_id": "181",
        "province_id": "16",
        "district_name": "บ้านสร้าง"
    },
    {
        "district_id": "182",
        "province_id": "16",
        "district_name": "ประจันตคาม"
    },
    {
        "district_id": "183",
        "province_id": "16",
        "district_name": "ศรีมหาโพธิ"
    },
    {
        "district_id": "184",
        "province_id": "16",
        "district_name": "ศรีมโหสถ"
    },
    {
        "district_id": "185",
        "province_id": "17",
        "district_name": "เมืองนครนายก"
    },
    {
        "district_id": "186",
        "province_id": "17",
        "district_name": "ปากพลี"
    },
    {
        "district_id": "187",
        "province_id": "17",
        "district_name": "บ้านนา"
    },
    {
        "district_id": "188",
        "province_id": "17",
        "district_name": "องครักษ์"
    },
    {
        "district_id": "189",
        "province_id": "18",
        "district_name": "เมืองสระแก้ว"
    },
    {
        "district_id": "190",
        "province_id": "18",
        "district_name": "คลองหาด"
    },
    {
        "district_id": "191",
        "province_id": "18",
        "district_name": "ตาพระยา"
    },
    {
        "district_id": "192",
        "province_id": "18",
        "district_name": "วังน้ำเย็น"
    },
    {
        "district_id": "193",
        "province_id": "18",
        "district_name": "วัฒนานคร"
    },
    {
        "district_id": "194",
        "province_id": "18",
        "district_name": "อรัญประเทศ"
    },
    {
        "district_id": "195",
        "province_id": "18",
        "district_name": "เขาฉกรรจ์"
    },
    {
        "district_id": "196",
        "province_id": "18",
        "district_name": "โคกสูง"
    },
    {
        "district_id": "197",
        "province_id": "18",
        "district_name": "วังสมบูรณ์"
    },
    {
        "district_id": "198",
        "province_id": "19",
        "district_name": "เมืองนครราชสีมา"
    },
    {
        "district_id": "199",
        "province_id": "19",
        "district_name": "ครบุรี"
    },
    {
        "district_id": "200",
        "province_id": "19",
        "district_name": "เสิงสาง"
    },
    {
        "district_id": "201",
        "province_id": "19",
        "district_name": "คง"
    },
    {
        "district_id": "202",
        "province_id": "19",
        "district_name": "บ้านเหลื่อม"
    },
    {
        "district_id": "203",
        "province_id": "19",
        "district_name": "จักราช"
    },
    {
        "district_id": "204",
        "province_id": "19",
        "district_name": "โชคชัย"
    },
    {
        "district_id": "205",
        "province_id": "19",
        "district_name": "ด่านขุนทด"
    },
    {
        "district_id": "206",
        "province_id": "19",
        "district_name": "โนนไทย"
    },
    {
        "district_id": "207",
        "province_id": "19",
        "district_name": "โนนสูง"
    },
    {
        "district_id": "208",
        "province_id": "19",
        "district_name": "ขามสะแกแสง"
    },
    {
        "district_id": "209",
        "province_id": "19",
        "district_name": "บัวใหญ่"
    },
    {
        "district_id": "210",
        "province_id": "19",
        "district_name": "ประทาย"
    },
    {
        "district_id": "211",
        "province_id": "19",
        "district_name": "ปักธงชัย"
    },
    {
        "district_id": "212",
        "province_id": "19",
        "district_name": "พิมาย"
    },
    {
        "district_id": "213",
        "province_id": "19",
        "district_name": "ห้วยแถลง"
    },
    {
        "district_id": "214",
        "province_id": "19",
        "district_name": "ชุมพวง"
    },
    {
        "district_id": "215",
        "province_id": "19",
        "district_name": "สูงเนิน"
    },
    {
        "district_id": "216",
        "province_id": "19",
        "district_name": "ขามทะเลสอ"
    },
    {
        "district_id": "217",
        "province_id": "19",
        "district_name": "สีคิ้ว"
    },
    {
        "district_id": "218",
        "province_id": "19",
        "district_name": "ปากช่อง"
    },
    {
        "district_id": "219",
        "province_id": "19",
        "district_name": "หนองบุญนาก"
    },
    {
        "district_id": "220",
        "province_id": "19",
        "district_name": "แก้งสนามนาง"
    },
    {
        "district_id": "221",
        "province_id": "19",
        "district_name": "โนนแดง"
    },
    {
        "district_id": "222",
        "province_id": "19",
        "district_name": "วังน้ำเขียว"
    },
    {
        "district_id": "223",
        "province_id": "19",
        "district_name": "เทพารักษ์"
    },
    {
        "district_id": "224",
        "province_id": "19",
        "district_name": "เมืองยาง"
    },
    {
        "district_id": "225",
        "province_id": "19",
        "district_name": "พระทองคำ"
    },
    {
        "district_id": "226",
        "province_id": "19",
        "district_name": "ลำทะเมนชัย"
    },
    {
        "district_id": "227",
        "province_id": "19",
        "district_name": "บัวลาย"
    },
    {
        "district_id": "228",
        "province_id": "19",
        "district_name": "สีดา"
    },
    {
        "district_id": "229",
        "province_id": "19",
        "district_name": "เฉลิมพระเกียรติ"
    },
    {
        "district_id": "230",
        "province_id": "20",
        "district_name": "เมืองบุรีรัมย์"
    },
    {
        "district_id": "231",
        "province_id": "20",
        "district_name": "คูเมือง"
    },
    {
        "district_id": "232",
        "province_id": "20",
        "district_name": "กระสัง"
    },
    {
        "district_id": "233",
        "province_id": "20",
        "district_name": "นางรอง"
    },
    {
        "district_id": "234",
        "province_id": "20",
        "district_name": "หนองกี่"
    },
    {
        "district_id": "235",
        "province_id": "20",
        "district_name": "ละหานทราย"
    },
    {
        "district_id": "236",
        "province_id": "20",
        "district_name": "ประโคนชัย"
    },
    {
        "district_id": "237",
        "province_id": "20",
        "district_name": "บ้านกรวด"
    },
    {
        "district_id": "238",
        "province_id": "20",
        "district_name": "พุทไธสง"
    },
    {
        "district_id": "239",
        "province_id": "20",
        "district_name": "ลำปลายมาศ"
    },
    {
        "district_id": "240",
        "province_id": "20",
        "district_name": "สตึก"
    },
    {
        "district_id": "241",
        "province_id": "20",
        "district_name": "ปะคำ"
    },
    {
        "district_id": "242",
        "province_id": "20",
        "district_name": "นาโพธิ์"
    },
    {
        "district_id": "243",
        "province_id": "20",
        "district_name": "หนองหงส์"
    },
    {
        "district_id": "244",
        "province_id": "20",
        "district_name": "พลับพลาชัย"
    },
    {
        "district_id": "245",
        "province_id": "20",
        "district_name": "ห้วยราช"
    },
    {
        "district_id": "246",
        "province_id": "20",
        "district_name": "โนนสุวรรณ"
    },
    {
        "district_id": "247",
        "province_id": "20",
        "district_name": "ชำนิ"
    },
    {
        "district_id": "248",
        "province_id": "20",
        "district_name": "บ้านใหม่ไชยพจน์"
    },
    {
        "district_id": "249",
        "province_id": "20",
        "district_name": "โนนดินแดง"
    },
    {
        "district_id": "250",
        "province_id": "20",
        "district_name": "บ้านด่าน"
    },
    {
        "district_id": "251",
        "province_id": "20",
        "district_name": "แคนดง"
    },
    {
        "district_id": "252",
        "province_id": "20",
        "district_name": "เฉลิมพระเกียรติ"
    },
    {
        "district_id": "253",
        "province_id": "21",
        "district_name": "เมืองสุรินทร์"
    },
    {
        "district_id": "254",
        "province_id": "21",
        "district_name": "ชุมพลบุรี"
    },
    {
        "district_id": "255",
        "province_id": "21",
        "district_name": "ท่าตูม"
    },
    {
        "district_id": "256",
        "province_id": "21",
        "district_name": "จอมพระ"
    },
    {
        "district_id": "257",
        "province_id": "21",
        "district_name": "ปราสาท"
    },
    {
        "district_id": "258",
        "province_id": "21",
        "district_name": "กาบเชิง"
    },
    {
        "district_id": "259",
        "province_id": "21",
        "district_name": "รัตนบุรี"
    },
    {
        "district_id": "260",
        "province_id": "21",
        "district_name": "สนม"
    },
    {
        "district_id": "261",
        "province_id": "21",
        "district_name": "ศรีขรภูมิ"
    },
    {
        "district_id": "262",
        "province_id": "21",
        "district_name": "สังขะ"
    },
    {
        "district_id": "263",
        "province_id": "21",
        "district_name": "ลำดวน"
    },
    {
        "district_id": "264",
        "province_id": "21",
        "district_name": "สำโรงทาบ"
    },
    {
        "district_id": "265",
        "province_id": "21",
        "district_name": "บัวเชด"
    },
    {
        "district_id": "266",
        "province_id": "21",
        "district_name": "พนมดงรัก"
    },
    {
        "district_id": "267",
        "province_id": "21",
        "district_name": "ศรีณรงค์"
    },
    {
        "district_id": "268",
        "province_id": "21",
        "district_name": "เขวาสินรินทร์"
    },
    {
        "district_id": "269",
        "province_id": "21",
        "district_name": "โนนนารายณ์"
    },
    {
        "district_id": "270",
        "province_id": "22",
        "district_name": "เมืองศรีสะเกษ"
    },
    {
        "district_id": "271",
        "province_id": "22",
        "district_name": "ยางชุมน้อย"
    },
    {
        "district_id": "272",
        "province_id": "22",
        "district_name": "กันทรารมย์"
    },
    {
        "district_id": "273",
        "province_id": "22",
        "district_name": "กันทรลักษ์"
    },
    {
        "district_id": "274",
        "province_id": "22",
        "district_name": "ขุขันธ์"
    },
    {
        "district_id": "275",
        "province_id": "22",
        "district_name": "ไพรบึง"
    },
    {
        "district_id": "276",
        "province_id": "22",
        "district_name": "ปรางค์กู่"
    },
    {
        "district_id": "277",
        "province_id": "22",
        "district_name": "ขุนหาญ"
    },
    {
        "district_id": "278",
        "province_id": "22",
        "district_name": "ราษีไศล"
    },
    {
        "district_id": "279",
        "province_id": "22",
        "district_name": "อุทุมพรพิสัย"
    },
    {
        "district_id": "280",
        "province_id": "22",
        "district_name": "บึงบูรพ์"
    },
    {
        "district_id": "281",
        "province_id": "22",
        "district_name": "ห้วยทับทัน"
    },
    {
        "district_id": "282",
        "province_id": "22",
        "district_name": "โนนคูณ"
    },
    {
        "district_id": "283",
        "province_id": "22",
        "district_name": "ศรีรัตนะ"
    },
    {
        "district_id": "284",
        "province_id": "22",
        "district_name": "น้ำเกลี้ยง"
    },
    {
        "district_id": "285",
        "province_id": "22",
        "district_name": "วังหิน"
    },
    {
        "district_id": "286",
        "province_id": "22",
        "district_name": "ภูสิงห์"
    },
    {
        "district_id": "287",
        "province_id": "22",
        "district_name": "เมืองจันทร์"
    },
    {
        "district_id": "288",
        "province_id": "22",
        "district_name": "เบญจลักษณ์"
    },
    {
        "district_id": "289",
        "province_id": "22",
        "district_name": "พยุห์"
    },
    {
        "district_id": "290",
        "province_id": "22",
        "district_name": "โพธิ์ศรีสุวรรณ"
    },
    {
        "district_id": "291",
        "province_id": "22",
        "district_name": "ศิลาลาด"
    },
    {
        "district_id": "292",
        "province_id": "23",
        "district_name": "เมืองอุบลราชธานี"
    },
    {
        "district_id": "293",
        "province_id": "23",
        "district_name": "ศรีเมืองใหม่"
    },
    {
        "district_id": "294",
        "province_id": "23",
        "district_name": "โขงเจียม"
    },
    {
        "district_id": "295",
        "province_id": "23",
        "district_name": "เขื่องใน"
    },
    {
        "district_id": "296",
        "province_id": "23",
        "district_name": "เขมราฐ"
    },
    {
        "district_id": "297",
        "province_id": "23",
        "district_name": "เดชอุดม"
    },
    {
        "district_id": "298",
        "province_id": "23",
        "district_name": "นาจะหลวย"
    },
    {
        "district_id": "299",
        "province_id": "23",
        "district_name": "น้ำยืน"
    },
    {
        "district_id": "300",
        "province_id": "23",
        "district_name": "บุณฑริก"
    },
    {
        "district_id": "301",
        "province_id": "23",
        "district_name": "ตระการพืชผล"
    },
    {
        "district_id": "302",
        "province_id": "23",
        "district_name": "กุดข้าวปุ้น"
    },
    {
        "district_id": "303",
        "province_id": "23",
        "district_name": "ม่วงสามสิบ"
    },
    {
        "district_id": "304",
        "province_id": "23",
        "district_name": "วารินชำราบ"
    },
    {
        "district_id": "305",
        "province_id": "23",
        "district_name": "พิบูลมังสาหาร"
    },
    {
        "district_id": "306",
        "province_id": "23",
        "district_name": "ตาลสุม"
    },
    {
        "district_id": "307",
        "province_id": "23",
        "district_name": "โพธิ์ไทร"
    },
    {
        "district_id": "308",
        "province_id": "23",
        "district_name": "สำโรง"
    },
    {
        "district_id": "309",
        "province_id": "23",
        "district_name": "ดอนมดแดง"
    },
    {
        "district_id": "310",
        "province_id": "23",
        "district_name": "สิรินธร"
    },
    {
        "district_id": "311",
        "province_id": "23",
        "district_name": "ทุ่งศรีอุดม"
    },
    {
        "district_id": "312",
        "province_id": "23",
        "district_name": "นาเยีย"
    },
    {
        "district_id": "313",
        "province_id": "23",
        "district_name": "นาตาล"
    },
    {
        "district_id": "314",
        "province_id": "23",
        "district_name": "เหล่าเสือโก้ก"
    },
    {
        "district_id": "315",
        "province_id": "23",
        "district_name": "สว่างวีระวงศ์"
    },
    {
        "district_id": "316",
        "province_id": "23",
        "district_name": "น้ำขุ่น"
    },
    {
        "district_id": "317",
        "province_id": "24",
        "district_name": "เมืองยโสธร"
    },
    {
        "district_id": "318",
        "province_id": "24",
        "district_name": "ทรายมูล"
    },
    {
        "district_id": "319",
        "province_id": "24",
        "district_name": "กุดชุม"
    },
    {
        "district_id": "320",
        "province_id": "24",
        "district_name": "คำเขื่อนแก้ว"
    },
    {
        "district_id": "321",
        "province_id": "24",
        "district_name": "ป่าติ้ว"
    },
    {
        "district_id": "322",
        "province_id": "24",
        "district_name": "มหาชนะชัย"
    },
    {
        "district_id": "323",
        "province_id": "24",
        "district_name": "ค้อวัง"
    },
    {
        "district_id": "324",
        "province_id": "24",
        "district_name": "เลิงนกทา"
    },
    {
        "district_id": "325",
        "province_id": "24",
        "district_name": "ไทยเจริญ"
    },
    {
        "district_id": "326",
        "province_id": "25",
        "district_name": "เมืองชัยภูมิ"
    },
    {
        "district_id": "327",
        "province_id": "25",
        "district_name": "บ้านเขว้า"
    },
    {
        "district_id": "328",
        "province_id": "25",
        "district_name": "คอนสวรรค์"
    },
    {
        "district_id": "329",
        "province_id": "25",
        "district_name": "เกษตรสมบูรณ์"
    },
    {
        "district_id": "330",
        "province_id": "25",
        "district_name": "หนองบัวแดง"
    },
    {
        "district_id": "331",
        "province_id": "25",
        "district_name": "จัตุรัส"
    },
    {
        "district_id": "332",
        "province_id": "25",
        "district_name": "บำเหน็จณรงค์"
    },
    {
        "district_id": "333",
        "province_id": "25",
        "district_name": "หนองบัวระเหว"
    },
    {
        "district_id": "334",
        "province_id": "25",
        "district_name": "เทพสถิต"
    },
    {
        "district_id": "335",
        "province_id": "25",
        "district_name": "ภูเขียว"
    },
    {
        "district_id": "336",
        "province_id": "25",
        "district_name": "บ้านแท่น"
    },
    {
        "district_id": "337",
        "province_id": "25",
        "district_name": "แก้งคร้อ"
    },
    {
        "district_id": "338",
        "province_id": "25",
        "district_name": "คอนสาร"
    },
    {
        "district_id": "339",
        "province_id": "25",
        "district_name": "ภักดีชุมพล"
    },
    {
        "district_id": "340",
        "province_id": "25",
        "district_name": "เนินสง่า"
    },
    {
        "district_id": "341",
        "province_id": "25",
        "district_name": "ซับใหญ่"
    },
    {
        "district_id": "342",
        "province_id": "26",
        "district_name": "เมืองอำนาจเจริญ"
    },
    {
        "district_id": "343",
        "province_id": "26",
        "district_name": "ชานุมาน"
    },
    {
        "district_id": "344",
        "province_id": "26",
        "district_name": "ปทุมราชวงศา"
    },
    {
        "district_id": "345",
        "province_id": "26",
        "district_name": "พนา"
    },
    {
        "district_id": "346",
        "province_id": "26",
        "district_name": "เสนางคนิคม"
    },
    {
        "district_id": "347",
        "province_id": "26",
        "district_name": "หัวตะพาน"
    },
    {
        "district_id": "348",
        "province_id": "26",
        "district_name": "ลืออำนาจ"
    },
    {
        "district_id": "349",
        "province_id": "27",
        "district_name": "เมืองหนองบัวลำภู"
    },
    {
        "district_id": "350",
        "province_id": "27",
        "district_name": "นากลาง"
    },
    {
        "district_id": "351",
        "province_id": "27",
        "district_name": "โนนสัง"
    },
    {
        "district_id": "352",
        "province_id": "27",
        "district_name": "ศรีบุญเรือง"
    },
    {
        "district_id": "353",
        "province_id": "27",
        "district_name": "สุวรรณคูหา"
    },
    {
        "district_id": "354",
        "province_id": "27",
        "district_name": "นาวัง"
    },
    {
        "district_id": "355",
        "province_id": "28",
        "district_name": "เมืองขอนแก่น"
    },
    {
        "district_id": "356",
        "province_id": "28",
        "district_name": "บ้านฝาง"
    },
    {
        "district_id": "357",
        "province_id": "28",
        "district_name": "พระยืน"
    },
    {
        "district_id": "358",
        "province_id": "28",
        "district_name": "หนองเรือ"
    },
    {
        "district_id": "359",
        "province_id": "28",
        "district_name": "ชุมแพ"
    },
    {
        "district_id": "360",
        "province_id": "28",
        "district_name": "สีชมพู"
    },
    {
        "district_id": "361",
        "province_id": "28",
        "district_name": "น้ำพอง"
    },
    {
        "district_id": "362",
        "province_id": "28",
        "district_name": "อุบลรัตน์"
    },
    {
        "district_id": "363",
        "province_id": "28",
        "district_name": "กระนวน"
    },
    {
        "district_id": "364",
        "province_id": "28",
        "district_name": "บ้านไผ่"
    },
    {
        "district_id": "365",
        "province_id": "28",
        "district_name": "เปือยน้อย"
    },
    {
        "district_id": "366",
        "province_id": "28",
        "district_name": "พล"
    },
    {
        "district_id": "367",
        "province_id": "28",
        "district_name": "แวงใหญ่"
    },
    {
        "district_id": "368",
        "province_id": "28",
        "district_name": "แวงน้อย"
    },
    {
        "district_id": "369",
        "province_id": "28",
        "district_name": "หนองสองห้อง"
    },
    {
        "district_id": "370",
        "province_id": "28",
        "district_name": "ภูเวียง"
    },
    {
        "district_id": "371",
        "province_id": "28",
        "district_name": "มัญจาคีรี"
    },
    {
        "district_id": "372",
        "province_id": "28",
        "district_name": "ชนบท"
    },
    {
        "district_id": "373",
        "province_id": "28",
        "district_name": "เขาสวนกวาง"
    },
    {
        "district_id": "374",
        "province_id": "28",
        "district_name": "ภูผาม่าน"
    },
    {
        "district_id": "375",
        "province_id": "28",
        "district_name": "ซำสูง"
    },
    {
        "district_id": "376",
        "province_id": "28",
        "district_name": "โคกโพธิ์ไชย"
    },
    {
        "district_id": "377",
        "province_id": "28",
        "district_name": "หนองนาคำ"
    },
    {
        "district_id": "378",
        "province_id": "28",
        "district_name": "บ้านแฮด"
    },
    {
        "district_id": "379",
        "province_id": "28",
        "district_name": "โนนศิลา"
    },
    {
        "district_id": "380",
        "province_id": "28",
        "district_name": "เวียงเก่า"
    },
    {
        "district_id": "381",
        "province_id": "29",
        "district_name": "เมืองอุดรธานี"
    },
    {
        "district_id": "382",
        "province_id": "29",
        "district_name": "กุดจับ"
    },
    {
        "district_id": "383",
        "province_id": "29",
        "district_name": "หนองวัวซอ"
    },
    {
        "district_id": "384",
        "province_id": "29",
        "district_name": "กุมภวาปี"
    },
    {
        "district_id": "385",
        "province_id": "29",
        "district_name": "โนนสะอาด"
    },
    {
        "district_id": "386",
        "province_id": "29",
        "district_name": "หนองหาน"
    },
    {
        "district_id": "387",
        "province_id": "29",
        "district_name": "ทุ่งฝน"
    },
    {
        "district_id": "388",
        "province_id": "29",
        "district_name": "ไชยวาน"
    },
    {
        "district_id": "389",
        "province_id": "29",
        "district_name": "ศรีธาตุ"
    },
    {
        "district_id": "390",
        "province_id": "29",
        "district_name": "วังสามหมอ"
    },
    {
        "district_id": "391",
        "province_id": "29",
        "district_name": "บ้านดุง"
    },
    {
        "district_id": "392",
        "province_id": "29",
        "district_name": "บ้านผือ"
    },
    {
        "district_id": "393",
        "province_id": "29",
        "district_name": "น้ำโสม"
    },
    {
        "district_id": "394",
        "province_id": "29",
        "district_name": "เพ็ญ"
    },
    {
        "district_id": "395",
        "province_id": "29",
        "district_name": "สร้างคอม"
    },
    {
        "district_id": "396",
        "province_id": "29",
        "district_name": "หนองแสง"
    },
    {
        "district_id": "397",
        "province_id": "29",
        "district_name": "นายูง"
    },
    {
        "district_id": "398",
        "province_id": "29",
        "district_name": "พิบูลย์รักษ์"
    },
    {
        "district_id": "399",
        "province_id": "29",
        "district_name": "กู่แก้ว"
    },
    {
        "district_id": "400",
        "province_id": "29",
        "district_name": "ประจักษ์ศิลปาคม"
    },
    {
        "district_id": "401",
        "province_id": "30",
        "district_name": "เมืองเลย"
    },
    {
        "district_id": "402",
        "province_id": "30",
        "district_name": "นาด้วง"
    },
    {
        "district_id": "403",
        "province_id": "30",
        "district_name": "เชียงคาน"
    },
    {
        "district_id": "404",
        "province_id": "30",
        "district_name": "ปากชม"
    },
    {
        "district_id": "405",
        "province_id": "30",
        "district_name": "ด่านซ้าย"
    },
    {
        "district_id": "406",
        "province_id": "30",
        "district_name": "นาแห้ว"
    },
    {
        "district_id": "407",
        "province_id": "30",
        "district_name": "ภูเรือ"
    },
    {
        "district_id": "408",
        "province_id": "30",
        "district_name": "ท่าลี่"
    },
    {
        "district_id": "409",
        "province_id": "30",
        "district_name": "วังสะพุง"
    },
    {
        "district_id": "410",
        "province_id": "30",
        "district_name": "ภูกระดึง"
    },
    {
        "district_id": "411",
        "province_id": "30",
        "district_name": "ภูหลวง"
    },
    {
        "district_id": "412",
        "province_id": "30",
        "district_name": "ผาขาว"
    },
    {
        "district_id": "413",
        "province_id": "30",
        "district_name": "เอราวัณ"
    },
    {
        "district_id": "414",
        "province_id": "30",
        "district_name": "หนองหิน"
    },
    {
        "district_id": "415",
        "province_id": "31",
        "district_name": "เมืองหนองคาย"
    },
    {
        "district_id": "416",
        "province_id": "31",
        "district_name": "ท่าบ่อ"
    },
    {
        "district_id": "417",
        "province_id": "31",
        "district_name": "บึงกาฬ"
    },
    {
        "district_id": "418",
        "province_id": "31",
        "district_name": "พรเจริญ"
    },
    {
        "district_id": "419",
        "province_id": "31",
        "district_name": "โพนพิสัย"
    },
    {
        "district_id": "420",
        "province_id": "31",
        "district_name": "โซ่พิสัย"
    },
    {
        "district_id": "421",
        "province_id": "31",
        "district_name": "ศรีเชียงใหม่"
    },
    {
        "district_id": "422",
        "province_id": "31",
        "district_name": "สังคม"
    },
    {
        "district_id": "423",
        "province_id": "31",
        "district_name": "เซกา"
    },
    {
        "district_id": "424",
        "province_id": "31",
        "district_name": "ปากคาด"
    },
    {
        "district_id": "425",
        "province_id": "31",
        "district_name": "บึงโขงหลง"
    },
    {
        "district_id": "426",
        "province_id": "31",
        "district_name": "ศรีวิไล"
    },
    {
        "district_id": "427",
        "province_id": "31",
        "district_name": "บุ่งคล้า"
    },
    {
        "district_id": "428",
        "province_id": "31",
        "district_name": "สระใคร"
    },
    {
        "district_id": "429",
        "province_id": "31",
        "district_name": "เฝ้าไร่"
    },
    {
        "district_id": "430",
        "province_id": "31",
        "district_name": "รัตนวาปี"
    },
    {
        "district_id": "431",
        "province_id": "31",
        "district_name": "โพธิ์ตาก"
    },
    {
        "district_id": "432",
        "province_id": "32",
        "district_name": "เมืองมหาสารคาม"
    },
    {
        "district_id": "433",
        "province_id": "32",
        "district_name": "แกดำ"
    },
    {
        "district_id": "434",
        "province_id": "32",
        "district_name": "โกสุมพิสัย"
    },
    {
        "district_id": "435",
        "province_id": "32",
        "district_name": "กันทรวิชัย"
    },
    {
        "district_id": "436",
        "province_id": "32",
        "district_name": "เชียงยืน"
    },
    {
        "district_id": "437",
        "province_id": "32",
        "district_name": "บรบือ"
    },
    {
        "district_id": "438",
        "province_id": "32",
        "district_name": "นาเชือก"
    },
    {
        "district_id": "439",
        "province_id": "32",
        "district_name": "พยัคฆภูมิพิสัย"
    },
    {
        "district_id": "440",
        "province_id": "32",
        "district_name": "วาปีปทุม"
    },
    {
        "district_id": "441",
        "province_id": "32",
        "district_name": "นาดูน"
    },
    {
        "district_id": "442",
        "province_id": "32",
        "district_name": "ยางสีสุราช"
    },
    {
        "district_id": "443",
        "province_id": "32",
        "district_name": "กุดรัง"
    },
    {
        "district_id": "444",
        "province_id": "32",
        "district_name": "ชื่นชม"
    },
    {
        "district_id": "445",
        "province_id": "33",
        "district_name": "เมืองร้อยเอ็ด"
    },
    {
        "district_id": "446",
        "province_id": "33",
        "district_name": "เกษตรวิสัย"
    },
    {
        "district_id": "447",
        "province_id": "33",
        "district_name": "ปทุมรัตต์"
    },
    {
        "district_id": "448",
        "province_id": "33",
        "district_name": "จตุรพักตรพิมาน"
    },
    {
        "district_id": "449",
        "province_id": "33",
        "district_name": "ธวัชบุรี"
    },
    {
        "district_id": "450",
        "province_id": "33",
        "district_name": "พนมไพร"
    },
    {
        "district_id": "451",
        "province_id": "33",
        "district_name": "โพนทอง"
    },
    {
        "district_id": "452",
        "province_id": "33",
        "district_name": "โพธิ์ชัย"
    },
    {
        "district_id": "453",
        "province_id": "33",
        "district_name": "หนองพอก"
    },
    {
        "district_id": "454",
        "province_id": "33",
        "district_name": "เสลภูมิ"
    },
    {
        "district_id": "455",
        "province_id": "33",
        "district_name": "สุวรรณภูมิ"
    },
    {
        "district_id": "456",
        "province_id": "33",
        "district_name": "เมืองสรวง"
    },
    {
        "district_id": "457",
        "province_id": "33",
        "district_name": "โพนทราย"
    },
    {
        "district_id": "458",
        "province_id": "33",
        "district_name": "อาจสามารถ"
    },
    {
        "district_id": "459",
        "province_id": "33",
        "district_name": "เมยวดี"
    },
    {
        "district_id": "460",
        "province_id": "33",
        "district_name": "ศรีสมเด็จ"
    },
    {
        "district_id": "461",
        "province_id": "33",
        "district_name": "จังหาร"
    },
    {
        "district_id": "462",
        "province_id": "33",
        "district_name": "เชียงขวัญ"
    },
    {
        "district_id": "463",
        "province_id": "33",
        "district_name": "หนองฮี"
    },
    {
        "district_id": "464",
        "province_id": "33",
        "district_name": "ทุ่งเขาหลวง"
    },
    {
        "district_id": "465",
        "province_id": "34",
        "district_name": "เมืองกาฬสินธุ์"
    },
    {
        "district_id": "466",
        "province_id": "34",
        "district_name": "นามน"
    },
    {
        "district_id": "467",
        "province_id": "34",
        "district_name": "กมลาไสย"
    },
    {
        "district_id": "468",
        "province_id": "34",
        "district_name": "ร่องคำ"
    },
    {
        "district_id": "469",
        "province_id": "34",
        "district_name": "กุฉินารายณ์"
    },
    {
        "district_id": "470",
        "province_id": "34",
        "district_name": "เขาวง"
    },
    {
        "district_id": "471",
        "province_id": "34",
        "district_name": "ยางตลาด"
    },
    {
        "district_id": "472",
        "province_id": "34",
        "district_name": "ห้วยเม็ก"
    },
    {
        "district_id": "473",
        "province_id": "34",
        "district_name": "สหัสขันธ์"
    },
    {
        "district_id": "474",
        "province_id": "34",
        "district_name": "คำม่วง"
    },
    {
        "district_id": "475",
        "province_id": "34",
        "district_name": "ท่าคันโท"
    },
    {
        "district_id": "476",
        "province_id": "34",
        "district_name": "หนองกุงศรี"
    },
    {
        "district_id": "477",
        "province_id": "34",
        "district_name": "สมเด็จ"
    },
    {
        "district_id": "478",
        "province_id": "34",
        "district_name": "ห้วยผึ้ง"
    },
    {
        "district_id": "479",
        "province_id": "34",
        "district_name": "สามชัย"
    },
    {
        "district_id": "480",
        "province_id": "34",
        "district_name": "นาคู"
    },
    {
        "district_id": "481",
        "province_id": "34",
        "district_name": "ดอนจาน"
    },
    {
        "district_id": "482",
        "province_id": "34",
        "district_name": "ฆ้องชัย"
    },
    {
        "district_id": "483",
        "province_id": "35",
        "district_name": "เมืองสกลนคร"
    },
    {
        "district_id": "484",
        "province_id": "35",
        "district_name": "กุสุมาลย์"
    },
    {
        "district_id": "485",
        "province_id": "35",
        "district_name": "กุดบาก"
    },
    {
        "district_id": "486",
        "province_id": "35",
        "district_name": "พรรณานิคม"
    },
    {
        "district_id": "487",
        "province_id": "35",
        "district_name": "พังโคน"
    },
    {
        "district_id": "488",
        "province_id": "35",
        "district_name": "วาริชภูมิ"
    },
    {
        "district_id": "489",
        "province_id": "35",
        "district_name": "นิคมน้ำอูน"
    },
    {
        "district_id": "490",
        "province_id": "35",
        "district_name": "วานรนิวาส"
    },
    {
        "district_id": "491",
        "province_id": "35",
        "district_name": "คำตากล้า"
    },
    {
        "district_id": "492",
        "province_id": "35",
        "district_name": "บ้านม่วง"
    },
    {
        "district_id": "493",
        "province_id": "35",
        "district_name": "อากาศอำนวย"
    },
    {
        "district_id": "494",
        "province_id": "35",
        "district_name": "สว่างแดนดิน"
    },
    {
        "district_id": "495",
        "province_id": "35",
        "district_name": "ส่องดาว"
    },
    {
        "district_id": "496",
        "province_id": "35",
        "district_name": "เต่างอย"
    },
    {
        "district_id": "497",
        "province_id": "35",
        "district_name": "โคกศรีสุพรรณ"
    },
    {
        "district_id": "498",
        "province_id": "35",
        "district_name": "เจริญศิลป์"
    },
    {
        "district_id": "499",
        "province_id": "35",
        "district_name": "โพนนาแก้ว"
    },
    {
        "district_id": "500",
        "province_id": "35",
        "district_name": "ภูพาน"
    },
    {
        "district_id": "501",
        "province_id": "36",
        "district_name": "เมืองนครพนม"
    },
    {
        "district_id": "502",
        "province_id": "36",
        "district_name": "ปลาปาก"
    },
    {
        "district_id": "503",
        "province_id": "36",
        "district_name": "ท่าอุเทน"
    },
    {
        "district_id": "504",
        "province_id": "36",
        "district_name": "บ้านแพง"
    },
    {
        "district_id": "505",
        "province_id": "36",
        "district_name": "ธาตุพนม"
    },
    {
        "district_id": "506",
        "province_id": "36",
        "district_name": "เรณูนคร"
    },
    {
        "district_id": "507",
        "province_id": "36",
        "district_name": "นาแก"
    },
    {
        "district_id": "508",
        "province_id": "36",
        "district_name": "ศรีสงคราม"
    },
    {
        "district_id": "509",
        "province_id": "36",
        "district_name": "นาหว้า"
    },
    {
        "district_id": "510",
        "province_id": "36",
        "district_name": "โพนสวรรค์"
    },
    {
        "district_id": "511",
        "province_id": "36",
        "district_name": "นาทม"
    },
    {
        "district_id": "512",
        "province_id": "36",
        "district_name": "วังยาง"
    },
    {
        "district_id": "513",
        "province_id": "37",
        "district_name": "เมืองมุกดาหาร"
    },
    {
        "district_id": "514",
        "province_id": "37",
        "district_name": "นิคมคำสร้อย"
    },
    {
        "district_id": "515",
        "province_id": "37",
        "district_name": "ดอนตาล"
    },
    {
        "district_id": "516",
        "province_id": "37",
        "district_name": "ดงหลวง"
    },
    {
        "district_id": "517",
        "province_id": "37",
        "district_name": "คำชะอี"
    },
    {
        "district_id": "518",
        "province_id": "37",
        "district_name": "หว้านใหญ่"
    },
    {
        "district_id": "519",
        "province_id": "37",
        "district_name": "หนองสูง"
    },
    {
        "district_id": "520",
        "province_id": "38",
        "district_name": "เมืองเชียงใหม่"
    },
    {
        "district_id": "521",
        "province_id": "38",
        "district_name": "จอมทอง"
    },
    {
        "district_id": "522",
        "province_id": "38",
        "district_name": "แม่แจ่ม"
    },
    {
        "district_id": "523",
        "province_id": "38",
        "district_name": "เชียงดาว"
    },
    {
        "district_id": "524",
        "province_id": "38",
        "district_name": "ดอยสะเก็ด"
    },
    {
        "district_id": "525",
        "province_id": "38",
        "district_name": "แม่แตง"
    },
    {
        "district_id": "526",
        "province_id": "38",
        "district_name": "แม่ริม"
    },
    {
        "district_id": "527",
        "province_id": "38",
        "district_name": "สะเมิง"
    },
    {
        "district_id": "528",
        "province_id": "38",
        "district_name": "ฝาง"
    },
    {
        "district_id": "529",
        "province_id": "38",
        "district_name": "แม่อาย"
    },
    {
        "district_id": "530",
        "province_id": "38",
        "district_name": "พร้าว"
    },
    {
        "district_id": "531",
        "province_id": "38",
        "district_name": "สันป่าตอง"
    },
    {
        "district_id": "532",
        "province_id": "38",
        "district_name": "สันกำแพง"
    },
    {
        "district_id": "533",
        "province_id": "38",
        "district_name": "สันทราย"
    },
    {
        "district_id": "534",
        "province_id": "38",
        "district_name": "หางดง"
    },
    {
        "district_id": "535",
        "province_id": "38",
        "district_name": "ฮอด"
    },
    {
        "district_id": "536",
        "province_id": "38",
        "district_name": "ดอยเต่า"
    },
    {
        "district_id": "537",
        "province_id": "38",
        "district_name": "อมก๋อย"
    },
    {
        "district_id": "538",
        "province_id": "38",
        "district_name": "สารภี"
    },
    {
        "district_id": "539",
        "province_id": "38",
        "district_name": "เวียงแหง"
    },
    {
        "district_id": "540",
        "province_id": "38",
        "district_name": "ไชยปราการ"
    },
    {
        "district_id": "541",
        "province_id": "38",
        "district_name": "แม่วาง"
    },
    {
        "district_id": "542",
        "province_id": "38",
        "district_name": "แม่ออน"
    },
    {
        "district_id": "543",
        "province_id": "38",
        "district_name": "ดอยหล่อ"
    },
    {
        "district_id": "544",
        "province_id": "39",
        "district_name": "เมืองลำพูน"
    },
    {
        "district_id": "545",
        "province_id": "39",
        "district_name": "แม่ทา"
    },
    {
        "district_id": "546",
        "province_id": "39",
        "district_name": "บ้านโฮ่ง"
    },
    {
        "district_id": "547",
        "province_id": "39",
        "district_name": "ลี้"
    },
    {
        "district_id": "548",
        "province_id": "39",
        "district_name": "ทุ่งหัวช้าง"
    },
    {
        "district_id": "549",
        "province_id": "39",
        "district_name": "ป่าซาง"
    },
    {
        "district_id": "550",
        "province_id": "39",
        "district_name": "บ้านธิ"
    },
    {
        "district_id": "551",
        "province_id": "39",
        "district_name": "เวียงหนองล่อง"
    },
    {
        "district_id": "552",
        "province_id": "40",
        "district_name": "เมืองลำปาง"
    },
    {
        "district_id": "553",
        "province_id": "40",
        "district_name": "แม่เมาะ"
    },
    {
        "district_id": "554",
        "province_id": "40",
        "district_name": "เกาะคา"
    },
    {
        "district_id": "555",
        "province_id": "40",
        "district_name": "เสริมงาม"
    },
    {
        "district_id": "556",
        "province_id": "40",
        "district_name": "งาว"
    },
    {
        "district_id": "557",
        "province_id": "40",
        "district_name": "แจ้ห่ม"
    },
    {
        "district_id": "558",
        "province_id": "40",
        "district_name": "วังเหนือ"
    },
    {
        "district_id": "559",
        "province_id": "40",
        "district_name": "เถิน"
    },
    {
        "district_id": "560",
        "province_id": "40",
        "district_name": "แม่พริก"
    },
    {
        "district_id": "561",
        "province_id": "40",
        "district_name": "แม่ทะ"
    },
    {
        "district_id": "562",
        "province_id": "40",
        "district_name": "สบปราบ"
    },
    {
        "district_id": "563",
        "province_id": "40",
        "district_name": "ห้างฉัตร"
    },
    {
        "district_id": "564",
        "province_id": "40",
        "district_name": "เมืองปาน"
    },
    {
        "district_id": "565",
        "province_id": "41",
        "district_name": "เมืองอุตรดิตถ์"
    },
    {
        "district_id": "566",
        "province_id": "41",
        "district_name": "ตรอน"
    },
    {
        "district_id": "567",
        "province_id": "41",
        "district_name": "ท่าปลา"
    },
    {
        "district_id": "568",
        "province_id": "41",
        "district_name": "น้ำปาด"
    },
    {
        "district_id": "569",
        "province_id": "41",
        "district_name": "ฟากท่า"
    },
    {
        "district_id": "570",
        "province_id": "41",
        "district_name": "บ้านโคก"
    },
    {
        "district_id": "571",
        "province_id": "41",
        "district_name": "พิชัย"
    },
    {
        "district_id": "572",
        "province_id": "41",
        "district_name": "ลับแล"
    },
    {
        "district_id": "573",
        "province_id": "41",
        "district_name": "ทองแสนขัน"
    },
    {
        "district_id": "574",
        "province_id": "42",
        "district_name": "เมืองแพร่"
    },
    {
        "district_id": "575",
        "province_id": "42",
        "district_name": "ร้องกวาง"
    },
    {
        "district_id": "576",
        "province_id": "42",
        "district_name": "ลอง"
    },
    {
        "district_id": "577",
        "province_id": "42",
        "district_name": "สูงเม่น"
    },
    {
        "district_id": "578",
        "province_id": "42",
        "district_name": "เด่นชัย"
    },
    {
        "district_id": "579",
        "province_id": "42",
        "district_name": "สอง"
    },
    {
        "district_id": "580",
        "province_id": "42",
        "district_name": "วังชิ้น"
    },
    {
        "district_id": "581",
        "province_id": "42",
        "district_name": "หนองม่วงไข่"
    },
    {
        "district_id": "582",
        "province_id": "43",
        "district_name": "เมืองน่าน"
    },
    {
        "district_id": "583",
        "province_id": "43",
        "district_name": "แม่จริม"
    },
    {
        "district_id": "584",
        "province_id": "43",
        "district_name": "บ้านหลวง"
    },
    {
        "district_id": "585",
        "province_id": "43",
        "district_name": "นาน้อย"
    },
    {
        "district_id": "586",
        "province_id": "43",
        "district_name": "ปัว"
    },
    {
        "district_id": "587",
        "province_id": "43",
        "district_name": "ท่าวังผา"
    },
    {
        "district_id": "588",
        "province_id": "43",
        "district_name": "เวียงสา"
    },
    {
        "district_id": "589",
        "province_id": "43",
        "district_name": "ทุ่งช้าง"
    },
    {
        "district_id": "590",
        "province_id": "43",
        "district_name": "เชียงกลาง"
    },
    {
        "district_id": "591",
        "province_id": "43",
        "district_name": "นาหมื่น"
    },
    {
        "district_id": "592",
        "province_id": "43",
        "district_name": "สันติสุข"
    },
    {
        "district_id": "593",
        "province_id": "43",
        "district_name": "บ่อเกลือ"
    },
    {
        "district_id": "594",
        "province_id": "43",
        "district_name": "สองแคว"
    },
    {
        "district_id": "595",
        "province_id": "43",
        "district_name": "ภูเพียง"
    },
    {
        "district_id": "596",
        "province_id": "43",
        "district_name": "เฉลิมพระเกียรติ"
    },
    {
        "district_id": "597",
        "province_id": "44",
        "district_name": "เมืองพะเยา"
    },
    {
        "district_id": "598",
        "province_id": "44",
        "district_name": "จุน"
    },
    {
        "district_id": "599",
        "province_id": "44",
        "district_name": "เชียงคำ"
    },
    {
        "district_id": "600",
        "province_id": "44",
        "district_name": "เชียงม่วน"
    },
    {
        "district_id": "601",
        "province_id": "44",
        "district_name": "ดอกคำใต้"
    },
    {
        "district_id": "602",
        "province_id": "44",
        "district_name": "ปง"
    },
    {
        "district_id": "603",
        "province_id": "44",
        "district_name": "แม่ใจ"
    },
    {
        "district_id": "604",
        "province_id": "44",
        "district_name": "ภูซาง"
    },
    {
        "district_id": "605",
        "province_id": "44",
        "district_name": "ภูกามยาว"
    },
    {
        "district_id": "606",
        "province_id": "45",
        "district_name": "เมืองเชียงราย"
    },
    {
        "district_id": "607",
        "province_id": "45",
        "district_name": "เวียงชัย"
    },
    {
        "district_id": "608",
        "province_id": "45",
        "district_name": "เชียงของ"
    },
    {
        "district_id": "609",
        "province_id": "45",
        "district_name": "เทิง"
    },
    {
        "district_id": "610",
        "province_id": "45",
        "district_name": "พาน"
    },
    {
        "district_id": "611",
        "province_id": "45",
        "district_name": "ป่าแดด"
    },
    {
        "district_id": "612",
        "province_id": "45",
        "district_name": "แม่จัน"
    },
    {
        "district_id": "613",
        "province_id": "45",
        "district_name": "เชียงแสน"
    },
    {
        "district_id": "614",
        "province_id": "45",
        "district_name": "แม่สาย"
    },
    {
        "district_id": "615",
        "province_id": "45",
        "district_name": "แม่สรวย"
    },
    {
        "district_id": "616",
        "province_id": "45",
        "district_name": "เวียงป่าเป้า"
    },
    {
        "district_id": "617",
        "province_id": "45",
        "district_name": "พญาเม็งราย"
    },
    {
        "district_id": "618",
        "province_id": "45",
        "district_name": "เวียงแก่น"
    },
    {
        "district_id": "619",
        "province_id": "45",
        "district_name": "ขุนตาล"
    },
    {
        "district_id": "620",
        "province_id": "45",
        "district_name": "แม่ฟ้าหลวง"
    },
    {
        "district_id": "621",
        "province_id": "45",
        "district_name": "แม่ลาว"
    },
    {
        "district_id": "622",
        "province_id": "45",
        "district_name": "เวียงเชียงรุ้ง"
    },
    {
        "district_id": "623",
        "province_id": "45",
        "district_name": "ดอยหลวง"
    },
    {
        "district_id": "624",
        "province_id": "46",
        "district_name": "เมืองแม่ฮ่องสอน"
    },
    {
        "district_id": "625",
        "province_id": "46",
        "district_name": "ขุนยวม"
    },
    {
        "district_id": "626",
        "province_id": "46",
        "district_name": "ปาย"
    },
    {
        "district_id": "627",
        "province_id": "46",
        "district_name": "แม่สะเรียง"
    },
    {
        "district_id": "628",
        "province_id": "46",
        "district_name": "แม่ลาน้อย"
    },
    {
        "district_id": "629",
        "province_id": "46",
        "district_name": "สบเมย"
    },
    {
        "district_id": "630",
        "province_id": "46",
        "district_name": "ปางมะผ้า"
    },
    {
        "district_id": "631",
        "province_id": "47",
        "district_name": "เมืองนครสวรรค์"
    },
    {
        "district_id": "632",
        "province_id": "47",
        "district_name": "โกรกพระ"
    },
    {
        "district_id": "633",
        "province_id": "47",
        "district_name": "ชุมแสง"
    },
    {
        "district_id": "634",
        "province_id": "47",
        "district_name": "หนองบัว"
    },
    {
        "district_id": "635",
        "province_id": "47",
        "district_name": "บรรพตพิสัย"
    },
    {
        "district_id": "636",
        "province_id": "47",
        "district_name": "เก้าเลี้ยว"
    },
    {
        "district_id": "637",
        "province_id": "47",
        "district_name": "ตาคลี"
    },
    {
        "district_id": "638",
        "province_id": "47",
        "district_name": "ท่าตะโก"
    },
    {
        "district_id": "639",
        "province_id": "47",
        "district_name": "ไพศาลี"
    },
    {
        "district_id": "640",
        "province_id": "47",
        "district_name": "พยุหะคีรี"
    },
    {
        "district_id": "641",
        "province_id": "47",
        "district_name": "ลาดยาว"
    },
    {
        "district_id": "642",
        "province_id": "47",
        "district_name": "ตากฟ้า"
    },
    {
        "district_id": "643",
        "province_id": "47",
        "district_name": "แม่วงก์"
    },
    {
        "district_id": "644",
        "province_id": "47",
        "district_name": "แม่เปิน"
    },
    {
        "district_id": "645",
        "province_id": "47",
        "district_name": "ชุมตาบง"
    },
    {
        "district_id": "646",
        "province_id": "48",
        "district_name": "เมืองอุทัยธานี"
    },
    {
        "district_id": "647",
        "province_id": "48",
        "district_name": "ทัพทัน"
    },
    {
        "district_id": "648",
        "province_id": "48",
        "district_name": "สว่างอารมณ์"
    },
    {
        "district_id": "649",
        "province_id": "48",
        "district_name": "หนองฉาง"
    },
    {
        "district_id": "650",
        "province_id": "48",
        "district_name": "หนองขาหย่าง"
    },
    {
        "district_id": "651",
        "province_id": "48",
        "district_name": "บ้านไร่"
    },
    {
        "district_id": "652",
        "province_id": "48",
        "district_name": "ลานสัก"
    },
    {
        "district_id": "653",
        "province_id": "48",
        "district_name": "ห้วยคต"
    },
    {
        "district_id": "654",
        "province_id": "49",
        "district_name": "เมืองกำแพงเพชร"
    },
    {
        "district_id": "655",
        "province_id": "49",
        "district_name": "ไทรงาม"
    },
    {
        "district_id": "656",
        "province_id": "49",
        "district_name": "คลองลาน"
    },
    {
        "district_id": "657",
        "province_id": "49",
        "district_name": "ขาณุวรลักษบุรี"
    },
    {
        "district_id": "658",
        "province_id": "49",
        "district_name": "คลองขลุง"
    },
    {
        "district_id": "659",
        "province_id": "49",
        "district_name": "พรานกระต่าย"
    },
    {
        "district_id": "660",
        "province_id": "49",
        "district_name": "ลานกระบือ"
    },
    {
        "district_id": "661",
        "province_id": "49",
        "district_name": "ทรายทองวัฒนา"
    },
    {
        "district_id": "662",
        "province_id": "49",
        "district_name": "ปางศิลาทอง"
    },
    {
        "district_id": "663",
        "province_id": "49",
        "district_name": "บึงสามัคคี"
    },
    {
        "district_id": "664",
        "province_id": "49",
        "district_name": "โกสัมพีนคร"
    },
    {
        "district_id": "665",
        "province_id": "50",
        "district_name": "เมืองตาก"
    },
    {
        "district_id": "666",
        "province_id": "50",
        "district_name": "บ้านตาก"
    },
    {
        "district_id": "667",
        "province_id": "50",
        "district_name": "สามเงา"
    },
    {
        "district_id": "668",
        "province_id": "50",
        "district_name": "แม่ระมาด"
    },
    {
        "district_id": "669",
        "province_id": "50",
        "district_name": "ท่าสองยาง"
    },
    {
        "district_id": "670",
        "province_id": "50",
        "district_name": "แม่สอด"
    },
    {
        "district_id": "671",
        "province_id": "50",
        "district_name": "พบพระ"
    },
    {
        "district_id": "672",
        "province_id": "50",
        "district_name": "อุ้มผาง"
    },
    {
        "district_id": "673",
        "province_id": "50",
        "district_name": "วังเจ้า"
    },
    {
        "district_id": "674",
        "province_id": "51",
        "district_name": "เมืองสุโขทัย"
    },
    {
        "district_id": "675",
        "province_id": "51",
        "district_name": "บ้านด่านลานหอย"
    },
    {
        "district_id": "676",
        "province_id": "51",
        "district_name": "คีรีมาศ"
    },
    {
        "district_id": "677",
        "province_id": "51",
        "district_name": "กงไกรลาศ"
    },
    {
        "district_id": "678",
        "province_id": "51",
        "district_name": "ศรีสัชนาลัย"
    },
    {
        "district_id": "679",
        "province_id": "51",
        "district_name": "ศรีสำโรง"
    },
    {
        "district_id": "680",
        "province_id": "51",
        "district_name": "สวรรคโลก"
    },
    {
        "district_id": "681",
        "province_id": "51",
        "district_name": "ศรีนคร"
    },
    {
        "district_id": "682",
        "province_id": "51",
        "district_name": "ทุ่งเสลี่ยม"
    },
    {
        "district_id": "683",
        "province_id": "52",
        "district_name": "เมืองพิษณุโลก"
    },
    {
        "district_id": "684",
        "province_id": "52",
        "district_name": "นครไทย"
    },
    {
        "district_id": "685",
        "province_id": "52",
        "district_name": "ชาติตระการ"
    },
    {
        "district_id": "686",
        "province_id": "52",
        "district_name": "บางระกำ"
    },
    {
        "district_id": "687",
        "province_id": "52",
        "district_name": "บางกระทุ่ม"
    },
    {
        "district_id": "688",
        "province_id": "52",
        "district_name": "พรหมพิราม"
    },
    {
        "district_id": "689",
        "province_id": "52",
        "district_name": "วัดโบสถ์"
    },
    {
        "district_id": "690",
        "province_id": "52",
        "district_name": "วังทอง"
    },
    {
        "district_id": "691",
        "province_id": "52",
        "district_name": "เนินมะปราง"
    },
    {
        "district_id": "692",
        "province_id": "53",
        "district_name": "เมืองพิจิตร"
    },
    {
        "district_id": "693",
        "province_id": "53",
        "district_name": "วังทรายพูน"
    },
    {
        "district_id": "694",
        "province_id": "53",
        "district_name": "โพธิ์ประทับช้าง"
    },
    {
        "district_id": "695",
        "province_id": "53",
        "district_name": "ตะพานหิน"
    },
    {
        "district_id": "696",
        "province_id": "53",
        "district_name": "บางมูลนาก"
    },
    {
        "district_id": "697",
        "province_id": "53",
        "district_name": "โพทะเล"
    },
    {
        "district_id": "698",
        "province_id": "53",
        "district_name": "สามง่าม"
    },
    {
        "district_id": "699",
        "province_id": "53",
        "district_name": "ทับคล้อ"
    },
    {
        "district_id": "700",
        "province_id": "53",
        "district_name": "สากเหล็ก"
    },
    {
        "district_id": "701",
        "province_id": "53",
        "district_name": "บึงนาราง"
    },
    {
        "district_id": "702",
        "province_id": "53",
        "district_name": "ดงเจริญ"
    },
    {
        "district_id": "703",
        "province_id": "53",
        "district_name": "วชิรบารมี"
    },
    {
        "district_id": "704",
        "province_id": "54",
        "district_name": "เมืองเพชรบูรณ์"
    },
    {
        "district_id": "705",
        "province_id": "54",
        "district_name": "ชนแดน"
    },
    {
        "district_id": "706",
        "province_id": "54",
        "district_name": "หล่มสัก"
    },
    {
        "district_id": "707",
        "province_id": "54",
        "district_name": "หล่มเก่า"
    },
    {
        "district_id": "708",
        "province_id": "54",
        "district_name": "วิเชียรบุรี"
    },
    {
        "district_id": "709",
        "province_id": "54",
        "district_name": "ศรีเทพ"
    },
    {
        "district_id": "710",
        "province_id": "54",
        "district_name": "หนองไผ่"
    },
    {
        "district_id": "711",
        "province_id": "54",
        "district_name": "บึงสามพัน"
    },
    {
        "district_id": "712",
        "province_id": "54",
        "district_name": "น้ำหนาว"
    },
    {
        "district_id": "713",
        "province_id": "54",
        "district_name": "วังโป่ง"
    },
    {
        "district_id": "714",
        "province_id": "54",
        "district_name": "เขาค้อ"
    },
    {
        "district_id": "715",
        "province_id": "55",
        "district_name": "เมืองราชบุรี"
    },
    {
        "district_id": "716",
        "province_id": "55",
        "district_name": "จอมบึง"
    },
    {
        "district_id": "717",
        "province_id": "55",
        "district_name": "สวนผึ้ง"
    },
    {
        "district_id": "718",
        "province_id": "55",
        "district_name": "ดำเนินสะดวก"
    },
    {
        "district_id": "719",
        "province_id": "55",
        "district_name": "บ้านโป่ง"
    },
    {
        "district_id": "720",
        "province_id": "55",
        "district_name": "บางแพ"
    },
    {
        "district_id": "721",
        "province_id": "55",
        "district_name": "โพธาราม"
    },
    {
        "district_id": "722",
        "province_id": "55",
        "district_name": "ปากท่อ"
    },
    {
        "district_id": "723",
        "province_id": "55",
        "district_name": "วัดเพลง"
    },
    {
        "district_id": "724",
        "province_id": "55",
        "district_name": "บ้านคา"
    },
    {
        "district_id": "725",
        "province_id": "55",
        "district_name": "ท้องถิ่นเทศบาลตำบลบ้านฆ้อง"
    },
    {
        "district_id": "726",
        "province_id": "56",
        "district_name": "เมืองกาญจนบุรี"
    },
    {
        "district_id": "727",
        "province_id": "56",
        "district_name": "ไทรโยค"
    },
    {
        "district_id": "728",
        "province_id": "56",
        "district_name": "บ่อพลอย"
    },
    {
        "district_id": "729",
        "province_id": "56",
        "district_name": "ศรีสวัสดิ์"
    },
    {
        "district_id": "730",
        "province_id": "56",
        "district_name": "ท่ามะกา"
    },
    {
        "district_id": "731",
        "province_id": "56",
        "district_name": "ท่าม่วง"
    },
    {
        "district_id": "732",
        "province_id": "56",
        "district_name": "ทองผาภูมิ"
    },
    {
        "district_id": "733",
        "province_id": "56",
        "district_name": "สังขละบุรี"
    },
    {
        "district_id": "734",
        "province_id": "56",
        "district_name": "พนมทวน"
    },
    {
        "district_id": "735",
        "province_id": "56",
        "district_name": "เลาขวัญ"
    },
    {
        "district_id": "736",
        "province_id": "56",
        "district_name": "ด่านมะขามเตี้ย"
    },
    {
        "district_id": "737",
        "province_id": "56",
        "district_name": "หนองปรือ"
    },
    {
        "district_id": "738",
        "province_id": "56",
        "district_name": "ห้วยกระเจา"
    },
    {
        "district_id": "739",
        "province_id": "57",
        "district_name": "เมืองสุพรรณบุรี"
    },
    {
        "district_id": "740",
        "province_id": "57",
        "district_name": "เดิมบางนางบวช"
    },
    {
        "district_id": "741",
        "province_id": "57",
        "district_name": "ด่านช้าง"
    },
    {
        "district_id": "742",
        "province_id": "57",
        "district_name": "บางปลาม้า"
    },
    {
        "district_id": "743",
        "province_id": "57",
        "district_name": "ศรีประจันต์"
    },
    {
        "district_id": "744",
        "province_id": "57",
        "district_name": "ดอนเจดีย์"
    },
    {
        "district_id": "745",
        "province_id": "57",
        "district_name": "สองพี่น้อง"
    },
    {
        "district_id": "746",
        "province_id": "57",
        "district_name": "สามชุก"
    },
    {
        "district_id": "747",
        "province_id": "57",
        "district_name": "อู่ทอง"
    },
    {
        "district_id": "748",
        "province_id": "57",
        "district_name": "หนองหญ้าไซ"
    },
    {
        "district_id": "749",
        "province_id": "58",
        "district_name": "เมืองนครปฐม"
    },
    {
        "district_id": "750",
        "province_id": "58",
        "district_name": "กำแพงแสน"
    },
    {
        "district_id": "751",
        "province_id": "58",
        "district_name": "นครชัยศรี"
    },
    {
        "district_id": "752",
        "province_id": "58",
        "district_name": "ดอนตูม"
    },
    {
        "district_id": "753",
        "province_id": "58",
        "district_name": "บางเลน"
    },
    {
        "district_id": "754",
        "province_id": "58",
        "district_name": "สามพราน"
    },
    {
        "district_id": "755",
        "province_id": "58",
        "district_name": "พุทธมณฑล"
    },
    {
        "district_id": "756",
        "province_id": "59",
        "district_name": "เมืองสมุทรสาคร"
    },
    {
        "district_id": "757",
        "province_id": "59",
        "district_name": "กระทุ่มแบน"
    },
    {
        "district_id": "758",
        "province_id": "59",
        "district_name": "บ้านแพ้ว"
    },
    {
        "district_id": "759",
        "province_id": "60",
        "district_name": "เมืองสมุทรสงคราม"
    },
    {
        "district_id": "760",
        "province_id": "60",
        "district_name": "บางคนที"
    },
    {
        "district_id": "761",
        "province_id": "60",
        "district_name": "อัมพวา"
    },
    {
        "district_id": "762",
        "province_id": "61",
        "district_name": "เมืองเพชรบุรี"
    },
    {
        "district_id": "763",
        "province_id": "61",
        "district_name": "เขาย้อย"
    },
    {
        "district_id": "764",
        "province_id": "61",
        "district_name": "หนองหญ้าปล้อง"
    },
    {
        "district_id": "765",
        "province_id": "61",
        "district_name": "ชะอำ"
    },
    {
        "district_id": "766",
        "province_id": "61",
        "district_name": "ท่ายาง"
    },
    {
        "district_id": "767",
        "province_id": "61",
        "district_name": "บ้านลาด"
    },
    {
        "district_id": "768",
        "province_id": "61",
        "district_name": "บ้านแหลม"
    },
    {
        "district_id": "769",
        "province_id": "61",
        "district_name": "แก่งกระจาน"
    },
    {
        "district_id": "770",
        "province_id": "62",
        "district_name": "เมืองประจวบคีรีขันธ์"
    },
    {
        "district_id": "771",
        "province_id": "62",
        "district_name": "กุยบุรี"
    },
    {
        "district_id": "772",
        "province_id": "62",
        "district_name": "ทับสะแก"
    },
    {
        "district_id": "773",
        "province_id": "62",
        "district_name": "บางสะพาน"
    },
    {
        "district_id": "774",
        "province_id": "62",
        "district_name": "บางสะพานน้อย"
    },
    {
        "district_id": "775",
        "province_id": "62",
        "district_name": "ปราณบุรี"
    },
    {
        "district_id": "776",
        "province_id": "62",
        "district_name": "หัวหิน"
    },
    {
        "district_id": "777",
        "province_id": "62",
        "district_name": "สามร้อยยอด"
    },
    {
        "district_id": "778",
        "province_id": "63",
        "district_name": "เมืองนครศรีธรรมราช"
    },
    {
        "district_id": "779",
        "province_id": "63",
        "district_name": "พรหมคีรี"
    },
    {
        "district_id": "780",
        "province_id": "63",
        "district_name": "ลานสกา"
    },
    {
        "district_id": "781",
        "province_id": "63",
        "district_name": "ฉวาง"
    },
    {
        "district_id": "782",
        "province_id": "63",
        "district_name": "พิปูน"
    },
    {
        "district_id": "783",
        "province_id": "63",
        "district_name": "เชียรใหญ่"
    },
    {
        "district_id": "784",
        "province_id": "63",
        "district_name": "ชะอวด"
    },
    {
        "district_id": "785",
        "province_id": "63",
        "district_name": "ท่าศาลา"
    },
    {
        "district_id": "786",
        "province_id": "63",
        "district_name": "ทุ่งสง"
    },
    {
        "district_id": "787",
        "province_id": "63",
        "district_name": "นาบอน"
    },
    {
        "district_id": "788",
        "province_id": "63",
        "district_name": "ทุ่งใหญ่"
    },
    {
        "district_id": "789",
        "province_id": "63",
        "district_name": "ปากพนัง"
    },
    {
        "district_id": "790",
        "province_id": "63",
        "district_name": "ร่อนพิบูลย์"
    },
    {
        "district_id": "791",
        "province_id": "63",
        "district_name": "สิชล"
    },
    {
        "district_id": "792",
        "province_id": "63",
        "district_name": "ขนอม"
    },
    {
        "district_id": "793",
        "province_id": "63",
        "district_name": "หัวไทร"
    },
    {
        "district_id": "794",
        "province_id": "63",
        "district_name": "บางขัน"
    },
    {
        "district_id": "795",
        "province_id": "63",
        "district_name": "ถ้ำพรรณรา"
    },
    {
        "district_id": "796",
        "province_id": "63",
        "district_name": "จุฬาภรณ์"
    },
    {
        "district_id": "797",
        "province_id": "63",
        "district_name": "พระพรหม"
    },
    {
        "district_id": "798",
        "province_id": "63",
        "district_name": "นบพิตำ"
    },
    {
        "district_id": "799",
        "province_id": "63",
        "district_name": "ช้างกลาง"
    },
    {
        "district_id": "800",
        "province_id": "63",
        "district_name": "เฉลิมพระเกียรติ"
    },
    {
        "district_id": "801",
        "province_id": "64",
        "district_name": "เมืองกระบี่"
    },
    {
        "district_id": "802",
        "province_id": "64",
        "district_name": "เขาพนม"
    },
    {
        "district_id": "803",
        "province_id": "64",
        "district_name": "เกาะลันตา"
    },
    {
        "district_id": "804",
        "province_id": "64",
        "district_name": "คลองท่อม"
    },
    {
        "district_id": "805",
        "province_id": "64",
        "district_name": "อ่าวลึก"
    },
    {
        "district_id": "806",
        "province_id": "64",
        "district_name": "ปลายพระยา"
    },
    {
        "district_id": "807",
        "province_id": "64",
        "district_name": "ลำทับ"
    },
    {
        "district_id": "808",
        "province_id": "64",
        "district_name": "เหนือคลอง"
    },
    {
        "district_id": "809",
        "province_id": "65",
        "district_name": "เมืองพังงา"
    },
    {
        "district_id": "810",
        "province_id": "65",
        "district_name": "เกาะยาว"
    },
    {
        "district_id": "811",
        "province_id": "65",
        "district_name": "กะปง"
    },
    {
        "district_id": "812",
        "province_id": "65",
        "district_name": "ตะกั่วทุ่ง"
    },
    {
        "district_id": "813",
        "province_id": "65",
        "district_name": "ตะกั่วป่า"
    },
    {
        "district_id": "814",
        "province_id": "65",
        "district_name": "คุระบุรี"
    },
    {
        "district_id": "815",
        "province_id": "65",
        "district_name": "ทับปุด"
    },
    {
        "district_id": "816",
        "province_id": "65",
        "district_name": "ท้ายเหมือง"
    },
    {
        "district_id": "817",
        "province_id": "66",
        "district_name": "เมืองภูเก็ต"
    },
    {
        "district_id": "818",
        "province_id": "66",
        "district_name": "กะทู้"
    },
    {
        "district_id": "819",
        "province_id": "66",
        "district_name": "ถลาง"
    },
    {
        "district_id": "820",
        "province_id": "67",
        "district_name": "เมืองสุราษฎร์ธานี"
    },
    {
        "district_id": "821",
        "province_id": "67",
        "district_name": "กาญจนดิษฐ์"
    },
    {
        "district_id": "822",
        "province_id": "67",
        "district_name": "ดอนสัก"
    },
    {
        "district_id": "823",
        "province_id": "67",
        "district_name": "เกาะสมุย"
    },
    {
        "district_id": "824",
        "province_id": "67",
        "district_name": "เกาะพะงัน"
    },
    {
        "district_id": "825",
        "province_id": "67",
        "district_name": "ไชยา"
    },
    {
        "district_id": "826",
        "province_id": "67",
        "district_name": "ท่าชนะ"
    },
    {
        "district_id": "827",
        "province_id": "67",
        "district_name": "คีรีรัฐนิคม"
    },
    {
        "district_id": "828",
        "province_id": "67",
        "district_name": "บ้านตาขุน"
    },
    {
        "district_id": "829",
        "province_id": "67",
        "district_name": "พนม"
    },
    {
        "district_id": "830",
        "province_id": "67",
        "district_name": "ท่าฉาง"
    },
    {
        "district_id": "831",
        "province_id": "67",
        "district_name": "บ้านนาสาร"
    },
    {
        "district_id": "832",
        "province_id": "67",
        "district_name": "บ้านนาเดิม"
    },
    {
        "district_id": "833",
        "province_id": "67",
        "district_name": "เคียนซา"
    },
    {
        "district_id": "834",
        "province_id": "67",
        "district_name": "เวียงสระ"
    },
    {
        "district_id": "835",
        "province_id": "67",
        "district_name": "พระแสง"
    },
    {
        "district_id": "836",
        "province_id": "67",
        "district_name": "พุนพิน"
    },
    {
        "district_id": "837",
        "province_id": "67",
        "district_name": "ชัยบุรี"
    },
    {
        "district_id": "838",
        "province_id": "67",
        "district_name": "วิภาวดี"
    },
    {
        "district_id": "839",
        "province_id": "67",
        "district_name": "เกาะพงัน (สาขาตำบลเกาะเต่า)"
    },
    {
        "district_id": "840",
        "province_id": "68",
        "district_name": "เมืองระนอง"
    },
    {
        "district_id": "841",
        "province_id": "68",
        "district_name": "ละอุ่น"
    },
    {
        "district_id": "842",
        "province_id": "68",
        "district_name": "กะเปอร์"
    },
    {
        "district_id": "843",
        "province_id": "68",
        "district_name": "กระบุรี"
    },
    {
        "district_id": "844",
        "province_id": "68",
        "district_name": "สุขสำราญ"
    },
    {
        "district_id": "845",
        "province_id": "69",
        "district_name": "เมืองชุมพร"
    },
    {
        "district_id": "846",
        "province_id": "69",
        "district_name": "ท่าแซะ"
    },
    {
        "district_id": "847",
        "province_id": "69",
        "district_name": "ปะทิว"
    },
    {
        "district_id": "848",
        "province_id": "69",
        "district_name": "หลังสวน"
    },
    {
        "district_id": "849",
        "province_id": "69",
        "district_name": "ละแม"
    },
    {
        "district_id": "850",
        "province_id": "69",
        "district_name": "พะโต๊ะ"
    },
    {
        "district_id": "851",
        "province_id": "69",
        "district_name": "สวี"
    },
    {
        "district_id": "852",
        "province_id": "69",
        "district_name": "ทุ่งตะโก"
    },
    {
        "district_id": "853",
        "province_id": "70",
        "district_name": "เมืองสงขลา"
    },
    {
        "district_id": "854",
        "province_id": "70",
        "district_name": "สทิงพระ"
    },
    {
        "district_id": "855",
        "province_id": "70",
        "district_name": "จะนะ"
    },
    {
        "district_id": "856",
        "province_id": "70",
        "district_name": "นาทวี"
    },
    {
        "district_id": "857",
        "province_id": "70",
        "district_name": "เทพา"
    },
    {
        "district_id": "858",
        "province_id": "70",
        "district_name": "สะบ้าย้อย"
    },
    {
        "district_id": "859",
        "province_id": "70",
        "district_name": "ระโนด"
    },
    {
        "district_id": "860",
        "province_id": "70",
        "district_name": "กระแสสินธุ์"
    },
    {
        "district_id": "861",
        "province_id": "70",
        "district_name": "รัตภูมิ"
    },
    {
        "district_id": "862",
        "province_id": "70",
        "district_name": "สะเดา"
    },
    {
        "district_id": "863",
        "province_id": "70",
        "district_name": "หาดใหญ่"
    },
    {
        "district_id": "864",
        "province_id": "70",
        "district_name": "นาหม่อม"
    },
    {
        "district_id": "865",
        "province_id": "70",
        "district_name": "ควนเนียง"
    },
    {
        "district_id": "866",
        "province_id": "70",
        "district_name": "บางกล่ำ"
    },
    {
        "district_id": "867",
        "province_id": "70",
        "district_name": "สิงหนคร"
    },
    {
        "district_id": "868",
        "province_id": "70",
        "district_name": "คลองหอยโข่ง"
    },
    {
        "district_id": "869",
        "province_id": "70",
        "district_name": "ท้องถิ่นเทศบาลตำบลสำนักขาม"
    },
    {
        "district_id": "870",
        "province_id": "71",
        "district_name": "เมืองสตูล"
    },
    {
        "district_id": "871",
        "province_id": "71",
        "district_name": "ควนโดน"
    },
    {
        "district_id": "872",
        "province_id": "71",
        "district_name": "ควนกาหลง"
    },
    {
        "district_id": "873",
        "province_id": "71",
        "district_name": "ท่าแพ"
    },
    {
        "district_id": "874",
        "province_id": "71",
        "district_name": "ละงู"
    },
    {
        "district_id": "875",
        "province_id": "71",
        "district_name": "ทุ่งหว้า"
    },
    {
        "district_id": "876",
        "province_id": "71",
        "district_name": "มะนัง"
    },
    {
        "district_id": "877",
        "province_id": "72",
        "district_name": "เมืองตรัง"
    },
    {
        "district_id": "878",
        "province_id": "72",
        "district_name": "กันตัง"
    },
    {
        "district_id": "879",
        "province_id": "72",
        "district_name": "ย่านตาขาว"
    },
    {
        "district_id": "880",
        "province_id": "72",
        "district_name": "ปะเหลียน"
    },
    {
        "district_id": "881",
        "province_id": "72",
        "district_name": "สิเกา"
    },
    {
        "district_id": "882",
        "province_id": "72",
        "district_name": "ห้วยยอด"
    },
    {
        "district_id": "883",
        "province_id": "72",
        "district_name": "วังวิเศษ"
    },
    {
        "district_id": "884",
        "province_id": "72",
        "district_name": "นาโยง"
    },
    {
        "district_id": "885",
        "province_id": "72",
        "district_name": "รัษฎา"
    },
    {
        "district_id": "886",
        "province_id": "72",
        "district_name": "หาดสำราญ"
    },
    {
        "district_id": "887",
        "province_id": "73",
        "district_name": "เมืองพัทลุง"
    },
    {
        "district_id": "888",
        "province_id": "73",
        "district_name": "กงหรา"
    },
    {
        "district_id": "889",
        "province_id": "73",
        "district_name": "เขาชัยสน"
    },
    {
        "district_id": "890",
        "province_id": "73",
        "district_name": "ตะโหมด"
    },
    {
        "district_id": "891",
        "province_id": "73",
        "district_name": "ควนขนุน"
    },
    {
        "district_id": "892",
        "province_id": "73",
        "district_name": "ปากพะยูน"
    },
    {
        "district_id": "893",
        "province_id": "73",
        "district_name": "ศรีบรรพต"
    },
    {
        "district_id": "894",
        "province_id": "73",
        "district_name": "ป่าบอน"
    },
    {
        "district_id": "895",
        "province_id": "73",
        "district_name": "บางแก้ว"
    },
    {
        "district_id": "896",
        "province_id": "73",
        "district_name": "ป่าพะยอม"
    },
    {
        "district_id": "897",
        "province_id": "73",
        "district_name": "ศรีนครินทร์"
    },
    {
        "district_id": "898",
        "province_id": "74",
        "district_name": "เมืองปัตตานี"
    },
    {
        "district_id": "899",
        "province_id": "74",
        "district_name": "โคกโพธิ์"
    },
    {
        "district_id": "900",
        "province_id": "74",
        "district_name": "หนองจิก"
    },
    {
        "district_id": "901",
        "province_id": "74",
        "district_name": "ปะนาเระ"
    },
    {
        "district_id": "902",
        "province_id": "74",
        "district_name": "มายอ"
    },
    {
        "district_id": "903",
        "province_id": "74",
        "district_name": "ทุ่งยางแดง"
    },
    {
        "district_id": "904",
        "province_id": "74",
        "district_name": "สายบุรี"
    },
    {
        "district_id": "905",
        "province_id": "74",
        "district_name": "ไม้แก่น"
    },
    {
        "district_id": "906",
        "province_id": "74",
        "district_name": "ยะหริ่ง"
    },
    {
        "district_id": "907",
        "province_id": "74",
        "district_name": "ยะรัง"
    },
    {
        "district_id": "908",
        "province_id": "74",
        "district_name": "กะพ้อ"
    },
    {
        "district_id": "909",
        "province_id": "74",
        "district_name": "แม่ลาน"
    },
    {
        "district_id": "910",
        "province_id": "75",
        "district_name": "เมืองยะลา"
    },
    {
        "district_id": "911",
        "province_id": "75",
        "district_name": "เบตง"
    },
    {
        "district_id": "912",
        "province_id": "75",
        "district_name": "บันนังสตา"
    },
    {
        "district_id": "913",
        "province_id": "75",
        "district_name": "ธารโต"
    },
    {
        "district_id": "914",
        "province_id": "75",
        "district_name": "ยะหา"
    },
    {
        "district_id": "915",
        "province_id": "75",
        "district_name": "รามัน"
    },
    {
        "district_id": "916",
        "province_id": "75",
        "district_name": "กาบัง"
    },
    {
        "district_id": "917",
        "province_id": "75",
        "district_name": "กรงปินัง"
    },
    {
        "district_id": "918",
        "province_id": "76",
        "district_name": "เมืองนราธิวาส"
    },
    {
        "district_id": "919",
        "province_id": "76",
        "district_name": "ตากใบ"
    },
    {
        "district_id": "920",
        "province_id": "76",
        "district_name": "บาเจาะ"
    },
    {
        "district_id": "921",
        "province_id": "76",
        "district_name": "ยี่งอ"
    },
    {
        "district_id": "922",
        "province_id": "76",
        "district_name": "ระแงะ"
    },
    {
        "district_id": "923",
        "province_id": "76",
        "district_name": "รือเสาะ"
    },
    {
        "district_id": "924",
        "province_id": "76",
        "district_name": "ศรีสาคร"
    },
    {
        "district_id": "925",
        "province_id": "76",
        "district_name": "แว้ง"
    },
    {
        "district_id": "926",
        "province_id": "76",
        "district_name": "สุคิริน"
    },
    {
        "district_id": "927",
        "province_id": "76",
        "district_name": "สุไหงโก-ลก"
    },
    {
        "district_id": "928",
        "province_id": "76",
        "district_name": "สุไหงปาดี"
    },
    {
        "district_id": "929",
        "province_id": "76",
        "district_name": "จะแนะ"
    },
    {
        "district_id": "930",
        "province_id": "76",
        "district_name": "เจาะไอร้อง"
    },
    {
        "district_id": "931",
        "province_id": "38",
        "district_name": "กัลยาณิวัฒนา"
    },
    {
        "district_id": "932",
        "province_id": "77",
        "district_name": "เซกา"
    },
    {
        "district_id": "933",
        "province_id": "77",
        "district_name": "โซ่พิสัย"
    },
    {
        "district_id": "934",
        "province_id": "77",
        "district_name": "บึงโขงหลง"
    },
    {
        "district_id": "935",
        "province_id": "77",
        "district_name": "บุ่งคล้า"
    },
    {
        "district_id": "936",
        "province_id": "22",
        "district_name": "เบญจลักษ์"
    },
    {
        "district_id": "937",
        "province_id": "77",
        "district_name": "ปากคาด"
    },
    {
        "district_id": "938",
        "province_id": "77",
        "district_name": "พรเจริญ"
    },
    {
        "district_id": "939",
        "province_id": "77",
        "district_name": "เมืองบึงกาฬ"
    },
    {
        "district_id": "940",
        "province_id": "77",
        "district_name": "ศรีวิไล"
    },
    {
        "district_id": "941",
        "province_id": "21",
        "district_name": "ศีขรภูมิ"
    },
    {
        "district_id": "942",
        "province_id": "15",
        "district_name": "สนามชัย"
    },
    {
        "district_id": "943",
        "province_id": "1",
        "district_name": "สำนักทะเบียนกลาง"
    },
    {
        "district_id": "944",
        "province_id": "19",
        "district_name": "หนองบุญมาก"
    },
    {
        "district_id": "945",
        "province_id": "19",
        "district_name": "หนองบุนนาก"
    }
]