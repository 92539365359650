import Vue from "vue";
import Vuetify from "vuetify/lib";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        background: "white",
        primary: "#0d4ca0",
      },
    },
  },
  icons: {
    iconfont: "mdi", // default - only for display purposes
  },
});
