<template>
  <div id="containerbarcode">
    <div class="bgdiv custom-font">
      <div style="height: 4px; background: #00bbf3"></div>
      <v-container style="color: white;">
        <div style="font-weight: 100" class="custom-font">ชำระเงินผ่านบาร์โค้ด</div>
        <h2 class="custom-font" style="font-size: 22px;letter-spacing: 1px;">Barcode</h2>
      </v-container>
    </div>
    <div style="padding-top: 10px;background-color: #fff;width: 100%;height: 100%;">
      <div class="contentBox">
        <div>
          <div style="text-align: center" class="pb-2">
            <div>Barcode สำหรับชำระ{{barcodetype}}</div>
            <div>ของเลขที่สัญญา {{query.contractno}}</div>
            <div style="margin-top:0.9em">
              <barcode :text="query.barcodetext" height="60" width="1" v-bind:value="mybarcode"></barcode>
            </div>
            <div style="margin-top: 0.2em;">
              <v-btn @click="close" rounded large class="mybtn">close</v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from "vue-barcode";
import queryString from "query-string";

export default {
  data() {
    return {
      query: "",
      data: {
        barcodetype: "ค่าประกัน",
        contractno: "0501120026306"
      }
    };
  },
  mounted() {
    this.queryinit();
  },
  components: {
    barcode: VueBarcode
  },
  computed: {
    mybarcode() {
      if (this.query && this.query.barcodetext) {
        return this.query.barcodetext.replace(/\s/g, "\n");
      } else {
        return "";
      }
    },
    barcodetype() {
      var type;
      switch (this.query.barcodetype) {
        case "intallment":
          type = "ค่างวด";
          break;
        case "insurance":
          type = "ค่าประกัน";
          break;
        case "registration":
          type = "งานทะเบียน";
          break;
      }
      return type;
    },
    userid() {
      if (this.query.apptype == "line") {
        return this.$store.state.profile.userId;
      } else {
        return this.query.userid;
      }
    },
    transid() {
      var d = new Date();
      var day = d.getDate() + "";
      var month = d.getMonth() + 1 + "";
      var year = d.getFullYear() + "";
      var hour = d.getHours() + "";
      var minute = d.getMinutes() + "";
      var second = d.getSeconds() + "";
      var mills = d.getMilliseconds() + "";
      var defaultFill = "00";

      mills = (defaultFill + mills).substr(mills.length);

      if (day.length < 2) day = "0" + day;

      if (month.length < 2) month = "0" + month;

      if (hour.length < 2) hour = "0" + hour;

      if (minute.length < 2) minute = "0" + minute;

      if (second.length < 2) second = "0" + second;

      return year + month + day + hour + minute + second + mills;
    }
  },
  methods: {
    close() {
      let vm = this;
      if (vm.query.apptype == "line") {
        vm.$liff.closeWindow();
      } else if (vm.query.apptype == "facebook") {
        MessengerExtensions.requestCloseBrowser(
          function success() {},
          function error() {
            window.close();
          }
        );
      }
    },
    queryinit() {
      var vm = this;
      vm.query = vm.$route.query["liff.state"]
        ? queryString.parse(vm.$route.query["liff.state"])
        : vm.$route.query;

      if (vm.query.pageid) vm.pageid = vm.query.pageid;
    }
  }
};
</script>

<style lang="scss">
#container {
  display: block;
}

@media screen and (max-width: 600px) {
  #containerbarcode {
    display: block;
    transform: rotate(90deg);
    transform-origin: bottom left;
    position: absolute;
    top: -100vw;
    left: 0;

    height: 100vw;
    width: 100vh;

    background-color: #000;
    // color: #fff;

    // overflow: hidden;
  }
}
</style>