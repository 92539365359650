import axios from 'axios'

const instance = () => {
    return axios.create({
    baseURL: `${process.env.VUE_APP_SUMMIT_SERVER_URL}api`,
    timeout: 5000,
    })
}

const prescreenApi = {
    getProvince: (request) => {
        const { data } = request
        return instance().post(`/MasterData/Getprovince`, data)
    },
    getDistrict: (request) => {
        const { data } = request
        return instance().post(`/MasterData/Getdistrict`, data)
    },
    getSubdistrict: (request) => {
        const { data } = request
        return instance().post(`/MasterData/Getsubdistrict`, data)
    },
    getZipcode: (request) => {
        const { data } = request
        return instance().post(`/MasterData/Getzipcode`, data)
    },
    getBrand: (request) => {
        const { data } = request
        return instance().post(`/MasterData/GetBrand`, data)
    },
    getModel: (request) => {
        const { data } = request
        return instance().post(`/MasterData/GetModel`, data)
    }
}

export default prescreenApi
