export default [
    {
        "subdistrict_id": "1",
        "district_id": "1",
        "subdistrict_name": "พระบรมมหาราชวัง"
    },
    {
        "subdistrict_id": "2",
        "district_id": "1",
        "subdistrict_name": "วังบูรพาภิรมย์"
    },
    {
        "subdistrict_id": "3",
        "district_id": "1",
        "subdistrict_name": "วัดราชบพิธ"
    },
    {
        "subdistrict_id": "4",
        "district_id": "1",
        "subdistrict_name": "สำราญราษฎร์"
    },
    {
        "subdistrict_id": "5",
        "district_id": "1",
        "subdistrict_name": "ศาลเจ้าพ่อเสือ"
    },
    {
        "subdistrict_id": "6",
        "district_id": "1",
        "subdistrict_name": "เสาชิงช้า"
    },
    {
        "subdistrict_id": "7",
        "district_id": "1",
        "subdistrict_name": "บวรนิเวศ"
    },
    {
        "subdistrict_id": "8",
        "district_id": "1",
        "subdistrict_name": "ตลาดยอด"
    },
    {
        "subdistrict_id": "9",
        "district_id": "1",
        "subdistrict_name": "ชนะสงคราม"
    },
    {
        "subdistrict_id": "10",
        "district_id": "1",
        "subdistrict_name": "บ้านพานถม"
    },
    {
        "subdistrict_id": "11",
        "district_id": "1",
        "subdistrict_name": "บางขุนพรหม"
    },
    {
        "subdistrict_id": "12",
        "district_id": "1",
        "subdistrict_name": "วัดสามพระยา"
    },
    {
        "subdistrict_id": "13",
        "district_id": "2",
        "subdistrict_name": "ดุสิต"
    },
    {
        "subdistrict_id": "14",
        "district_id": "2",
        "subdistrict_name": "วชิรพยาบาล"
    },
    {
        "subdistrict_id": "15",
        "district_id": "2",
        "subdistrict_name": "สวนจิตรลดา"
    },
    {
        "subdistrict_id": "16",
        "district_id": "2",
        "subdistrict_name": "สี่แยกมหานาค"
    },
    {
        "subdistrict_id": "17",
        "district_id": "2",
        "subdistrict_name": "ถนนนครไชยศรี"
    },
    {
        "subdistrict_id": "18",
        "district_id": "3",
        "subdistrict_name": "กระทุ่มราย"
    },
    {
        "subdistrict_id": "19",
        "district_id": "3",
        "subdistrict_name": "หนองจอก"
    },
    {
        "subdistrict_id": "20",
        "district_id": "3",
        "subdistrict_name": "คลองสิบ"
    },
    {
        "subdistrict_id": "21",
        "district_id": "3",
        "subdistrict_name": "คลองสิบสอง"
    },
    {
        "subdistrict_id": "22",
        "district_id": "3",
        "subdistrict_name": "โคกแฝด"
    },
    {
        "subdistrict_id": "23",
        "district_id": "3",
        "subdistrict_name": "คู้ฝั่งเหนือ"
    },
    {
        "subdistrict_id": "24",
        "district_id": "3",
        "subdistrict_name": "ลำผักชี"
    },
    {
        "subdistrict_id": "25",
        "district_id": "3",
        "subdistrict_name": "ลำต้อยติ่ง"
    },
    {
        "subdistrict_id": "26",
        "district_id": "4",
        "subdistrict_name": "มหาพฤฒาราม"
    },
    {
        "subdistrict_id": "27",
        "district_id": "4",
        "subdistrict_name": "สีลม"
    },
    {
        "subdistrict_id": "28",
        "district_id": "4",
        "subdistrict_name": "สุริยวงศ์"
    },
    {
        "subdistrict_id": "29",
        "district_id": "4",
        "subdistrict_name": "บางรัก"
    },
    {
        "subdistrict_id": "30",
        "district_id": "4",
        "subdistrict_name": "สี่พระยา"
    },
    {
        "subdistrict_id": "31",
        "district_id": "5",
        "subdistrict_name": "อนุสาวรีย์"
    },
    {
        "subdistrict_id": "32",
        "district_id": "5",
        "subdistrict_name": "สายไหม"
    },
    {
        "subdistrict_id": "33",
        "district_id": "5",
        "subdistrict_name": "ท่าแร้ง"
    },
    {
        "subdistrict_id": "34",
        "district_id": "6",
        "subdistrict_name": "คลองจั่น"
    },
    {
        "subdistrict_id": "35",
        "district_id": "6",
        "subdistrict_name": "ลาดพร้าว"
    },
    {
        "subdistrict_id": "36",
        "district_id": "6",
        "subdistrict_name": "หัวหมาก"
    },
    {
        "subdistrict_id": "37",
        "district_id": "7",
        "subdistrict_name": "รองเมือง"
    },
    {
        "subdistrict_id": "38",
        "district_id": "7",
        "subdistrict_name": "วังใหม่"
    },
    {
        "subdistrict_id": "39",
        "district_id": "7",
        "subdistrict_name": "ปทุมวัน"
    },
    {
        "subdistrict_id": "40",
        "district_id": "7",
        "subdistrict_name": "ลุมพินี"
    },
    {
        "subdistrict_id": "41",
        "district_id": "8",
        "subdistrict_name": "ป้อมปราบ"
    },
    {
        "subdistrict_id": "42",
        "district_id": "8",
        "subdistrict_name": "วัดเทพศิรินทร์"
    },
    {
        "subdistrict_id": "43",
        "district_id": "8",
        "subdistrict_name": "คลองมหานาค"
    },
    {
        "subdistrict_id": "44",
        "district_id": "8",
        "subdistrict_name": "บ้านบาตร"
    },
    {
        "subdistrict_id": "45",
        "district_id": "8",
        "subdistrict_name": "วัดโสมนัส"
    },
    {
        "subdistrict_id": "46",
        "district_id": "9",
        "subdistrict_name": "บางนา"
    },
    {
        "subdistrict_id": "47",
        "district_id": "9",
        "subdistrict_name": "บางจาก"
    },
    {
        "subdistrict_id": "48",
        "district_id": "9",
        "subdistrict_name": "พระโขนง"
    },
    {
        "subdistrict_id": "49",
        "district_id": "10",
        "subdistrict_name": "มีนบุรี"
    },
    {
        "subdistrict_id": "50",
        "district_id": "10",
        "subdistrict_name": "แสนแสบ"
    },
    {
        "subdistrict_id": "51",
        "district_id": "10",
        "subdistrict_name": "บางชัน"
    },
    {
        "subdistrict_id": "52",
        "district_id": "11",
        "subdistrict_name": "ลาดกระบัง"
    },
    {
        "subdistrict_id": "53",
        "district_id": "11",
        "subdistrict_name": "คลองสองต้นนุ่น"
    },
    {
        "subdistrict_id": "54",
        "district_id": "11",
        "subdistrict_name": "คลองสามประเวศ"
    },
    {
        "subdistrict_id": "55",
        "district_id": "11",
        "subdistrict_name": "ลำปลาทิว"
    },
    {
        "subdistrict_id": "56",
        "district_id": "11",
        "subdistrict_name": "ทับยาว"
    },
    {
        "subdistrict_id": "57",
        "district_id": "11",
        "subdistrict_name": "ขุมทอง"
    },
    {
        "subdistrict_id": "58",
        "district_id": "12",
        "subdistrict_name": "ช่องนนทรี"
    },
    {
        "subdistrict_id": "59",
        "district_id": "12",
        "subdistrict_name": "บางโพงพาง"
    },
    {
        "subdistrict_id": "60",
        "district_id": "13",
        "subdistrict_name": "จักรวรรดิ"
    },
    {
        "subdistrict_id": "61",
        "district_id": "13",
        "subdistrict_name": "สัมพันธวงศ์"
    },
    {
        "subdistrict_id": "62",
        "district_id": "13",
        "subdistrict_name": "ตลาดน้อย"
    },
    {
        "subdistrict_id": "63",
        "district_id": "14",
        "subdistrict_name": "สามเสนใน"
    },
    {
        "subdistrict_id": "64",
        "district_id": "15",
        "subdistrict_name": "วัดกัลยาณ์"
    },
    {
        "subdistrict_id": "65",
        "district_id": "15",
        "subdistrict_name": "หิรัญรูจี"
    },
    {
        "subdistrict_id": "66",
        "district_id": "15",
        "subdistrict_name": "บางยี่เรือ"
    },
    {
        "subdistrict_id": "67",
        "district_id": "15",
        "subdistrict_name": "บุคคโล"
    },
    {
        "subdistrict_id": "68",
        "district_id": "15",
        "subdistrict_name": "ตลาดพลู"
    },
    {
        "subdistrict_id": "69",
        "district_id": "15",
        "subdistrict_name": "ดาวคะนอง"
    },
    {
        "subdistrict_id": "70",
        "district_id": "15",
        "subdistrict_name": "สำเหร่"
    },
    {
        "subdistrict_id": "71",
        "district_id": "16",
        "subdistrict_name": "วัดอรุณ"
    },
    {
        "subdistrict_id": "72",
        "district_id": "16",
        "subdistrict_name": "วัดท่าพระ"
    },
    {
        "subdistrict_id": "73",
        "district_id": "17",
        "subdistrict_name": "ห้วยขวาง"
    },
    {
        "subdistrict_id": "74",
        "district_id": "17",
        "subdistrict_name": "บางกะปิ"
    },
    {
        "subdistrict_id": "75",
        "district_id": "17",
        "subdistrict_name": "สามเสนนอก"
    },
    {
        "subdistrict_id": "76",
        "district_id": "18",
        "subdistrict_name": "สมเด็จเจ้าพระยา"
    },
    {
        "subdistrict_id": "77",
        "district_id": "18",
        "subdistrict_name": "คลองสาน"
    },
    {
        "subdistrict_id": "78",
        "district_id": "18",
        "subdistrict_name": "บางลำภูล่าง"
    },
    {
        "subdistrict_id": "79",
        "district_id": "18",
        "subdistrict_name": "คลองต้นไทร"
    },
    {
        "subdistrict_id": "80",
        "district_id": "19",
        "subdistrict_name": "คลองชักพระ"
    },
    {
        "subdistrict_id": "81",
        "district_id": "19",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "82",
        "district_id": "19",
        "subdistrict_name": "ฉิมพลี"
    },
    {
        "subdistrict_id": "83",
        "district_id": "19",
        "subdistrict_name": "บางพรม"
    },
    {
        "subdistrict_id": "84",
        "district_id": "19",
        "subdistrict_name": "บางระมาด"
    },
    {
        "subdistrict_id": "85",
        "district_id": "19",
        "subdistrict_name": "บางเชือกหนัง"
    },
    {
        "subdistrict_id": "86",
        "district_id": "20",
        "subdistrict_name": "ศิริราช"
    },
    {
        "subdistrict_id": "87",
        "district_id": "20",
        "subdistrict_name": "บ้านช่างหล่อ"
    },
    {
        "subdistrict_id": "88",
        "district_id": "20",
        "subdistrict_name": "บางขุนนนท์"
    },
    {
        "subdistrict_id": "89",
        "district_id": "20",
        "subdistrict_name": "บางขุนศรี"
    },
    {
        "subdistrict_id": "90",
        "district_id": "20",
        "subdistrict_name": "อรุณอมรินทร์"
    },
    {
        "subdistrict_id": "91",
        "district_id": "21",
        "subdistrict_name": "จอมทอง"
    },
    {
        "subdistrict_id": "92",
        "district_id": "21",
        "subdistrict_name": "บางบอน"
    },
    {
        "subdistrict_id": "93",
        "district_id": "21",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "94",
        "district_id": "21",
        "subdistrict_name": "แสมดำ"
    },
    {
        "subdistrict_id": "95",
        "district_id": "22",
        "subdistrict_name": "บางหว้า"
    },
    {
        "subdistrict_id": "96",
        "district_id": "22",
        "subdistrict_name": "บางด้วน"
    },
    {
        "subdistrict_id": "97",
        "district_id": "22",
        "subdistrict_name": "บางแค"
    },
    {
        "subdistrict_id": "98",
        "district_id": "22",
        "subdistrict_name": "บางแคเหนือ"
    },
    {
        "subdistrict_id": "99",
        "district_id": "22",
        "subdistrict_name": "บางไผ่"
    },
    {
        "subdistrict_id": "100",
        "district_id": "22",
        "subdistrict_name": "บางจาก"
    },
    {
        "subdistrict_id": "101",
        "district_id": "22",
        "subdistrict_name": "บางแวก"
    },
    {
        "subdistrict_id": "102",
        "district_id": "22",
        "subdistrict_name": "คลองขวาง"
    },
    {
        "subdistrict_id": "103",
        "district_id": "22",
        "subdistrict_name": "ปากคลองภาษีเจริญ"
    },
    {
        "subdistrict_id": "104",
        "district_id": "22",
        "subdistrict_name": "คูหาสวรรค์"
    },
    {
        "subdistrict_id": "105",
        "district_id": "23",
        "subdistrict_name": "หนองแขม"
    },
    {
        "subdistrict_id": "106",
        "district_id": "23",
        "subdistrict_name": "หนองค้างพลู"
    },
    {
        "subdistrict_id": "107",
        "district_id": "24",
        "subdistrict_name": "ราษฎร์บูรณะ"
    },
    {
        "subdistrict_id": "108",
        "district_id": "24",
        "subdistrict_name": "บางปะกอก"
    },
    {
        "subdistrict_id": "109",
        "district_id": "25",
        "subdistrict_name": "บางพลัด"
    },
    {
        "subdistrict_id": "110",
        "district_id": "25",
        "subdistrict_name": "บางอ้อ"
    },
    {
        "subdistrict_id": "111",
        "district_id": "25",
        "subdistrict_name": "บางบำหรุ"
    },
    {
        "subdistrict_id": "112",
        "district_id": "25",
        "subdistrict_name": "บางยี่ขัน"
    },
    {
        "subdistrict_id": "113",
        "district_id": "26",
        "subdistrict_name": "ดินแดง"
    },
    {
        "subdistrict_id": "114",
        "district_id": "27",
        "subdistrict_name": "คลองกุ่ม"
    },
    {
        "subdistrict_id": "115",
        "district_id": "27",
        "subdistrict_name": "สะพานสูง"
    },
    {
        "subdistrict_id": "116",
        "district_id": "27",
        "subdistrict_name": "คันนายาว"
    },
    {
        "subdistrict_id": "117",
        "district_id": "28",
        "subdistrict_name": "ทุ่งวัดดอน"
    },
    {
        "subdistrict_id": "118",
        "district_id": "28",
        "subdistrict_name": "ยานนาวา"
    },
    {
        "subdistrict_id": "119",
        "district_id": "28",
        "subdistrict_name": "ทุ่งมหาเมฆ"
    },
    {
        "subdistrict_id": "120",
        "district_id": "29",
        "subdistrict_name": "บางซื่อ"
    },
    {
        "subdistrict_id": "121",
        "district_id": "30",
        "subdistrict_name": "ลาดยาว"
    },
    {
        "subdistrict_id": "122",
        "district_id": "30",
        "subdistrict_name": "เสนานิคม"
    },
    {
        "subdistrict_id": "123",
        "district_id": "30",
        "subdistrict_name": "จันทรเกษม"
    },
    {
        "subdistrict_id": "124",
        "district_id": "30",
        "subdistrict_name": "จอมพล"
    },
    {
        "subdistrict_id": "125",
        "district_id": "30",
        "subdistrict_name": "จตุจักร"
    },
    {
        "subdistrict_id": "126",
        "district_id": "31",
        "subdistrict_name": "บางคอแหลม"
    },
    {
        "subdistrict_id": "127",
        "district_id": "31",
        "subdistrict_name": "วัดพระยาไกร"
    },
    {
        "subdistrict_id": "128",
        "district_id": "31",
        "subdistrict_name": "บางโคล่"
    },
    {
        "subdistrict_id": "129",
        "district_id": "32",
        "subdistrict_name": "ประเวศ"
    },
    {
        "subdistrict_id": "130",
        "district_id": "32",
        "subdistrict_name": "หนองบอน"
    },
    {
        "subdistrict_id": "131",
        "district_id": "32",
        "subdistrict_name": "ดอกไม้"
    },
    {
        "subdistrict_id": "132",
        "district_id": "32",
        "subdistrict_name": "สวนหลวง"
    },
    {
        "subdistrict_id": "133",
        "district_id": "33",
        "subdistrict_name": "คลองเตย"
    },
    {
        "subdistrict_id": "134",
        "district_id": "33",
        "subdistrict_name": "คลองตัน"
    },
    {
        "subdistrict_id": "135",
        "district_id": "33",
        "subdistrict_name": "พระโขนง"
    },
    {
        "subdistrict_id": "136",
        "district_id": "33",
        "subdistrict_name": "คลองเตยเหนือ"
    },
    {
        "subdistrict_id": "137",
        "district_id": "33",
        "subdistrict_name": "คลองตันเหนือ"
    },
    {
        "subdistrict_id": "138",
        "district_id": "33",
        "subdistrict_name": "พระโขนงเหนือ"
    },
    {
        "subdistrict_id": "139",
        "district_id": "34",
        "subdistrict_name": "สวนหลวง"
    },
    {
        "subdistrict_id": "140",
        "district_id": "35",
        "subdistrict_name": "บางขุนเทียน"
    },
    {
        "subdistrict_id": "141",
        "district_id": "35",
        "subdistrict_name": "บางค้อ"
    },
    {
        "subdistrict_id": "142",
        "district_id": "35",
        "subdistrict_name": "บางมด"
    },
    {
        "subdistrict_id": "143",
        "district_id": "35",
        "subdistrict_name": "จอมทอง"
    },
    {
        "subdistrict_id": "144",
        "district_id": "36",
        "subdistrict_name": "สีกัน"
    },
    {
        "subdistrict_id": "145",
        "district_id": "37",
        "subdistrict_name": "ทุ่งพญาไท"
    },
    {
        "subdistrict_id": "146",
        "district_id": "37",
        "subdistrict_name": "ถนนพญาไท"
    },
    {
        "subdistrict_id": "147",
        "district_id": "37",
        "subdistrict_name": "ถนนเพชรบุรี"
    },
    {
        "subdistrict_id": "148",
        "district_id": "37",
        "subdistrict_name": "มักกะสัน"
    },
    {
        "subdistrict_id": "149",
        "district_id": "38",
        "subdistrict_name": "ลาดพร้าว"
    },
    {
        "subdistrict_id": "150",
        "district_id": "38",
        "subdistrict_name": "จรเข้บัว"
    },
    {
        "subdistrict_id": "151",
        "district_id": "39",
        "subdistrict_name": "คลองเตยเหนือ"
    },
    {
        "subdistrict_id": "152",
        "district_id": "39",
        "subdistrict_name": "คลองตันเหนือ"
    },
    {
        "subdistrict_id": "153",
        "district_id": "39",
        "subdistrict_name": "พระโขนงเหนือ"
    },
    {
        "subdistrict_id": "154",
        "district_id": "40",
        "subdistrict_name": "บางแค"
    },
    {
        "subdistrict_id": "155",
        "district_id": "40",
        "subdistrict_name": "บางแคเหนือ"
    },
    {
        "subdistrict_id": "156",
        "district_id": "40",
        "subdistrict_name": "บางไผ่"
    },
    {
        "subdistrict_id": "157",
        "district_id": "40",
        "subdistrict_name": "หลักสอง"
    },
    {
        "subdistrict_id": "158",
        "district_id": "41",
        "subdistrict_name": "ทุ่งสองห้อง"
    },
    {
        "subdistrict_id": "159",
        "district_id": "41",
        "subdistrict_name": "ตลาดบางเขน"
    },
    {
        "subdistrict_id": "160",
        "district_id": "42",
        "subdistrict_name": "สายไหม"
    },
    {
        "subdistrict_id": "161",
        "district_id": "42",
        "subdistrict_name": "ออเงิน"
    },
    {
        "subdistrict_id": "162",
        "district_id": "42",
        "subdistrict_name": "คลองถนน"
    },
    {
        "subdistrict_id": "163",
        "district_id": "43",
        "subdistrict_name": "คันนายาว"
    },
    {
        "subdistrict_id": "164",
        "district_id": "44",
        "subdistrict_name": "สะพานสูง"
    },
    {
        "subdistrict_id": "165",
        "district_id": "45",
        "subdistrict_name": "วังทองหลาง"
    },
    {
        "subdistrict_id": "166",
        "district_id": "46",
        "subdistrict_name": "สามวาตะวันตก"
    },
    {
        "subdistrict_id": "167",
        "district_id": "46",
        "subdistrict_name": "สามวาตะวันออก"
    },
    {
        "subdistrict_id": "168",
        "district_id": "46",
        "subdistrict_name": "บางชัน"
    },
    {
        "subdistrict_id": "169",
        "district_id": "46",
        "subdistrict_name": "ทรายกองดิน"
    },
    {
        "subdistrict_id": "170",
        "district_id": "46",
        "subdistrict_name": "ทรายกองดินใต้"
    },
    {
        "subdistrict_id": "171",
        "district_id": "47",
        "subdistrict_name": "บางนา"
    },
    {
        "subdistrict_id": "172",
        "district_id": "48",
        "subdistrict_name": "ทวีวัฒนา"
    },
    {
        "subdistrict_id": "173",
        "district_id": "48",
        "subdistrict_name": "ศาลาธรรมสพน์"
    },
    {
        "subdistrict_id": "174",
        "district_id": "49",
        "subdistrict_name": "บางมด"
    },
    {
        "subdistrict_id": "175",
        "district_id": "49",
        "subdistrict_name": "ทุ่งครุ"
    },
    {
        "subdistrict_id": "176",
        "district_id": "50",
        "subdistrict_name": "บางบอน"
    },
    {
        "subdistrict_id": "177",
        "district_id": "51",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "178",
        "district_id": "51",
        "subdistrict_name": "สำโรงเหนือ"
    },
    {
        "subdistrict_id": "179",
        "district_id": "51",
        "subdistrict_name": "บางเมือง"
    },
    {
        "subdistrict_id": "180",
        "district_id": "51",
        "subdistrict_name": "ท้ายบ้าน"
    },
    {
        "subdistrict_id": "181",
        "district_id": "51",
        "subdistrict_name": "บางปูใหม่"
    },
    {
        "subdistrict_id": "182",
        "district_id": "51",
        "subdistrict_name": "แพรกษา"
    },
    {
        "subdistrict_id": "183",
        "district_id": "51",
        "subdistrict_name": "บางโปรง"
    },
    {
        "subdistrict_id": "184",
        "district_id": "51",
        "subdistrict_name": "บางปู"
    },
    {
        "subdistrict_id": "185",
        "district_id": "51",
        "subdistrict_name": "บางด้วน"
    },
    {
        "subdistrict_id": "186",
        "district_id": "51",
        "subdistrict_name": "บางเมืองใหม่"
    },
    {
        "subdistrict_id": "187",
        "district_id": "51",
        "subdistrict_name": "เทพารักษ์"
    },
    {
        "subdistrict_id": "188",
        "district_id": "51",
        "subdistrict_name": "ท้ายบ้านใหม่"
    },
    {
        "subdistrict_id": "189",
        "district_id": "51",
        "subdistrict_name": "แพรกษาใหม่"
    },
    {
        "subdistrict_id": "190",
        "district_id": "52",
        "subdistrict_name": "บางบ่อ"
    },
    {
        "subdistrict_id": "191",
        "district_id": "52",
        "subdistrict_name": "บ้านระกาศ"
    },
    {
        "subdistrict_id": "192",
        "district_id": "52",
        "subdistrict_name": "บางพลีน้อย"
    },
    {
        "subdistrict_id": "193",
        "district_id": "52",
        "subdistrict_name": "บางเพรียง"
    },
    {
        "subdistrict_id": "194",
        "district_id": "52",
        "subdistrict_name": "คลองด่าน"
    },
    {
        "subdistrict_id": "195",
        "district_id": "52",
        "subdistrict_name": "คลองสวน"
    },
    {
        "subdistrict_id": "196",
        "district_id": "52",
        "subdistrict_name": "เปร็ง"
    },
    {
        "subdistrict_id": "197",
        "district_id": "52",
        "subdistrict_name": "คลองนิยมยาตรา"
    },
    {
        "subdistrict_id": "198",
        "district_id": "53",
        "subdistrict_name": "บางพลีใหญ่"
    },
    {
        "subdistrict_id": "199",
        "district_id": "53",
        "subdistrict_name": "บางแก้ว"
    },
    {
        "subdistrict_id": "200",
        "district_id": "53",
        "subdistrict_name": "บางปลา"
    },
    {
        "subdistrict_id": "201",
        "district_id": "53",
        "subdistrict_name": "บางโฉลง"
    },
    {
        "subdistrict_id": "202",
        "district_id": "53",
        "subdistrict_name": "ราชาเทวะ"
    },
    {
        "subdistrict_id": "203",
        "district_id": "53",
        "subdistrict_name": "หนองปรือ"
    },
    {
        "subdistrict_id": "204",
        "district_id": "54",
        "subdistrict_name": "ตลาด"
    },
    {
        "subdistrict_id": "205",
        "district_id": "54",
        "subdistrict_name": "บางพึ่ง"
    },
    {
        "subdistrict_id": "206",
        "district_id": "54",
        "subdistrict_name": "บางจาก"
    },
    {
        "subdistrict_id": "207",
        "district_id": "54",
        "subdistrict_name": "บางครุ"
    },
    {
        "subdistrict_id": "208",
        "district_id": "54",
        "subdistrict_name": "บางหญ้าแพรก"
    },
    {
        "subdistrict_id": "209",
        "district_id": "54",
        "subdistrict_name": "บางหัวเสือ"
    },
    {
        "subdistrict_id": "210",
        "district_id": "54",
        "subdistrict_name": "สำโรงใต้"
    },
    {
        "subdistrict_id": "211",
        "district_id": "54",
        "subdistrict_name": "บางยอ"
    },
    {
        "subdistrict_id": "212",
        "district_id": "54",
        "subdistrict_name": "บางกะเจ้า"
    },
    {
        "subdistrict_id": "213",
        "district_id": "54",
        "subdistrict_name": "บางน้ำผึ้ง"
    },
    {
        "subdistrict_id": "214",
        "district_id": "54",
        "subdistrict_name": "บางกระสอบ"
    },
    {
        "subdistrict_id": "215",
        "district_id": "54",
        "subdistrict_name": "บางกอบัว"
    },
    {
        "subdistrict_id": "216",
        "district_id": "54",
        "subdistrict_name": "ทรงคนอง"
    },
    {
        "subdistrict_id": "217",
        "district_id": "54",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "218",
        "district_id": "54",
        "subdistrict_name": "สำโรงกลาง"
    },
    {
        "subdistrict_id": "219",
        "district_id": "55",
        "subdistrict_name": "นาเกลือ"
    },
    {
        "subdistrict_id": "220",
        "district_id": "55",
        "subdistrict_name": "บ้านคลองสวน"
    },
    {
        "subdistrict_id": "221",
        "district_id": "55",
        "subdistrict_name": "แหลมฟ้าผ่า"
    },
    {
        "subdistrict_id": "222",
        "district_id": "55",
        "subdistrict_name": "ปากคลองบางปลากด"
    },
    {
        "subdistrict_id": "223",
        "district_id": "55",
        "subdistrict_name": "ในคลองบางปลากด"
    },
    {
        "subdistrict_id": "224",
        "district_id": "56",
        "subdistrict_name": "บางเสาธง"
    },
    {
        "subdistrict_id": "225",
        "district_id": "56",
        "subdistrict_name": "ศีรษะจรเข้น้อย"
    },
    {
        "subdistrict_id": "226",
        "district_id": "56",
        "subdistrict_name": "ศีรษะจรเข้ใหญ่"
    },
    {
        "subdistrict_id": "227",
        "district_id": "57",
        "subdistrict_name": "สวนใหญ่"
    },
    {
        "subdistrict_id": "228",
        "district_id": "57",
        "subdistrict_name": "ตลาดขวัญ"
    },
    {
        "subdistrict_id": "229",
        "district_id": "57",
        "subdistrict_name": "บางเขน"
    },
    {
        "subdistrict_id": "230",
        "district_id": "57",
        "subdistrict_name": "บางกระสอ"
    },
    {
        "subdistrict_id": "231",
        "district_id": "57",
        "subdistrict_name": "ท่าทราย"
    },
    {
        "subdistrict_id": "232",
        "district_id": "57",
        "subdistrict_name": "บางไผ่"
    },
    {
        "subdistrict_id": "233",
        "district_id": "57",
        "subdistrict_name": "บางศรีเมือง"
    },
    {
        "subdistrict_id": "234",
        "district_id": "57",
        "subdistrict_name": "บางกร่าง"
    },
    {
        "subdistrict_id": "235",
        "district_id": "57",
        "subdistrict_name": "ไทรม้า"
    },
    {
        "subdistrict_id": "236",
        "district_id": "57",
        "subdistrict_name": "บางรักน้อย"
    },
    {
        "subdistrict_id": "237",
        "district_id": "58",
        "subdistrict_name": "วัดชลอ"
    },
    {
        "subdistrict_id": "238",
        "district_id": "58",
        "subdistrict_name": "บางกรวย"
    },
    {
        "subdistrict_id": "239",
        "district_id": "58",
        "subdistrict_name": "บางสีทอง"
    },
    {
        "subdistrict_id": "240",
        "district_id": "58",
        "subdistrict_name": "บางขนุน"
    },
    {
        "subdistrict_id": "241",
        "district_id": "58",
        "subdistrict_name": "บางขุนกอง"
    },
    {
        "subdistrict_id": "242",
        "district_id": "58",
        "subdistrict_name": "บางคูเวียง"
    },
    {
        "subdistrict_id": "243",
        "district_id": "58",
        "subdistrict_name": "มหาสวัสดิ์"
    },
    {
        "subdistrict_id": "244",
        "district_id": "58",
        "subdistrict_name": "ปลายบาง"
    },
    {
        "subdistrict_id": "245",
        "district_id": "58",
        "subdistrict_name": "ศาลากลาง"
    },
    {
        "subdistrict_id": "246",
        "district_id": "59",
        "subdistrict_name": "บางม่วง"
    },
    {
        "subdistrict_id": "247",
        "district_id": "59",
        "subdistrict_name": "บางแม่นาง"
    },
    {
        "subdistrict_id": "248",
        "district_id": "59",
        "subdistrict_name": "บางเลน"
    },
    {
        "subdistrict_id": "249",
        "district_id": "59",
        "subdistrict_name": "เสาธงหิน"
    },
    {
        "subdistrict_id": "250",
        "district_id": "59",
        "subdistrict_name": "บางใหญ่"
    },
    {
        "subdistrict_id": "251",
        "district_id": "59",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "252",
        "district_id": "60",
        "subdistrict_name": "โสนลอย"
    },
    {
        "subdistrict_id": "253",
        "district_id": "60",
        "subdistrict_name": "บางบัวทอง"
    },
    {
        "subdistrict_id": "254",
        "district_id": "60",
        "subdistrict_name": "บางรักใหญ่"
    },
    {
        "subdistrict_id": "255",
        "district_id": "60",
        "subdistrict_name": "บางคูรัด"
    },
    {
        "subdistrict_id": "256",
        "district_id": "60",
        "subdistrict_name": "ละหาร"
    },
    {
        "subdistrict_id": "257",
        "district_id": "60",
        "subdistrict_name": "ลำโพ"
    },
    {
        "subdistrict_id": "258",
        "district_id": "60",
        "subdistrict_name": "พิมลราช"
    },
    {
        "subdistrict_id": "259",
        "district_id": "60",
        "subdistrict_name": "บางรักพัฒนา"
    },
    {
        "subdistrict_id": "260",
        "district_id": "61",
        "subdistrict_name": "ไทรน้อย"
    },
    {
        "subdistrict_id": "261",
        "district_id": "61",
        "subdistrict_name": "ราษฎร์นิยม"
    },
    {
        "subdistrict_id": "262",
        "district_id": "61",
        "subdistrict_name": "หนองเพรางาย"
    },
    {
        "subdistrict_id": "263",
        "district_id": "61",
        "subdistrict_name": "ไทรใหญ่"
    },
    {
        "subdistrict_id": "264",
        "district_id": "61",
        "subdistrict_name": "ขุนศรี"
    },
    {
        "subdistrict_id": "265",
        "district_id": "61",
        "subdistrict_name": "คลองขวาง"
    },
    {
        "subdistrict_id": "266",
        "district_id": "61",
        "subdistrict_name": "ทวีวัฒนา"
    },
    {
        "subdistrict_id": "267",
        "district_id": "62",
        "subdistrict_name": "ปากเกร็ด"
    },
    {
        "subdistrict_id": "268",
        "district_id": "62",
        "subdistrict_name": "บางตลาด"
    },
    {
        "subdistrict_id": "269",
        "district_id": "62",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "270",
        "district_id": "62",
        "subdistrict_name": "บางพูด"
    },
    {
        "subdistrict_id": "271",
        "district_id": "62",
        "subdistrict_name": "บางตะไนย์"
    },
    {
        "subdistrict_id": "272",
        "district_id": "62",
        "subdistrict_name": "คลองพระอุดม"
    },
    {
        "subdistrict_id": "273",
        "district_id": "62",
        "subdistrict_name": "ท่าอิฐ"
    },
    {
        "subdistrict_id": "274",
        "district_id": "62",
        "subdistrict_name": "เกาะเกร็ด"
    },
    {
        "subdistrict_id": "275",
        "district_id": "62",
        "subdistrict_name": "อ้อมเกร็ด"
    },
    {
        "subdistrict_id": "276",
        "district_id": "62",
        "subdistrict_name": "คลองข่อย"
    },
    {
        "subdistrict_id": "277",
        "district_id": "62",
        "subdistrict_name": "บางพลับ"
    },
    {
        "subdistrict_id": "278",
        "district_id": "62",
        "subdistrict_name": "คลองเกลือ"
    },
    {
        "subdistrict_id": "279",
        "district_id": "63",
        "subdistrict_name": "บางปรอก"
    },
    {
        "subdistrict_id": "280",
        "district_id": "63",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "281",
        "district_id": "63",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "282",
        "district_id": "63",
        "subdistrict_name": "บ้านฉาง"
    },
    {
        "subdistrict_id": "283",
        "district_id": "63",
        "subdistrict_name": "บ้านกระแชง"
    },
    {
        "subdistrict_id": "284",
        "district_id": "63",
        "subdistrict_name": "บางขะแยง"
    },
    {
        "subdistrict_id": "285",
        "district_id": "63",
        "subdistrict_name": "บางคูวัด"
    },
    {
        "subdistrict_id": "286",
        "district_id": "63",
        "subdistrict_name": "บางหลวง"
    },
    {
        "subdistrict_id": "287",
        "district_id": "63",
        "subdistrict_name": "บางเดื่อ"
    },
    {
        "subdistrict_id": "288",
        "district_id": "63",
        "subdistrict_name": "บางพูด"
    },
    {
        "subdistrict_id": "289",
        "district_id": "63",
        "subdistrict_name": "บางพูน"
    },
    {
        "subdistrict_id": "290",
        "district_id": "63",
        "subdistrict_name": "บางกะดี"
    },
    {
        "subdistrict_id": "291",
        "district_id": "63",
        "subdistrict_name": "สวนพริกไทย"
    },
    {
        "subdistrict_id": "292",
        "district_id": "63",
        "subdistrict_name": "หลักหก"
    },
    {
        "subdistrict_id": "293",
        "district_id": "64",
        "subdistrict_name": "คลองหนึ่ง"
    },
    {
        "subdistrict_id": "294",
        "district_id": "64",
        "subdistrict_name": "คลองสอง"
    },
    {
        "subdistrict_id": "295",
        "district_id": "64",
        "subdistrict_name": "คลองสาม"
    },
    {
        "subdistrict_id": "296",
        "district_id": "64",
        "subdistrict_name": "คลองสี่"
    },
    {
        "subdistrict_id": "297",
        "district_id": "64",
        "subdistrict_name": "คลองห้า"
    },
    {
        "subdistrict_id": "298",
        "district_id": "64",
        "subdistrict_name": "คลองหก"
    },
    {
        "subdistrict_id": "299",
        "district_id": "64",
        "subdistrict_name": "คลองเจ็ด"
    },
    {
        "subdistrict_id": "300",
        "district_id": "65",
        "subdistrict_name": "ประชาธิปัตย์"
    },
    {
        "subdistrict_id": "301",
        "district_id": "65",
        "subdistrict_name": "บึงยี่โถ"
    },
    {
        "subdistrict_id": "302",
        "district_id": "65",
        "subdistrict_name": "รังสิต"
    },
    {
        "subdistrict_id": "303",
        "district_id": "65",
        "subdistrict_name": "ลำผักกูด"
    },
    {
        "subdistrict_id": "304",
        "district_id": "65",
        "subdistrict_name": "บึงสนั่น"
    },
    {
        "subdistrict_id": "305",
        "district_id": "65",
        "subdistrict_name": "บึงน้ำรักษ์"
    },
    {
        "subdistrict_id": "306",
        "district_id": "66",
        "subdistrict_name": "บึงบา"
    },
    {
        "subdistrict_id": "307",
        "district_id": "66",
        "subdistrict_name": "บึงบอน"
    },
    {
        "subdistrict_id": "308",
        "district_id": "66",
        "subdistrict_name": "บึงกาสาม"
    },
    {
        "subdistrict_id": "309",
        "district_id": "66",
        "subdistrict_name": "บึงชำอ้อ"
    },
    {
        "subdistrict_id": "310",
        "district_id": "66",
        "subdistrict_name": "หนองสามวัง"
    },
    {
        "subdistrict_id": "311",
        "district_id": "66",
        "subdistrict_name": "ศาลาครุ"
    },
    {
        "subdistrict_id": "312",
        "district_id": "66",
        "subdistrict_name": "นพรัตน์"
    },
    {
        "subdistrict_id": "313",
        "district_id": "67",
        "subdistrict_name": "ระแหง"
    },
    {
        "subdistrict_id": "314",
        "district_id": "67",
        "subdistrict_name": "ลาดหลุมแก้ว"
    },
    {
        "subdistrict_id": "315",
        "district_id": "67",
        "subdistrict_name": "คูบางหลวง"
    },
    {
        "subdistrict_id": "316",
        "district_id": "67",
        "subdistrict_name": "คูขวาง"
    },
    {
        "subdistrict_id": "317",
        "district_id": "67",
        "subdistrict_name": "คลองพระอุดม"
    },
    {
        "subdistrict_id": "318",
        "district_id": "67",
        "subdistrict_name": "บ่อเงิน"
    },
    {
        "subdistrict_id": "319",
        "district_id": "67",
        "subdistrict_name": "หน้าไม้"
    },
    {
        "subdistrict_id": "320",
        "district_id": "68",
        "subdistrict_name": "คูคต"
    },
    {
        "subdistrict_id": "321",
        "district_id": "68",
        "subdistrict_name": "ลาดสวาย"
    },
    {
        "subdistrict_id": "322",
        "district_id": "68",
        "subdistrict_name": "บึงคำพร้อย"
    },
    {
        "subdistrict_id": "323",
        "district_id": "68",
        "subdistrict_name": "ลำลูกกา"
    },
    {
        "subdistrict_id": "324",
        "district_id": "68",
        "subdistrict_name": "บึงทองหลาง"
    },
    {
        "subdistrict_id": "325",
        "district_id": "68",
        "subdistrict_name": "ลำไทร"
    },
    {
        "subdistrict_id": "326",
        "district_id": "68",
        "subdistrict_name": "บึงคอไห"
    },
    {
        "subdistrict_id": "327",
        "district_id": "68",
        "subdistrict_name": "พืชอุดม"
    },
    {
        "subdistrict_id": "328",
        "district_id": "69",
        "subdistrict_name": "บางเตย"
    },
    {
        "subdistrict_id": "329",
        "district_id": "69",
        "subdistrict_name": "คลองควาย"
    },
    {
        "subdistrict_id": "330",
        "district_id": "69",
        "subdistrict_name": "สามโคก"
    },
    {
        "subdistrict_id": "331",
        "district_id": "69",
        "subdistrict_name": "กระแชง"
    },
    {
        "subdistrict_id": "332",
        "district_id": "69",
        "subdistrict_name": "บางโพธิ์เหนือ"
    },
    {
        "subdistrict_id": "333",
        "district_id": "69",
        "subdistrict_name": "เชียงรากใหญ่"
    },
    {
        "subdistrict_id": "334",
        "district_id": "69",
        "subdistrict_name": "บ้านปทุม"
    },
    {
        "subdistrict_id": "335",
        "district_id": "69",
        "subdistrict_name": "บ้านงิ้ว"
    },
    {
        "subdistrict_id": "336",
        "district_id": "69",
        "subdistrict_name": "เชียงรากน้อย"
    },
    {
        "subdistrict_id": "337",
        "district_id": "69",
        "subdistrict_name": "บางกระบือ"
    },
    {
        "subdistrict_id": "338",
        "district_id": "69",
        "subdistrict_name": "ท้ายเกาะ"
    },
    {
        "subdistrict_id": "339",
        "district_id": "70",
        "subdistrict_name": "ประตูชัย"
    },
    {
        "subdistrict_id": "340",
        "district_id": "70",
        "subdistrict_name": "กะมัง"
    },
    {
        "subdistrict_id": "341",
        "district_id": "70",
        "subdistrict_name": "หอรัตนไชย"
    },
    {
        "subdistrict_id": "342",
        "district_id": "70",
        "subdistrict_name": "หัวรอ"
    },
    {
        "subdistrict_id": "343",
        "district_id": "70",
        "subdistrict_name": "ท่าวาสุกรี"
    },
    {
        "subdistrict_id": "344",
        "district_id": "70",
        "subdistrict_name": "ไผ่ลิง"
    },
    {
        "subdistrict_id": "345",
        "district_id": "70",
        "subdistrict_name": "ปากกราน"
    },
    {
        "subdistrict_id": "346",
        "district_id": "70",
        "subdistrict_name": "ภูเขาทอง"
    },
    {
        "subdistrict_id": "347",
        "district_id": "70",
        "subdistrict_name": "สำเภาล่ม"
    },
    {
        "subdistrict_id": "348",
        "district_id": "70",
        "subdistrict_name": "สวนพริก"
    },
    {
        "subdistrict_id": "349",
        "district_id": "70",
        "subdistrict_name": "คลองตะเคียน"
    },
    {
        "subdistrict_id": "350",
        "district_id": "70",
        "subdistrict_name": "วัดตูม"
    },
    {
        "subdistrict_id": "351",
        "district_id": "70",
        "subdistrict_name": "หันตรา"
    },
    {
        "subdistrict_id": "352",
        "district_id": "70",
        "subdistrict_name": "ลุมพลี"
    },
    {
        "subdistrict_id": "353",
        "district_id": "70",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "354",
        "district_id": "70",
        "subdistrict_name": "บ้านเกาะ"
    },
    {
        "subdistrict_id": "355",
        "district_id": "70",
        "subdistrict_name": "คลองสวนพลู"
    },
    {
        "subdistrict_id": "356",
        "district_id": "70",
        "subdistrict_name": "คลองสระบัว"
    },
    {
        "subdistrict_id": "357",
        "district_id": "70",
        "subdistrict_name": "เกาะเรียน"
    },
    {
        "subdistrict_id": "358",
        "district_id": "70",
        "subdistrict_name": "บ้านป้อม"
    },
    {
        "subdistrict_id": "359",
        "district_id": "70",
        "subdistrict_name": "บ้านรุน"
    },
    {
        "subdistrict_id": "360",
        "district_id": "71",
        "subdistrict_name": "ท่าเรือ"
    },
    {
        "subdistrict_id": "361",
        "district_id": "71",
        "subdistrict_name": "จำปา"
    },
    {
        "subdistrict_id": "362",
        "district_id": "71",
        "subdistrict_name": "ท่าหลวง"
    },
    {
        "subdistrict_id": "363",
        "district_id": "71",
        "subdistrict_name": "บ้านร่อม"
    },
    {
        "subdistrict_id": "364",
        "district_id": "71",
        "subdistrict_name": "ศาลาลอย"
    },
    {
        "subdistrict_id": "365",
        "district_id": "71",
        "subdistrict_name": "วังแดง"
    },
    {
        "subdistrict_id": "366",
        "district_id": "71",
        "subdistrict_name": "โพธิ์เอน"
    },
    {
        "subdistrict_id": "367",
        "district_id": "71",
        "subdistrict_name": "ปากท่า"
    },
    {
        "subdistrict_id": "368",
        "district_id": "71",
        "subdistrict_name": "หนองขนาก"
    },
    {
        "subdistrict_id": "369",
        "district_id": "71",
        "subdistrict_name": "ท่าเจ้าสนุก"
    },
    {
        "subdistrict_id": "370",
        "district_id": "72",
        "subdistrict_name": "นครหลวง"
    },
    {
        "subdistrict_id": "371",
        "district_id": "72",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "372",
        "district_id": "72",
        "subdistrict_name": "บ่อโพง"
    },
    {
        "subdistrict_id": "373",
        "district_id": "72",
        "subdistrict_name": "บ้านชุ้ง"
    },
    {
        "subdistrict_id": "374",
        "district_id": "72",
        "subdistrict_name": "ปากจั่น"
    },
    {
        "subdistrict_id": "375",
        "district_id": "72",
        "subdistrict_name": "บางระกำ"
    },
    {
        "subdistrict_id": "376",
        "district_id": "72",
        "subdistrict_name": "บางพระครู"
    },
    {
        "subdistrict_id": "377",
        "district_id": "72",
        "subdistrict_name": "แม่ลา"
    },
    {
        "subdistrict_id": "378",
        "district_id": "72",
        "subdistrict_name": "หนองปลิง"
    },
    {
        "subdistrict_id": "379",
        "district_id": "72",
        "subdistrict_name": "คลองสะแก"
    },
    {
        "subdistrict_id": "380",
        "district_id": "72",
        "subdistrict_name": "สามไถ"
    },
    {
        "subdistrict_id": "381",
        "district_id": "72",
        "subdistrict_name": "พระนอน"
    },
    {
        "subdistrict_id": "382",
        "district_id": "73",
        "subdistrict_name": "บางไทร"
    },
    {
        "subdistrict_id": "383",
        "district_id": "73",
        "subdistrict_name": "บางพลี"
    },
    {
        "subdistrict_id": "384",
        "district_id": "73",
        "subdistrict_name": "สนามชัย"
    },
    {
        "subdistrict_id": "385",
        "district_id": "73",
        "subdistrict_name": "บ้านแป้ง"
    },
    {
        "subdistrict_id": "386",
        "district_id": "73",
        "subdistrict_name": "หน้าไม้"
    },
    {
        "subdistrict_id": "387",
        "district_id": "73",
        "subdistrict_name": "บางยี่โท"
    },
    {
        "subdistrict_id": "388",
        "district_id": "73",
        "subdistrict_name": "แคออก"
    },
    {
        "subdistrict_id": "389",
        "district_id": "73",
        "subdistrict_name": "แคตก"
    },
    {
        "subdistrict_id": "390",
        "district_id": "73",
        "subdistrict_name": "ช่างเหล็ก"
    },
    {
        "subdistrict_id": "391",
        "district_id": "73",
        "subdistrict_name": "กระแชง"
    },
    {
        "subdistrict_id": "392",
        "district_id": "73",
        "subdistrict_name": "บ้านกลึง"
    },
    {
        "subdistrict_id": "393",
        "district_id": "73",
        "subdistrict_name": "ช้างน้อย"
    },
    {
        "subdistrict_id": "394",
        "district_id": "73",
        "subdistrict_name": "ห่อหมก"
    },
    {
        "subdistrict_id": "395",
        "district_id": "73",
        "subdistrict_name": "ไผ่พระ"
    },
    {
        "subdistrict_id": "396",
        "district_id": "73",
        "subdistrict_name": "กกแก้วบูรพา"
    },
    {
        "subdistrict_id": "397",
        "district_id": "73",
        "subdistrict_name": "ไม้ตรา"
    },
    {
        "subdistrict_id": "398",
        "district_id": "73",
        "subdistrict_name": "บ้านม้า"
    },
    {
        "subdistrict_id": "399",
        "district_id": "73",
        "subdistrict_name": "บ้านเกาะ"
    },
    {
        "subdistrict_id": "400",
        "district_id": "73",
        "subdistrict_name": "ราชคราม"
    },
    {
        "subdistrict_id": "401",
        "district_id": "73",
        "subdistrict_name": "ช้างใหญ่"
    },
    {
        "subdistrict_id": "402",
        "district_id": "73",
        "subdistrict_name": "โพแตง"
    },
    {
        "subdistrict_id": "403",
        "district_id": "73",
        "subdistrict_name": "เชียงรากน้อย"
    },
    {
        "subdistrict_id": "404",
        "district_id": "73",
        "subdistrict_name": "โคกช้าง"
    },
    {
        "subdistrict_id": "405",
        "district_id": "74",
        "subdistrict_name": "บางบาล"
    },
    {
        "subdistrict_id": "406",
        "district_id": "74",
        "subdistrict_name": "วัดยม"
    },
    {
        "subdistrict_id": "407",
        "district_id": "74",
        "subdistrict_name": "ไทรน้อย"
    },
    {
        "subdistrict_id": "408",
        "district_id": "74",
        "subdistrict_name": "สะพานไทย"
    },
    {
        "subdistrict_id": "409",
        "district_id": "74",
        "subdistrict_name": "มหาพราหมณ์"
    },
    {
        "subdistrict_id": "410",
        "district_id": "74",
        "subdistrict_name": "กบเจา"
    },
    {
        "subdistrict_id": "411",
        "district_id": "74",
        "subdistrict_name": "บ้านคลัง"
    },
    {
        "subdistrict_id": "412",
        "district_id": "74",
        "subdistrict_name": "พระขาว"
    },
    {
        "subdistrict_id": "413",
        "district_id": "74",
        "subdistrict_name": "น้ำเต้า"
    },
    {
        "subdistrict_id": "414",
        "district_id": "74",
        "subdistrict_name": "ทางช้าง"
    },
    {
        "subdistrict_id": "415",
        "district_id": "74",
        "subdistrict_name": "วัดตะกู"
    },
    {
        "subdistrict_id": "416",
        "district_id": "74",
        "subdistrict_name": "บางหลวง"
    },
    {
        "subdistrict_id": "417",
        "district_id": "74",
        "subdistrict_name": "บางหลวงโดด"
    },
    {
        "subdistrict_id": "418",
        "district_id": "74",
        "subdistrict_name": "บางหัก"
    },
    {
        "subdistrict_id": "419",
        "district_id": "74",
        "subdistrict_name": "บางชะนี"
    },
    {
        "subdistrict_id": "420",
        "district_id": "74",
        "subdistrict_name": "บ้านกุ่ม"
    },
    {
        "subdistrict_id": "421",
        "district_id": "75",
        "subdistrict_name": "บ้านเลน"
    },
    {
        "subdistrict_id": "422",
        "district_id": "75",
        "subdistrict_name": "เชียงรากน้อย"
    },
    {
        "subdistrict_id": "423",
        "district_id": "75",
        "subdistrict_name": "บ้านโพ"
    },
    {
        "subdistrict_id": "424",
        "district_id": "75",
        "subdistrict_name": "บ้านกรด"
    },
    {
        "subdistrict_id": "425",
        "district_id": "75",
        "subdistrict_name": "บางกระสั้น"
    },
    {
        "subdistrict_id": "426",
        "district_id": "75",
        "subdistrict_name": "คลองจิก"
    },
    {
        "subdistrict_id": "427",
        "district_id": "75",
        "subdistrict_name": "บ้านหว้า"
    },
    {
        "subdistrict_id": "428",
        "district_id": "75",
        "subdistrict_name": "วัดยม"
    },
    {
        "subdistrict_id": "429",
        "district_id": "75",
        "subdistrict_name": "บางประแดง"
    },
    {
        "subdistrict_id": "430",
        "district_id": "75",
        "subdistrict_name": "สามเรือน"
    },
    {
        "subdistrict_id": "431",
        "district_id": "75",
        "subdistrict_name": "เกาะเกิด"
    },
    {
        "subdistrict_id": "432",
        "district_id": "75",
        "subdistrict_name": "บ้านพลับ"
    },
    {
        "subdistrict_id": "433",
        "district_id": "75",
        "subdistrict_name": "บ้านแป้ง"
    },
    {
        "subdistrict_id": "434",
        "district_id": "75",
        "subdistrict_name": "คุ้งลาน"
    },
    {
        "subdistrict_id": "435",
        "district_id": "75",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "436",
        "district_id": "75",
        "subdistrict_name": "บ้านสร้าง"
    },
    {
        "subdistrict_id": "437",
        "district_id": "75",
        "subdistrict_name": "ตลาดเกรียบ"
    },
    {
        "subdistrict_id": "438",
        "district_id": "75",
        "subdistrict_name": "ขนอนหลวง"
    },
    {
        "subdistrict_id": "439",
        "district_id": "76",
        "subdistrict_name": "บางปะหัน"
    },
    {
        "subdistrict_id": "440",
        "district_id": "76",
        "subdistrict_name": "ขยาย"
    },
    {
        "subdistrict_id": "441",
        "district_id": "76",
        "subdistrict_name": "บางเดื่อ"
    },
    {
        "subdistrict_id": "442",
        "district_id": "76",
        "subdistrict_name": "เสาธง"
    },
    {
        "subdistrict_id": "443",
        "district_id": "76",
        "subdistrict_name": "ทางกลาง"
    },
    {
        "subdistrict_id": "444",
        "district_id": "76",
        "subdistrict_name": "บางเพลิง"
    },
    {
        "subdistrict_id": "445",
        "district_id": "76",
        "subdistrict_name": "หันสัง"
    },
    {
        "subdistrict_id": "446",
        "district_id": "76",
        "subdistrict_name": "บางนางร้า"
    },
    {
        "subdistrict_id": "447",
        "district_id": "76",
        "subdistrict_name": "ตานิม"
    },
    {
        "subdistrict_id": "448",
        "district_id": "76",
        "subdistrict_name": "ทับน้ำ"
    },
    {
        "subdistrict_id": "449",
        "district_id": "76",
        "subdistrict_name": "บ้านม้า"
    },
    {
        "subdistrict_id": "450",
        "district_id": "76",
        "subdistrict_name": "ขวัญเมือง"
    },
    {
        "subdistrict_id": "451",
        "district_id": "76",
        "subdistrict_name": "บ้านลี่"
    },
    {
        "subdistrict_id": "452",
        "district_id": "76",
        "subdistrict_name": "โพธิ์สามต้น"
    },
    {
        "subdistrict_id": "453",
        "district_id": "76",
        "subdistrict_name": "พุทเลา"
    },
    {
        "subdistrict_id": "454",
        "district_id": "76",
        "subdistrict_name": "ตาลเอน"
    },
    {
        "subdistrict_id": "455",
        "district_id": "76",
        "subdistrict_name": "บ้านขล้อ"
    },
    {
        "subdistrict_id": "456",
        "district_id": "77",
        "subdistrict_name": "ผักไห่"
    },
    {
        "subdistrict_id": "457",
        "district_id": "77",
        "subdistrict_name": "อมฤต"
    },
    {
        "subdistrict_id": "458",
        "district_id": "77",
        "subdistrict_name": "บ้านแค"
    },
    {
        "subdistrict_id": "459",
        "district_id": "77",
        "subdistrict_name": "ลาดน้ำเค็ม"
    },
    {
        "subdistrict_id": "460",
        "district_id": "77",
        "subdistrict_name": "ตาลาน"
    },
    {
        "subdistrict_id": "461",
        "district_id": "77",
        "subdistrict_name": "ท่าดินแดง"
    },
    {
        "subdistrict_id": "462",
        "district_id": "77",
        "subdistrict_name": "ดอนลาน"
    },
    {
        "subdistrict_id": "463",
        "district_id": "77",
        "subdistrict_name": "นาคู"
    },
    {
        "subdistrict_id": "464",
        "district_id": "77",
        "subdistrict_name": "กุฎี"
    },
    {
        "subdistrict_id": "465",
        "district_id": "77",
        "subdistrict_name": "ลำตะเคียน"
    },
    {
        "subdistrict_id": "466",
        "district_id": "77",
        "subdistrict_name": "โคกช้าง"
    },
    {
        "subdistrict_id": "467",
        "district_id": "77",
        "subdistrict_name": "จักราช"
    },
    {
        "subdistrict_id": "468",
        "district_id": "77",
        "subdistrict_name": "หนองน้ำใหญ่"
    },
    {
        "subdistrict_id": "469",
        "district_id": "77",
        "subdistrict_name": "ลาดชิด"
    },
    {
        "subdistrict_id": "470",
        "district_id": "77",
        "subdistrict_name": "หน้าโคก"
    },
    {
        "subdistrict_id": "471",
        "district_id": "77",
        "subdistrict_name": "บ้านใหญ่"
    },
    {
        "subdistrict_id": "472",
        "district_id": "78",
        "subdistrict_name": "ภาชี"
    },
    {
        "subdistrict_id": "473",
        "district_id": "78",
        "subdistrict_name": "โคกม่วง"
    },
    {
        "subdistrict_id": "474",
        "district_id": "78",
        "subdistrict_name": "ระโสม"
    },
    {
        "subdistrict_id": "475",
        "district_id": "78",
        "subdistrict_name": "หนองน้ำใส"
    },
    {
        "subdistrict_id": "476",
        "district_id": "78",
        "subdistrict_name": "ดอนหญ้านาง"
    },
    {
        "subdistrict_id": "477",
        "district_id": "78",
        "subdistrict_name": "ไผ่ล้อม"
    },
    {
        "subdistrict_id": "478",
        "district_id": "78",
        "subdistrict_name": "กระจิว"
    },
    {
        "subdistrict_id": "479",
        "district_id": "78",
        "subdistrict_name": "พระแก้ว"
    },
    {
        "subdistrict_id": "480",
        "district_id": "79",
        "subdistrict_name": "ลาดบัวหลวง"
    },
    {
        "subdistrict_id": "481",
        "district_id": "79",
        "subdistrict_name": "หลักชัย"
    },
    {
        "subdistrict_id": "482",
        "district_id": "79",
        "subdistrict_name": "สามเมือง"
    },
    {
        "subdistrict_id": "483",
        "district_id": "79",
        "subdistrict_name": "พระยาบันลือ"
    },
    {
        "subdistrict_id": "484",
        "district_id": "79",
        "subdistrict_name": "สิงหนาท"
    },
    {
        "subdistrict_id": "485",
        "district_id": "79",
        "subdistrict_name": "คู้สลอด"
    },
    {
        "subdistrict_id": "486",
        "district_id": "79",
        "subdistrict_name": "คลองพระยาบันลือ"
    },
    {
        "subdistrict_id": "487",
        "district_id": "80",
        "subdistrict_name": "ลำตาเสา"
    },
    {
        "subdistrict_id": "488",
        "district_id": "80",
        "subdistrict_name": "บ่อตาโล่"
    },
    {
        "subdistrict_id": "489",
        "district_id": "80",
        "subdistrict_name": "วังน้อย"
    },
    {
        "subdistrict_id": "490",
        "district_id": "80",
        "subdistrict_name": "ลำไทร"
    },
    {
        "subdistrict_id": "491",
        "district_id": "80",
        "subdistrict_name": "สนับทึบ"
    },
    {
        "subdistrict_id": "492",
        "district_id": "80",
        "subdistrict_name": "พยอม"
    },
    {
        "subdistrict_id": "493",
        "district_id": "80",
        "subdistrict_name": "หันตะเภา"
    },
    {
        "subdistrict_id": "494",
        "district_id": "80",
        "subdistrict_name": "วังจุฬา"
    },
    {
        "subdistrict_id": "495",
        "district_id": "80",
        "subdistrict_name": "ข้าวงาม"
    },
    {
        "subdistrict_id": "496",
        "district_id": "80",
        "subdistrict_name": "ชะแมบ"
    },
    {
        "subdistrict_id": "497",
        "district_id": "81",
        "subdistrict_name": "เสนา"
    },
    {
        "subdistrict_id": "498",
        "district_id": "81",
        "subdistrict_name": "บ้านแพน"
    },
    {
        "subdistrict_id": "499",
        "district_id": "81",
        "subdistrict_name": "เจ้าเจ็ด"
    },
    {
        "subdistrict_id": "500",
        "district_id": "81",
        "subdistrict_name": "สามกอ"
    },
    {
        "subdistrict_id": "501",
        "district_id": "81",
        "subdistrict_name": "บางนมโค"
    },
    {
        "subdistrict_id": "502",
        "district_id": "81",
        "subdistrict_name": "หัวเวียง"
    },
    {
        "subdistrict_id": "503",
        "district_id": "81",
        "subdistrict_name": "มารวิชัย"
    },
    {
        "subdistrict_id": "504",
        "district_id": "81",
        "subdistrict_name": "บ้านโพธิ์"
    },
    {
        "subdistrict_id": "505",
        "district_id": "81",
        "subdistrict_name": "รางจรเข้"
    },
    {
        "subdistrict_id": "506",
        "district_id": "81",
        "subdistrict_name": "บ้านกระทุ่ม"
    },
    {
        "subdistrict_id": "507",
        "district_id": "81",
        "subdistrict_name": "บ้านแถว"
    },
    {
        "subdistrict_id": "508",
        "district_id": "81",
        "subdistrict_name": "ชายนา"
    },
    {
        "subdistrict_id": "509",
        "district_id": "81",
        "subdistrict_name": "สามตุ่ม"
    },
    {
        "subdistrict_id": "510",
        "district_id": "81",
        "subdistrict_name": "ลาดงา"
    },
    {
        "subdistrict_id": "511",
        "district_id": "81",
        "subdistrict_name": "ดอนทอง"
    },
    {
        "subdistrict_id": "512",
        "district_id": "81",
        "subdistrict_name": "บ้านหลวง"
    },
    {
        "subdistrict_id": "513",
        "district_id": "81",
        "subdistrict_name": "เจ้าเสด็จ"
    },
    {
        "subdistrict_id": "514",
        "district_id": "82",
        "subdistrict_name": "บางซ้าย"
    },
    {
        "subdistrict_id": "515",
        "district_id": "82",
        "subdistrict_name": "แก้วฟ้า"
    },
    {
        "subdistrict_id": "516",
        "district_id": "82",
        "subdistrict_name": "เต่าเล่า"
    },
    {
        "subdistrict_id": "517",
        "district_id": "82",
        "subdistrict_name": "ปลายกลัด"
    },
    {
        "subdistrict_id": "518",
        "district_id": "82",
        "subdistrict_name": "เทพมงคล"
    },
    {
        "subdistrict_id": "519",
        "district_id": "82",
        "subdistrict_name": "วังพัฒนา"
    },
    {
        "subdistrict_id": "520",
        "district_id": "83",
        "subdistrict_name": "คานหาม"
    },
    {
        "subdistrict_id": "521",
        "district_id": "83",
        "subdistrict_name": "บ้านช้าง"
    },
    {
        "subdistrict_id": "522",
        "district_id": "83",
        "subdistrict_name": "สามบัณฑิต"
    },
    {
        "subdistrict_id": "523",
        "district_id": "83",
        "subdistrict_name": "บ้านหีบ"
    },
    {
        "subdistrict_id": "524",
        "district_id": "83",
        "subdistrict_name": "หนองไม้ซุง"
    },
    {
        "subdistrict_id": "525",
        "district_id": "83",
        "subdistrict_name": "อุทัย"
    },
    {
        "subdistrict_id": "526",
        "district_id": "83",
        "subdistrict_name": "เสนา"
    },
    {
        "subdistrict_id": "527",
        "district_id": "83",
        "subdistrict_name": "หนองน้ำส้ม"
    },
    {
        "subdistrict_id": "528",
        "district_id": "83",
        "subdistrict_name": "โพสาวหาญ"
    },
    {
        "subdistrict_id": "529",
        "district_id": "83",
        "subdistrict_name": "ธนู"
    },
    {
        "subdistrict_id": "530",
        "district_id": "83",
        "subdistrict_name": "ข้าวเม่า"
    },
    {
        "subdistrict_id": "531",
        "district_id": "84",
        "subdistrict_name": "หัวไผ่"
    },
    {
        "subdistrict_id": "532",
        "district_id": "84",
        "subdistrict_name": "กะทุ่ม"
    },
    {
        "subdistrict_id": "533",
        "district_id": "84",
        "subdistrict_name": "มหาราช"
    },
    {
        "subdistrict_id": "534",
        "district_id": "84",
        "subdistrict_name": "น้ำเต้า"
    },
    {
        "subdistrict_id": "535",
        "district_id": "84",
        "subdistrict_name": "บางนา"
    },
    {
        "subdistrict_id": "536",
        "district_id": "84",
        "subdistrict_name": "โรงช้าง"
    },
    {
        "subdistrict_id": "537",
        "district_id": "84",
        "subdistrict_name": "เจ้าปลุก"
    },
    {
        "subdistrict_id": "538",
        "district_id": "84",
        "subdistrict_name": "พิตเพียน"
    },
    {
        "subdistrict_id": "539",
        "district_id": "84",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "540",
        "district_id": "84",
        "subdistrict_name": "บ้านขวาง"
    },
    {
        "subdistrict_id": "541",
        "district_id": "84",
        "subdistrict_name": "ท่าตอ"
    },
    {
        "subdistrict_id": "542",
        "district_id": "84",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "543",
        "district_id": "85",
        "subdistrict_name": "บ้านแพรก"
    },
    {
        "subdistrict_id": "544",
        "district_id": "85",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "545",
        "district_id": "85",
        "subdistrict_name": "สำพะเนียง"
    },
    {
        "subdistrict_id": "546",
        "district_id": "85",
        "subdistrict_name": "คลองน้อย"
    },
    {
        "subdistrict_id": "547",
        "district_id": "85",
        "subdistrict_name": "สองห้อง"
    },
    {
        "subdistrict_id": "548",
        "district_id": "86",
        "subdistrict_name": "ตลาดหลวง"
    },
    {
        "subdistrict_id": "549",
        "district_id": "86",
        "subdistrict_name": "บางแก้ว"
    },
    {
        "subdistrict_id": "550",
        "district_id": "86",
        "subdistrict_name": "ศาลาแดง"
    },
    {
        "subdistrict_id": "551",
        "district_id": "86",
        "subdistrict_name": "ป่างิ้ว"
    },
    {
        "subdistrict_id": "552",
        "district_id": "86",
        "subdistrict_name": "บ้านแห"
    },
    {
        "subdistrict_id": "553",
        "district_id": "86",
        "subdistrict_name": "ตลาดกรวด"
    },
    {
        "subdistrict_id": "554",
        "district_id": "86",
        "subdistrict_name": "มหาดไทย"
    },
    {
        "subdistrict_id": "555",
        "district_id": "86",
        "subdistrict_name": "บ้านอิฐ"
    },
    {
        "subdistrict_id": "556",
        "district_id": "86",
        "subdistrict_name": "หัวไผ่"
    },
    {
        "subdistrict_id": "557",
        "district_id": "86",
        "subdistrict_name": "จำปาหล่อ"
    },
    {
        "subdistrict_id": "558",
        "district_id": "86",
        "subdistrict_name": "โพสะ"
    },
    {
        "subdistrict_id": "559",
        "district_id": "86",
        "subdistrict_name": "บ้านรี"
    },
    {
        "subdistrict_id": "560",
        "district_id": "86",
        "subdistrict_name": "คลองวัว"
    },
    {
        "subdistrict_id": "561",
        "district_id": "86",
        "subdistrict_name": "ย่านซื่อ"
    },
    {
        "subdistrict_id": "562",
        "district_id": "87",
        "subdistrict_name": "จรเข้ร้อง"
    },
    {
        "subdistrict_id": "563",
        "district_id": "87",
        "subdistrict_name": "ไชยภูมิ"
    },
    {
        "subdistrict_id": "564",
        "district_id": "87",
        "subdistrict_name": "ชัยฤทธิ์"
    },
    {
        "subdistrict_id": "565",
        "district_id": "87",
        "subdistrict_name": "เทวราช"
    },
    {
        "subdistrict_id": "566",
        "district_id": "87",
        "subdistrict_name": "ราชสถิตย์"
    },
    {
        "subdistrict_id": "567",
        "district_id": "87",
        "subdistrict_name": "ไชโย"
    },
    {
        "subdistrict_id": "568",
        "district_id": "87",
        "subdistrict_name": "หลักฟ้า"
    },
    {
        "subdistrict_id": "569",
        "district_id": "87",
        "subdistrict_name": "ชะไว"
    },
    {
        "subdistrict_id": "570",
        "district_id": "87",
        "subdistrict_name": "ตรีณรงค์"
    },
    {
        "subdistrict_id": "571",
        "district_id": "88",
        "subdistrict_name": "บางปลากด"
    },
    {
        "subdistrict_id": "572",
        "district_id": "88",
        "subdistrict_name": "ป่าโมก"
    },
    {
        "subdistrict_id": "573",
        "district_id": "88",
        "subdistrict_name": "สายทอง"
    },
    {
        "subdistrict_id": "574",
        "district_id": "88",
        "subdistrict_name": "โรงช้าง"
    },
    {
        "subdistrict_id": "575",
        "district_id": "88",
        "subdistrict_name": "บางเสด็จ"
    },
    {
        "subdistrict_id": "576",
        "district_id": "88",
        "subdistrict_name": "นรสิงห์"
    },
    {
        "subdistrict_id": "577",
        "district_id": "88",
        "subdistrict_name": "เอกราช"
    },
    {
        "subdistrict_id": "578",
        "district_id": "88",
        "subdistrict_name": "โผงเผง"
    },
    {
        "subdistrict_id": "579",
        "district_id": "89",
        "subdistrict_name": "อ่างแก้ว"
    },
    {
        "subdistrict_id": "580",
        "district_id": "89",
        "subdistrict_name": "อินทประมูล"
    },
    {
        "subdistrict_id": "581",
        "district_id": "89",
        "subdistrict_name": "บางพลับ"
    },
    {
        "subdistrict_id": "582",
        "district_id": "89",
        "subdistrict_name": "หนองแม่ไก่"
    },
    {
        "subdistrict_id": "583",
        "district_id": "89",
        "subdistrict_name": "รำมะสัก"
    },
    {
        "subdistrict_id": "584",
        "district_id": "89",
        "subdistrict_name": "บางระกำ"
    },
    {
        "subdistrict_id": "585",
        "district_id": "89",
        "subdistrict_name": "โพธิ์รังนก"
    },
    {
        "subdistrict_id": "586",
        "district_id": "89",
        "subdistrict_name": "องครักษ์"
    },
    {
        "subdistrict_id": "587",
        "district_id": "89",
        "subdistrict_name": "โคกพุทรา"
    },
    {
        "subdistrict_id": "588",
        "district_id": "89",
        "subdistrict_name": "ยางช้าย"
    },
    {
        "subdistrict_id": "589",
        "district_id": "89",
        "subdistrict_name": "บ่อแร่"
    },
    {
        "subdistrict_id": "590",
        "district_id": "89",
        "subdistrict_name": "ทางพระ"
    },
    {
        "subdistrict_id": "591",
        "district_id": "89",
        "subdistrict_name": "สามง่าม"
    },
    {
        "subdistrict_id": "592",
        "district_id": "89",
        "subdistrict_name": "บางเจ้าฉ่า"
    },
    {
        "subdistrict_id": "593",
        "district_id": "89",
        "subdistrict_name": "คำหยาด"
    },
    {
        "subdistrict_id": "594",
        "district_id": "90",
        "subdistrict_name": "แสวงหา"
    },
    {
        "subdistrict_id": "595",
        "district_id": "90",
        "subdistrict_name": "ศรีพราน"
    },
    {
        "subdistrict_id": "596",
        "district_id": "90",
        "subdistrict_name": "บ้านพราน"
    },
    {
        "subdistrict_id": "597",
        "district_id": "90",
        "subdistrict_name": "วังน้ำเย็น"
    },
    {
        "subdistrict_id": "598",
        "district_id": "90",
        "subdistrict_name": "สีบัวทอง"
    },
    {
        "subdistrict_id": "599",
        "district_id": "90",
        "subdistrict_name": "ห้วยไผ่"
    },
    {
        "subdistrict_id": "600",
        "district_id": "90",
        "subdistrict_name": "จำลอง"
    },
    {
        "subdistrict_id": "601",
        "district_id": "91",
        "subdistrict_name": "ไผ่จำศิล"
    },
    {
        "subdistrict_id": "602",
        "district_id": "91",
        "subdistrict_name": "ศาลเจ้าโรงทอง"
    },
    {
        "subdistrict_id": "603",
        "district_id": "91",
        "subdistrict_name": "ไผ่ดำพัฒนา"
    },
    {
        "subdistrict_id": "604",
        "district_id": "91",
        "subdistrict_name": "สาวร้องไห้"
    },
    {
        "subdistrict_id": "605",
        "district_id": "91",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "606",
        "district_id": "91",
        "subdistrict_name": "ยี่ล้น"
    },
    {
        "subdistrict_id": "607",
        "district_id": "91",
        "subdistrict_name": "บางจัก"
    },
    {
        "subdistrict_id": "608",
        "district_id": "91",
        "subdistrict_name": "ห้วยคันแหลน"
    },
    {
        "subdistrict_id": "609",
        "district_id": "91",
        "subdistrict_name": "คลองขนาก"
    },
    {
        "subdistrict_id": "610",
        "district_id": "91",
        "subdistrict_name": "ไผ่วง"
    },
    {
        "subdistrict_id": "611",
        "district_id": "91",
        "subdistrict_name": "สี่ร้อย"
    },
    {
        "subdistrict_id": "612",
        "district_id": "91",
        "subdistrict_name": "ม่วงเตี้ย"
    },
    {
        "subdistrict_id": "613",
        "district_id": "91",
        "subdistrict_name": "หัวตะพาน"
    },
    {
        "subdistrict_id": "614",
        "district_id": "91",
        "subdistrict_name": "หลักแก้ว"
    },
    {
        "subdistrict_id": "615",
        "district_id": "91",
        "subdistrict_name": "ตลาดใหม่"
    },
    {
        "subdistrict_id": "616",
        "district_id": "92",
        "subdistrict_name": "สามโก้"
    },
    {
        "subdistrict_id": "617",
        "district_id": "92",
        "subdistrict_name": "ราษฎรพัฒนา"
    },
    {
        "subdistrict_id": "618",
        "district_id": "92",
        "subdistrict_name": "อบทม"
    },
    {
        "subdistrict_id": "619",
        "district_id": "92",
        "subdistrict_name": "โพธิ์ม่วงพันธ์"
    },
    {
        "subdistrict_id": "620",
        "district_id": "92",
        "subdistrict_name": "มงคลธรรมนิมิต"
    },
    {
        "subdistrict_id": "621",
        "district_id": "93",
        "subdistrict_name": "ทะเลชุบศร"
    },
    {
        "subdistrict_id": "622",
        "district_id": "93",
        "subdistrict_name": "ท่าหิน"
    },
    {
        "subdistrict_id": "623",
        "district_id": "93",
        "subdistrict_name": "กกโก"
    },
    {
        "subdistrict_id": "624",
        "district_id": "93",
        "subdistrict_name": "โก่งธนู"
    },
    {
        "subdistrict_id": "625",
        "district_id": "93",
        "subdistrict_name": "เขาพระงาม"
    },
    {
        "subdistrict_id": "626",
        "district_id": "93",
        "subdistrict_name": "เขาสามยอด"
    },
    {
        "subdistrict_id": "627",
        "district_id": "93",
        "subdistrict_name": "โคกกะเทียม"
    },
    {
        "subdistrict_id": "628",
        "district_id": "93",
        "subdistrict_name": "โคกลำพาน"
    },
    {
        "subdistrict_id": "629",
        "district_id": "93",
        "subdistrict_name": "โคกตูม"
    },
    {
        "subdistrict_id": "630",
        "district_id": "93",
        "subdistrict_name": "งิ้วราย"
    },
    {
        "subdistrict_id": "631",
        "district_id": "93",
        "subdistrict_name": "ดอนโพธิ์"
    },
    {
        "subdistrict_id": "632",
        "district_id": "93",
        "subdistrict_name": "ตะลุง"
    },
    {
        "subdistrict_id": "633",
        "district_id": "93",
        "subdistrict_name": "ท่าแค"
    },
    {
        "subdistrict_id": "634",
        "district_id": "93",
        "subdistrict_name": "ท่าศาลา"
    },
    {
        "subdistrict_id": "635",
        "district_id": "93",
        "subdistrict_name": "นิคมสร้างตนเอง"
    },
    {
        "subdistrict_id": "636",
        "district_id": "93",
        "subdistrict_name": "บางขันหมาก"
    },
    {
        "subdistrict_id": "637",
        "district_id": "93",
        "subdistrict_name": "บ้านข่อย"
    },
    {
        "subdistrict_id": "638",
        "district_id": "93",
        "subdistrict_name": "ท้ายตลาด"
    },
    {
        "subdistrict_id": "639",
        "district_id": "93",
        "subdistrict_name": "ป่าตาล"
    },
    {
        "subdistrict_id": "640",
        "district_id": "93",
        "subdistrict_name": "พรหมมาสตร์"
    },
    {
        "subdistrict_id": "641",
        "district_id": "93",
        "subdistrict_name": "โพธิ์เก้าต้น"
    },
    {
        "subdistrict_id": "642",
        "district_id": "93",
        "subdistrict_name": "โพธิ์ตรุ"
    },
    {
        "subdistrict_id": "643",
        "district_id": "93",
        "subdistrict_name": "สี่คลอง"
    },
    {
        "subdistrict_id": "644",
        "district_id": "93",
        "subdistrict_name": "ถนนใหญ่"
    },
    {
        "subdistrict_id": "645",
        "district_id": "94",
        "subdistrict_name": "พัฒนานิคม"
    },
    {
        "subdistrict_id": "646",
        "district_id": "94",
        "subdistrict_name": "ช่องสาริกา"
    },
    {
        "subdistrict_id": "647",
        "district_id": "94",
        "subdistrict_name": "มะนาวหวาน"
    },
    {
        "subdistrict_id": "648",
        "district_id": "94",
        "subdistrict_name": "ดีลัง"
    },
    {
        "subdistrict_id": "649",
        "district_id": "94",
        "subdistrict_name": "โคกสลุง"
    },
    {
        "subdistrict_id": "650",
        "district_id": "94",
        "subdistrict_name": "ชอนน้อย"
    },
    {
        "subdistrict_id": "651",
        "district_id": "94",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "652",
        "district_id": "94",
        "subdistrict_name": "ห้วยขุนราม"
    },
    {
        "subdistrict_id": "653",
        "district_id": "94",
        "subdistrict_name": "น้ำสุด"
    },
    {
        "subdistrict_id": "654",
        "district_id": "95",
        "subdistrict_name": "โคกสำโรง"
    },
    {
        "subdistrict_id": "655",
        "district_id": "95",
        "subdistrict_name": "เกาะแก้ว"
    },
    {
        "subdistrict_id": "656",
        "district_id": "95",
        "subdistrict_name": "ถลุงเหล็ก"
    },
    {
        "subdistrict_id": "657",
        "district_id": "95",
        "subdistrict_name": "หลุมข้าว"
    },
    {
        "subdistrict_id": "658",
        "district_id": "95",
        "subdistrict_name": "ห้วยโป่ง"
    },
    {
        "subdistrict_id": "659",
        "district_id": "95",
        "subdistrict_name": "คลองเกตุ"
    },
    {
        "subdistrict_id": "660",
        "district_id": "95",
        "subdistrict_name": "สะแกราบ"
    },
    {
        "subdistrict_id": "661",
        "district_id": "95",
        "subdistrict_name": "เพนียด"
    },
    {
        "subdistrict_id": "662",
        "district_id": "95",
        "subdistrict_name": "วังเพลิง"
    },
    {
        "subdistrict_id": "663",
        "district_id": "95",
        "subdistrict_name": "ดงมะรุม"
    },
    {
        "subdistrict_id": "664",
        "district_id": "95",
        "subdistrict_name": "วังขอนขว้าง"
    },
    {
        "subdistrict_id": "665",
        "district_id": "95",
        "subdistrict_name": "วังจั่น"
    },
    {
        "subdistrict_id": "666",
        "district_id": "95",
        "subdistrict_name": "หนองแขม"
    },
    {
        "subdistrict_id": "667",
        "district_id": "96",
        "subdistrict_name": "ลำนารายณ์"
    },
    {
        "subdistrict_id": "668",
        "district_id": "96",
        "subdistrict_name": "ชัยนารายณ์"
    },
    {
        "subdistrict_id": "669",
        "district_id": "96",
        "subdistrict_name": "ศิลาทิพย์"
    },
    {
        "subdistrict_id": "670",
        "district_id": "96",
        "subdistrict_name": "ห้วยหิน"
    },
    {
        "subdistrict_id": "671",
        "district_id": "96",
        "subdistrict_name": "ม่วงค่อม"
    },
    {
        "subdistrict_id": "672",
        "district_id": "96",
        "subdistrict_name": "บัวชุม"
    },
    {
        "subdistrict_id": "673",
        "district_id": "96",
        "subdistrict_name": "ท่าดินดำ"
    },
    {
        "subdistrict_id": "674",
        "district_id": "96",
        "subdistrict_name": "มะกอกหวาน"
    },
    {
        "subdistrict_id": "675",
        "district_id": "96",
        "subdistrict_name": "ซับตะเคียน"
    },
    {
        "subdistrict_id": "676",
        "district_id": "96",
        "subdistrict_name": "นาโสม"
    },
    {
        "subdistrict_id": "677",
        "district_id": "96",
        "subdistrict_name": "หนองยายโต๊ะ"
    },
    {
        "subdistrict_id": "678",
        "district_id": "96",
        "subdistrict_name": "เกาะรัง"
    },
    {
        "subdistrict_id": "679",
        "district_id": "96",
        "subdistrict_name": "ท่ามะนาว"
    },
    {
        "subdistrict_id": "680",
        "district_id": "96",
        "subdistrict_name": "นิคมลำนารายณ์"
    },
    {
        "subdistrict_id": "681",
        "district_id": "96",
        "subdistrict_name": "ชัยบาดาล"
    },
    {
        "subdistrict_id": "682",
        "district_id": "96",
        "subdistrict_name": "บ้านใหม่สามัคคี"
    },
    {
        "subdistrict_id": "683",
        "district_id": "96",
        "subdistrict_name": "เขาแหลม"
    },
    {
        "subdistrict_id": "684",
        "district_id": "97",
        "subdistrict_name": "ท่าวุ้ง"
    },
    {
        "subdistrict_id": "685",
        "district_id": "97",
        "subdistrict_name": "บางคู้"
    },
    {
        "subdistrict_id": "686",
        "district_id": "97",
        "subdistrict_name": "โพตลาดแก้ว"
    },
    {
        "subdistrict_id": "687",
        "district_id": "97",
        "subdistrict_name": "บางลี่"
    },
    {
        "subdistrict_id": "688",
        "district_id": "97",
        "subdistrict_name": "บางงา"
    },
    {
        "subdistrict_id": "689",
        "district_id": "97",
        "subdistrict_name": "โคกสลุด"
    },
    {
        "subdistrict_id": "690",
        "district_id": "97",
        "subdistrict_name": "เขาสมอคอน"
    },
    {
        "subdistrict_id": "691",
        "district_id": "97",
        "subdistrict_name": "หัวสำโรง"
    },
    {
        "subdistrict_id": "692",
        "district_id": "97",
        "subdistrict_name": "ลาดสาลี่"
    },
    {
        "subdistrict_id": "693",
        "district_id": "97",
        "subdistrict_name": "บ้านเบิก"
    },
    {
        "subdistrict_id": "694",
        "district_id": "97",
        "subdistrict_name": "มุจลินท์"
    },
    {
        "subdistrict_id": "695",
        "district_id": "98",
        "subdistrict_name": "ไผ่ใหญ่"
    },
    {
        "subdistrict_id": "696",
        "district_id": "98",
        "subdistrict_name": "บ้านทราย"
    },
    {
        "subdistrict_id": "697",
        "district_id": "98",
        "subdistrict_name": "บ้านกล้วย"
    },
    {
        "subdistrict_id": "698",
        "district_id": "98",
        "subdistrict_name": "ดงพลับ"
    },
    {
        "subdistrict_id": "699",
        "district_id": "98",
        "subdistrict_name": "บ้านชี"
    },
    {
        "subdistrict_id": "700",
        "district_id": "98",
        "subdistrict_name": "พุคา"
    },
    {
        "subdistrict_id": "701",
        "district_id": "98",
        "subdistrict_name": "หินปัก"
    },
    {
        "subdistrict_id": "702",
        "district_id": "98",
        "subdistrict_name": "บางพึ่ง"
    },
    {
        "subdistrict_id": "703",
        "district_id": "98",
        "subdistrict_name": "หนองทรายขาว"
    },
    {
        "subdistrict_id": "704",
        "district_id": "98",
        "subdistrict_name": "บางกะพี้"
    },
    {
        "subdistrict_id": "705",
        "district_id": "98",
        "subdistrict_name": "หนองเต่า"
    },
    {
        "subdistrict_id": "706",
        "district_id": "98",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "707",
        "district_id": "98",
        "subdistrict_name": "บางขาม"
    },
    {
        "subdistrict_id": "708",
        "district_id": "98",
        "subdistrict_name": "ดอนดึง"
    },
    {
        "subdistrict_id": "709",
        "district_id": "98",
        "subdistrict_name": "ชอนม่วง"
    },
    {
        "subdistrict_id": "710",
        "district_id": "98",
        "subdistrict_name": "หนองกระเบียน"
    },
    {
        "subdistrict_id": "711",
        "district_id": "98",
        "subdistrict_name": "สายห้วยแก้ว"
    },
    {
        "subdistrict_id": "712",
        "district_id": "98",
        "subdistrict_name": "มหาสอน"
    },
    {
        "subdistrict_id": "713",
        "district_id": "98",
        "subdistrict_name": "บ้านหมี่"
    },
    {
        "subdistrict_id": "714",
        "district_id": "98",
        "subdistrict_name": "เชียงงา"
    },
    {
        "subdistrict_id": "715",
        "district_id": "98",
        "subdistrict_name": "หนองเมือง"
    },
    {
        "subdistrict_id": "716",
        "district_id": "98",
        "subdistrict_name": "สนามแจง"
    },
    {
        "subdistrict_id": "717",
        "district_id": "99",
        "subdistrict_name": "ท่าหลวง"
    },
    {
        "subdistrict_id": "718",
        "district_id": "99",
        "subdistrict_name": "แก่งผักกูด"
    },
    {
        "subdistrict_id": "719",
        "district_id": "99",
        "subdistrict_name": "ซับจำปา"
    },
    {
        "subdistrict_id": "720",
        "district_id": "99",
        "subdistrict_name": "หนองผักแว่น"
    },
    {
        "subdistrict_id": "721",
        "district_id": "99",
        "subdistrict_name": "ทะเลวังวัด"
    },
    {
        "subdistrict_id": "722",
        "district_id": "99",
        "subdistrict_name": "หัวลำ"
    },
    {
        "subdistrict_id": "723",
        "district_id": "100",
        "subdistrict_name": "สระโบสถ์"
    },
    {
        "subdistrict_id": "724",
        "district_id": "100",
        "subdistrict_name": "มหาโพธิ"
    },
    {
        "subdistrict_id": "725",
        "district_id": "100",
        "subdistrict_name": "ทุ่งท่าช้าง"
    },
    {
        "subdistrict_id": "726",
        "district_id": "100",
        "subdistrict_name": "ห้วยใหญ่"
    },
    {
        "subdistrict_id": "727",
        "district_id": "100",
        "subdistrict_name": "นิยมชัย"
    },
    {
        "subdistrict_id": "728",
        "district_id": "101",
        "subdistrict_name": "โคกเจริญ"
    },
    {
        "subdistrict_id": "729",
        "district_id": "101",
        "subdistrict_name": "ยางราก"
    },
    {
        "subdistrict_id": "730",
        "district_id": "101",
        "subdistrict_name": "หนองมะค่า"
    },
    {
        "subdistrict_id": "731",
        "district_id": "101",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "732",
        "district_id": "101",
        "subdistrict_name": "โคกแสมสาร"
    },
    {
        "subdistrict_id": "733",
        "district_id": "102",
        "subdistrict_name": "ลำสนธิ"
    },
    {
        "subdistrict_id": "734",
        "district_id": "102",
        "subdistrict_name": "ซับสมบูรณ์"
    },
    {
        "subdistrict_id": "735",
        "district_id": "102",
        "subdistrict_name": "หนองรี"
    },
    {
        "subdistrict_id": "736",
        "district_id": "102",
        "subdistrict_name": "กุดตาเพชร"
    },
    {
        "subdistrict_id": "737",
        "district_id": "102",
        "subdistrict_name": "เขารวก"
    },
    {
        "subdistrict_id": "738",
        "district_id": "102",
        "subdistrict_name": "เขาน้อย"
    },
    {
        "subdistrict_id": "739",
        "district_id": "103",
        "subdistrict_name": "หนองม่วง"
    },
    {
        "subdistrict_id": "740",
        "district_id": "103",
        "subdistrict_name": "บ่อทอง"
    },
    {
        "subdistrict_id": "741",
        "district_id": "103",
        "subdistrict_name": "ดงดินแดง"
    },
    {
        "subdistrict_id": "742",
        "district_id": "103",
        "subdistrict_name": "ชอนสมบูรณ์"
    },
    {
        "subdistrict_id": "743",
        "district_id": "103",
        "subdistrict_name": "ยางโทน"
    },
    {
        "subdistrict_id": "744",
        "district_id": "103",
        "subdistrict_name": "ชอนสารเดช"
    },
    {
        "subdistrict_id": "745",
        "district_id": "104",
        "subdistrict_name": "บางพุทรา"
    },
    {
        "subdistrict_id": "746",
        "district_id": "104",
        "subdistrict_name": "บางมัญ"
    },
    {
        "subdistrict_id": "747",
        "district_id": "104",
        "subdistrict_name": "โพกรวม"
    },
    {
        "subdistrict_id": "748",
        "district_id": "104",
        "subdistrict_name": "ม่วงหมู่"
    },
    {
        "subdistrict_id": "749",
        "district_id": "104",
        "subdistrict_name": "หัวไผ่"
    },
    {
        "subdistrict_id": "750",
        "district_id": "104",
        "subdistrict_name": "ต้นโพธิ์"
    },
    {
        "subdistrict_id": "751",
        "district_id": "104",
        "subdistrict_name": "จักรสีห์"
    },
    {
        "subdistrict_id": "752",
        "district_id": "104",
        "subdistrict_name": "บางกระบือ"
    },
    {
        "subdistrict_id": "753",
        "district_id": "105",
        "subdistrict_name": "สิงห์"
    },
    {
        "subdistrict_id": "754",
        "district_id": "105",
        "subdistrict_name": "ไม้ดัด"
    },
    {
        "subdistrict_id": "755",
        "district_id": "105",
        "subdistrict_name": "เชิงกลัด"
    },
    {
        "subdistrict_id": "756",
        "district_id": "105",
        "subdistrict_name": "โพชนไก่"
    },
    {
        "subdistrict_id": "757",
        "district_id": "105",
        "subdistrict_name": "แม่ลา"
    },
    {
        "subdistrict_id": "758",
        "district_id": "105",
        "subdistrict_name": "บ้านจ่า"
    },
    {
        "subdistrict_id": "759",
        "district_id": "105",
        "subdistrict_name": "พักทัน"
    },
    {
        "subdistrict_id": "760",
        "district_id": "105",
        "subdistrict_name": "สระแจง"
    },
    {
        "subdistrict_id": "761",
        "district_id": "106",
        "subdistrict_name": "โพทะเล"
    },
    {
        "subdistrict_id": "762",
        "district_id": "106",
        "subdistrict_name": "บางระจัน"
    },
    {
        "subdistrict_id": "763",
        "district_id": "106",
        "subdistrict_name": "โพสังโฆ"
    },
    {
        "subdistrict_id": "764",
        "district_id": "106",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "765",
        "district_id": "106",
        "subdistrict_name": "คอทราย"
    },
    {
        "subdistrict_id": "766",
        "district_id": "106",
        "subdistrict_name": "หนองกระทุ่ม"
    },
    {
        "subdistrict_id": "767",
        "district_id": "107",
        "subdistrict_name": "พระงาม"
    },
    {
        "subdistrict_id": "768",
        "district_id": "107",
        "subdistrict_name": "พรหมบุรี"
    },
    {
        "subdistrict_id": "769",
        "district_id": "107",
        "subdistrict_name": "บางน้ำเชี่ยว"
    },
    {
        "subdistrict_id": "770",
        "district_id": "107",
        "subdistrict_name": "บ้านหม้อ"
    },
    {
        "subdistrict_id": "771",
        "district_id": "107",
        "subdistrict_name": "บ้านแป้ง"
    },
    {
        "subdistrict_id": "772",
        "district_id": "107",
        "subdistrict_name": "หัวป่า"
    },
    {
        "subdistrict_id": "773",
        "district_id": "107",
        "subdistrict_name": "โรงช้าง"
    },
    {
        "subdistrict_id": "774",
        "district_id": "108",
        "subdistrict_name": "ถอนสมอ"
    },
    {
        "subdistrict_id": "775",
        "district_id": "108",
        "subdistrict_name": "โพประจักษ์"
    },
    {
        "subdistrict_id": "776",
        "district_id": "108",
        "subdistrict_name": "วิหารขาว"
    },
    {
        "subdistrict_id": "777",
        "district_id": "108",
        "subdistrict_name": "พิกุลทอง"
    },
    {
        "subdistrict_id": "778",
        "district_id": "109",
        "subdistrict_name": "อินทร์บุรี"
    },
    {
        "subdistrict_id": "779",
        "district_id": "109",
        "subdistrict_name": "ประศุก"
    },
    {
        "subdistrict_id": "780",
        "district_id": "109",
        "subdistrict_name": "ทับยา"
    },
    {
        "subdistrict_id": "781",
        "district_id": "109",
        "subdistrict_name": "งิ้วราย"
    },
    {
        "subdistrict_id": "782",
        "district_id": "109",
        "subdistrict_name": "ชีน้ำร้าย"
    },
    {
        "subdistrict_id": "783",
        "district_id": "109",
        "subdistrict_name": "ท่างาม"
    },
    {
        "subdistrict_id": "784",
        "district_id": "109",
        "subdistrict_name": "น้ำตาล"
    },
    {
        "subdistrict_id": "785",
        "district_id": "109",
        "subdistrict_name": "ทองเอน"
    },
    {
        "subdistrict_id": "786",
        "district_id": "109",
        "subdistrict_name": "ห้วยชัน"
    },
    {
        "subdistrict_id": "787",
        "district_id": "109",
        "subdistrict_name": "โพธิ์ชัย"
    },
    {
        "subdistrict_id": "788",
        "district_id": "110",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "789",
        "district_id": "110",
        "subdistrict_name": "บ้านกล้วย"
    },
    {
        "subdistrict_id": "790",
        "district_id": "110",
        "subdistrict_name": "ท่าชัย"
    },
    {
        "subdistrict_id": "791",
        "district_id": "110",
        "subdistrict_name": "ชัยนาท"
    },
    {
        "subdistrict_id": "792",
        "district_id": "110",
        "subdistrict_name": "เขาท่าพระ"
    },
    {
        "subdistrict_id": "793",
        "district_id": "110",
        "subdistrict_name": "หาดท่าเสา"
    },
    {
        "subdistrict_id": "794",
        "district_id": "110",
        "subdistrict_name": "ธรรมามูล"
    },
    {
        "subdistrict_id": "795",
        "district_id": "110",
        "subdistrict_name": "เสือโฮก"
    },
    {
        "subdistrict_id": "796",
        "district_id": "110",
        "subdistrict_name": "นางลือ"
    },
    {
        "subdistrict_id": "797",
        "district_id": "111",
        "subdistrict_name": "คุ้งสำเภา"
    },
    {
        "subdistrict_id": "798",
        "district_id": "111",
        "subdistrict_name": "วัดโคก"
    },
    {
        "subdistrict_id": "799",
        "district_id": "111",
        "subdistrict_name": "ศิลาดาน"
    },
    {
        "subdistrict_id": "800",
        "district_id": "111",
        "subdistrict_name": "ท่าฉนวน"
    },
    {
        "subdistrict_id": "801",
        "district_id": "111",
        "subdistrict_name": "หางน้ำสาคร"
    },
    {
        "subdistrict_id": "802",
        "district_id": "111",
        "subdistrict_name": "ไร่พัฒนา"
    },
    {
        "subdistrict_id": "803",
        "district_id": "111",
        "subdistrict_name": "อู่ตะเภา"
    },
    {
        "subdistrict_id": "804",
        "district_id": "112",
        "subdistrict_name": "วัดสิงห์"
    },
    {
        "subdistrict_id": "805",
        "district_id": "112",
        "subdistrict_name": "มะขามเฒ่า"
    },
    {
        "subdistrict_id": "806",
        "district_id": "112",
        "subdistrict_name": "หนองน้อย"
    },
    {
        "subdistrict_id": "807",
        "district_id": "112",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "808",
        "district_id": "112",
        "subdistrict_name": "หนองขุ่น"
    },
    {
        "subdistrict_id": "809",
        "district_id": "112",
        "subdistrict_name": "บ่อแร่"
    },
    {
        "subdistrict_id": "810",
        "district_id": "112",
        "subdistrict_name": "วังหมัน"
    },
    {
        "subdistrict_id": "811",
        "district_id": "113",
        "subdistrict_name": "สรรพยา"
    },
    {
        "subdistrict_id": "812",
        "district_id": "113",
        "subdistrict_name": "ตลุก"
    },
    {
        "subdistrict_id": "813",
        "district_id": "113",
        "subdistrict_name": "เขาแก้ว"
    },
    {
        "subdistrict_id": "814",
        "district_id": "113",
        "subdistrict_name": "โพนางดำตก"
    },
    {
        "subdistrict_id": "815",
        "district_id": "113",
        "subdistrict_name": "โพนางดำออก"
    },
    {
        "subdistrict_id": "816",
        "district_id": "113",
        "subdistrict_name": "บางหลวง"
    },
    {
        "subdistrict_id": "817",
        "district_id": "113",
        "subdistrict_name": "หาดอาษา"
    },
    {
        "subdistrict_id": "818",
        "district_id": "114",
        "subdistrict_name": "แพรกศรีราชา"
    },
    {
        "subdistrict_id": "819",
        "district_id": "114",
        "subdistrict_name": "เที่ยงแท้"
    },
    {
        "subdistrict_id": "820",
        "district_id": "114",
        "subdistrict_name": "ห้วยกรด"
    },
    {
        "subdistrict_id": "821",
        "district_id": "114",
        "subdistrict_name": "โพงาม"
    },
    {
        "subdistrict_id": "822",
        "district_id": "114",
        "subdistrict_name": "บางขุด"
    },
    {
        "subdistrict_id": "823",
        "district_id": "114",
        "subdistrict_name": "ดงคอน"
    },
    {
        "subdistrict_id": "824",
        "district_id": "114",
        "subdistrict_name": "ดอนกำ"
    },
    {
        "subdistrict_id": "825",
        "district_id": "114",
        "subdistrict_name": "ห้วยกรดพัฒนา"
    },
    {
        "subdistrict_id": "826",
        "district_id": "115",
        "subdistrict_name": "หันคา"
    },
    {
        "subdistrict_id": "827",
        "district_id": "115",
        "subdistrict_name": "บ้านเชี่ยน"
    },
    {
        "subdistrict_id": "828",
        "district_id": "115",
        "subdistrict_name": "ไพรนกยูง"
    },
    {
        "subdistrict_id": "829",
        "district_id": "115",
        "subdistrict_name": "หนองแซง"
    },
    {
        "subdistrict_id": "830",
        "district_id": "115",
        "subdistrict_name": "ห้วยงู"
    },
    {
        "subdistrict_id": "831",
        "district_id": "115",
        "subdistrict_name": "วังไก่เถื่อน"
    },
    {
        "subdistrict_id": "832",
        "district_id": "115",
        "subdistrict_name": "เด่นใหญ่"
    },
    {
        "subdistrict_id": "833",
        "district_id": "115",
        "subdistrict_name": "สามง่ามท่าโบสถ์"
    },
    {
        "subdistrict_id": "834",
        "district_id": "116",
        "subdistrict_name": "หนองมะโมง"
    },
    {
        "subdistrict_id": "835",
        "district_id": "116",
        "subdistrict_name": "วังตะเคียน"
    },
    {
        "subdistrict_id": "836",
        "district_id": "116",
        "subdistrict_name": "สะพานหิน"
    },
    {
        "subdistrict_id": "837",
        "district_id": "116",
        "subdistrict_name": "กุดจอก"
    },
    {
        "subdistrict_id": "838",
        "district_id": "117",
        "subdistrict_name": "เนินขาม"
    },
    {
        "subdistrict_id": "839",
        "district_id": "117",
        "subdistrict_name": "กะบกเตี้ย"
    },
    {
        "subdistrict_id": "840",
        "district_id": "117",
        "subdistrict_name": "สุขเดือนห้า"
    },
    {
        "subdistrict_id": "841",
        "district_id": "118",
        "subdistrict_name": "ปากเพรียว"
    },
    {
        "subdistrict_id": "842",
        "district_id": "118",
        "subdistrict_name": "ดาวเรือง"
    },
    {
        "subdistrict_id": "843",
        "district_id": "118",
        "subdistrict_name": "นาโฉง"
    },
    {
        "subdistrict_id": "844",
        "district_id": "118",
        "subdistrict_name": "โคกสว่าง"
    },
    {
        "subdistrict_id": "845",
        "district_id": "118",
        "subdistrict_name": "หนองโน"
    },
    {
        "subdistrict_id": "846",
        "district_id": "118",
        "subdistrict_name": "หนองยาว"
    },
    {
        "subdistrict_id": "847",
        "district_id": "118",
        "subdistrict_name": "ปากข้าวสาร"
    },
    {
        "subdistrict_id": "848",
        "district_id": "118",
        "subdistrict_name": "หนองปลาไหล"
    },
    {
        "subdistrict_id": "849",
        "district_id": "118",
        "subdistrict_name": "กุดนกเปล้า"
    },
    {
        "subdistrict_id": "850",
        "district_id": "118",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "851",
        "district_id": "118",
        "subdistrict_name": "ตะกุด"
    },
    {
        "subdistrict_id": "852",
        "district_id": "119",
        "subdistrict_name": "แก่งคอย"
    },
    {
        "subdistrict_id": "853",
        "district_id": "119",
        "subdistrict_name": "ทับกวาง"
    },
    {
        "subdistrict_id": "854",
        "district_id": "119",
        "subdistrict_name": "ตาลเดี่ยว"
    },
    {
        "subdistrict_id": "855",
        "district_id": "119",
        "subdistrict_name": "ห้วยแห้ง"
    },
    {
        "subdistrict_id": "856",
        "district_id": "119",
        "subdistrict_name": "ท่าคล้อ"
    },
    {
        "subdistrict_id": "857",
        "district_id": "119",
        "subdistrict_name": "หินซ้อน"
    },
    {
        "subdistrict_id": "858",
        "district_id": "119",
        "subdistrict_name": "บ้านธาตุ"
    },
    {
        "subdistrict_id": "859",
        "district_id": "119",
        "subdistrict_name": "บ้านป่า"
    },
    {
        "subdistrict_id": "860",
        "district_id": "119",
        "subdistrict_name": "ท่าตูม"
    },
    {
        "subdistrict_id": "861",
        "district_id": "119",
        "subdistrict_name": "ชะอม"
    },
    {
        "subdistrict_id": "862",
        "district_id": "119",
        "subdistrict_name": "สองคอน"
    },
    {
        "subdistrict_id": "863",
        "district_id": "119",
        "subdistrict_name": "เตาปูน"
    },
    {
        "subdistrict_id": "864",
        "district_id": "119",
        "subdistrict_name": "ชำผักแพว"
    },
    {
        "subdistrict_id": "865",
        "district_id": "119",
        "subdistrict_name": "ท่ามะปราง"
    },
    {
        "subdistrict_id": "866",
        "district_id": "120",
        "subdistrict_name": "หนองแค"
    },
    {
        "subdistrict_id": "867",
        "district_id": "120",
        "subdistrict_name": "กุ่มหัก"
    },
    {
        "subdistrict_id": "868",
        "district_id": "120",
        "subdistrict_name": "คชสิทธิ์"
    },
    {
        "subdistrict_id": "869",
        "district_id": "120",
        "subdistrict_name": "โคกตูม"
    },
    {
        "subdistrict_id": "870",
        "district_id": "120",
        "subdistrict_name": "โคกแย้"
    },
    {
        "subdistrict_id": "871",
        "district_id": "120",
        "subdistrict_name": "บัวลอย"
    },
    {
        "subdistrict_id": "872",
        "district_id": "120",
        "subdistrict_name": "ไผ่ต่ำ"
    },
    {
        "subdistrict_id": "873",
        "district_id": "120",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "874",
        "district_id": "120",
        "subdistrict_name": "ห้วยขมิ้น"
    },
    {
        "subdistrict_id": "875",
        "district_id": "120",
        "subdistrict_name": "ห้วยทราย"
    },
    {
        "subdistrict_id": "876",
        "district_id": "120",
        "subdistrict_name": "หนองไข่น้ำ"
    },
    {
        "subdistrict_id": "877",
        "district_id": "120",
        "subdistrict_name": "หนองแขม"
    },
    {
        "subdistrict_id": "878",
        "district_id": "120",
        "subdistrict_name": "หนองจิก"
    },
    {
        "subdistrict_id": "879",
        "district_id": "120",
        "subdistrict_name": "หนองจรเข้"
    },
    {
        "subdistrict_id": "880",
        "district_id": "120",
        "subdistrict_name": "หนองนาก"
    },
    {
        "subdistrict_id": "881",
        "district_id": "120",
        "subdistrict_name": "หนองปลาหมอ"
    },
    {
        "subdistrict_id": "882",
        "district_id": "120",
        "subdistrict_name": "หนองปลิง"
    },
    {
        "subdistrict_id": "883",
        "district_id": "120",
        "subdistrict_name": "หนองโรง"
    },
    {
        "subdistrict_id": "884",
        "district_id": "121",
        "subdistrict_name": "หนองหมู"
    },
    {
        "subdistrict_id": "885",
        "district_id": "121",
        "subdistrict_name": "บ้านลำ"
    },
    {
        "subdistrict_id": "886",
        "district_id": "121",
        "subdistrict_name": "คลองเรือ"
    },
    {
        "subdistrict_id": "887",
        "district_id": "121",
        "subdistrict_name": "วิหารแดง"
    },
    {
        "subdistrict_id": "888",
        "district_id": "121",
        "subdistrict_name": "หนองสรวง"
    },
    {
        "subdistrict_id": "889",
        "district_id": "121",
        "subdistrict_name": "เจริญธรรม"
    },
    {
        "subdistrict_id": "890",
        "district_id": "122",
        "subdistrict_name": "หนองแซง"
    },
    {
        "subdistrict_id": "891",
        "district_id": "122",
        "subdistrict_name": "หนองควายโซ"
    },
    {
        "subdistrict_id": "892",
        "district_id": "122",
        "subdistrict_name": "หนองหัวโพ"
    },
    {
        "subdistrict_id": "893",
        "district_id": "122",
        "subdistrict_name": "หนองสีดา"
    },
    {
        "subdistrict_id": "894",
        "district_id": "122",
        "subdistrict_name": "หนองกบ"
    },
    {
        "subdistrict_id": "895",
        "district_id": "122",
        "subdistrict_name": "ไก่เส่า"
    },
    {
        "subdistrict_id": "896",
        "district_id": "122",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "897",
        "district_id": "122",
        "subdistrict_name": "ม่วงหวาน"
    },
    {
        "subdistrict_id": "898",
        "district_id": "122",
        "subdistrict_name": "เขาดิน"
    },
    {
        "subdistrict_id": "899",
        "district_id": "123",
        "subdistrict_name": "บ้านหมอ"
    },
    {
        "subdistrict_id": "900",
        "district_id": "123",
        "subdistrict_name": "บางโขมด"
    },
    {
        "subdistrict_id": "901",
        "district_id": "123",
        "subdistrict_name": "สร่างโศก"
    },
    {
        "subdistrict_id": "902",
        "district_id": "123",
        "subdistrict_name": "ตลาดน้อย"
    },
    {
        "subdistrict_id": "903",
        "district_id": "123",
        "subdistrict_name": "หรเทพ"
    },
    {
        "subdistrict_id": "904",
        "district_id": "123",
        "subdistrict_name": "โคกใหญ่"
    },
    {
        "subdistrict_id": "905",
        "district_id": "123",
        "subdistrict_name": "ไผ่ขวาง"
    },
    {
        "subdistrict_id": "906",
        "district_id": "123",
        "subdistrict_name": "บ้านครัว"
    },
    {
        "subdistrict_id": "907",
        "district_id": "123",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "908",
        "district_id": "124",
        "subdistrict_name": "ดอนพุด"
    },
    {
        "subdistrict_id": "909",
        "district_id": "124",
        "subdistrict_name": "ไผ่หลิ่ว"
    },
    {
        "subdistrict_id": "910",
        "district_id": "124",
        "subdistrict_name": "บ้านหลวง"
    },
    {
        "subdistrict_id": "911",
        "district_id": "124",
        "subdistrict_name": "ดงตะงาว"
    },
    {
        "subdistrict_id": "912",
        "district_id": "125",
        "subdistrict_name": "หนองโดน"
    },
    {
        "subdistrict_id": "913",
        "district_id": "125",
        "subdistrict_name": "บ้านกลับ"
    },
    {
        "subdistrict_id": "914",
        "district_id": "125",
        "subdistrict_name": "ดอนทอง"
    },
    {
        "subdistrict_id": "915",
        "district_id": "125",
        "subdistrict_name": "บ้านโปร่ง"
    },
    {
        "subdistrict_id": "916",
        "district_id": "126",
        "subdistrict_name": "พระพุทธบาท"
    },
    {
        "subdistrict_id": "917",
        "district_id": "126",
        "subdistrict_name": "ขุนโขลน"
    },
    {
        "subdistrict_id": "918",
        "district_id": "126",
        "subdistrict_name": "ธารเกษม"
    },
    {
        "subdistrict_id": "919",
        "district_id": "126",
        "subdistrict_name": "นายาว"
    },
    {
        "subdistrict_id": "920",
        "district_id": "126",
        "subdistrict_name": "พุคำจาน"
    },
    {
        "subdistrict_id": "921",
        "district_id": "126",
        "subdistrict_name": "เขาวง"
    },
    {
        "subdistrict_id": "922",
        "district_id": "126",
        "subdistrict_name": "ห้วยป่าหวาย"
    },
    {
        "subdistrict_id": "923",
        "district_id": "126",
        "subdistrict_name": "พุกร่าง"
    },
    {
        "subdistrict_id": "924",
        "district_id": "126",
        "subdistrict_name": "หนองแก"
    },
    {
        "subdistrict_id": "925",
        "district_id": "127",
        "subdistrict_name": "เสาไห้"
    },
    {
        "subdistrict_id": "926",
        "district_id": "127",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "927",
        "district_id": "127",
        "subdistrict_name": "หัวปลวก"
    },
    {
        "subdistrict_id": "928",
        "district_id": "127",
        "subdistrict_name": "งิ้วงาม"
    },
    {
        "subdistrict_id": "929",
        "district_id": "127",
        "subdistrict_name": "ศาลารีไทย"
    },
    {
        "subdistrict_id": "930",
        "district_id": "127",
        "subdistrict_name": "ต้นตาล"
    },
    {
        "subdistrict_id": "931",
        "district_id": "127",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "932",
        "district_id": "127",
        "subdistrict_name": "พระยาทด"
    },
    {
        "subdistrict_id": "933",
        "district_id": "127",
        "subdistrict_name": "ม่วงงาม"
    },
    {
        "subdistrict_id": "934",
        "district_id": "127",
        "subdistrict_name": "เริงราง"
    },
    {
        "subdistrict_id": "935",
        "district_id": "127",
        "subdistrict_name": "เมืองเก่า"
    },
    {
        "subdistrict_id": "936",
        "district_id": "127",
        "subdistrict_name": "สวนดอกไม้"
    },
    {
        "subdistrict_id": "937",
        "district_id": "128",
        "subdistrict_name": "มวกเหล็ก"
    },
    {
        "subdistrict_id": "938",
        "district_id": "128",
        "subdistrict_name": "มิตรภาพ"
    },
    {
        "subdistrict_id": "939",
        "district_id": "128",
        "subdistrict_name": "หนองย่างเสือ"
    },
    {
        "subdistrict_id": "940",
        "district_id": "128",
        "subdistrict_name": "ลำสมพุง"
    },
    {
        "subdistrict_id": "941",
        "district_id": "128",
        "subdistrict_name": "ลำพญากลาง"
    },
    {
        "subdistrict_id": "942",
        "district_id": "128",
        "subdistrict_name": "ซับสนุ่น"
    },
    {
        "subdistrict_id": "943",
        "district_id": "129",
        "subdistrict_name": "แสลงพัน"
    },
    {
        "subdistrict_id": "944",
        "district_id": "129",
        "subdistrict_name": "คำพราน"
    },
    {
        "subdistrict_id": "945",
        "district_id": "129",
        "subdistrict_name": "วังม่วง"
    },
    {
        "subdistrict_id": "946",
        "district_id": "130",
        "subdistrict_name": "เขาดินพัฒนา"
    },
    {
        "subdistrict_id": "947",
        "district_id": "130",
        "subdistrict_name": "บ้านแก้ง"
    },
    {
        "subdistrict_id": "948",
        "district_id": "130",
        "subdistrict_name": "ผึ้งรวง"
    },
    {
        "subdistrict_id": "949",
        "district_id": "130",
        "subdistrict_name": "พุแค"
    },
    {
        "subdistrict_id": "950",
        "district_id": "130",
        "subdistrict_name": "ห้วยบง"
    },
    {
        "subdistrict_id": "951",
        "district_id": "130",
        "subdistrict_name": "หน้าพระลาน"
    },
    {
        "subdistrict_id": "952",
        "district_id": "131",
        "subdistrict_name": "บางปลาสร้อย"
    },
    {
        "subdistrict_id": "953",
        "district_id": "131",
        "subdistrict_name": "มะขามหย่ง"
    },
    {
        "subdistrict_id": "954",
        "district_id": "131",
        "subdistrict_name": "บ้านโขด"
    },
    {
        "subdistrict_id": "955",
        "district_id": "131",
        "subdistrict_name": "แสนสุข"
    },
    {
        "subdistrict_id": "956",
        "district_id": "131",
        "subdistrict_name": "บ้านสวน"
    },
    {
        "subdistrict_id": "957",
        "district_id": "131",
        "subdistrict_name": "หนองรี"
    },
    {
        "subdistrict_id": "958",
        "district_id": "131",
        "subdistrict_name": "นาป่า"
    },
    {
        "subdistrict_id": "959",
        "district_id": "131",
        "subdistrict_name": "หนองข้างคอก"
    },
    {
        "subdistrict_id": "960",
        "district_id": "131",
        "subdistrict_name": "ดอนหัวฬ่อ"
    },
    {
        "subdistrict_id": "961",
        "district_id": "131",
        "subdistrict_name": "หนองไม้แดง"
    },
    {
        "subdistrict_id": "962",
        "district_id": "131",
        "subdistrict_name": "บางทราย"
    },
    {
        "subdistrict_id": "963",
        "district_id": "131",
        "subdistrict_name": "คลองตำหรุ"
    },
    {
        "subdistrict_id": "964",
        "district_id": "131",
        "subdistrict_name": "เหมือง"
    },
    {
        "subdistrict_id": "965",
        "district_id": "131",
        "subdistrict_name": "บ้านปึก"
    },
    {
        "subdistrict_id": "966",
        "district_id": "131",
        "subdistrict_name": "ห้วยกะปิ"
    },
    {
        "subdistrict_id": "967",
        "district_id": "131",
        "subdistrict_name": "เสม็ด"
    },
    {
        "subdistrict_id": "968",
        "district_id": "131",
        "subdistrict_name": "อ่างศิลา"
    },
    {
        "subdistrict_id": "969",
        "district_id": "131",
        "subdistrict_name": "สำนักบก"
    },
    {
        "subdistrict_id": "970",
        "district_id": "132",
        "subdistrict_name": "บ้านบึง"
    },
    {
        "subdistrict_id": "971",
        "district_id": "132",
        "subdistrict_name": "คลองกิ่ว"
    },
    {
        "subdistrict_id": "972",
        "district_id": "132",
        "subdistrict_name": "มาบไผ่"
    },
    {
        "subdistrict_id": "973",
        "district_id": "132",
        "subdistrict_name": "หนองซ้ำซาก"
    },
    {
        "subdistrict_id": "974",
        "district_id": "132",
        "subdistrict_name": "หนองบอนแดง"
    },
    {
        "subdistrict_id": "975",
        "district_id": "132",
        "subdistrict_name": "หนองชาก"
    },
    {
        "subdistrict_id": "976",
        "district_id": "132",
        "subdistrict_name": "หนองอิรุณ"
    },
    {
        "subdistrict_id": "977",
        "district_id": "132",
        "subdistrict_name": "หนองไผ่แก้ว"
    },
    {
        "subdistrict_id": "978",
        "district_id": "133",
        "subdistrict_name": "หนองใหญ่"
    },
    {
        "subdistrict_id": "979",
        "district_id": "133",
        "subdistrict_name": "คลองพลู"
    },
    {
        "subdistrict_id": "980",
        "district_id": "133",
        "subdistrict_name": "หนองเสือช้าง"
    },
    {
        "subdistrict_id": "981",
        "district_id": "133",
        "subdistrict_name": "ห้างสูง"
    },
    {
        "subdistrict_id": "982",
        "district_id": "133",
        "subdistrict_name": "เขาซก"
    },
    {
        "subdistrict_id": "983",
        "district_id": "134",
        "subdistrict_name": "บางละมุง"
    },
    {
        "subdistrict_id": "984",
        "district_id": "134",
        "subdistrict_name": "หนองปรือ"
    },
    {
        "subdistrict_id": "985",
        "district_id": "134",
        "subdistrict_name": "หนองปลาไหล"
    },
    {
        "subdistrict_id": "986",
        "district_id": "134",
        "subdistrict_name": "โป่ง"
    },
    {
        "subdistrict_id": "987",
        "district_id": "134",
        "subdistrict_name": "เขาไม้แก้ว"
    },
    {
        "subdistrict_id": "988",
        "district_id": "134",
        "subdistrict_name": "ห้วยใหญ่"
    },
    {
        "subdistrict_id": "989",
        "district_id": "134",
        "subdistrict_name": "ตะเคียนเตี้ย"
    },
    {
        "subdistrict_id": "990",
        "district_id": "134",
        "subdistrict_name": "นาเกลือ"
    },
    {
        "subdistrict_id": "991",
        "district_id": "135",
        "subdistrict_name": "พานทอง"
    },
    {
        "subdistrict_id": "992",
        "district_id": "135",
        "subdistrict_name": "หนองตำลึง"
    },
    {
        "subdistrict_id": "993",
        "district_id": "135",
        "subdistrict_name": "มาบโป่ง"
    },
    {
        "subdistrict_id": "994",
        "district_id": "135",
        "subdistrict_name": "หนองกะขะ"
    },
    {
        "subdistrict_id": "995",
        "district_id": "135",
        "subdistrict_name": "หนองหงษ์"
    },
    {
        "subdistrict_id": "996",
        "district_id": "135",
        "subdistrict_name": "โคกขี้หนอน"
    },
    {
        "subdistrict_id": "997",
        "district_id": "135",
        "subdistrict_name": "บ้านเก่า"
    },
    {
        "subdistrict_id": "998",
        "district_id": "135",
        "subdistrict_name": "หน้าประดู่"
    },
    {
        "subdistrict_id": "999",
        "district_id": "135",
        "subdistrict_name": "บางนาง"
    },
    {
        "subdistrict_id": "1000",
        "district_id": "135",
        "subdistrict_name": "เกาะลอย"
    },
    {
        "subdistrict_id": "1001",
        "district_id": "135",
        "subdistrict_name": "บางหัก"
    },
    {
        "subdistrict_id": "1002",
        "district_id": "136",
        "subdistrict_name": "พนัสนิคม"
    },
    {
        "subdistrict_id": "1003",
        "district_id": "136",
        "subdistrict_name": "หน้าพระธาตุ"
    },
    {
        "subdistrict_id": "1004",
        "district_id": "136",
        "subdistrict_name": "วัดหลวง"
    },
    {
        "subdistrict_id": "1005",
        "district_id": "136",
        "subdistrict_name": "บ้านเซิด"
    },
    {
        "subdistrict_id": "1006",
        "district_id": "136",
        "subdistrict_name": "นาเริก"
    },
    {
        "subdistrict_id": "1007",
        "district_id": "136",
        "subdistrict_name": "หมอนนาง"
    },
    {
        "subdistrict_id": "1008",
        "district_id": "136",
        "subdistrict_name": "สระสี่เหลี่ยม"
    },
    {
        "subdistrict_id": "1009",
        "district_id": "136",
        "subdistrict_name": "วัดโบสถ์"
    },
    {
        "subdistrict_id": "1010",
        "district_id": "136",
        "subdistrict_name": "กุฎโง้ง"
    },
    {
        "subdistrict_id": "1011",
        "district_id": "136",
        "subdistrict_name": "หัวถนน"
    },
    {
        "subdistrict_id": "1012",
        "district_id": "136",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "1013",
        "district_id": "136",
        "subdistrict_name": "หนองปรือ"
    },
    {
        "subdistrict_id": "1014",
        "district_id": "136",
        "subdistrict_name": "หนองขยาด"
    },
    {
        "subdistrict_id": "1015",
        "district_id": "136",
        "subdistrict_name": "ทุ่งขวาง"
    },
    {
        "subdistrict_id": "1016",
        "district_id": "136",
        "subdistrict_name": "หนองเหียง"
    },
    {
        "subdistrict_id": "1017",
        "district_id": "136",
        "subdistrict_name": "นาวังหิน"
    },
    {
        "subdistrict_id": "1018",
        "district_id": "136",
        "subdistrict_name": "บ้านช้าง"
    },
    {
        "subdistrict_id": "1019",
        "district_id": "136",
        "subdistrict_name": "โคกเพลาะ"
    },
    {
        "subdistrict_id": "1020",
        "district_id": "136",
        "subdistrict_name": "ไร่หลักทอง"
    },
    {
        "subdistrict_id": "1021",
        "district_id": "136",
        "subdistrict_name": "นามะตูม"
    },
    {
        "subdistrict_id": "1022",
        "district_id": "137",
        "subdistrict_name": "ศรีราชา"
    },
    {
        "subdistrict_id": "1023",
        "district_id": "137",
        "subdistrict_name": "สุรศักดิ์"
    },
    {
        "subdistrict_id": "1024",
        "district_id": "137",
        "subdistrict_name": "ทุ่งสุขลา"
    },
    {
        "subdistrict_id": "1025",
        "district_id": "137",
        "subdistrict_name": "บึง"
    },
    {
        "subdistrict_id": "1026",
        "district_id": "137",
        "subdistrict_name": "หนองขาม"
    },
    {
        "subdistrict_id": "1027",
        "district_id": "137",
        "subdistrict_name": "เขาคันทรง"
    },
    {
        "subdistrict_id": "1028",
        "district_id": "137",
        "subdistrict_name": "บางพระ"
    },
    {
        "subdistrict_id": "1029",
        "district_id": "137",
        "subdistrict_name": "บ่อวิน"
    },
    {
        "subdistrict_id": "1030",
        "district_id": "138",
        "subdistrict_name": "ท่าเทววงษ์"
    },
    {
        "subdistrict_id": "1031",
        "district_id": "139",
        "subdistrict_name": "สัตหีบ"
    },
    {
        "subdistrict_id": "1032",
        "district_id": "139",
        "subdistrict_name": "นาจอมเทียน"
    },
    {
        "subdistrict_id": "1033",
        "district_id": "139",
        "subdistrict_name": "พลูตาหลวง"
    },
    {
        "subdistrict_id": "1034",
        "district_id": "139",
        "subdistrict_name": "บางเสร่"
    },
    {
        "subdistrict_id": "1035",
        "district_id": "139",
        "subdistrict_name": "แสมสาร"
    },
    {
        "subdistrict_id": "1036",
        "district_id": "140",
        "subdistrict_name": "บ่อทอง"
    },
    {
        "subdistrict_id": "1037",
        "district_id": "140",
        "subdistrict_name": "วัดสุวรรณ"
    },
    {
        "subdistrict_id": "1038",
        "district_id": "140",
        "subdistrict_name": "บ่อกวางทอง"
    },
    {
        "subdistrict_id": "1039",
        "district_id": "140",
        "subdistrict_name": "ธาตุทอง"
    },
    {
        "subdistrict_id": "1040",
        "district_id": "140",
        "subdistrict_name": "เกษตรสุวรรณ"
    },
    {
        "subdistrict_id": "1041",
        "district_id": "140",
        "subdistrict_name": "พลวงทอง"
    },
    {
        "subdistrict_id": "1042",
        "district_id": "141",
        "subdistrict_name": "เกาะจันทร์"
    },
    {
        "subdistrict_id": "1043",
        "district_id": "141",
        "subdistrict_name": "ท่าบุญมี"
    },
    {
        "subdistrict_id": "1044",
        "district_id": "142",
        "subdistrict_name": "ท่าประดู่"
    },
    {
        "subdistrict_id": "1045",
        "district_id": "142",
        "subdistrict_name": "เชิงเนิน"
    },
    {
        "subdistrict_id": "1046",
        "district_id": "142",
        "subdistrict_name": "ตะพง"
    },
    {
        "subdistrict_id": "1047",
        "district_id": "142",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "1048",
        "district_id": "142",
        "subdistrict_name": "เพ"
    },
    {
        "subdistrict_id": "1049",
        "district_id": "142",
        "subdistrict_name": "แกลง"
    },
    {
        "subdistrict_id": "1050",
        "district_id": "142",
        "subdistrict_name": "บ้านแลง"
    },
    {
        "subdistrict_id": "1051",
        "district_id": "142",
        "subdistrict_name": "นาตาขวัญ"
    },
    {
        "subdistrict_id": "1052",
        "district_id": "142",
        "subdistrict_name": "เนินพระ"
    },
    {
        "subdistrict_id": "1053",
        "district_id": "142",
        "subdistrict_name": "กะเฉด"
    },
    {
        "subdistrict_id": "1054",
        "district_id": "142",
        "subdistrict_name": "ทับมา"
    },
    {
        "subdistrict_id": "1055",
        "district_id": "142",
        "subdistrict_name": "น้ำคอก"
    },
    {
        "subdistrict_id": "1056",
        "district_id": "142",
        "subdistrict_name": "ห้วยโป่ง"
    },
    {
        "subdistrict_id": "1057",
        "district_id": "142",
        "subdistrict_name": "มาบตาพุด"
    },
    {
        "subdistrict_id": "1058",
        "district_id": "142",
        "subdistrict_name": "สำนักทอง"
    },
    {
        "subdistrict_id": "1059",
        "district_id": "143",
        "subdistrict_name": "สำนักท้อน"
    },
    {
        "subdistrict_id": "1060",
        "district_id": "143",
        "subdistrict_name": "พลา"
    },
    {
        "subdistrict_id": "1061",
        "district_id": "143",
        "subdistrict_name": "บ้านฉาง"
    },
    {
        "subdistrict_id": "1062",
        "district_id": "144",
        "subdistrict_name": "ทางเกวียน"
    },
    {
        "subdistrict_id": "1063",
        "district_id": "144",
        "subdistrict_name": "วังหว้า"
    },
    {
        "subdistrict_id": "1064",
        "district_id": "144",
        "subdistrict_name": "ชากโดน"
    },
    {
        "subdistrict_id": "1065",
        "district_id": "144",
        "subdistrict_name": "เนินฆ้อ"
    },
    {
        "subdistrict_id": "1066",
        "district_id": "144",
        "subdistrict_name": "กร่ำ"
    },
    {
        "subdistrict_id": "1067",
        "district_id": "144",
        "subdistrict_name": "ชากพง"
    },
    {
        "subdistrict_id": "1068",
        "district_id": "144",
        "subdistrict_name": "กระแสบน"
    },
    {
        "subdistrict_id": "1069",
        "district_id": "144",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "1070",
        "district_id": "144",
        "subdistrict_name": "ทุ่งควายกิน"
    },
    {
        "subdistrict_id": "1071",
        "district_id": "144",
        "subdistrict_name": "กองดิน"
    },
    {
        "subdistrict_id": "1072",
        "district_id": "144",
        "subdistrict_name": "คลองปูน"
    },
    {
        "subdistrict_id": "1073",
        "district_id": "144",
        "subdistrict_name": "พังราด"
    },
    {
        "subdistrict_id": "1074",
        "district_id": "144",
        "subdistrict_name": "ปากน้ำกระแส"
    },
    {
        "subdistrict_id": "1075",
        "district_id": "144",
        "subdistrict_name": "ห้วยยาง"
    },
    {
        "subdistrict_id": "1076",
        "district_id": "144",
        "subdistrict_name": "สองสลึง"
    },
    {
        "subdistrict_id": "1077",
        "district_id": "145",
        "subdistrict_name": "วังจันทร์"
    },
    {
        "subdistrict_id": "1078",
        "district_id": "145",
        "subdistrict_name": "ชุมแสง"
    },
    {
        "subdistrict_id": "1079",
        "district_id": "145",
        "subdistrict_name": "ป่ายุบใน"
    },
    {
        "subdistrict_id": "1080",
        "district_id": "145",
        "subdistrict_name": "พลงตาเอี่ยม"
    },
    {
        "subdistrict_id": "1081",
        "district_id": "146",
        "subdistrict_name": "บ้านค่าย"
    },
    {
        "subdistrict_id": "1082",
        "district_id": "146",
        "subdistrict_name": "หนองละลอก"
    },
    {
        "subdistrict_id": "1083",
        "district_id": "146",
        "subdistrict_name": "หนองตะพาน"
    },
    {
        "subdistrict_id": "1084",
        "district_id": "146",
        "subdistrict_name": "ตาขัน"
    },
    {
        "subdistrict_id": "1085",
        "district_id": "146",
        "subdistrict_name": "บางบุตร"
    },
    {
        "subdistrict_id": "1086",
        "district_id": "146",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "1087",
        "district_id": "146",
        "subdistrict_name": "ชากบก"
    },
    {
        "subdistrict_id": "1088",
        "district_id": "147",
        "subdistrict_name": "ปลวกแดง"
    },
    {
        "subdistrict_id": "1089",
        "district_id": "147",
        "subdistrict_name": "ตาสิทธิ์"
    },
    {
        "subdistrict_id": "1090",
        "district_id": "147",
        "subdistrict_name": "ละหาร"
    },
    {
        "subdistrict_id": "1091",
        "district_id": "147",
        "subdistrict_name": "แม่น้ำคู้"
    },
    {
        "subdistrict_id": "1092",
        "district_id": "147",
        "subdistrict_name": "มาบยางพร"
    },
    {
        "subdistrict_id": "1093",
        "district_id": "147",
        "subdistrict_name": "หนองไร่"
    },
    {
        "subdistrict_id": "1094",
        "district_id": "148",
        "subdistrict_name": "น้ำเป็น"
    },
    {
        "subdistrict_id": "1095",
        "district_id": "148",
        "subdistrict_name": "ห้วยทับมอญ"
    },
    {
        "subdistrict_id": "1096",
        "district_id": "148",
        "subdistrict_name": "ชำฆ้อ"
    },
    {
        "subdistrict_id": "1097",
        "district_id": "148",
        "subdistrict_name": "เขาน้อย"
    },
    {
        "subdistrict_id": "1098",
        "district_id": "149",
        "subdistrict_name": "นิคมพัฒนา"
    },
    {
        "subdistrict_id": "1099",
        "district_id": "149",
        "subdistrict_name": "มาบข่า"
    },
    {
        "subdistrict_id": "1100",
        "district_id": "149",
        "subdistrict_name": "พนานิคม"
    },
    {
        "subdistrict_id": "1101",
        "district_id": "149",
        "subdistrict_name": "มะขามคู่"
    },
    {
        "subdistrict_id": "1102",
        "district_id": "150",
        "subdistrict_name": "ตลาด"
    },
    {
        "subdistrict_id": "1103",
        "district_id": "150",
        "subdistrict_name": "วัดใหม่"
    },
    {
        "subdistrict_id": "1104",
        "district_id": "150",
        "subdistrict_name": "คลองนารายณ์"
    },
    {
        "subdistrict_id": "1105",
        "district_id": "150",
        "subdistrict_name": "เกาะขวาง"
    },
    {
        "subdistrict_id": "1106",
        "district_id": "150",
        "subdistrict_name": "คมบาง"
    },
    {
        "subdistrict_id": "1107",
        "district_id": "150",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "1108",
        "district_id": "150",
        "subdistrict_name": "จันทนิมิต"
    },
    {
        "subdistrict_id": "1109",
        "district_id": "150",
        "subdistrict_name": "บางกะจะ"
    },
    {
        "subdistrict_id": "1110",
        "district_id": "150",
        "subdistrict_name": "แสลง"
    },
    {
        "subdistrict_id": "1111",
        "district_id": "150",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "1112",
        "district_id": "150",
        "subdistrict_name": "พลับพลา"
    },
    {
        "subdistrict_id": "1113",
        "district_id": "151",
        "subdistrict_name": "ขลุง"
    },
    {
        "subdistrict_id": "1114",
        "district_id": "151",
        "subdistrict_name": "บ่อ"
    },
    {
        "subdistrict_id": "1115",
        "district_id": "151",
        "subdistrict_name": "เกวียนหัก"
    },
    {
        "subdistrict_id": "1116",
        "district_id": "151",
        "subdistrict_name": "ตะปอน"
    },
    {
        "subdistrict_id": "1117",
        "district_id": "151",
        "subdistrict_name": "บางชัน"
    },
    {
        "subdistrict_id": "1118",
        "district_id": "151",
        "subdistrict_name": "วันยาว"
    },
    {
        "subdistrict_id": "1119",
        "district_id": "151",
        "subdistrict_name": "ซึ้ง"
    },
    {
        "subdistrict_id": "1120",
        "district_id": "151",
        "subdistrict_name": "มาบไพ"
    },
    {
        "subdistrict_id": "1121",
        "district_id": "151",
        "subdistrict_name": "วังสรรพรส"
    },
    {
        "subdistrict_id": "1122",
        "district_id": "151",
        "subdistrict_name": "ตรอกนอง"
    },
    {
        "subdistrict_id": "1123",
        "district_id": "151",
        "subdistrict_name": "ตกพรม"
    },
    {
        "subdistrict_id": "1124",
        "district_id": "151",
        "subdistrict_name": "บ่อเวฬุ"
    },
    {
        "subdistrict_id": "1125",
        "district_id": "152",
        "subdistrict_name": "ท่าใหม่"
    },
    {
        "subdistrict_id": "1126",
        "district_id": "152",
        "subdistrict_name": "ยายร้า"
    },
    {
        "subdistrict_id": "1127",
        "district_id": "152",
        "subdistrict_name": "สีพยา"
    },
    {
        "subdistrict_id": "1128",
        "district_id": "152",
        "subdistrict_name": "บ่อพุ"
    },
    {
        "subdistrict_id": "1129",
        "district_id": "152",
        "subdistrict_name": "พลอยแหวน"
    },
    {
        "subdistrict_id": "1130",
        "district_id": "152",
        "subdistrict_name": "เขาวัว"
    },
    {
        "subdistrict_id": "1131",
        "district_id": "152",
        "subdistrict_name": "เขาบายศรี"
    },
    {
        "subdistrict_id": "1132",
        "district_id": "152",
        "subdistrict_name": "สองพี่น้อง"
    },
    {
        "subdistrict_id": "1133",
        "district_id": "152",
        "subdistrict_name": "ทุ่งเบญจา"
    },
    {
        "subdistrict_id": "1134",
        "district_id": "152",
        "subdistrict_name": "รำพัน"
    },
    {
        "subdistrict_id": "1135",
        "district_id": "152",
        "subdistrict_name": "โขมง"
    },
    {
        "subdistrict_id": "1136",
        "district_id": "152",
        "subdistrict_name": "ตะกาดเง้า"
    },
    {
        "subdistrict_id": "1137",
        "district_id": "152",
        "subdistrict_name": "คลองขุด"
    },
    {
        "subdistrict_id": "1138",
        "district_id": "152",
        "subdistrict_name": "เขาแก้ว"
    },
    {
        "subdistrict_id": "1139",
        "district_id": "153",
        "subdistrict_name": "ทับไทร"
    },
    {
        "subdistrict_id": "1140",
        "district_id": "153",
        "subdistrict_name": "โป่งน้ำร้อน"
    },
    {
        "subdistrict_id": "1141",
        "district_id": "153",
        "subdistrict_name": "หนองตาคง"
    },
    {
        "subdistrict_id": "1142",
        "district_id": "153",
        "subdistrict_name": "เทพนิมิต"
    },
    {
        "subdistrict_id": "1143",
        "district_id": "153",
        "subdistrict_name": "คลองใหญ่"
    },
    {
        "subdistrict_id": "1144",
        "district_id": "154",
        "subdistrict_name": "มะขาม"
    },
    {
        "subdistrict_id": "1145",
        "district_id": "154",
        "subdistrict_name": "ท่าหลวง"
    },
    {
        "subdistrict_id": "1146",
        "district_id": "154",
        "subdistrict_name": "ปัถวี"
    },
    {
        "subdistrict_id": "1147",
        "district_id": "154",
        "subdistrict_name": "วังแซ้ม"
    },
    {
        "subdistrict_id": "1148",
        "district_id": "154",
        "subdistrict_name": "ฉมัน"
    },
    {
        "subdistrict_id": "1149",
        "district_id": "154",
        "subdistrict_name": "อ่างคีรี"
    },
    {
        "subdistrict_id": "1150",
        "district_id": "155",
        "subdistrict_name": "ปากน้ำแหลมสิงห์"
    },
    {
        "subdistrict_id": "1151",
        "district_id": "155",
        "subdistrict_name": "เกาะเปริด"
    },
    {
        "subdistrict_id": "1152",
        "district_id": "155",
        "subdistrict_name": "หนองชิ่ม"
    },
    {
        "subdistrict_id": "1153",
        "district_id": "155",
        "subdistrict_name": "พลิ้ว"
    },
    {
        "subdistrict_id": "1154",
        "district_id": "155",
        "subdistrict_name": "คลองน้ำเค็ม"
    },
    {
        "subdistrict_id": "1155",
        "district_id": "155",
        "subdistrict_name": "บางสระเก้า"
    },
    {
        "subdistrict_id": "1156",
        "district_id": "155",
        "subdistrict_name": "บางกะไชย"
    },
    {
        "subdistrict_id": "1157",
        "district_id": "156",
        "subdistrict_name": "ปะตง"
    },
    {
        "subdistrict_id": "1158",
        "district_id": "156",
        "subdistrict_name": "ทุ่งขนาน"
    },
    {
        "subdistrict_id": "1159",
        "district_id": "156",
        "subdistrict_name": "ทับช้าง"
    },
    {
        "subdistrict_id": "1160",
        "district_id": "156",
        "subdistrict_name": "ทรายขาว"
    },
    {
        "subdistrict_id": "1161",
        "district_id": "156",
        "subdistrict_name": "สะตอน"
    },
    {
        "subdistrict_id": "1162",
        "district_id": "157",
        "subdistrict_name": "แก่งหางแมว"
    },
    {
        "subdistrict_id": "1163",
        "district_id": "157",
        "subdistrict_name": "ขุนซ่อง"
    },
    {
        "subdistrict_id": "1164",
        "district_id": "157",
        "subdistrict_name": "สามพี่น้อง"
    },
    {
        "subdistrict_id": "1165",
        "district_id": "157",
        "subdistrict_name": "พวา"
    },
    {
        "subdistrict_id": "1166",
        "district_id": "157",
        "subdistrict_name": "เขาวงกต"
    },
    {
        "subdistrict_id": "1167",
        "district_id": "158",
        "subdistrict_name": "นายายอาม"
    },
    {
        "subdistrict_id": "1168",
        "district_id": "158",
        "subdistrict_name": "วังโตนด"
    },
    {
        "subdistrict_id": "1169",
        "district_id": "158",
        "subdistrict_name": "กระแจะ"
    },
    {
        "subdistrict_id": "1170",
        "district_id": "158",
        "subdistrict_name": "สนามไชย"
    },
    {
        "subdistrict_id": "1171",
        "district_id": "158",
        "subdistrict_name": "ช้างข้าม"
    },
    {
        "subdistrict_id": "1172",
        "district_id": "158",
        "subdistrict_name": "วังใหม่"
    },
    {
        "subdistrict_id": "1173",
        "district_id": "159",
        "subdistrict_name": "ชากไทย"
    },
    {
        "subdistrict_id": "1174",
        "district_id": "159",
        "subdistrict_name": "พลวง"
    },
    {
        "subdistrict_id": "1175",
        "district_id": "159",
        "subdistrict_name": "ตะเคียนทอง"
    },
    {
        "subdistrict_id": "1176",
        "district_id": "159",
        "subdistrict_name": "คลองพลู"
    },
    {
        "subdistrict_id": "1177",
        "district_id": "159",
        "subdistrict_name": "จันทเขลม"
    },
    {
        "subdistrict_id": "1178",
        "district_id": "160",
        "subdistrict_name": "บางพระ"
    },
    {
        "subdistrict_id": "1179",
        "district_id": "160",
        "subdistrict_name": "หนองเสม็ด"
    },
    {
        "subdistrict_id": "1180",
        "district_id": "160",
        "subdistrict_name": "หนองโสน"
    },
    {
        "subdistrict_id": "1181",
        "district_id": "160",
        "subdistrict_name": "หนองคันทรง"
    },
    {
        "subdistrict_id": "1182",
        "district_id": "160",
        "subdistrict_name": "ห้วงน้ำขาว"
    },
    {
        "subdistrict_id": "1183",
        "district_id": "160",
        "subdistrict_name": "อ่าวใหญ่"
    },
    {
        "subdistrict_id": "1184",
        "district_id": "160",
        "subdistrict_name": "วังกระแจะ"
    },
    {
        "subdistrict_id": "1185",
        "district_id": "160",
        "subdistrict_name": "ห้วยแร้ง"
    },
    {
        "subdistrict_id": "1186",
        "district_id": "160",
        "subdistrict_name": "เนินทราย"
    },
    {
        "subdistrict_id": "1187",
        "district_id": "160",
        "subdistrict_name": "ท่าพริก"
    },
    {
        "subdistrict_id": "1188",
        "district_id": "160",
        "subdistrict_name": "ท่ากุ่ม"
    },
    {
        "subdistrict_id": "1189",
        "district_id": "160",
        "subdistrict_name": "ตะกาง"
    },
    {
        "subdistrict_id": "1190",
        "district_id": "160",
        "subdistrict_name": "ชำราก"
    },
    {
        "subdistrict_id": "1191",
        "district_id": "160",
        "subdistrict_name": "แหลมกลัด"
    },
    {
        "subdistrict_id": "1192",
        "district_id": "161",
        "subdistrict_name": "คลองใหญ่"
    },
    {
        "subdistrict_id": "1193",
        "district_id": "161",
        "subdistrict_name": "ไม้รูด"
    },
    {
        "subdistrict_id": "1194",
        "district_id": "161",
        "subdistrict_name": "หาดเล็ก"
    },
    {
        "subdistrict_id": "1195",
        "district_id": "162",
        "subdistrict_name": "เขาสมิง"
    },
    {
        "subdistrict_id": "1196",
        "district_id": "162",
        "subdistrict_name": "แสนตุ้ง"
    },
    {
        "subdistrict_id": "1197",
        "district_id": "162",
        "subdistrict_name": "วังตะเคียน"
    },
    {
        "subdistrict_id": "1198",
        "district_id": "162",
        "subdistrict_name": "ท่าโสม"
    },
    {
        "subdistrict_id": "1199",
        "district_id": "162",
        "subdistrict_name": "สะตอ"
    },
    {
        "subdistrict_id": "1200",
        "district_id": "162",
        "subdistrict_name": "ประณีต"
    },
    {
        "subdistrict_id": "1201",
        "district_id": "162",
        "subdistrict_name": "เทพนิมิต"
    },
    {
        "subdistrict_id": "1202",
        "district_id": "162",
        "subdistrict_name": "ทุ่งนนทรี"
    },
    {
        "subdistrict_id": "1203",
        "district_id": "163",
        "subdistrict_name": "บ่อพลอย"
    },
    {
        "subdistrict_id": "1204",
        "district_id": "163",
        "subdistrict_name": "ช้างทูน"
    },
    {
        "subdistrict_id": "1205",
        "district_id": "163",
        "subdistrict_name": "ด่านชุมพล"
    },
    {
        "subdistrict_id": "1206",
        "district_id": "163",
        "subdistrict_name": "หนองบอน"
    },
    {
        "subdistrict_id": "1207",
        "district_id": "163",
        "subdistrict_name": "นนทรีย์"
    },
    {
        "subdistrict_id": "1208",
        "district_id": "164",
        "subdistrict_name": "แหลมงอบ"
    },
    {
        "subdistrict_id": "1209",
        "district_id": "164",
        "subdistrict_name": "น้ำเชี่ยว"
    },
    {
        "subdistrict_id": "1210",
        "district_id": "164",
        "subdistrict_name": "บางปิด"
    },
    {
        "subdistrict_id": "1211",
        "district_id": "164",
        "subdistrict_name": "คลองใหญ่"
    },
    {
        "subdistrict_id": "1212",
        "district_id": "165",
        "subdistrict_name": "เกาะหมาก"
    },
    {
        "subdistrict_id": "1213",
        "district_id": "165",
        "subdistrict_name": "เกาะกูด"
    },
    {
        "subdistrict_id": "1214",
        "district_id": "166",
        "subdistrict_name": "เกาะช้าง"
    },
    {
        "subdistrict_id": "1215",
        "district_id": "166",
        "subdistrict_name": "เกาะช้างใต้"
    },
    {
        "subdistrict_id": "1216",
        "district_id": "167",
        "subdistrict_name": "หน้าเมือง"
    },
    {
        "subdistrict_id": "1217",
        "district_id": "167",
        "subdistrict_name": "ท่าไข่"
    },
    {
        "subdistrict_id": "1218",
        "district_id": "167",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "1219",
        "district_id": "167",
        "subdistrict_name": "คลองนา"
    },
    {
        "subdistrict_id": "1220",
        "district_id": "167",
        "subdistrict_name": "บางตีนเป็ด"
    },
    {
        "subdistrict_id": "1221",
        "district_id": "167",
        "subdistrict_name": "บางไผ่"
    },
    {
        "subdistrict_id": "1222",
        "district_id": "167",
        "subdistrict_name": "คลองจุกกระเฌอ"
    },
    {
        "subdistrict_id": "1223",
        "district_id": "167",
        "subdistrict_name": "บางแก้ว"
    },
    {
        "subdistrict_id": "1224",
        "district_id": "167",
        "subdistrict_name": "บางขวัญ"
    },
    {
        "subdistrict_id": "1225",
        "district_id": "167",
        "subdistrict_name": "คลองนครเนื่องเขต"
    },
    {
        "subdistrict_id": "1226",
        "district_id": "167",
        "subdistrict_name": "วังตะเคียน"
    },
    {
        "subdistrict_id": "1227",
        "district_id": "167",
        "subdistrict_name": "โสธร"
    },
    {
        "subdistrict_id": "1228",
        "district_id": "167",
        "subdistrict_name": "บางพระ"
    },
    {
        "subdistrict_id": "1229",
        "district_id": "167",
        "subdistrict_name": "บางกะไห"
    },
    {
        "subdistrict_id": "1230",
        "district_id": "167",
        "subdistrict_name": "หนามแดง"
    },
    {
        "subdistrict_id": "1231",
        "district_id": "167",
        "subdistrict_name": "คลองเปรง"
    },
    {
        "subdistrict_id": "1232",
        "district_id": "167",
        "subdistrict_name": "คลองอุดมชลจร"
    },
    {
        "subdistrict_id": "1233",
        "district_id": "167",
        "subdistrict_name": "คลองหลวงแพ่ง"
    },
    {
        "subdistrict_id": "1234",
        "district_id": "167",
        "subdistrict_name": "บางเตย"
    },
    {
        "subdistrict_id": "1235",
        "district_id": "168",
        "subdistrict_name": "บางคล้า"
    },
    {
        "subdistrict_id": "1236",
        "district_id": "168",
        "subdistrict_name": "บางสวน"
    },
    {
        "subdistrict_id": "1237",
        "district_id": "168",
        "subdistrict_name": "บางกระเจ็ด"
    },
    {
        "subdistrict_id": "1238",
        "district_id": "168",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "1239",
        "district_id": "168",
        "subdistrict_name": "ท่าทองหลาง"
    },
    {
        "subdistrict_id": "1240",
        "district_id": "168",
        "subdistrict_name": "สาวชะโงก"
    },
    {
        "subdistrict_id": "1241",
        "district_id": "168",
        "subdistrict_name": "เสม็ดเหนือ"
    },
    {
        "subdistrict_id": "1242",
        "district_id": "168",
        "subdistrict_name": "เสม็ดใต้"
    },
    {
        "subdistrict_id": "1243",
        "district_id": "168",
        "subdistrict_name": "หัวไทร"
    },
    {
        "subdistrict_id": "1244",
        "district_id": "169",
        "subdistrict_name": "บางน้ำเปรี้ยว"
    },
    {
        "subdistrict_id": "1245",
        "district_id": "169",
        "subdistrict_name": "บางขนาก"
    },
    {
        "subdistrict_id": "1246",
        "district_id": "169",
        "subdistrict_name": "สิงโตทอง"
    },
    {
        "subdistrict_id": "1247",
        "district_id": "169",
        "subdistrict_name": "หมอนทอง"
    },
    {
        "subdistrict_id": "1248",
        "district_id": "169",
        "subdistrict_name": "บึงน้ำรักษ์"
    },
    {
        "subdistrict_id": "1249",
        "district_id": "169",
        "subdistrict_name": "ดอนเกาะกา"
    },
    {
        "subdistrict_id": "1250",
        "district_id": "169",
        "subdistrict_name": "โยธะกา"
    },
    {
        "subdistrict_id": "1251",
        "district_id": "169",
        "subdistrict_name": "ดอนฉิมพลี"
    },
    {
        "subdistrict_id": "1252",
        "district_id": "169",
        "subdistrict_name": "ศาลาแดง"
    },
    {
        "subdistrict_id": "1253",
        "district_id": "169",
        "subdistrict_name": "โพรงอากาศ"
    },
    {
        "subdistrict_id": "1254",
        "district_id": "170",
        "subdistrict_name": "บางปะกง"
    },
    {
        "subdistrict_id": "1255",
        "district_id": "170",
        "subdistrict_name": "ท่าสะอ้าน"
    },
    {
        "subdistrict_id": "1256",
        "district_id": "170",
        "subdistrict_name": "บางวัว"
    },
    {
        "subdistrict_id": "1257",
        "district_id": "170",
        "subdistrict_name": "บางสมัคร"
    },
    {
        "subdistrict_id": "1258",
        "district_id": "170",
        "subdistrict_name": "บางผึ้ง"
    },
    {
        "subdistrict_id": "1259",
        "district_id": "170",
        "subdistrict_name": "บางเกลือ"
    },
    {
        "subdistrict_id": "1260",
        "district_id": "170",
        "subdistrict_name": "สองคลอง"
    },
    {
        "subdistrict_id": "1261",
        "district_id": "170",
        "subdistrict_name": "หนองจอก"
    },
    {
        "subdistrict_id": "1262",
        "district_id": "170",
        "subdistrict_name": "พิมพา"
    },
    {
        "subdistrict_id": "1263",
        "district_id": "170",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "1264",
        "district_id": "170",
        "subdistrict_name": "หอมศีล"
    },
    {
        "subdistrict_id": "1265",
        "district_id": "170",
        "subdistrict_name": "เขาดิน"
    },
    {
        "subdistrict_id": "1266",
        "district_id": "171",
        "subdistrict_name": "บ้านโพธิ์"
    },
    {
        "subdistrict_id": "1267",
        "district_id": "171",
        "subdistrict_name": "เกาะไร่"
    },
    {
        "subdistrict_id": "1268",
        "district_id": "171",
        "subdistrict_name": "คลองขุด"
    },
    {
        "subdistrict_id": "1269",
        "district_id": "171",
        "subdistrict_name": "คลองบ้านโพธิ์"
    },
    {
        "subdistrict_id": "1270",
        "district_id": "171",
        "subdistrict_name": "คลองประเวศ"
    },
    {
        "subdistrict_id": "1271",
        "district_id": "171",
        "subdistrict_name": "ดอนทราย"
    },
    {
        "subdistrict_id": "1272",
        "district_id": "171",
        "subdistrict_name": "เทพราช"
    },
    {
        "subdistrict_id": "1273",
        "district_id": "171",
        "subdistrict_name": "ท่าพลับ"
    },
    {
        "subdistrict_id": "1274",
        "district_id": "171",
        "subdistrict_name": "หนองตีนนก"
    },
    {
        "subdistrict_id": "1275",
        "district_id": "171",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "1276",
        "district_id": "171",
        "subdistrict_name": "บางซ่อน"
    },
    {
        "subdistrict_id": "1277",
        "district_id": "171",
        "subdistrict_name": "บางกรูด"
    },
    {
        "subdistrict_id": "1278",
        "district_id": "171",
        "subdistrict_name": "แหลมประดู่"
    },
    {
        "subdistrict_id": "1279",
        "district_id": "171",
        "subdistrict_name": "ลาดขวาง"
    },
    {
        "subdistrict_id": "1280",
        "district_id": "171",
        "subdistrict_name": "สนามจันทร์"
    },
    {
        "subdistrict_id": "1281",
        "district_id": "171",
        "subdistrict_name": "แสนภูดาษ"
    },
    {
        "subdistrict_id": "1282",
        "district_id": "171",
        "subdistrict_name": "สิบเอ็ดศอก"
    },
    {
        "subdistrict_id": "1283",
        "district_id": "172",
        "subdistrict_name": "เกาะขนุน"
    },
    {
        "subdistrict_id": "1284",
        "district_id": "172",
        "subdistrict_name": "บ้านซ่อง"
    },
    {
        "subdistrict_id": "1285",
        "district_id": "172",
        "subdistrict_name": "พนมสารคาม"
    },
    {
        "subdistrict_id": "1286",
        "district_id": "172",
        "subdistrict_name": "เมืองเก่า"
    },
    {
        "subdistrict_id": "1287",
        "district_id": "172",
        "subdistrict_name": "หนองยาว"
    },
    {
        "subdistrict_id": "1288",
        "district_id": "172",
        "subdistrict_name": "ท่าถ่าน"
    },
    {
        "subdistrict_id": "1289",
        "district_id": "172",
        "subdistrict_name": "หนองแหน"
    },
    {
        "subdistrict_id": "1290",
        "district_id": "172",
        "subdistrict_name": "เขาหินซ้อน"
    },
    {
        "subdistrict_id": "1291",
        "district_id": "173",
        "subdistrict_name": "บางคา"
    },
    {
        "subdistrict_id": "1292",
        "district_id": "173",
        "subdistrict_name": "เมืองใหม่"
    },
    {
        "subdistrict_id": "1293",
        "district_id": "173",
        "subdistrict_name": "ดงน้อย"
    },
    {
        "subdistrict_id": "1294",
        "district_id": "174",
        "subdistrict_name": "คู้ยายหมี"
    },
    {
        "subdistrict_id": "1295",
        "district_id": "174",
        "subdistrict_name": "ท่ากระดาน"
    },
    {
        "subdistrict_id": "1296",
        "district_id": "174",
        "subdistrict_name": "ทุ่งพระยา"
    },
    {
        "subdistrict_id": "1297",
        "district_id": "174",
        "subdistrict_name": "ลาดกระทิง"
    },
    {
        "subdistrict_id": "1298",
        "district_id": "175",
        "subdistrict_name": "แปลงยาว"
    },
    {
        "subdistrict_id": "1299",
        "district_id": "175",
        "subdistrict_name": "วังเย็น"
    },
    {
        "subdistrict_id": "1300",
        "district_id": "175",
        "subdistrict_name": "หัวสำโรง"
    },
    {
        "subdistrict_id": "1301",
        "district_id": "175",
        "subdistrict_name": "หนองไม้แก่น"
    },
    {
        "subdistrict_id": "1302",
        "district_id": "176",
        "subdistrict_name": "ท่าตะเกียบ"
    },
    {
        "subdistrict_id": "1303",
        "district_id": "176",
        "subdistrict_name": "คลองตะเกรา"
    },
    {
        "subdistrict_id": "1304",
        "district_id": "177",
        "subdistrict_name": "ก้อนแก้ว"
    },
    {
        "subdistrict_id": "1305",
        "district_id": "177",
        "subdistrict_name": "คลองเขื่อน"
    },
    {
        "subdistrict_id": "1306",
        "district_id": "177",
        "subdistrict_name": "บางเล่า"
    },
    {
        "subdistrict_id": "1307",
        "district_id": "177",
        "subdistrict_name": "บางโรง"
    },
    {
        "subdistrict_id": "1308",
        "district_id": "177",
        "subdistrict_name": "บางตลาด"
    },
    {
        "subdistrict_id": "1309",
        "district_id": "178",
        "subdistrict_name": "หน้าเมือง"
    },
    {
        "subdistrict_id": "1310",
        "district_id": "178",
        "subdistrict_name": "รอบเมือง"
    },
    {
        "subdistrict_id": "1311",
        "district_id": "178",
        "subdistrict_name": "วัดโบสถ์"
    },
    {
        "subdistrict_id": "1312",
        "district_id": "178",
        "subdistrict_name": "บางเดชะ"
    },
    {
        "subdistrict_id": "1313",
        "district_id": "178",
        "subdistrict_name": "ท่างาม"
    },
    {
        "subdistrict_id": "1314",
        "district_id": "178",
        "subdistrict_name": "บางบริบูรณ์"
    },
    {
        "subdistrict_id": "1315",
        "district_id": "178",
        "subdistrict_name": "ดงพระราม"
    },
    {
        "subdistrict_id": "1316",
        "district_id": "178",
        "subdistrict_name": "บ้านพระ"
    },
    {
        "subdistrict_id": "1317",
        "district_id": "178",
        "subdistrict_name": "โคกไม้ลาย"
    },
    {
        "subdistrict_id": "1318",
        "district_id": "178",
        "subdistrict_name": "ไม้เค็ด"
    },
    {
        "subdistrict_id": "1319",
        "district_id": "178",
        "subdistrict_name": "ดงขี้เหล็ก"
    },
    {
        "subdistrict_id": "1320",
        "district_id": "178",
        "subdistrict_name": "เนินหอม"
    },
    {
        "subdistrict_id": "1321",
        "district_id": "178",
        "subdistrict_name": "โนนห้อม"
    },
    {
        "subdistrict_id": "1322",
        "district_id": "179",
        "subdistrict_name": "กบินทร์"
    },
    {
        "subdistrict_id": "1323",
        "district_id": "179",
        "subdistrict_name": "เมืองเก่า"
    },
    {
        "subdistrict_id": "1324",
        "district_id": "179",
        "subdistrict_name": "วังดาล"
    },
    {
        "subdistrict_id": "1325",
        "district_id": "179",
        "subdistrict_name": "นนทรี"
    },
    {
        "subdistrict_id": "1326",
        "district_id": "179",
        "subdistrict_name": "ย่านรี"
    },
    {
        "subdistrict_id": "1327",
        "district_id": "179",
        "subdistrict_name": "วังตะเคียน"
    },
    {
        "subdistrict_id": "1328",
        "district_id": "179",
        "subdistrict_name": "หาดนางแก้ว"
    },
    {
        "subdistrict_id": "1329",
        "district_id": "179",
        "subdistrict_name": "ลาดตะเคียน"
    },
    {
        "subdistrict_id": "1330",
        "district_id": "179",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "1331",
        "district_id": "179",
        "subdistrict_name": "บ่อทอง"
    },
    {
        "subdistrict_id": "1332",
        "district_id": "179",
        "subdistrict_name": "หนองกี่"
    },
    {
        "subdistrict_id": "1333",
        "district_id": "179",
        "subdistrict_name": "นาแขม"
    },
    {
        "subdistrict_id": "1334",
        "district_id": "179",
        "subdistrict_name": "เขาไม้แก้ว"
    },
    {
        "subdistrict_id": "1335",
        "district_id": "179",
        "subdistrict_name": "วังท่าช้าง"
    },
    {
        "subdistrict_id": "1336",
        "district_id": "180",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "1337",
        "district_id": "180",
        "subdistrict_name": "สำพันตา"
    },
    {
        "subdistrict_id": "1338",
        "district_id": "180",
        "subdistrict_name": "สะพานหิน"
    },
    {
        "subdistrict_id": "1339",
        "district_id": "180",
        "subdistrict_name": "ทุ่งโพธิ์"
    },
    {
        "subdistrict_id": "1340",
        "district_id": "180",
        "subdistrict_name": "แก่งดินสอ"
    },
    {
        "subdistrict_id": "1341",
        "district_id": "180",
        "subdistrict_name": "บุพราหมณ์"
    },
    {
        "subdistrict_id": "1342",
        "district_id": "181",
        "subdistrict_name": "บ้านสร้าง"
    },
    {
        "subdistrict_id": "1343",
        "district_id": "181",
        "subdistrict_name": "บางกระเบา"
    },
    {
        "subdistrict_id": "1344",
        "district_id": "181",
        "subdistrict_name": "บางเตย"
    },
    {
        "subdistrict_id": "1345",
        "district_id": "181",
        "subdistrict_name": "บางยาง"
    },
    {
        "subdistrict_id": "1346",
        "district_id": "181",
        "subdistrict_name": "บางแตน"
    },
    {
        "subdistrict_id": "1347",
        "district_id": "181",
        "subdistrict_name": "บางพลวง"
    },
    {
        "subdistrict_id": "1348",
        "district_id": "181",
        "subdistrict_name": "บางปลาร้า"
    },
    {
        "subdistrict_id": "1349",
        "district_id": "181",
        "subdistrict_name": "บางขาม"
    },
    {
        "subdistrict_id": "1350",
        "district_id": "181",
        "subdistrict_name": "กระทุ่มแพ้ว"
    },
    {
        "subdistrict_id": "1351",
        "district_id": "182",
        "subdistrict_name": "ประจันตคาม"
    },
    {
        "subdistrict_id": "1352",
        "district_id": "182",
        "subdistrict_name": "เกาะลอย"
    },
    {
        "subdistrict_id": "1353",
        "district_id": "182",
        "subdistrict_name": "บ้านหอย"
    },
    {
        "subdistrict_id": "1354",
        "district_id": "182",
        "subdistrict_name": "หนองแสง"
    },
    {
        "subdistrict_id": "1355",
        "district_id": "182",
        "subdistrict_name": "ดงบัง"
    },
    {
        "subdistrict_id": "1356",
        "district_id": "182",
        "subdistrict_name": "คำโตนด"
    },
    {
        "subdistrict_id": "1357",
        "district_id": "182",
        "subdistrict_name": "บุฝ้าย"
    },
    {
        "subdistrict_id": "1358",
        "district_id": "182",
        "subdistrict_name": "หนองแก้ว"
    },
    {
        "subdistrict_id": "1359",
        "district_id": "182",
        "subdistrict_name": "โพธิ์งาม"
    },
    {
        "subdistrict_id": "1360",
        "district_id": "183",
        "subdistrict_name": "ศรีมหาโพธิ"
    },
    {
        "subdistrict_id": "1361",
        "district_id": "183",
        "subdistrict_name": "สัมพันธ์"
    },
    {
        "subdistrict_id": "1362",
        "district_id": "183",
        "subdistrict_name": "บ้านทาม"
    },
    {
        "subdistrict_id": "1363",
        "district_id": "183",
        "subdistrict_name": "ท่าตูม"
    },
    {
        "subdistrict_id": "1364",
        "district_id": "183",
        "subdistrict_name": "บางกุ้ง"
    },
    {
        "subdistrict_id": "1365",
        "district_id": "183",
        "subdistrict_name": "ดงกระทงยาม"
    },
    {
        "subdistrict_id": "1366",
        "district_id": "183",
        "subdistrict_name": "หนองโพรง"
    },
    {
        "subdistrict_id": "1367",
        "district_id": "183",
        "subdistrict_name": "หัวหว้า"
    },
    {
        "subdistrict_id": "1368",
        "district_id": "183",
        "subdistrict_name": "หาดยาง"
    },
    {
        "subdistrict_id": "1369",
        "district_id": "183",
        "subdistrict_name": "กรอกสมบูรณ์"
    },
    {
        "subdistrict_id": "1370",
        "district_id": "184",
        "subdistrict_name": "โคกปีบ"
    },
    {
        "subdistrict_id": "1371",
        "district_id": "184",
        "subdistrict_name": "โคกไทย"
    },
    {
        "subdistrict_id": "1372",
        "district_id": "184",
        "subdistrict_name": "คู้ลำพัน"
    },
    {
        "subdistrict_id": "1373",
        "district_id": "184",
        "subdistrict_name": "ไผ่ชะเลือด"
    },
    {
        "subdistrict_id": "1374",
        "district_id": "185",
        "subdistrict_name": "นครนายก"
    },
    {
        "subdistrict_id": "1375",
        "district_id": "185",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "1376",
        "district_id": "185",
        "subdistrict_name": "บ้านใหญ่"
    },
    {
        "subdistrict_id": "1377",
        "district_id": "185",
        "subdistrict_name": "วังกระโจม"
    },
    {
        "subdistrict_id": "1378",
        "district_id": "185",
        "subdistrict_name": "ท่าทราย"
    },
    {
        "subdistrict_id": "1379",
        "district_id": "185",
        "subdistrict_name": "ดอนยอ"
    },
    {
        "subdistrict_id": "1380",
        "district_id": "185",
        "subdistrict_name": "ศรีจุฬา"
    },
    {
        "subdistrict_id": "1381",
        "district_id": "185",
        "subdistrict_name": "ดงละคร"
    },
    {
        "subdistrict_id": "1382",
        "district_id": "185",
        "subdistrict_name": "ศรีนาวา"
    },
    {
        "subdistrict_id": "1383",
        "district_id": "185",
        "subdistrict_name": "สาริกา"
    },
    {
        "subdistrict_id": "1384",
        "district_id": "185",
        "subdistrict_name": "หินตั้ง"
    },
    {
        "subdistrict_id": "1385",
        "district_id": "185",
        "subdistrict_name": "เขาพระ"
    },
    {
        "subdistrict_id": "1386",
        "district_id": "185",
        "subdistrict_name": "พรหมณี"
    },
    {
        "subdistrict_id": "1387",
        "district_id": "186",
        "subdistrict_name": "เกาะหวาย"
    },
    {
        "subdistrict_id": "1388",
        "district_id": "186",
        "subdistrict_name": "เกาะโพธิ์"
    },
    {
        "subdistrict_id": "1389",
        "district_id": "186",
        "subdistrict_name": "ปากพลี"
    },
    {
        "subdistrict_id": "1390",
        "district_id": "186",
        "subdistrict_name": "โคกกรวด"
    },
    {
        "subdistrict_id": "1391",
        "district_id": "186",
        "subdistrict_name": "ท่าเรือ"
    },
    {
        "subdistrict_id": "1392",
        "district_id": "186",
        "subdistrict_name": "หนองแสง"
    },
    {
        "subdistrict_id": "1393",
        "district_id": "186",
        "subdistrict_name": "นาหินลาด"
    },
    {
        "subdistrict_id": "1394",
        "district_id": "187",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "1395",
        "district_id": "187",
        "subdistrict_name": "บ้านพร้าว"
    },
    {
        "subdistrict_id": "1396",
        "district_id": "187",
        "subdistrict_name": "บ้านพริก"
    },
    {
        "subdistrict_id": "1397",
        "district_id": "187",
        "subdistrict_name": "อาษา"
    },
    {
        "subdistrict_id": "1398",
        "district_id": "187",
        "subdistrict_name": "ทองหลาง"
    },
    {
        "subdistrict_id": "1399",
        "district_id": "187",
        "subdistrict_name": "บางอ้อ"
    },
    {
        "subdistrict_id": "1400",
        "district_id": "187",
        "subdistrict_name": "พิกุลออก"
    },
    {
        "subdistrict_id": "1401",
        "district_id": "187",
        "subdistrict_name": "ป่าขะ"
    },
    {
        "subdistrict_id": "1402",
        "district_id": "187",
        "subdistrict_name": "เขาเพิ่ม"
    },
    {
        "subdistrict_id": "1403",
        "district_id": "187",
        "subdistrict_name": "ศรีกะอาง"
    },
    {
        "subdistrict_id": "1404",
        "district_id": "188",
        "subdistrict_name": "พระอาจารย์"
    },
    {
        "subdistrict_id": "1405",
        "district_id": "188",
        "subdistrict_name": "บึงศาล"
    },
    {
        "subdistrict_id": "1406",
        "district_id": "188",
        "subdistrict_name": "ศีรษะกระบือ"
    },
    {
        "subdistrict_id": "1407",
        "district_id": "188",
        "subdistrict_name": "โพธิ์แทน"
    },
    {
        "subdistrict_id": "1408",
        "district_id": "188",
        "subdistrict_name": "บางสมบูรณ์"
    },
    {
        "subdistrict_id": "1409",
        "district_id": "188",
        "subdistrict_name": "ทรายมูล"
    },
    {
        "subdistrict_id": "1410",
        "district_id": "188",
        "subdistrict_name": "บางปลากด"
    },
    {
        "subdistrict_id": "1411",
        "district_id": "188",
        "subdistrict_name": "บางลูกเสือ"
    },
    {
        "subdistrict_id": "1412",
        "district_id": "188",
        "subdistrict_name": "องครักษ์"
    },
    {
        "subdistrict_id": "1413",
        "district_id": "188",
        "subdistrict_name": "ชุมพล"
    },
    {
        "subdistrict_id": "1414",
        "district_id": "188",
        "subdistrict_name": "คลองใหญ่"
    },
    {
        "subdistrict_id": "1415",
        "district_id": "189",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "1416",
        "district_id": "189",
        "subdistrict_name": "บ้านแก้ง"
    },
    {
        "subdistrict_id": "1417",
        "district_id": "189",
        "subdistrict_name": "ศาลาลำดวน"
    },
    {
        "subdistrict_id": "1418",
        "district_id": "189",
        "subdistrict_name": "โคกปี่ฆ้อง"
    },
    {
        "subdistrict_id": "1419",
        "district_id": "189",
        "subdistrict_name": "ท่าแยก"
    },
    {
        "subdistrict_id": "1420",
        "district_id": "189",
        "subdistrict_name": "ท่าเกษม"
    },
    {
        "subdistrict_id": "1421",
        "district_id": "189",
        "subdistrict_name": "สระขวัญ"
    },
    {
        "subdistrict_id": "1422",
        "district_id": "189",
        "subdistrict_name": "หนองบอน"
    },
    {
        "subdistrict_id": "1423",
        "district_id": "190",
        "subdistrict_name": "คลองหาด"
    },
    {
        "subdistrict_id": "1424",
        "district_id": "190",
        "subdistrict_name": "ไทยอุดม"
    },
    {
        "subdistrict_id": "1425",
        "district_id": "190",
        "subdistrict_name": "ซับมะกรูด"
    },
    {
        "subdistrict_id": "1426",
        "district_id": "190",
        "subdistrict_name": "ไทรเดี่ยว"
    },
    {
        "subdistrict_id": "1427",
        "district_id": "190",
        "subdistrict_name": "คลองไก่เถื่อน"
    },
    {
        "subdistrict_id": "1428",
        "district_id": "190",
        "subdistrict_name": "เบญจขร"
    },
    {
        "subdistrict_id": "1429",
        "district_id": "190",
        "subdistrict_name": "ไทรทอง"
    },
    {
        "subdistrict_id": "1430",
        "district_id": "191",
        "subdistrict_name": "ตาพระยา"
    },
    {
        "subdistrict_id": "1431",
        "district_id": "191",
        "subdistrict_name": "ทัพเสด็จ"
    },
    {
        "subdistrict_id": "1432",
        "district_id": "191",
        "subdistrict_name": "ทัพราช"
    },
    {
        "subdistrict_id": "1433",
        "district_id": "191",
        "subdistrict_name": "ทัพไทย"
    },
    {
        "subdistrict_id": "1434",
        "district_id": "191",
        "subdistrict_name": "โคคลาน"
    },
    {
        "subdistrict_id": "1435",
        "district_id": "192",
        "subdistrict_name": "วังน้ำเย็น"
    },
    {
        "subdistrict_id": "1436",
        "district_id": "192",
        "subdistrict_name": "ตาหลังใน"
    },
    {
        "subdistrict_id": "1437",
        "district_id": "192",
        "subdistrict_name": "คลองหินปูน"
    },
    {
        "subdistrict_id": "1438",
        "district_id": "192",
        "subdistrict_name": "ทุ่งมหาเจริญ"
    },
    {
        "subdistrict_id": "1439",
        "district_id": "193",
        "subdistrict_name": "วัฒนานคร"
    },
    {
        "subdistrict_id": "1440",
        "district_id": "193",
        "subdistrict_name": "ท่าเกวียน"
    },
    {
        "subdistrict_id": "1441",
        "district_id": "193",
        "subdistrict_name": "ผักขะ"
    },
    {
        "subdistrict_id": "1442",
        "district_id": "193",
        "subdistrict_name": "โนนหมากเค็ง"
    },
    {
        "subdistrict_id": "1443",
        "district_id": "193",
        "subdistrict_name": "หนองน้ำใส"
    },
    {
        "subdistrict_id": "1444",
        "district_id": "193",
        "subdistrict_name": "ช่องกุ่ม"
    },
    {
        "subdistrict_id": "1445",
        "district_id": "193",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "1446",
        "district_id": "193",
        "subdistrict_name": "แซร์ออ"
    },
    {
        "subdistrict_id": "1447",
        "district_id": "193",
        "subdistrict_name": "หนองหมากฝ้าย"
    },
    {
        "subdistrict_id": "1448",
        "district_id": "193",
        "subdistrict_name": "หนองตะเคียนบอน"
    },
    {
        "subdistrict_id": "1449",
        "district_id": "193",
        "subdistrict_name": "ห้วยโจด"
    },
    {
        "subdistrict_id": "1450",
        "district_id": "194",
        "subdistrict_name": "อรัญประเทศ"
    },
    {
        "subdistrict_id": "1451",
        "district_id": "194",
        "subdistrict_name": "เมืองไผ่"
    },
    {
        "subdistrict_id": "1452",
        "district_id": "194",
        "subdistrict_name": "หันทราย"
    },
    {
        "subdistrict_id": "1453",
        "district_id": "194",
        "subdistrict_name": "คลองน้ำใส"
    },
    {
        "subdistrict_id": "1454",
        "district_id": "194",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "1455",
        "district_id": "194",
        "subdistrict_name": "ป่าไร่"
    },
    {
        "subdistrict_id": "1456",
        "district_id": "194",
        "subdistrict_name": "ทับพริก"
    },
    {
        "subdistrict_id": "1457",
        "district_id": "194",
        "subdistrict_name": "บ้านใหม่หนองไทร"
    },
    {
        "subdistrict_id": "1458",
        "district_id": "194",
        "subdistrict_name": "ผ่านศึก"
    },
    {
        "subdistrict_id": "1459",
        "district_id": "194",
        "subdistrict_name": "หนองสังข์"
    },
    {
        "subdistrict_id": "1460",
        "district_id": "194",
        "subdistrict_name": "คลองทับจันทร์"
    },
    {
        "subdistrict_id": "1461",
        "district_id": "194",
        "subdistrict_name": "ฟากห้วย"
    },
    {
        "subdistrict_id": "1462",
        "district_id": "194",
        "subdistrict_name": "บ้านด่าน"
    },
    {
        "subdistrict_id": "1463",
        "district_id": "195",
        "subdistrict_name": "เขาฉกรรจ์"
    },
    {
        "subdistrict_id": "1464",
        "district_id": "195",
        "subdistrict_name": "หนองหว้า"
    },
    {
        "subdistrict_id": "1465",
        "district_id": "195",
        "subdistrict_name": "พระเพลิง"
    },
    {
        "subdistrict_id": "1466",
        "district_id": "195",
        "subdistrict_name": "เขาสามสิบ"
    },
    {
        "subdistrict_id": "1467",
        "district_id": "196",
        "subdistrict_name": "โคกสูง"
    },
    {
        "subdistrict_id": "1468",
        "district_id": "196",
        "subdistrict_name": "หนองม่วง"
    },
    {
        "subdistrict_id": "1469",
        "district_id": "196",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "1470",
        "district_id": "196",
        "subdistrict_name": "โนนหมากมุ่น"
    },
    {
        "subdistrict_id": "1471",
        "district_id": "197",
        "subdistrict_name": "วังสมบูรณ์"
    },
    {
        "subdistrict_id": "1472",
        "district_id": "197",
        "subdistrict_name": "วังใหม่"
    },
    {
        "subdistrict_id": "1473",
        "district_id": "197",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "1474",
        "district_id": "198",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "1475",
        "district_id": "198",
        "subdistrict_name": "โพธิ์กลาง"
    },
    {
        "subdistrict_id": "1476",
        "district_id": "198",
        "subdistrict_name": "หนองจะบก"
    },
    {
        "subdistrict_id": "1477",
        "district_id": "198",
        "subdistrict_name": "โคกสูง"
    },
    {
        "subdistrict_id": "1478",
        "district_id": "198",
        "subdistrict_name": "มะเริง"
    },
    {
        "subdistrict_id": "1479",
        "district_id": "198",
        "subdistrict_name": "หนองระเวียง"
    },
    {
        "subdistrict_id": "1480",
        "district_id": "198",
        "subdistrict_name": "ปรุใหญ่"
    },
    {
        "subdistrict_id": "1481",
        "district_id": "198",
        "subdistrict_name": "หมื่นไวย"
    },
    {
        "subdistrict_id": "1482",
        "district_id": "198",
        "subdistrict_name": "พลกรัง"
    },
    {
        "subdistrict_id": "1483",
        "district_id": "198",
        "subdistrict_name": "หนองไผ่ล้อม"
    },
    {
        "subdistrict_id": "1484",
        "district_id": "198",
        "subdistrict_name": "หัวทะเล"
    },
    {
        "subdistrict_id": "1485",
        "district_id": "198",
        "subdistrict_name": "บ้านเกาะ"
    },
    {
        "subdistrict_id": "1486",
        "district_id": "198",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "1487",
        "district_id": "198",
        "subdistrict_name": "พุดซา"
    },
    {
        "subdistrict_id": "1488",
        "district_id": "198",
        "subdistrict_name": "บ้านโพธิ์"
    },
    {
        "subdistrict_id": "1489",
        "district_id": "198",
        "subdistrict_name": "จอหอ"
    },
    {
        "subdistrict_id": "1490",
        "district_id": "198",
        "subdistrict_name": "โคกกรวด"
    },
    {
        "subdistrict_id": "1491",
        "district_id": "198",
        "subdistrict_name": "ไชยมงคล"
    },
    {
        "subdistrict_id": "1492",
        "district_id": "198",
        "subdistrict_name": "หนองบัวศาลา"
    },
    {
        "subdistrict_id": "1493",
        "district_id": "198",
        "subdistrict_name": "สุรนารี"
    },
    {
        "subdistrict_id": "1494",
        "district_id": "198",
        "subdistrict_name": "สีมุม"
    },
    {
        "subdistrict_id": "1495",
        "district_id": "198",
        "subdistrict_name": "ตลาด"
    },
    {
        "subdistrict_id": "1496",
        "district_id": "198",
        "subdistrict_name": "พะเนา"
    },
    {
        "subdistrict_id": "1497",
        "district_id": "198",
        "subdistrict_name": "หนองกระทุ่ม"
    },
    {
        "subdistrict_id": "1498",
        "district_id": "198",
        "subdistrict_name": "หนองไข่น้ำ"
    },
    {
        "subdistrict_id": "1499",
        "district_id": "199",
        "subdistrict_name": "แชะ"
    },
    {
        "subdistrict_id": "1500",
        "district_id": "199",
        "subdistrict_name": "เฉลียง"
    },
    {
        "subdistrict_id": "1501",
        "district_id": "199",
        "subdistrict_name": "ครบุรี"
    },
    {
        "subdistrict_id": "1502",
        "district_id": "199",
        "subdistrict_name": "โคกกระชาย"
    },
    {
        "subdistrict_id": "1503",
        "district_id": "199",
        "subdistrict_name": "จระเข้หิน"
    },
    {
        "subdistrict_id": "1504",
        "district_id": "199",
        "subdistrict_name": "มาบตะโกเอน"
    },
    {
        "subdistrict_id": "1505",
        "district_id": "199",
        "subdistrict_name": "อรพิมพ์"
    },
    {
        "subdistrict_id": "1506",
        "district_id": "199",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "1507",
        "district_id": "199",
        "subdistrict_name": "ลำเพียก"
    },
    {
        "subdistrict_id": "1508",
        "district_id": "199",
        "subdistrict_name": "ครบุรีใต้"
    },
    {
        "subdistrict_id": "1509",
        "district_id": "199",
        "subdistrict_name": "ตะแบกบาน"
    },
    {
        "subdistrict_id": "1510",
        "district_id": "199",
        "subdistrict_name": "สระว่านพระยา"
    },
    {
        "subdistrict_id": "1511",
        "district_id": "200",
        "subdistrict_name": "เสิงสาง"
    },
    {
        "subdistrict_id": "1512",
        "district_id": "200",
        "subdistrict_name": "สระตะเคียน"
    },
    {
        "subdistrict_id": "1513",
        "district_id": "200",
        "subdistrict_name": "โนนสมบูรณ์"
    },
    {
        "subdistrict_id": "1514",
        "district_id": "200",
        "subdistrict_name": "กุดโบสถ์"
    },
    {
        "subdistrict_id": "1515",
        "district_id": "200",
        "subdistrict_name": "สุขไพบูลย์"
    },
    {
        "subdistrict_id": "1516",
        "district_id": "200",
        "subdistrict_name": "บ้านราษฎร์"
    },
    {
        "subdistrict_id": "1517",
        "district_id": "201",
        "subdistrict_name": "เมืองคง"
    },
    {
        "subdistrict_id": "1518",
        "district_id": "201",
        "subdistrict_name": "คูขาด"
    },
    {
        "subdistrict_id": "1519",
        "district_id": "201",
        "subdistrict_name": "เทพาลัย"
    },
    {
        "subdistrict_id": "1520",
        "district_id": "201",
        "subdistrict_name": "ตาจั่น"
    },
    {
        "subdistrict_id": "1521",
        "district_id": "201",
        "subdistrict_name": "บ้านปรางค์"
    },
    {
        "subdistrict_id": "1522",
        "district_id": "201",
        "subdistrict_name": "หนองมะนาว"
    },
    {
        "subdistrict_id": "1523",
        "district_id": "201",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "1524",
        "district_id": "201",
        "subdistrict_name": "โนนเต็ง"
    },
    {
        "subdistrict_id": "1525",
        "district_id": "201",
        "subdistrict_name": "ดอนใหญ่"
    },
    {
        "subdistrict_id": "1526",
        "district_id": "201",
        "subdistrict_name": "ขามสมบูรณ์"
    },
    {
        "subdistrict_id": "1527",
        "district_id": "202",
        "subdistrict_name": "บ้านเหลื่อม"
    },
    {
        "subdistrict_id": "1528",
        "district_id": "202",
        "subdistrict_name": "วังโพธิ์"
    },
    {
        "subdistrict_id": "1529",
        "district_id": "202",
        "subdistrict_name": "โคกกระเบื้อง"
    },
    {
        "subdistrict_id": "1530",
        "district_id": "202",
        "subdistrict_name": "ช่อระกา"
    },
    {
        "subdistrict_id": "1531",
        "district_id": "203",
        "subdistrict_name": "จักราช"
    },
    {
        "subdistrict_id": "1532",
        "district_id": "203",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "1533",
        "district_id": "203",
        "subdistrict_name": "ทองหลาง"
    },
    {
        "subdistrict_id": "1534",
        "district_id": "203",
        "subdistrict_name": "สีสุก"
    },
    {
        "subdistrict_id": "1535",
        "district_id": "203",
        "subdistrict_name": "หนองขาม"
    },
    {
        "subdistrict_id": "1536",
        "district_id": "203",
        "subdistrict_name": "หนองงูเหลือม"
    },
    {
        "subdistrict_id": "1537",
        "district_id": "203",
        "subdistrict_name": "หนองพลวง"
    },
    {
        "subdistrict_id": "1538",
        "district_id": "203",
        "subdistrict_name": "หนองยาง"
    },
    {
        "subdistrict_id": "1539",
        "district_id": "203",
        "subdistrict_name": "พระพุทธ"
    },
    {
        "subdistrict_id": "1540",
        "district_id": "203",
        "subdistrict_name": "ศรีละกอ"
    },
    {
        "subdistrict_id": "1541",
        "district_id": "203",
        "subdistrict_name": "คลองเมือง"
    },
    {
        "subdistrict_id": "1542",
        "district_id": "203",
        "subdistrict_name": "ช้างทอง"
    },
    {
        "subdistrict_id": "1543",
        "district_id": "203",
        "subdistrict_name": "หินโคน"
    },
    {
        "subdistrict_id": "1544",
        "district_id": "204",
        "subdistrict_name": "กระโทก"
    },
    {
        "subdistrict_id": "1545",
        "district_id": "204",
        "subdistrict_name": "พลับพลา"
    },
    {
        "subdistrict_id": "1546",
        "district_id": "204",
        "subdistrict_name": "ท่าอ่าง"
    },
    {
        "subdistrict_id": "1547",
        "district_id": "204",
        "subdistrict_name": "ทุ่งอรุณ"
    },
    {
        "subdistrict_id": "1548",
        "district_id": "204",
        "subdistrict_name": "ท่าลาดขาว"
    },
    {
        "subdistrict_id": "1549",
        "district_id": "204",
        "subdistrict_name": "ท่าจะหลุง"
    },
    {
        "subdistrict_id": "1550",
        "district_id": "204",
        "subdistrict_name": "ท่าเยี่ยม"
    },
    {
        "subdistrict_id": "1551",
        "district_id": "204",
        "subdistrict_name": "โชคชัย"
    },
    {
        "subdistrict_id": "1552",
        "district_id": "204",
        "subdistrict_name": "ละลมใหม่พัฒนา"
    },
    {
        "subdistrict_id": "1553",
        "district_id": "204",
        "subdistrict_name": "ด่านเกวียน"
    },
    {
        "subdistrict_id": "1554",
        "district_id": "205",
        "subdistrict_name": "กุดพิมาน"
    },
    {
        "subdistrict_id": "1555",
        "district_id": "205",
        "subdistrict_name": "ด่านขุนทด"
    },
    {
        "subdistrict_id": "1556",
        "district_id": "205",
        "subdistrict_name": "ด่านนอก"
    },
    {
        "subdistrict_id": "1557",
        "district_id": "205",
        "subdistrict_name": "ด่านใน"
    },
    {
        "subdistrict_id": "1558",
        "district_id": "205",
        "subdistrict_name": "ตะเคียน"
    },
    {
        "subdistrict_id": "1559",
        "district_id": "205",
        "subdistrict_name": "บ้านเก่า"
    },
    {
        "subdistrict_id": "1560",
        "district_id": "205",
        "subdistrict_name": "บ้านแปรง"
    },
    {
        "subdistrict_id": "1561",
        "district_id": "205",
        "subdistrict_name": "พันชนะ"
    },
    {
        "subdistrict_id": "1562",
        "district_id": "205",
        "subdistrict_name": "สระจรเข้"
    },
    {
        "subdistrict_id": "1563",
        "district_id": "205",
        "subdistrict_name": "หนองกราด"
    },
    {
        "subdistrict_id": "1564",
        "district_id": "205",
        "subdistrict_name": "หนองบัวตะเกียด"
    },
    {
        "subdistrict_id": "1565",
        "district_id": "205",
        "subdistrict_name": "หนองบัวละคร"
    },
    {
        "subdistrict_id": "1566",
        "district_id": "205",
        "subdistrict_name": "หินดาด"
    },
    {
        "subdistrict_id": "1567",
        "district_id": "205",
        "subdistrict_name": "ห้วยบง"
    },
    {
        "subdistrict_id": "1568",
        "district_id": "205",
        "subdistrict_name": "โนนเมืองพัฒนา"
    },
    {
        "subdistrict_id": "1569",
        "district_id": "205",
        "subdistrict_name": "หนองไทร"
    },
    {
        "subdistrict_id": "1570",
        "district_id": "206",
        "subdistrict_name": "โนนไทย"
    },
    {
        "subdistrict_id": "1571",
        "district_id": "206",
        "subdistrict_name": "ด่านจาก"
    },
    {
        "subdistrict_id": "1572",
        "district_id": "206",
        "subdistrict_name": "กำปัง"
    },
    {
        "subdistrict_id": "1573",
        "district_id": "206",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "1574",
        "district_id": "206",
        "subdistrict_name": "ค้างพลู"
    },
    {
        "subdistrict_id": "1575",
        "district_id": "206",
        "subdistrict_name": "บ้านวัง"
    },
    {
        "subdistrict_id": "1576",
        "district_id": "206",
        "subdistrict_name": "บัลลังก์"
    },
    {
        "subdistrict_id": "1577",
        "district_id": "206",
        "subdistrict_name": "สายออ"
    },
    {
        "subdistrict_id": "1578",
        "district_id": "206",
        "subdistrict_name": "ถนนโพธิ์"
    },
    {
        "subdistrict_id": "1579",
        "district_id": "206",
        "subdistrict_name": "พังเทียม"
    },
    {
        "subdistrict_id": "1580",
        "district_id": "206",
        "subdistrict_name": "สระพระ"
    },
    {
        "subdistrict_id": "1581",
        "district_id": "206",
        "subdistrict_name": "ทัพรั้ง"
    },
    {
        "subdistrict_id": "1582",
        "district_id": "206",
        "subdistrict_name": "หนองหอย"
    },
    {
        "subdistrict_id": "1583",
        "district_id": "206",
        "subdistrict_name": "มะค่า"
    },
    {
        "subdistrict_id": "1584",
        "district_id": "206",
        "subdistrict_name": "มาบกราด"
    },
    {
        "subdistrict_id": "1585",
        "district_id": "207",
        "subdistrict_name": "โนนสูง"
    },
    {
        "subdistrict_id": "1586",
        "district_id": "207",
        "subdistrict_name": "ใหม่"
    },
    {
        "subdistrict_id": "1587",
        "district_id": "207",
        "subdistrict_name": "โตนด"
    },
    {
        "subdistrict_id": "1588",
        "district_id": "207",
        "subdistrict_name": "บิง"
    },
    {
        "subdistrict_id": "1589",
        "district_id": "207",
        "subdistrict_name": "ดอนชมพู"
    },
    {
        "subdistrict_id": "1590",
        "district_id": "207",
        "subdistrict_name": "ธารปราสาท"
    },
    {
        "subdistrict_id": "1591",
        "district_id": "207",
        "subdistrict_name": "หลุมข้าว"
    },
    {
        "subdistrict_id": "1592",
        "district_id": "207",
        "subdistrict_name": "มะค่า"
    },
    {
        "subdistrict_id": "1593",
        "district_id": "207",
        "subdistrict_name": "พลสงคราม"
    },
    {
        "subdistrict_id": "1594",
        "district_id": "207",
        "subdistrict_name": "จันอัด"
    },
    {
        "subdistrict_id": "1595",
        "district_id": "207",
        "subdistrict_name": "ขามเฒ่า"
    },
    {
        "subdistrict_id": "1596",
        "district_id": "207",
        "subdistrict_name": "ด่านคล้า"
    },
    {
        "subdistrict_id": "1597",
        "district_id": "207",
        "subdistrict_name": "ลำคอหงษ์"
    },
    {
        "subdistrict_id": "1598",
        "district_id": "207",
        "subdistrict_name": "เมืองปราสาท"
    },
    {
        "subdistrict_id": "1599",
        "district_id": "207",
        "subdistrict_name": "ดอนหวาย"
    },
    {
        "subdistrict_id": "1600",
        "district_id": "207",
        "subdistrict_name": "ลำมูล"
    },
    {
        "subdistrict_id": "1601",
        "district_id": "208",
        "subdistrict_name": "ขามสะแกแสง"
    },
    {
        "subdistrict_id": "1602",
        "district_id": "208",
        "subdistrict_name": "โนนเมือง"
    },
    {
        "subdistrict_id": "1603",
        "district_id": "208",
        "subdistrict_name": "เมืองนาท"
    },
    {
        "subdistrict_id": "1604",
        "district_id": "208",
        "subdistrict_name": "ชีวึก"
    },
    {
        "subdistrict_id": "1605",
        "district_id": "208",
        "subdistrict_name": "พะงาด"
    },
    {
        "subdistrict_id": "1606",
        "district_id": "208",
        "subdistrict_name": "หนองหัวฟาน"
    },
    {
        "subdistrict_id": "1607",
        "district_id": "208",
        "subdistrict_name": "เมืองเกษตร"
    },
    {
        "subdistrict_id": "1608",
        "district_id": "209",
        "subdistrict_name": "บัวใหญ่"
    },
    {
        "subdistrict_id": "1609",
        "district_id": "209",
        "subdistrict_name": "ห้วยยาง"
    },
    {
        "subdistrict_id": "1610",
        "district_id": "209",
        "subdistrict_name": "เสมาใหญ่"
    },
    {
        "subdistrict_id": "1611",
        "district_id": "209",
        "subdistrict_name": "ดอนตะหนิน"
    },
    {
        "subdistrict_id": "1612",
        "district_id": "209",
        "subdistrict_name": "หนองบัวสะอาด"
    },
    {
        "subdistrict_id": "1613",
        "district_id": "209",
        "subdistrict_name": "โนนทองหลาง"
    },
    {
        "subdistrict_id": "1614",
        "district_id": "209",
        "subdistrict_name": "หนองหว้า"
    },
    {
        "subdistrict_id": "1615",
        "district_id": "209",
        "subdistrict_name": "บัวลาย"
    },
    {
        "subdistrict_id": "1616",
        "district_id": "209",
        "subdistrict_name": "สีดา"
    },
    {
        "subdistrict_id": "1617",
        "district_id": "209",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "1618",
        "district_id": "209",
        "subdistrict_name": "กุดจอก"
    },
    {
        "subdistrict_id": "1619",
        "district_id": "209",
        "subdistrict_name": "ด่านช้าง"
    },
    {
        "subdistrict_id": "1620",
        "district_id": "209",
        "subdistrict_name": "โนนจาน"
    },
    {
        "subdistrict_id": "1621",
        "district_id": "209",
        "subdistrict_name": "สามเมือง"
    },
    {
        "subdistrict_id": "1622",
        "district_id": "209",
        "subdistrict_name": "ขุนทอง"
    },
    {
        "subdistrict_id": "1623",
        "district_id": "209",
        "subdistrict_name": "หนองตาดใหญ่"
    },
    {
        "subdistrict_id": "1624",
        "district_id": "209",
        "subdistrict_name": "เมืองพะไล"
    },
    {
        "subdistrict_id": "1625",
        "district_id": "209",
        "subdistrict_name": "โนนประดู่"
    },
    {
        "subdistrict_id": "1626",
        "district_id": "209",
        "subdistrict_name": "หนองแจ้งใหญ่"
    },
    {
        "subdistrict_id": "1627",
        "district_id": "210",
        "subdistrict_name": "ประทาย"
    },
    {
        "subdistrict_id": "1628",
        "district_id": "210",
        "subdistrict_name": "กระทุ่มราย"
    },
    {
        "subdistrict_id": "1629",
        "district_id": "210",
        "subdistrict_name": "วังไม้แดง"
    },
    {
        "subdistrict_id": "1630",
        "district_id": "210",
        "subdistrict_name": "ตลาดไทร"
    },
    {
        "subdistrict_id": "1631",
        "district_id": "210",
        "subdistrict_name": "หนองพลวง"
    },
    {
        "subdistrict_id": "1632",
        "district_id": "210",
        "subdistrict_name": "หนองค่าย"
    },
    {
        "subdistrict_id": "1633",
        "district_id": "210",
        "subdistrict_name": "หันห้วยทราย"
    },
    {
        "subdistrict_id": "1634",
        "district_id": "210",
        "subdistrict_name": "ดอนมัน"
    },
    {
        "subdistrict_id": "1635",
        "district_id": "210",
        "subdistrict_name": "นางรำ"
    },
    {
        "subdistrict_id": "1636",
        "district_id": "210",
        "subdistrict_name": "โนนเพ็ด"
    },
    {
        "subdistrict_id": "1637",
        "district_id": "210",
        "subdistrict_name": "ทุ่งสว่าง"
    },
    {
        "subdistrict_id": "1638",
        "district_id": "210",
        "subdistrict_name": "โคกกลาง"
    },
    {
        "subdistrict_id": "1639",
        "district_id": "210",
        "subdistrict_name": "เมืองโดน"
    },
    {
        "subdistrict_id": "1640",
        "district_id": "211",
        "subdistrict_name": "เมืองปัก"
    },
    {
        "subdistrict_id": "1641",
        "district_id": "211",
        "subdistrict_name": "ตะคุ"
    },
    {
        "subdistrict_id": "1642",
        "district_id": "211",
        "subdistrict_name": "โคกไทย"
    },
    {
        "subdistrict_id": "1643",
        "district_id": "211",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "1644",
        "district_id": "211",
        "subdistrict_name": "ตะขบ"
    },
    {
        "subdistrict_id": "1645",
        "district_id": "211",
        "subdistrict_name": "นกออก"
    },
    {
        "subdistrict_id": "1646",
        "district_id": "211",
        "subdistrict_name": "ดอน"
    },
    {
        "subdistrict_id": "1647",
        "district_id": "211",
        "subdistrict_name": "ตูม"
    },
    {
        "subdistrict_id": "1648",
        "district_id": "211",
        "subdistrict_name": "งิ้ว"
    },
    {
        "subdistrict_id": "1649",
        "district_id": "211",
        "subdistrict_name": "สะแกราช"
    },
    {
        "subdistrict_id": "1650",
        "district_id": "211",
        "subdistrict_name": "ลำนางแก้ว"
    },
    {
        "subdistrict_id": "1651",
        "district_id": "211",
        "subdistrict_name": "ภูหลวง"
    },
    {
        "subdistrict_id": "1652",
        "district_id": "211",
        "subdistrict_name": "ธงชัยเหนือ"
    },
    {
        "subdistrict_id": "1653",
        "district_id": "211",
        "subdistrict_name": "สุขเกษม"
    },
    {
        "subdistrict_id": "1654",
        "district_id": "211",
        "subdistrict_name": "เกษมทรัพย์"
    },
    {
        "subdistrict_id": "1655",
        "district_id": "211",
        "subdistrict_name": "บ่อปลาทอง"
    },
    {
        "subdistrict_id": "1656",
        "district_id": "212",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "1657",
        "district_id": "212",
        "subdistrict_name": "สัมฤทธิ์"
    },
    {
        "subdistrict_id": "1658",
        "district_id": "212",
        "subdistrict_name": "โบสถ์"
    },
    {
        "subdistrict_id": "1659",
        "district_id": "212",
        "subdistrict_name": "กระเบื้องใหญ่"
    },
    {
        "subdistrict_id": "1660",
        "district_id": "212",
        "subdistrict_name": "ท่าหลวง"
    },
    {
        "subdistrict_id": "1661",
        "district_id": "212",
        "subdistrict_name": "รังกาใหญ่"
    },
    {
        "subdistrict_id": "1662",
        "district_id": "212",
        "subdistrict_name": "ชีวาน"
    },
    {
        "subdistrict_id": "1663",
        "district_id": "212",
        "subdistrict_name": "นิคมสร้างตนเอง"
    },
    {
        "subdistrict_id": "1664",
        "district_id": "212",
        "subdistrict_name": "กระชอน"
    },
    {
        "subdistrict_id": "1665",
        "district_id": "212",
        "subdistrict_name": "ดงใหญ่"
    },
    {
        "subdistrict_id": "1666",
        "district_id": "212",
        "subdistrict_name": "ธารละหลอด"
    },
    {
        "subdistrict_id": "1667",
        "district_id": "212",
        "subdistrict_name": "หนองระเวียง"
    },
    {
        "subdistrict_id": "1668",
        "district_id": "213",
        "subdistrict_name": "ห้วยแถลง"
    },
    {
        "subdistrict_id": "1669",
        "district_id": "213",
        "subdistrict_name": "ทับสวาย"
    },
    {
        "subdistrict_id": "1670",
        "district_id": "213",
        "subdistrict_name": "เมืองพลับพลา"
    },
    {
        "subdistrict_id": "1671",
        "district_id": "213",
        "subdistrict_name": "หลุ่งตะเคียน"
    },
    {
        "subdistrict_id": "1672",
        "district_id": "213",
        "subdistrict_name": "หินดาด"
    },
    {
        "subdistrict_id": "1673",
        "district_id": "213",
        "subdistrict_name": "งิ้ว"
    },
    {
        "subdistrict_id": "1674",
        "district_id": "213",
        "subdistrict_name": "กงรถ"
    },
    {
        "subdistrict_id": "1675",
        "district_id": "213",
        "subdistrict_name": "หลุ่งประดู่"
    },
    {
        "subdistrict_id": "1676",
        "district_id": "213",
        "subdistrict_name": "ตะโก"
    },
    {
        "subdistrict_id": "1677",
        "district_id": "213",
        "subdistrict_name": "ห้วยแคน"
    },
    {
        "subdistrict_id": "1678",
        "district_id": "214",
        "subdistrict_name": "ชุมพวง"
    },
    {
        "subdistrict_id": "1679",
        "district_id": "214",
        "subdistrict_name": "ประสุข"
    },
    {
        "subdistrict_id": "1680",
        "district_id": "214",
        "subdistrict_name": "ท่าลาด"
    },
    {
        "subdistrict_id": "1681",
        "district_id": "214",
        "subdistrict_name": "สาหร่าย"
    },
    {
        "subdistrict_id": "1682",
        "district_id": "214",
        "subdistrict_name": "ตลาดไทร"
    },
    {
        "subdistrict_id": "1683",
        "district_id": "214",
        "subdistrict_name": "ช่องแมว"
    },
    {
        "subdistrict_id": "1684",
        "district_id": "214",
        "subdistrict_name": "ขุย"
    },
    {
        "subdistrict_id": "1685",
        "district_id": "214",
        "subdistrict_name": "โนนรัง"
    },
    {
        "subdistrict_id": "1686",
        "district_id": "214",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "1687",
        "district_id": "214",
        "subdistrict_name": "หนองหลัก"
    },
    {
        "subdistrict_id": "1688",
        "district_id": "214",
        "subdistrict_name": "ไพล"
    },
    {
        "subdistrict_id": "1689",
        "district_id": "214",
        "subdistrict_name": "โนนตูม"
    },
    {
        "subdistrict_id": "1690",
        "district_id": "214",
        "subdistrict_name": "โนนยอ"
    },
    {
        "subdistrict_id": "1691",
        "district_id": "215",
        "subdistrict_name": "สูงเนิน"
    },
    {
        "subdistrict_id": "1692",
        "district_id": "215",
        "subdistrict_name": "เสมา"
    },
    {
        "subdistrict_id": "1693",
        "district_id": "215",
        "subdistrict_name": "โคราช"
    },
    {
        "subdistrict_id": "1694",
        "district_id": "215",
        "subdistrict_name": "บุ่งขี้เหล็ก"
    },
    {
        "subdistrict_id": "1695",
        "district_id": "215",
        "subdistrict_name": "โนนค่า"
    },
    {
        "subdistrict_id": "1696",
        "district_id": "215",
        "subdistrict_name": "โค้งยาง"
    },
    {
        "subdistrict_id": "1697",
        "district_id": "215",
        "subdistrict_name": "มะเกลือเก่า"
    },
    {
        "subdistrict_id": "1698",
        "district_id": "215",
        "subdistrict_name": "มะเกลือใหม่"
    },
    {
        "subdistrict_id": "1699",
        "district_id": "215",
        "subdistrict_name": "นากลาง"
    },
    {
        "subdistrict_id": "1700",
        "district_id": "215",
        "subdistrict_name": "หนองตะไก้"
    },
    {
        "subdistrict_id": "1701",
        "district_id": "215",
        "subdistrict_name": "กุดจิก"
    },
    {
        "subdistrict_id": "1702",
        "district_id": "216",
        "subdistrict_name": "ขามทะเลสอ"
    },
    {
        "subdistrict_id": "1703",
        "district_id": "216",
        "subdistrict_name": "โป่งแดง"
    },
    {
        "subdistrict_id": "1704",
        "district_id": "216",
        "subdistrict_name": "พันดุง"
    },
    {
        "subdistrict_id": "1705",
        "district_id": "216",
        "subdistrict_name": "หนองสรวง"
    },
    {
        "subdistrict_id": "1706",
        "district_id": "216",
        "subdistrict_name": "บึงอ้อ"
    },
    {
        "subdistrict_id": "1707",
        "district_id": "217",
        "subdistrict_name": "สีคิ้ว"
    },
    {
        "subdistrict_id": "1708",
        "district_id": "217",
        "subdistrict_name": "บ้านหัน"
    },
    {
        "subdistrict_id": "1709",
        "district_id": "217",
        "subdistrict_name": "กฤษณา"
    },
    {
        "subdistrict_id": "1710",
        "district_id": "217",
        "subdistrict_name": "ลาดบัวขาว"
    },
    {
        "subdistrict_id": "1711",
        "district_id": "217",
        "subdistrict_name": "หนองหญ้าขาว"
    },
    {
        "subdistrict_id": "1712",
        "district_id": "217",
        "subdistrict_name": "กุดน้อย"
    },
    {
        "subdistrict_id": "1713",
        "district_id": "217",
        "subdistrict_name": "หนองน้ำใส"
    },
    {
        "subdistrict_id": "1714",
        "district_id": "217",
        "subdistrict_name": "วังโรงใหญ่"
    },
    {
        "subdistrict_id": "1715",
        "district_id": "217",
        "subdistrict_name": "มิตรภาพ"
    },
    {
        "subdistrict_id": "1716",
        "district_id": "217",
        "subdistrict_name": "คลองไผ่"
    },
    {
        "subdistrict_id": "1717",
        "district_id": "217",
        "subdistrict_name": "ดอนเมือง"
    },
    {
        "subdistrict_id": "1718",
        "district_id": "217",
        "subdistrict_name": "หนองบัวน้อย"
    },
    {
        "subdistrict_id": "1719",
        "district_id": "218",
        "subdistrict_name": "ปากช่อง"
    },
    {
        "subdistrict_id": "1720",
        "district_id": "218",
        "subdistrict_name": "กลางดง"
    },
    {
        "subdistrict_id": "1721",
        "district_id": "218",
        "subdistrict_name": "จันทึก"
    },
    {
        "subdistrict_id": "1722",
        "district_id": "218",
        "subdistrict_name": "วังกะทะ"
    },
    {
        "subdistrict_id": "1723",
        "district_id": "218",
        "subdistrict_name": "หมูสี"
    },
    {
        "subdistrict_id": "1724",
        "district_id": "218",
        "subdistrict_name": "หนองสาหร่าย"
    },
    {
        "subdistrict_id": "1725",
        "district_id": "218",
        "subdistrict_name": "ขนงพระ"
    },
    {
        "subdistrict_id": "1726",
        "district_id": "218",
        "subdistrict_name": "โป่งตาลอง"
    },
    {
        "subdistrict_id": "1727",
        "district_id": "218",
        "subdistrict_name": "คลองม่วง"
    },
    {
        "subdistrict_id": "1728",
        "district_id": "218",
        "subdistrict_name": "หนองน้ำแดง"
    },
    {
        "subdistrict_id": "1729",
        "district_id": "218",
        "subdistrict_name": "วังไทร"
    },
    {
        "subdistrict_id": "1730",
        "district_id": "218",
        "subdistrict_name": "พญาเย็น"
    },
    {
        "subdistrict_id": "1731",
        "district_id": "219",
        "subdistrict_name": "หนองบุนนาก"
    },
    {
        "subdistrict_id": "1732",
        "district_id": "219",
        "subdistrict_name": "สารภี"
    },
    {
        "subdistrict_id": "1733",
        "district_id": "219",
        "subdistrict_name": "ไทยเจริญ"
    },
    {
        "subdistrict_id": "1734",
        "district_id": "219",
        "subdistrict_name": "หนองหัวแรต"
    },
    {
        "subdistrict_id": "1735",
        "district_id": "219",
        "subdistrict_name": "แหลมทอง"
    },
    {
        "subdistrict_id": "1736",
        "district_id": "219",
        "subdistrict_name": "หนองตะไก้"
    },
    {
        "subdistrict_id": "1737",
        "district_id": "219",
        "subdistrict_name": "ลุงเขว้า"
    },
    {
        "subdistrict_id": "1738",
        "district_id": "219",
        "subdistrict_name": "หนองไม้ไผ่"
    },
    {
        "subdistrict_id": "1739",
        "district_id": "219",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "1740",
        "district_id": "220",
        "subdistrict_name": "แก้งสนามนาง"
    },
    {
        "subdistrict_id": "1741",
        "district_id": "220",
        "subdistrict_name": "โนนสำราญ"
    },
    {
        "subdistrict_id": "1742",
        "district_id": "220",
        "subdistrict_name": "บึงพะไล"
    },
    {
        "subdistrict_id": "1743",
        "district_id": "220",
        "subdistrict_name": "สีสุก"
    },
    {
        "subdistrict_id": "1744",
        "district_id": "220",
        "subdistrict_name": "บึงสำโรง"
    },
    {
        "subdistrict_id": "1745",
        "district_id": "221",
        "subdistrict_name": "โนนแดง"
    },
    {
        "subdistrict_id": "1746",
        "district_id": "221",
        "subdistrict_name": "โนนตาเถร"
    },
    {
        "subdistrict_id": "1747",
        "district_id": "221",
        "subdistrict_name": "สำพะเนียง"
    },
    {
        "subdistrict_id": "1748",
        "district_id": "221",
        "subdistrict_name": "วังหิน"
    },
    {
        "subdistrict_id": "1749",
        "district_id": "221",
        "subdistrict_name": "ดอนยาวใหญ่"
    },
    {
        "subdistrict_id": "1750",
        "district_id": "222",
        "subdistrict_name": "วังน้ำเขียว"
    },
    {
        "subdistrict_id": "1751",
        "district_id": "222",
        "subdistrict_name": "วังหมี"
    },
    {
        "subdistrict_id": "1752",
        "district_id": "222",
        "subdistrict_name": "ระเริง"
    },
    {
        "subdistrict_id": "1753",
        "district_id": "222",
        "subdistrict_name": "อุดมทรัพย์"
    },
    {
        "subdistrict_id": "1754",
        "district_id": "222",
        "subdistrict_name": "ไทยสามัคคี"
    },
    {
        "subdistrict_id": "1755",
        "district_id": "223",
        "subdistrict_name": "สำนักตะคร้อ"
    },
    {
        "subdistrict_id": "1756",
        "district_id": "223",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "1757",
        "district_id": "223",
        "subdistrict_name": "บึงปรือ"
    },
    {
        "subdistrict_id": "1758",
        "district_id": "223",
        "subdistrict_name": "วังยายทอง"
    },
    {
        "subdistrict_id": "1759",
        "district_id": "224",
        "subdistrict_name": "เมืองยาง"
    },
    {
        "subdistrict_id": "1760",
        "district_id": "224",
        "subdistrict_name": "กระเบื้องนอก"
    },
    {
        "subdistrict_id": "1761",
        "district_id": "224",
        "subdistrict_name": "ละหานปลาค้าว"
    },
    {
        "subdistrict_id": "1762",
        "district_id": "224",
        "subdistrict_name": "โนนอุดม"
    },
    {
        "subdistrict_id": "1763",
        "district_id": "225",
        "subdistrict_name": "สระพระ"
    },
    {
        "subdistrict_id": "1764",
        "district_id": "225",
        "subdistrict_name": "มาบกราด"
    },
    {
        "subdistrict_id": "1765",
        "district_id": "225",
        "subdistrict_name": "พังเทียม"
    },
    {
        "subdistrict_id": "1766",
        "district_id": "225",
        "subdistrict_name": "ทัพรั้ง"
    },
    {
        "subdistrict_id": "1767",
        "district_id": "225",
        "subdistrict_name": "หนองหอย"
    },
    {
        "subdistrict_id": "1768",
        "district_id": "226",
        "subdistrict_name": "ขุย"
    },
    {
        "subdistrict_id": "1769",
        "district_id": "226",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "1770",
        "district_id": "226",
        "subdistrict_name": "ช่องแมว"
    },
    {
        "subdistrict_id": "1771",
        "district_id": "226",
        "subdistrict_name": "ไพล"
    },
    {
        "subdistrict_id": "1772",
        "district_id": "227",
        "subdistrict_name": "เมืองพะไล"
    },
    {
        "subdistrict_id": "1773",
        "district_id": "227",
        "subdistrict_name": "โนนจาน"
    },
    {
        "subdistrict_id": "1774",
        "district_id": "227",
        "subdistrict_name": "บัวลาย"
    },
    {
        "subdistrict_id": "1775",
        "district_id": "227",
        "subdistrict_name": "หนองหว้า"
    },
    {
        "subdistrict_id": "1776",
        "district_id": "228",
        "subdistrict_name": "สีดา"
    },
    {
        "subdistrict_id": "1777",
        "district_id": "228",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "1778",
        "district_id": "228",
        "subdistrict_name": "โนนประดู่"
    },
    {
        "subdistrict_id": "1779",
        "district_id": "228",
        "subdistrict_name": "สามเมือง"
    },
    {
        "subdistrict_id": "1780",
        "district_id": "228",
        "subdistrict_name": "หนองตาดใหญ่"
    },
    {
        "subdistrict_id": "1781",
        "district_id": "229",
        "subdistrict_name": "ช้างทอง"
    },
    {
        "subdistrict_id": "1782",
        "district_id": "229",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "1783",
        "district_id": "229",
        "subdistrict_name": "พระพุทธ"
    },
    {
        "subdistrict_id": "1784",
        "district_id": "229",
        "subdistrict_name": "หนองงูเหลือม"
    },
    {
        "subdistrict_id": "1785",
        "district_id": "229",
        "subdistrict_name": "หนองยาง"
    },
    {
        "subdistrict_id": "1786",
        "district_id": "230",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "1787",
        "district_id": "230",
        "subdistrict_name": "อิสาณ"
    },
    {
        "subdistrict_id": "1788",
        "district_id": "230",
        "subdistrict_name": "เสม็ด"
    },
    {
        "subdistrict_id": "1789",
        "district_id": "230",
        "subdistrict_name": "บ้านบัว"
    },
    {
        "subdistrict_id": "1790",
        "district_id": "230",
        "subdistrict_name": "สะแกโพรง"
    },
    {
        "subdistrict_id": "1791",
        "district_id": "230",
        "subdistrict_name": "สวายจีก"
    },
    {
        "subdistrict_id": "1792",
        "district_id": "230",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "1793",
        "district_id": "230",
        "subdistrict_name": "พระครู"
    },
    {
        "subdistrict_id": "1794",
        "district_id": "230",
        "subdistrict_name": "ถลุงเหล็ก"
    },
    {
        "subdistrict_id": "1795",
        "district_id": "230",
        "subdistrict_name": "หนองตาด"
    },
    {
        "subdistrict_id": "1796",
        "district_id": "230",
        "subdistrict_name": "ลุมปุ๊ก"
    },
    {
        "subdistrict_id": "1797",
        "district_id": "230",
        "subdistrict_name": "สองห้อง"
    },
    {
        "subdistrict_id": "1798",
        "district_id": "230",
        "subdistrict_name": "บัวทอง"
    },
    {
        "subdistrict_id": "1799",
        "district_id": "230",
        "subdistrict_name": "ชุมเห็ด"
    },
    {
        "subdistrict_id": "1800",
        "district_id": "230",
        "subdistrict_name": "หลักเขต"
    },
    {
        "subdistrict_id": "1801",
        "district_id": "230",
        "subdistrict_name": "สะแกซำ"
    },
    {
        "subdistrict_id": "1802",
        "district_id": "230",
        "subdistrict_name": "กลันทา"
    },
    {
        "subdistrict_id": "1803",
        "district_id": "230",
        "subdistrict_name": "กระสัง"
    },
    {
        "subdistrict_id": "1804",
        "district_id": "230",
        "subdistrict_name": "เมืองฝาง"
    },
    {
        "subdistrict_id": "1805",
        "district_id": "231",
        "subdistrict_name": "คูเมือง"
    },
    {
        "subdistrict_id": "1806",
        "district_id": "231",
        "subdistrict_name": "ปะเคียบ"
    },
    {
        "subdistrict_id": "1807",
        "district_id": "231",
        "subdistrict_name": "บ้านแพ"
    },
    {
        "subdistrict_id": "1808",
        "district_id": "231",
        "subdistrict_name": "พรสำราญ"
    },
    {
        "subdistrict_id": "1809",
        "district_id": "231",
        "subdistrict_name": "หินเหล็กไฟ"
    },
    {
        "subdistrict_id": "1810",
        "district_id": "231",
        "subdistrict_name": "ตูมใหญ่"
    },
    {
        "subdistrict_id": "1811",
        "district_id": "231",
        "subdistrict_name": "หนองขมาร"
    },
    {
        "subdistrict_id": "1812",
        "district_id": "232",
        "subdistrict_name": "กระสัง"
    },
    {
        "subdistrict_id": "1813",
        "district_id": "232",
        "subdistrict_name": "ลำดวน"
    },
    {
        "subdistrict_id": "1814",
        "district_id": "232",
        "subdistrict_name": "สองชั้น"
    },
    {
        "subdistrict_id": "1815",
        "district_id": "232",
        "subdistrict_name": "สูงเนิน"
    },
    {
        "subdistrict_id": "1816",
        "district_id": "232",
        "subdistrict_name": "หนองเต็ง"
    },
    {
        "subdistrict_id": "1817",
        "district_id": "232",
        "subdistrict_name": "เมืองไผ่"
    },
    {
        "subdistrict_id": "1818",
        "district_id": "232",
        "subdistrict_name": "ชุมแสง"
    },
    {
        "subdistrict_id": "1819",
        "district_id": "232",
        "subdistrict_name": "บ้านปรือ"
    },
    {
        "subdistrict_id": "1820",
        "district_id": "232",
        "subdistrict_name": "ห้วยสำราญ"
    },
    {
        "subdistrict_id": "1821",
        "district_id": "232",
        "subdistrict_name": "กันทรารมย์"
    },
    {
        "subdistrict_id": "1822",
        "district_id": "232",
        "subdistrict_name": "ศรีภูมิ"
    },
    {
        "subdistrict_id": "1823",
        "district_id": "233",
        "subdistrict_name": "นางรอง"
    },
    {
        "subdistrict_id": "1824",
        "district_id": "233",
        "subdistrict_name": "สะเดา"
    },
    {
        "subdistrict_id": "1825",
        "district_id": "233",
        "subdistrict_name": "ชุมแสง"
    },
    {
        "subdistrict_id": "1826",
        "district_id": "233",
        "subdistrict_name": "หนองโบสถ์"
    },
    {
        "subdistrict_id": "1827",
        "district_id": "233",
        "subdistrict_name": "หนองกง"
    },
    {
        "subdistrict_id": "1828",
        "district_id": "233",
        "subdistrict_name": "ถนนหัก"
    },
    {
        "subdistrict_id": "1829",
        "district_id": "233",
        "subdistrict_name": "หนองไทร"
    },
    {
        "subdistrict_id": "1830",
        "district_id": "233",
        "subdistrict_name": "ก้านเหลือง"
    },
    {
        "subdistrict_id": "1831",
        "district_id": "233",
        "subdistrict_name": "บ้านสิงห์"
    },
    {
        "subdistrict_id": "1832",
        "district_id": "233",
        "subdistrict_name": "ลำไทรโยง"
    },
    {
        "subdistrict_id": "1833",
        "district_id": "233",
        "subdistrict_name": "ทรัพย์พระยา"
    },
    {
        "subdistrict_id": "1834",
        "district_id": "233",
        "subdistrict_name": "หนองยายพิมพ์"
    },
    {
        "subdistrict_id": "1835",
        "district_id": "233",
        "subdistrict_name": "หัวถนน"
    },
    {
        "subdistrict_id": "1836",
        "district_id": "233",
        "subdistrict_name": "ทุ่งแสงทอง"
    },
    {
        "subdistrict_id": "1837",
        "district_id": "233",
        "subdistrict_name": "หนองโสน"
    },
    {
        "subdistrict_id": "1838",
        "district_id": "234",
        "subdistrict_name": "หนองกี่"
    },
    {
        "subdistrict_id": "1839",
        "district_id": "234",
        "subdistrict_name": "เย้ยปราสาท"
    },
    {
        "subdistrict_id": "1840",
        "district_id": "234",
        "subdistrict_name": "เมืองไผ่"
    },
    {
        "subdistrict_id": "1841",
        "district_id": "234",
        "subdistrict_name": "ดอนอะราง"
    },
    {
        "subdistrict_id": "1842",
        "district_id": "234",
        "subdistrict_name": "โคกสว่าง"
    },
    {
        "subdistrict_id": "1843",
        "district_id": "234",
        "subdistrict_name": "ทุ่งกระตาดพัฒนา"
    },
    {
        "subdistrict_id": "1844",
        "district_id": "234",
        "subdistrict_name": "ทุ่งกระเต็น"
    },
    {
        "subdistrict_id": "1845",
        "district_id": "234",
        "subdistrict_name": "ท่าโพธิ์ชัย"
    },
    {
        "subdistrict_id": "1846",
        "district_id": "234",
        "subdistrict_name": "โคกสูง"
    },
    {
        "subdistrict_id": "1847",
        "district_id": "234",
        "subdistrict_name": "บุกระสัง"
    },
    {
        "subdistrict_id": "1848",
        "district_id": "235",
        "subdistrict_name": "ละหานทราย"
    },
    {
        "subdistrict_id": "1849",
        "district_id": "235",
        "subdistrict_name": "ตาจง"
    },
    {
        "subdistrict_id": "1850",
        "district_id": "235",
        "subdistrict_name": "สำโรงใหม่"
    },
    {
        "subdistrict_id": "1851",
        "district_id": "235",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "1852",
        "district_id": "235",
        "subdistrict_name": "หนองตะครอง"
    },
    {
        "subdistrict_id": "1853",
        "district_id": "235",
        "subdistrict_name": "โคกว่าน"
    },
    {
        "subdistrict_id": "1854",
        "district_id": "236",
        "subdistrict_name": "ประโคนชัย"
    },
    {
        "subdistrict_id": "1855",
        "district_id": "236",
        "subdistrict_name": "แสลงโทน"
    },
    {
        "subdistrict_id": "1856",
        "district_id": "236",
        "subdistrict_name": "บ้านไทร"
    },
    {
        "subdistrict_id": "1857",
        "district_id": "236",
        "subdistrict_name": "ละเวี้ย"
    },
    {
        "subdistrict_id": "1858",
        "district_id": "236",
        "subdistrict_name": "จรเข้มาก"
    },
    {
        "subdistrict_id": "1859",
        "district_id": "236",
        "subdistrict_name": "ปังกู"
    },
    {
        "subdistrict_id": "1860",
        "district_id": "236",
        "subdistrict_name": "โคกย่าง"
    },
    {
        "subdistrict_id": "1861",
        "district_id": "236",
        "subdistrict_name": "โคกม้า"
    },
    {
        "subdistrict_id": "1862",
        "district_id": "236",
        "subdistrict_name": "ไพศาล"
    },
    {
        "subdistrict_id": "1863",
        "district_id": "236",
        "subdistrict_name": "ตะโกตาพิ"
    },
    {
        "subdistrict_id": "1864",
        "district_id": "236",
        "subdistrict_name": "เขาคอก"
    },
    {
        "subdistrict_id": "1865",
        "district_id": "236",
        "subdistrict_name": "หนองบอน"
    },
    {
        "subdistrict_id": "1866",
        "district_id": "236",
        "subdistrict_name": "โคกมะขาม"
    },
    {
        "subdistrict_id": "1867",
        "district_id": "236",
        "subdistrict_name": "โคกตูม"
    },
    {
        "subdistrict_id": "1868",
        "district_id": "236",
        "subdistrict_name": "ประทัดบุ"
    },
    {
        "subdistrict_id": "1869",
        "district_id": "236",
        "subdistrict_name": "สี่เหลี่ยม"
    },
    {
        "subdistrict_id": "1870",
        "district_id": "237",
        "subdistrict_name": "บ้านกรวด"
    },
    {
        "subdistrict_id": "1871",
        "district_id": "237",
        "subdistrict_name": "โนนเจริญ"
    },
    {
        "subdistrict_id": "1872",
        "district_id": "237",
        "subdistrict_name": "หนองไม้งาม"
    },
    {
        "subdistrict_id": "1873",
        "district_id": "237",
        "subdistrict_name": "ปราสาท"
    },
    {
        "subdistrict_id": "1874",
        "district_id": "237",
        "subdistrict_name": "สายตะกู"
    },
    {
        "subdistrict_id": "1875",
        "district_id": "237",
        "subdistrict_name": "หินลาด"
    },
    {
        "subdistrict_id": "1876",
        "district_id": "237",
        "subdistrict_name": "บึงเจริญ"
    },
    {
        "subdistrict_id": "1877",
        "district_id": "237",
        "subdistrict_name": "จันทบเพชร"
    },
    {
        "subdistrict_id": "1878",
        "district_id": "237",
        "subdistrict_name": "เขาดินเหนือ"
    },
    {
        "subdistrict_id": "1879",
        "district_id": "238",
        "subdistrict_name": "พุทไธสง"
    },
    {
        "subdistrict_id": "1880",
        "district_id": "238",
        "subdistrict_name": "มะเฟือง"
    },
    {
        "subdistrict_id": "1881",
        "district_id": "238",
        "subdistrict_name": "บ้านจาน"
    },
    {
        "subdistrict_id": "1882",
        "district_id": "238",
        "subdistrict_name": "บ้านเป้า"
    },
    {
        "subdistrict_id": "1883",
        "district_id": "238",
        "subdistrict_name": "บ้านแวง"
    },
    {
        "subdistrict_id": "1884",
        "district_id": "238",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "1885",
        "district_id": "238",
        "subdistrict_name": "หายโศก"
    },
    {
        "subdistrict_id": "1886",
        "district_id": "239",
        "subdistrict_name": "ลำปลายมาศ"
    },
    {
        "subdistrict_id": "1887",
        "district_id": "239",
        "subdistrict_name": "หนองคู"
    },
    {
        "subdistrict_id": "1888",
        "district_id": "239",
        "subdistrict_name": "แสลงพัน"
    },
    {
        "subdistrict_id": "1889",
        "district_id": "239",
        "subdistrict_name": "ทะเมนชัย"
    },
    {
        "subdistrict_id": "1890",
        "district_id": "239",
        "subdistrict_name": "ตลาดโพธิ์"
    },
    {
        "subdistrict_id": "1891",
        "district_id": "239",
        "subdistrict_name": "หนองกะทิง"
    },
    {
        "subdistrict_id": "1892",
        "district_id": "239",
        "subdistrict_name": "โคกกลาง"
    },
    {
        "subdistrict_id": "1893",
        "district_id": "239",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "1894",
        "district_id": "239",
        "subdistrict_name": "เมืองแฝก"
    },
    {
        "subdistrict_id": "1895",
        "district_id": "239",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "1896",
        "district_id": "239",
        "subdistrict_name": "ผไทรินทร์"
    },
    {
        "subdistrict_id": "1897",
        "district_id": "239",
        "subdistrict_name": "โคกล่าม"
    },
    {
        "subdistrict_id": "1898",
        "district_id": "239",
        "subdistrict_name": "หินโคน"
    },
    {
        "subdistrict_id": "1899",
        "district_id": "239",
        "subdistrict_name": "หนองบัวโคก"
    },
    {
        "subdistrict_id": "1900",
        "district_id": "239",
        "subdistrict_name": "บุโพธิ์"
    },
    {
        "subdistrict_id": "1901",
        "district_id": "239",
        "subdistrict_name": "หนองโดน"
    },
    {
        "subdistrict_id": "1902",
        "district_id": "240",
        "subdistrict_name": "สตึก"
    },
    {
        "subdistrict_id": "1903",
        "district_id": "240",
        "subdistrict_name": "นิคม"
    },
    {
        "subdistrict_id": "1904",
        "district_id": "240",
        "subdistrict_name": "ทุ่งวัง"
    },
    {
        "subdistrict_id": "1905",
        "district_id": "240",
        "subdistrict_name": "เมืองแก"
    },
    {
        "subdistrict_id": "1906",
        "district_id": "240",
        "subdistrict_name": "หนองใหญ่"
    },
    {
        "subdistrict_id": "1907",
        "district_id": "240",
        "subdistrict_name": "ร่อนทอง"
    },
    {
        "subdistrict_id": "1908",
        "district_id": "240",
        "subdistrict_name": "ดอนมนต์"
    },
    {
        "subdistrict_id": "1909",
        "district_id": "240",
        "subdistrict_name": "ชุมแสง"
    },
    {
        "subdistrict_id": "1910",
        "district_id": "240",
        "subdistrict_name": "ท่าม่วง"
    },
    {
        "subdistrict_id": "1911",
        "district_id": "240",
        "subdistrict_name": "สะแก"
    },
    {
        "subdistrict_id": "1912",
        "district_id": "240",
        "subdistrict_name": "สนามชัย"
    },
    {
        "subdistrict_id": "1913",
        "district_id": "240",
        "subdistrict_name": "กระสัง"
    },
    {
        "subdistrict_id": "1914",
        "district_id": "241",
        "subdistrict_name": "ปะคำ"
    },
    {
        "subdistrict_id": "1915",
        "district_id": "241",
        "subdistrict_name": "ไทยเจริญ"
    },
    {
        "subdistrict_id": "1916",
        "district_id": "241",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "1917",
        "district_id": "241",
        "subdistrict_name": "โคกมะม่วง"
    },
    {
        "subdistrict_id": "1918",
        "district_id": "241",
        "subdistrict_name": "หูทำนบ"
    },
    {
        "subdistrict_id": "1919",
        "district_id": "242",
        "subdistrict_name": "นาโพธิ์"
    },
    {
        "subdistrict_id": "1920",
        "district_id": "242",
        "subdistrict_name": "บ้านคู"
    },
    {
        "subdistrict_id": "1921",
        "district_id": "242",
        "subdistrict_name": "บ้านดู่"
    },
    {
        "subdistrict_id": "1922",
        "district_id": "242",
        "subdistrict_name": "ดอนกอก"
    },
    {
        "subdistrict_id": "1923",
        "district_id": "242",
        "subdistrict_name": "ศรีสว่าง"
    },
    {
        "subdistrict_id": "1924",
        "district_id": "243",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "1925",
        "district_id": "243",
        "subdistrict_name": "ห้วยหิน"
    },
    {
        "subdistrict_id": "1926",
        "district_id": "243",
        "subdistrict_name": "ไทยสามัคคี"
    },
    {
        "subdistrict_id": "1927",
        "district_id": "243",
        "subdistrict_name": "หนองชัยศรี"
    },
    {
        "subdistrict_id": "1928",
        "district_id": "243",
        "subdistrict_name": "เสาเดียว"
    },
    {
        "subdistrict_id": "1929",
        "district_id": "243",
        "subdistrict_name": "เมืองฝ้าย"
    },
    {
        "subdistrict_id": "1930",
        "district_id": "243",
        "subdistrict_name": "สระทอง"
    },
    {
        "subdistrict_id": "1931",
        "district_id": "244",
        "subdistrict_name": "จันดุม"
    },
    {
        "subdistrict_id": "1932",
        "district_id": "244",
        "subdistrict_name": "โคกขมิ้น"
    },
    {
        "subdistrict_id": "1933",
        "district_id": "244",
        "subdistrict_name": "ป่าชัน"
    },
    {
        "subdistrict_id": "1934",
        "district_id": "244",
        "subdistrict_name": "สะเดา"
    },
    {
        "subdistrict_id": "1935",
        "district_id": "244",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "1936",
        "district_id": "245",
        "subdistrict_name": "ห้วยราช"
    },
    {
        "subdistrict_id": "1937",
        "district_id": "245",
        "subdistrict_name": "สามแวง"
    },
    {
        "subdistrict_id": "1938",
        "district_id": "245",
        "subdistrict_name": "ตาเสา"
    },
    {
        "subdistrict_id": "1939",
        "district_id": "245",
        "subdistrict_name": "บ้านตะโก"
    },
    {
        "subdistrict_id": "1940",
        "district_id": "245",
        "subdistrict_name": "สนวน"
    },
    {
        "subdistrict_id": "1941",
        "district_id": "245",
        "subdistrict_name": "โคกเหล็ก"
    },
    {
        "subdistrict_id": "1942",
        "district_id": "245",
        "subdistrict_name": "เมืองโพธิ์"
    },
    {
        "subdistrict_id": "1943",
        "district_id": "245",
        "subdistrict_name": "ห้วยราชา"
    },
    {
        "subdistrict_id": "1944",
        "district_id": "246",
        "subdistrict_name": "โนนสุวรรณ"
    },
    {
        "subdistrict_id": "1945",
        "district_id": "246",
        "subdistrict_name": "ทุ่งจังหัน"
    },
    {
        "subdistrict_id": "1946",
        "district_id": "246",
        "subdistrict_name": "โกรกแก้ว"
    },
    {
        "subdistrict_id": "1947",
        "district_id": "246",
        "subdistrict_name": "ดงอีจาน"
    },
    {
        "subdistrict_id": "1948",
        "district_id": "247",
        "subdistrict_name": "ชำนิ"
    },
    {
        "subdistrict_id": "1949",
        "district_id": "247",
        "subdistrict_name": "หนองปล่อง"
    },
    {
        "subdistrict_id": "1950",
        "district_id": "247",
        "subdistrict_name": "เมืองยาง"
    },
    {
        "subdistrict_id": "1951",
        "district_id": "247",
        "subdistrict_name": "ช่อผกา"
    },
    {
        "subdistrict_id": "1952",
        "district_id": "247",
        "subdistrict_name": "ละลวด"
    },
    {
        "subdistrict_id": "1953",
        "district_id": "247",
        "subdistrict_name": "โคกสนวน"
    },
    {
        "subdistrict_id": "1954",
        "district_id": "248",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "1955",
        "district_id": "248",
        "subdistrict_name": "ทองหลาง"
    },
    {
        "subdistrict_id": "1956",
        "district_id": "248",
        "subdistrict_name": "แดงใหญ่"
    },
    {
        "subdistrict_id": "1957",
        "district_id": "248",
        "subdistrict_name": "กู่สวนแตง"
    },
    {
        "subdistrict_id": "1958",
        "district_id": "248",
        "subdistrict_name": "หนองเยือง"
    },
    {
        "subdistrict_id": "1959",
        "district_id": "249",
        "subdistrict_name": "โนนดินแดง"
    },
    {
        "subdistrict_id": "1960",
        "district_id": "249",
        "subdistrict_name": "ส้มป่อย"
    },
    {
        "subdistrict_id": "1961",
        "district_id": "249",
        "subdistrict_name": "ลำนางรอง"
    },
    {
        "subdistrict_id": "1962",
        "district_id": "250",
        "subdistrict_name": "บ้านด่าน"
    },
    {
        "subdistrict_id": "1963",
        "district_id": "250",
        "subdistrict_name": "ปราสาท"
    },
    {
        "subdistrict_id": "1964",
        "district_id": "250",
        "subdistrict_name": "วังเหนือ"
    },
    {
        "subdistrict_id": "1965",
        "district_id": "250",
        "subdistrict_name": "โนนขวาง"
    },
    {
        "subdistrict_id": "1966",
        "district_id": "251",
        "subdistrict_name": "แคนดง"
    },
    {
        "subdistrict_id": "1967",
        "district_id": "251",
        "subdistrict_name": "ดงพลอง"
    },
    {
        "subdistrict_id": "1968",
        "district_id": "251",
        "subdistrict_name": "สระบัว"
    },
    {
        "subdistrict_id": "1969",
        "district_id": "251",
        "subdistrict_name": "หัวฝาย"
    },
    {
        "subdistrict_id": "1970",
        "district_id": "252",
        "subdistrict_name": "เจริญสุข"
    },
    {
        "subdistrict_id": "1971",
        "district_id": "252",
        "subdistrict_name": "ตาเป๊ก"
    },
    {
        "subdistrict_id": "1972",
        "district_id": "252",
        "subdistrict_name": "อีสานเขต"
    },
    {
        "subdistrict_id": "1973",
        "district_id": "252",
        "subdistrict_name": "ถาวร"
    },
    {
        "subdistrict_id": "1974",
        "district_id": "252",
        "subdistrict_name": "ยายแย้มวัฒนา"
    },
    {
        "subdistrict_id": "1975",
        "district_id": "253",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "1976",
        "district_id": "253",
        "subdistrict_name": "ตั้งใจ"
    },
    {
        "subdistrict_id": "1977",
        "district_id": "253",
        "subdistrict_name": "เพี้ยราม"
    },
    {
        "subdistrict_id": "1978",
        "district_id": "253",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "1979",
        "district_id": "253",
        "subdistrict_name": "ท่าสว่าง"
    },
    {
        "subdistrict_id": "1980",
        "district_id": "253",
        "subdistrict_name": "สลักได"
    },
    {
        "subdistrict_id": "1981",
        "district_id": "253",
        "subdistrict_name": "ตาอ็อง"
    },
    {
        "subdistrict_id": "1982",
        "district_id": "253",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "1983",
        "district_id": "253",
        "subdistrict_name": "แกใหญ่"
    },
    {
        "subdistrict_id": "1984",
        "district_id": "253",
        "subdistrict_name": "นอกเมือง"
    },
    {
        "subdistrict_id": "1985",
        "district_id": "253",
        "subdistrict_name": "คอโค"
    },
    {
        "subdistrict_id": "1986",
        "district_id": "253",
        "subdistrict_name": "สวาย"
    },
    {
        "subdistrict_id": "1987",
        "district_id": "253",
        "subdistrict_name": "เฉนียง"
    },
    {
        "subdistrict_id": "1988",
        "district_id": "253",
        "subdistrict_name": "เทนมีย์"
    },
    {
        "subdistrict_id": "1989",
        "district_id": "253",
        "subdistrict_name": "นาบัว"
    },
    {
        "subdistrict_id": "1990",
        "district_id": "253",
        "subdistrict_name": "เมืองที"
    },
    {
        "subdistrict_id": "1991",
        "district_id": "253",
        "subdistrict_name": "ราม"
    },
    {
        "subdistrict_id": "1992",
        "district_id": "253",
        "subdistrict_name": "บุฤาษี"
    },
    {
        "subdistrict_id": "1993",
        "district_id": "253",
        "subdistrict_name": "ตระแสง"
    },
    {
        "subdistrict_id": "1994",
        "district_id": "253",
        "subdistrict_name": "แสลงพันธ์"
    },
    {
        "subdistrict_id": "1995",
        "district_id": "253",
        "subdistrict_name": "กาเกาะ"
    },
    {
        "subdistrict_id": "1996",
        "district_id": "254",
        "subdistrict_name": "ชุมพลบุรี"
    },
    {
        "subdistrict_id": "1997",
        "district_id": "254",
        "subdistrict_name": "นาหนองไผ่"
    },
    {
        "subdistrict_id": "1998",
        "district_id": "254",
        "subdistrict_name": "ไพรขลา"
    },
    {
        "subdistrict_id": "1999",
        "district_id": "254",
        "subdistrict_name": "ศรีณรงค์"
    },
    {
        "subdistrict_id": "2000",
        "district_id": "254",
        "subdistrict_name": "ยะวึก"
    },
    {
        "subdistrict_id": "2001",
        "district_id": "254",
        "subdistrict_name": "เมืองบัว"
    },
    {
        "subdistrict_id": "2002",
        "district_id": "254",
        "subdistrict_name": "สระขุด"
    },
    {
        "subdistrict_id": "2003",
        "district_id": "254",
        "subdistrict_name": "กระเบื้อง"
    },
    {
        "subdistrict_id": "2004",
        "district_id": "254",
        "subdistrict_name": "หนองเรือ"
    },
    {
        "subdistrict_id": "2005",
        "district_id": "255",
        "subdistrict_name": "ท่าตูม"
    },
    {
        "subdistrict_id": "2006",
        "district_id": "255",
        "subdistrict_name": "กระโพ"
    },
    {
        "subdistrict_id": "2007",
        "district_id": "255",
        "subdistrict_name": "พรมเทพ"
    },
    {
        "subdistrict_id": "2008",
        "district_id": "255",
        "subdistrict_name": "โพนครก"
    },
    {
        "subdistrict_id": "2009",
        "district_id": "255",
        "subdistrict_name": "เมืองแก"
    },
    {
        "subdistrict_id": "2010",
        "district_id": "255",
        "subdistrict_name": "บะ"
    },
    {
        "subdistrict_id": "2011",
        "district_id": "255",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "2012",
        "district_id": "255",
        "subdistrict_name": "บัวโคก"
    },
    {
        "subdistrict_id": "2013",
        "district_id": "255",
        "subdistrict_name": "หนองเมธี"
    },
    {
        "subdistrict_id": "2014",
        "district_id": "255",
        "subdistrict_name": "ทุ่งกุลา"
    },
    {
        "subdistrict_id": "2015",
        "district_id": "256",
        "subdistrict_name": "จอมพระ"
    },
    {
        "subdistrict_id": "2016",
        "district_id": "256",
        "subdistrict_name": "เมืองลีง"
    },
    {
        "subdistrict_id": "2017",
        "district_id": "256",
        "subdistrict_name": "กระหาด"
    },
    {
        "subdistrict_id": "2018",
        "district_id": "256",
        "subdistrict_name": "บุแกรง"
    },
    {
        "subdistrict_id": "2019",
        "district_id": "256",
        "subdistrict_name": "หนองสนิท"
    },
    {
        "subdistrict_id": "2020",
        "district_id": "256",
        "subdistrict_name": "บ้านผือ"
    },
    {
        "subdistrict_id": "2021",
        "district_id": "256",
        "subdistrict_name": "ลุ่มระวี"
    },
    {
        "subdistrict_id": "2022",
        "district_id": "256",
        "subdistrict_name": "ชุมแสง"
    },
    {
        "subdistrict_id": "2023",
        "district_id": "256",
        "subdistrict_name": "เป็นสุข"
    },
    {
        "subdistrict_id": "2024",
        "district_id": "257",
        "subdistrict_name": "กังแอน"
    },
    {
        "subdistrict_id": "2025",
        "district_id": "257",
        "subdistrict_name": "ทมอ"
    },
    {
        "subdistrict_id": "2026",
        "district_id": "257",
        "subdistrict_name": "ไพล"
    },
    {
        "subdistrict_id": "2027",
        "district_id": "257",
        "subdistrict_name": "ปรือ"
    },
    {
        "subdistrict_id": "2028",
        "district_id": "257",
        "subdistrict_name": "ทุ่งมน"
    },
    {
        "subdistrict_id": "2029",
        "district_id": "257",
        "subdistrict_name": "ตาเบา"
    },
    {
        "subdistrict_id": "2030",
        "district_id": "257",
        "subdistrict_name": "หนองใหญ่"
    },
    {
        "subdistrict_id": "2031",
        "district_id": "257",
        "subdistrict_name": "โคกยาง"
    },
    {
        "subdistrict_id": "2032",
        "district_id": "257",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "2033",
        "district_id": "257",
        "subdistrict_name": "บ้านไทร"
    },
    {
        "subdistrict_id": "2034",
        "district_id": "257",
        "subdistrict_name": "โชคนาสาม"
    },
    {
        "subdistrict_id": "2035",
        "district_id": "257",
        "subdistrict_name": "เชื้อเพลิง"
    },
    {
        "subdistrict_id": "2036",
        "district_id": "257",
        "subdistrict_name": "ปราสาททนง"
    },
    {
        "subdistrict_id": "2037",
        "district_id": "257",
        "subdistrict_name": "ตานี"
    },
    {
        "subdistrict_id": "2038",
        "district_id": "257",
        "subdistrict_name": "บ้านพลวง"
    },
    {
        "subdistrict_id": "2039",
        "district_id": "257",
        "subdistrict_name": "กันตวจระมวล"
    },
    {
        "subdistrict_id": "2040",
        "district_id": "257",
        "subdistrict_name": "สมุด"
    },
    {
        "subdistrict_id": "2041",
        "district_id": "257",
        "subdistrict_name": "ประทัดบุ"
    },
    {
        "subdistrict_id": "2042",
        "district_id": "258",
        "subdistrict_name": "กาบเชิง"
    },
    {
        "subdistrict_id": "2043",
        "district_id": "258",
        "subdistrict_name": "คูตัน"
    },
    {
        "subdistrict_id": "2044",
        "district_id": "258",
        "subdistrict_name": "ด่าน"
    },
    {
        "subdistrict_id": "2045",
        "district_id": "258",
        "subdistrict_name": "แนงมุด"
    },
    {
        "subdistrict_id": "2046",
        "district_id": "258",
        "subdistrict_name": "โคกตะเคียน"
    },
    {
        "subdistrict_id": "2047",
        "district_id": "258",
        "subdistrict_name": "ตะเคียน"
    },
    {
        "subdistrict_id": "2048",
        "district_id": "259",
        "subdistrict_name": "รัตนบุรี"
    },
    {
        "subdistrict_id": "2049",
        "district_id": "259",
        "subdistrict_name": "ธาตุ"
    },
    {
        "subdistrict_id": "2050",
        "district_id": "259",
        "subdistrict_name": "แก"
    },
    {
        "subdistrict_id": "2051",
        "district_id": "259",
        "subdistrict_name": "ดอนแรด"
    },
    {
        "subdistrict_id": "2052",
        "district_id": "259",
        "subdistrict_name": "หนองบัวทอง"
    },
    {
        "subdistrict_id": "2053",
        "district_id": "259",
        "subdistrict_name": "หนองบัวบาน"
    },
    {
        "subdistrict_id": "2054",
        "district_id": "259",
        "subdistrict_name": "ไผ่"
    },
    {
        "subdistrict_id": "2055",
        "district_id": "259",
        "subdistrict_name": "เบิด"
    },
    {
        "subdistrict_id": "2056",
        "district_id": "259",
        "subdistrict_name": "น้ำเขียว"
    },
    {
        "subdistrict_id": "2057",
        "district_id": "259",
        "subdistrict_name": "กุดขาคีม"
    },
    {
        "subdistrict_id": "2058",
        "district_id": "259",
        "subdistrict_name": "ยางสว่าง"
    },
    {
        "subdistrict_id": "2059",
        "district_id": "259",
        "subdistrict_name": "ทับใหญ่"
    },
    {
        "subdistrict_id": "2060",
        "district_id": "260",
        "subdistrict_name": "สนม"
    },
    {
        "subdistrict_id": "2061",
        "district_id": "260",
        "subdistrict_name": "โพนโก"
    },
    {
        "subdistrict_id": "2062",
        "district_id": "260",
        "subdistrict_name": "หนองระฆัง"
    },
    {
        "subdistrict_id": "2063",
        "district_id": "260",
        "subdistrict_name": "นานวน"
    },
    {
        "subdistrict_id": "2064",
        "district_id": "260",
        "subdistrict_name": "แคน"
    },
    {
        "subdistrict_id": "2065",
        "district_id": "260",
        "subdistrict_name": "หัวงัว"
    },
    {
        "subdistrict_id": "2066",
        "district_id": "260",
        "subdistrict_name": "หนองอียอ"
    },
    {
        "subdistrict_id": "2067",
        "district_id": "261",
        "subdistrict_name": "ระแงง"
    },
    {
        "subdistrict_id": "2068",
        "district_id": "261",
        "subdistrict_name": "ตรึม"
    },
    {
        "subdistrict_id": "2069",
        "district_id": "261",
        "subdistrict_name": "จารพัต"
    },
    {
        "subdistrict_id": "2070",
        "district_id": "261",
        "subdistrict_name": "ยาง"
    },
    {
        "subdistrict_id": "2071",
        "district_id": "261",
        "subdistrict_name": "แตล"
    },
    {
        "subdistrict_id": "2072",
        "district_id": "261",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "2073",
        "district_id": "261",
        "subdistrict_name": "คาละแมะ"
    },
    {
        "subdistrict_id": "2074",
        "district_id": "261",
        "subdistrict_name": "หนองเหล็ก"
    },
    {
        "subdistrict_id": "2075",
        "district_id": "261",
        "subdistrict_name": "หนองขวาว"
    },
    {
        "subdistrict_id": "2076",
        "district_id": "261",
        "subdistrict_name": "ช่างปี่"
    },
    {
        "subdistrict_id": "2077",
        "district_id": "261",
        "subdistrict_name": "กุดหวาย"
    },
    {
        "subdistrict_id": "2078",
        "district_id": "261",
        "subdistrict_name": "ขวาวใหญ่"
    },
    {
        "subdistrict_id": "2079",
        "district_id": "261",
        "subdistrict_name": "นารุ่ง"
    },
    {
        "subdistrict_id": "2080",
        "district_id": "261",
        "subdistrict_name": "ตรมไพร"
    },
    {
        "subdistrict_id": "2081",
        "district_id": "261",
        "subdistrict_name": "ผักไหม"
    },
    {
        "subdistrict_id": "2082",
        "district_id": "262",
        "subdistrict_name": "สังขะ"
    },
    {
        "subdistrict_id": "2083",
        "district_id": "262",
        "subdistrict_name": "ขอนแตก"
    },
    {
        "subdistrict_id": "2084",
        "district_id": "262",
        "subdistrict_name": "ดม"
    },
    {
        "subdistrict_id": "2085",
        "district_id": "262",
        "subdistrict_name": "พระแก้ว"
    },
    {
        "subdistrict_id": "2086",
        "district_id": "262",
        "subdistrict_name": "บ้านจารย์"
    },
    {
        "subdistrict_id": "2087",
        "district_id": "262",
        "subdistrict_name": "กระเทียม"
    },
    {
        "subdistrict_id": "2088",
        "district_id": "262",
        "subdistrict_name": "สะกาด"
    },
    {
        "subdistrict_id": "2089",
        "district_id": "262",
        "subdistrict_name": "ตาตุม"
    },
    {
        "subdistrict_id": "2090",
        "district_id": "262",
        "subdistrict_name": "ทับทัน"
    },
    {
        "subdistrict_id": "2091",
        "district_id": "262",
        "subdistrict_name": "ตาคง"
    },
    {
        "subdistrict_id": "2092",
        "district_id": "262",
        "subdistrict_name": "บ้านชบ"
    },
    {
        "subdistrict_id": "2093",
        "district_id": "262",
        "subdistrict_name": "เทพรักษา"
    },
    {
        "subdistrict_id": "2094",
        "district_id": "263",
        "subdistrict_name": "ลำดวน"
    },
    {
        "subdistrict_id": "2095",
        "district_id": "263",
        "subdistrict_name": "โชคเหนือ"
    },
    {
        "subdistrict_id": "2096",
        "district_id": "263",
        "subdistrict_name": "อู่โลก"
    },
    {
        "subdistrict_id": "2097",
        "district_id": "263",
        "subdistrict_name": "ตรำดม"
    },
    {
        "subdistrict_id": "2098",
        "district_id": "263",
        "subdistrict_name": "ตระเปียงเตีย"
    },
    {
        "subdistrict_id": "2099",
        "district_id": "264",
        "subdistrict_name": "สำโรงทาบ"
    },
    {
        "subdistrict_id": "2100",
        "district_id": "264",
        "subdistrict_name": "หนองไผ่ล้อม"
    },
    {
        "subdistrict_id": "2101",
        "district_id": "264",
        "subdistrict_name": "กระออม"
    },
    {
        "subdistrict_id": "2102",
        "district_id": "264",
        "subdistrict_name": "หนองฮะ"
    },
    {
        "subdistrict_id": "2103",
        "district_id": "264",
        "subdistrict_name": "ศรีสุข"
    },
    {
        "subdistrict_id": "2104",
        "district_id": "264",
        "subdistrict_name": "เกาะแก้ว"
    },
    {
        "subdistrict_id": "2105",
        "district_id": "264",
        "subdistrict_name": "หมื่นศรี"
    },
    {
        "subdistrict_id": "2106",
        "district_id": "264",
        "subdistrict_name": "เสม็จ"
    },
    {
        "subdistrict_id": "2107",
        "district_id": "264",
        "subdistrict_name": "สะโน"
    },
    {
        "subdistrict_id": "2108",
        "district_id": "264",
        "subdistrict_name": "ประดู่"
    },
    {
        "subdistrict_id": "2109",
        "district_id": "265",
        "subdistrict_name": "บัวเชด"
    },
    {
        "subdistrict_id": "2110",
        "district_id": "265",
        "subdistrict_name": "สะเดา"
    },
    {
        "subdistrict_id": "2111",
        "district_id": "265",
        "subdistrict_name": "จรัส"
    },
    {
        "subdistrict_id": "2112",
        "district_id": "265",
        "subdistrict_name": "ตาวัง"
    },
    {
        "subdistrict_id": "2113",
        "district_id": "265",
        "subdistrict_name": "อาโพน"
    },
    {
        "subdistrict_id": "2114",
        "district_id": "265",
        "subdistrict_name": "สำเภาลูน"
    },
    {
        "subdistrict_id": "2115",
        "district_id": "266",
        "subdistrict_name": "บักได"
    },
    {
        "subdistrict_id": "2116",
        "district_id": "266",
        "subdistrict_name": "โคกกลาง"
    },
    {
        "subdistrict_id": "2117",
        "district_id": "266",
        "subdistrict_name": "จีกแดก"
    },
    {
        "subdistrict_id": "2118",
        "district_id": "266",
        "subdistrict_name": "ตาเมียง"
    },
    {
        "subdistrict_id": "2119",
        "district_id": "267",
        "subdistrict_name": "ณรงค์"
    },
    {
        "subdistrict_id": "2120",
        "district_id": "267",
        "subdistrict_name": "แจนแวน"
    },
    {
        "subdistrict_id": "2121",
        "district_id": "267",
        "subdistrict_name": "ตรวจ"
    },
    {
        "subdistrict_id": "2122",
        "district_id": "267",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "2123",
        "district_id": "267",
        "subdistrict_name": "ศรีสุข"
    },
    {
        "subdistrict_id": "2124",
        "district_id": "268",
        "subdistrict_name": "เขวาสินรินทร์"
    },
    {
        "subdistrict_id": "2125",
        "district_id": "268",
        "subdistrict_name": "บึง"
    },
    {
        "subdistrict_id": "2126",
        "district_id": "268",
        "subdistrict_name": "ตากูก"
    },
    {
        "subdistrict_id": "2127",
        "district_id": "268",
        "subdistrict_name": "ปราสาททอง"
    },
    {
        "subdistrict_id": "2128",
        "district_id": "268",
        "subdistrict_name": "บ้านแร่"
    },
    {
        "subdistrict_id": "2129",
        "district_id": "269",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "2130",
        "district_id": "269",
        "subdistrict_name": "คำผง"
    },
    {
        "subdistrict_id": "2131",
        "district_id": "269",
        "subdistrict_name": "โนน"
    },
    {
        "subdistrict_id": "2132",
        "district_id": "269",
        "subdistrict_name": "ระเวียง"
    },
    {
        "subdistrict_id": "2133",
        "district_id": "269",
        "subdistrict_name": "หนองเทพ"
    },
    {
        "subdistrict_id": "2134",
        "district_id": "270",
        "subdistrict_name": "เมืองเหนือ"
    },
    {
        "subdistrict_id": "2135",
        "district_id": "270",
        "subdistrict_name": "เมืองใต้"
    },
    {
        "subdistrict_id": "2136",
        "district_id": "270",
        "subdistrict_name": "คูซอด"
    },
    {
        "subdistrict_id": "2137",
        "district_id": "270",
        "subdistrict_name": "ซำ"
    },
    {
        "subdistrict_id": "2138",
        "district_id": "270",
        "subdistrict_name": "จาน"
    },
    {
        "subdistrict_id": "2139",
        "district_id": "270",
        "subdistrict_name": "ตะดอบ"
    },
    {
        "subdistrict_id": "2140",
        "district_id": "270",
        "subdistrict_name": "หนองครก"
    },
    {
        "subdistrict_id": "2141",
        "district_id": "270",
        "subdistrict_name": "โพนข่า"
    },
    {
        "subdistrict_id": "2142",
        "district_id": "270",
        "subdistrict_name": "โพนค้อ"
    },
    {
        "subdistrict_id": "2143",
        "district_id": "270",
        "subdistrict_name": "โพนเขวา"
    },
    {
        "subdistrict_id": "2144",
        "district_id": "270",
        "subdistrict_name": "หญ้าปล้อง"
    },
    {
        "subdistrict_id": "2145",
        "district_id": "270",
        "subdistrict_name": "ทุ่ม"
    },
    {
        "subdistrict_id": "2146",
        "district_id": "270",
        "subdistrict_name": "หนองไฮ"
    },
    {
        "subdistrict_id": "2147",
        "district_id": "270",
        "subdistrict_name": "หนองแก้ว"
    },
    {
        "subdistrict_id": "2148",
        "district_id": "270",
        "subdistrict_name": "น้ำคำ"
    },
    {
        "subdistrict_id": "2149",
        "district_id": "270",
        "subdistrict_name": "โพธิ์"
    },
    {
        "subdistrict_id": "2150",
        "district_id": "270",
        "subdistrict_name": "หมากเขียบ"
    },
    {
        "subdistrict_id": "2151",
        "district_id": "270",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "2152",
        "district_id": "271",
        "subdistrict_name": "ยางชุมน้อย"
    },
    {
        "subdistrict_id": "2153",
        "district_id": "271",
        "subdistrict_name": "ลิ้นฟ้า"
    },
    {
        "subdistrict_id": "2154",
        "district_id": "271",
        "subdistrict_name": "คอนกาม"
    },
    {
        "subdistrict_id": "2155",
        "district_id": "271",
        "subdistrict_name": "โนนคูณ"
    },
    {
        "subdistrict_id": "2156",
        "district_id": "271",
        "subdistrict_name": "กุดเมืองฮาม"
    },
    {
        "subdistrict_id": "2157",
        "district_id": "271",
        "subdistrict_name": "บึงบอน"
    },
    {
        "subdistrict_id": "2158",
        "district_id": "271",
        "subdistrict_name": "ยางชุมใหญ่"
    },
    {
        "subdistrict_id": "2159",
        "district_id": "272",
        "subdistrict_name": "ดูน"
    },
    {
        "subdistrict_id": "2160",
        "district_id": "272",
        "subdistrict_name": "โนนสัง"
    },
    {
        "subdistrict_id": "2161",
        "district_id": "272",
        "subdistrict_name": "หนองหัวช้าง"
    },
    {
        "subdistrict_id": "2162",
        "district_id": "272",
        "subdistrict_name": "ยาง"
    },
    {
        "subdistrict_id": "2163",
        "district_id": "272",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "2164",
        "district_id": "272",
        "subdistrict_name": "หนองแก้ว"
    },
    {
        "subdistrict_id": "2165",
        "district_id": "272",
        "subdistrict_name": "ทาม"
    },
    {
        "subdistrict_id": "2166",
        "district_id": "272",
        "subdistrict_name": "ละทาย"
    },
    {
        "subdistrict_id": "2167",
        "district_id": "272",
        "subdistrict_name": "เมืองน้อย"
    },
    {
        "subdistrict_id": "2168",
        "district_id": "272",
        "subdistrict_name": "อีปาด"
    },
    {
        "subdistrict_id": "2169",
        "district_id": "272",
        "subdistrict_name": "บัวน้อย"
    },
    {
        "subdistrict_id": "2170",
        "district_id": "272",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "2171",
        "district_id": "272",
        "subdistrict_name": "ดู่"
    },
    {
        "subdistrict_id": "2172",
        "district_id": "272",
        "subdistrict_name": "ผักแพว"
    },
    {
        "subdistrict_id": "2173",
        "district_id": "272",
        "subdistrict_name": "จาน"
    },
    {
        "subdistrict_id": "2174",
        "district_id": "272",
        "subdistrict_name": "คำเนียม"
    },
    {
        "subdistrict_id": "2175",
        "district_id": "273",
        "subdistrict_name": "บึงมะลู"
    },
    {
        "subdistrict_id": "2176",
        "district_id": "273",
        "subdistrict_name": "กุดเสลา"
    },
    {
        "subdistrict_id": "2177",
        "district_id": "273",
        "subdistrict_name": "เมือง"
    },
    {
        "subdistrict_id": "2178",
        "district_id": "273",
        "subdistrict_name": "สังเม็ก"
    },
    {
        "subdistrict_id": "2179",
        "district_id": "273",
        "subdistrict_name": "น้ำอ้อม"
    },
    {
        "subdistrict_id": "2180",
        "district_id": "273",
        "subdistrict_name": "ละลาย"
    },
    {
        "subdistrict_id": "2181",
        "district_id": "273",
        "subdistrict_name": "รุง"
    },
    {
        "subdistrict_id": "2182",
        "district_id": "273",
        "subdistrict_name": "ตระกาจ"
    },
    {
        "subdistrict_id": "2183",
        "district_id": "273",
        "subdistrict_name": "จานใหญ่"
    },
    {
        "subdistrict_id": "2184",
        "district_id": "273",
        "subdistrict_name": "ภูเงิน"
    },
    {
        "subdistrict_id": "2185",
        "district_id": "273",
        "subdistrict_name": "ชำ"
    },
    {
        "subdistrict_id": "2186",
        "district_id": "273",
        "subdistrict_name": "กระแชง"
    },
    {
        "subdistrict_id": "2187",
        "district_id": "273",
        "subdistrict_name": "โนนสำราญ"
    },
    {
        "subdistrict_id": "2188",
        "district_id": "273",
        "subdistrict_name": "หนองหญ้าลาด"
    },
    {
        "subdistrict_id": "2189",
        "district_id": "273",
        "subdistrict_name": "เสาธงชัย"
    },
    {
        "subdistrict_id": "2190",
        "district_id": "273",
        "subdistrict_name": "ขนุน"
    },
    {
        "subdistrict_id": "2191",
        "district_id": "273",
        "subdistrict_name": "สวนกล้วย"
    },
    {
        "subdistrict_id": "2192",
        "district_id": "273",
        "subdistrict_name": "เวียงเหนือ"
    },
    {
        "subdistrict_id": "2193",
        "district_id": "273",
        "subdistrict_name": "ทุ่งใหญ่"
    },
    {
        "subdistrict_id": "2194",
        "district_id": "273",
        "subdistrict_name": "ภูผาหมอก"
    },
    {
        "subdistrict_id": "2195",
        "district_id": "274",
        "subdistrict_name": "กันทรารมย์"
    },
    {
        "subdistrict_id": "2196",
        "district_id": "274",
        "subdistrict_name": "จะกง"
    },
    {
        "subdistrict_id": "2197",
        "district_id": "274",
        "subdistrict_name": "ใจดี"
    },
    {
        "subdistrict_id": "2198",
        "district_id": "274",
        "subdistrict_name": "ดองกำเม็ด"
    },
    {
        "subdistrict_id": "2199",
        "district_id": "274",
        "subdistrict_name": "โสน"
    },
    {
        "subdistrict_id": "2200",
        "district_id": "274",
        "subdistrict_name": "ปรือใหญ่"
    },
    {
        "subdistrict_id": "2201",
        "district_id": "274",
        "subdistrict_name": "สะเดาใหญ่"
    },
    {
        "subdistrict_id": "2202",
        "district_id": "274",
        "subdistrict_name": "ตาอุด"
    },
    {
        "subdistrict_id": "2203",
        "district_id": "274",
        "subdistrict_name": "ห้วยเหนือ"
    },
    {
        "subdistrict_id": "2204",
        "district_id": "274",
        "subdistrict_name": "ห้วยใต้"
    },
    {
        "subdistrict_id": "2205",
        "district_id": "274",
        "subdistrict_name": "หัวเสือ"
    },
    {
        "subdistrict_id": "2206",
        "district_id": "274",
        "subdistrict_name": "ตะเคียน"
    },
    {
        "subdistrict_id": "2207",
        "district_id": "274",
        "subdistrict_name": "นิคมพัฒนา"
    },
    {
        "subdistrict_id": "2208",
        "district_id": "274",
        "subdistrict_name": "โคกเพชร"
    },
    {
        "subdistrict_id": "2209",
        "district_id": "274",
        "subdistrict_name": "ปราสาท"
    },
    {
        "subdistrict_id": "2210",
        "district_id": "274",
        "subdistrict_name": "สำโรงตาเจ็น"
    },
    {
        "subdistrict_id": "2211",
        "district_id": "274",
        "subdistrict_name": "ห้วยสำราญ"
    },
    {
        "subdistrict_id": "2212",
        "district_id": "274",
        "subdistrict_name": "กฤษณา"
    },
    {
        "subdistrict_id": "2213",
        "district_id": "274",
        "subdistrict_name": "ลมศักดิ์"
    },
    {
        "subdistrict_id": "2214",
        "district_id": "274",
        "subdistrict_name": "หนองฉลอง"
    },
    {
        "subdistrict_id": "2215",
        "district_id": "274",
        "subdistrict_name": "ศรีตระกูล"
    },
    {
        "subdistrict_id": "2216",
        "district_id": "274",
        "subdistrict_name": "ศรีสะอาด"
    },
    {
        "subdistrict_id": "2217",
        "district_id": "275",
        "subdistrict_name": "ไพรบึง"
    },
    {
        "subdistrict_id": "2218",
        "district_id": "275",
        "subdistrict_name": "ดินแดง"
    },
    {
        "subdistrict_id": "2219",
        "district_id": "275",
        "subdistrict_name": "ปราสาทเยอ"
    },
    {
        "subdistrict_id": "2220",
        "district_id": "275",
        "subdistrict_name": "สำโรงพลัน"
    },
    {
        "subdistrict_id": "2221",
        "district_id": "275",
        "subdistrict_name": "สุขสวัสดิ์"
    },
    {
        "subdistrict_id": "2222",
        "district_id": "275",
        "subdistrict_name": "โนนปูน"
    },
    {
        "subdistrict_id": "2223",
        "district_id": "276",
        "subdistrict_name": "พิมาย"
    },
    {
        "subdistrict_id": "2224",
        "district_id": "276",
        "subdistrict_name": "กู่"
    },
    {
        "subdistrict_id": "2225",
        "district_id": "276",
        "subdistrict_name": "หนองเชียงทูน"
    },
    {
        "subdistrict_id": "2226",
        "district_id": "276",
        "subdistrict_name": "ตูม"
    },
    {
        "subdistrict_id": "2227",
        "district_id": "276",
        "subdistrict_name": "สมอ"
    },
    {
        "subdistrict_id": "2228",
        "district_id": "276",
        "subdistrict_name": "โพธิ์ศรี"
    },
    {
        "subdistrict_id": "2229",
        "district_id": "276",
        "subdistrict_name": "สำโรงปราสาท"
    },
    {
        "subdistrict_id": "2230",
        "district_id": "276",
        "subdistrict_name": "ดู่"
    },
    {
        "subdistrict_id": "2231",
        "district_id": "276",
        "subdistrict_name": "สวาย"
    },
    {
        "subdistrict_id": "2232",
        "district_id": "276",
        "subdistrict_name": "พิมายเหนือ"
    },
    {
        "subdistrict_id": "2233",
        "district_id": "277",
        "subdistrict_name": "สิ"
    },
    {
        "subdistrict_id": "2234",
        "district_id": "277",
        "subdistrict_name": "บักดอง"
    },
    {
        "subdistrict_id": "2235",
        "district_id": "277",
        "subdistrict_name": "พราน"
    },
    {
        "subdistrict_id": "2236",
        "district_id": "277",
        "subdistrict_name": "โพธิ์วงศ์"
    },
    {
        "subdistrict_id": "2237",
        "district_id": "277",
        "subdistrict_name": "ไพร"
    },
    {
        "subdistrict_id": "2238",
        "district_id": "277",
        "subdistrict_name": "กระหวัน"
    },
    {
        "subdistrict_id": "2239",
        "district_id": "277",
        "subdistrict_name": "ขุนหาญ"
    },
    {
        "subdistrict_id": "2240",
        "district_id": "277",
        "subdistrict_name": "โนนสูง"
    },
    {
        "subdistrict_id": "2241",
        "district_id": "277",
        "subdistrict_name": "กันทรอม"
    },
    {
        "subdistrict_id": "2242",
        "district_id": "277",
        "subdistrict_name": "ภูฝ้าย"
    },
    {
        "subdistrict_id": "2243",
        "district_id": "277",
        "subdistrict_name": "โพธิ์กระสังข์"
    },
    {
        "subdistrict_id": "2244",
        "district_id": "277",
        "subdistrict_name": "ห้วยจันทร์"
    },
    {
        "subdistrict_id": "2245",
        "district_id": "278",
        "subdistrict_name": "เมืองคง"
    },
    {
        "subdistrict_id": "2246",
        "district_id": "278",
        "subdistrict_name": "เมืองแคน"
    },
    {
        "subdistrict_id": "2247",
        "district_id": "278",
        "subdistrict_name": "หนองแค"
    },
    {
        "subdistrict_id": "2248",
        "district_id": "278",
        "subdistrict_name": "จิกสังข์ทอง"
    },
    {
        "subdistrict_id": "2249",
        "district_id": "278",
        "subdistrict_name": "ด่าน"
    },
    {
        "subdistrict_id": "2250",
        "district_id": "278",
        "subdistrict_name": "ดู่"
    },
    {
        "subdistrict_id": "2251",
        "district_id": "278",
        "subdistrict_name": "หนองอึ่ง"
    },
    {
        "subdistrict_id": "2252",
        "district_id": "278",
        "subdistrict_name": "บัวหุ่ง"
    },
    {
        "subdistrict_id": "2253",
        "district_id": "278",
        "subdistrict_name": "ไผ่"
    },
    {
        "subdistrict_id": "2254",
        "district_id": "278",
        "subdistrict_name": "ส้มป่อย"
    },
    {
        "subdistrict_id": "2255",
        "district_id": "278",
        "subdistrict_name": "หนองหมี"
    },
    {
        "subdistrict_id": "2256",
        "district_id": "278",
        "subdistrict_name": "หว้านคำ"
    },
    {
        "subdistrict_id": "2257",
        "district_id": "278",
        "subdistrict_name": "สร้างปี่"
    },
    {
        "subdistrict_id": "2258",
        "district_id": "279",
        "subdistrict_name": "กำแพง"
    },
    {
        "subdistrict_id": "2259",
        "district_id": "279",
        "subdistrict_name": "อี่หล่ำ"
    },
    {
        "subdistrict_id": "2260",
        "district_id": "279",
        "subdistrict_name": "ก้านเหลือง"
    },
    {
        "subdistrict_id": "2261",
        "district_id": "279",
        "subdistrict_name": "ทุ่งไชย"
    },
    {
        "subdistrict_id": "2262",
        "district_id": "279",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "2263",
        "district_id": "279",
        "subdistrict_name": "แขม"
    },
    {
        "subdistrict_id": "2264",
        "district_id": "279",
        "subdistrict_name": "หนองไฮ"
    },
    {
        "subdistrict_id": "2265",
        "district_id": "279",
        "subdistrict_name": "ขะยูง"
    },
    {
        "subdistrict_id": "2266",
        "district_id": "279",
        "subdistrict_name": "ตาเกษ"
    },
    {
        "subdistrict_id": "2267",
        "district_id": "279",
        "subdistrict_name": "หัวช้าง"
    },
    {
        "subdistrict_id": "2268",
        "district_id": "279",
        "subdistrict_name": "รังแร้ง"
    },
    {
        "subdistrict_id": "2269",
        "district_id": "279",
        "subdistrict_name": "แต้"
    },
    {
        "subdistrict_id": "2270",
        "district_id": "279",
        "subdistrict_name": "แข้"
    },
    {
        "subdistrict_id": "2271",
        "district_id": "279",
        "subdistrict_name": "โพธิ์ชัย"
    },
    {
        "subdistrict_id": "2272",
        "district_id": "279",
        "subdistrict_name": "ปะอาว"
    },
    {
        "subdistrict_id": "2273",
        "district_id": "279",
        "subdistrict_name": "หนองห้าง"
    },
    {
        "subdistrict_id": "2274",
        "district_id": "279",
        "subdistrict_name": "สระกำแพงใหญ่"
    },
    {
        "subdistrict_id": "2275",
        "district_id": "279",
        "subdistrict_name": "โคกหล่าม"
    },
    {
        "subdistrict_id": "2276",
        "district_id": "279",
        "subdistrict_name": "โคกจาน"
    },
    {
        "subdistrict_id": "2277",
        "district_id": "280",
        "subdistrict_name": "เป๊าะ"
    },
    {
        "subdistrict_id": "2278",
        "district_id": "280",
        "subdistrict_name": "บึงบูรพ์"
    },
    {
        "subdistrict_id": "2279",
        "district_id": "281",
        "subdistrict_name": "ห้วยทับทัน"
    },
    {
        "subdistrict_id": "2280",
        "district_id": "281",
        "subdistrict_name": "เมืองหลวง"
    },
    {
        "subdistrict_id": "2281",
        "district_id": "281",
        "subdistrict_name": "กล้วยกว้าง"
    },
    {
        "subdistrict_id": "2282",
        "district_id": "281",
        "subdistrict_name": "ผักไหม"
    },
    {
        "subdistrict_id": "2283",
        "district_id": "281",
        "subdistrict_name": "จานแสนไชย"
    },
    {
        "subdistrict_id": "2284",
        "district_id": "281",
        "subdistrict_name": "ปราสาท"
    },
    {
        "subdistrict_id": "2285",
        "district_id": "282",
        "subdistrict_name": "โนนค้อ"
    },
    {
        "subdistrict_id": "2286",
        "district_id": "282",
        "subdistrict_name": "บก"
    },
    {
        "subdistrict_id": "2287",
        "district_id": "282",
        "subdistrict_name": "โพธิ์"
    },
    {
        "subdistrict_id": "2288",
        "district_id": "282",
        "subdistrict_name": "หนองกุง"
    },
    {
        "subdistrict_id": "2289",
        "district_id": "282",
        "subdistrict_name": "เหล่ากวาง"
    },
    {
        "subdistrict_id": "2290",
        "district_id": "283",
        "subdistrict_name": "ศรีแก้ว"
    },
    {
        "subdistrict_id": "2291",
        "district_id": "283",
        "subdistrict_name": "พิงพวย"
    },
    {
        "subdistrict_id": "2292",
        "district_id": "283",
        "subdistrict_name": "สระเยาว์"
    },
    {
        "subdistrict_id": "2293",
        "district_id": "283",
        "subdistrict_name": "ตูม"
    },
    {
        "subdistrict_id": "2294",
        "district_id": "283",
        "subdistrict_name": "เสื่องข้าว"
    },
    {
        "subdistrict_id": "2295",
        "district_id": "283",
        "subdistrict_name": "ศรีโนนงาม"
    },
    {
        "subdistrict_id": "2296",
        "district_id": "283",
        "subdistrict_name": "สะพุง"
    },
    {
        "subdistrict_id": "2297",
        "district_id": "284",
        "subdistrict_name": "น้ำเกลี้ยง"
    },
    {
        "subdistrict_id": "2298",
        "district_id": "284",
        "subdistrict_name": "ละเอาะ"
    },
    {
        "subdistrict_id": "2299",
        "district_id": "284",
        "subdistrict_name": "ตองปิด"
    },
    {
        "subdistrict_id": "2300",
        "district_id": "284",
        "subdistrict_name": "เขิน"
    },
    {
        "subdistrict_id": "2301",
        "district_id": "284",
        "subdistrict_name": "รุ่งระวี"
    },
    {
        "subdistrict_id": "2302",
        "district_id": "284",
        "subdistrict_name": "คูบ"
    },
    {
        "subdistrict_id": "2303",
        "district_id": "285",
        "subdistrict_name": "บุสูง"
    },
    {
        "subdistrict_id": "2304",
        "district_id": "285",
        "subdistrict_name": "ธาตุ"
    },
    {
        "subdistrict_id": "2305",
        "district_id": "285",
        "subdistrict_name": "ดวนใหญ่"
    },
    {
        "subdistrict_id": "2306",
        "district_id": "285",
        "subdistrict_name": "บ่อแก้ว"
    },
    {
        "subdistrict_id": "2307",
        "district_id": "285",
        "subdistrict_name": "ศรีสำราญ"
    },
    {
        "subdistrict_id": "2308",
        "district_id": "285",
        "subdistrict_name": "ทุ่งสว่าง"
    },
    {
        "subdistrict_id": "2309",
        "district_id": "285",
        "subdistrict_name": "วังหิน"
    },
    {
        "subdistrict_id": "2310",
        "district_id": "285",
        "subdistrict_name": "โพนยาง"
    },
    {
        "subdistrict_id": "2311",
        "district_id": "286",
        "subdistrict_name": "โคกตาล"
    },
    {
        "subdistrict_id": "2312",
        "district_id": "286",
        "subdistrict_name": "ห้วยตามอญ"
    },
    {
        "subdistrict_id": "2313",
        "district_id": "286",
        "subdistrict_name": "ห้วยตึ๊กชู"
    },
    {
        "subdistrict_id": "2314",
        "district_id": "286",
        "subdistrict_name": "ละลม"
    },
    {
        "subdistrict_id": "2315",
        "district_id": "286",
        "subdistrict_name": "ตะเคียนราม"
    },
    {
        "subdistrict_id": "2316",
        "district_id": "286",
        "subdistrict_name": "ดงรัก"
    },
    {
        "subdistrict_id": "2317",
        "district_id": "286",
        "subdistrict_name": "ไพรพัฒนา"
    },
    {
        "subdistrict_id": "2318",
        "district_id": "287",
        "subdistrict_name": "เมืองจันทร์"
    },
    {
        "subdistrict_id": "2319",
        "district_id": "287",
        "subdistrict_name": "ตาโกน"
    },
    {
        "subdistrict_id": "2320",
        "district_id": "287",
        "subdistrict_name": "หนองใหญ่"
    },
    {
        "subdistrict_id": "2321",
        "district_id": "288",
        "subdistrict_name": "เสียว"
    },
    {
        "subdistrict_id": "2322",
        "district_id": "288",
        "subdistrict_name": "หนองหว้า"
    },
    {
        "subdistrict_id": "2323",
        "district_id": "288",
        "subdistrict_name": "หนองงูเหลือม"
    },
    {
        "subdistrict_id": "2324",
        "district_id": "288",
        "subdistrict_name": "หนองฮาง"
    },
    {
        "subdistrict_id": "2325",
        "district_id": "288",
        "subdistrict_name": "ท่าคล้อ"
    },
    {
        "subdistrict_id": "2326",
        "district_id": "289",
        "subdistrict_name": "พยุห์"
    },
    {
        "subdistrict_id": "2327",
        "district_id": "289",
        "subdistrict_name": "พรหมสวัสดิ์"
    },
    {
        "subdistrict_id": "2328",
        "district_id": "289",
        "subdistrict_name": "ตำแย"
    },
    {
        "subdistrict_id": "2329",
        "district_id": "289",
        "subdistrict_name": "โนนเพ็ก"
    },
    {
        "subdistrict_id": "2330",
        "district_id": "289",
        "subdistrict_name": "หนองค้า"
    },
    {
        "subdistrict_id": "2331",
        "district_id": "290",
        "subdistrict_name": "โดด"
    },
    {
        "subdistrict_id": "2332",
        "district_id": "290",
        "subdistrict_name": "เสียว"
    },
    {
        "subdistrict_id": "2333",
        "district_id": "290",
        "subdistrict_name": "หนองม้า"
    },
    {
        "subdistrict_id": "2334",
        "district_id": "290",
        "subdistrict_name": "ผือใหญ่"
    },
    {
        "subdistrict_id": "2335",
        "district_id": "290",
        "subdistrict_name": "อีเซ"
    },
    {
        "subdistrict_id": "2336",
        "district_id": "291",
        "subdistrict_name": "กุง"
    },
    {
        "subdistrict_id": "2337",
        "district_id": "291",
        "subdistrict_name": "คลีกลิ้ง"
    },
    {
        "subdistrict_id": "2338",
        "district_id": "291",
        "subdistrict_name": "หนองบัวดง"
    },
    {
        "subdistrict_id": "2339",
        "district_id": "291",
        "subdistrict_name": "โจดม่วง"
    },
    {
        "subdistrict_id": "2340",
        "district_id": "292",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "2341",
        "district_id": "292",
        "subdistrict_name": "หัวเรือ"
    },
    {
        "subdistrict_id": "2342",
        "district_id": "292",
        "subdistrict_name": "หนองขอน"
    },
    {
        "subdistrict_id": "2343",
        "district_id": "292",
        "subdistrict_name": "ปทุม"
    },
    {
        "subdistrict_id": "2344",
        "district_id": "292",
        "subdistrict_name": "ขามใหญ่"
    },
    {
        "subdistrict_id": "2345",
        "district_id": "292",
        "subdistrict_name": "แจระแม"
    },
    {
        "subdistrict_id": "2346",
        "district_id": "292",
        "subdistrict_name": "หนองบ่อ"
    },
    {
        "subdistrict_id": "2347",
        "district_id": "292",
        "subdistrict_name": "ไร่น้อย"
    },
    {
        "subdistrict_id": "2348",
        "district_id": "292",
        "subdistrict_name": "กระโสบ"
    },
    {
        "subdistrict_id": "2349",
        "district_id": "292",
        "subdistrict_name": "กุดลาด"
    },
    {
        "subdistrict_id": "2350",
        "district_id": "292",
        "subdistrict_name": "ขี้เหล็ก"
    },
    {
        "subdistrict_id": "2351",
        "district_id": "292",
        "subdistrict_name": "ปะอาว"
    },
    {
        "subdistrict_id": "2352",
        "district_id": "293",
        "subdistrict_name": "นาคำ"
    },
    {
        "subdistrict_id": "2353",
        "district_id": "293",
        "subdistrict_name": "แก้งกอก"
    },
    {
        "subdistrict_id": "2354",
        "district_id": "293",
        "subdistrict_name": "เอือดใหญ่"
    },
    {
        "subdistrict_id": "2355",
        "district_id": "293",
        "subdistrict_name": "วาริน"
    },
    {
        "subdistrict_id": "2356",
        "district_id": "293",
        "subdistrict_name": "ลาดควาย"
    },
    {
        "subdistrict_id": "2357",
        "district_id": "293",
        "subdistrict_name": "สงยาง"
    },
    {
        "subdistrict_id": "2358",
        "district_id": "293",
        "subdistrict_name": "ตะบ่าย"
    },
    {
        "subdistrict_id": "2359",
        "district_id": "293",
        "subdistrict_name": "คำไหล"
    },
    {
        "subdistrict_id": "2360",
        "district_id": "293",
        "subdistrict_name": "หนามแท่ง"
    },
    {
        "subdistrict_id": "2361",
        "district_id": "293",
        "subdistrict_name": "นาเลิน"
    },
    {
        "subdistrict_id": "2362",
        "district_id": "293",
        "subdistrict_name": "ดอนใหญ่"
    },
    {
        "subdistrict_id": "2363",
        "district_id": "294",
        "subdistrict_name": "โขงเจียม"
    },
    {
        "subdistrict_id": "2364",
        "district_id": "294",
        "subdistrict_name": "ห้วยยาง"
    },
    {
        "subdistrict_id": "2365",
        "district_id": "294",
        "subdistrict_name": "นาโพธิ์กลาง"
    },
    {
        "subdistrict_id": "2366",
        "district_id": "294",
        "subdistrict_name": "หนองแสงใหญ่"
    },
    {
        "subdistrict_id": "2367",
        "district_id": "294",
        "subdistrict_name": "ห้วยไผ่"
    },
    {
        "subdistrict_id": "2368",
        "district_id": "294",
        "subdistrict_name": "คำเขื่อนแก้ว"
    },
    {
        "subdistrict_id": "2369",
        "district_id": "295",
        "subdistrict_name": "เขื่องใน"
    },
    {
        "subdistrict_id": "2370",
        "district_id": "295",
        "subdistrict_name": "สร้างถ่อ"
    },
    {
        "subdistrict_id": "2371",
        "district_id": "295",
        "subdistrict_name": "ค้อทอง"
    },
    {
        "subdistrict_id": "2372",
        "district_id": "295",
        "subdistrict_name": "ก่อเอ้"
    },
    {
        "subdistrict_id": "2373",
        "district_id": "295",
        "subdistrict_name": "หัวดอน"
    },
    {
        "subdistrict_id": "2374",
        "district_id": "295",
        "subdistrict_name": "ชีทวน"
    },
    {
        "subdistrict_id": "2375",
        "district_id": "295",
        "subdistrict_name": "ท่าไห"
    },
    {
        "subdistrict_id": "2376",
        "district_id": "295",
        "subdistrict_name": "นาคำใหญ่"
    },
    {
        "subdistrict_id": "2377",
        "district_id": "295",
        "subdistrict_name": "แดงหม้อ"
    },
    {
        "subdistrict_id": "2378",
        "district_id": "295",
        "subdistrict_name": "ธาตุน้อย"
    },
    {
        "subdistrict_id": "2379",
        "district_id": "295",
        "subdistrict_name": "บ้านไทย"
    },
    {
        "subdistrict_id": "2380",
        "district_id": "295",
        "subdistrict_name": "บ้านกอก"
    },
    {
        "subdistrict_id": "2381",
        "district_id": "295",
        "subdistrict_name": "กลางใหญ่"
    },
    {
        "subdistrict_id": "2382",
        "district_id": "295",
        "subdistrict_name": "โนนรัง"
    },
    {
        "subdistrict_id": "2383",
        "district_id": "295",
        "subdistrict_name": "ยางขี้นก"
    },
    {
        "subdistrict_id": "2384",
        "district_id": "295",
        "subdistrict_name": "ศรีสุข"
    },
    {
        "subdistrict_id": "2385",
        "district_id": "295",
        "subdistrict_name": "สหธาตุ"
    },
    {
        "subdistrict_id": "2386",
        "district_id": "295",
        "subdistrict_name": "หนองเหล่า"
    },
    {
        "subdistrict_id": "2387",
        "district_id": "296",
        "subdistrict_name": "เขมราฐ"
    },
    {
        "subdistrict_id": "2388",
        "district_id": "296",
        "subdistrict_name": "ขามป้อม"
    },
    {
        "subdistrict_id": "2389",
        "district_id": "296",
        "subdistrict_name": "เจียด"
    },
    {
        "subdistrict_id": "2390",
        "district_id": "296",
        "subdistrict_name": "หนองผือ"
    },
    {
        "subdistrict_id": "2391",
        "district_id": "296",
        "subdistrict_name": "นาแวง"
    },
    {
        "subdistrict_id": "2392",
        "district_id": "296",
        "subdistrict_name": "แก้งเหนือ"
    },
    {
        "subdistrict_id": "2393",
        "district_id": "296",
        "subdistrict_name": "หนองนกทา"
    },
    {
        "subdistrict_id": "2394",
        "district_id": "296",
        "subdistrict_name": "หนองสิม"
    },
    {
        "subdistrict_id": "2395",
        "district_id": "296",
        "subdistrict_name": "หัวนา"
    },
    {
        "subdistrict_id": "2396",
        "district_id": "297",
        "subdistrict_name": "เมืองเดช"
    },
    {
        "subdistrict_id": "2397",
        "district_id": "297",
        "subdistrict_name": "นาส่วง"
    },
    {
        "subdistrict_id": "2398",
        "district_id": "297",
        "subdistrict_name": "นาเจริญ"
    },
    {
        "subdistrict_id": "2399",
        "district_id": "297",
        "subdistrict_name": "ทุ่งเทิง"
    },
    {
        "subdistrict_id": "2400",
        "district_id": "297",
        "subdistrict_name": "สมสะอาด"
    },
    {
        "subdistrict_id": "2401",
        "district_id": "297",
        "subdistrict_name": "กุดประทาย"
    },
    {
        "subdistrict_id": "2402",
        "district_id": "297",
        "subdistrict_name": "ตบหู"
    },
    {
        "subdistrict_id": "2403",
        "district_id": "297",
        "subdistrict_name": "กลาง"
    },
    {
        "subdistrict_id": "2404",
        "district_id": "297",
        "subdistrict_name": "แก้ง"
    },
    {
        "subdistrict_id": "2405",
        "district_id": "297",
        "subdistrict_name": "ท่าโพธิ์ศรี"
    },
    {
        "subdistrict_id": "2406",
        "district_id": "297",
        "subdistrict_name": "บัวงาม"
    },
    {
        "subdistrict_id": "2407",
        "district_id": "297",
        "subdistrict_name": "คำครั่ง"
    },
    {
        "subdistrict_id": "2408",
        "district_id": "297",
        "subdistrict_name": "นากระแซง"
    },
    {
        "subdistrict_id": "2409",
        "district_id": "297",
        "subdistrict_name": "โพนงาม"
    },
    {
        "subdistrict_id": "2410",
        "district_id": "297",
        "subdistrict_name": "ป่าโมง"
    },
    {
        "subdistrict_id": "2411",
        "district_id": "297",
        "subdistrict_name": "โนนสมบูรณ์"
    },
    {
        "subdistrict_id": "2412",
        "district_id": "298",
        "subdistrict_name": "นาจะหลวย"
    },
    {
        "subdistrict_id": "2413",
        "district_id": "298",
        "subdistrict_name": "โนนสมบูรณ์"
    },
    {
        "subdistrict_id": "2414",
        "district_id": "298",
        "subdistrict_name": "พรสวรรค์"
    },
    {
        "subdistrict_id": "2415",
        "district_id": "298",
        "subdistrict_name": "บ้านตูม"
    },
    {
        "subdistrict_id": "2416",
        "district_id": "298",
        "subdistrict_name": "โสกแสง"
    },
    {
        "subdistrict_id": "2417",
        "district_id": "298",
        "subdistrict_name": "โนนสวรรค์"
    },
    {
        "subdistrict_id": "2418",
        "district_id": "299",
        "subdistrict_name": "โซง"
    },
    {
        "subdistrict_id": "2419",
        "district_id": "299",
        "subdistrict_name": "ยาง"
    },
    {
        "subdistrict_id": "2420",
        "district_id": "299",
        "subdistrict_name": "โดมประดิษฐ์"
    },
    {
        "subdistrict_id": "2421",
        "district_id": "299",
        "subdistrict_name": "บุเปือย"
    },
    {
        "subdistrict_id": "2422",
        "district_id": "299",
        "subdistrict_name": "สีวิเชียร"
    },
    {
        "subdistrict_id": "2423",
        "district_id": "299",
        "subdistrict_name": "ยางใหญ่"
    },
    {
        "subdistrict_id": "2424",
        "district_id": "299",
        "subdistrict_name": "เก่าขาม"
    },
    {
        "subdistrict_id": "2425",
        "district_id": "300",
        "subdistrict_name": "โพนงาม"
    },
    {
        "subdistrict_id": "2426",
        "district_id": "300",
        "subdistrict_name": "ห้วยข่า"
    },
    {
        "subdistrict_id": "2427",
        "district_id": "300",
        "subdistrict_name": "คอแลน"
    },
    {
        "subdistrict_id": "2428",
        "district_id": "300",
        "subdistrict_name": "นาโพธิ์"
    },
    {
        "subdistrict_id": "2429",
        "district_id": "300",
        "subdistrict_name": "หนองสะโน"
    },
    {
        "subdistrict_id": "2430",
        "district_id": "300",
        "subdistrict_name": "โนนค้อ"
    },
    {
        "subdistrict_id": "2431",
        "district_id": "300",
        "subdistrict_name": "บัวงาม"
    },
    {
        "subdistrict_id": "2432",
        "district_id": "300",
        "subdistrict_name": "บ้านแมด"
    },
    {
        "subdistrict_id": "2433",
        "district_id": "301",
        "subdistrict_name": "ขุหลุ"
    },
    {
        "subdistrict_id": "2434",
        "district_id": "301",
        "subdistrict_name": "กระเดียน"
    },
    {
        "subdistrict_id": "2435",
        "district_id": "301",
        "subdistrict_name": "เกษม"
    },
    {
        "subdistrict_id": "2436",
        "district_id": "301",
        "subdistrict_name": "กุศกร"
    },
    {
        "subdistrict_id": "2437",
        "district_id": "301",
        "subdistrict_name": "ขามเปี้ย"
    },
    {
        "subdistrict_id": "2438",
        "district_id": "301",
        "subdistrict_name": "คอนสาย"
    },
    {
        "subdistrict_id": "2439",
        "district_id": "301",
        "subdistrict_name": "โคกจาน"
    },
    {
        "subdistrict_id": "2440",
        "district_id": "301",
        "subdistrict_name": "นาพิน"
    },
    {
        "subdistrict_id": "2441",
        "district_id": "301",
        "subdistrict_name": "นาสะไม"
    },
    {
        "subdistrict_id": "2442",
        "district_id": "301",
        "subdistrict_name": "โนนกุง"
    },
    {
        "subdistrict_id": "2443",
        "district_id": "301",
        "subdistrict_name": "ตระการ"
    },
    {
        "subdistrict_id": "2444",
        "district_id": "301",
        "subdistrict_name": "ตากแดด"
    },
    {
        "subdistrict_id": "2445",
        "district_id": "301",
        "subdistrict_name": "ไหล่ทุ่ง"
    },
    {
        "subdistrict_id": "2446",
        "district_id": "301",
        "subdistrict_name": "เป้า"
    },
    {
        "subdistrict_id": "2447",
        "district_id": "301",
        "subdistrict_name": "เซเป็ด"
    },
    {
        "subdistrict_id": "2448",
        "district_id": "301",
        "subdistrict_name": "สะพือ"
    },
    {
        "subdistrict_id": "2449",
        "district_id": "301",
        "subdistrict_name": "หนองเต่า"
    },
    {
        "subdistrict_id": "2450",
        "district_id": "301",
        "subdistrict_name": "ถ้ำแข้"
    },
    {
        "subdistrict_id": "2451",
        "district_id": "301",
        "subdistrict_name": "ท่าหลวง"
    },
    {
        "subdistrict_id": "2452",
        "district_id": "301",
        "subdistrict_name": "ห้วยฝ้ายพัฒนา"
    },
    {
        "subdistrict_id": "2453",
        "district_id": "301",
        "subdistrict_name": "กุดยาลวน"
    },
    {
        "subdistrict_id": "2454",
        "district_id": "301",
        "subdistrict_name": "บ้านแดง"
    },
    {
        "subdistrict_id": "2455",
        "district_id": "301",
        "subdistrict_name": "คำเจริญ"
    },
    {
        "subdistrict_id": "2456",
        "district_id": "302",
        "subdistrict_name": "ข้าวปุ้น"
    },
    {
        "subdistrict_id": "2457",
        "district_id": "302",
        "subdistrict_name": "โนนสวาง"
    },
    {
        "subdistrict_id": "2458",
        "district_id": "302",
        "subdistrict_name": "แก่งเค็ง"
    },
    {
        "subdistrict_id": "2459",
        "district_id": "302",
        "subdistrict_name": "กาบิน"
    },
    {
        "subdistrict_id": "2460",
        "district_id": "302",
        "subdistrict_name": "หนองทันน้ำ"
    },
    {
        "subdistrict_id": "2461",
        "district_id": "303",
        "subdistrict_name": "ม่วงสามสิบ"
    },
    {
        "subdistrict_id": "2462",
        "district_id": "303",
        "subdistrict_name": "เหล่าบก"
    },
    {
        "subdistrict_id": "2463",
        "district_id": "303",
        "subdistrict_name": "ดุมใหญ่"
    },
    {
        "subdistrict_id": "2464",
        "district_id": "303",
        "subdistrict_name": "หนองช้างใหญ่"
    },
    {
        "subdistrict_id": "2465",
        "district_id": "303",
        "subdistrict_name": "หนองเมือง"
    },
    {
        "subdistrict_id": "2466",
        "district_id": "303",
        "subdistrict_name": "เตย"
    },
    {
        "subdistrict_id": "2467",
        "district_id": "303",
        "subdistrict_name": "ยางสักกระโพหลุ่ม"
    },
    {
        "subdistrict_id": "2468",
        "district_id": "303",
        "subdistrict_name": "หนองไข่นก"
    },
    {
        "subdistrict_id": "2469",
        "district_id": "303",
        "subdistrict_name": "หนองเหล่า"
    },
    {
        "subdistrict_id": "2470",
        "district_id": "303",
        "subdistrict_name": "หนองฮาง"
    },
    {
        "subdistrict_id": "2471",
        "district_id": "303",
        "subdistrict_name": "ยางโยภาพ"
    },
    {
        "subdistrict_id": "2472",
        "district_id": "303",
        "subdistrict_name": "ไผ่ใหญ่"
    },
    {
        "subdistrict_id": "2473",
        "district_id": "303",
        "subdistrict_name": "นาเลิง"
    },
    {
        "subdistrict_id": "2474",
        "district_id": "303",
        "subdistrict_name": "โพนแพง"
    },
    {
        "subdistrict_id": "2475",
        "district_id": "304",
        "subdistrict_name": "วารินชำราบ"
    },
    {
        "subdistrict_id": "2476",
        "district_id": "304",
        "subdistrict_name": "ธาตุ"
    },
    {
        "subdistrict_id": "2477",
        "district_id": "304",
        "subdistrict_name": "ท่าลาด"
    },
    {
        "subdistrict_id": "2478",
        "district_id": "304",
        "subdistrict_name": "โนนโหนน"
    },
    {
        "subdistrict_id": "2479",
        "district_id": "304",
        "subdistrict_name": "คูเมือง"
    },
    {
        "subdistrict_id": "2480",
        "district_id": "304",
        "subdistrict_name": "สระสมิง"
    },
    {
        "subdistrict_id": "2481",
        "district_id": "304",
        "subdistrict_name": "คำน้ำแซบ"
    },
    {
        "subdistrict_id": "2482",
        "district_id": "304",
        "subdistrict_name": "บุ่งหวาย"
    },
    {
        "subdistrict_id": "2483",
        "district_id": "304",
        "subdistrict_name": "คำขวาง"
    },
    {
        "subdistrict_id": "2484",
        "district_id": "304",
        "subdistrict_name": "โพธิ์ใหญ่"
    },
    {
        "subdistrict_id": "2485",
        "district_id": "304",
        "subdistrict_name": "แสนสุข"
    },
    {
        "subdistrict_id": "2486",
        "district_id": "304",
        "subdistrict_name": "หนองกินเพล"
    },
    {
        "subdistrict_id": "2487",
        "district_id": "304",
        "subdistrict_name": "โนนผึ้ง"
    },
    {
        "subdistrict_id": "2488",
        "district_id": "304",
        "subdistrict_name": "เมืองศรีไค"
    },
    {
        "subdistrict_id": "2489",
        "district_id": "304",
        "subdistrict_name": "ห้วยขะยูง"
    },
    {
        "subdistrict_id": "2490",
        "district_id": "304",
        "subdistrict_name": "บุ่งไหม"
    },
    {
        "subdistrict_id": "2491",
        "district_id": "305",
        "subdistrict_name": "พิบูล"
    },
    {
        "subdistrict_id": "2492",
        "district_id": "305",
        "subdistrict_name": "กุดชมภู"
    },
    {
        "subdistrict_id": "2493",
        "district_id": "305",
        "subdistrict_name": "ดอนจิก"
    },
    {
        "subdistrict_id": "2494",
        "district_id": "305",
        "subdistrict_name": "ทรายมูล"
    },
    {
        "subdistrict_id": "2495",
        "district_id": "305",
        "subdistrict_name": "นาโพธิ์"
    },
    {
        "subdistrict_id": "2496",
        "district_id": "305",
        "subdistrict_name": "โนนกลาง"
    },
    {
        "subdistrict_id": "2497",
        "district_id": "305",
        "subdistrict_name": "โพธิ์ไทร"
    },
    {
        "subdistrict_id": "2498",
        "district_id": "305",
        "subdistrict_name": "โพธิ์ศรี"
    },
    {
        "subdistrict_id": "2499",
        "district_id": "305",
        "subdistrict_name": "ระเว"
    },
    {
        "subdistrict_id": "2500",
        "district_id": "305",
        "subdistrict_name": "ไร่ใต้"
    },
    {
        "subdistrict_id": "2501",
        "district_id": "305",
        "subdistrict_name": "หนองบัวฮี"
    },
    {
        "subdistrict_id": "2502",
        "district_id": "305",
        "subdistrict_name": "อ่างศิลา"
    },
    {
        "subdistrict_id": "2503",
        "district_id": "305",
        "subdistrict_name": "โนนกาหลง"
    },
    {
        "subdistrict_id": "2504",
        "district_id": "305",
        "subdistrict_name": "บ้านแขม"
    },
    {
        "subdistrict_id": "2505",
        "district_id": "306",
        "subdistrict_name": "ตาลสุม"
    },
    {
        "subdistrict_id": "2506",
        "district_id": "306",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "2507",
        "district_id": "306",
        "subdistrict_name": "จิกเทิง"
    },
    {
        "subdistrict_id": "2508",
        "district_id": "306",
        "subdistrict_name": "หนองกุง"
    },
    {
        "subdistrict_id": "2509",
        "district_id": "306",
        "subdistrict_name": "นาคาย"
    },
    {
        "subdistrict_id": "2510",
        "district_id": "306",
        "subdistrict_name": "คำหว้า"
    },
    {
        "subdistrict_id": "2511",
        "district_id": "307",
        "subdistrict_name": "โพธิ์ไทร"
    },
    {
        "subdistrict_id": "2512",
        "district_id": "307",
        "subdistrict_name": "ม่วงใหญ่"
    },
    {
        "subdistrict_id": "2513",
        "district_id": "307",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "2514",
        "district_id": "307",
        "subdistrict_name": "สองคอน"
    },
    {
        "subdistrict_id": "2515",
        "district_id": "307",
        "subdistrict_name": "สารภี"
    },
    {
        "subdistrict_id": "2516",
        "district_id": "307",
        "subdistrict_name": "เหล่างาม"
    },
    {
        "subdistrict_id": "2517",
        "district_id": "308",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "2518",
        "district_id": "308",
        "subdistrict_name": "โคกก่อง"
    },
    {
        "subdistrict_id": "2519",
        "district_id": "308",
        "subdistrict_name": "หนองไฮ"
    },
    {
        "subdistrict_id": "2520",
        "district_id": "308",
        "subdistrict_name": "ค้อน้อย"
    },
    {
        "subdistrict_id": "2521",
        "district_id": "308",
        "subdistrict_name": "โนนกาเล็น"
    },
    {
        "subdistrict_id": "2522",
        "district_id": "308",
        "subdistrict_name": "โคกสว่าง"
    },
    {
        "subdistrict_id": "2523",
        "district_id": "308",
        "subdistrict_name": "โนนกลาง"
    },
    {
        "subdistrict_id": "2524",
        "district_id": "308",
        "subdistrict_name": "บอน"
    },
    {
        "subdistrict_id": "2525",
        "district_id": "308",
        "subdistrict_name": "ขามป้อม"
    },
    {
        "subdistrict_id": "2526",
        "district_id": "309",
        "subdistrict_name": "ดอนมดแดง"
    },
    {
        "subdistrict_id": "2527",
        "district_id": "309",
        "subdistrict_name": "เหล่าแดง"
    },
    {
        "subdistrict_id": "2528",
        "district_id": "309",
        "subdistrict_name": "ท่าเมือง"
    },
    {
        "subdistrict_id": "2529",
        "district_id": "309",
        "subdistrict_name": "คำไฮใหญ่"
    },
    {
        "subdistrict_id": "2530",
        "district_id": "310",
        "subdistrict_name": "คันไร่"
    },
    {
        "subdistrict_id": "2531",
        "district_id": "310",
        "subdistrict_name": "ช่องเม็ก"
    },
    {
        "subdistrict_id": "2532",
        "district_id": "310",
        "subdistrict_name": "โนนก่อ"
    },
    {
        "subdistrict_id": "2533",
        "district_id": "310",
        "subdistrict_name": "นิคมสร้างตนเองลำโดมน้อย"
    },
    {
        "subdistrict_id": "2534",
        "district_id": "310",
        "subdistrict_name": "ฝางคำ"
    },
    {
        "subdistrict_id": "2535",
        "district_id": "310",
        "subdistrict_name": "คำเขื่อนแก้ว"
    },
    {
        "subdistrict_id": "2536",
        "district_id": "311",
        "subdistrict_name": "หนองอ้ม"
    },
    {
        "subdistrict_id": "2537",
        "district_id": "311",
        "subdistrict_name": "นาเกษม"
    },
    {
        "subdistrict_id": "2538",
        "district_id": "311",
        "subdistrict_name": "กุดเรือ"
    },
    {
        "subdistrict_id": "2539",
        "district_id": "311",
        "subdistrict_name": "โคกชำแระ"
    },
    {
        "subdistrict_id": "2540",
        "district_id": "311",
        "subdistrict_name": "นาห่อม"
    },
    {
        "subdistrict_id": "2541",
        "district_id": "312",
        "subdistrict_name": "นาเยีย"
    },
    {
        "subdistrict_id": "2542",
        "district_id": "312",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "2543",
        "district_id": "312",
        "subdistrict_name": "นาเรือง"
    },
    {
        "subdistrict_id": "2544",
        "district_id": "313",
        "subdistrict_name": "นาตาล"
    },
    {
        "subdistrict_id": "2545",
        "district_id": "313",
        "subdistrict_name": "พะลาน"
    },
    {
        "subdistrict_id": "2546",
        "district_id": "313",
        "subdistrict_name": "กองโพน"
    },
    {
        "subdistrict_id": "2547",
        "district_id": "313",
        "subdistrict_name": "พังเคน"
    },
    {
        "subdistrict_id": "2548",
        "district_id": "314",
        "subdistrict_name": "เหล่าเสือโก้ก"
    },
    {
        "subdistrict_id": "2549",
        "district_id": "314",
        "subdistrict_name": "โพนเมือง"
    },
    {
        "subdistrict_id": "2550",
        "district_id": "314",
        "subdistrict_name": "แพงใหญ่"
    },
    {
        "subdistrict_id": "2551",
        "district_id": "314",
        "subdistrict_name": "หนองบก"
    },
    {
        "subdistrict_id": "2552",
        "district_id": "315",
        "subdistrict_name": "แก่งโดม"
    },
    {
        "subdistrict_id": "2553",
        "district_id": "315",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "2554",
        "district_id": "315",
        "subdistrict_name": "บุ่งมะแลง"
    },
    {
        "subdistrict_id": "2555",
        "district_id": "315",
        "subdistrict_name": "สว่าง"
    },
    {
        "subdistrict_id": "2556",
        "district_id": "316",
        "subdistrict_name": "ตาเกา"
    },
    {
        "subdistrict_id": "2557",
        "district_id": "316",
        "subdistrict_name": "ไพบูลย์"
    },
    {
        "subdistrict_id": "2558",
        "district_id": "316",
        "subdistrict_name": "ขี้เหล็ก"
    },
    {
        "subdistrict_id": "2559",
        "district_id": "316",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "2560",
        "district_id": "317",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "2561",
        "district_id": "317",
        "subdistrict_name": "น้ำคำใหญ่"
    },
    {
        "subdistrict_id": "2562",
        "district_id": "317",
        "subdistrict_name": "ตาดทอง"
    },
    {
        "subdistrict_id": "2563",
        "district_id": "317",
        "subdistrict_name": "สำราญ"
    },
    {
        "subdistrict_id": "2564",
        "district_id": "317",
        "subdistrict_name": "ค้อเหนือ"
    },
    {
        "subdistrict_id": "2565",
        "district_id": "317",
        "subdistrict_name": "ดู่ทุ่ง"
    },
    {
        "subdistrict_id": "2566",
        "district_id": "317",
        "subdistrict_name": "เดิด"
    },
    {
        "subdistrict_id": "2567",
        "district_id": "317",
        "subdistrict_name": "ขั้นไดใหญ่"
    },
    {
        "subdistrict_id": "2568",
        "district_id": "317",
        "subdistrict_name": "ทุ่งแต้"
    },
    {
        "subdistrict_id": "2569",
        "district_id": "317",
        "subdistrict_name": "สิงห์"
    },
    {
        "subdistrict_id": "2570",
        "district_id": "317",
        "subdistrict_name": "นาสะไมย์"
    },
    {
        "subdistrict_id": "2571",
        "district_id": "317",
        "subdistrict_name": "เขื่องคำ"
    },
    {
        "subdistrict_id": "2572",
        "district_id": "317",
        "subdistrict_name": "หนองหิน"
    },
    {
        "subdistrict_id": "2573",
        "district_id": "317",
        "subdistrict_name": "หนองคู"
    },
    {
        "subdistrict_id": "2574",
        "district_id": "317",
        "subdistrict_name": "ขุมเงิน"
    },
    {
        "subdistrict_id": "2575",
        "district_id": "317",
        "subdistrict_name": "ทุ่งนางโอก"
    },
    {
        "subdistrict_id": "2576",
        "district_id": "317",
        "subdistrict_name": "หนองเรือ"
    },
    {
        "subdistrict_id": "2577",
        "district_id": "317",
        "subdistrict_name": "หนองเป็ด"
    },
    {
        "subdistrict_id": "2578",
        "district_id": "318",
        "subdistrict_name": "ทรายมูล"
    },
    {
        "subdistrict_id": "2579",
        "district_id": "318",
        "subdistrict_name": "ดู่ลาด"
    },
    {
        "subdistrict_id": "2580",
        "district_id": "318",
        "subdistrict_name": "ดงมะไฟ"
    },
    {
        "subdistrict_id": "2581",
        "district_id": "318",
        "subdistrict_name": "นาเวียง"
    },
    {
        "subdistrict_id": "2582",
        "district_id": "318",
        "subdistrict_name": "ไผ่"
    },
    {
        "subdistrict_id": "2583",
        "district_id": "319",
        "subdistrict_name": "กุดชุม"
    },
    {
        "subdistrict_id": "2584",
        "district_id": "319",
        "subdistrict_name": "โนนเปือย"
    },
    {
        "subdistrict_id": "2585",
        "district_id": "319",
        "subdistrict_name": "กำแมด"
    },
    {
        "subdistrict_id": "2586",
        "district_id": "319",
        "subdistrict_name": "นาโส่"
    },
    {
        "subdistrict_id": "2587",
        "district_id": "319",
        "subdistrict_name": "ห้วยแก้ง"
    },
    {
        "subdistrict_id": "2588",
        "district_id": "319",
        "subdistrict_name": "หนองหมี"
    },
    {
        "subdistrict_id": "2589",
        "district_id": "319",
        "subdistrict_name": "โพนงาม"
    },
    {
        "subdistrict_id": "2590",
        "district_id": "319",
        "subdistrict_name": "คำน้ำสร้าง"
    },
    {
        "subdistrict_id": "2591",
        "district_id": "319",
        "subdistrict_name": "หนองแหน"
    },
    {
        "subdistrict_id": "2592",
        "district_id": "320",
        "subdistrict_name": "ลุมพุก"
    },
    {
        "subdistrict_id": "2593",
        "district_id": "320",
        "subdistrict_name": "ย่อ"
    },
    {
        "subdistrict_id": "2594",
        "district_id": "320",
        "subdistrict_name": "สงเปือย"
    },
    {
        "subdistrict_id": "2595",
        "district_id": "320",
        "subdistrict_name": "โพนทัน"
    },
    {
        "subdistrict_id": "2596",
        "district_id": "320",
        "subdistrict_name": "ทุ่งมน"
    },
    {
        "subdistrict_id": "2597",
        "district_id": "320",
        "subdistrict_name": "นาคำ"
    },
    {
        "subdistrict_id": "2598",
        "district_id": "320",
        "subdistrict_name": "ดงแคนใหญ่"
    },
    {
        "subdistrict_id": "2599",
        "district_id": "320",
        "subdistrict_name": "กู่จาน"
    },
    {
        "subdistrict_id": "2600",
        "district_id": "320",
        "subdistrict_name": "นาแก"
    },
    {
        "subdistrict_id": "2601",
        "district_id": "320",
        "subdistrict_name": "กุดกุง"
    },
    {
        "subdistrict_id": "2602",
        "district_id": "320",
        "subdistrict_name": "เหล่าไฮ"
    },
    {
        "subdistrict_id": "2603",
        "district_id": "320",
        "subdistrict_name": "แคนน้อย"
    },
    {
        "subdistrict_id": "2604",
        "district_id": "320",
        "subdistrict_name": "ดงเจริญ"
    },
    {
        "subdistrict_id": "2605",
        "district_id": "321",
        "subdistrict_name": "โพธิ์ไทร"
    },
    {
        "subdistrict_id": "2606",
        "district_id": "321",
        "subdistrict_name": "กระจาย"
    },
    {
        "subdistrict_id": "2607",
        "district_id": "321",
        "subdistrict_name": "โคกนาโก"
    },
    {
        "subdistrict_id": "2608",
        "district_id": "321",
        "subdistrict_name": "เชียงเพ็ง"
    },
    {
        "subdistrict_id": "2609",
        "district_id": "321",
        "subdistrict_name": "ศรีฐาน"
    },
    {
        "subdistrict_id": "2610",
        "district_id": "322",
        "subdistrict_name": "ฟ้าหยาด"
    },
    {
        "subdistrict_id": "2611",
        "district_id": "322",
        "subdistrict_name": "หัวเมือง"
    },
    {
        "subdistrict_id": "2612",
        "district_id": "322",
        "subdistrict_name": "คูเมือง"
    },
    {
        "subdistrict_id": "2613",
        "district_id": "322",
        "subdistrict_name": "ผือฮี"
    },
    {
        "subdistrict_id": "2614",
        "district_id": "322",
        "subdistrict_name": "บากเรือ"
    },
    {
        "subdistrict_id": "2615",
        "district_id": "322",
        "subdistrict_name": "ม่วง"
    },
    {
        "subdistrict_id": "2616",
        "district_id": "322",
        "subdistrict_name": "โนนทราย"
    },
    {
        "subdistrict_id": "2617",
        "district_id": "322",
        "subdistrict_name": "บึงแก"
    },
    {
        "subdistrict_id": "2618",
        "district_id": "322",
        "subdistrict_name": "พระเสาร์"
    },
    {
        "subdistrict_id": "2619",
        "district_id": "322",
        "subdistrict_name": "สงยาง"
    },
    {
        "subdistrict_id": "2620",
        "district_id": "323",
        "subdistrict_name": "ฟ้าห่วน"
    },
    {
        "subdistrict_id": "2621",
        "district_id": "323",
        "subdistrict_name": "กุดน้ำใส"
    },
    {
        "subdistrict_id": "2622",
        "district_id": "323",
        "subdistrict_name": "น้ำอ้อม"
    },
    {
        "subdistrict_id": "2623",
        "district_id": "323",
        "subdistrict_name": "ค้อวัง"
    },
    {
        "subdistrict_id": "2624",
        "district_id": "324",
        "subdistrict_name": "บุ่งค้า"
    },
    {
        "subdistrict_id": "2625",
        "district_id": "324",
        "subdistrict_name": "สวาท"
    },
    {
        "subdistrict_id": "2626",
        "district_id": "324",
        "subdistrict_name": "ห้องแซง"
    },
    {
        "subdistrict_id": "2627",
        "district_id": "324",
        "subdistrict_name": "สามัคคี"
    },
    {
        "subdistrict_id": "2628",
        "district_id": "324",
        "subdistrict_name": "กุดเชียงหมี"
    },
    {
        "subdistrict_id": "2629",
        "district_id": "324",
        "subdistrict_name": "สามแยก"
    },
    {
        "subdistrict_id": "2630",
        "district_id": "324",
        "subdistrict_name": "กุดแห่"
    },
    {
        "subdistrict_id": "2631",
        "district_id": "324",
        "subdistrict_name": "โคกสำราญ"
    },
    {
        "subdistrict_id": "2632",
        "district_id": "324",
        "subdistrict_name": "สร้างมิ่ง"
    },
    {
        "subdistrict_id": "2633",
        "district_id": "324",
        "subdistrict_name": "ศรีแก้ว"
    },
    {
        "subdistrict_id": "2634",
        "district_id": "325",
        "subdistrict_name": "ไทยเจริญ"
    },
    {
        "subdistrict_id": "2635",
        "district_id": "325",
        "subdistrict_name": "น้ำคำ"
    },
    {
        "subdistrict_id": "2636",
        "district_id": "325",
        "subdistrict_name": "ส้มผ่อ"
    },
    {
        "subdistrict_id": "2637",
        "district_id": "325",
        "subdistrict_name": "คำเตย"
    },
    {
        "subdistrict_id": "2638",
        "district_id": "325",
        "subdistrict_name": "คำไผ่"
    },
    {
        "subdistrict_id": "2639",
        "district_id": "326",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "2640",
        "district_id": "326",
        "subdistrict_name": "รอบเมือง"
    },
    {
        "subdistrict_id": "2641",
        "district_id": "326",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "2642",
        "district_id": "326",
        "subdistrict_name": "นาฝาย"
    },
    {
        "subdistrict_id": "2643",
        "district_id": "326",
        "subdistrict_name": "บ้านค่าย"
    },
    {
        "subdistrict_id": "2644",
        "district_id": "326",
        "subdistrict_name": "กุดตุ้ม"
    },
    {
        "subdistrict_id": "2645",
        "district_id": "326",
        "subdistrict_name": "ชีลอง"
    },
    {
        "subdistrict_id": "2646",
        "district_id": "326",
        "subdistrict_name": "บ้านเล่า"
    },
    {
        "subdistrict_id": "2647",
        "district_id": "326",
        "subdistrict_name": "นาเสียว"
    },
    {
        "subdistrict_id": "2648",
        "district_id": "326",
        "subdistrict_name": "หนองนาแซง"
    },
    {
        "subdistrict_id": "2649",
        "district_id": "326",
        "subdistrict_name": "ลาดใหญ่"
    },
    {
        "subdistrict_id": "2650",
        "district_id": "326",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "2651",
        "district_id": "326",
        "subdistrict_name": "ท่าหินโงม"
    },
    {
        "subdistrict_id": "2652",
        "district_id": "326",
        "subdistrict_name": "ห้วยต้อน"
    },
    {
        "subdistrict_id": "2653",
        "district_id": "326",
        "subdistrict_name": "ห้วยบง"
    },
    {
        "subdistrict_id": "2654",
        "district_id": "326",
        "subdistrict_name": "โนนสำราญ"
    },
    {
        "subdistrict_id": "2655",
        "district_id": "326",
        "subdistrict_name": "โคกสูง"
    },
    {
        "subdistrict_id": "2656",
        "district_id": "326",
        "subdistrict_name": "บุ่งคล้า"
    },
    {
        "subdistrict_id": "2657",
        "district_id": "326",
        "subdistrict_name": "ซับสีทอง"
    },
    {
        "subdistrict_id": "2658",
        "district_id": "327",
        "subdistrict_name": "บ้านเขว้า"
    },
    {
        "subdistrict_id": "2659",
        "district_id": "327",
        "subdistrict_name": "ตลาดแร้ง"
    },
    {
        "subdistrict_id": "2660",
        "district_id": "327",
        "subdistrict_name": "ลุ่มลำชี"
    },
    {
        "subdistrict_id": "2661",
        "district_id": "327",
        "subdistrict_name": "ชีบน"
    },
    {
        "subdistrict_id": "2662",
        "district_id": "327",
        "subdistrict_name": "ภูแลนคา"
    },
    {
        "subdistrict_id": "2663",
        "district_id": "327",
        "subdistrict_name": "โนนแดง"
    },
    {
        "subdistrict_id": "2664",
        "district_id": "328",
        "subdistrict_name": "คอนสวรรค์"
    },
    {
        "subdistrict_id": "2665",
        "district_id": "328",
        "subdistrict_name": "ยางหวาย"
    },
    {
        "subdistrict_id": "2666",
        "district_id": "328",
        "subdistrict_name": "ช่องสามหมอ"
    },
    {
        "subdistrict_id": "2667",
        "district_id": "328",
        "subdistrict_name": "โนนสะอาด"
    },
    {
        "subdistrict_id": "2668",
        "district_id": "328",
        "subdistrict_name": "ห้วยไร่"
    },
    {
        "subdistrict_id": "2669",
        "district_id": "328",
        "subdistrict_name": "บ้านโสก"
    },
    {
        "subdistrict_id": "2670",
        "district_id": "328",
        "subdistrict_name": "โคกมั่งงอย"
    },
    {
        "subdistrict_id": "2671",
        "district_id": "328",
        "subdistrict_name": "หนองขาม"
    },
    {
        "subdistrict_id": "2672",
        "district_id": "328",
        "subdistrict_name": "ศรีสำราญ"
    },
    {
        "subdistrict_id": "2673",
        "district_id": "329",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "2674",
        "district_id": "329",
        "subdistrict_name": "บ้านหัน"
    },
    {
        "subdistrict_id": "2675",
        "district_id": "329",
        "subdistrict_name": "บ้านเดื่อ"
    },
    {
        "subdistrict_id": "2676",
        "district_id": "329",
        "subdistrict_name": "บ้านเป้า"
    },
    {
        "subdistrict_id": "2677",
        "district_id": "329",
        "subdistrict_name": "กุดเลาะ"
    },
    {
        "subdistrict_id": "2678",
        "district_id": "329",
        "subdistrict_name": "โนนกอก"
    },
    {
        "subdistrict_id": "2679",
        "district_id": "329",
        "subdistrict_name": "สระโพนทอง"
    },
    {
        "subdistrict_id": "2680",
        "district_id": "329",
        "subdistrict_name": "หนองข่า"
    },
    {
        "subdistrict_id": "2681",
        "district_id": "329",
        "subdistrict_name": "หนองโพนงาม"
    },
    {
        "subdistrict_id": "2682",
        "district_id": "329",
        "subdistrict_name": "บ้านบัว"
    },
    {
        "subdistrict_id": "2683",
        "district_id": "329",
        "subdistrict_name": "โนนทอง"
    },
    {
        "subdistrict_id": "2684",
        "district_id": "330",
        "subdistrict_name": "หนองบัวแดง"
    },
    {
        "subdistrict_id": "2685",
        "district_id": "330",
        "subdistrict_name": "กุดชุมแสง"
    },
    {
        "subdistrict_id": "2686",
        "district_id": "330",
        "subdistrict_name": "ถ้ำวัวแดง"
    },
    {
        "subdistrict_id": "2687",
        "district_id": "330",
        "subdistrict_name": "นางแดด"
    },
    {
        "subdistrict_id": "2688",
        "district_id": "330",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "2689",
        "district_id": "330",
        "subdistrict_name": "คูเมือง"
    },
    {
        "subdistrict_id": "2690",
        "district_id": "330",
        "subdistrict_name": "ท่าใหญ่"
    },
    {
        "subdistrict_id": "2691",
        "district_id": "330",
        "subdistrict_name": "วังชมภู"
    },
    {
        "subdistrict_id": "2692",
        "district_id": "331",
        "subdistrict_name": "บ้านกอก"
    },
    {
        "subdistrict_id": "2693",
        "district_id": "331",
        "subdistrict_name": "หนองบัวบาน"
    },
    {
        "subdistrict_id": "2694",
        "district_id": "331",
        "subdistrict_name": "บ้านขาม"
    },
    {
        "subdistrict_id": "2695",
        "district_id": "331",
        "subdistrict_name": "กุดน้ำใส"
    },
    {
        "subdistrict_id": "2696",
        "district_id": "331",
        "subdistrict_name": "หนองโดน"
    },
    {
        "subdistrict_id": "2697",
        "district_id": "331",
        "subdistrict_name": "ละหาน"
    },
    {
        "subdistrict_id": "2698",
        "district_id": "331",
        "subdistrict_name": "หนองบัวใหญ่"
    },
    {
        "subdistrict_id": "2699",
        "district_id": "331",
        "subdistrict_name": "หนองบัวโคก"
    },
    {
        "subdistrict_id": "2700",
        "district_id": "331",
        "subdistrict_name": "ส้มป่อย"
    },
    {
        "subdistrict_id": "2701",
        "district_id": "332",
        "subdistrict_name": "บ้านชวน"
    },
    {
        "subdistrict_id": "2702",
        "district_id": "332",
        "subdistrict_name": "บ้านเพชร"
    },
    {
        "subdistrict_id": "2703",
        "district_id": "332",
        "subdistrict_name": "บ้านตาล"
    },
    {
        "subdistrict_id": "2704",
        "district_id": "332",
        "subdistrict_name": "หัวทะเล"
    },
    {
        "subdistrict_id": "2705",
        "district_id": "332",
        "subdistrict_name": "โคกเริงรมย์"
    },
    {
        "subdistrict_id": "2706",
        "district_id": "332",
        "subdistrict_name": "เกาะมะนาว"
    },
    {
        "subdistrict_id": "2707",
        "district_id": "332",
        "subdistrict_name": "โคกเพชรพัฒนา"
    },
    {
        "subdistrict_id": "2708",
        "district_id": "333",
        "subdistrict_name": "หนองบัวระเหว"
    },
    {
        "subdistrict_id": "2709",
        "district_id": "333",
        "subdistrict_name": "วังตะเฆ่"
    },
    {
        "subdistrict_id": "2710",
        "district_id": "333",
        "subdistrict_name": "ห้วยแย้"
    },
    {
        "subdistrict_id": "2711",
        "district_id": "333",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "2712",
        "district_id": "333",
        "subdistrict_name": "โสกปลาดุก"
    },
    {
        "subdistrict_id": "2713",
        "district_id": "334",
        "subdistrict_name": "วะตะแบก"
    },
    {
        "subdistrict_id": "2714",
        "district_id": "334",
        "subdistrict_name": "ห้วยยายจิ๋ว"
    },
    {
        "subdistrict_id": "2715",
        "district_id": "334",
        "subdistrict_name": "นายางกลัก"
    },
    {
        "subdistrict_id": "2716",
        "district_id": "334",
        "subdistrict_name": "บ้านไร่"
    },
    {
        "subdistrict_id": "2717",
        "district_id": "334",
        "subdistrict_name": "โป่งนก"
    },
    {
        "subdistrict_id": "2718",
        "district_id": "335",
        "subdistrict_name": "ผักปัง"
    },
    {
        "subdistrict_id": "2719",
        "district_id": "335",
        "subdistrict_name": "กวางโจน"
    },
    {
        "subdistrict_id": "2720",
        "district_id": "335",
        "subdistrict_name": "หนองคอนไทย"
    },
    {
        "subdistrict_id": "2721",
        "district_id": "335",
        "subdistrict_name": "บ้านแก้ง"
    },
    {
        "subdistrict_id": "2722",
        "district_id": "335",
        "subdistrict_name": "กุดยม"
    },
    {
        "subdistrict_id": "2723",
        "district_id": "335",
        "subdistrict_name": "บ้านเพชร"
    },
    {
        "subdistrict_id": "2724",
        "district_id": "335",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "2725",
        "district_id": "335",
        "subdistrict_name": "หนองตูม"
    },
    {
        "subdistrict_id": "2726",
        "district_id": "335",
        "subdistrict_name": "โอโล"
    },
    {
        "subdistrict_id": "2727",
        "district_id": "335",
        "subdistrict_name": "ธาตุทอง"
    },
    {
        "subdistrict_id": "2728",
        "district_id": "335",
        "subdistrict_name": "บ้านดอน"
    },
    {
        "subdistrict_id": "2729",
        "district_id": "336",
        "subdistrict_name": "บ้านแท่น"
    },
    {
        "subdistrict_id": "2730",
        "district_id": "336",
        "subdistrict_name": "สามสวน"
    },
    {
        "subdistrict_id": "2731",
        "district_id": "336",
        "subdistrict_name": "สระพัง"
    },
    {
        "subdistrict_id": "2732",
        "district_id": "336",
        "subdistrict_name": "บ้านเต่า"
    },
    {
        "subdistrict_id": "2733",
        "district_id": "336",
        "subdistrict_name": "หนองคู"
    },
    {
        "subdistrict_id": "2734",
        "district_id": "337",
        "subdistrict_name": "ช่องสามหมอ"
    },
    {
        "subdistrict_id": "2735",
        "district_id": "337",
        "subdistrict_name": "หนองขาม"
    },
    {
        "subdistrict_id": "2736",
        "district_id": "337",
        "subdistrict_name": "นาหนองทุ่ม"
    },
    {
        "subdistrict_id": "2737",
        "district_id": "337",
        "subdistrict_name": "บ้านแก้ง"
    },
    {
        "subdistrict_id": "2738",
        "district_id": "337",
        "subdistrict_name": "หนองสังข์"
    },
    {
        "subdistrict_id": "2739",
        "district_id": "337",
        "subdistrict_name": "หลุบคา"
    },
    {
        "subdistrict_id": "2740",
        "district_id": "337",
        "subdistrict_name": "โคกกุง"
    },
    {
        "subdistrict_id": "2741",
        "district_id": "337",
        "subdistrict_name": "เก่าย่าดี"
    },
    {
        "subdistrict_id": "2742",
        "district_id": "337",
        "subdistrict_name": "ท่ามะไฟหวาน"
    },
    {
        "subdistrict_id": "2743",
        "district_id": "337",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "2744",
        "district_id": "338",
        "subdistrict_name": "คอนสาร"
    },
    {
        "subdistrict_id": "2745",
        "district_id": "338",
        "subdistrict_name": "ทุ่งพระ"
    },
    {
        "subdistrict_id": "2746",
        "district_id": "338",
        "subdistrict_name": "โนนคูณ"
    },
    {
        "subdistrict_id": "2747",
        "district_id": "338",
        "subdistrict_name": "ห้วยยาง"
    },
    {
        "subdistrict_id": "2748",
        "district_id": "338",
        "subdistrict_name": "ทุ่งลุยลาย"
    },
    {
        "subdistrict_id": "2749",
        "district_id": "338",
        "subdistrict_name": "ดงบัง"
    },
    {
        "subdistrict_id": "2750",
        "district_id": "338",
        "subdistrict_name": "ทุ่งนาเลา"
    },
    {
        "subdistrict_id": "2751",
        "district_id": "338",
        "subdistrict_name": "ดงกลาง"
    },
    {
        "subdistrict_id": "2752",
        "district_id": "339",
        "subdistrict_name": "บ้านเจียง"
    },
    {
        "subdistrict_id": "2753",
        "district_id": "339",
        "subdistrict_name": "เจาทอง"
    },
    {
        "subdistrict_id": "2754",
        "district_id": "339",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "2755",
        "district_id": "339",
        "subdistrict_name": "แหลมทอง"
    },
    {
        "subdistrict_id": "2756",
        "district_id": "340",
        "subdistrict_name": "หนองฉิม"
    },
    {
        "subdistrict_id": "2757",
        "district_id": "340",
        "subdistrict_name": "ตาเนิน"
    },
    {
        "subdistrict_id": "2758",
        "district_id": "340",
        "subdistrict_name": "กะฮาด"
    },
    {
        "subdistrict_id": "2759",
        "district_id": "340",
        "subdistrict_name": "รังงาม"
    },
    {
        "subdistrict_id": "2760",
        "district_id": "341",
        "subdistrict_name": "ซับใหญ่"
    },
    {
        "subdistrict_id": "2761",
        "district_id": "341",
        "subdistrict_name": "ท่ากูบ"
    },
    {
        "subdistrict_id": "2762",
        "district_id": "341",
        "subdistrict_name": "ตะโกทอง"
    },
    {
        "subdistrict_id": "2763",
        "district_id": "342",
        "subdistrict_name": "บุ่ง"
    },
    {
        "subdistrict_id": "2764",
        "district_id": "342",
        "subdistrict_name": "ไก่คำ"
    },
    {
        "subdistrict_id": "2765",
        "district_id": "342",
        "subdistrict_name": "นาจิก"
    },
    {
        "subdistrict_id": "2766",
        "district_id": "342",
        "subdistrict_name": "ปลาค้าว"
    },
    {
        "subdistrict_id": "2767",
        "district_id": "342",
        "subdistrict_name": "เหล่าพรวน"
    },
    {
        "subdistrict_id": "2768",
        "district_id": "342",
        "subdistrict_name": "สร้างนกทา"
    },
    {
        "subdistrict_id": "2769",
        "district_id": "342",
        "subdistrict_name": "คึมใหญ่"
    },
    {
        "subdistrict_id": "2770",
        "district_id": "342",
        "subdistrict_name": "นาผือ"
    },
    {
        "subdistrict_id": "2771",
        "district_id": "342",
        "subdistrict_name": "น้ำปลีก"
    },
    {
        "subdistrict_id": "2772",
        "district_id": "342",
        "subdistrict_name": "นาวัง"
    },
    {
        "subdistrict_id": "2773",
        "district_id": "342",
        "subdistrict_name": "นาหมอม้า"
    },
    {
        "subdistrict_id": "2774",
        "district_id": "342",
        "subdistrict_name": "โนนโพธิ์"
    },
    {
        "subdistrict_id": "2775",
        "district_id": "342",
        "subdistrict_name": "โนนหนามแท่ง"
    },
    {
        "subdistrict_id": "2776",
        "district_id": "342",
        "subdistrict_name": "ห้วยไร่"
    },
    {
        "subdistrict_id": "2777",
        "district_id": "342",
        "subdistrict_name": "หนองมะแซว"
    },
    {
        "subdistrict_id": "2778",
        "district_id": "342",
        "subdistrict_name": "กุดปลาดุก"
    },
    {
        "subdistrict_id": "2779",
        "district_id": "342",
        "subdistrict_name": "ดอนเมย"
    },
    {
        "subdistrict_id": "2780",
        "district_id": "342",
        "subdistrict_name": "นายม"
    },
    {
        "subdistrict_id": "2781",
        "district_id": "342",
        "subdistrict_name": "นาแต้"
    },
    {
        "subdistrict_id": "2782",
        "district_id": "343",
        "subdistrict_name": "ชานุมาน"
    },
    {
        "subdistrict_id": "2783",
        "district_id": "343",
        "subdistrict_name": "โคกสาร"
    },
    {
        "subdistrict_id": "2784",
        "district_id": "343",
        "subdistrict_name": "คำเขื่อนแก้ว"
    },
    {
        "subdistrict_id": "2785",
        "district_id": "343",
        "subdistrict_name": "โคกก่ง"
    },
    {
        "subdistrict_id": "2786",
        "district_id": "343",
        "subdistrict_name": "ป่าก่อ"
    },
    {
        "subdistrict_id": "2787",
        "district_id": "344",
        "subdistrict_name": "หนองข่า"
    },
    {
        "subdistrict_id": "2788",
        "district_id": "344",
        "subdistrict_name": "คำโพน"
    },
    {
        "subdistrict_id": "2789",
        "district_id": "344",
        "subdistrict_name": "นาหว้า"
    },
    {
        "subdistrict_id": "2790",
        "district_id": "344",
        "subdistrict_name": "ลือ"
    },
    {
        "subdistrict_id": "2791",
        "district_id": "344",
        "subdistrict_name": "ห้วย"
    },
    {
        "subdistrict_id": "2792",
        "district_id": "344",
        "subdistrict_name": "โนนงาม"
    },
    {
        "subdistrict_id": "2793",
        "district_id": "344",
        "subdistrict_name": "นาป่าแซง"
    },
    {
        "subdistrict_id": "2794",
        "district_id": "345",
        "subdistrict_name": "พนา"
    },
    {
        "subdistrict_id": "2795",
        "district_id": "345",
        "subdistrict_name": "จานลาน"
    },
    {
        "subdistrict_id": "2796",
        "district_id": "345",
        "subdistrict_name": "ไม้กลอน"
    },
    {
        "subdistrict_id": "2797",
        "district_id": "345",
        "subdistrict_name": "พระเหลา"
    },
    {
        "subdistrict_id": "2798",
        "district_id": "346",
        "subdistrict_name": "เสนางคนิคม"
    },
    {
        "subdistrict_id": "2799",
        "district_id": "346",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "2800",
        "district_id": "346",
        "subdistrict_name": "ไร่สีสุก"
    },
    {
        "subdistrict_id": "2801",
        "district_id": "346",
        "subdistrict_name": "นาเวียง"
    },
    {
        "subdistrict_id": "2802",
        "district_id": "346",
        "subdistrict_name": "หนองไฮ"
    },
    {
        "subdistrict_id": "2803",
        "district_id": "346",
        "subdistrict_name": "หนองสามสี"
    },
    {
        "subdistrict_id": "2804",
        "district_id": "347",
        "subdistrict_name": "หัวตะพาน"
    },
    {
        "subdistrict_id": "2805",
        "district_id": "347",
        "subdistrict_name": "คำพระ"
    },
    {
        "subdistrict_id": "2806",
        "district_id": "347",
        "subdistrict_name": "เค็งใหญ่"
    },
    {
        "subdistrict_id": "2807",
        "district_id": "347",
        "subdistrict_name": "หนองแก้ว"
    },
    {
        "subdistrict_id": "2808",
        "district_id": "347",
        "subdistrict_name": "โพนเมืองน้อย"
    },
    {
        "subdistrict_id": "2809",
        "district_id": "347",
        "subdistrict_name": "สร้างถ่อน้อย"
    },
    {
        "subdistrict_id": "2810",
        "district_id": "347",
        "subdistrict_name": "จิกดู่"
    },
    {
        "subdistrict_id": "2811",
        "district_id": "347",
        "subdistrict_name": "รัตนวารี"
    },
    {
        "subdistrict_id": "2812",
        "district_id": "348",
        "subdistrict_name": "อำนาจ"
    },
    {
        "subdistrict_id": "2813",
        "district_id": "348",
        "subdistrict_name": "ดงมะยาง"
    },
    {
        "subdistrict_id": "2814",
        "district_id": "348",
        "subdistrict_name": "เปือย"
    },
    {
        "subdistrict_id": "2815",
        "district_id": "348",
        "subdistrict_name": "ดงบัง"
    },
    {
        "subdistrict_id": "2816",
        "district_id": "348",
        "subdistrict_name": "ไร่ขี"
    },
    {
        "subdistrict_id": "2817",
        "district_id": "348",
        "subdistrict_name": "แมด"
    },
    {
        "subdistrict_id": "2818",
        "district_id": "348",
        "subdistrict_name": "โคกกลาง"
    },
    {
        "subdistrict_id": "2819",
        "district_id": "349",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "2820",
        "district_id": "349",
        "subdistrict_name": "หนองภัยศูนย์"
    },
    {
        "subdistrict_id": "2821",
        "district_id": "349",
        "subdistrict_name": "โพธิ์ชัย"
    },
    {
        "subdistrict_id": "2822",
        "district_id": "349",
        "subdistrict_name": "หนองสวรรค์"
    },
    {
        "subdistrict_id": "2823",
        "district_id": "349",
        "subdistrict_name": "หัวนา"
    },
    {
        "subdistrict_id": "2824",
        "district_id": "349",
        "subdistrict_name": "บ้านขาม"
    },
    {
        "subdistrict_id": "2825",
        "district_id": "349",
        "subdistrict_name": "นามะเฟือง"
    },
    {
        "subdistrict_id": "2826",
        "district_id": "349",
        "subdistrict_name": "บ้านพร้าว"
    },
    {
        "subdistrict_id": "2827",
        "district_id": "349",
        "subdistrict_name": "โนนขมิ้น"
    },
    {
        "subdistrict_id": "2828",
        "district_id": "349",
        "subdistrict_name": "ลำภู"
    },
    {
        "subdistrict_id": "2829",
        "district_id": "349",
        "subdistrict_name": "กุดจิก"
    },
    {
        "subdistrict_id": "2830",
        "district_id": "349",
        "subdistrict_name": "โนนทัน"
    },
    {
        "subdistrict_id": "2831",
        "district_id": "349",
        "subdistrict_name": "นาคำไฮ"
    },
    {
        "subdistrict_id": "2832",
        "district_id": "349",
        "subdistrict_name": "ป่าไม้งาม"
    },
    {
        "subdistrict_id": "2833",
        "district_id": "349",
        "subdistrict_name": "หนองหว้า"
    },
    {
        "subdistrict_id": "2834",
        "district_id": "350",
        "subdistrict_name": "นากลาง"
    },
    {
        "subdistrict_id": "2835",
        "district_id": "350",
        "subdistrict_name": "ด่านช้าง"
    },
    {
        "subdistrict_id": "2836",
        "district_id": "350",
        "subdistrict_name": "กุดดินจี่"
    },
    {
        "subdistrict_id": "2837",
        "district_id": "350",
        "subdistrict_name": "ฝั่งแดง"
    },
    {
        "subdistrict_id": "2838",
        "district_id": "350",
        "subdistrict_name": "เก่ากลอย"
    },
    {
        "subdistrict_id": "2839",
        "district_id": "350",
        "subdistrict_name": "โนนเมือง"
    },
    {
        "subdistrict_id": "2840",
        "district_id": "350",
        "subdistrict_name": "อุทัยสวรรค์"
    },
    {
        "subdistrict_id": "2841",
        "district_id": "350",
        "subdistrict_name": "ดงสวรรค์"
    },
    {
        "subdistrict_id": "2842",
        "district_id": "350",
        "subdistrict_name": "กุดแห่"
    },
    {
        "subdistrict_id": "2843",
        "district_id": "351",
        "subdistrict_name": "โนนสัง"
    },
    {
        "subdistrict_id": "2844",
        "district_id": "351",
        "subdistrict_name": "บ้านถิ่น"
    },
    {
        "subdistrict_id": "2845",
        "district_id": "351",
        "subdistrict_name": "หนองเรือ"
    },
    {
        "subdistrict_id": "2846",
        "district_id": "351",
        "subdistrict_name": "กุดดู่"
    },
    {
        "subdistrict_id": "2847",
        "district_id": "351",
        "subdistrict_name": "บ้านค้อ"
    },
    {
        "subdistrict_id": "2848",
        "district_id": "351",
        "subdistrict_name": "โนนเมือง"
    },
    {
        "subdistrict_id": "2849",
        "district_id": "351",
        "subdistrict_name": "โคกใหญ่"
    },
    {
        "subdistrict_id": "2850",
        "district_id": "351",
        "subdistrict_name": "โคกม่วง"
    },
    {
        "subdistrict_id": "2851",
        "district_id": "351",
        "subdistrict_name": "นิคมพัฒนา"
    },
    {
        "subdistrict_id": "2852",
        "district_id": "351",
        "subdistrict_name": "ปางกู่"
    },
    {
        "subdistrict_id": "2853",
        "district_id": "352",
        "subdistrict_name": "เมืองใหม่"
    },
    {
        "subdistrict_id": "2854",
        "district_id": "352",
        "subdistrict_name": "ศรีบุญเรือง"
    },
    {
        "subdistrict_id": "2855",
        "district_id": "352",
        "subdistrict_name": "หนองบัวใต้"
    },
    {
        "subdistrict_id": "2856",
        "district_id": "352",
        "subdistrict_name": "กุดสะเทียน"
    },
    {
        "subdistrict_id": "2857",
        "district_id": "352",
        "subdistrict_name": "นากอก"
    },
    {
        "subdistrict_id": "2858",
        "district_id": "352",
        "subdistrict_name": "โนนสะอาด"
    },
    {
        "subdistrict_id": "2859",
        "district_id": "352",
        "subdistrict_name": "ยางหล่อ"
    },
    {
        "subdistrict_id": "2860",
        "district_id": "352",
        "subdistrict_name": "โนนม่วง"
    },
    {
        "subdistrict_id": "2861",
        "district_id": "352",
        "subdistrict_name": "หนองกุงแก้ว"
    },
    {
        "subdistrict_id": "2862",
        "district_id": "352",
        "subdistrict_name": "หนองแก"
    },
    {
        "subdistrict_id": "2863",
        "district_id": "352",
        "subdistrict_name": "ทรายทอง"
    },
    {
        "subdistrict_id": "2864",
        "district_id": "352",
        "subdistrict_name": "หันนางาม"
    },
    {
        "subdistrict_id": "2865",
        "district_id": "353",
        "subdistrict_name": "นาสี"
    },
    {
        "subdistrict_id": "2866",
        "district_id": "353",
        "subdistrict_name": "บ้านโคก"
    },
    {
        "subdistrict_id": "2867",
        "district_id": "353",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "2868",
        "district_id": "353",
        "subdistrict_name": "นาด่าน"
    },
    {
        "subdistrict_id": "2869",
        "district_id": "353",
        "subdistrict_name": "ดงมะไฟ"
    },
    {
        "subdistrict_id": "2870",
        "district_id": "353",
        "subdistrict_name": "สุวรรณคูหา"
    },
    {
        "subdistrict_id": "2871",
        "district_id": "353",
        "subdistrict_name": "บุญทัน"
    },
    {
        "subdistrict_id": "2872",
        "district_id": "353",
        "subdistrict_name": "กุดผึ้ง"
    },
    {
        "subdistrict_id": "2873",
        "district_id": "354",
        "subdistrict_name": "นาเหล่า"
    },
    {
        "subdistrict_id": "2874",
        "district_id": "354",
        "subdistrict_name": "นาแก"
    },
    {
        "subdistrict_id": "2875",
        "district_id": "354",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "2876",
        "district_id": "354",
        "subdistrict_name": "วังปลาป้อม"
    },
    {
        "subdistrict_id": "2877",
        "district_id": "354",
        "subdistrict_name": "เทพคีรี"
    },
    {
        "subdistrict_id": "2878",
        "district_id": "355",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "2879",
        "district_id": "355",
        "subdistrict_name": "สำราญ"
    },
    {
        "subdistrict_id": "2880",
        "district_id": "355",
        "subdistrict_name": "โคกสี"
    },
    {
        "subdistrict_id": "2881",
        "district_id": "355",
        "subdistrict_name": "ท่าพระ"
    },
    {
        "subdistrict_id": "2882",
        "district_id": "355",
        "subdistrict_name": "บ้านทุ่ม"
    },
    {
        "subdistrict_id": "2883",
        "district_id": "355",
        "subdistrict_name": "เมืองเก่า"
    },
    {
        "subdistrict_id": "2884",
        "district_id": "355",
        "subdistrict_name": "พระลับ"
    },
    {
        "subdistrict_id": "2885",
        "district_id": "355",
        "subdistrict_name": "สาวะถี"
    },
    {
        "subdistrict_id": "2886",
        "district_id": "355",
        "subdistrict_name": "บ้านหว้า"
    },
    {
        "subdistrict_id": "2887",
        "district_id": "355",
        "subdistrict_name": "บ้านค้อ"
    },
    {
        "subdistrict_id": "2888",
        "district_id": "355",
        "subdistrict_name": "แดงใหญ่"
    },
    {
        "subdistrict_id": "2889",
        "district_id": "355",
        "subdistrict_name": "ดอนช้าง"
    },
    {
        "subdistrict_id": "2890",
        "district_id": "355",
        "subdistrict_name": "ดอนหัน"
    },
    {
        "subdistrict_id": "2891",
        "district_id": "355",
        "subdistrict_name": "ศิลา"
    },
    {
        "subdistrict_id": "2892",
        "district_id": "355",
        "subdistrict_name": "บ้านเป็ด"
    },
    {
        "subdistrict_id": "2893",
        "district_id": "355",
        "subdistrict_name": "หนองตูม"
    },
    {
        "subdistrict_id": "2894",
        "district_id": "355",
        "subdistrict_name": "บึงเนียม"
    },
    {
        "subdistrict_id": "2895",
        "district_id": "355",
        "subdistrict_name": "โนนท่อน"
    },
    {
        "subdistrict_id": "2896",
        "district_id": "356",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "2897",
        "district_id": "356",
        "subdistrict_name": "ป่าหวายนั่ง"
    },
    {
        "subdistrict_id": "2898",
        "district_id": "356",
        "subdistrict_name": "โนนฆ้อง"
    },
    {
        "subdistrict_id": "2899",
        "district_id": "356",
        "subdistrict_name": "บ้านเหล่า"
    },
    {
        "subdistrict_id": "2900",
        "district_id": "356",
        "subdistrict_name": "ป่ามะนาว"
    },
    {
        "subdistrict_id": "2901",
        "district_id": "356",
        "subdistrict_name": "บ้านฝาง"
    },
    {
        "subdistrict_id": "2902",
        "district_id": "356",
        "subdistrict_name": "โคกงาม"
    },
    {
        "subdistrict_id": "2903",
        "district_id": "357",
        "subdistrict_name": "พระยืน"
    },
    {
        "subdistrict_id": "2904",
        "district_id": "357",
        "subdistrict_name": "พระบุ"
    },
    {
        "subdistrict_id": "2905",
        "district_id": "357",
        "subdistrict_name": "บ้านโต้น"
    },
    {
        "subdistrict_id": "2906",
        "district_id": "357",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "2907",
        "district_id": "357",
        "subdistrict_name": "ขามป้อม"
    },
    {
        "subdistrict_id": "2908",
        "district_id": "358",
        "subdistrict_name": "หนองเรือ"
    },
    {
        "subdistrict_id": "2909",
        "district_id": "358",
        "subdistrict_name": "บ้านเม็ง"
    },
    {
        "subdistrict_id": "2910",
        "district_id": "358",
        "subdistrict_name": "บ้านกง"
    },
    {
        "subdistrict_id": "2911",
        "district_id": "358",
        "subdistrict_name": "ยางคำ"
    },
    {
        "subdistrict_id": "2912",
        "district_id": "358",
        "subdistrict_name": "จระเข้"
    },
    {
        "subdistrict_id": "2913",
        "district_id": "358",
        "subdistrict_name": "โนนทอง"
    },
    {
        "subdistrict_id": "2914",
        "district_id": "358",
        "subdistrict_name": "กุดกว้าง"
    },
    {
        "subdistrict_id": "2915",
        "district_id": "358",
        "subdistrict_name": "โนนทัน"
    },
    {
        "subdistrict_id": "2916",
        "district_id": "358",
        "subdistrict_name": "โนนสะอาด"
    },
    {
        "subdistrict_id": "2917",
        "district_id": "358",
        "subdistrict_name": "บ้านผือ"
    },
    {
        "subdistrict_id": "2918",
        "district_id": "359",
        "subdistrict_name": "ชุมแพ"
    },
    {
        "subdistrict_id": "2919",
        "district_id": "359",
        "subdistrict_name": "โนนหัน"
    },
    {
        "subdistrict_id": "2920",
        "district_id": "359",
        "subdistrict_name": "นาหนองทุ่ม"
    },
    {
        "subdistrict_id": "2921",
        "district_id": "359",
        "subdistrict_name": "โนนอุดม"
    },
    {
        "subdistrict_id": "2922",
        "district_id": "359",
        "subdistrict_name": "ขัวเรียง"
    },
    {
        "subdistrict_id": "2923",
        "district_id": "359",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "2924",
        "district_id": "359",
        "subdistrict_name": "ไชยสอ"
    },
    {
        "subdistrict_id": "2925",
        "district_id": "359",
        "subdistrict_name": "วังหินลาด"
    },
    {
        "subdistrict_id": "2926",
        "district_id": "359",
        "subdistrict_name": "นาเพียง"
    },
    {
        "subdistrict_id": "2927",
        "district_id": "359",
        "subdistrict_name": "หนองเขียด"
    },
    {
        "subdistrict_id": "2928",
        "district_id": "359",
        "subdistrict_name": "หนองเสาเล้า"
    },
    {
        "subdistrict_id": "2929",
        "district_id": "359",
        "subdistrict_name": "โนนสะอาด"
    },
    {
        "subdistrict_id": "2930",
        "district_id": "360",
        "subdistrict_name": "สีชมพู"
    },
    {
        "subdistrict_id": "2931",
        "district_id": "360",
        "subdistrict_name": "ศรีสุข"
    },
    {
        "subdistrict_id": "2932",
        "district_id": "360",
        "subdistrict_name": "นาจาน"
    },
    {
        "subdistrict_id": "2933",
        "district_id": "360",
        "subdistrict_name": "วังเพิ่ม"
    },
    {
        "subdistrict_id": "2934",
        "district_id": "360",
        "subdistrict_name": "ซำยาง"
    },
    {
        "subdistrict_id": "2935",
        "district_id": "360",
        "subdistrict_name": "หนองแดง"
    },
    {
        "subdistrict_id": "2936",
        "district_id": "360",
        "subdistrict_name": "ดงลาน"
    },
    {
        "subdistrict_id": "2937",
        "district_id": "360",
        "subdistrict_name": "บริบูรณ์"
    },
    {
        "subdistrict_id": "2938",
        "district_id": "360",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "2939",
        "district_id": "360",
        "subdistrict_name": "ภูห่าน"
    },
    {
        "subdistrict_id": "2940",
        "district_id": "361",
        "subdistrict_name": "น้ำพอง"
    },
    {
        "subdistrict_id": "2941",
        "district_id": "361",
        "subdistrict_name": "วังชัย"
    },
    {
        "subdistrict_id": "2942",
        "district_id": "361",
        "subdistrict_name": "หนองกุง"
    },
    {
        "subdistrict_id": "2943",
        "district_id": "361",
        "subdistrict_name": "บัวใหญ่"
    },
    {
        "subdistrict_id": "2944",
        "district_id": "361",
        "subdistrict_name": "สะอาด"
    },
    {
        "subdistrict_id": "2945",
        "district_id": "361",
        "subdistrict_name": "ม่วงหวาน"
    },
    {
        "subdistrict_id": "2946",
        "district_id": "361",
        "subdistrict_name": "บ้านขาม"
    },
    {
        "subdistrict_id": "2947",
        "district_id": "361",
        "subdistrict_name": "บัวเงิน"
    },
    {
        "subdistrict_id": "2948",
        "district_id": "361",
        "subdistrict_name": "ทรายมูล"
    },
    {
        "subdistrict_id": "2949",
        "district_id": "361",
        "subdistrict_name": "ท่ากระเสริม"
    },
    {
        "subdistrict_id": "2950",
        "district_id": "361",
        "subdistrict_name": "พังทุย"
    },
    {
        "subdistrict_id": "2951",
        "district_id": "361",
        "subdistrict_name": "กุดน้ำใส"
    },
    {
        "subdistrict_id": "2952",
        "district_id": "362",
        "subdistrict_name": "โคกสูง"
    },
    {
        "subdistrict_id": "2953",
        "district_id": "362",
        "subdistrict_name": "บ้านดง"
    },
    {
        "subdistrict_id": "2954",
        "district_id": "362",
        "subdistrict_name": "เขื่อนอุบลรัตน์"
    },
    {
        "subdistrict_id": "2955",
        "district_id": "362",
        "subdistrict_name": "นาคำ"
    },
    {
        "subdistrict_id": "2956",
        "district_id": "362",
        "subdistrict_name": "ศรีสุขสำราญ"
    },
    {
        "subdistrict_id": "2957",
        "district_id": "362",
        "subdistrict_name": "ทุ่งโป่ง"
    },
    {
        "subdistrict_id": "2958",
        "district_id": "363",
        "subdistrict_name": "หนองโก"
    },
    {
        "subdistrict_id": "2959",
        "district_id": "363",
        "subdistrict_name": "หนองกุงใหญ่"
    },
    {
        "subdistrict_id": "2960",
        "district_id": "363",
        "subdistrict_name": "ห้วยโจด"
    },
    {
        "subdistrict_id": "2961",
        "district_id": "363",
        "subdistrict_name": "ห้วยยาง"
    },
    {
        "subdistrict_id": "2962",
        "district_id": "363",
        "subdistrict_name": "บ้านฝาง"
    },
    {
        "subdistrict_id": "2963",
        "district_id": "363",
        "subdistrict_name": "ดูนสาด"
    },
    {
        "subdistrict_id": "2964",
        "district_id": "363",
        "subdistrict_name": "หนองโน"
    },
    {
        "subdistrict_id": "2965",
        "district_id": "363",
        "subdistrict_name": "น้ำอ้อม"
    },
    {
        "subdistrict_id": "2966",
        "district_id": "363",
        "subdistrict_name": "หัวนาคำ"
    },
    {
        "subdistrict_id": "2967",
        "district_id": "364",
        "subdistrict_name": "บ้านไผ่"
    },
    {
        "subdistrict_id": "2968",
        "district_id": "364",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "2969",
        "district_id": "364",
        "subdistrict_name": "เมืองเพีย"
    },
    {
        "subdistrict_id": "2970",
        "district_id": "364",
        "subdistrict_name": "บ้านลาน"
    },
    {
        "subdistrict_id": "2971",
        "district_id": "364",
        "subdistrict_name": "แคนเหนือ"
    },
    {
        "subdistrict_id": "2972",
        "district_id": "364",
        "subdistrict_name": "ภูเหล็ก"
    },
    {
        "subdistrict_id": "2973",
        "district_id": "364",
        "subdistrict_name": "ป่าปอ"
    },
    {
        "subdistrict_id": "2974",
        "district_id": "364",
        "subdistrict_name": "หินตั้ง"
    },
    {
        "subdistrict_id": "2975",
        "district_id": "364",
        "subdistrict_name": "หนองน้ำใส"
    },
    {
        "subdistrict_id": "2976",
        "district_id": "364",
        "subdistrict_name": "หัวหนอง"
    },
    {
        "subdistrict_id": "2977",
        "district_id": "365",
        "subdistrict_name": "เปือยน้อย"
    },
    {
        "subdistrict_id": "2978",
        "district_id": "365",
        "subdistrict_name": "วังม่วง"
    },
    {
        "subdistrict_id": "2979",
        "district_id": "365",
        "subdistrict_name": "ขามป้อม"
    },
    {
        "subdistrict_id": "2980",
        "district_id": "365",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "2981",
        "district_id": "366",
        "subdistrict_name": "เมืองพล"
    },
    {
        "subdistrict_id": "2982",
        "district_id": "366",
        "subdistrict_name": "โจดหนองแก"
    },
    {
        "subdistrict_id": "2983",
        "district_id": "366",
        "subdistrict_name": "เก่างิ้ว"
    },
    {
        "subdistrict_id": "2984",
        "district_id": "366",
        "subdistrict_name": "หนองมะเขือ"
    },
    {
        "subdistrict_id": "2985",
        "district_id": "366",
        "subdistrict_name": "หนองแวงโสกพระ"
    },
    {
        "subdistrict_id": "2986",
        "district_id": "366",
        "subdistrict_name": "เพ็กใหญ่"
    },
    {
        "subdistrict_id": "2987",
        "district_id": "366",
        "subdistrict_name": "โคกสง่า"
    },
    {
        "subdistrict_id": "2988",
        "district_id": "366",
        "subdistrict_name": "หนองแวงนางเบ้า"
    },
    {
        "subdistrict_id": "2989",
        "district_id": "366",
        "subdistrict_name": "ลอมคอม"
    },
    {
        "subdistrict_id": "2990",
        "district_id": "366",
        "subdistrict_name": "โนนข่า"
    },
    {
        "subdistrict_id": "2991",
        "district_id": "366",
        "subdistrict_name": "โสกนกเต็น"
    },
    {
        "subdistrict_id": "2992",
        "district_id": "366",
        "subdistrict_name": "หัวทุ่ง"
    },
    {
        "subdistrict_id": "2993",
        "district_id": "367",
        "subdistrict_name": "คอนฉิม"
    },
    {
        "subdistrict_id": "2994",
        "district_id": "367",
        "subdistrict_name": "ใหม่นาเพียง"
    },
    {
        "subdistrict_id": "2995",
        "district_id": "367",
        "subdistrict_name": "โนนทอง"
    },
    {
        "subdistrict_id": "2996",
        "district_id": "367",
        "subdistrict_name": "แวงใหญ่"
    },
    {
        "subdistrict_id": "2997",
        "district_id": "367",
        "subdistrict_name": "โนนสะอาด"
    },
    {
        "subdistrict_id": "2998",
        "district_id": "368",
        "subdistrict_name": "แวงน้อย"
    },
    {
        "subdistrict_id": "2999",
        "district_id": "368",
        "subdistrict_name": "ก้านเหลือง"
    },
    {
        "subdistrict_id": "3000",
        "district_id": "368",
        "subdistrict_name": "ท่านางแนว"
    },
    {
        "subdistrict_id": "3001",
        "district_id": "368",
        "subdistrict_name": "ละหานนา"
    },
    {
        "subdistrict_id": "3002",
        "district_id": "368",
        "subdistrict_name": "ท่าวัด"
    },
    {
        "subdistrict_id": "3003",
        "district_id": "368",
        "subdistrict_name": "ทางขวาง"
    },
    {
        "subdistrict_id": "3004",
        "district_id": "369",
        "subdistrict_name": "หนองสองห้อง"
    },
    {
        "subdistrict_id": "3005",
        "district_id": "369",
        "subdistrict_name": "คึมชาด"
    },
    {
        "subdistrict_id": "3006",
        "district_id": "369",
        "subdistrict_name": "โนนธาตุ"
    },
    {
        "subdistrict_id": "3007",
        "district_id": "369",
        "subdistrict_name": "ตะกั่วป่า"
    },
    {
        "subdistrict_id": "3008",
        "district_id": "369",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "3009",
        "district_id": "369",
        "subdistrict_name": "หนองเม็ก"
    },
    {
        "subdistrict_id": "3010",
        "district_id": "369",
        "subdistrict_name": "ดอนดู่"
    },
    {
        "subdistrict_id": "3011",
        "district_id": "369",
        "subdistrict_name": "ดงเค็ง"
    },
    {
        "subdistrict_id": "3012",
        "district_id": "369",
        "subdistrict_name": "หันโจด"
    },
    {
        "subdistrict_id": "3013",
        "district_id": "369",
        "subdistrict_name": "ดอนดั่ง"
    },
    {
        "subdistrict_id": "3014",
        "district_id": "369",
        "subdistrict_name": "วังหิน"
    },
    {
        "subdistrict_id": "3015",
        "district_id": "369",
        "subdistrict_name": "หนองไผ่ล้อม"
    },
    {
        "subdistrict_id": "3016",
        "district_id": "370",
        "subdistrict_name": "บ้านเรือ"
    },
    {
        "subdistrict_id": "3017",
        "district_id": "370",
        "subdistrict_name": "หว้าทอง"
    },
    {
        "subdistrict_id": "3018",
        "district_id": "370",
        "subdistrict_name": "กุดขอนแก่น"
    },
    {
        "subdistrict_id": "3019",
        "district_id": "370",
        "subdistrict_name": "นาชุมแสง"
    },
    {
        "subdistrict_id": "3020",
        "district_id": "370",
        "subdistrict_name": "นาหว้า"
    },
    {
        "subdistrict_id": "3021",
        "district_id": "370",
        "subdistrict_name": "หนองกุงธนสาร"
    },
    {
        "subdistrict_id": "3022",
        "district_id": "370",
        "subdistrict_name": "หนองกุงเซิน"
    },
    {
        "subdistrict_id": "3023",
        "district_id": "370",
        "subdistrict_name": "สงเปือย"
    },
    {
        "subdistrict_id": "3024",
        "district_id": "370",
        "subdistrict_name": "ทุ่งชมพู"
    },
    {
        "subdistrict_id": "3025",
        "district_id": "370",
        "subdistrict_name": "เมืองเก่าพัฒนา"
    },
    {
        "subdistrict_id": "3026",
        "district_id": "370",
        "subdistrict_name": "ดินดำ"
    },
    {
        "subdistrict_id": "3027",
        "district_id": "370",
        "subdistrict_name": "ภูเวียง"
    },
    {
        "subdistrict_id": "3028",
        "district_id": "371",
        "subdistrict_name": "กุดเค้า"
    },
    {
        "subdistrict_id": "3029",
        "district_id": "371",
        "subdistrict_name": "สวนหม่อน"
    },
    {
        "subdistrict_id": "3030",
        "district_id": "371",
        "subdistrict_name": "หนองแปน"
    },
    {
        "subdistrict_id": "3031",
        "district_id": "371",
        "subdistrict_name": "โพนเพ็ก"
    },
    {
        "subdistrict_id": "3032",
        "district_id": "371",
        "subdistrict_name": "คำแคน"
    },
    {
        "subdistrict_id": "3033",
        "district_id": "371",
        "subdistrict_name": "นาข่า"
    },
    {
        "subdistrict_id": "3034",
        "district_id": "371",
        "subdistrict_name": "นางาม"
    },
    {
        "subdistrict_id": "3035",
        "district_id": "371",
        "subdistrict_name": "ท่าศาลา"
    },
    {
        "subdistrict_id": "3036",
        "district_id": "372",
        "subdistrict_name": "ชนบท"
    },
    {
        "subdistrict_id": "3037",
        "district_id": "372",
        "subdistrict_name": "กุดเพียขอม"
    },
    {
        "subdistrict_id": "3038",
        "district_id": "372",
        "subdistrict_name": "วังแสง"
    },
    {
        "subdistrict_id": "3039",
        "district_id": "372",
        "subdistrict_name": "ห้วยแก"
    },
    {
        "subdistrict_id": "3040",
        "district_id": "372",
        "subdistrict_name": "บ้านแท่น"
    },
    {
        "subdistrict_id": "3041",
        "district_id": "372",
        "subdistrict_name": "ศรีบุญเรือง"
    },
    {
        "subdistrict_id": "3042",
        "district_id": "372",
        "subdistrict_name": "โนนพะยอม"
    },
    {
        "subdistrict_id": "3043",
        "district_id": "372",
        "subdistrict_name": "ปอแดง"
    },
    {
        "subdistrict_id": "3044",
        "district_id": "373",
        "subdistrict_name": "เขาสวนกวาง"
    },
    {
        "subdistrict_id": "3045",
        "district_id": "373",
        "subdistrict_name": "ดงเมืองแอม"
    },
    {
        "subdistrict_id": "3046",
        "district_id": "373",
        "subdistrict_name": "นางิ้ว"
    },
    {
        "subdistrict_id": "3047",
        "district_id": "373",
        "subdistrict_name": "โนนสมบูรณ์"
    },
    {
        "subdistrict_id": "3048",
        "district_id": "373",
        "subdistrict_name": "คำม่วง"
    },
    {
        "subdistrict_id": "3049",
        "district_id": "374",
        "subdistrict_name": "โนนคอม"
    },
    {
        "subdistrict_id": "3050",
        "district_id": "374",
        "subdistrict_name": "นาฝาย"
    },
    {
        "subdistrict_id": "3051",
        "district_id": "374",
        "subdistrict_name": "ภูผาม่าน"
    },
    {
        "subdistrict_id": "3052",
        "district_id": "374",
        "subdistrict_name": "วังสวาบ"
    },
    {
        "subdistrict_id": "3053",
        "district_id": "374",
        "subdistrict_name": "ห้วยม่วง"
    },
    {
        "subdistrict_id": "3054",
        "district_id": "375",
        "subdistrict_name": "กระนวน"
    },
    {
        "subdistrict_id": "3055",
        "district_id": "375",
        "subdistrict_name": "คำแมด"
    },
    {
        "subdistrict_id": "3056",
        "district_id": "375",
        "subdistrict_name": "บ้านโนน"
    },
    {
        "subdistrict_id": "3057",
        "district_id": "375",
        "subdistrict_name": "คูคำ"
    },
    {
        "subdistrict_id": "3058",
        "district_id": "375",
        "subdistrict_name": "ห้วยเตย"
    },
    {
        "subdistrict_id": "3059",
        "district_id": "376",
        "subdistrict_name": "บ้านโคก"
    },
    {
        "subdistrict_id": "3060",
        "district_id": "376",
        "subdistrict_name": "โพธิ์ไชย"
    },
    {
        "subdistrict_id": "3061",
        "district_id": "376",
        "subdistrict_name": "ซับสมบูรณ์"
    },
    {
        "subdistrict_id": "3062",
        "district_id": "376",
        "subdistrict_name": "นาแพง"
    },
    {
        "subdistrict_id": "3063",
        "district_id": "377",
        "subdistrict_name": "กุดธาตุ"
    },
    {
        "subdistrict_id": "3064",
        "district_id": "377",
        "subdistrict_name": "บ้านโคก"
    },
    {
        "subdistrict_id": "3065",
        "district_id": "377",
        "subdistrict_name": "ขนวน"
    },
    {
        "subdistrict_id": "3066",
        "district_id": "378",
        "subdistrict_name": "บ้านแฮด"
    },
    {
        "subdistrict_id": "3067",
        "district_id": "378",
        "subdistrict_name": "โคกสำราญ"
    },
    {
        "subdistrict_id": "3068",
        "district_id": "378",
        "subdistrict_name": "โนนสมบูรณ์"
    },
    {
        "subdistrict_id": "3069",
        "district_id": "378",
        "subdistrict_name": "หนองแซง"
    },
    {
        "subdistrict_id": "3070",
        "district_id": "379",
        "subdistrict_name": "โนนศิลา"
    },
    {
        "subdistrict_id": "3071",
        "district_id": "379",
        "subdistrict_name": "หนองปลาหมอ"
    },
    {
        "subdistrict_id": "3072",
        "district_id": "379",
        "subdistrict_name": "บ้านหัน"
    },
    {
        "subdistrict_id": "3073",
        "district_id": "379",
        "subdistrict_name": "เปือยใหญ่"
    },
    {
        "subdistrict_id": "3074",
        "district_id": "379",
        "subdistrict_name": "โนนแดง"
    },
    {
        "subdistrict_id": "3075",
        "district_id": "380",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "3076",
        "district_id": "380",
        "subdistrict_name": "เมืองเก่าพัฒนา"
    },
    {
        "subdistrict_id": "3077",
        "district_id": "380",
        "subdistrict_name": "เขาน้อย"
    },
    {
        "subdistrict_id": "3078",
        "district_id": "381",
        "subdistrict_name": "หมากแข้ง"
    },
    {
        "subdistrict_id": "3079",
        "district_id": "381",
        "subdistrict_name": "นิคมสงเคราะห์"
    },
    {
        "subdistrict_id": "3080",
        "district_id": "381",
        "subdistrict_name": "บ้านขาว"
    },
    {
        "subdistrict_id": "3081",
        "district_id": "381",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "3082",
        "district_id": "381",
        "subdistrict_name": "บ้านตาด"
    },
    {
        "subdistrict_id": "3083",
        "district_id": "381",
        "subdistrict_name": "โนนสูง"
    },
    {
        "subdistrict_id": "3084",
        "district_id": "381",
        "subdistrict_name": "หมูม่น"
    },
    {
        "subdistrict_id": "3085",
        "district_id": "381",
        "subdistrict_name": "เชียงยืน"
    },
    {
        "subdistrict_id": "3086",
        "district_id": "381",
        "subdistrict_name": "หนองนาคำ"
    },
    {
        "subdistrict_id": "3087",
        "district_id": "381",
        "subdistrict_name": "กุดสระ"
    },
    {
        "subdistrict_id": "3088",
        "district_id": "381",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "3089",
        "district_id": "381",
        "subdistrict_name": "บ้านเลื่อม"
    },
    {
        "subdistrict_id": "3090",
        "district_id": "381",
        "subdistrict_name": "เชียงพิณ"
    },
    {
        "subdistrict_id": "3091",
        "district_id": "381",
        "subdistrict_name": "สามพร้าว"
    },
    {
        "subdistrict_id": "3092",
        "district_id": "381",
        "subdistrict_name": "หนองไฮ"
    },
    {
        "subdistrict_id": "3093",
        "district_id": "381",
        "subdistrict_name": "นาข่า"
    },
    {
        "subdistrict_id": "3094",
        "district_id": "381",
        "subdistrict_name": "บ้านจั่น"
    },
    {
        "subdistrict_id": "3095",
        "district_id": "381",
        "subdistrict_name": "หนองขอนกว้าง"
    },
    {
        "subdistrict_id": "3096",
        "district_id": "381",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "3097",
        "district_id": "381",
        "subdistrict_name": "นากว้าง"
    },
    {
        "subdistrict_id": "3098",
        "district_id": "381",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "3099",
        "district_id": "382",
        "subdistrict_name": "กุดจับ"
    },
    {
        "subdistrict_id": "3100",
        "district_id": "382",
        "subdistrict_name": "ปะโค"
    },
    {
        "subdistrict_id": "3101",
        "district_id": "382",
        "subdistrict_name": "ขอนยูง"
    },
    {
        "subdistrict_id": "3102",
        "district_id": "382",
        "subdistrict_name": "เชียงเพ็ง"
    },
    {
        "subdistrict_id": "3103",
        "district_id": "382",
        "subdistrict_name": "สร้างก่อ"
    },
    {
        "subdistrict_id": "3104",
        "district_id": "382",
        "subdistrict_name": "เมืองเพีย"
    },
    {
        "subdistrict_id": "3105",
        "district_id": "382",
        "subdistrict_name": "ตาลเลียน"
    },
    {
        "subdistrict_id": "3106",
        "district_id": "383",
        "subdistrict_name": "หมากหญ้า"
    },
    {
        "subdistrict_id": "3107",
        "district_id": "383",
        "subdistrict_name": "หนองอ้อ"
    },
    {
        "subdistrict_id": "3108",
        "district_id": "383",
        "subdistrict_name": "อูบมุง"
    },
    {
        "subdistrict_id": "3109",
        "district_id": "383",
        "subdistrict_name": "กุดหมากไฟ"
    },
    {
        "subdistrict_id": "3110",
        "district_id": "383",
        "subdistrict_name": "น้ำพ่น"
    },
    {
        "subdistrict_id": "3111",
        "district_id": "383",
        "subdistrict_name": "หนองบัวบาน"
    },
    {
        "subdistrict_id": "3112",
        "district_id": "383",
        "subdistrict_name": "โนนหวาย"
    },
    {
        "subdistrict_id": "3113",
        "district_id": "383",
        "subdistrict_name": "หนองวัวซอ"
    },
    {
        "subdistrict_id": "3114",
        "district_id": "384",
        "subdistrict_name": "ตูมใต้"
    },
    {
        "subdistrict_id": "3115",
        "district_id": "384",
        "subdistrict_name": "พันดอน"
    },
    {
        "subdistrict_id": "3116",
        "district_id": "384",
        "subdistrict_name": "เวียงคำ"
    },
    {
        "subdistrict_id": "3117",
        "district_id": "384",
        "subdistrict_name": "แชแล"
    },
    {
        "subdistrict_id": "3118",
        "district_id": "384",
        "subdistrict_name": "เชียงแหว"
    },
    {
        "subdistrict_id": "3119",
        "district_id": "384",
        "subdistrict_name": "ห้วยเกิ้ง"
    },
    {
        "subdistrict_id": "3120",
        "district_id": "384",
        "subdistrict_name": "เสอเพลอ"
    },
    {
        "subdistrict_id": "3121",
        "district_id": "384",
        "subdistrict_name": "สีออ"
    },
    {
        "subdistrict_id": "3122",
        "district_id": "384",
        "subdistrict_name": "ปะโค"
    },
    {
        "subdistrict_id": "3123",
        "district_id": "384",
        "subdistrict_name": "ผาสุก"
    },
    {
        "subdistrict_id": "3124",
        "district_id": "384",
        "subdistrict_name": "ท่าลี่"
    },
    {
        "subdistrict_id": "3125",
        "district_id": "384",
        "subdistrict_name": "กุมภวาปี"
    },
    {
        "subdistrict_id": "3126",
        "district_id": "384",
        "subdistrict_name": "หนองหว้า"
    },
    {
        "subdistrict_id": "3127",
        "district_id": "385",
        "subdistrict_name": "โนนสะอาด"
    },
    {
        "subdistrict_id": "3128",
        "district_id": "385",
        "subdistrict_name": "บุ่งแก้ว"
    },
    {
        "subdistrict_id": "3129",
        "district_id": "385",
        "subdistrict_name": "โพธิ์ศรีสำราญ"
    },
    {
        "subdistrict_id": "3130",
        "district_id": "385",
        "subdistrict_name": "ทมนางาม"
    },
    {
        "subdistrict_id": "3131",
        "district_id": "385",
        "subdistrict_name": "หนองกุงศรี"
    },
    {
        "subdistrict_id": "3132",
        "district_id": "385",
        "subdistrict_name": "โคกกลาง"
    },
    {
        "subdistrict_id": "3133",
        "district_id": "386",
        "subdistrict_name": "หนองหาน"
    },
    {
        "subdistrict_id": "3134",
        "district_id": "386",
        "subdistrict_name": "หนองเม็ก"
    },
    {
        "subdistrict_id": "3135",
        "district_id": "386",
        "subdistrict_name": "พังงู"
    },
    {
        "subdistrict_id": "3136",
        "district_id": "386",
        "subdistrict_name": "สะแบง"
    },
    {
        "subdistrict_id": "3137",
        "district_id": "386",
        "subdistrict_name": "สร้อยพร้าว"
    },
    {
        "subdistrict_id": "3138",
        "district_id": "386",
        "subdistrict_name": "บ้านเชียง"
    },
    {
        "subdistrict_id": "3139",
        "district_id": "386",
        "subdistrict_name": "บ้านยา"
    },
    {
        "subdistrict_id": "3140",
        "district_id": "386",
        "subdistrict_name": "โพนงาม"
    },
    {
        "subdistrict_id": "3141",
        "district_id": "386",
        "subdistrict_name": "ผักตบ"
    },
    {
        "subdistrict_id": "3142",
        "district_id": "386",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "3143",
        "district_id": "386",
        "subdistrict_name": "ดอนหายโศก"
    },
    {
        "subdistrict_id": "3144",
        "district_id": "386",
        "subdistrict_name": "หนองสระปลา"
    },
    {
        "subdistrict_id": "3145",
        "district_id": "387",
        "subdistrict_name": "ทุ่งฝน"
    },
    {
        "subdistrict_id": "3146",
        "district_id": "387",
        "subdistrict_name": "ทุ่งใหญ่"
    },
    {
        "subdistrict_id": "3147",
        "district_id": "387",
        "subdistrict_name": "นาชุมแสง"
    },
    {
        "subdistrict_id": "3148",
        "district_id": "387",
        "subdistrict_name": "นาทม"
    },
    {
        "subdistrict_id": "3149",
        "district_id": "388",
        "subdistrict_name": "ไชยวาน"
    },
    {
        "subdistrict_id": "3150",
        "district_id": "388",
        "subdistrict_name": "หนองหลัก"
    },
    {
        "subdistrict_id": "3151",
        "district_id": "388",
        "subdistrict_name": "คำเลาะ"
    },
    {
        "subdistrict_id": "3152",
        "district_id": "388",
        "subdistrict_name": "โพนสูง"
    },
    {
        "subdistrict_id": "3153",
        "district_id": "389",
        "subdistrict_name": "ศรีธาตุ"
    },
    {
        "subdistrict_id": "3154",
        "district_id": "389",
        "subdistrict_name": "จำปี"
    },
    {
        "subdistrict_id": "3155",
        "district_id": "389",
        "subdistrict_name": "บ้านโปร่ง"
    },
    {
        "subdistrict_id": "3156",
        "district_id": "389",
        "subdistrict_name": "หัวนาคำ"
    },
    {
        "subdistrict_id": "3157",
        "district_id": "389",
        "subdistrict_name": "หนองนกเขียน"
    },
    {
        "subdistrict_id": "3158",
        "district_id": "389",
        "subdistrict_name": "นายูง"
    },
    {
        "subdistrict_id": "3159",
        "district_id": "389",
        "subdistrict_name": "ตาดทอง"
    },
    {
        "subdistrict_id": "3160",
        "district_id": "390",
        "subdistrict_name": "หนองกุงทับม้า"
    },
    {
        "subdistrict_id": "3161",
        "district_id": "390",
        "subdistrict_name": "หนองหญ้าไซ"
    },
    {
        "subdistrict_id": "3162",
        "district_id": "390",
        "subdistrict_name": "บะยาว"
    },
    {
        "subdistrict_id": "3163",
        "district_id": "390",
        "subdistrict_name": "ผาสุก"
    },
    {
        "subdistrict_id": "3164",
        "district_id": "390",
        "subdistrict_name": "คำโคกสูง"
    },
    {
        "subdistrict_id": "3165",
        "district_id": "390",
        "subdistrict_name": "วังสามหมอ"
    },
    {
        "subdistrict_id": "3166",
        "district_id": "391",
        "subdistrict_name": "ศรีสุทโธ"
    },
    {
        "subdistrict_id": "3167",
        "district_id": "391",
        "subdistrict_name": "บ้านดุง"
    },
    {
        "subdistrict_id": "3168",
        "district_id": "391",
        "subdistrict_name": "ดงเย็น"
    },
    {
        "subdistrict_id": "3169",
        "district_id": "391",
        "subdistrict_name": "โพนสูง"
    },
    {
        "subdistrict_id": "3170",
        "district_id": "391",
        "subdistrict_name": "อ้อมกอ"
    },
    {
        "subdistrict_id": "3171",
        "district_id": "391",
        "subdistrict_name": "บ้านจันทน์"
    },
    {
        "subdistrict_id": "3172",
        "district_id": "391",
        "subdistrict_name": "บ้านชัย"
    },
    {
        "subdistrict_id": "3173",
        "district_id": "391",
        "subdistrict_name": "นาไหม"
    },
    {
        "subdistrict_id": "3174",
        "district_id": "391",
        "subdistrict_name": "ถ่อนนาลับ"
    },
    {
        "subdistrict_id": "3175",
        "district_id": "391",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "3176",
        "district_id": "391",
        "subdistrict_name": "บ้านม่วง"
    },
    {
        "subdistrict_id": "3177",
        "district_id": "391",
        "subdistrict_name": "บ้านตาด"
    },
    {
        "subdistrict_id": "3178",
        "district_id": "391",
        "subdistrict_name": "นาคำ"
    },
    {
        "subdistrict_id": "3179",
        "district_id": "392",
        "subdistrict_name": "บ้านผือ"
    },
    {
        "subdistrict_id": "3180",
        "district_id": "392",
        "subdistrict_name": "หายโศก"
    },
    {
        "subdistrict_id": "3181",
        "district_id": "392",
        "subdistrict_name": "เขือน้ำ"
    },
    {
        "subdistrict_id": "3182",
        "district_id": "392",
        "subdistrict_name": "คำบง"
    },
    {
        "subdistrict_id": "3183",
        "district_id": "392",
        "subdistrict_name": "โนนทอง"
    },
    {
        "subdistrict_id": "3184",
        "district_id": "392",
        "subdistrict_name": "ข้าวสาร"
    },
    {
        "subdistrict_id": "3185",
        "district_id": "392",
        "subdistrict_name": "จำปาโมง"
    },
    {
        "subdistrict_id": "3186",
        "district_id": "392",
        "subdistrict_name": "กลางใหญ่"
    },
    {
        "subdistrict_id": "3187",
        "district_id": "392",
        "subdistrict_name": "เมืองพาน"
    },
    {
        "subdistrict_id": "3188",
        "district_id": "392",
        "subdistrict_name": "คำด้วง"
    },
    {
        "subdistrict_id": "3189",
        "district_id": "392",
        "subdistrict_name": "หนองหัวคู"
    },
    {
        "subdistrict_id": "3190",
        "district_id": "392",
        "subdistrict_name": "บ้านค้อ"
    },
    {
        "subdistrict_id": "3191",
        "district_id": "392",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "3192",
        "district_id": "393",
        "subdistrict_name": "นางัว"
    },
    {
        "subdistrict_id": "3193",
        "district_id": "393",
        "subdistrict_name": "น้ำโสม"
    },
    {
        "subdistrict_id": "3194",
        "district_id": "393",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "3195",
        "district_id": "393",
        "subdistrict_name": "บ้านหยวก"
    },
    {
        "subdistrict_id": "3196",
        "district_id": "393",
        "subdistrict_name": "โสมเยี่ยม"
    },
    {
        "subdistrict_id": "3197",
        "district_id": "393",
        "subdistrict_name": "ศรีสำราญ"
    },
    {
        "subdistrict_id": "3198",
        "district_id": "393",
        "subdistrict_name": "สามัคคี"
    },
    {
        "subdistrict_id": "3199",
        "district_id": "394",
        "subdistrict_name": "เพ็ญ"
    },
    {
        "subdistrict_id": "3200",
        "district_id": "394",
        "subdistrict_name": "บ้านธาตุ"
    },
    {
        "subdistrict_id": "3201",
        "district_id": "394",
        "subdistrict_name": "นาพู่"
    },
    {
        "subdistrict_id": "3202",
        "district_id": "394",
        "subdistrict_name": "เชียงหวาง"
    },
    {
        "subdistrict_id": "3203",
        "district_id": "394",
        "subdistrict_name": "สุมเส้า"
    },
    {
        "subdistrict_id": "3204",
        "district_id": "394",
        "subdistrict_name": "นาบัว"
    },
    {
        "subdistrict_id": "3205",
        "district_id": "394",
        "subdistrict_name": "บ้านเหล่า"
    },
    {
        "subdistrict_id": "3206",
        "district_id": "394",
        "subdistrict_name": "จอมศรี"
    },
    {
        "subdistrict_id": "3207",
        "district_id": "394",
        "subdistrict_name": "เตาไห"
    },
    {
        "subdistrict_id": "3208",
        "district_id": "394",
        "subdistrict_name": "โคกกลาง"
    },
    {
        "subdistrict_id": "3209",
        "district_id": "394",
        "subdistrict_name": "สร้างแป้น"
    },
    {
        "subdistrict_id": "3210",
        "district_id": "395",
        "subdistrict_name": "สร้างคอม"
    },
    {
        "subdistrict_id": "3211",
        "district_id": "395",
        "subdistrict_name": "เชียงดา"
    },
    {
        "subdistrict_id": "3212",
        "district_id": "395",
        "subdistrict_name": "บ้านยวด"
    },
    {
        "subdistrict_id": "3213",
        "district_id": "395",
        "subdistrict_name": "บ้านโคก"
    },
    {
        "subdistrict_id": "3214",
        "district_id": "395",
        "subdistrict_name": "นาสะอาด"
    },
    {
        "subdistrict_id": "3215",
        "district_id": "395",
        "subdistrict_name": "บ้านหินโงม"
    },
    {
        "subdistrict_id": "3216",
        "district_id": "396",
        "subdistrict_name": "หนองแสง"
    },
    {
        "subdistrict_id": "3217",
        "district_id": "396",
        "subdistrict_name": "แสงสว่าง"
    },
    {
        "subdistrict_id": "3218",
        "district_id": "396",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "3219",
        "district_id": "396",
        "subdistrict_name": "ทับกุง"
    },
    {
        "subdistrict_id": "3220",
        "district_id": "397",
        "subdistrict_name": "นายูง"
    },
    {
        "subdistrict_id": "3221",
        "district_id": "397",
        "subdistrict_name": "บ้านก้อง"
    },
    {
        "subdistrict_id": "3222",
        "district_id": "397",
        "subdistrict_name": "นาแค"
    },
    {
        "subdistrict_id": "3223",
        "district_id": "397",
        "subdistrict_name": "โนนทอง"
    },
    {
        "subdistrict_id": "3224",
        "district_id": "398",
        "subdistrict_name": "บ้านแดง"
    },
    {
        "subdistrict_id": "3225",
        "district_id": "398",
        "subdistrict_name": "นาทราย"
    },
    {
        "subdistrict_id": "3226",
        "district_id": "398",
        "subdistrict_name": "ดอนกลอย"
    },
    {
        "subdistrict_id": "3227",
        "district_id": "399",
        "subdistrict_name": "บ้านจีต"
    },
    {
        "subdistrict_id": "3228",
        "district_id": "399",
        "subdistrict_name": "โนนทองอินทร์"
    },
    {
        "subdistrict_id": "3229",
        "district_id": "399",
        "subdistrict_name": "ค้อใหญ่"
    },
    {
        "subdistrict_id": "3230",
        "district_id": "399",
        "subdistrict_name": "คอนสาย"
    },
    {
        "subdistrict_id": "3231",
        "district_id": "400",
        "subdistrict_name": "นาม่วง"
    },
    {
        "subdistrict_id": "3232",
        "district_id": "400",
        "subdistrict_name": "ห้วยสามพาด"
    },
    {
        "subdistrict_id": "3233",
        "district_id": "400",
        "subdistrict_name": "อุ่มจาน"
    },
    {
        "subdistrict_id": "3234",
        "district_id": "401",
        "subdistrict_name": "กุดป่อง"
    },
    {
        "subdistrict_id": "3235",
        "district_id": "401",
        "subdistrict_name": "เมือง"
    },
    {
        "subdistrict_id": "3236",
        "district_id": "401",
        "subdistrict_name": "นาอ้อ"
    },
    {
        "subdistrict_id": "3237",
        "district_id": "401",
        "subdistrict_name": "กกดู่"
    },
    {
        "subdistrict_id": "3238",
        "district_id": "401",
        "subdistrict_name": "น้ำหมาน"
    },
    {
        "subdistrict_id": "3239",
        "district_id": "401",
        "subdistrict_name": "เสี้ยว"
    },
    {
        "subdistrict_id": "3240",
        "district_id": "401",
        "subdistrict_name": "นาอาน"
    },
    {
        "subdistrict_id": "3241",
        "district_id": "401",
        "subdistrict_name": "นาโป่ง"
    },
    {
        "subdistrict_id": "3242",
        "district_id": "401",
        "subdistrict_name": "นาดินดำ"
    },
    {
        "subdistrict_id": "3243",
        "district_id": "401",
        "subdistrict_name": "น้ำสวย"
    },
    {
        "subdistrict_id": "3244",
        "district_id": "401",
        "subdistrict_name": "ชัยพฤกษ์"
    },
    {
        "subdistrict_id": "3245",
        "district_id": "401",
        "subdistrict_name": "นาแขม"
    },
    {
        "subdistrict_id": "3246",
        "district_id": "401",
        "subdistrict_name": "ศรีสองรัก"
    },
    {
        "subdistrict_id": "3247",
        "district_id": "401",
        "subdistrict_name": "กกทอง"
    },
    {
        "subdistrict_id": "3248",
        "district_id": "402",
        "subdistrict_name": "นาด้วง"
    },
    {
        "subdistrict_id": "3249",
        "district_id": "402",
        "subdistrict_name": "นาดอกคำ"
    },
    {
        "subdistrict_id": "3250",
        "district_id": "402",
        "subdistrict_name": "ท่าสะอาด"
    },
    {
        "subdistrict_id": "3251",
        "district_id": "402",
        "subdistrict_name": "ท่าสวรรค์"
    },
    {
        "subdistrict_id": "3252",
        "district_id": "403",
        "subdistrict_name": "เชียงคาน"
    },
    {
        "subdistrict_id": "3253",
        "district_id": "403",
        "subdistrict_name": "ธาตุ"
    },
    {
        "subdistrict_id": "3254",
        "district_id": "403",
        "subdistrict_name": "นาซ่าว"
    },
    {
        "subdistrict_id": "3255",
        "district_id": "403",
        "subdistrict_name": "เขาแก้ว"
    },
    {
        "subdistrict_id": "3256",
        "district_id": "403",
        "subdistrict_name": "ปากตม"
    },
    {
        "subdistrict_id": "3257",
        "district_id": "403",
        "subdistrict_name": "บุฮม"
    },
    {
        "subdistrict_id": "3258",
        "district_id": "403",
        "subdistrict_name": "จอมศรี"
    },
    {
        "subdistrict_id": "3259",
        "district_id": "403",
        "subdistrict_name": "หาดทรายขาว"
    },
    {
        "subdistrict_id": "3260",
        "district_id": "404",
        "subdistrict_name": "ปากชม"
    },
    {
        "subdistrict_id": "3261",
        "district_id": "404",
        "subdistrict_name": "เชียงกลม"
    },
    {
        "subdistrict_id": "3262",
        "district_id": "404",
        "subdistrict_name": "หาดคัมภีร์"
    },
    {
        "subdistrict_id": "3263",
        "district_id": "404",
        "subdistrict_name": "ห้วยบ่อซืน"
    },
    {
        "subdistrict_id": "3264",
        "district_id": "404",
        "subdistrict_name": "ห้วยพิชัย"
    },
    {
        "subdistrict_id": "3265",
        "district_id": "404",
        "subdistrict_name": "ชมเจริญ"
    },
    {
        "subdistrict_id": "3266",
        "district_id": "405",
        "subdistrict_name": "ด่านซ้าย"
    },
    {
        "subdistrict_id": "3267",
        "district_id": "405",
        "subdistrict_name": "ปากหมัน"
    },
    {
        "subdistrict_id": "3268",
        "district_id": "405",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "3269",
        "district_id": "405",
        "subdistrict_name": "โคกงาม"
    },
    {
        "subdistrict_id": "3270",
        "district_id": "405",
        "subdistrict_name": "โพนสูง"
    },
    {
        "subdistrict_id": "3271",
        "district_id": "405",
        "subdistrict_name": "อิปุ่ม"
    },
    {
        "subdistrict_id": "3272",
        "district_id": "405",
        "subdistrict_name": "กกสะทอน"
    },
    {
        "subdistrict_id": "3273",
        "district_id": "405",
        "subdistrict_name": "โป่ง"
    },
    {
        "subdistrict_id": "3274",
        "district_id": "405",
        "subdistrict_name": "วังยาว"
    },
    {
        "subdistrict_id": "3275",
        "district_id": "405",
        "subdistrict_name": "นาหอ"
    },
    {
        "subdistrict_id": "3276",
        "district_id": "406",
        "subdistrict_name": "นาแห้ว"
    },
    {
        "subdistrict_id": "3277",
        "district_id": "406",
        "subdistrict_name": "แสงภา"
    },
    {
        "subdistrict_id": "3278",
        "district_id": "406",
        "subdistrict_name": "นาพึง"
    },
    {
        "subdistrict_id": "3279",
        "district_id": "406",
        "subdistrict_name": "นามาลา"
    },
    {
        "subdistrict_id": "3280",
        "district_id": "406",
        "subdistrict_name": "เหล่ากอหก"
    },
    {
        "subdistrict_id": "3281",
        "district_id": "407",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "3282",
        "district_id": "407",
        "subdistrict_name": "ท่าศาลา"
    },
    {
        "subdistrict_id": "3283",
        "district_id": "407",
        "subdistrict_name": "ร่องจิก"
    },
    {
        "subdistrict_id": "3284",
        "district_id": "407",
        "subdistrict_name": "ปลาบ่า"
    },
    {
        "subdistrict_id": "3285",
        "district_id": "407",
        "subdistrict_name": "ลาดค่าง"
    },
    {
        "subdistrict_id": "3286",
        "district_id": "407",
        "subdistrict_name": "สานตม"
    },
    {
        "subdistrict_id": "3287",
        "district_id": "408",
        "subdistrict_name": "ท่าลี่"
    },
    {
        "subdistrict_id": "3288",
        "district_id": "408",
        "subdistrict_name": "หนองผือ"
    },
    {
        "subdistrict_id": "3289",
        "district_id": "408",
        "subdistrict_name": "อาฮี"
    },
    {
        "subdistrict_id": "3290",
        "district_id": "408",
        "subdistrict_name": "น้ำแคม"
    },
    {
        "subdistrict_id": "3291",
        "district_id": "408",
        "subdistrict_name": "โคกใหญ่"
    },
    {
        "subdistrict_id": "3292",
        "district_id": "408",
        "subdistrict_name": "น้ำทูน"
    },
    {
        "subdistrict_id": "3293",
        "district_id": "409",
        "subdistrict_name": "วังสะพุง"
    },
    {
        "subdistrict_id": "3294",
        "district_id": "409",
        "subdistrict_name": "ทรายขาว"
    },
    {
        "subdistrict_id": "3295",
        "district_id": "409",
        "subdistrict_name": "หนองหญ้าปล้อง"
    },
    {
        "subdistrict_id": "3296",
        "district_id": "409",
        "subdistrict_name": "หนองงิ้ว"
    },
    {
        "subdistrict_id": "3297",
        "district_id": "409",
        "subdistrict_name": "ปากปวน"
    },
    {
        "subdistrict_id": "3298",
        "district_id": "409",
        "subdistrict_name": "ผาน้อย"
    },
    {
        "subdistrict_id": "3299",
        "district_id": "409",
        "subdistrict_name": "ผาบิ้ง"
    },
    {
        "subdistrict_id": "3300",
        "district_id": "409",
        "subdistrict_name": "เขาหลวง"
    },
    {
        "subdistrict_id": "3301",
        "district_id": "409",
        "subdistrict_name": "โคกขมิ้น"
    },
    {
        "subdistrict_id": "3302",
        "district_id": "409",
        "subdistrict_name": "ศรีสงคราม"
    },
    {
        "subdistrict_id": "3303",
        "district_id": "410",
        "subdistrict_name": "ศรีฐาน"
    },
    {
        "subdistrict_id": "3304",
        "district_id": "410",
        "subdistrict_name": "ผานกเค้า"
    },
    {
        "subdistrict_id": "3305",
        "district_id": "410",
        "subdistrict_name": "ภูกระดึง"
    },
    {
        "subdistrict_id": "3306",
        "district_id": "410",
        "subdistrict_name": "ห้วยส้ม"
    },
    {
        "subdistrict_id": "3307",
        "district_id": "411",
        "subdistrict_name": "ภูหอ"
    },
    {
        "subdistrict_id": "3308",
        "district_id": "411",
        "subdistrict_name": "หนองคัน"
    },
    {
        "subdistrict_id": "3309",
        "district_id": "411",
        "subdistrict_name": "ห้วยสีเสียด"
    },
    {
        "subdistrict_id": "3310",
        "district_id": "411",
        "subdistrict_name": "เลยวังไสย์"
    },
    {
        "subdistrict_id": "3311",
        "district_id": "411",
        "subdistrict_name": "แก่งศรีภูมิ"
    },
    {
        "subdistrict_id": "3312",
        "district_id": "412",
        "subdistrict_name": "ผาขาว"
    },
    {
        "subdistrict_id": "3313",
        "district_id": "412",
        "subdistrict_name": "ท่าช้างคล้อง"
    },
    {
        "subdistrict_id": "3314",
        "district_id": "412",
        "subdistrict_name": "โนนปอแดง"
    },
    {
        "subdistrict_id": "3315",
        "district_id": "412",
        "subdistrict_name": "โนนป่าซาง"
    },
    {
        "subdistrict_id": "3316",
        "district_id": "412",
        "subdistrict_name": "บ้านเพิ่ม"
    },
    {
        "subdistrict_id": "3317",
        "district_id": "413",
        "subdistrict_name": "เอราวัณ"
    },
    {
        "subdistrict_id": "3318",
        "district_id": "413",
        "subdistrict_name": "ผาอินทร์แปลง"
    },
    {
        "subdistrict_id": "3319",
        "district_id": "413",
        "subdistrict_name": "ผาสามยอด"
    },
    {
        "subdistrict_id": "3320",
        "district_id": "413",
        "subdistrict_name": "ทรัพย์ไพวัลย์"
    },
    {
        "subdistrict_id": "3321",
        "district_id": "414",
        "subdistrict_name": "หนองหิน"
    },
    {
        "subdistrict_id": "3322",
        "district_id": "414",
        "subdistrict_name": "ตาดข่า"
    },
    {
        "subdistrict_id": "3323",
        "district_id": "414",
        "subdistrict_name": "ปวนพุ"
    },
    {
        "subdistrict_id": "3324",
        "district_id": "415",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "3325",
        "district_id": "415",
        "subdistrict_name": "มีชัย"
    },
    {
        "subdistrict_id": "3326",
        "district_id": "415",
        "subdistrict_name": "โพธิ์ชัย"
    },
    {
        "subdistrict_id": "3327",
        "district_id": "415",
        "subdistrict_name": "กวนวัน"
    },
    {
        "subdistrict_id": "3328",
        "district_id": "415",
        "subdistrict_name": "เวียงคุก"
    },
    {
        "subdistrict_id": "3329",
        "district_id": "415",
        "subdistrict_name": "วัดธาตุ"
    },
    {
        "subdistrict_id": "3330",
        "district_id": "415",
        "subdistrict_name": "หาดคำ"
    },
    {
        "subdistrict_id": "3331",
        "district_id": "415",
        "subdistrict_name": "หินโงม"
    },
    {
        "subdistrict_id": "3332",
        "district_id": "415",
        "subdistrict_name": "บ้านเดื่อ"
    },
    {
        "subdistrict_id": "3333",
        "district_id": "415",
        "subdistrict_name": "ค่ายบกหวาน"
    },
    {
        "subdistrict_id": "3334",
        "district_id": "415",
        "subdistrict_name": "สองห้อง"
    },
    {
        "subdistrict_id": "3335",
        "district_id": "415",
        "subdistrict_name": "พระธาตุบังพวน"
    },
    {
        "subdistrict_id": "3336",
        "district_id": "415",
        "subdistrict_name": "หนองกอมเกาะ"
    },
    {
        "subdistrict_id": "3337",
        "district_id": "415",
        "subdistrict_name": "ปะโค"
    },
    {
        "subdistrict_id": "3338",
        "district_id": "415",
        "subdistrict_name": "เมืองหมี"
    },
    {
        "subdistrict_id": "3339",
        "district_id": "415",
        "subdistrict_name": "สีกาย"
    },
    {
        "subdistrict_id": "3340",
        "district_id": "416",
        "subdistrict_name": "ท่าบ่อ"
    },
    {
        "subdistrict_id": "3341",
        "district_id": "416",
        "subdistrict_name": "น้ำโมง"
    },
    {
        "subdistrict_id": "3342",
        "district_id": "416",
        "subdistrict_name": "กองนาง"
    },
    {
        "subdistrict_id": "3343",
        "district_id": "416",
        "subdistrict_name": "โคกคอน"
    },
    {
        "subdistrict_id": "3344",
        "district_id": "416",
        "subdistrict_name": "บ้านเดื่อ"
    },
    {
        "subdistrict_id": "3345",
        "district_id": "416",
        "subdistrict_name": "บ้านถ่อน"
    },
    {
        "subdistrict_id": "3346",
        "district_id": "416",
        "subdistrict_name": "บ้านว่าน"
    },
    {
        "subdistrict_id": "3347",
        "district_id": "416",
        "subdistrict_name": "นาข่า"
    },
    {
        "subdistrict_id": "3348",
        "district_id": "416",
        "subdistrict_name": "โพนสา"
    },
    {
        "subdistrict_id": "3349",
        "district_id": "416",
        "subdistrict_name": "หนองนาง"
    },
    {
        "subdistrict_id": "3350",
        "district_id": "417",
        "subdistrict_name": "บึงกาฬ"
    },
    {
        "subdistrict_id": "3351",
        "district_id": "417",
        "subdistrict_name": "โนนสมบูรณ์"
    },
    {
        "subdistrict_id": "3352",
        "district_id": "417",
        "subdistrict_name": "หนองเข็ง"
    },
    {
        "subdistrict_id": "3353",
        "district_id": "417",
        "subdistrict_name": "หอคำ"
    },
    {
        "subdistrict_id": "3354",
        "district_id": "417",
        "subdistrict_name": "หนองเลิง"
    },
    {
        "subdistrict_id": "3355",
        "district_id": "417",
        "subdistrict_name": "โคกก่อง"
    },
    {
        "subdistrict_id": "3356",
        "district_id": "417",
        "subdistrict_name": "นาสวรรค์"
    },
    {
        "subdistrict_id": "3357",
        "district_id": "417",
        "subdistrict_name": "ไคสี"
    },
    {
        "subdistrict_id": "3358",
        "district_id": "417",
        "subdistrict_name": "ชัยพร"
    },
    {
        "subdistrict_id": "3359",
        "district_id": "417",
        "subdistrict_name": "วิศิษฐ์"
    },
    {
        "subdistrict_id": "3360",
        "district_id": "417",
        "subdistrict_name": "คำนาดี"
    },
    {
        "subdistrict_id": "3361",
        "district_id": "417",
        "subdistrict_name": "โป่งเปือย"
    },
    {
        "subdistrict_id": "3362",
        "district_id": "418",
        "subdistrict_name": "ศรีชมภู"
    },
    {
        "subdistrict_id": "3363",
        "district_id": "418",
        "subdistrict_name": "ดอนหญ้านาง"
    },
    {
        "subdistrict_id": "3364",
        "district_id": "418",
        "subdistrict_name": "พรเจริญ"
    },
    {
        "subdistrict_id": "3365",
        "district_id": "418",
        "subdistrict_name": "หนองหัวช้าง"
    },
    {
        "subdistrict_id": "3366",
        "district_id": "418",
        "subdistrict_name": "วังชมภู"
    },
    {
        "subdistrict_id": "3367",
        "district_id": "418",
        "subdistrict_name": "ป่าแฝก"
    },
    {
        "subdistrict_id": "3368",
        "district_id": "418",
        "subdistrict_name": "ศรีสำราญ"
    },
    {
        "subdistrict_id": "3369",
        "district_id": "419",
        "subdistrict_name": "จุมพล"
    },
    {
        "subdistrict_id": "3370",
        "district_id": "419",
        "subdistrict_name": "วัดหลวง"
    },
    {
        "subdistrict_id": "3371",
        "district_id": "419",
        "subdistrict_name": "กุดบง"
    },
    {
        "subdistrict_id": "3372",
        "district_id": "419",
        "subdistrict_name": "ชุมช้าง"
    },
    {
        "subdistrict_id": "3373",
        "district_id": "419",
        "subdistrict_name": "ทุ่งหลวง"
    },
    {
        "subdistrict_id": "3374",
        "district_id": "419",
        "subdistrict_name": "เหล่าต่างคำ"
    },
    {
        "subdistrict_id": "3375",
        "district_id": "419",
        "subdistrict_name": "นาหนัง"
    },
    {
        "subdistrict_id": "3376",
        "district_id": "419",
        "subdistrict_name": "เซิม"
    },
    {
        "subdistrict_id": "3377",
        "district_id": "419",
        "subdistrict_name": "บ้านโพธิ์"
    },
    {
        "subdistrict_id": "3378",
        "district_id": "419",
        "subdistrict_name": "บ้านผือ"
    },
    {
        "subdistrict_id": "3379",
        "district_id": "419",
        "subdistrict_name": "สร้างนางขาว"
    },
    {
        "subdistrict_id": "3380",
        "district_id": "420",
        "subdistrict_name": "โซ่"
    },
    {
        "subdistrict_id": "3381",
        "district_id": "420",
        "subdistrict_name": "หนองพันทา"
    },
    {
        "subdistrict_id": "3382",
        "district_id": "420",
        "subdistrict_name": "ศรีชมภู"
    },
    {
        "subdistrict_id": "3383",
        "district_id": "420",
        "subdistrict_name": "คำแก้ว"
    },
    {
        "subdistrict_id": "3384",
        "district_id": "420",
        "subdistrict_name": "บัวตูม"
    },
    {
        "subdistrict_id": "3385",
        "district_id": "420",
        "subdistrict_name": "ถ้ำเจริญ"
    },
    {
        "subdistrict_id": "3386",
        "district_id": "420",
        "subdistrict_name": "เหล่าทอง"
    },
    {
        "subdistrict_id": "3387",
        "district_id": "421",
        "subdistrict_name": "พานพร้าว"
    },
    {
        "subdistrict_id": "3388",
        "district_id": "421",
        "subdistrict_name": "บ้านหม้อ"
    },
    {
        "subdistrict_id": "3389",
        "district_id": "421",
        "subdistrict_name": "พระพุทธบาท"
    },
    {
        "subdistrict_id": "3390",
        "district_id": "421",
        "subdistrict_name": "หนองปลาปาก"
    },
    {
        "subdistrict_id": "3391",
        "district_id": "422",
        "subdistrict_name": "แก้งไก่"
    },
    {
        "subdistrict_id": "3392",
        "district_id": "422",
        "subdistrict_name": "ผาตั้ง"
    },
    {
        "subdistrict_id": "3393",
        "district_id": "422",
        "subdistrict_name": "บ้านม่วง"
    },
    {
        "subdistrict_id": "3394",
        "district_id": "422",
        "subdistrict_name": "นางิ้ว"
    },
    {
        "subdistrict_id": "3395",
        "district_id": "422",
        "subdistrict_name": "สังคม"
    },
    {
        "subdistrict_id": "3396",
        "district_id": "423",
        "subdistrict_name": "เซกา"
    },
    {
        "subdistrict_id": "3397",
        "district_id": "423",
        "subdistrict_name": "ซาง"
    },
    {
        "subdistrict_id": "3398",
        "district_id": "423",
        "subdistrict_name": "ท่ากกแดง"
    },
    {
        "subdistrict_id": "3399",
        "district_id": "423",
        "subdistrict_name": "บ้านต้อง"
    },
    {
        "subdistrict_id": "3400",
        "district_id": "423",
        "subdistrict_name": "ป่งไฮ"
    },
    {
        "subdistrict_id": "3401",
        "district_id": "423",
        "subdistrict_name": "น้ำจั้น"
    },
    {
        "subdistrict_id": "3402",
        "district_id": "423",
        "subdistrict_name": "ท่าสะอาด"
    },
    {
        "subdistrict_id": "3403",
        "district_id": "423",
        "subdistrict_name": "หนองทุ่ม"
    },
    {
        "subdistrict_id": "3404",
        "district_id": "423",
        "subdistrict_name": "โสกก่าม"
    },
    {
        "subdistrict_id": "3405",
        "district_id": "424",
        "subdistrict_name": "ปากคาด"
    },
    {
        "subdistrict_id": "3406",
        "district_id": "424",
        "subdistrict_name": "หนองยอง"
    },
    {
        "subdistrict_id": "3407",
        "district_id": "424",
        "subdistrict_name": "นากั้ง"
    },
    {
        "subdistrict_id": "3408",
        "district_id": "424",
        "subdistrict_name": "โนนศิลา"
    },
    {
        "subdistrict_id": "3409",
        "district_id": "424",
        "subdistrict_name": "สมสนุก"
    },
    {
        "subdistrict_id": "3410",
        "district_id": "424",
        "subdistrict_name": "นาดง"
    },
    {
        "subdistrict_id": "3411",
        "district_id": "425",
        "subdistrict_name": "บึงโขงหลง"
    },
    {
        "subdistrict_id": "3412",
        "district_id": "425",
        "subdistrict_name": "โพธิ์หมากแข้ง"
    },
    {
        "subdistrict_id": "3413",
        "district_id": "425",
        "subdistrict_name": "ดงบัง"
    },
    {
        "subdistrict_id": "3414",
        "district_id": "425",
        "subdistrict_name": "ท่าดอกคำ"
    },
    {
        "subdistrict_id": "3415",
        "district_id": "426",
        "subdistrict_name": "ศรีวิไล"
    },
    {
        "subdistrict_id": "3416",
        "district_id": "426",
        "subdistrict_name": "ชุมภูพร"
    },
    {
        "subdistrict_id": "3417",
        "district_id": "426",
        "subdistrict_name": "นาแสง"
    },
    {
        "subdistrict_id": "3418",
        "district_id": "426",
        "subdistrict_name": "นาสะแบง"
    },
    {
        "subdistrict_id": "3419",
        "district_id": "426",
        "subdistrict_name": "นาสิงห์"
    },
    {
        "subdistrict_id": "3420",
        "district_id": "427",
        "subdistrict_name": "บุ่งคล้า"
    },
    {
        "subdistrict_id": "3421",
        "district_id": "427",
        "subdistrict_name": "หนองเดิ่น"
    },
    {
        "subdistrict_id": "3422",
        "district_id": "427",
        "subdistrict_name": "โคกกว้าง"
    },
    {
        "subdistrict_id": "3423",
        "district_id": "428",
        "subdistrict_name": "สระใคร"
    },
    {
        "subdistrict_id": "3424",
        "district_id": "428",
        "subdistrict_name": "คอกช้าง"
    },
    {
        "subdistrict_id": "3425",
        "district_id": "428",
        "subdistrict_name": "บ้านฝาง"
    },
    {
        "subdistrict_id": "3426",
        "district_id": "429",
        "subdistrict_name": "เฝ้าไร่"
    },
    {
        "subdistrict_id": "3427",
        "district_id": "429",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "3428",
        "district_id": "429",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "3429",
        "district_id": "429",
        "subdistrict_name": "วังหลวง"
    },
    {
        "subdistrict_id": "3430",
        "district_id": "429",
        "subdistrict_name": "อุดมพร"
    },
    {
        "subdistrict_id": "3431",
        "district_id": "430",
        "subdistrict_name": "รัตนวาปี"
    },
    {
        "subdistrict_id": "3432",
        "district_id": "430",
        "subdistrict_name": "นาทับไฮ"
    },
    {
        "subdistrict_id": "3433",
        "district_id": "430",
        "subdistrict_name": "บ้านต้อน"
    },
    {
        "subdistrict_id": "3434",
        "district_id": "430",
        "subdistrict_name": "พระบาทนาสิงห์"
    },
    {
        "subdistrict_id": "3435",
        "district_id": "430",
        "subdistrict_name": "โพนแพง"
    },
    {
        "subdistrict_id": "3436",
        "district_id": "431",
        "subdistrict_name": "โพธิ์ตาก"
    },
    {
        "subdistrict_id": "3437",
        "district_id": "431",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "3438",
        "district_id": "431",
        "subdistrict_name": "ด่านศรีสุข"
    },
    {
        "subdistrict_id": "3439",
        "district_id": "432",
        "subdistrict_name": "ตลาด"
    },
    {
        "subdistrict_id": "3440",
        "district_id": "432",
        "subdistrict_name": "เขวา"
    },
    {
        "subdistrict_id": "3441",
        "district_id": "432",
        "subdistrict_name": "ท่าตูม"
    },
    {
        "subdistrict_id": "3442",
        "district_id": "432",
        "subdistrict_name": "แวงน่าง"
    },
    {
        "subdistrict_id": "3443",
        "district_id": "432",
        "subdistrict_name": "โคกก่อ"
    },
    {
        "subdistrict_id": "3444",
        "district_id": "432",
        "subdistrict_name": "ดอนหว่าน"
    },
    {
        "subdistrict_id": "3445",
        "district_id": "432",
        "subdistrict_name": "เกิ้ง"
    },
    {
        "subdistrict_id": "3446",
        "district_id": "432",
        "subdistrict_name": "แก่งเลิงจาน"
    },
    {
        "subdistrict_id": "3447",
        "district_id": "432",
        "subdistrict_name": "ท่าสองคอน"
    },
    {
        "subdistrict_id": "3448",
        "district_id": "432",
        "subdistrict_name": "ลาดพัฒนา"
    },
    {
        "subdistrict_id": "3449",
        "district_id": "432",
        "subdistrict_name": "หนองปลิง"
    },
    {
        "subdistrict_id": "3450",
        "district_id": "432",
        "subdistrict_name": "ห้วยแอ่ง"
    },
    {
        "subdistrict_id": "3451",
        "district_id": "432",
        "subdistrict_name": "หนองโน"
    },
    {
        "subdistrict_id": "3452",
        "district_id": "432",
        "subdistrict_name": "บัวค้อ"
    },
    {
        "subdistrict_id": "3453",
        "district_id": "433",
        "subdistrict_name": "แกดำ"
    },
    {
        "subdistrict_id": "3454",
        "district_id": "433",
        "subdistrict_name": "วังแสง"
    },
    {
        "subdistrict_id": "3455",
        "district_id": "433",
        "subdistrict_name": "มิตรภาพ"
    },
    {
        "subdistrict_id": "3456",
        "district_id": "433",
        "subdistrict_name": "หนองกุง"
    },
    {
        "subdistrict_id": "3457",
        "district_id": "433",
        "subdistrict_name": "โนนภิบาล"
    },
    {
        "subdistrict_id": "3458",
        "district_id": "434",
        "subdistrict_name": "หัวขวาง"
    },
    {
        "subdistrict_id": "3459",
        "district_id": "434",
        "subdistrict_name": "ยางน้อย"
    },
    {
        "subdistrict_id": "3460",
        "district_id": "434",
        "subdistrict_name": "วังยาว"
    },
    {
        "subdistrict_id": "3461",
        "district_id": "434",
        "subdistrict_name": "เขวาไร่"
    },
    {
        "subdistrict_id": "3462",
        "district_id": "434",
        "subdistrict_name": "แพง"
    },
    {
        "subdistrict_id": "3463",
        "district_id": "434",
        "subdistrict_name": "แก้งแก"
    },
    {
        "subdistrict_id": "3464",
        "district_id": "434",
        "subdistrict_name": "หนองเหล็ก"
    },
    {
        "subdistrict_id": "3465",
        "district_id": "434",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "3466",
        "district_id": "434",
        "subdistrict_name": "เหล่า"
    },
    {
        "subdistrict_id": "3467",
        "district_id": "434",
        "subdistrict_name": "เขื่อน"
    },
    {
        "subdistrict_id": "3468",
        "district_id": "434",
        "subdistrict_name": "หนองบอน"
    },
    {
        "subdistrict_id": "3469",
        "district_id": "434",
        "subdistrict_name": "โพนงาม"
    },
    {
        "subdistrict_id": "3470",
        "district_id": "434",
        "subdistrict_name": "ยางท่าแจ้ง"
    },
    {
        "subdistrict_id": "3471",
        "district_id": "434",
        "subdistrict_name": "แห่ใต้"
    },
    {
        "subdistrict_id": "3472",
        "district_id": "434",
        "subdistrict_name": "หนองกุงสวรรค์"
    },
    {
        "subdistrict_id": "3473",
        "district_id": "434",
        "subdistrict_name": "เลิงใต้"
    },
    {
        "subdistrict_id": "3474",
        "district_id": "434",
        "subdistrict_name": "ดอนกลาง"
    },
    {
        "subdistrict_id": "3475",
        "district_id": "435",
        "subdistrict_name": "โคกพระ"
    },
    {
        "subdistrict_id": "3476",
        "district_id": "435",
        "subdistrict_name": "คันธารราษฎร์"
    },
    {
        "subdistrict_id": "3477",
        "district_id": "435",
        "subdistrict_name": "มะค่า"
    },
    {
        "subdistrict_id": "3478",
        "district_id": "435",
        "subdistrict_name": "ท่าขอนยาง"
    },
    {
        "subdistrict_id": "3479",
        "district_id": "435",
        "subdistrict_name": "นาสีนวน"
    },
    {
        "subdistrict_id": "3480",
        "district_id": "435",
        "subdistrict_name": "ขามเรียง"
    },
    {
        "subdistrict_id": "3481",
        "district_id": "435",
        "subdistrict_name": "เขวาใหญ่"
    },
    {
        "subdistrict_id": "3482",
        "district_id": "435",
        "subdistrict_name": "ศรีสุข"
    },
    {
        "subdistrict_id": "3483",
        "district_id": "435",
        "subdistrict_name": "กุดใส้จ่อ"
    },
    {
        "subdistrict_id": "3484",
        "district_id": "435",
        "subdistrict_name": "ขามเฒ่าพัฒนา"
    },
    {
        "subdistrict_id": "3485",
        "district_id": "436",
        "subdistrict_name": "เชียงยืน"
    },
    {
        "subdistrict_id": "3486",
        "district_id": "436",
        "subdistrict_name": "หนองซอน"
    },
    {
        "subdistrict_id": "3487",
        "district_id": "436",
        "subdistrict_name": "ดอนเงิน"
    },
    {
        "subdistrict_id": "3488",
        "district_id": "436",
        "subdistrict_name": "กู่ทอง"
    },
    {
        "subdistrict_id": "3489",
        "district_id": "436",
        "subdistrict_name": "นาทอง"
    },
    {
        "subdistrict_id": "3490",
        "district_id": "436",
        "subdistrict_name": "เสือเฒ่า"
    },
    {
        "subdistrict_id": "3491",
        "district_id": "436",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "3492",
        "district_id": "436",
        "subdistrict_name": "เหล่าบัวบาน"
    },
    {
        "subdistrict_id": "3493",
        "district_id": "437",
        "subdistrict_name": "บรบือ"
    },
    {
        "subdistrict_id": "3494",
        "district_id": "437",
        "subdistrict_name": "บ่อใหญ่"
    },
    {
        "subdistrict_id": "3495",
        "district_id": "437",
        "subdistrict_name": "วังไชย"
    },
    {
        "subdistrict_id": "3496",
        "district_id": "437",
        "subdistrict_name": "หนองม่วง"
    },
    {
        "subdistrict_id": "3497",
        "district_id": "437",
        "subdistrict_name": "กำพี้"
    },
    {
        "subdistrict_id": "3498",
        "district_id": "437",
        "subdistrict_name": "โนนราษี"
    },
    {
        "subdistrict_id": "3499",
        "district_id": "437",
        "subdistrict_name": "โนนแดง"
    },
    {
        "subdistrict_id": "3500",
        "district_id": "437",
        "subdistrict_name": "หนองจิก"
    },
    {
        "subdistrict_id": "3501",
        "district_id": "437",
        "subdistrict_name": "บัวมาศ"
    },
    {
        "subdistrict_id": "3502",
        "district_id": "437",
        "subdistrict_name": "หนองคูขาด"
    },
    {
        "subdistrict_id": "3503",
        "district_id": "437",
        "subdistrict_name": "วังใหม่"
    },
    {
        "subdistrict_id": "3504",
        "district_id": "437",
        "subdistrict_name": "ยาง"
    },
    {
        "subdistrict_id": "3505",
        "district_id": "437",
        "subdistrict_name": "หนองสิม"
    },
    {
        "subdistrict_id": "3506",
        "district_id": "437",
        "subdistrict_name": "หนองโก"
    },
    {
        "subdistrict_id": "3507",
        "district_id": "437",
        "subdistrict_name": "ดอนงัว"
    },
    {
        "subdistrict_id": "3508",
        "district_id": "438",
        "subdistrict_name": "นาเชือก"
    },
    {
        "subdistrict_id": "3509",
        "district_id": "438",
        "subdistrict_name": "สำโรง"
    },
    {
        "subdistrict_id": "3510",
        "district_id": "438",
        "subdistrict_name": "หนองแดง"
    },
    {
        "subdistrict_id": "3511",
        "district_id": "438",
        "subdistrict_name": "เขวาไร่"
    },
    {
        "subdistrict_id": "3512",
        "district_id": "438",
        "subdistrict_name": "หนองโพธิ์"
    },
    {
        "subdistrict_id": "3513",
        "district_id": "438",
        "subdistrict_name": "ปอพาน"
    },
    {
        "subdistrict_id": "3514",
        "district_id": "438",
        "subdistrict_name": "หนองเม็ก"
    },
    {
        "subdistrict_id": "3515",
        "district_id": "438",
        "subdistrict_name": "หนองเรือ"
    },
    {
        "subdistrict_id": "3516",
        "district_id": "438",
        "subdistrict_name": "หนองกุง"
    },
    {
        "subdistrict_id": "3517",
        "district_id": "438",
        "subdistrict_name": "สันป่าตอง"
    },
    {
        "subdistrict_id": "3518",
        "district_id": "439",
        "subdistrict_name": "ปะหลาน"
    },
    {
        "subdistrict_id": "3519",
        "district_id": "439",
        "subdistrict_name": "ก้ามปู"
    },
    {
        "subdistrict_id": "3520",
        "district_id": "439",
        "subdistrict_name": "เวียงสะอาด"
    },
    {
        "subdistrict_id": "3521",
        "district_id": "439",
        "subdistrict_name": "เม็กดำ"
    },
    {
        "subdistrict_id": "3522",
        "district_id": "439",
        "subdistrict_name": "นาสีนวล"
    },
    {
        "subdistrict_id": "3523",
        "district_id": "439",
        "subdistrict_name": "ราษฎร์เจริญ"
    },
    {
        "subdistrict_id": "3524",
        "district_id": "439",
        "subdistrict_name": "หนองบัวแก้ว"
    },
    {
        "subdistrict_id": "3525",
        "district_id": "439",
        "subdistrict_name": "เมืองเตา"
    },
    {
        "subdistrict_id": "3526",
        "district_id": "439",
        "subdistrict_name": "ลานสะแก"
    },
    {
        "subdistrict_id": "3527",
        "district_id": "439",
        "subdistrict_name": "เวียงชัย"
    },
    {
        "subdistrict_id": "3528",
        "district_id": "439",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "3529",
        "district_id": "439",
        "subdistrict_name": "ราษฎร์พัฒนา"
    },
    {
        "subdistrict_id": "3530",
        "district_id": "439",
        "subdistrict_name": "เมืองเสือ"
    },
    {
        "subdistrict_id": "3531",
        "district_id": "439",
        "subdistrict_name": "ภารแอ่น"
    },
    {
        "subdistrict_id": "3532",
        "district_id": "440",
        "subdistrict_name": "หนองแสง"
    },
    {
        "subdistrict_id": "3533",
        "district_id": "440",
        "subdistrict_name": "ขามป้อม"
    },
    {
        "subdistrict_id": "3534",
        "district_id": "440",
        "subdistrict_name": "เสือโก้ก"
    },
    {
        "subdistrict_id": "3535",
        "district_id": "440",
        "subdistrict_name": "ดงใหญ่"
    },
    {
        "subdistrict_id": "3536",
        "district_id": "440",
        "subdistrict_name": "โพธิ์ชัย"
    },
    {
        "subdistrict_id": "3537",
        "district_id": "440",
        "subdistrict_name": "หัวเรือ"
    },
    {
        "subdistrict_id": "3538",
        "district_id": "440",
        "subdistrict_name": "แคน"
    },
    {
        "subdistrict_id": "3539",
        "district_id": "440",
        "subdistrict_name": "งัวบา"
    },
    {
        "subdistrict_id": "3540",
        "district_id": "440",
        "subdistrict_name": "นาข่า"
    },
    {
        "subdistrict_id": "3541",
        "district_id": "440",
        "subdistrict_name": "บ้านหวาย"
    },
    {
        "subdistrict_id": "3542",
        "district_id": "440",
        "subdistrict_name": "หนองไฮ"
    },
    {
        "subdistrict_id": "3543",
        "district_id": "440",
        "subdistrict_name": "ประชาพัฒนา"
    },
    {
        "subdistrict_id": "3544",
        "district_id": "440",
        "subdistrict_name": "หนองทุ่ม"
    },
    {
        "subdistrict_id": "3545",
        "district_id": "440",
        "subdistrict_name": "หนองแสน"
    },
    {
        "subdistrict_id": "3546",
        "district_id": "440",
        "subdistrict_name": "โคกสีทองหลาง"
    },
    {
        "subdistrict_id": "3547",
        "district_id": "441",
        "subdistrict_name": "นาดูน"
    },
    {
        "subdistrict_id": "3548",
        "district_id": "441",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "3549",
        "district_id": "441",
        "subdistrict_name": "หนองคู"
    },
    {
        "subdistrict_id": "3550",
        "district_id": "441",
        "subdistrict_name": "ดงบัง"
    },
    {
        "subdistrict_id": "3551",
        "district_id": "441",
        "subdistrict_name": "ดงดวน"
    },
    {
        "subdistrict_id": "3552",
        "district_id": "441",
        "subdistrict_name": "หัวดง"
    },
    {
        "subdistrict_id": "3553",
        "district_id": "441",
        "subdistrict_name": "ดงยาง"
    },
    {
        "subdistrict_id": "3554",
        "district_id": "441",
        "subdistrict_name": "กู่สันตรัตน์"
    },
    {
        "subdistrict_id": "3555",
        "district_id": "441",
        "subdistrict_name": "พระธาตุ"
    },
    {
        "subdistrict_id": "3556",
        "district_id": "442",
        "subdistrict_name": "ยางสีสุราช"
    },
    {
        "subdistrict_id": "3557",
        "district_id": "442",
        "subdistrict_name": "นาภู"
    },
    {
        "subdistrict_id": "3558",
        "district_id": "442",
        "subdistrict_name": "แวงดง"
    },
    {
        "subdistrict_id": "3559",
        "district_id": "442",
        "subdistrict_name": "บ้านกู่"
    },
    {
        "subdistrict_id": "3560",
        "district_id": "442",
        "subdistrict_name": "ดงเมือง"
    },
    {
        "subdistrict_id": "3561",
        "district_id": "442",
        "subdistrict_name": "ขามเรียน"
    },
    {
        "subdistrict_id": "3562",
        "district_id": "442",
        "subdistrict_name": "หนองบัวสันตุ"
    },
    {
        "subdistrict_id": "3563",
        "district_id": "443",
        "subdistrict_name": "กุดรัง"
    },
    {
        "subdistrict_id": "3564",
        "district_id": "443",
        "subdistrict_name": "นาโพธิ์"
    },
    {
        "subdistrict_id": "3565",
        "district_id": "443",
        "subdistrict_name": "เลิงแฝก"
    },
    {
        "subdistrict_id": "3566",
        "district_id": "443",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "3567",
        "district_id": "443",
        "subdistrict_name": "ห้วยเตย"
    },
    {
        "subdistrict_id": "3568",
        "district_id": "444",
        "subdistrict_name": "ชื่นชม"
    },
    {
        "subdistrict_id": "3569",
        "district_id": "444",
        "subdistrict_name": "กุดปลาดุก"
    },
    {
        "subdistrict_id": "3570",
        "district_id": "444",
        "subdistrict_name": "เหล่าดอกไม้"
    },
    {
        "subdistrict_id": "3571",
        "district_id": "444",
        "subdistrict_name": "หนองกุง"
    },
    {
        "subdistrict_id": "3572",
        "district_id": "445",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "3573",
        "district_id": "445",
        "subdistrict_name": "รอบเมือง"
    },
    {
        "subdistrict_id": "3574",
        "district_id": "445",
        "subdistrict_name": "เหนือเมือง"
    },
    {
        "subdistrict_id": "3575",
        "district_id": "445",
        "subdistrict_name": "ขอนแก่น"
    },
    {
        "subdistrict_id": "3576",
        "district_id": "445",
        "subdistrict_name": "นาโพธิ์"
    },
    {
        "subdistrict_id": "3577",
        "district_id": "445",
        "subdistrict_name": "สะอาดสมบูรณ์"
    },
    {
        "subdistrict_id": "3578",
        "district_id": "445",
        "subdistrict_name": "สีแก้ว"
    },
    {
        "subdistrict_id": "3579",
        "district_id": "445",
        "subdistrict_name": "ปอภาร  (ปอพาน)"
    },
    {
        "subdistrict_id": "3580",
        "district_id": "445",
        "subdistrict_name": "โนนรัง"
    },
    {
        "subdistrict_id": "3581",
        "district_id": "445",
        "subdistrict_name": "หนองแก้ว"
    },
    {
        "subdistrict_id": "3582",
        "district_id": "445",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "3583",
        "district_id": "445",
        "subdistrict_name": "ดงลาน"
    },
    {
        "subdistrict_id": "3584",
        "district_id": "445",
        "subdistrict_name": "แคนใหญ่"
    },
    {
        "subdistrict_id": "3585",
        "district_id": "445",
        "subdistrict_name": "โนนตาล"
    },
    {
        "subdistrict_id": "3586",
        "district_id": "445",
        "subdistrict_name": "เมืองทอง"
    },
    {
        "subdistrict_id": "3587",
        "district_id": "446",
        "subdistrict_name": "เกษตรวิสัย"
    },
    {
        "subdistrict_id": "3588",
        "district_id": "446",
        "subdistrict_name": "เมืองบัว"
    },
    {
        "subdistrict_id": "3589",
        "district_id": "446",
        "subdistrict_name": "เหล่าหลวง"
    },
    {
        "subdistrict_id": "3590",
        "district_id": "446",
        "subdistrict_name": "สิงห์โคก"
    },
    {
        "subdistrict_id": "3591",
        "district_id": "446",
        "subdistrict_name": "ดงครั่งใหญ่"
    },
    {
        "subdistrict_id": "3592",
        "district_id": "446",
        "subdistrict_name": "บ้านฝาง"
    },
    {
        "subdistrict_id": "3593",
        "district_id": "446",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "3594",
        "district_id": "446",
        "subdistrict_name": "กำแพง"
    },
    {
        "subdistrict_id": "3595",
        "district_id": "446",
        "subdistrict_name": "กู่กาสิงห์"
    },
    {
        "subdistrict_id": "3596",
        "district_id": "446",
        "subdistrict_name": "น้ำอ้อม"
    },
    {
        "subdistrict_id": "3597",
        "district_id": "446",
        "subdistrict_name": "โนนสว่าง"
    },
    {
        "subdistrict_id": "3598",
        "district_id": "446",
        "subdistrict_name": "ทุ่งทอง"
    },
    {
        "subdistrict_id": "3599",
        "district_id": "446",
        "subdistrict_name": "ดงครั่งน้อย"
    },
    {
        "subdistrict_id": "3600",
        "district_id": "447",
        "subdistrict_name": "บัวแดง"
    },
    {
        "subdistrict_id": "3601",
        "district_id": "447",
        "subdistrict_name": "ดอกล้ำ"
    },
    {
        "subdistrict_id": "3602",
        "district_id": "447",
        "subdistrict_name": "หนองแคน"
    },
    {
        "subdistrict_id": "3603",
        "district_id": "447",
        "subdistrict_name": "โพนสูง"
    },
    {
        "subdistrict_id": "3604",
        "district_id": "447",
        "subdistrict_name": "โนนสวรรค์"
    },
    {
        "subdistrict_id": "3605",
        "district_id": "447",
        "subdistrict_name": "สระบัว"
    },
    {
        "subdistrict_id": "3606",
        "district_id": "447",
        "subdistrict_name": "โนนสง่า"
    },
    {
        "subdistrict_id": "3607",
        "district_id": "447",
        "subdistrict_name": "ขี้เหล็ก"
    },
    {
        "subdistrict_id": "3608",
        "district_id": "448",
        "subdistrict_name": "หัวช้าง"
    },
    {
        "subdistrict_id": "3609",
        "district_id": "448",
        "subdistrict_name": "หนองผือ"
    },
    {
        "subdistrict_id": "3610",
        "district_id": "448",
        "subdistrict_name": "เมืองหงส์"
    },
    {
        "subdistrict_id": "3611",
        "district_id": "448",
        "subdistrict_name": "โคกล่าม"
    },
    {
        "subdistrict_id": "3612",
        "district_id": "448",
        "subdistrict_name": "น้ำใส"
    },
    {
        "subdistrict_id": "3613",
        "district_id": "448",
        "subdistrict_name": "ดงแดง"
    },
    {
        "subdistrict_id": "3614",
        "district_id": "448",
        "subdistrict_name": "ดงกลาง"
    },
    {
        "subdistrict_id": "3615",
        "district_id": "448",
        "subdistrict_name": "ป่าสังข์"
    },
    {
        "subdistrict_id": "3616",
        "district_id": "448",
        "subdistrict_name": "อีง่อง"
    },
    {
        "subdistrict_id": "3617",
        "district_id": "448",
        "subdistrict_name": "ลิ้นฟ้า"
    },
    {
        "subdistrict_id": "3618",
        "district_id": "448",
        "subdistrict_name": "ดู่น้อย"
    },
    {
        "subdistrict_id": "3619",
        "district_id": "448",
        "subdistrict_name": "ศรีโคตร"
    },
    {
        "subdistrict_id": "3620",
        "district_id": "449",
        "subdistrict_name": "นิเวศน์"
    },
    {
        "subdistrict_id": "3621",
        "district_id": "449",
        "subdistrict_name": "ธงธานี"
    },
    {
        "subdistrict_id": "3622",
        "district_id": "449",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "3623",
        "district_id": "449",
        "subdistrict_name": "ธวัชบุรี"
    },
    {
        "subdistrict_id": "3624",
        "district_id": "449",
        "subdistrict_name": "อุ่มเม้า"
    },
    {
        "subdistrict_id": "3625",
        "district_id": "449",
        "subdistrict_name": "มะอึ"
    },
    {
        "subdistrict_id": "3626",
        "district_id": "449",
        "subdistrict_name": "เขวาทุ่ง"
    },
    {
        "subdistrict_id": "3627",
        "district_id": "449",
        "subdistrict_name": "ไพศาล"
    },
    {
        "subdistrict_id": "3628",
        "district_id": "449",
        "subdistrict_name": "เมืองน้อย"
    },
    {
        "subdistrict_id": "3629",
        "district_id": "449",
        "subdistrict_name": "บึงนคร"
    },
    {
        "subdistrict_id": "3630",
        "district_id": "449",
        "subdistrict_name": "ราชธานี"
    },
    {
        "subdistrict_id": "3631",
        "district_id": "449",
        "subdistrict_name": "หนองพอก"
    },
    {
        "subdistrict_id": "3632",
        "district_id": "450",
        "subdistrict_name": "พนมไพร"
    },
    {
        "subdistrict_id": "3633",
        "district_id": "450",
        "subdistrict_name": "แสนสุข"
    },
    {
        "subdistrict_id": "3634",
        "district_id": "450",
        "subdistrict_name": "กุดน้ำใส"
    },
    {
        "subdistrict_id": "3635",
        "district_id": "450",
        "subdistrict_name": "หนองทัพไทย"
    },
    {
        "subdistrict_id": "3636",
        "district_id": "450",
        "subdistrict_name": "โพธิ์ใหญ่"
    },
    {
        "subdistrict_id": "3637",
        "district_id": "450",
        "subdistrict_name": "วารีสวัสดิ์"
    },
    {
        "subdistrict_id": "3638",
        "district_id": "450",
        "subdistrict_name": "โคกสว่าง"
    },
    {
        "subdistrict_id": "3639",
        "district_id": "450",
        "subdistrict_name": "โพธิ์ชัย"
    },
    {
        "subdistrict_id": "3640",
        "district_id": "450",
        "subdistrict_name": "นานวล"
    },
    {
        "subdistrict_id": "3641",
        "district_id": "450",
        "subdistrict_name": "คำไฮ"
    },
    {
        "subdistrict_id": "3642",
        "district_id": "450",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "3643",
        "district_id": "450",
        "subdistrict_name": "ค้อใหญ่"
    },
    {
        "subdistrict_id": "3644",
        "district_id": "450",
        "subdistrict_name": "ชานุวรรณ"
    },
    {
        "subdistrict_id": "3645",
        "district_id": "451",
        "subdistrict_name": "แวง"
    },
    {
        "subdistrict_id": "3646",
        "district_id": "451",
        "subdistrict_name": "โคกกกม่วง"
    },
    {
        "subdistrict_id": "3647",
        "district_id": "451",
        "subdistrict_name": "นาอุดม"
    },
    {
        "subdistrict_id": "3648",
        "district_id": "451",
        "subdistrict_name": "สว่าง"
    },
    {
        "subdistrict_id": "3649",
        "district_id": "451",
        "subdistrict_name": "หนองใหญ่"
    },
    {
        "subdistrict_id": "3650",
        "district_id": "451",
        "subdistrict_name": "โพธิ์ทอง"
    },
    {
        "subdistrict_id": "3651",
        "district_id": "451",
        "subdistrict_name": "โนนชัยศรี"
    },
    {
        "subdistrict_id": "3652",
        "district_id": "451",
        "subdistrict_name": "โพธิ์ศรีสว่าง"
    },
    {
        "subdistrict_id": "3653",
        "district_id": "451",
        "subdistrict_name": "อุ่มเม่า"
    },
    {
        "subdistrict_id": "3654",
        "district_id": "451",
        "subdistrict_name": "คำนาดี"
    },
    {
        "subdistrict_id": "3655",
        "district_id": "451",
        "subdistrict_name": "พรมสวรรค์"
    },
    {
        "subdistrict_id": "3656",
        "district_id": "451",
        "subdistrict_name": "สระนกแก้ว"
    },
    {
        "subdistrict_id": "3657",
        "district_id": "451",
        "subdistrict_name": "วังสามัคคี"
    },
    {
        "subdistrict_id": "3658",
        "district_id": "451",
        "subdistrict_name": "โคกสูง"
    },
    {
        "subdistrict_id": "3659",
        "district_id": "452",
        "subdistrict_name": "ขามเปี้ย"
    },
    {
        "subdistrict_id": "3660",
        "district_id": "452",
        "subdistrict_name": "เชียงใหม่"
    },
    {
        "subdistrict_id": "3661",
        "district_id": "452",
        "subdistrict_name": "บัวคำ"
    },
    {
        "subdistrict_id": "3662",
        "district_id": "452",
        "subdistrict_name": "อัคคะคำ"
    },
    {
        "subdistrict_id": "3663",
        "district_id": "452",
        "subdistrict_name": "สะอาด"
    },
    {
        "subdistrict_id": "3664",
        "district_id": "452",
        "subdistrict_name": "คำพอุง"
    },
    {
        "subdistrict_id": "3665",
        "district_id": "452",
        "subdistrict_name": "หนองตาไก้"
    },
    {
        "subdistrict_id": "3666",
        "district_id": "452",
        "subdistrict_name": "ดอนโอง"
    },
    {
        "subdistrict_id": "3667",
        "district_id": "452",
        "subdistrict_name": "โพธิ์ศรี"
    },
    {
        "subdistrict_id": "3668",
        "district_id": "453",
        "subdistrict_name": "หนองพอก"
    },
    {
        "subdistrict_id": "3669",
        "district_id": "453",
        "subdistrict_name": "บึงงาม"
    },
    {
        "subdistrict_id": "3670",
        "district_id": "453",
        "subdistrict_name": "ภูเขาทอง"
    },
    {
        "subdistrict_id": "3671",
        "district_id": "453",
        "subdistrict_name": "กกโพธิ์"
    },
    {
        "subdistrict_id": "3672",
        "district_id": "453",
        "subdistrict_name": "โคกสว่าง"
    },
    {
        "subdistrict_id": "3673",
        "district_id": "453",
        "subdistrict_name": "หนองขุ่นใหญ่"
    },
    {
        "subdistrict_id": "3674",
        "district_id": "453",
        "subdistrict_name": "รอบเมือง"
    },
    {
        "subdistrict_id": "3675",
        "district_id": "453",
        "subdistrict_name": "ผาน้ำย้อย"
    },
    {
        "subdistrict_id": "3676",
        "district_id": "453",
        "subdistrict_name": "ท่าสีดา"
    },
    {
        "subdistrict_id": "3677",
        "district_id": "454",
        "subdistrict_name": "กลาง"
    },
    {
        "subdistrict_id": "3678",
        "district_id": "454",
        "subdistrict_name": "นางาม"
    },
    {
        "subdistrict_id": "3679",
        "district_id": "454",
        "subdistrict_name": "เมืองไพร"
    },
    {
        "subdistrict_id": "3680",
        "district_id": "454",
        "subdistrict_name": "นาแซง"
    },
    {
        "subdistrict_id": "3681",
        "district_id": "454",
        "subdistrict_name": "นาเมือง"
    },
    {
        "subdistrict_id": "3682",
        "district_id": "454",
        "subdistrict_name": "วังหลวง"
    },
    {
        "subdistrict_id": "3683",
        "district_id": "454",
        "subdistrict_name": "ท่าม่วง"
    },
    {
        "subdistrict_id": "3684",
        "district_id": "454",
        "subdistrict_name": "ขวาว"
    },
    {
        "subdistrict_id": "3685",
        "district_id": "454",
        "subdistrict_name": "โพธิ์ทอง"
    },
    {
        "subdistrict_id": "3686",
        "district_id": "454",
        "subdistrict_name": "ภูเงิน"
    },
    {
        "subdistrict_id": "3687",
        "district_id": "454",
        "subdistrict_name": "เกาะแก้ว"
    },
    {
        "subdistrict_id": "3688",
        "district_id": "454",
        "subdistrict_name": "นาเลิง"
    },
    {
        "subdistrict_id": "3689",
        "district_id": "454",
        "subdistrict_name": "เหล่าน้อย"
    },
    {
        "subdistrict_id": "3690",
        "district_id": "454",
        "subdistrict_name": "ศรีวิลัย"
    },
    {
        "subdistrict_id": "3691",
        "district_id": "454",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "3692",
        "district_id": "454",
        "subdistrict_name": "พรสวรรค์"
    },
    {
        "subdistrict_id": "3693",
        "district_id": "454",
        "subdistrict_name": "ขวัญเมือง"
    },
    {
        "subdistrict_id": "3694",
        "district_id": "454",
        "subdistrict_name": "บึงเกลือ"
    },
    {
        "subdistrict_id": "3695",
        "district_id": "455",
        "subdistrict_name": "สระคู"
    },
    {
        "subdistrict_id": "3696",
        "district_id": "455",
        "subdistrict_name": "ดอกไม้"
    },
    {
        "subdistrict_id": "3697",
        "district_id": "455",
        "subdistrict_name": "นาใหญ่"
    },
    {
        "subdistrict_id": "3698",
        "district_id": "455",
        "subdistrict_name": "หินกอง"
    },
    {
        "subdistrict_id": "3699",
        "district_id": "455",
        "subdistrict_name": "เมืองทุ่ง"
    },
    {
        "subdistrict_id": "3700",
        "district_id": "455",
        "subdistrict_name": "หัวโทน"
    },
    {
        "subdistrict_id": "3701",
        "district_id": "455",
        "subdistrict_name": "บ่อพันขัน"
    },
    {
        "subdistrict_id": "3702",
        "district_id": "455",
        "subdistrict_name": "ทุ่งหลวง"
    },
    {
        "subdistrict_id": "3703",
        "district_id": "455",
        "subdistrict_name": "หัวช้าง"
    },
    {
        "subdistrict_id": "3704",
        "district_id": "455",
        "subdistrict_name": "น้ำคำ"
    },
    {
        "subdistrict_id": "3705",
        "district_id": "455",
        "subdistrict_name": "ห้วยหินลาด"
    },
    {
        "subdistrict_id": "3706",
        "district_id": "455",
        "subdistrict_name": "ช้างเผือก"
    },
    {
        "subdistrict_id": "3707",
        "district_id": "455",
        "subdistrict_name": "ทุ่งกุลา"
    },
    {
        "subdistrict_id": "3708",
        "district_id": "455",
        "subdistrict_name": "ทุ่งศรีเมือง"
    },
    {
        "subdistrict_id": "3709",
        "district_id": "455",
        "subdistrict_name": "จำปาขัน"
    },
    {
        "subdistrict_id": "3710",
        "district_id": "456",
        "subdistrict_name": "หนองผือ"
    },
    {
        "subdistrict_id": "3711",
        "district_id": "456",
        "subdistrict_name": "หนองหิน"
    },
    {
        "subdistrict_id": "3712",
        "district_id": "456",
        "subdistrict_name": "คูเมือง"
    },
    {
        "subdistrict_id": "3713",
        "district_id": "456",
        "subdistrict_name": "กกกุง"
    },
    {
        "subdistrict_id": "3714",
        "district_id": "456",
        "subdistrict_name": "เมืองสรวง"
    },
    {
        "subdistrict_id": "3715",
        "district_id": "457",
        "subdistrict_name": "โพนทราย"
    },
    {
        "subdistrict_id": "3716",
        "district_id": "457",
        "subdistrict_name": "สามขา"
    },
    {
        "subdistrict_id": "3717",
        "district_id": "457",
        "subdistrict_name": "ศรีสว่าง"
    },
    {
        "subdistrict_id": "3718",
        "district_id": "457",
        "subdistrict_name": "ยางคำ"
    },
    {
        "subdistrict_id": "3719",
        "district_id": "457",
        "subdistrict_name": "ท่าหาดยาว"
    },
    {
        "subdistrict_id": "3720",
        "district_id": "458",
        "subdistrict_name": "อาจสามารถ"
    },
    {
        "subdistrict_id": "3721",
        "district_id": "458",
        "subdistrict_name": "โพนเมือง"
    },
    {
        "subdistrict_id": "3722",
        "district_id": "458",
        "subdistrict_name": "บ้านแจ้ง"
    },
    {
        "subdistrict_id": "3723",
        "district_id": "458",
        "subdistrict_name": "หน่อม"
    },
    {
        "subdistrict_id": "3724",
        "district_id": "458",
        "subdistrict_name": "หนองหมื่นถ่าน"
    },
    {
        "subdistrict_id": "3725",
        "district_id": "458",
        "subdistrict_name": "หนองขาม"
    },
    {
        "subdistrict_id": "3726",
        "district_id": "458",
        "subdistrict_name": "โหรา"
    },
    {
        "subdistrict_id": "3727",
        "district_id": "458",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "3728",
        "district_id": "458",
        "subdistrict_name": "ขี้เหล็ก"
    },
    {
        "subdistrict_id": "3729",
        "district_id": "458",
        "subdistrict_name": "บ้านดู่"
    },
    {
        "subdistrict_id": "3730",
        "district_id": "459",
        "subdistrict_name": "เมยวดี"
    },
    {
        "subdistrict_id": "3731",
        "district_id": "459",
        "subdistrict_name": "ชุมพร"
    },
    {
        "subdistrict_id": "3732",
        "district_id": "459",
        "subdistrict_name": "บุ่งเลิศ"
    },
    {
        "subdistrict_id": "3733",
        "district_id": "459",
        "subdistrict_name": "ชมสะอาด"
    },
    {
        "subdistrict_id": "3734",
        "district_id": "460",
        "subdistrict_name": "โพธิ์ทอง"
    },
    {
        "subdistrict_id": "3735",
        "district_id": "460",
        "subdistrict_name": "ศรีสมเด็จ"
    },
    {
        "subdistrict_id": "3736",
        "district_id": "460",
        "subdistrict_name": "เมืองเปลือย"
    },
    {
        "subdistrict_id": "3737",
        "district_id": "460",
        "subdistrict_name": "หนองใหญ่"
    },
    {
        "subdistrict_id": "3738",
        "district_id": "460",
        "subdistrict_name": "สวนจิก"
    },
    {
        "subdistrict_id": "3739",
        "district_id": "460",
        "subdistrict_name": "โพธิ์สัย"
    },
    {
        "subdistrict_id": "3740",
        "district_id": "460",
        "subdistrict_name": "หนองแวงควง"
    },
    {
        "subdistrict_id": "3741",
        "district_id": "460",
        "subdistrict_name": "บ้านบาก"
    },
    {
        "subdistrict_id": "3742",
        "district_id": "461",
        "subdistrict_name": "ดินดำ"
    },
    {
        "subdistrict_id": "3743",
        "district_id": "461",
        "subdistrict_name": "ปาฝา"
    },
    {
        "subdistrict_id": "3744",
        "district_id": "461",
        "subdistrict_name": "ม่วงลาด"
    },
    {
        "subdistrict_id": "3745",
        "district_id": "461",
        "subdistrict_name": "จังหาร"
    },
    {
        "subdistrict_id": "3746",
        "district_id": "461",
        "subdistrict_name": "ดงสิงห์"
    },
    {
        "subdistrict_id": "3747",
        "district_id": "461",
        "subdistrict_name": "ยางใหญ่"
    },
    {
        "subdistrict_id": "3748",
        "district_id": "461",
        "subdistrict_name": "ผักแว่น"
    },
    {
        "subdistrict_id": "3749",
        "district_id": "461",
        "subdistrict_name": "แสนชาติ"
    },
    {
        "subdistrict_id": "3750",
        "district_id": "462",
        "subdistrict_name": "เชียงขวัญ"
    },
    {
        "subdistrict_id": "3751",
        "district_id": "462",
        "subdistrict_name": "พลับพลา"
    },
    {
        "subdistrict_id": "3752",
        "district_id": "462",
        "subdistrict_name": "พระธาตุ"
    },
    {
        "subdistrict_id": "3753",
        "district_id": "462",
        "subdistrict_name": "พระเจ้า"
    },
    {
        "subdistrict_id": "3754",
        "district_id": "462",
        "subdistrict_name": "หมูม้น"
    },
    {
        "subdistrict_id": "3755",
        "district_id": "462",
        "subdistrict_name": "บ้านเขือง"
    },
    {
        "subdistrict_id": "3756",
        "district_id": "463",
        "subdistrict_name": "หนองฮี"
    },
    {
        "subdistrict_id": "3757",
        "district_id": "463",
        "subdistrict_name": "สาวแห"
    },
    {
        "subdistrict_id": "3758",
        "district_id": "463",
        "subdistrict_name": "ดูกอึ่ง"
    },
    {
        "subdistrict_id": "3759",
        "district_id": "463",
        "subdistrict_name": "เด่นราษฎร์"
    },
    {
        "subdistrict_id": "3760",
        "district_id": "464",
        "subdistrict_name": "ทุ่งเขาหลวง"
    },
    {
        "subdistrict_id": "3761",
        "district_id": "464",
        "subdistrict_name": "เทอดไทย"
    },
    {
        "subdistrict_id": "3762",
        "district_id": "464",
        "subdistrict_name": "บึงงาม"
    },
    {
        "subdistrict_id": "3763",
        "district_id": "464",
        "subdistrict_name": "มะบ้า"
    },
    {
        "subdistrict_id": "3764",
        "district_id": "464",
        "subdistrict_name": "เหล่า"
    },
    {
        "subdistrict_id": "3765",
        "district_id": "465",
        "subdistrict_name": "กาฬสินธุ์"
    },
    {
        "subdistrict_id": "3766",
        "district_id": "465",
        "subdistrict_name": "เหนือ"
    },
    {
        "subdistrict_id": "3767",
        "district_id": "465",
        "subdistrict_name": "หลุบ"
    },
    {
        "subdistrict_id": "3768",
        "district_id": "465",
        "subdistrict_name": "ไผ่"
    },
    {
        "subdistrict_id": "3769",
        "district_id": "465",
        "subdistrict_name": "ลำปาว"
    },
    {
        "subdistrict_id": "3770",
        "district_id": "465",
        "subdistrict_name": "ลำพาน"
    },
    {
        "subdistrict_id": "3771",
        "district_id": "465",
        "subdistrict_name": "เชียงเครือ"
    },
    {
        "subdistrict_id": "3772",
        "district_id": "465",
        "subdistrict_name": "บึงวิชัย"
    },
    {
        "subdistrict_id": "3773",
        "district_id": "465",
        "subdistrict_name": "ห้วยโพธิ์"
    },
    {
        "subdistrict_id": "3774",
        "district_id": "465",
        "subdistrict_name": "ภูปอ"
    },
    {
        "subdistrict_id": "3775",
        "district_id": "465",
        "subdistrict_name": "ภูดิน"
    },
    {
        "subdistrict_id": "3776",
        "district_id": "465",
        "subdistrict_name": "หนองกุง"
    },
    {
        "subdistrict_id": "3777",
        "district_id": "465",
        "subdistrict_name": "กลางหมื่น"
    },
    {
        "subdistrict_id": "3778",
        "district_id": "465",
        "subdistrict_name": "ขมิ้น"
    },
    {
        "subdistrict_id": "3779",
        "district_id": "465",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "3780",
        "district_id": "465",
        "subdistrict_name": "นาจารย์"
    },
    {
        "subdistrict_id": "3781",
        "district_id": "465",
        "subdistrict_name": "ลำคลอง"
    },
    {
        "subdistrict_id": "3782",
        "district_id": "466",
        "subdistrict_name": "นามน"
    },
    {
        "subdistrict_id": "3783",
        "district_id": "466",
        "subdistrict_name": "ยอดแกง"
    },
    {
        "subdistrict_id": "3784",
        "district_id": "466",
        "subdistrict_name": "สงเปลือย"
    },
    {
        "subdistrict_id": "3785",
        "district_id": "466",
        "subdistrict_name": "หลักเหลี่ยม"
    },
    {
        "subdistrict_id": "3786",
        "district_id": "466",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "3787",
        "district_id": "467",
        "subdistrict_name": "กมลาไสย"
    },
    {
        "subdistrict_id": "3788",
        "district_id": "467",
        "subdistrict_name": "หลักเมือง"
    },
    {
        "subdistrict_id": "3789",
        "district_id": "467",
        "subdistrict_name": "โพนงาม"
    },
    {
        "subdistrict_id": "3790",
        "district_id": "467",
        "subdistrict_name": "ดงลิง"
    },
    {
        "subdistrict_id": "3791",
        "district_id": "467",
        "subdistrict_name": "ธัญญา"
    },
    {
        "subdistrict_id": "3792",
        "district_id": "467",
        "subdistrict_name": "หนองแปน"
    },
    {
        "subdistrict_id": "3793",
        "district_id": "467",
        "subdistrict_name": "เจ้าท่า"
    },
    {
        "subdistrict_id": "3794",
        "district_id": "467",
        "subdistrict_name": "โคกสมบูรณ์"
    },
    {
        "subdistrict_id": "3795",
        "district_id": "468",
        "subdistrict_name": "ร่องคำ"
    },
    {
        "subdistrict_id": "3796",
        "district_id": "468",
        "subdistrict_name": "สามัคคี"
    },
    {
        "subdistrict_id": "3797",
        "district_id": "468",
        "subdistrict_name": "เหล่าอ้อย"
    },
    {
        "subdistrict_id": "3798",
        "district_id": "469",
        "subdistrict_name": "บัวขาว"
    },
    {
        "subdistrict_id": "3799",
        "district_id": "469",
        "subdistrict_name": "แจนแลน"
    },
    {
        "subdistrict_id": "3800",
        "district_id": "469",
        "subdistrict_name": "เหล่าใหญ่"
    },
    {
        "subdistrict_id": "3801",
        "district_id": "469",
        "subdistrict_name": "จุมจัง"
    },
    {
        "subdistrict_id": "3802",
        "district_id": "469",
        "subdistrict_name": "เหล่าไฮงาม"
    },
    {
        "subdistrict_id": "3803",
        "district_id": "469",
        "subdistrict_name": "กุดหว้า"
    },
    {
        "subdistrict_id": "3804",
        "district_id": "469",
        "subdistrict_name": "สามขา"
    },
    {
        "subdistrict_id": "3805",
        "district_id": "469",
        "subdistrict_name": "นาขาม"
    },
    {
        "subdistrict_id": "3806",
        "district_id": "469",
        "subdistrict_name": "หนองห้าง"
    },
    {
        "subdistrict_id": "3807",
        "district_id": "469",
        "subdistrict_name": "นาโก"
    },
    {
        "subdistrict_id": "3808",
        "district_id": "469",
        "subdistrict_name": "สมสะอาด"
    },
    {
        "subdistrict_id": "3809",
        "district_id": "469",
        "subdistrict_name": "กุดค้าว"
    },
    {
        "subdistrict_id": "3810",
        "district_id": "470",
        "subdistrict_name": "คุ้มเก่า"
    },
    {
        "subdistrict_id": "3811",
        "district_id": "470",
        "subdistrict_name": "สงเปลือย"
    },
    {
        "subdistrict_id": "3812",
        "district_id": "470",
        "subdistrict_name": "หนองผือ"
    },
    {
        "subdistrict_id": "3813",
        "district_id": "470",
        "subdistrict_name": "กุดสิมคุ้มใหม่"
    },
    {
        "subdistrict_id": "3814",
        "district_id": "470",
        "subdistrict_name": "สระพังทอง"
    },
    {
        "subdistrict_id": "3815",
        "district_id": "470",
        "subdistrict_name": "กุดปลาค้าว"
    },
    {
        "subdistrict_id": "3816",
        "district_id": "471",
        "subdistrict_name": "ยางตลาด"
    },
    {
        "subdistrict_id": "3817",
        "district_id": "471",
        "subdistrict_name": "หัวงัว"
    },
    {
        "subdistrict_id": "3818",
        "district_id": "471",
        "subdistrict_name": "อุ่มเม่า"
    },
    {
        "subdistrict_id": "3819",
        "district_id": "471",
        "subdistrict_name": "บัวบาน"
    },
    {
        "subdistrict_id": "3820",
        "district_id": "471",
        "subdistrict_name": "เว่อ"
    },
    {
        "subdistrict_id": "3821",
        "district_id": "471",
        "subdistrict_name": "อิตื้อ"
    },
    {
        "subdistrict_id": "3822",
        "district_id": "471",
        "subdistrict_name": "หัวนาคำ"
    },
    {
        "subdistrict_id": "3823",
        "district_id": "471",
        "subdistrict_name": "หนองอิเฒ่า"
    },
    {
        "subdistrict_id": "3824",
        "district_id": "471",
        "subdistrict_name": "ดอนสมบูรณ์"
    },
    {
        "subdistrict_id": "3825",
        "district_id": "471",
        "subdistrict_name": "นาเชือก"
    },
    {
        "subdistrict_id": "3826",
        "district_id": "471",
        "subdistrict_name": "คลองขาม"
    },
    {
        "subdistrict_id": "3827",
        "district_id": "471",
        "subdistrict_name": "เขาพระนอน"
    },
    {
        "subdistrict_id": "3828",
        "district_id": "471",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "3829",
        "district_id": "471",
        "subdistrict_name": "โนนสูง"
    },
    {
        "subdistrict_id": "3830",
        "district_id": "471",
        "subdistrict_name": "หนองตอกแป้น"
    },
    {
        "subdistrict_id": "3831",
        "district_id": "472",
        "subdistrict_name": "ห้วยเม็ก"
    },
    {
        "subdistrict_id": "3832",
        "district_id": "472",
        "subdistrict_name": "คำใหญ่"
    },
    {
        "subdistrict_id": "3833",
        "district_id": "472",
        "subdistrict_name": "กุดโดน"
    },
    {
        "subdistrict_id": "3834",
        "district_id": "472",
        "subdistrict_name": "บึงนาเรียง"
    },
    {
        "subdistrict_id": "3835",
        "district_id": "472",
        "subdistrict_name": "หัวหิน"
    },
    {
        "subdistrict_id": "3836",
        "district_id": "472",
        "subdistrict_name": "พิมูล"
    },
    {
        "subdistrict_id": "3837",
        "district_id": "472",
        "subdistrict_name": "คำเหมือดแก้ว"
    },
    {
        "subdistrict_id": "3838",
        "district_id": "472",
        "subdistrict_name": "โนนสะอาด"
    },
    {
        "subdistrict_id": "3839",
        "district_id": "472",
        "subdistrict_name": "ทรายทอง"
    },
    {
        "subdistrict_id": "3840",
        "district_id": "473",
        "subdistrict_name": "ภูสิงห์"
    },
    {
        "subdistrict_id": "3841",
        "district_id": "473",
        "subdistrict_name": "สหัสขันธ์"
    },
    {
        "subdistrict_id": "3842",
        "district_id": "473",
        "subdistrict_name": "นามะเขือ"
    },
    {
        "subdistrict_id": "3843",
        "district_id": "473",
        "subdistrict_name": "โนนศิลา"
    },
    {
        "subdistrict_id": "3844",
        "district_id": "473",
        "subdistrict_name": "นิคม"
    },
    {
        "subdistrict_id": "3845",
        "district_id": "473",
        "subdistrict_name": "โนนแหลมทอง"
    },
    {
        "subdistrict_id": "3846",
        "district_id": "473",
        "subdistrict_name": "โนนบุรี"
    },
    {
        "subdistrict_id": "3847",
        "district_id": "473",
        "subdistrict_name": "โนนน้ำเกลี้ยง"
    },
    {
        "subdistrict_id": "3848",
        "district_id": "474",
        "subdistrict_name": "ทุ่งคลอง"
    },
    {
        "subdistrict_id": "3849",
        "district_id": "474",
        "subdistrict_name": "โพน"
    },
    {
        "subdistrict_id": "3850",
        "district_id": "474",
        "subdistrict_name": "ดินจี่"
    },
    {
        "subdistrict_id": "3851",
        "district_id": "474",
        "subdistrict_name": "นาบอน"
    },
    {
        "subdistrict_id": "3852",
        "district_id": "474",
        "subdistrict_name": "นาทัน"
    },
    {
        "subdistrict_id": "3853",
        "district_id": "474",
        "subdistrict_name": "เนินยาง"
    },
    {
        "subdistrict_id": "3854",
        "district_id": "475",
        "subdistrict_name": "ท่าคันโท"
    },
    {
        "subdistrict_id": "3855",
        "district_id": "475",
        "subdistrict_name": "กุงเก่า"
    },
    {
        "subdistrict_id": "3856",
        "district_id": "475",
        "subdistrict_name": "ยางอู้ม"
    },
    {
        "subdistrict_id": "3857",
        "district_id": "475",
        "subdistrict_name": "กุดจิก"
    },
    {
        "subdistrict_id": "3858",
        "district_id": "475",
        "subdistrict_name": "นาตาล"
    },
    {
        "subdistrict_id": "3859",
        "district_id": "475",
        "subdistrict_name": "ดงสมบูรณ์"
    },
    {
        "subdistrict_id": "3860",
        "district_id": "476",
        "subdistrict_name": "หนองกุงศรี"
    },
    {
        "subdistrict_id": "3861",
        "district_id": "476",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "3862",
        "district_id": "476",
        "subdistrict_name": "โคกเครือ"
    },
    {
        "subdistrict_id": "3863",
        "district_id": "476",
        "subdistrict_name": "หนองสรวง"
    },
    {
        "subdistrict_id": "3864",
        "district_id": "476",
        "subdistrict_name": "เสาเล้า"
    },
    {
        "subdistrict_id": "3865",
        "district_id": "476",
        "subdistrict_name": "หนองใหญ่"
    },
    {
        "subdistrict_id": "3866",
        "district_id": "476",
        "subdistrict_name": "ดงมูล"
    },
    {
        "subdistrict_id": "3867",
        "district_id": "476",
        "subdistrict_name": "ลำหนองแสน"
    },
    {
        "subdistrict_id": "3868",
        "district_id": "476",
        "subdistrict_name": "หนองหิน"
    },
    {
        "subdistrict_id": "3869",
        "district_id": "477",
        "subdistrict_name": "สมเด็จ"
    },
    {
        "subdistrict_id": "3870",
        "district_id": "477",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "3871",
        "district_id": "477",
        "subdistrict_name": "แซงบาดาล"
    },
    {
        "subdistrict_id": "3872",
        "district_id": "477",
        "subdistrict_name": "มหาไชย"
    },
    {
        "subdistrict_id": "3873",
        "district_id": "477",
        "subdistrict_name": "หมูม่น"
    },
    {
        "subdistrict_id": "3874",
        "district_id": "477",
        "subdistrict_name": "ผาเสวย"
    },
    {
        "subdistrict_id": "3875",
        "district_id": "477",
        "subdistrict_name": "ศรีสมเด็จ"
    },
    {
        "subdistrict_id": "3876",
        "district_id": "477",
        "subdistrict_name": "ลำห้วยหลัว"
    },
    {
        "subdistrict_id": "3877",
        "district_id": "478",
        "subdistrict_name": "คำบง"
    },
    {
        "subdistrict_id": "3878",
        "district_id": "478",
        "subdistrict_name": "ไค้นุ่น"
    },
    {
        "subdistrict_id": "3879",
        "district_id": "478",
        "subdistrict_name": "นิคมห้วยผึ้ง"
    },
    {
        "subdistrict_id": "3880",
        "district_id": "478",
        "subdistrict_name": "หนองอีบุตร"
    },
    {
        "subdistrict_id": "3881",
        "district_id": "479",
        "subdistrict_name": "สำราญ"
    },
    {
        "subdistrict_id": "3882",
        "district_id": "479",
        "subdistrict_name": "สำราญใต้"
    },
    {
        "subdistrict_id": "3883",
        "district_id": "479",
        "subdistrict_name": "คำสร้างเที่ยง"
    },
    {
        "subdistrict_id": "3884",
        "district_id": "479",
        "subdistrict_name": "หนองช้าง"
    },
    {
        "subdistrict_id": "3885",
        "district_id": "480",
        "subdistrict_name": "นาคู"
    },
    {
        "subdistrict_id": "3886",
        "district_id": "480",
        "subdistrict_name": "สายนาวัง"
    },
    {
        "subdistrict_id": "3887",
        "district_id": "480",
        "subdistrict_name": "โนนนาจาน"
    },
    {
        "subdistrict_id": "3888",
        "district_id": "480",
        "subdistrict_name": "บ่อแก้ว"
    },
    {
        "subdistrict_id": "3889",
        "district_id": "480",
        "subdistrict_name": "ภูแล่นช้าง"
    },
    {
        "subdistrict_id": "3890",
        "district_id": "481",
        "subdistrict_name": "ดอนจาน"
    },
    {
        "subdistrict_id": "3891",
        "district_id": "481",
        "subdistrict_name": "สะอาดไชยศรี"
    },
    {
        "subdistrict_id": "3892",
        "district_id": "481",
        "subdistrict_name": "ดงพยุง"
    },
    {
        "subdistrict_id": "3893",
        "district_id": "481",
        "subdistrict_name": "ม่วงนา"
    },
    {
        "subdistrict_id": "3894",
        "district_id": "481",
        "subdistrict_name": "นาจำปา"
    },
    {
        "subdistrict_id": "3895",
        "district_id": "482",
        "subdistrict_name": "ฆ้องชัยพัฒนา"
    },
    {
        "subdistrict_id": "3896",
        "district_id": "482",
        "subdistrict_name": "เหล่ากลาง"
    },
    {
        "subdistrict_id": "3897",
        "district_id": "482",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "3898",
        "district_id": "482",
        "subdistrict_name": "โนนศิลาเลิง"
    },
    {
        "subdistrict_id": "3899",
        "district_id": "482",
        "subdistrict_name": "ลำชี"
    },
    {
        "subdistrict_id": "3900",
        "district_id": "483",
        "subdistrict_name": "ธาตุเชิงชุม"
    },
    {
        "subdistrict_id": "3901",
        "district_id": "483",
        "subdistrict_name": "ขมิ้น"
    },
    {
        "subdistrict_id": "3902",
        "district_id": "483",
        "subdistrict_name": "งิ้วด่อน"
    },
    {
        "subdistrict_id": "3903",
        "district_id": "483",
        "subdistrict_name": "โนนหอม"
    },
    {
        "subdistrict_id": "3904",
        "district_id": "483",
        "subdistrict_name": "เชียงเครือ"
    },
    {
        "subdistrict_id": "3905",
        "district_id": "483",
        "subdistrict_name": "ท่าแร่"
    },
    {
        "subdistrict_id": "3906",
        "district_id": "483",
        "subdistrict_name": "ม่วงลาย"
    },
    {
        "subdistrict_id": "3907",
        "district_id": "483",
        "subdistrict_name": "ดงชน"
    },
    {
        "subdistrict_id": "3908",
        "district_id": "483",
        "subdistrict_name": "ห้วยยาง"
    },
    {
        "subdistrict_id": "3909",
        "district_id": "483",
        "subdistrict_name": "พังขว้าง"
    },
    {
        "subdistrict_id": "3910",
        "district_id": "483",
        "subdistrict_name": "ดงมะไฟ"
    },
    {
        "subdistrict_id": "3911",
        "district_id": "483",
        "subdistrict_name": "ธาตุนาเวง"
    },
    {
        "subdistrict_id": "3912",
        "district_id": "483",
        "subdistrict_name": "เหล่าปอแดง"
    },
    {
        "subdistrict_id": "3913",
        "district_id": "483",
        "subdistrict_name": "หนองลาด"
    },
    {
        "subdistrict_id": "3914",
        "district_id": "483",
        "subdistrict_name": "ฮางโฮง"
    },
    {
        "subdistrict_id": "3915",
        "district_id": "483",
        "subdistrict_name": "โคกก่อง"
    },
    {
        "subdistrict_id": "3916",
        "district_id": "484",
        "subdistrict_name": "กุสุมาลย์"
    },
    {
        "subdistrict_id": "3917",
        "district_id": "484",
        "subdistrict_name": "นาโพธิ์"
    },
    {
        "subdistrict_id": "3918",
        "district_id": "484",
        "subdistrict_name": "นาเพียง"
    },
    {
        "subdistrict_id": "3919",
        "district_id": "484",
        "subdistrict_name": "โพธิไพศาล"
    },
    {
        "subdistrict_id": "3920",
        "district_id": "484",
        "subdistrict_name": "อุ่มจาน"
    },
    {
        "subdistrict_id": "3921",
        "district_id": "485",
        "subdistrict_name": "กุดบาก"
    },
    {
        "subdistrict_id": "3922",
        "district_id": "485",
        "subdistrict_name": "นาม่อง"
    },
    {
        "subdistrict_id": "3923",
        "district_id": "485",
        "subdistrict_name": "กุดไห"
    },
    {
        "subdistrict_id": "3924",
        "district_id": "486",
        "subdistrict_name": "พรรณา"
    },
    {
        "subdistrict_id": "3925",
        "district_id": "486",
        "subdistrict_name": "วังยาง"
    },
    {
        "subdistrict_id": "3926",
        "district_id": "486",
        "subdistrict_name": "พอกน้อย"
    },
    {
        "subdistrict_id": "3927",
        "district_id": "486",
        "subdistrict_name": "นาหัวบ่อ"
    },
    {
        "subdistrict_id": "3928",
        "district_id": "486",
        "subdistrict_name": "ไร่"
    },
    {
        "subdistrict_id": "3929",
        "district_id": "486",
        "subdistrict_name": "ช้างมิ่ง"
    },
    {
        "subdistrict_id": "3930",
        "district_id": "486",
        "subdistrict_name": "นาใน"
    },
    {
        "subdistrict_id": "3931",
        "district_id": "486",
        "subdistrict_name": "สว่าง"
    },
    {
        "subdistrict_id": "3932",
        "district_id": "486",
        "subdistrict_name": "บะฮี"
    },
    {
        "subdistrict_id": "3933",
        "district_id": "486",
        "subdistrict_name": "เชิงชุม"
    },
    {
        "subdistrict_id": "3934",
        "district_id": "487",
        "subdistrict_name": "พังโคน"
    },
    {
        "subdistrict_id": "3935",
        "district_id": "487",
        "subdistrict_name": "ม่วงไข่"
    },
    {
        "subdistrict_id": "3936",
        "district_id": "487",
        "subdistrict_name": "แร่"
    },
    {
        "subdistrict_id": "3937",
        "district_id": "487",
        "subdistrict_name": "ไฮหย่อง"
    },
    {
        "subdistrict_id": "3938",
        "district_id": "487",
        "subdistrict_name": "ต้นผึ้ง"
    },
    {
        "subdistrict_id": "3939",
        "district_id": "488",
        "subdistrict_name": "วาริชภูมิ"
    },
    {
        "subdistrict_id": "3940",
        "district_id": "488",
        "subdistrict_name": "ปลาโหล"
    },
    {
        "subdistrict_id": "3941",
        "district_id": "488",
        "subdistrict_name": "หนองลาด"
    },
    {
        "subdistrict_id": "3942",
        "district_id": "488",
        "subdistrict_name": "คำบ่อ"
    },
    {
        "subdistrict_id": "3943",
        "district_id": "488",
        "subdistrict_name": "ค้อเขียว"
    },
    {
        "subdistrict_id": "3944",
        "district_id": "489",
        "subdistrict_name": "นิคมน้ำอูน"
    },
    {
        "subdistrict_id": "3945",
        "district_id": "489",
        "subdistrict_name": "หนองปลิง"
    },
    {
        "subdistrict_id": "3946",
        "district_id": "489",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "3947",
        "district_id": "489",
        "subdistrict_name": "สุวรรณคาม"
    },
    {
        "subdistrict_id": "3948",
        "district_id": "490",
        "subdistrict_name": "วานรนิวาส"
    },
    {
        "subdistrict_id": "3949",
        "district_id": "490",
        "subdistrict_name": "เดื่อศรีคันไชย"
    },
    {
        "subdistrict_id": "3950",
        "district_id": "490",
        "subdistrict_name": "ขัวก่าย"
    },
    {
        "subdistrict_id": "3951",
        "district_id": "490",
        "subdistrict_name": "หนองสนม"
    },
    {
        "subdistrict_id": "3952",
        "district_id": "490",
        "subdistrict_name": "คูสะคาม"
    },
    {
        "subdistrict_id": "3953",
        "district_id": "490",
        "subdistrict_name": "ธาตุ"
    },
    {
        "subdistrict_id": "3954",
        "district_id": "490",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "3955",
        "district_id": "490",
        "subdistrict_name": "ศรีวิชัย"
    },
    {
        "subdistrict_id": "3956",
        "district_id": "490",
        "subdistrict_name": "นาซอ"
    },
    {
        "subdistrict_id": "3957",
        "district_id": "490",
        "subdistrict_name": "อินทร์แปลง"
    },
    {
        "subdistrict_id": "3958",
        "district_id": "490",
        "subdistrict_name": "นาคำ"
    },
    {
        "subdistrict_id": "3959",
        "district_id": "490",
        "subdistrict_name": "คอนสวรรค์"
    },
    {
        "subdistrict_id": "3960",
        "district_id": "490",
        "subdistrict_name": "กุดเรือคำ"
    },
    {
        "subdistrict_id": "3961",
        "district_id": "490",
        "subdistrict_name": "หนองแวงใต้"
    },
    {
        "subdistrict_id": "3962",
        "district_id": "491",
        "subdistrict_name": "คำตากล้า"
    },
    {
        "subdistrict_id": "3963",
        "district_id": "491",
        "subdistrict_name": "หนองบัวสิม"
    },
    {
        "subdistrict_id": "3964",
        "district_id": "491",
        "subdistrict_name": "นาแต้"
    },
    {
        "subdistrict_id": "3965",
        "district_id": "491",
        "subdistrict_name": "แพด"
    },
    {
        "subdistrict_id": "3966",
        "district_id": "492",
        "subdistrict_name": "ม่วง"
    },
    {
        "subdistrict_id": "3967",
        "district_id": "492",
        "subdistrict_name": "มาย"
    },
    {
        "subdistrict_id": "3968",
        "district_id": "492",
        "subdistrict_name": "ดงหม้อทอง"
    },
    {
        "subdistrict_id": "3969",
        "district_id": "492",
        "subdistrict_name": "ดงเหนือ"
    },
    {
        "subdistrict_id": "3970",
        "district_id": "492",
        "subdistrict_name": "ดงหม้อทองใต้"
    },
    {
        "subdistrict_id": "3971",
        "district_id": "492",
        "subdistrict_name": "ห้วยหลัว"
    },
    {
        "subdistrict_id": "3972",
        "district_id": "492",
        "subdistrict_name": "โนนสะอาด"
    },
    {
        "subdistrict_id": "3973",
        "district_id": "492",
        "subdistrict_name": "หนองกวั่ง"
    },
    {
        "subdistrict_id": "3974",
        "district_id": "492",
        "subdistrict_name": "บ่อแก้ว"
    },
    {
        "subdistrict_id": "3975",
        "district_id": "493",
        "subdistrict_name": "อากาศ"
    },
    {
        "subdistrict_id": "3976",
        "district_id": "493",
        "subdistrict_name": "โพนแพง"
    },
    {
        "subdistrict_id": "3977",
        "district_id": "493",
        "subdistrict_name": "วาใหญ่"
    },
    {
        "subdistrict_id": "3978",
        "district_id": "493",
        "subdistrict_name": "โพนงาม"
    },
    {
        "subdistrict_id": "3979",
        "district_id": "493",
        "subdistrict_name": "ท่าก้อน"
    },
    {
        "subdistrict_id": "3980",
        "district_id": "493",
        "subdistrict_name": "นาฮี"
    },
    {
        "subdistrict_id": "3981",
        "district_id": "493",
        "subdistrict_name": "บะหว้า"
    },
    {
        "subdistrict_id": "3982",
        "district_id": "493",
        "subdistrict_name": "สามัคคีพัฒนา"
    },
    {
        "subdistrict_id": "3983",
        "district_id": "494",
        "subdistrict_name": "สว่างแดนดิน"
    },
    {
        "subdistrict_id": "3984",
        "district_id": "494",
        "subdistrict_name": "คำสะอาด"
    },
    {
        "subdistrict_id": "3985",
        "district_id": "494",
        "subdistrict_name": "บ้านต้าย"
    },
    {
        "subdistrict_id": "3986",
        "district_id": "494",
        "subdistrict_name": "บงเหนือ"
    },
    {
        "subdistrict_id": "3987",
        "district_id": "494",
        "subdistrict_name": "โพนสูง"
    },
    {
        "subdistrict_id": "3988",
        "district_id": "494",
        "subdistrict_name": "โคกสี"
    },
    {
        "subdistrict_id": "3989",
        "district_id": "494",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "3990",
        "district_id": "494",
        "subdistrict_name": "บงใต้"
    },
    {
        "subdistrict_id": "3991",
        "district_id": "494",
        "subdistrict_name": "ค้อใต้"
    },
    {
        "subdistrict_id": "3992",
        "district_id": "494",
        "subdistrict_name": "พันนา"
    },
    {
        "subdistrict_id": "3993",
        "district_id": "494",
        "subdistrict_name": "แวง"
    },
    {
        "subdistrict_id": "3994",
        "district_id": "494",
        "subdistrict_name": "ทรายมูล"
    },
    {
        "subdistrict_id": "3995",
        "district_id": "494",
        "subdistrict_name": "ตาลโกน"
    },
    {
        "subdistrict_id": "3996",
        "district_id": "494",
        "subdistrict_name": "ตาลเนิ้ง"
    },
    {
        "subdistrict_id": "3997",
        "district_id": "494",
        "subdistrict_name": "ธาตุทอง"
    },
    {
        "subdistrict_id": "3998",
        "district_id": "494",
        "subdistrict_name": "บ้านถ่อน"
    },
    {
        "subdistrict_id": "3999",
        "district_id": "495",
        "subdistrict_name": "ส่องดาว"
    },
    {
        "subdistrict_id": "4000",
        "district_id": "495",
        "subdistrict_name": "ท่าศิลา"
    },
    {
        "subdistrict_id": "4001",
        "district_id": "495",
        "subdistrict_name": "วัฒนา"
    },
    {
        "subdistrict_id": "4002",
        "district_id": "495",
        "subdistrict_name": "ปทุมวาปี"
    },
    {
        "subdistrict_id": "4003",
        "district_id": "496",
        "subdistrict_name": "เต่างอย"
    },
    {
        "subdistrict_id": "4004",
        "district_id": "496",
        "subdistrict_name": "บึงทวาย"
    },
    {
        "subdistrict_id": "4005",
        "district_id": "496",
        "subdistrict_name": "นาตาล"
    },
    {
        "subdistrict_id": "4006",
        "district_id": "496",
        "subdistrict_name": "จันทร์เพ็ญ"
    },
    {
        "subdistrict_id": "4007",
        "district_id": "497",
        "subdistrict_name": "ตองโขบ"
    },
    {
        "subdistrict_id": "4008",
        "district_id": "497",
        "subdistrict_name": "เหล่าโพนค้อ"
    },
    {
        "subdistrict_id": "4009",
        "district_id": "497",
        "subdistrict_name": "ด่านม่วงคำ"
    },
    {
        "subdistrict_id": "4010",
        "district_id": "497",
        "subdistrict_name": "แมดนาท่ม"
    },
    {
        "subdistrict_id": "4011",
        "district_id": "498",
        "subdistrict_name": "บ้านเหล่า"
    },
    {
        "subdistrict_id": "4012",
        "district_id": "498",
        "subdistrict_name": "เจริญศิลป์"
    },
    {
        "subdistrict_id": "4013",
        "district_id": "498",
        "subdistrict_name": "ทุ่งแก"
    },
    {
        "subdistrict_id": "4014",
        "district_id": "498",
        "subdistrict_name": "โคกศิลา"
    },
    {
        "subdistrict_id": "4015",
        "district_id": "498",
        "subdistrict_name": "หนองแปน"
    },
    {
        "subdistrict_id": "4016",
        "district_id": "499",
        "subdistrict_name": "บ้านโพน"
    },
    {
        "subdistrict_id": "4017",
        "district_id": "499",
        "subdistrict_name": "นาแก้ว"
    },
    {
        "subdistrict_id": "4018",
        "district_id": "499",
        "subdistrict_name": "นาตงวัฒนา"
    },
    {
        "subdistrict_id": "4019",
        "district_id": "499",
        "subdistrict_name": "บ้านแป้น"
    },
    {
        "subdistrict_id": "4020",
        "district_id": "499",
        "subdistrict_name": "เชียงสือ"
    },
    {
        "subdistrict_id": "4021",
        "district_id": "500",
        "subdistrict_name": "สร้างค้อ"
    },
    {
        "subdistrict_id": "4022",
        "district_id": "500",
        "subdistrict_name": "หลุบเลา"
    },
    {
        "subdistrict_id": "4023",
        "district_id": "500",
        "subdistrict_name": "โคกภู"
    },
    {
        "subdistrict_id": "4024",
        "district_id": "500",
        "subdistrict_name": "กกปลาซิว"
    },
    {
        "subdistrict_id": "4025",
        "district_id": "501",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "4026",
        "district_id": "501",
        "subdistrict_name": "หนองแสง"
    },
    {
        "subdistrict_id": "4027",
        "district_id": "501",
        "subdistrict_name": "นาทราย"
    },
    {
        "subdistrict_id": "4028",
        "district_id": "501",
        "subdistrict_name": "นาราชควาย"
    },
    {
        "subdistrict_id": "4029",
        "district_id": "501",
        "subdistrict_name": "กุรุคุ"
    },
    {
        "subdistrict_id": "4030",
        "district_id": "501",
        "subdistrict_name": "บ้านผึ้ง"
    },
    {
        "subdistrict_id": "4031",
        "district_id": "501",
        "subdistrict_name": "อาจสามารถ"
    },
    {
        "subdistrict_id": "4032",
        "district_id": "501",
        "subdistrict_name": "ขามเฒ่า"
    },
    {
        "subdistrict_id": "4033",
        "district_id": "501",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "4034",
        "district_id": "501",
        "subdistrict_name": "ท่าค้อ"
    },
    {
        "subdistrict_id": "4035",
        "district_id": "501",
        "subdistrict_name": "คำเตย"
    },
    {
        "subdistrict_id": "4036",
        "district_id": "501",
        "subdistrict_name": "หนองญาติ"
    },
    {
        "subdistrict_id": "4037",
        "district_id": "501",
        "subdistrict_name": "ดงขวาง"
    },
    {
        "subdistrict_id": "4038",
        "district_id": "501",
        "subdistrict_name": "วังตามัว"
    },
    {
        "subdistrict_id": "4039",
        "district_id": "501",
        "subdistrict_name": "โพธิ์ตาก"
    },
    {
        "subdistrict_id": "4040",
        "district_id": "502",
        "subdistrict_name": "ปลาปาก"
    },
    {
        "subdistrict_id": "4041",
        "district_id": "502",
        "subdistrict_name": "หนองฮี"
    },
    {
        "subdistrict_id": "4042",
        "district_id": "502",
        "subdistrict_name": "กุตาไก้"
    },
    {
        "subdistrict_id": "4043",
        "district_id": "502",
        "subdistrict_name": "โคกสว่าง"
    },
    {
        "subdistrict_id": "4044",
        "district_id": "502",
        "subdistrict_name": "โคกสูง"
    },
    {
        "subdistrict_id": "4045",
        "district_id": "502",
        "subdistrict_name": "มหาชัย"
    },
    {
        "subdistrict_id": "4046",
        "district_id": "502",
        "subdistrict_name": "นามะเขือ"
    },
    {
        "subdistrict_id": "4047",
        "district_id": "502",
        "subdistrict_name": "หนองเทาใหญ่"
    },
    {
        "subdistrict_id": "4048",
        "district_id": "503",
        "subdistrict_name": "ท่าอุเทน"
    },
    {
        "subdistrict_id": "4049",
        "district_id": "503",
        "subdistrict_name": "โนนตาล"
    },
    {
        "subdistrict_id": "4050",
        "district_id": "503",
        "subdistrict_name": "ท่าจำปา"
    },
    {
        "subdistrict_id": "4051",
        "district_id": "503",
        "subdistrict_name": "ไชยบุรี"
    },
    {
        "subdistrict_id": "4052",
        "district_id": "503",
        "subdistrict_name": "พนอม"
    },
    {
        "subdistrict_id": "4053",
        "district_id": "503",
        "subdistrict_name": "พะทาย"
    },
    {
        "subdistrict_id": "4054",
        "district_id": "503",
        "subdistrict_name": "เวินพระบาท"
    },
    {
        "subdistrict_id": "4055",
        "district_id": "503",
        "subdistrict_name": "รามราช"
    },
    {
        "subdistrict_id": "4056",
        "district_id": "503",
        "subdistrict_name": "หนองเทา"
    },
    {
        "subdistrict_id": "4057",
        "district_id": "504",
        "subdistrict_name": "บ้านแพง"
    },
    {
        "subdistrict_id": "4058",
        "district_id": "504",
        "subdistrict_name": "ไผ่ล้อม"
    },
    {
        "subdistrict_id": "4059",
        "district_id": "504",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "4060",
        "district_id": "504",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "4061",
        "district_id": "504",
        "subdistrict_name": "นางัว"
    },
    {
        "subdistrict_id": "4062",
        "district_id": "504",
        "subdistrict_name": "นาเข"
    },
    {
        "subdistrict_id": "4063",
        "district_id": "505",
        "subdistrict_name": "ธาตุพนม"
    },
    {
        "subdistrict_id": "4064",
        "district_id": "505",
        "subdistrict_name": "ฝั่งแดง"
    },
    {
        "subdistrict_id": "4065",
        "district_id": "505",
        "subdistrict_name": "โพนแพง"
    },
    {
        "subdistrict_id": "4066",
        "district_id": "505",
        "subdistrict_name": "พระกลางทุ่ง"
    },
    {
        "subdistrict_id": "4067",
        "district_id": "505",
        "subdistrict_name": "นาถ่อน"
    },
    {
        "subdistrict_id": "4068",
        "district_id": "505",
        "subdistrict_name": "แสนพัน"
    },
    {
        "subdistrict_id": "4069",
        "district_id": "505",
        "subdistrict_name": "ดอนนางหงส์"
    },
    {
        "subdistrict_id": "4070",
        "district_id": "505",
        "subdistrict_name": "น้ำก่ำ"
    },
    {
        "subdistrict_id": "4071",
        "district_id": "505",
        "subdistrict_name": "อุ่มเหม้า"
    },
    {
        "subdistrict_id": "4072",
        "district_id": "505",
        "subdistrict_name": "นาหนาด"
    },
    {
        "subdistrict_id": "4073",
        "district_id": "505",
        "subdistrict_name": "กุดฉิม"
    },
    {
        "subdistrict_id": "4074",
        "district_id": "505",
        "subdistrict_name": "ธาตุพนมเหนือ"
    },
    {
        "subdistrict_id": "4075",
        "district_id": "506",
        "subdistrict_name": "เรณู"
    },
    {
        "subdistrict_id": "4076",
        "district_id": "506",
        "subdistrict_name": "โพนทอง"
    },
    {
        "subdistrict_id": "4077",
        "district_id": "506",
        "subdistrict_name": "ท่าลาด"
    },
    {
        "subdistrict_id": "4078",
        "district_id": "506",
        "subdistrict_name": "นางาม"
    },
    {
        "subdistrict_id": "4079",
        "district_id": "506",
        "subdistrict_name": "โคกหินแฮ่"
    },
    {
        "subdistrict_id": "4080",
        "district_id": "506",
        "subdistrict_name": "หนองย่างชิ้น"
    },
    {
        "subdistrict_id": "4081",
        "district_id": "506",
        "subdistrict_name": "เรณูใต้"
    },
    {
        "subdistrict_id": "4082",
        "district_id": "506",
        "subdistrict_name": "นาขาม"
    },
    {
        "subdistrict_id": "4083",
        "district_id": "507",
        "subdistrict_name": "นาแก"
    },
    {
        "subdistrict_id": "4084",
        "district_id": "507",
        "subdistrict_name": "พระซอง"
    },
    {
        "subdistrict_id": "4085",
        "district_id": "507",
        "subdistrict_name": "หนองสังข์"
    },
    {
        "subdistrict_id": "4086",
        "district_id": "507",
        "subdistrict_name": "นาคู่"
    },
    {
        "subdistrict_id": "4087",
        "district_id": "507",
        "subdistrict_name": "พิมาน"
    },
    {
        "subdistrict_id": "4088",
        "district_id": "507",
        "subdistrict_name": "พุ่มแก"
    },
    {
        "subdistrict_id": "4089",
        "district_id": "507",
        "subdistrict_name": "ก้านเหลือง"
    },
    {
        "subdistrict_id": "4090",
        "district_id": "507",
        "subdistrict_name": "หนองบ่อ"
    },
    {
        "subdistrict_id": "4091",
        "district_id": "507",
        "subdistrict_name": "นาเลียง"
    },
    {
        "subdistrict_id": "4092",
        "district_id": "507",
        "subdistrict_name": "บ้านแก้ง"
    },
    {
        "subdistrict_id": "4093",
        "district_id": "507",
        "subdistrict_name": "คำพี้"
    },
    {
        "subdistrict_id": "4094",
        "district_id": "507",
        "subdistrict_name": "สีชมพู"
    },
    {
        "subdistrict_id": "4095",
        "district_id": "508",
        "subdistrict_name": "ศรีสงคราม"
    },
    {
        "subdistrict_id": "4096",
        "district_id": "508",
        "subdistrict_name": "นาเดื่อ"
    },
    {
        "subdistrict_id": "4097",
        "district_id": "508",
        "subdistrict_name": "บ้านเอื้อง"
    },
    {
        "subdistrict_id": "4098",
        "district_id": "508",
        "subdistrict_name": "สามผง"
    },
    {
        "subdistrict_id": "4099",
        "district_id": "508",
        "subdistrict_name": "ท่าบ่อสงคราม"
    },
    {
        "subdistrict_id": "4100",
        "district_id": "508",
        "subdistrict_name": "บ้านข่า"
    },
    {
        "subdistrict_id": "4101",
        "district_id": "508",
        "subdistrict_name": "นาคำ"
    },
    {
        "subdistrict_id": "4102",
        "district_id": "508",
        "subdistrict_name": "โพนสว่าง"
    },
    {
        "subdistrict_id": "4103",
        "district_id": "508",
        "subdistrict_name": "หาดแพง"
    },
    {
        "subdistrict_id": "4104",
        "district_id": "509",
        "subdistrict_name": "นาหว้า"
    },
    {
        "subdistrict_id": "4105",
        "district_id": "509",
        "subdistrict_name": "นางัว"
    },
    {
        "subdistrict_id": "4106",
        "district_id": "509",
        "subdistrict_name": "บ้านเสียว"
    },
    {
        "subdistrict_id": "4107",
        "district_id": "509",
        "subdistrict_name": "นาคูณใหญ่"
    },
    {
        "subdistrict_id": "4108",
        "district_id": "509",
        "subdistrict_name": "เหล่าพัฒนา"
    },
    {
        "subdistrict_id": "4109",
        "district_id": "509",
        "subdistrict_name": "ท่าเรือ"
    },
    {
        "subdistrict_id": "4110",
        "district_id": "510",
        "subdistrict_name": "โพนสวรรค์"
    },
    {
        "subdistrict_id": "4111",
        "district_id": "510",
        "subdistrict_name": "นาหัวบ่อ"
    },
    {
        "subdistrict_id": "4112",
        "district_id": "510",
        "subdistrict_name": "นาขมิ้น"
    },
    {
        "subdistrict_id": "4113",
        "district_id": "510",
        "subdistrict_name": "โพนบก"
    },
    {
        "subdistrict_id": "4114",
        "district_id": "510",
        "subdistrict_name": "บ้านค้อ"
    },
    {
        "subdistrict_id": "4115",
        "district_id": "510",
        "subdistrict_name": "โพนจาน"
    },
    {
        "subdistrict_id": "4116",
        "district_id": "510",
        "subdistrict_name": "นาใน"
    },
    {
        "subdistrict_id": "4117",
        "district_id": "511",
        "subdistrict_name": "นาทม"
    },
    {
        "subdistrict_id": "4118",
        "district_id": "511",
        "subdistrict_name": "หนองซน"
    },
    {
        "subdistrict_id": "4119",
        "district_id": "511",
        "subdistrict_name": "ดอนเตย"
    },
    {
        "subdistrict_id": "4120",
        "district_id": "512",
        "subdistrict_name": "วังยาง"
    },
    {
        "subdistrict_id": "4121",
        "district_id": "512",
        "subdistrict_name": "โคกสี"
    },
    {
        "subdistrict_id": "4122",
        "district_id": "512",
        "subdistrict_name": "ยอดชาด"
    },
    {
        "subdistrict_id": "4123",
        "district_id": "512",
        "subdistrict_name": "หนองโพธิ์"
    },
    {
        "subdistrict_id": "4124",
        "district_id": "513",
        "subdistrict_name": "มุกดาหาร"
    },
    {
        "subdistrict_id": "4125",
        "district_id": "513",
        "subdistrict_name": "ศรีบุญเรือง"
    },
    {
        "subdistrict_id": "4126",
        "district_id": "513",
        "subdistrict_name": "บ้านโคก"
    },
    {
        "subdistrict_id": "4127",
        "district_id": "513",
        "subdistrict_name": "บางทรายใหญ่"
    },
    {
        "subdistrict_id": "4128",
        "district_id": "513",
        "subdistrict_name": "โพนทราย"
    },
    {
        "subdistrict_id": "4129",
        "district_id": "513",
        "subdistrict_name": "ผึ่งแดด"
    },
    {
        "subdistrict_id": "4130",
        "district_id": "513",
        "subdistrict_name": "นาโสก"
    },
    {
        "subdistrict_id": "4131",
        "district_id": "513",
        "subdistrict_name": "นาสีนวน"
    },
    {
        "subdistrict_id": "4132",
        "district_id": "513",
        "subdistrict_name": "คำป่าหลาย"
    },
    {
        "subdistrict_id": "4133",
        "district_id": "513",
        "subdistrict_name": "คำอาฮวน"
    },
    {
        "subdistrict_id": "4134",
        "district_id": "513",
        "subdistrict_name": "ดงเย็น"
    },
    {
        "subdistrict_id": "4135",
        "district_id": "513",
        "subdistrict_name": "ดงมอน"
    },
    {
        "subdistrict_id": "4136",
        "district_id": "513",
        "subdistrict_name": "กุดแข้"
    },
    {
        "subdistrict_id": "4137",
        "district_id": "514",
        "subdistrict_name": "นิคมคำสร้อย"
    },
    {
        "subdistrict_id": "4138",
        "district_id": "514",
        "subdistrict_name": "นากอก"
    },
    {
        "subdistrict_id": "4139",
        "district_id": "514",
        "subdistrict_name": "หนองแวง"
    },
    {
        "subdistrict_id": "4140",
        "district_id": "514",
        "subdistrict_name": "กกแดง"
    },
    {
        "subdistrict_id": "4141",
        "district_id": "514",
        "subdistrict_name": "นาอุดม"
    },
    {
        "subdistrict_id": "4142",
        "district_id": "514",
        "subdistrict_name": "โชคชัย"
    },
    {
        "subdistrict_id": "4143",
        "district_id": "514",
        "subdistrict_name": "ร่มเกล้า"
    },
    {
        "subdistrict_id": "4144",
        "district_id": "515",
        "subdistrict_name": "ดอนตาล"
    },
    {
        "subdistrict_id": "4145",
        "district_id": "515",
        "subdistrict_name": "โพธิ์ไทร"
    },
    {
        "subdistrict_id": "4146",
        "district_id": "515",
        "subdistrict_name": "ป่าไร่"
    },
    {
        "subdistrict_id": "4147",
        "district_id": "515",
        "subdistrict_name": "เหล่าหมี"
    },
    {
        "subdistrict_id": "4148",
        "district_id": "515",
        "subdistrict_name": "บ้านบาก"
    },
    {
        "subdistrict_id": "4149",
        "district_id": "515",
        "subdistrict_name": "นาสะเม็ง"
    },
    {
        "subdistrict_id": "4150",
        "district_id": "515",
        "subdistrict_name": "บ้านแก้ง"
    },
    {
        "subdistrict_id": "4151",
        "district_id": "516",
        "subdistrict_name": "ดงหลวง"
    },
    {
        "subdistrict_id": "4152",
        "district_id": "516",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "4153",
        "district_id": "516",
        "subdistrict_name": "กกตูม"
    },
    {
        "subdistrict_id": "4154",
        "district_id": "516",
        "subdistrict_name": "หนองแคน"
    },
    {
        "subdistrict_id": "4155",
        "district_id": "516",
        "subdistrict_name": "ชะโนดน้อย"
    },
    {
        "subdistrict_id": "4156",
        "district_id": "516",
        "subdistrict_name": "พังแดง"
    },
    {
        "subdistrict_id": "4157",
        "district_id": "517",
        "subdistrict_name": "บ้านซ่ง"
    },
    {
        "subdistrict_id": "4158",
        "district_id": "517",
        "subdistrict_name": "คำชะอี"
    },
    {
        "subdistrict_id": "4159",
        "district_id": "517",
        "subdistrict_name": "หนองเอี่ยน"
    },
    {
        "subdistrict_id": "4160",
        "district_id": "517",
        "subdistrict_name": "บ้านค้อ"
    },
    {
        "subdistrict_id": "4161",
        "district_id": "517",
        "subdistrict_name": "บ้านเหล่า"
    },
    {
        "subdistrict_id": "4162",
        "district_id": "517",
        "subdistrict_name": "โพนงาม"
    },
    {
        "subdistrict_id": "4163",
        "district_id": "517",
        "subdistrict_name": "เหล่าสร้างถ่อ"
    },
    {
        "subdistrict_id": "4164",
        "district_id": "517",
        "subdistrict_name": "คำบก"
    },
    {
        "subdistrict_id": "4165",
        "district_id": "517",
        "subdistrict_name": "น้ำเที่ยง"
    },
    {
        "subdistrict_id": "4166",
        "district_id": "518",
        "subdistrict_name": "หว้านใหญ่"
    },
    {
        "subdistrict_id": "4167",
        "district_id": "518",
        "subdistrict_name": "ป่งขาม"
    },
    {
        "subdistrict_id": "4168",
        "district_id": "518",
        "subdistrict_name": "บางทรายน้อย"
    },
    {
        "subdistrict_id": "4169",
        "district_id": "518",
        "subdistrict_name": "ชะโนด"
    },
    {
        "subdistrict_id": "4170",
        "district_id": "518",
        "subdistrict_name": "ดงหมู"
    },
    {
        "subdistrict_id": "4171",
        "district_id": "519",
        "subdistrict_name": "หนองสูง"
    },
    {
        "subdistrict_id": "4172",
        "district_id": "519",
        "subdistrict_name": "โนนยาง"
    },
    {
        "subdistrict_id": "4173",
        "district_id": "519",
        "subdistrict_name": "ภูวง"
    },
    {
        "subdistrict_id": "4174",
        "district_id": "519",
        "subdistrict_name": "บ้านเป้า"
    },
    {
        "subdistrict_id": "4175",
        "district_id": "519",
        "subdistrict_name": "หนองสูงใต้"
    },
    {
        "subdistrict_id": "4176",
        "district_id": "519",
        "subdistrict_name": "หนองสูงเหนือ"
    },
    {
        "subdistrict_id": "4177",
        "district_id": "520",
        "subdistrict_name": "ศรีภูมิ"
    },
    {
        "subdistrict_id": "4178",
        "district_id": "520",
        "subdistrict_name": "พระสิงห์"
    },
    {
        "subdistrict_id": "4179",
        "district_id": "520",
        "subdistrict_name": "หายยา"
    },
    {
        "subdistrict_id": "4180",
        "district_id": "520",
        "subdistrict_name": "ช้างม่อย"
    },
    {
        "subdistrict_id": "4181",
        "district_id": "520",
        "subdistrict_name": "ช้างคลาน"
    },
    {
        "subdistrict_id": "4182",
        "district_id": "520",
        "subdistrict_name": "วัดเกต"
    },
    {
        "subdistrict_id": "4183",
        "district_id": "520",
        "subdistrict_name": "ช้างเผือก"
    },
    {
        "subdistrict_id": "4184",
        "district_id": "520",
        "subdistrict_name": "สุเทพ"
    },
    {
        "subdistrict_id": "4185",
        "district_id": "520",
        "subdistrict_name": "แม่เหียะ"
    },
    {
        "subdistrict_id": "4186",
        "district_id": "520",
        "subdistrict_name": "ป่าแดด"
    },
    {
        "subdistrict_id": "4187",
        "district_id": "520",
        "subdistrict_name": "หนองหอย"
    },
    {
        "subdistrict_id": "4188",
        "district_id": "520",
        "subdistrict_name": "ท่าศาลา"
    },
    {
        "subdistrict_id": "4189",
        "district_id": "520",
        "subdistrict_name": "หนองป่าครั่ง"
    },
    {
        "subdistrict_id": "4190",
        "district_id": "520",
        "subdistrict_name": "ฟ้าฮ่าม"
    },
    {
        "subdistrict_id": "4191",
        "district_id": "520",
        "subdistrict_name": "ป่าตัน"
    },
    {
        "subdistrict_id": "4192",
        "district_id": "520",
        "subdistrict_name": "สันผีเสื้อ"
    },
    {
        "subdistrict_id": "4193",
        "district_id": "521",
        "subdistrict_name": "บ้านหลวง"
    },
    {
        "subdistrict_id": "4194",
        "district_id": "521",
        "subdistrict_name": "ข่วงเปา"
    },
    {
        "subdistrict_id": "4195",
        "district_id": "521",
        "subdistrict_name": "สบเตี๊ยะ"
    },
    {
        "subdistrict_id": "4196",
        "district_id": "521",
        "subdistrict_name": "บ้านแปะ"
    },
    {
        "subdistrict_id": "4197",
        "district_id": "521",
        "subdistrict_name": "ดอยแก้ว"
    },
    {
        "subdistrict_id": "4198",
        "district_id": "521",
        "subdistrict_name": "แม่สอย"
    },
    {
        "subdistrict_id": "4199",
        "district_id": "522",
        "subdistrict_name": "ช่างเคิ่ง"
    },
    {
        "subdistrict_id": "4200",
        "district_id": "522",
        "subdistrict_name": "ท่าผา"
    },
    {
        "subdistrict_id": "4201",
        "district_id": "522",
        "subdistrict_name": "บ้านทับ"
    },
    {
        "subdistrict_id": "4202",
        "district_id": "522",
        "subdistrict_name": "แม่ศึก"
    },
    {
        "subdistrict_id": "4203",
        "district_id": "522",
        "subdistrict_name": "แม่นาจร"
    },
    {
        "subdistrict_id": "4204",
        "district_id": "522",
        "subdistrict_name": "บ้านจันทร์"
    },
    {
        "subdistrict_id": "4205",
        "district_id": "522",
        "subdistrict_name": "ปางหินฝน"
    },
    {
        "subdistrict_id": "4206",
        "district_id": "522",
        "subdistrict_name": "กองแขก"
    },
    {
        "subdistrict_id": "4207",
        "district_id": "522",
        "subdistrict_name": "แม่แดด"
    },
    {
        "subdistrict_id": "4208",
        "district_id": "522",
        "subdistrict_name": "แจ่มหลวง"
    },
    {
        "subdistrict_id": "4209",
        "district_id": "523",
        "subdistrict_name": "เชียงดาว"
    },
    {
        "subdistrict_id": "4210",
        "district_id": "523",
        "subdistrict_name": "เมืองนะ"
    },
    {
        "subdistrict_id": "4211",
        "district_id": "523",
        "subdistrict_name": "เมืองงาย"
    },
    {
        "subdistrict_id": "4212",
        "district_id": "523",
        "subdistrict_name": "แม่นะ"
    },
    {
        "subdistrict_id": "4213",
        "district_id": "523",
        "subdistrict_name": "เมืองคอง"
    },
    {
        "subdistrict_id": "4214",
        "district_id": "523",
        "subdistrict_name": "ปิงโค้ง"
    },
    {
        "subdistrict_id": "4215",
        "district_id": "523",
        "subdistrict_name": "ทุ่งข้าวพวง"
    },
    {
        "subdistrict_id": "4216",
        "district_id": "524",
        "subdistrict_name": "เชิงดอย"
    },
    {
        "subdistrict_id": "4217",
        "district_id": "524",
        "subdistrict_name": "สันปูเลย"
    },
    {
        "subdistrict_id": "4218",
        "district_id": "524",
        "subdistrict_name": "ลวงเหนือ"
    },
    {
        "subdistrict_id": "4219",
        "district_id": "524",
        "subdistrict_name": "ป่าป้อง"
    },
    {
        "subdistrict_id": "4220",
        "district_id": "524",
        "subdistrict_name": "สง่าบ้าน"
    },
    {
        "subdistrict_id": "4221",
        "district_id": "524",
        "subdistrict_name": "ป่าลาน"
    },
    {
        "subdistrict_id": "4222",
        "district_id": "524",
        "subdistrict_name": "ตลาดขวัญ"
    },
    {
        "subdistrict_id": "4223",
        "district_id": "524",
        "subdistrict_name": "สำราญราษฎร์"
    },
    {
        "subdistrict_id": "4224",
        "district_id": "524",
        "subdistrict_name": "แม่คือ"
    },
    {
        "subdistrict_id": "4225",
        "district_id": "524",
        "subdistrict_name": "ตลาดใหญ่"
    },
    {
        "subdistrict_id": "4226",
        "district_id": "524",
        "subdistrict_name": "แม่ฮ้อยเงิน"
    },
    {
        "subdistrict_id": "4227",
        "district_id": "524",
        "subdistrict_name": "แม่โป่ง"
    },
    {
        "subdistrict_id": "4228",
        "district_id": "524",
        "subdistrict_name": "ป่าเมี่ยง"
    },
    {
        "subdistrict_id": "4229",
        "district_id": "524",
        "subdistrict_name": "เทพเสด็จ"
    },
    {
        "subdistrict_id": "4230",
        "district_id": "525",
        "subdistrict_name": "สันมหาพน"
    },
    {
        "subdistrict_id": "4231",
        "district_id": "525",
        "subdistrict_name": "แม่แตง"
    },
    {
        "subdistrict_id": "4232",
        "district_id": "525",
        "subdistrict_name": "ขี้เหล็ก"
    },
    {
        "subdistrict_id": "4233",
        "district_id": "525",
        "subdistrict_name": "ช่อแล"
    },
    {
        "subdistrict_id": "4234",
        "district_id": "525",
        "subdistrict_name": "แม่หอพระ"
    },
    {
        "subdistrict_id": "4235",
        "district_id": "525",
        "subdistrict_name": "สบเปิง"
    },
    {
        "subdistrict_id": "4236",
        "district_id": "525",
        "subdistrict_name": "บ้านเป้า"
    },
    {
        "subdistrict_id": "4237",
        "district_id": "525",
        "subdistrict_name": "สันป่ายาง"
    },
    {
        "subdistrict_id": "4238",
        "district_id": "525",
        "subdistrict_name": "ป่าแป๋"
    },
    {
        "subdistrict_id": "4239",
        "district_id": "525",
        "subdistrict_name": "เมืองก๋าย"
    },
    {
        "subdistrict_id": "4240",
        "district_id": "525",
        "subdistrict_name": "บ้านช้าง"
    },
    {
        "subdistrict_id": "4241",
        "district_id": "525",
        "subdistrict_name": "กื้ดช้าง"
    },
    {
        "subdistrict_id": "4242",
        "district_id": "525",
        "subdistrict_name": "อินทขิล"
    },
    {
        "subdistrict_id": "4243",
        "district_id": "525",
        "subdistrict_name": "สมก๋าย"
    },
    {
        "subdistrict_id": "4244",
        "district_id": "526",
        "subdistrict_name": "ริมใต้"
    },
    {
        "subdistrict_id": "4245",
        "district_id": "526",
        "subdistrict_name": "ริมเหนือ"
    },
    {
        "subdistrict_id": "4246",
        "district_id": "526",
        "subdistrict_name": "สันโป่ง"
    },
    {
        "subdistrict_id": "4247",
        "district_id": "526",
        "subdistrict_name": "ขี้เหล็ก"
    },
    {
        "subdistrict_id": "4248",
        "district_id": "526",
        "subdistrict_name": "สะลวง"
    },
    {
        "subdistrict_id": "4249",
        "district_id": "526",
        "subdistrict_name": "ห้วยทราย"
    },
    {
        "subdistrict_id": "4250",
        "district_id": "526",
        "subdistrict_name": "แม่แรม"
    },
    {
        "subdistrict_id": "4251",
        "district_id": "526",
        "subdistrict_name": "โป่งแยง"
    },
    {
        "subdistrict_id": "4252",
        "district_id": "526",
        "subdistrict_name": "แม่สา"
    },
    {
        "subdistrict_id": "4253",
        "district_id": "526",
        "subdistrict_name": "ดอนแก้ว"
    },
    {
        "subdistrict_id": "4254",
        "district_id": "526",
        "subdistrict_name": "เหมืองแก้ว"
    },
    {
        "subdistrict_id": "4255",
        "district_id": "527",
        "subdistrict_name": "สะเมิงใต้"
    },
    {
        "subdistrict_id": "4256",
        "district_id": "527",
        "subdistrict_name": "สะเมิงเหนือ"
    },
    {
        "subdistrict_id": "4257",
        "district_id": "527",
        "subdistrict_name": "แม่สาบ"
    },
    {
        "subdistrict_id": "4258",
        "district_id": "527",
        "subdistrict_name": "บ่อแก้ว"
    },
    {
        "subdistrict_id": "4259",
        "district_id": "527",
        "subdistrict_name": "ยั้งเมิน"
    },
    {
        "subdistrict_id": "4260",
        "district_id": "528",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4261",
        "district_id": "528",
        "subdistrict_name": "ม่อนปิ่น"
    },
    {
        "subdistrict_id": "4262",
        "district_id": "528",
        "subdistrict_name": "แม่งอน"
    },
    {
        "subdistrict_id": "4263",
        "district_id": "528",
        "subdistrict_name": "แม่สูน"
    },
    {
        "subdistrict_id": "4264",
        "district_id": "528",
        "subdistrict_name": "สันทราย"
    },
    {
        "subdistrict_id": "4265",
        "district_id": "528",
        "subdistrict_name": "แม่คะ"
    },
    {
        "subdistrict_id": "4266",
        "district_id": "528",
        "subdistrict_name": "แม่ข่า"
    },
    {
        "subdistrict_id": "4267",
        "district_id": "528",
        "subdistrict_name": "โป่งน้ำร้อน"
    },
    {
        "subdistrict_id": "4268",
        "district_id": "529",
        "subdistrict_name": "แม่อาย"
    },
    {
        "subdistrict_id": "4269",
        "district_id": "529",
        "subdistrict_name": "แม่สาว"
    },
    {
        "subdistrict_id": "4270",
        "district_id": "529",
        "subdistrict_name": "สันต้นหมื้อ"
    },
    {
        "subdistrict_id": "4271",
        "district_id": "529",
        "subdistrict_name": "แม่นาวาง"
    },
    {
        "subdistrict_id": "4272",
        "district_id": "529",
        "subdistrict_name": "ท่าตอน"
    },
    {
        "subdistrict_id": "4273",
        "district_id": "529",
        "subdistrict_name": "บ้านหลวง"
    },
    {
        "subdistrict_id": "4274",
        "district_id": "529",
        "subdistrict_name": "มะลิกา"
    },
    {
        "subdistrict_id": "4275",
        "district_id": "530",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4276",
        "district_id": "530",
        "subdistrict_name": "ทุ่งหลวง"
    },
    {
        "subdistrict_id": "4277",
        "district_id": "530",
        "subdistrict_name": "ป่าตุ้ม"
    },
    {
        "subdistrict_id": "4278",
        "district_id": "530",
        "subdistrict_name": "ป่าไหน่"
    },
    {
        "subdistrict_id": "4279",
        "district_id": "530",
        "subdistrict_name": "สันทราย"
    },
    {
        "subdistrict_id": "4280",
        "district_id": "530",
        "subdistrict_name": "บ้านโป่ง"
    },
    {
        "subdistrict_id": "4281",
        "district_id": "530",
        "subdistrict_name": "น้ำแพร่"
    },
    {
        "subdistrict_id": "4282",
        "district_id": "530",
        "subdistrict_name": "เขื่อนผาก"
    },
    {
        "subdistrict_id": "4283",
        "district_id": "530",
        "subdistrict_name": "แม่แวน"
    },
    {
        "subdistrict_id": "4284",
        "district_id": "530",
        "subdistrict_name": "แม่ปั๋ง"
    },
    {
        "subdistrict_id": "4285",
        "district_id": "530",
        "subdistrict_name": "โหล่งขอด"
    },
    {
        "subdistrict_id": "4286",
        "district_id": "531",
        "subdistrict_name": "ยุหว่า"
    },
    {
        "subdistrict_id": "4287",
        "district_id": "531",
        "subdistrict_name": "สันกลาง"
    },
    {
        "subdistrict_id": "4288",
        "district_id": "531",
        "subdistrict_name": "ท่าวังพร้าว"
    },
    {
        "subdistrict_id": "4289",
        "district_id": "531",
        "subdistrict_name": "มะขามหลวง"
    },
    {
        "subdistrict_id": "4290",
        "district_id": "531",
        "subdistrict_name": "แม่ก๊า"
    },
    {
        "subdistrict_id": "4291",
        "district_id": "531",
        "subdistrict_name": "บ้านแม"
    },
    {
        "subdistrict_id": "4292",
        "district_id": "531",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "4293",
        "district_id": "531",
        "subdistrict_name": "ทุ่งสะโตก"
    },
    {
        "subdistrict_id": "4294",
        "district_id": "531",
        "subdistrict_name": "ทุ่งต้อม"
    },
    {
        "subdistrict_id": "4295",
        "district_id": "531",
        "subdistrict_name": "น้ำบ่อหลวง"
    },
    {
        "subdistrict_id": "4296",
        "district_id": "531",
        "subdistrict_name": "มะขุนหวาน"
    },
    {
        "subdistrict_id": "4297",
        "district_id": "532",
        "subdistrict_name": "สันกำแพง"
    },
    {
        "subdistrict_id": "4298",
        "district_id": "532",
        "subdistrict_name": "ทรายมูล"
    },
    {
        "subdistrict_id": "4299",
        "district_id": "532",
        "subdistrict_name": "ร้องวัวแดง"
    },
    {
        "subdistrict_id": "4300",
        "district_id": "532",
        "subdistrict_name": "บวกค้าง"
    },
    {
        "subdistrict_id": "4301",
        "district_id": "532",
        "subdistrict_name": "แช่ช้าง"
    },
    {
        "subdistrict_id": "4302",
        "district_id": "532",
        "subdistrict_name": "ออนใต้"
    },
    {
        "subdistrict_id": "4303",
        "district_id": "532",
        "subdistrict_name": "แม่ปูคา"
    },
    {
        "subdistrict_id": "4304",
        "district_id": "532",
        "subdistrict_name": "ห้วยทราย"
    },
    {
        "subdistrict_id": "4305",
        "district_id": "532",
        "subdistrict_name": "ต้นเปา"
    },
    {
        "subdistrict_id": "4306",
        "district_id": "532",
        "subdistrict_name": "สันกลาง"
    },
    {
        "subdistrict_id": "4307",
        "district_id": "533",
        "subdistrict_name": "สันทรายหลวง"
    },
    {
        "subdistrict_id": "4308",
        "district_id": "533",
        "subdistrict_name": "สันทรายน้อย"
    },
    {
        "subdistrict_id": "4309",
        "district_id": "533",
        "subdistrict_name": "สันพระเนตร"
    },
    {
        "subdistrict_id": "4310",
        "district_id": "533",
        "subdistrict_name": "สันนาเม็ง"
    },
    {
        "subdistrict_id": "4311",
        "district_id": "533",
        "subdistrict_name": "สันป่าเปา"
    },
    {
        "subdistrict_id": "4312",
        "district_id": "533",
        "subdistrict_name": "หนองแหย่ง"
    },
    {
        "subdistrict_id": "4313",
        "district_id": "533",
        "subdistrict_name": "หนองจ๊อม"
    },
    {
        "subdistrict_id": "4314",
        "district_id": "533",
        "subdistrict_name": "หนองหาร"
    },
    {
        "subdistrict_id": "4315",
        "district_id": "533",
        "subdistrict_name": "แม่แฝก"
    },
    {
        "subdistrict_id": "4316",
        "district_id": "533",
        "subdistrict_name": "แม่แฝกใหม่"
    },
    {
        "subdistrict_id": "4317",
        "district_id": "533",
        "subdistrict_name": "เมืองเล็น"
    },
    {
        "subdistrict_id": "4318",
        "district_id": "533",
        "subdistrict_name": "ป่าไผ่"
    },
    {
        "subdistrict_id": "4319",
        "district_id": "534",
        "subdistrict_name": "หางดง"
    },
    {
        "subdistrict_id": "4320",
        "district_id": "534",
        "subdistrict_name": "หนองแก๋ว"
    },
    {
        "subdistrict_id": "4321",
        "district_id": "534",
        "subdistrict_name": "หารแก้ว"
    },
    {
        "subdistrict_id": "4322",
        "district_id": "534",
        "subdistrict_name": "หนองตอง"
    },
    {
        "subdistrict_id": "4323",
        "district_id": "534",
        "subdistrict_name": "ขุนคง"
    },
    {
        "subdistrict_id": "4324",
        "district_id": "534",
        "subdistrict_name": "สบแม่ข่า"
    },
    {
        "subdistrict_id": "4325",
        "district_id": "534",
        "subdistrict_name": "บ้านแหวน"
    },
    {
        "subdistrict_id": "4326",
        "district_id": "534",
        "subdistrict_name": "สันผักหวาน"
    },
    {
        "subdistrict_id": "4327",
        "district_id": "534",
        "subdistrict_name": "หนองควาย"
    },
    {
        "subdistrict_id": "4328",
        "district_id": "534",
        "subdistrict_name": "บ้านปง"
    },
    {
        "subdistrict_id": "4329",
        "district_id": "534",
        "subdistrict_name": "น้ำแพร่"
    },
    {
        "subdistrict_id": "4330",
        "district_id": "535",
        "subdistrict_name": "หางดง"
    },
    {
        "subdistrict_id": "4331",
        "district_id": "535",
        "subdistrict_name": "ฮอด"
    },
    {
        "subdistrict_id": "4332",
        "district_id": "535",
        "subdistrict_name": "บ้านตาล"
    },
    {
        "subdistrict_id": "4333",
        "district_id": "535",
        "subdistrict_name": "บ่อหลวง"
    },
    {
        "subdistrict_id": "4334",
        "district_id": "535",
        "subdistrict_name": "บ่อสลี"
    },
    {
        "subdistrict_id": "4335",
        "district_id": "535",
        "subdistrict_name": "นาคอเรือ"
    },
    {
        "subdistrict_id": "4336",
        "district_id": "536",
        "subdistrict_name": "ดอยเต่า"
    },
    {
        "subdistrict_id": "4337",
        "district_id": "536",
        "subdistrict_name": "ท่าเดื่อ"
    },
    {
        "subdistrict_id": "4338",
        "district_id": "536",
        "subdistrict_name": "มืดกา"
    },
    {
        "subdistrict_id": "4339",
        "district_id": "536",
        "subdistrict_name": "บ้านแอ่น"
    },
    {
        "subdistrict_id": "4340",
        "district_id": "536",
        "subdistrict_name": "บงตัน"
    },
    {
        "subdistrict_id": "4341",
        "district_id": "536",
        "subdistrict_name": "โปงทุ่ง"
    },
    {
        "subdistrict_id": "4342",
        "district_id": "537",
        "subdistrict_name": "อมก๋อย"
    },
    {
        "subdistrict_id": "4343",
        "district_id": "537",
        "subdistrict_name": "ยางเปียง"
    },
    {
        "subdistrict_id": "4344",
        "district_id": "537",
        "subdistrict_name": "แม่ตื่น"
    },
    {
        "subdistrict_id": "4345",
        "district_id": "537",
        "subdistrict_name": "ม่อนจอง"
    },
    {
        "subdistrict_id": "4346",
        "district_id": "537",
        "subdistrict_name": "สบโขง"
    },
    {
        "subdistrict_id": "4347",
        "district_id": "537",
        "subdistrict_name": "นาเกียน"
    },
    {
        "subdistrict_id": "4348",
        "district_id": "538",
        "subdistrict_name": "ยางเนิ้ง"
    },
    {
        "subdistrict_id": "4349",
        "district_id": "538",
        "subdistrict_name": "สารภี"
    },
    {
        "subdistrict_id": "4350",
        "district_id": "538",
        "subdistrict_name": "ชมภู"
    },
    {
        "subdistrict_id": "4351",
        "district_id": "538",
        "subdistrict_name": "ไชยสถาน"
    },
    {
        "subdistrict_id": "4352",
        "district_id": "538",
        "subdistrict_name": "ขัวมุง"
    },
    {
        "subdistrict_id": "4353",
        "district_id": "538",
        "subdistrict_name": "หนองแฝก"
    },
    {
        "subdistrict_id": "4354",
        "district_id": "538",
        "subdistrict_name": "หนองผึ้ง"
    },
    {
        "subdistrict_id": "4355",
        "district_id": "538",
        "subdistrict_name": "ท่ากว้าง"
    },
    {
        "subdistrict_id": "4356",
        "district_id": "538",
        "subdistrict_name": "ดอนแก้ว"
    },
    {
        "subdistrict_id": "4357",
        "district_id": "538",
        "subdistrict_name": "ท่าวังตาล"
    },
    {
        "subdistrict_id": "4358",
        "district_id": "538",
        "subdistrict_name": "สันทราย"
    },
    {
        "subdistrict_id": "4359",
        "district_id": "538",
        "subdistrict_name": "ป่าบง"
    },
    {
        "subdistrict_id": "4360",
        "district_id": "539",
        "subdistrict_name": "เมืองแหง"
    },
    {
        "subdistrict_id": "4361",
        "district_id": "539",
        "subdistrict_name": "เปียงหลวง"
    },
    {
        "subdistrict_id": "4362",
        "district_id": "539",
        "subdistrict_name": "แสนไห"
    },
    {
        "subdistrict_id": "4363",
        "district_id": "540",
        "subdistrict_name": "ปงตำ"
    },
    {
        "subdistrict_id": "4364",
        "district_id": "540",
        "subdistrict_name": "ศรีดงเย็น"
    },
    {
        "subdistrict_id": "4365",
        "district_id": "540",
        "subdistrict_name": "แม่ทะลบ"
    },
    {
        "subdistrict_id": "4366",
        "district_id": "540",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "4367",
        "district_id": "541",
        "subdistrict_name": "บ้านกาด"
    },
    {
        "subdistrict_id": "4368",
        "district_id": "541",
        "subdistrict_name": "ทุ่งปี้"
    },
    {
        "subdistrict_id": "4369",
        "district_id": "541",
        "subdistrict_name": "ทุ่งรวงทอง"
    },
    {
        "subdistrict_id": "4370",
        "district_id": "541",
        "subdistrict_name": "แม่วิน"
    },
    {
        "subdistrict_id": "4371",
        "district_id": "541",
        "subdistrict_name": "ดอนเปา"
    },
    {
        "subdistrict_id": "4372",
        "district_id": "542",
        "subdistrict_name": "ออนเหนือ"
    },
    {
        "subdistrict_id": "4373",
        "district_id": "542",
        "subdistrict_name": "ออนกลาง"
    },
    {
        "subdistrict_id": "4374",
        "district_id": "542",
        "subdistrict_name": "บ้านสหกรณ์"
    },
    {
        "subdistrict_id": "4375",
        "district_id": "542",
        "subdistrict_name": "ห้วยแก้ว"
    },
    {
        "subdistrict_id": "4376",
        "district_id": "542",
        "subdistrict_name": "แม่ทา"
    },
    {
        "subdistrict_id": "4377",
        "district_id": "542",
        "subdistrict_name": "ทาเหนือ"
    },
    {
        "subdistrict_id": "4378",
        "district_id": "543",
        "subdistrict_name": "ดอยหล่อ"
    },
    {
        "subdistrict_id": "4379",
        "district_id": "543",
        "subdistrict_name": "สองแคว"
    },
    {
        "subdistrict_id": "4380",
        "district_id": "543",
        "subdistrict_name": "ยางคราม"
    },
    {
        "subdistrict_id": "4381",
        "district_id": "543",
        "subdistrict_name": "สันติสุข"
    },
    {
        "subdistrict_id": "4382",
        "district_id": "544",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "4383",
        "district_id": "544",
        "subdistrict_name": "เหมืองง่า"
    },
    {
        "subdistrict_id": "4384",
        "district_id": "544",
        "subdistrict_name": "อุโมงค์"
    },
    {
        "subdistrict_id": "4385",
        "district_id": "544",
        "subdistrict_name": "หนองช้างคืน"
    },
    {
        "subdistrict_id": "4386",
        "district_id": "544",
        "subdistrict_name": "ประตูป่า"
    },
    {
        "subdistrict_id": "4387",
        "district_id": "544",
        "subdistrict_name": "ริมปิง"
    },
    {
        "subdistrict_id": "4388",
        "district_id": "544",
        "subdistrict_name": "ต้นธง"
    },
    {
        "subdistrict_id": "4389",
        "district_id": "544",
        "subdistrict_name": "บ้านแป้น"
    },
    {
        "subdistrict_id": "4390",
        "district_id": "544",
        "subdistrict_name": "เหมืองจี้"
    },
    {
        "subdistrict_id": "4391",
        "district_id": "544",
        "subdistrict_name": "ป่าสัก"
    },
    {
        "subdistrict_id": "4392",
        "district_id": "544",
        "subdistrict_name": "เวียงยอง"
    },
    {
        "subdistrict_id": "4393",
        "district_id": "544",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "4394",
        "district_id": "544",
        "subdistrict_name": "มะเขือแจ้"
    },
    {
        "subdistrict_id": "4395",
        "district_id": "544",
        "subdistrict_name": "ศรีบัวบาน"
    },
    {
        "subdistrict_id": "4396",
        "district_id": "544",
        "subdistrict_name": "หนองหนาม"
    },
    {
        "subdistrict_id": "4397",
        "district_id": "545",
        "subdistrict_name": "ทาปลาดุก"
    },
    {
        "subdistrict_id": "4398",
        "district_id": "545",
        "subdistrict_name": "ทาสบเส้า"
    },
    {
        "subdistrict_id": "4399",
        "district_id": "545",
        "subdistrict_name": "ทากาศ"
    },
    {
        "subdistrict_id": "4400",
        "district_id": "545",
        "subdistrict_name": "ทาขุมเงิน"
    },
    {
        "subdistrict_id": "4401",
        "district_id": "545",
        "subdistrict_name": "ทาทุ่งหลวง"
    },
    {
        "subdistrict_id": "4402",
        "district_id": "545",
        "subdistrict_name": "ทาแม่ลอบ"
    },
    {
        "subdistrict_id": "4403",
        "district_id": "546",
        "subdistrict_name": "บ้านโฮ่ง"
    },
    {
        "subdistrict_id": "4404",
        "district_id": "546",
        "subdistrict_name": "ป่าพลู"
    },
    {
        "subdistrict_id": "4405",
        "district_id": "546",
        "subdistrict_name": "เหล่ายาว"
    },
    {
        "subdistrict_id": "4406",
        "district_id": "546",
        "subdistrict_name": "ศรีเตี้ย"
    },
    {
        "subdistrict_id": "4407",
        "district_id": "546",
        "subdistrict_name": "หนองปลาสะวาย"
    },
    {
        "subdistrict_id": "4408",
        "district_id": "547",
        "subdistrict_name": "ลี้"
    },
    {
        "subdistrict_id": "4409",
        "district_id": "547",
        "subdistrict_name": "แม่ตืน"
    },
    {
        "subdistrict_id": "4410",
        "district_id": "547",
        "subdistrict_name": "นาทราย"
    },
    {
        "subdistrict_id": "4411",
        "district_id": "547",
        "subdistrict_name": "ดงดำ"
    },
    {
        "subdistrict_id": "4412",
        "district_id": "547",
        "subdistrict_name": "ก้อ"
    },
    {
        "subdistrict_id": "4413",
        "district_id": "547",
        "subdistrict_name": "แม่ลาน"
    },
    {
        "subdistrict_id": "4414",
        "district_id": "547",
        "subdistrict_name": "ป่าไผ่"
    },
    {
        "subdistrict_id": "4415",
        "district_id": "547",
        "subdistrict_name": "ศรีวิชัย"
    },
    {
        "subdistrict_id": "4416",
        "district_id": "548",
        "subdistrict_name": "ทุ่งหัวช้าง"
    },
    {
        "subdistrict_id": "4417",
        "district_id": "548",
        "subdistrict_name": "บ้านปวง"
    },
    {
        "subdistrict_id": "4418",
        "district_id": "548",
        "subdistrict_name": "ตะเคียนปม"
    },
    {
        "subdistrict_id": "4419",
        "district_id": "549",
        "subdistrict_name": "ปากบ่อง"
    },
    {
        "subdistrict_id": "4420",
        "district_id": "549",
        "subdistrict_name": "ป่าซาง"
    },
    {
        "subdistrict_id": "4421",
        "district_id": "549",
        "subdistrict_name": "แม่แรง"
    },
    {
        "subdistrict_id": "4422",
        "district_id": "549",
        "subdistrict_name": "ม่วงน้อย"
    },
    {
        "subdistrict_id": "4423",
        "district_id": "549",
        "subdistrict_name": "บ้านเรือน"
    },
    {
        "subdistrict_id": "4424",
        "district_id": "549",
        "subdistrict_name": "มะกอก"
    },
    {
        "subdistrict_id": "4425",
        "district_id": "549",
        "subdistrict_name": "ท่าตุ้ม"
    },
    {
        "subdistrict_id": "4426",
        "district_id": "549",
        "subdistrict_name": "น้ำดิบ"
    },
    {
        "subdistrict_id": "4427",
        "district_id": "549",
        "subdistrict_name": "นครเจดีย์"
    },
    {
        "subdistrict_id": "4428",
        "district_id": "550",
        "subdistrict_name": "บ้านธิ"
    },
    {
        "subdistrict_id": "4429",
        "district_id": "550",
        "subdistrict_name": "ห้วยยาบ"
    },
    {
        "subdistrict_id": "4430",
        "district_id": "551",
        "subdistrict_name": "หนองล่อง"
    },
    {
        "subdistrict_id": "4431",
        "district_id": "551",
        "subdistrict_name": "หนองยวง"
    },
    {
        "subdistrict_id": "4432",
        "district_id": "551",
        "subdistrict_name": "วังผาง"
    },
    {
        "subdistrict_id": "4433",
        "district_id": "552",
        "subdistrict_name": "เวียงเหนือ"
    },
    {
        "subdistrict_id": "4434",
        "district_id": "552",
        "subdistrict_name": "หัวเวียง"
    },
    {
        "subdistrict_id": "4435",
        "district_id": "552",
        "subdistrict_name": "สวนดอก"
    },
    {
        "subdistrict_id": "4436",
        "district_id": "552",
        "subdistrict_name": "สบตุ๋ย"
    },
    {
        "subdistrict_id": "4437",
        "district_id": "552",
        "subdistrict_name": "พระบาท"
    },
    {
        "subdistrict_id": "4438",
        "district_id": "552",
        "subdistrict_name": "ชมพู"
    },
    {
        "subdistrict_id": "4439",
        "district_id": "552",
        "subdistrict_name": "กล้วยแพะ"
    },
    {
        "subdistrict_id": "4440",
        "district_id": "552",
        "subdistrict_name": "ปงแสนทอง"
    },
    {
        "subdistrict_id": "4441",
        "district_id": "552",
        "subdistrict_name": "บ้านแลง"
    },
    {
        "subdistrict_id": "4442",
        "district_id": "552",
        "subdistrict_name": "บ้านเสด็จ"
    },
    {
        "subdistrict_id": "4443",
        "district_id": "552",
        "subdistrict_name": "พิชัย"
    },
    {
        "subdistrict_id": "4444",
        "district_id": "552",
        "subdistrict_name": "ทุ่งฝาย"
    },
    {
        "subdistrict_id": "4445",
        "district_id": "552",
        "subdistrict_name": "บ้านเอื้อม"
    },
    {
        "subdistrict_id": "4446",
        "district_id": "552",
        "subdistrict_name": "บ้านเป้า"
    },
    {
        "subdistrict_id": "4447",
        "district_id": "552",
        "subdistrict_name": "บ้านค่า"
    },
    {
        "subdistrict_id": "4448",
        "district_id": "552",
        "subdistrict_name": "บ่อแฮ้ว"
    },
    {
        "subdistrict_id": "4449",
        "district_id": "552",
        "subdistrict_name": "ต้นธงชัย"
    },
    {
        "subdistrict_id": "4450",
        "district_id": "552",
        "subdistrict_name": "นิคมพัฒนา"
    },
    {
        "subdistrict_id": "4451",
        "district_id": "552",
        "subdistrict_name": "บุญนาคพัฒนา"
    },
    {
        "subdistrict_id": "4452",
        "district_id": "553",
        "subdistrict_name": "บ้านดง"
    },
    {
        "subdistrict_id": "4453",
        "district_id": "553",
        "subdistrict_name": "นาสัก"
    },
    {
        "subdistrict_id": "4454",
        "district_id": "553",
        "subdistrict_name": "จางเหนือ"
    },
    {
        "subdistrict_id": "4455",
        "district_id": "553",
        "subdistrict_name": "แม่เมาะ"
    },
    {
        "subdistrict_id": "4456",
        "district_id": "553",
        "subdistrict_name": "สบป้าด"
    },
    {
        "subdistrict_id": "4457",
        "district_id": "554",
        "subdistrict_name": "ลำปางหลวง"
    },
    {
        "subdistrict_id": "4458",
        "district_id": "554",
        "subdistrict_name": "นาแก้ว"
    },
    {
        "subdistrict_id": "4459",
        "district_id": "554",
        "subdistrict_name": "ไหล่หิน"
    },
    {
        "subdistrict_id": "4460",
        "district_id": "554",
        "subdistrict_name": "วังพร้าว"
    },
    {
        "subdistrict_id": "4461",
        "district_id": "554",
        "subdistrict_name": "ศาลา"
    },
    {
        "subdistrict_id": "4462",
        "district_id": "554",
        "subdistrict_name": "เกาะคา"
    },
    {
        "subdistrict_id": "4463",
        "district_id": "554",
        "subdistrict_name": "นาแส่ง"
    },
    {
        "subdistrict_id": "4464",
        "district_id": "554",
        "subdistrict_name": "ท่าผา"
    },
    {
        "subdistrict_id": "4465",
        "district_id": "554",
        "subdistrict_name": "ใหม่พัฒนา"
    },
    {
        "subdistrict_id": "4466",
        "district_id": "555",
        "subdistrict_name": "ทุ่งงาม"
    },
    {
        "subdistrict_id": "4467",
        "district_id": "555",
        "subdistrict_name": "เสริมขวา"
    },
    {
        "subdistrict_id": "4468",
        "district_id": "555",
        "subdistrict_name": "เสริมซ้าย"
    },
    {
        "subdistrict_id": "4469",
        "district_id": "555",
        "subdistrict_name": "เสริมกลาง"
    },
    {
        "subdistrict_id": "4470",
        "district_id": "556",
        "subdistrict_name": "หลวงเหนือ"
    },
    {
        "subdistrict_id": "4471",
        "district_id": "556",
        "subdistrict_name": "หลวงใต้"
    },
    {
        "subdistrict_id": "4472",
        "district_id": "556",
        "subdistrict_name": "บ้านโป่ง"
    },
    {
        "subdistrict_id": "4473",
        "district_id": "556",
        "subdistrict_name": "บ้านร้อง"
    },
    {
        "subdistrict_id": "4474",
        "district_id": "556",
        "subdistrict_name": "ปงเตา"
    },
    {
        "subdistrict_id": "4475",
        "district_id": "556",
        "subdistrict_name": "นาแก"
    },
    {
        "subdistrict_id": "4476",
        "district_id": "556",
        "subdistrict_name": "บ้านอ้อน"
    },
    {
        "subdistrict_id": "4477",
        "district_id": "556",
        "subdistrict_name": "บ้านแหง"
    },
    {
        "subdistrict_id": "4478",
        "district_id": "556",
        "subdistrict_name": "บ้านหวด"
    },
    {
        "subdistrict_id": "4479",
        "district_id": "556",
        "subdistrict_name": "แม่ตีบ"
    },
    {
        "subdistrict_id": "4480",
        "district_id": "557",
        "subdistrict_name": "แจ้ห่ม"
    },
    {
        "subdistrict_id": "4481",
        "district_id": "557",
        "subdistrict_name": "บ้านสา"
    },
    {
        "subdistrict_id": "4482",
        "district_id": "557",
        "subdistrict_name": "ปงดอน"
    },
    {
        "subdistrict_id": "4483",
        "district_id": "557",
        "subdistrict_name": "แม่สุก"
    },
    {
        "subdistrict_id": "4484",
        "district_id": "557",
        "subdistrict_name": "เมืองมาย"
    },
    {
        "subdistrict_id": "4485",
        "district_id": "557",
        "subdistrict_name": "ทุ่งผึ้ง"
    },
    {
        "subdistrict_id": "4486",
        "district_id": "557",
        "subdistrict_name": "วิเชตนคร"
    },
    {
        "subdistrict_id": "4487",
        "district_id": "558",
        "subdistrict_name": "ทุ่งฮั้ว"
    },
    {
        "subdistrict_id": "4488",
        "district_id": "558",
        "subdistrict_name": "วังเหนือ"
    },
    {
        "subdistrict_id": "4489",
        "district_id": "558",
        "subdistrict_name": "วังใต้"
    },
    {
        "subdistrict_id": "4490",
        "district_id": "558",
        "subdistrict_name": "ร่องเคาะ"
    },
    {
        "subdistrict_id": "4491",
        "district_id": "558",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "4492",
        "district_id": "558",
        "subdistrict_name": "วังซ้าย"
    },
    {
        "subdistrict_id": "4493",
        "district_id": "558",
        "subdistrict_name": "วังแก้ว"
    },
    {
        "subdistrict_id": "4494",
        "district_id": "558",
        "subdistrict_name": "วังทรายคำ"
    },
    {
        "subdistrict_id": "4495",
        "district_id": "559",
        "subdistrict_name": "ล้อมแรด"
    },
    {
        "subdistrict_id": "4496",
        "district_id": "559",
        "subdistrict_name": "แม่วะ"
    },
    {
        "subdistrict_id": "4497",
        "district_id": "559",
        "subdistrict_name": "แม่ปะ"
    },
    {
        "subdistrict_id": "4498",
        "district_id": "559",
        "subdistrict_name": "แม่มอก"
    },
    {
        "subdistrict_id": "4499",
        "district_id": "559",
        "subdistrict_name": "เวียงมอก"
    },
    {
        "subdistrict_id": "4500",
        "district_id": "559",
        "subdistrict_name": "นาโป่ง"
    },
    {
        "subdistrict_id": "4501",
        "district_id": "559",
        "subdistrict_name": "แม่ถอด"
    },
    {
        "subdistrict_id": "4502",
        "district_id": "559",
        "subdistrict_name": "เถินบุรี"
    },
    {
        "subdistrict_id": "4503",
        "district_id": "560",
        "subdistrict_name": "แม่พริก"
    },
    {
        "subdistrict_id": "4504",
        "district_id": "560",
        "subdistrict_name": "ผาปัง"
    },
    {
        "subdistrict_id": "4505",
        "district_id": "560",
        "subdistrict_name": "แม่ปุ"
    },
    {
        "subdistrict_id": "4506",
        "district_id": "560",
        "subdistrict_name": "พระบาทวังตวง"
    },
    {
        "subdistrict_id": "4507",
        "district_id": "561",
        "subdistrict_name": "แม่ทะ"
    },
    {
        "subdistrict_id": "4508",
        "district_id": "561",
        "subdistrict_name": "นาครัว"
    },
    {
        "subdistrict_id": "4509",
        "district_id": "561",
        "subdistrict_name": "ป่าตัน"
    },
    {
        "subdistrict_id": "4510",
        "district_id": "561",
        "subdistrict_name": "บ้านกิ่ว"
    },
    {
        "subdistrict_id": "4511",
        "district_id": "561",
        "subdistrict_name": "บ้านบอม"
    },
    {
        "subdistrict_id": "4512",
        "district_id": "561",
        "subdistrict_name": "น้ำโจ้"
    },
    {
        "subdistrict_id": "4513",
        "district_id": "561",
        "subdistrict_name": "ดอนไฟ"
    },
    {
        "subdistrict_id": "4514",
        "district_id": "561",
        "subdistrict_name": "หัวเสือ"
    },
    {
        "subdistrict_id": "4515",
        "district_id": "561",
        "subdistrict_name": "วังเงิน"
    },
    {
        "subdistrict_id": "4516",
        "district_id": "561",
        "subdistrict_name": "สันดอนแก้ว"
    },
    {
        "subdistrict_id": "4517",
        "district_id": "562",
        "subdistrict_name": "สบปราบ"
    },
    {
        "subdistrict_id": "4518",
        "district_id": "562",
        "subdistrict_name": "สมัย"
    },
    {
        "subdistrict_id": "4519",
        "district_id": "562",
        "subdistrict_name": "แม่กัวะ"
    },
    {
        "subdistrict_id": "4520",
        "district_id": "562",
        "subdistrict_name": "นายาง"
    },
    {
        "subdistrict_id": "4521",
        "district_id": "563",
        "subdistrict_name": "ห้างฉัตร"
    },
    {
        "subdistrict_id": "4522",
        "district_id": "563",
        "subdistrict_name": "หนองหล่ม"
    },
    {
        "subdistrict_id": "4523",
        "district_id": "563",
        "subdistrict_name": "เมืองยาว"
    },
    {
        "subdistrict_id": "4524",
        "district_id": "563",
        "subdistrict_name": "ปงยางคก"
    },
    {
        "subdistrict_id": "4525",
        "district_id": "563",
        "subdistrict_name": "เวียงตาล"
    },
    {
        "subdistrict_id": "4526",
        "district_id": "563",
        "subdistrict_name": "แม่สัน"
    },
    {
        "subdistrict_id": "4527",
        "district_id": "563",
        "subdistrict_name": "วอแก้ว"
    },
    {
        "subdistrict_id": "4528",
        "district_id": "564",
        "subdistrict_name": "เมืองปาน"
    },
    {
        "subdistrict_id": "4529",
        "district_id": "564",
        "subdistrict_name": "บ้านขอ"
    },
    {
        "subdistrict_id": "4530",
        "district_id": "564",
        "subdistrict_name": "ทุ่งกว๋าว"
    },
    {
        "subdistrict_id": "4531",
        "district_id": "564",
        "subdistrict_name": "แจ้ซ้อน"
    },
    {
        "subdistrict_id": "4532",
        "district_id": "564",
        "subdistrict_name": "หัวเมือง"
    },
    {
        "subdistrict_id": "4533",
        "district_id": "565",
        "subdistrict_name": "ท่าอิฐ"
    },
    {
        "subdistrict_id": "4534",
        "district_id": "565",
        "subdistrict_name": "ท่าเสา"
    },
    {
        "subdistrict_id": "4535",
        "district_id": "565",
        "subdistrict_name": "บ้านเกาะ"
    },
    {
        "subdistrict_id": "4536",
        "district_id": "565",
        "subdistrict_name": "ป่าเซ่า"
    },
    {
        "subdistrict_id": "4537",
        "district_id": "565",
        "subdistrict_name": "คุ้งตะเภา"
    },
    {
        "subdistrict_id": "4538",
        "district_id": "565",
        "subdistrict_name": "วังกะพี้"
    },
    {
        "subdistrict_id": "4539",
        "district_id": "565",
        "subdistrict_name": "หาดกรวด"
    },
    {
        "subdistrict_id": "4540",
        "district_id": "565",
        "subdistrict_name": "น้ำริด"
    },
    {
        "subdistrict_id": "4541",
        "district_id": "565",
        "subdistrict_name": "งิ้วงาม"
    },
    {
        "subdistrict_id": "4542",
        "district_id": "565",
        "subdistrict_name": "บ้านด่านนาขาม"
    },
    {
        "subdistrict_id": "4543",
        "district_id": "565",
        "subdistrict_name": "บ้านด่าน"
    },
    {
        "subdistrict_id": "4544",
        "district_id": "565",
        "subdistrict_name": "ผาจุก"
    },
    {
        "subdistrict_id": "4545",
        "district_id": "565",
        "subdistrict_name": "วังดิน"
    },
    {
        "subdistrict_id": "4546",
        "district_id": "565",
        "subdistrict_name": "แสนตอ"
    },
    {
        "subdistrict_id": "4547",
        "district_id": "565",
        "subdistrict_name": "หาดงิ้ว"
    },
    {
        "subdistrict_id": "4548",
        "district_id": "565",
        "subdistrict_name": "ขุนฝาง"
    },
    {
        "subdistrict_id": "4549",
        "district_id": "565",
        "subdistrict_name": "ถ้ำฉลอง"
    },
    {
        "subdistrict_id": "4550",
        "district_id": "566",
        "subdistrict_name": "วังแดง"
    },
    {
        "subdistrict_id": "4551",
        "district_id": "566",
        "subdistrict_name": "บ้านแก่ง"
    },
    {
        "subdistrict_id": "4552",
        "district_id": "566",
        "subdistrict_name": "หาดสองแคว"
    },
    {
        "subdistrict_id": "4553",
        "district_id": "566",
        "subdistrict_name": "น้ำอ่าง"
    },
    {
        "subdistrict_id": "4554",
        "district_id": "566",
        "subdistrict_name": "ข่อยสูง"
    },
    {
        "subdistrict_id": "4555",
        "district_id": "567",
        "subdistrict_name": "ท่าปลา"
    },
    {
        "subdistrict_id": "4556",
        "district_id": "567",
        "subdistrict_name": "หาดล้า"
    },
    {
        "subdistrict_id": "4557",
        "district_id": "567",
        "subdistrict_name": "ผาเลือด"
    },
    {
        "subdistrict_id": "4558",
        "district_id": "567",
        "subdistrict_name": "จริม"
    },
    {
        "subdistrict_id": "4559",
        "district_id": "567",
        "subdistrict_name": "น้ำหมัน"
    },
    {
        "subdistrict_id": "4560",
        "district_id": "567",
        "subdistrict_name": "ท่าแฝก"
    },
    {
        "subdistrict_id": "4561",
        "district_id": "567",
        "subdistrict_name": "นางพญา"
    },
    {
        "subdistrict_id": "4562",
        "district_id": "567",
        "subdistrict_name": "ร่วมจิต"
    },
    {
        "subdistrict_id": "4563",
        "district_id": "568",
        "subdistrict_name": "แสนตอ"
    },
    {
        "subdistrict_id": "4564",
        "district_id": "568",
        "subdistrict_name": "บ้านฝาย"
    },
    {
        "subdistrict_id": "4565",
        "district_id": "568",
        "subdistrict_name": "เด่นเหล็ก"
    },
    {
        "subdistrict_id": "4566",
        "district_id": "568",
        "subdistrict_name": "น้ำไคร้"
    },
    {
        "subdistrict_id": "4567",
        "district_id": "568",
        "subdistrict_name": "น้ำไผ่"
    },
    {
        "subdistrict_id": "4568",
        "district_id": "568",
        "subdistrict_name": "ห้วยมุ่น"
    },
    {
        "subdistrict_id": "4569",
        "district_id": "569",
        "subdistrict_name": "ฟากท่า"
    },
    {
        "subdistrict_id": "4570",
        "district_id": "569",
        "subdistrict_name": "สองคอน"
    },
    {
        "subdistrict_id": "4571",
        "district_id": "569",
        "subdistrict_name": "บ้านเสี้ยว"
    },
    {
        "subdistrict_id": "4572",
        "district_id": "569",
        "subdistrict_name": "สองห้อง"
    },
    {
        "subdistrict_id": "4573",
        "district_id": "570",
        "subdistrict_name": "ม่วงเจ็ดต้น"
    },
    {
        "subdistrict_id": "4574",
        "district_id": "570",
        "subdistrict_name": "บ้านโคก"
    },
    {
        "subdistrict_id": "4575",
        "district_id": "570",
        "subdistrict_name": "นาขุม"
    },
    {
        "subdistrict_id": "4576",
        "district_id": "570",
        "subdistrict_name": "บ่อเบี้ย"
    },
    {
        "subdistrict_id": "4577",
        "district_id": "571",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "4578",
        "district_id": "571",
        "subdistrict_name": "บ้านดารา"
    },
    {
        "subdistrict_id": "4579",
        "district_id": "571",
        "subdistrict_name": "ไร่อ้อย"
    },
    {
        "subdistrict_id": "4580",
        "district_id": "571",
        "subdistrict_name": "ท่าสัก"
    },
    {
        "subdistrict_id": "4581",
        "district_id": "571",
        "subdistrict_name": "คอรุม"
    },
    {
        "subdistrict_id": "4582",
        "district_id": "571",
        "subdistrict_name": "บ้านหม้อ"
    },
    {
        "subdistrict_id": "4583",
        "district_id": "571",
        "subdistrict_name": "ท่ามะเฟือง"
    },
    {
        "subdistrict_id": "4584",
        "district_id": "571",
        "subdistrict_name": "บ้านโคน"
    },
    {
        "subdistrict_id": "4585",
        "district_id": "571",
        "subdistrict_name": "พญาแมน"
    },
    {
        "subdistrict_id": "4586",
        "district_id": "571",
        "subdistrict_name": "นาอิน"
    },
    {
        "subdistrict_id": "4587",
        "district_id": "571",
        "subdistrict_name": "นายาง"
    },
    {
        "subdistrict_id": "4588",
        "district_id": "572",
        "subdistrict_name": "ศรีพนมมาศ"
    },
    {
        "subdistrict_id": "4589",
        "district_id": "572",
        "subdistrict_name": "แม่พูล"
    },
    {
        "subdistrict_id": "4590",
        "district_id": "572",
        "subdistrict_name": "นานกกก"
    },
    {
        "subdistrict_id": "4591",
        "district_id": "572",
        "subdistrict_name": "ฝายหลวง"
    },
    {
        "subdistrict_id": "4592",
        "district_id": "572",
        "subdistrict_name": "ชัยจุมพล"
    },
    {
        "subdistrict_id": "4593",
        "district_id": "572",
        "subdistrict_name": "ไผ่ล้อม"
    },
    {
        "subdistrict_id": "4594",
        "district_id": "572",
        "subdistrict_name": "ทุ่งยั้ง"
    },
    {
        "subdistrict_id": "4595",
        "district_id": "572",
        "subdistrict_name": "ด่านแม่คำมัน"
    },
    {
        "subdistrict_id": "4596",
        "district_id": "573",
        "subdistrict_name": "ผักขวง"
    },
    {
        "subdistrict_id": "4597",
        "district_id": "573",
        "subdistrict_name": "บ่อทอง"
    },
    {
        "subdistrict_id": "4598",
        "district_id": "573",
        "subdistrict_name": "ป่าคาย"
    },
    {
        "subdistrict_id": "4599",
        "district_id": "573",
        "subdistrict_name": "น้ำพี้"
    },
    {
        "subdistrict_id": "4600",
        "district_id": "574",
        "subdistrict_name": "ในเวียง"
    },
    {
        "subdistrict_id": "4601",
        "district_id": "574",
        "subdistrict_name": "นาจักร"
    },
    {
        "subdistrict_id": "4602",
        "district_id": "574",
        "subdistrict_name": "น้ำชำ"
    },
    {
        "subdistrict_id": "4603",
        "district_id": "574",
        "subdistrict_name": "ป่าแดง"
    },
    {
        "subdistrict_id": "4604",
        "district_id": "574",
        "subdistrict_name": "ทุ่งโฮ้ง"
    },
    {
        "subdistrict_id": "4605",
        "district_id": "574",
        "subdistrict_name": "เหมืองหม้อ"
    },
    {
        "subdistrict_id": "4606",
        "district_id": "574",
        "subdistrict_name": "วังธง"
    },
    {
        "subdistrict_id": "4607",
        "district_id": "574",
        "subdistrict_name": "แม่หล่าย"
    },
    {
        "subdistrict_id": "4608",
        "district_id": "574",
        "subdistrict_name": "ห้วยม้า"
    },
    {
        "subdistrict_id": "4609",
        "district_id": "574",
        "subdistrict_name": "ป่าแมต"
    },
    {
        "subdistrict_id": "4610",
        "district_id": "574",
        "subdistrict_name": "บ้านถิ่น"
    },
    {
        "subdistrict_id": "4611",
        "district_id": "574",
        "subdistrict_name": "สวนเขื่อน"
    },
    {
        "subdistrict_id": "4612",
        "district_id": "574",
        "subdistrict_name": "วังหงส์"
    },
    {
        "subdistrict_id": "4613",
        "district_id": "574",
        "subdistrict_name": "แม่คำมี"
    },
    {
        "subdistrict_id": "4614",
        "district_id": "574",
        "subdistrict_name": "ทุ่งกวาว"
    },
    {
        "subdistrict_id": "4615",
        "district_id": "574",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "4616",
        "district_id": "574",
        "subdistrict_name": "แม่ยม"
    },
    {
        "subdistrict_id": "4617",
        "district_id": "574",
        "subdistrict_name": "ช่อแฮ"
    },
    {
        "subdistrict_id": "4618",
        "district_id": "574",
        "subdistrict_name": "ร่องฟอง"
    },
    {
        "subdistrict_id": "4619",
        "district_id": "574",
        "subdistrict_name": "กาญจนา"
    },
    {
        "subdistrict_id": "4620",
        "district_id": "575",
        "subdistrict_name": "ร้องกวาง"
    },
    {
        "subdistrict_id": "4621",
        "district_id": "575",
        "subdistrict_name": "ร้องเข็ม"
    },
    {
        "subdistrict_id": "4622",
        "district_id": "575",
        "subdistrict_name": "น้ำเลา"
    },
    {
        "subdistrict_id": "4623",
        "district_id": "575",
        "subdistrict_name": "บ้านเวียง"
    },
    {
        "subdistrict_id": "4624",
        "district_id": "575",
        "subdistrict_name": "ทุ่งศรี"
    },
    {
        "subdistrict_id": "4625",
        "district_id": "575",
        "subdistrict_name": "แม่ยางตาล"
    },
    {
        "subdistrict_id": "4626",
        "district_id": "575",
        "subdistrict_name": "แม่ยางฮ่อ"
    },
    {
        "subdistrict_id": "4627",
        "district_id": "575",
        "subdistrict_name": "ไผ่โทน"
    },
    {
        "subdistrict_id": "4628",
        "district_id": "575",
        "subdistrict_name": "ห้วยโรง"
    },
    {
        "subdistrict_id": "4629",
        "district_id": "575",
        "subdistrict_name": "แม่ทราย"
    },
    {
        "subdistrict_id": "4630",
        "district_id": "575",
        "subdistrict_name": "แม่ยางร้อง"
    },
    {
        "subdistrict_id": "4631",
        "district_id": "576",
        "subdistrict_name": "ห้วยอ้อ"
    },
    {
        "subdistrict_id": "4632",
        "district_id": "576",
        "subdistrict_name": "บ้านปิน"
    },
    {
        "subdistrict_id": "4633",
        "district_id": "576",
        "subdistrict_name": "ต้าผามอก"
    },
    {
        "subdistrict_id": "4634",
        "district_id": "576",
        "subdistrict_name": "เวียงต้า"
    },
    {
        "subdistrict_id": "4635",
        "district_id": "576",
        "subdistrict_name": "ปากกาง"
    },
    {
        "subdistrict_id": "4636",
        "district_id": "576",
        "subdistrict_name": "หัวทุ่ง"
    },
    {
        "subdistrict_id": "4637",
        "district_id": "576",
        "subdistrict_name": "ทุ่งแล้ง"
    },
    {
        "subdistrict_id": "4638",
        "district_id": "576",
        "subdistrict_name": "บ่อเหล็กลอง"
    },
    {
        "subdistrict_id": "4639",
        "district_id": "576",
        "subdistrict_name": "แม่ปาน"
    },
    {
        "subdistrict_id": "4640",
        "district_id": "577",
        "subdistrict_name": "สูงเม่น"
    },
    {
        "subdistrict_id": "4641",
        "district_id": "577",
        "subdistrict_name": "น้ำชำ"
    },
    {
        "subdistrict_id": "4642",
        "district_id": "577",
        "subdistrict_name": "หัวฝาย"
    },
    {
        "subdistrict_id": "4643",
        "district_id": "577",
        "subdistrict_name": "ดอนมูล"
    },
    {
        "subdistrict_id": "4644",
        "district_id": "577",
        "subdistrict_name": "บ้านเหล่า"
    },
    {
        "subdistrict_id": "4645",
        "district_id": "577",
        "subdistrict_name": "บ้านกวาง"
    },
    {
        "subdistrict_id": "4646",
        "district_id": "577",
        "subdistrict_name": "บ้านปง"
    },
    {
        "subdistrict_id": "4647",
        "district_id": "577",
        "subdistrict_name": "บ้านกาศ"
    },
    {
        "subdistrict_id": "4648",
        "district_id": "577",
        "subdistrict_name": "ร่องกาศ"
    },
    {
        "subdistrict_id": "4649",
        "district_id": "577",
        "subdistrict_name": "สบสาย"
    },
    {
        "subdistrict_id": "4650",
        "district_id": "577",
        "subdistrict_name": "เวียงทอง"
    },
    {
        "subdistrict_id": "4651",
        "district_id": "577",
        "subdistrict_name": "พระหลวง"
    },
    {
        "subdistrict_id": "4652",
        "district_id": "578",
        "subdistrict_name": "เด่นชัย"
    },
    {
        "subdistrict_id": "4653",
        "district_id": "578",
        "subdistrict_name": "แม่จั๊วะ"
    },
    {
        "subdistrict_id": "4654",
        "district_id": "578",
        "subdistrict_name": "ไทรย้อย"
    },
    {
        "subdistrict_id": "4655",
        "district_id": "578",
        "subdistrict_name": "ห้วยไร่"
    },
    {
        "subdistrict_id": "4656",
        "district_id": "578",
        "subdistrict_name": "ปงป่าหวาย"
    },
    {
        "subdistrict_id": "4657",
        "district_id": "579",
        "subdistrict_name": "บ้านหนุน"
    },
    {
        "subdistrict_id": "4658",
        "district_id": "579",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "4659",
        "district_id": "579",
        "subdistrict_name": "ห้วยหม้าย"
    },
    {
        "subdistrict_id": "4660",
        "district_id": "579",
        "subdistrict_name": "เตาปูน"
    },
    {
        "subdistrict_id": "4661",
        "district_id": "579",
        "subdistrict_name": "หัวเมือง"
    },
    {
        "subdistrict_id": "4662",
        "district_id": "579",
        "subdistrict_name": "สะเอียบ"
    },
    {
        "subdistrict_id": "4663",
        "district_id": "579",
        "subdistrict_name": "แดนชุมพล"
    },
    {
        "subdistrict_id": "4664",
        "district_id": "579",
        "subdistrict_name": "ทุ่งน้าว"
    },
    {
        "subdistrict_id": "4665",
        "district_id": "580",
        "subdistrict_name": "วังชิ้น"
    },
    {
        "subdistrict_id": "4666",
        "district_id": "580",
        "subdistrict_name": "สรอย"
    },
    {
        "subdistrict_id": "4667",
        "district_id": "580",
        "subdistrict_name": "แม่ป้าก"
    },
    {
        "subdistrict_id": "4668",
        "district_id": "580",
        "subdistrict_name": "นาพูน"
    },
    {
        "subdistrict_id": "4669",
        "district_id": "580",
        "subdistrict_name": "แม่พุง"
    },
    {
        "subdistrict_id": "4670",
        "district_id": "580",
        "subdistrict_name": "ป่าสัก"
    },
    {
        "subdistrict_id": "4671",
        "district_id": "580",
        "subdistrict_name": "แม่เกิ๋ง"
    },
    {
        "subdistrict_id": "4672",
        "district_id": "581",
        "subdistrict_name": "แม่คำมี"
    },
    {
        "subdistrict_id": "4673",
        "district_id": "581",
        "subdistrict_name": "หนองม่วงไข่"
    },
    {
        "subdistrict_id": "4674",
        "district_id": "581",
        "subdistrict_name": "น้ำรัด"
    },
    {
        "subdistrict_id": "4675",
        "district_id": "581",
        "subdistrict_name": "วังหลวง"
    },
    {
        "subdistrict_id": "4676",
        "district_id": "581",
        "subdistrict_name": "ตำหนักธรรม"
    },
    {
        "subdistrict_id": "4677",
        "district_id": "581",
        "subdistrict_name": "ทุ่งแค้ว"
    },
    {
        "subdistrict_id": "4678",
        "district_id": "582",
        "subdistrict_name": "ในเวียง"
    },
    {
        "subdistrict_id": "4679",
        "district_id": "582",
        "subdistrict_name": "บ่อ"
    },
    {
        "subdistrict_id": "4680",
        "district_id": "582",
        "subdistrict_name": "ผาสิงห์"
    },
    {
        "subdistrict_id": "4681",
        "district_id": "582",
        "subdistrict_name": "ไชยสถาน"
    },
    {
        "subdistrict_id": "4682",
        "district_id": "582",
        "subdistrict_name": "ถืมตอง"
    },
    {
        "subdistrict_id": "4683",
        "district_id": "582",
        "subdistrict_name": "เรือง"
    },
    {
        "subdistrict_id": "4684",
        "district_id": "582",
        "subdistrict_name": "นาซาว"
    },
    {
        "subdistrict_id": "4685",
        "district_id": "582",
        "subdistrict_name": "ดู่ใต้"
    },
    {
        "subdistrict_id": "4686",
        "district_id": "582",
        "subdistrict_name": "กองควาย"
    },
    {
        "subdistrict_id": "4687",
        "district_id": "582",
        "subdistrict_name": "สวก"
    },
    {
        "subdistrict_id": "4688",
        "district_id": "582",
        "subdistrict_name": "สะเนียน"
    },
    {
        "subdistrict_id": "4689",
        "district_id": "583",
        "subdistrict_name": "หนองแดง"
    },
    {
        "subdistrict_id": "4690",
        "district_id": "583",
        "subdistrict_name": "หมอเมือง"
    },
    {
        "subdistrict_id": "4691",
        "district_id": "583",
        "subdistrict_name": "น้ำพาง"
    },
    {
        "subdistrict_id": "4692",
        "district_id": "583",
        "subdistrict_name": "น้ำปาย"
    },
    {
        "subdistrict_id": "4693",
        "district_id": "583",
        "subdistrict_name": "แม่จริม"
    },
    {
        "subdistrict_id": "4694",
        "district_id": "584",
        "subdistrict_name": "บ้านฟ้า"
    },
    {
        "subdistrict_id": "4695",
        "district_id": "584",
        "subdistrict_name": "ป่าคาหลวง"
    },
    {
        "subdistrict_id": "4696",
        "district_id": "584",
        "subdistrict_name": "สวด"
    },
    {
        "subdistrict_id": "4697",
        "district_id": "584",
        "subdistrict_name": "บ้านพี้"
    },
    {
        "subdistrict_id": "4698",
        "district_id": "585",
        "subdistrict_name": "นาน้อย"
    },
    {
        "subdistrict_id": "4699",
        "district_id": "585",
        "subdistrict_name": "เชียงของ"
    },
    {
        "subdistrict_id": "4700",
        "district_id": "585",
        "subdistrict_name": "ศรีษะเกษ"
    },
    {
        "subdistrict_id": "4701",
        "district_id": "585",
        "subdistrict_name": "สถาน"
    },
    {
        "subdistrict_id": "4702",
        "district_id": "585",
        "subdistrict_name": "สันทะ"
    },
    {
        "subdistrict_id": "4703",
        "district_id": "585",
        "subdistrict_name": "บัวใหญ่"
    },
    {
        "subdistrict_id": "4704",
        "district_id": "585",
        "subdistrict_name": "น้ำตก"
    },
    {
        "subdistrict_id": "4705",
        "district_id": "586",
        "subdistrict_name": "ปัว"
    },
    {
        "subdistrict_id": "4706",
        "district_id": "586",
        "subdistrict_name": "แงง"
    },
    {
        "subdistrict_id": "4707",
        "district_id": "586",
        "subdistrict_name": "สถาน"
    },
    {
        "subdistrict_id": "4708",
        "district_id": "586",
        "subdistrict_name": "ศิลาแลง"
    },
    {
        "subdistrict_id": "4709",
        "district_id": "586",
        "subdistrict_name": "ศิลาเพชร"
    },
    {
        "subdistrict_id": "4710",
        "district_id": "586",
        "subdistrict_name": "อวน"
    },
    {
        "subdistrict_id": "4711",
        "district_id": "586",
        "subdistrict_name": "ไชยวัฒนา"
    },
    {
        "subdistrict_id": "4712",
        "district_id": "586",
        "subdistrict_name": "เจดีย์ชัย"
    },
    {
        "subdistrict_id": "4713",
        "district_id": "586",
        "subdistrict_name": "ภูคา"
    },
    {
        "subdistrict_id": "4714",
        "district_id": "586",
        "subdistrict_name": "สกาด"
    },
    {
        "subdistrict_id": "4715",
        "district_id": "586",
        "subdistrict_name": "ป่ากลาง"
    },
    {
        "subdistrict_id": "4716",
        "district_id": "586",
        "subdistrict_name": "วรนคร"
    },
    {
        "subdistrict_id": "4717",
        "district_id": "587",
        "subdistrict_name": "ริม"
    },
    {
        "subdistrict_id": "4718",
        "district_id": "587",
        "subdistrict_name": "ป่าคา"
    },
    {
        "subdistrict_id": "4719",
        "district_id": "587",
        "subdistrict_name": "ผาตอ"
    },
    {
        "subdistrict_id": "4720",
        "district_id": "587",
        "subdistrict_name": "ยม"
    },
    {
        "subdistrict_id": "4721",
        "district_id": "587",
        "subdistrict_name": "ตาลชุม"
    },
    {
        "subdistrict_id": "4722",
        "district_id": "587",
        "subdistrict_name": "ศรีภูมิ"
    },
    {
        "subdistrict_id": "4723",
        "district_id": "587",
        "subdistrict_name": "จอมพระ"
    },
    {
        "subdistrict_id": "4724",
        "district_id": "587",
        "subdistrict_name": "แสนทอง"
    },
    {
        "subdistrict_id": "4725",
        "district_id": "587",
        "subdistrict_name": "ท่าวังผา"
    },
    {
        "subdistrict_id": "4726",
        "district_id": "587",
        "subdistrict_name": "ผาทอง"
    },
    {
        "subdistrict_id": "4727",
        "district_id": "588",
        "subdistrict_name": "กลางเวียง"
    },
    {
        "subdistrict_id": "4728",
        "district_id": "588",
        "subdistrict_name": "ขึ่ง"
    },
    {
        "subdistrict_id": "4729",
        "district_id": "588",
        "subdistrict_name": "ไหล่น่าน"
    },
    {
        "subdistrict_id": "4730",
        "district_id": "588",
        "subdistrict_name": "ตาลชุม"
    },
    {
        "subdistrict_id": "4731",
        "district_id": "588",
        "subdistrict_name": "นาเหลือง"
    },
    {
        "subdistrict_id": "4732",
        "district_id": "588",
        "subdistrict_name": "ส้าน"
    },
    {
        "subdistrict_id": "4733",
        "district_id": "588",
        "subdistrict_name": "น้ำมวบ"
    },
    {
        "subdistrict_id": "4734",
        "district_id": "588",
        "subdistrict_name": "น้ำปั้ว"
    },
    {
        "subdistrict_id": "4735",
        "district_id": "588",
        "subdistrict_name": "ยาบหัวนา"
    },
    {
        "subdistrict_id": "4736",
        "district_id": "588",
        "subdistrict_name": "ปงสนุก"
    },
    {
        "subdistrict_id": "4737",
        "district_id": "588",
        "subdistrict_name": "อ่ายนาไลย"
    },
    {
        "subdistrict_id": "4738",
        "district_id": "588",
        "subdistrict_name": "ส้านนาหนองใหม่"
    },
    {
        "subdistrict_id": "4739",
        "district_id": "588",
        "subdistrict_name": "แม่ขะนิง"
    },
    {
        "subdistrict_id": "4740",
        "district_id": "588",
        "subdistrict_name": "แม่สาคร"
    },
    {
        "subdistrict_id": "4741",
        "district_id": "588",
        "subdistrict_name": "จอมจันทร์"
    },
    {
        "subdistrict_id": "4742",
        "district_id": "588",
        "subdistrict_name": "แม่สา"
    },
    {
        "subdistrict_id": "4743",
        "district_id": "588",
        "subdistrict_name": "ทุ่งศรีทอง"
    },
    {
        "subdistrict_id": "4744",
        "district_id": "589",
        "subdistrict_name": "ปอน"
    },
    {
        "subdistrict_id": "4745",
        "district_id": "589",
        "subdistrict_name": "งอบ"
    },
    {
        "subdistrict_id": "4746",
        "district_id": "589",
        "subdistrict_name": "และ"
    },
    {
        "subdistrict_id": "4747",
        "district_id": "589",
        "subdistrict_name": "ทุ่งช้าง"
    },
    {
        "subdistrict_id": "4748",
        "district_id": "590",
        "subdistrict_name": "เชียงกลาง"
    },
    {
        "subdistrict_id": "4749",
        "district_id": "590",
        "subdistrict_name": "เปือ"
    },
    {
        "subdistrict_id": "4750",
        "district_id": "590",
        "subdistrict_name": "เชียงคาน"
    },
    {
        "subdistrict_id": "4751",
        "district_id": "590",
        "subdistrict_name": "พระธาตุ"
    },
    {
        "subdistrict_id": "4752",
        "district_id": "590",
        "subdistrict_name": "พญาแก้ว"
    },
    {
        "subdistrict_id": "4753",
        "district_id": "590",
        "subdistrict_name": "พระพุทธบาท"
    },
    {
        "subdistrict_id": "4754",
        "district_id": "591",
        "subdistrict_name": "นาทะนุง"
    },
    {
        "subdistrict_id": "4755",
        "district_id": "591",
        "subdistrict_name": "บ่อแก้ว"
    },
    {
        "subdistrict_id": "4756",
        "district_id": "591",
        "subdistrict_name": "เมืองลี"
    },
    {
        "subdistrict_id": "4757",
        "district_id": "591",
        "subdistrict_name": "ปิงหลวง"
    },
    {
        "subdistrict_id": "4758",
        "district_id": "592",
        "subdistrict_name": "ดู่พงษ์"
    },
    {
        "subdistrict_id": "4759",
        "district_id": "592",
        "subdistrict_name": "ป่าแลวหลวง"
    },
    {
        "subdistrict_id": "4760",
        "district_id": "592",
        "subdistrict_name": "พงษ์"
    },
    {
        "subdistrict_id": "4761",
        "district_id": "593",
        "subdistrict_name": "บ่อเกลือเหนือ"
    },
    {
        "subdistrict_id": "4762",
        "district_id": "593",
        "subdistrict_name": "บ่อเกลือใต้"
    },
    {
        "subdistrict_id": "4763",
        "district_id": "593",
        "subdistrict_name": "ภูฟ้า"
    },
    {
        "subdistrict_id": "4764",
        "district_id": "593",
        "subdistrict_name": "ดงพญา"
    },
    {
        "subdistrict_id": "4765",
        "district_id": "594",
        "subdistrict_name": "นาไร่หลวง"
    },
    {
        "subdistrict_id": "4766",
        "district_id": "594",
        "subdistrict_name": "ชนแดน"
    },
    {
        "subdistrict_id": "4767",
        "district_id": "594",
        "subdistrict_name": "ยอด"
    },
    {
        "subdistrict_id": "4768",
        "district_id": "595",
        "subdistrict_name": "ม่วงตึ๊ด"
    },
    {
        "subdistrict_id": "4769",
        "district_id": "595",
        "subdistrict_name": "นาปัง"
    },
    {
        "subdistrict_id": "4770",
        "district_id": "595",
        "subdistrict_name": "น้ำแก่น"
    },
    {
        "subdistrict_id": "4771",
        "district_id": "595",
        "subdistrict_name": "น้ำเกี๋ยน"
    },
    {
        "subdistrict_id": "4772",
        "district_id": "595",
        "subdistrict_name": "เมืองจัง"
    },
    {
        "subdistrict_id": "4773",
        "district_id": "595",
        "subdistrict_name": "ท่าน้าว"
    },
    {
        "subdistrict_id": "4774",
        "district_id": "595",
        "subdistrict_name": "ฝายแก้ว"
    },
    {
        "subdistrict_id": "4775",
        "district_id": "596",
        "subdistrict_name": "ห้วยโก๋น"
    },
    {
        "subdistrict_id": "4776",
        "district_id": "596",
        "subdistrict_name": "ขุนน่าน"
    },
    {
        "subdistrict_id": "4777",
        "district_id": "597",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4778",
        "district_id": "597",
        "subdistrict_name": "แม่ต๋ำ"
    },
    {
        "subdistrict_id": "4779",
        "district_id": "597",
        "subdistrict_name": "แม่นาเรือ"
    },
    {
        "subdistrict_id": "4780",
        "district_id": "597",
        "subdistrict_name": "บ้านตุ่น"
    },
    {
        "subdistrict_id": "4781",
        "district_id": "597",
        "subdistrict_name": "บ้านต๊ำ"
    },
    {
        "subdistrict_id": "4782",
        "district_id": "597",
        "subdistrict_name": "บ้านต๋อม"
    },
    {
        "subdistrict_id": "4783",
        "district_id": "597",
        "subdistrict_name": "แม่ปืม"
    },
    {
        "subdistrict_id": "4784",
        "district_id": "597",
        "subdistrict_name": "แม่กา"
    },
    {
        "subdistrict_id": "4785",
        "district_id": "597",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "4786",
        "district_id": "597",
        "subdistrict_name": "จำป่าหวาย"
    },
    {
        "subdistrict_id": "4787",
        "district_id": "597",
        "subdistrict_name": "ท่าวังทอง"
    },
    {
        "subdistrict_id": "4788",
        "district_id": "597",
        "subdistrict_name": "แม่ใส"
    },
    {
        "subdistrict_id": "4789",
        "district_id": "597",
        "subdistrict_name": "บ้านสาง"
    },
    {
        "subdistrict_id": "4790",
        "district_id": "597",
        "subdistrict_name": "ท่าจำปี"
    },
    {
        "subdistrict_id": "4791",
        "district_id": "597",
        "subdistrict_name": "สันป่าม่วง"
    },
    {
        "subdistrict_id": "4792",
        "district_id": "598",
        "subdistrict_name": "ห้วยข้าวก่ำ"
    },
    {
        "subdistrict_id": "4793",
        "district_id": "598",
        "subdistrict_name": "จุน"
    },
    {
        "subdistrict_id": "4794",
        "district_id": "598",
        "subdistrict_name": "ลอ"
    },
    {
        "subdistrict_id": "4795",
        "district_id": "598",
        "subdistrict_name": "หงส์หิน"
    },
    {
        "subdistrict_id": "4796",
        "district_id": "598",
        "subdistrict_name": "ทุ่งรวงทอง"
    },
    {
        "subdistrict_id": "4797",
        "district_id": "598",
        "subdistrict_name": "ห้วยยางขาม"
    },
    {
        "subdistrict_id": "4798",
        "district_id": "598",
        "subdistrict_name": "พระธาตุขิงแกง"
    },
    {
        "subdistrict_id": "4799",
        "district_id": "599",
        "subdistrict_name": "หย่วน"
    },
    {
        "subdistrict_id": "4800",
        "district_id": "599",
        "subdistrict_name": "น้ำแวน"
    },
    {
        "subdistrict_id": "4801",
        "district_id": "599",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4802",
        "district_id": "599",
        "subdistrict_name": "ฝายกวาง"
    },
    {
        "subdistrict_id": "4803",
        "district_id": "599",
        "subdistrict_name": "เจดีย์คำ"
    },
    {
        "subdistrict_id": "4804",
        "district_id": "599",
        "subdistrict_name": "ร่มเย็น"
    },
    {
        "subdistrict_id": "4805",
        "district_id": "599",
        "subdistrict_name": "เชียงบาน"
    },
    {
        "subdistrict_id": "4806",
        "district_id": "599",
        "subdistrict_name": "แม่ลาว"
    },
    {
        "subdistrict_id": "4807",
        "district_id": "599",
        "subdistrict_name": "อ่างทอง"
    },
    {
        "subdistrict_id": "4808",
        "district_id": "599",
        "subdistrict_name": "ทุ่งผาสุข"
    },
    {
        "subdistrict_id": "4809",
        "district_id": "600",
        "subdistrict_name": "เชียงม่วน"
    },
    {
        "subdistrict_id": "4810",
        "district_id": "600",
        "subdistrict_name": "บ้านมาง"
    },
    {
        "subdistrict_id": "4811",
        "district_id": "600",
        "subdistrict_name": "สระ"
    },
    {
        "subdistrict_id": "4812",
        "district_id": "601",
        "subdistrict_name": "ดอกคำใต้"
    },
    {
        "subdistrict_id": "4813",
        "district_id": "601",
        "subdistrict_name": "ดอนศรีชุม"
    },
    {
        "subdistrict_id": "4814",
        "district_id": "601",
        "subdistrict_name": "บ้านถ้ำ"
    },
    {
        "subdistrict_id": "4815",
        "district_id": "601",
        "subdistrict_name": "บ้านปิน"
    },
    {
        "subdistrict_id": "4816",
        "district_id": "601",
        "subdistrict_name": "ห้วยลาน"
    },
    {
        "subdistrict_id": "4817",
        "district_id": "601",
        "subdistrict_name": "สันโค้ง"
    },
    {
        "subdistrict_id": "4818",
        "district_id": "601",
        "subdistrict_name": "ป่าซาง"
    },
    {
        "subdistrict_id": "4819",
        "district_id": "601",
        "subdistrict_name": "หนองหล่ม"
    },
    {
        "subdistrict_id": "4820",
        "district_id": "601",
        "subdistrict_name": "ดงสุวรรณ"
    },
    {
        "subdistrict_id": "4821",
        "district_id": "601",
        "subdistrict_name": "บุญเกิด"
    },
    {
        "subdistrict_id": "4822",
        "district_id": "601",
        "subdistrict_name": "สว่างอารมณ์"
    },
    {
        "subdistrict_id": "4823",
        "district_id": "601",
        "subdistrict_name": "คือเวียง"
    },
    {
        "subdistrict_id": "4824",
        "district_id": "602",
        "subdistrict_name": "ปง"
    },
    {
        "subdistrict_id": "4825",
        "district_id": "602",
        "subdistrict_name": "ควร"
    },
    {
        "subdistrict_id": "4826",
        "district_id": "602",
        "subdistrict_name": "ออย"
    },
    {
        "subdistrict_id": "4827",
        "district_id": "602",
        "subdistrict_name": "งิม"
    },
    {
        "subdistrict_id": "4828",
        "district_id": "602",
        "subdistrict_name": "ผาช้างน้อย"
    },
    {
        "subdistrict_id": "4829",
        "district_id": "602",
        "subdistrict_name": "นาปรัง"
    },
    {
        "subdistrict_id": "4830",
        "district_id": "602",
        "subdistrict_name": "ขุนควร"
    },
    {
        "subdistrict_id": "4831",
        "district_id": "603",
        "subdistrict_name": "แม่ใจ"
    },
    {
        "subdistrict_id": "4832",
        "district_id": "603",
        "subdistrict_name": "ศรีถ้อย"
    },
    {
        "subdistrict_id": "4833",
        "district_id": "603",
        "subdistrict_name": "แม่สุก"
    },
    {
        "subdistrict_id": "4834",
        "district_id": "603",
        "subdistrict_name": "ป่าแฝก"
    },
    {
        "subdistrict_id": "4835",
        "district_id": "603",
        "subdistrict_name": "บ้านเหล่า"
    },
    {
        "subdistrict_id": "4836",
        "district_id": "603",
        "subdistrict_name": "เจริญราษฎร์"
    },
    {
        "subdistrict_id": "4837",
        "district_id": "604",
        "subdistrict_name": "ภูซาง"
    },
    {
        "subdistrict_id": "4838",
        "district_id": "604",
        "subdistrict_name": "ป่าสัก"
    },
    {
        "subdistrict_id": "4839",
        "district_id": "604",
        "subdistrict_name": "ทุ่งกล้วย"
    },
    {
        "subdistrict_id": "4840",
        "district_id": "604",
        "subdistrict_name": "เชียงแรง"
    },
    {
        "subdistrict_id": "4841",
        "district_id": "604",
        "subdistrict_name": "สบบง"
    },
    {
        "subdistrict_id": "4842",
        "district_id": "605",
        "subdistrict_name": "ห้วยแก้ว"
    },
    {
        "subdistrict_id": "4843",
        "district_id": "605",
        "subdistrict_name": "ดงเจน"
    },
    {
        "subdistrict_id": "4844",
        "district_id": "605",
        "subdistrict_name": "แม่อิง"
    },
    {
        "subdistrict_id": "4845",
        "district_id": "606",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4846",
        "district_id": "606",
        "subdistrict_name": "รอบเวียง"
    },
    {
        "subdistrict_id": "4847",
        "district_id": "606",
        "subdistrict_name": "บ้านดู่"
    },
    {
        "subdistrict_id": "4848",
        "district_id": "606",
        "subdistrict_name": "นางแล"
    },
    {
        "subdistrict_id": "4849",
        "district_id": "606",
        "subdistrict_name": "แม่ข้าวต้ม"
    },
    {
        "subdistrict_id": "4850",
        "district_id": "606",
        "subdistrict_name": "แม่ยาว"
    },
    {
        "subdistrict_id": "4851",
        "district_id": "606",
        "subdistrict_name": "สันทราย"
    },
    {
        "subdistrict_id": "4852",
        "district_id": "606",
        "subdistrict_name": "แม่กรณ์"
    },
    {
        "subdistrict_id": "4853",
        "district_id": "606",
        "subdistrict_name": "ห้วยชมภู"
    },
    {
        "subdistrict_id": "4854",
        "district_id": "606",
        "subdistrict_name": "ห้วยสัก"
    },
    {
        "subdistrict_id": "4855",
        "district_id": "606",
        "subdistrict_name": "ริมกก"
    },
    {
        "subdistrict_id": "4856",
        "district_id": "606",
        "subdistrict_name": "ดอยลาน"
    },
    {
        "subdistrict_id": "4857",
        "district_id": "606",
        "subdistrict_name": "ป่าอ้อดอนชัย"
    },
    {
        "subdistrict_id": "4858",
        "district_id": "606",
        "subdistrict_name": "ท่าสาย"
    },
    {
        "subdistrict_id": "4859",
        "district_id": "606",
        "subdistrict_name": "ดอยฮาง"
    },
    {
        "subdistrict_id": "4860",
        "district_id": "606",
        "subdistrict_name": "ท่าสุด"
    },
    {
        "subdistrict_id": "4861",
        "district_id": "607",
        "subdistrict_name": "เวียงชัย"
    },
    {
        "subdistrict_id": "4862",
        "district_id": "607",
        "subdistrict_name": "ผางาม"
    },
    {
        "subdistrict_id": "4863",
        "district_id": "607",
        "subdistrict_name": "เวียงเหนือ"
    },
    {
        "subdistrict_id": "4864",
        "district_id": "607",
        "subdistrict_name": "ดอนศิลา"
    },
    {
        "subdistrict_id": "4865",
        "district_id": "607",
        "subdistrict_name": "เมืองชุม"
    },
    {
        "subdistrict_id": "4866",
        "district_id": "608",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4867",
        "district_id": "608",
        "subdistrict_name": "สถาน"
    },
    {
        "subdistrict_id": "4868",
        "district_id": "608",
        "subdistrict_name": "ครึ่ง"
    },
    {
        "subdistrict_id": "4869",
        "district_id": "608",
        "subdistrict_name": "บุญเรือง"
    },
    {
        "subdistrict_id": "4870",
        "district_id": "608",
        "subdistrict_name": "ห้วยซ้อ"
    },
    {
        "subdistrict_id": "4871",
        "district_id": "608",
        "subdistrict_name": "ศรีดอนชัย"
    },
    {
        "subdistrict_id": "4872",
        "district_id": "608",
        "subdistrict_name": "ริมโขง"
    },
    {
        "subdistrict_id": "4873",
        "district_id": "609",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4874",
        "district_id": "609",
        "subdistrict_name": "งิ้ว"
    },
    {
        "subdistrict_id": "4875",
        "district_id": "609",
        "subdistrict_name": "ปล้อง"
    },
    {
        "subdistrict_id": "4876",
        "district_id": "609",
        "subdistrict_name": "แม่ลอย"
    },
    {
        "subdistrict_id": "4877",
        "district_id": "609",
        "subdistrict_name": "เชียงเคี่ยน"
    },
    {
        "subdistrict_id": "4878",
        "district_id": "609",
        "subdistrict_name": "ตับเต่า"
    },
    {
        "subdistrict_id": "4879",
        "district_id": "609",
        "subdistrict_name": "หงาว"
    },
    {
        "subdistrict_id": "4880",
        "district_id": "609",
        "subdistrict_name": "สันทรายงาม"
    },
    {
        "subdistrict_id": "4881",
        "district_id": "609",
        "subdistrict_name": "ศรีดอนไชย"
    },
    {
        "subdistrict_id": "4882",
        "district_id": "609",
        "subdistrict_name": "หนองแรด"
    },
    {
        "subdistrict_id": "4883",
        "district_id": "610",
        "subdistrict_name": "สันมะเค็ด"
    },
    {
        "subdistrict_id": "4884",
        "district_id": "610",
        "subdistrict_name": "แม่อ้อ"
    },
    {
        "subdistrict_id": "4885",
        "district_id": "610",
        "subdistrict_name": "ธารทอง"
    },
    {
        "subdistrict_id": "4886",
        "district_id": "610",
        "subdistrict_name": "สันติสุข"
    },
    {
        "subdistrict_id": "4887",
        "district_id": "610",
        "subdistrict_name": "ดอยงาม"
    },
    {
        "subdistrict_id": "4888",
        "district_id": "610",
        "subdistrict_name": "หัวง้ม"
    },
    {
        "subdistrict_id": "4889",
        "district_id": "610",
        "subdistrict_name": "เจริญเมือง"
    },
    {
        "subdistrict_id": "4890",
        "district_id": "610",
        "subdistrict_name": "ป่าหุ่ง"
    },
    {
        "subdistrict_id": "4891",
        "district_id": "610",
        "subdistrict_name": "ม่วงคำ"
    },
    {
        "subdistrict_id": "4892",
        "district_id": "610",
        "subdistrict_name": "ทรายขาว"
    },
    {
        "subdistrict_id": "4893",
        "district_id": "610",
        "subdistrict_name": "สันกลาง"
    },
    {
        "subdistrict_id": "4894",
        "district_id": "610",
        "subdistrict_name": "แม่เย็น"
    },
    {
        "subdistrict_id": "4895",
        "district_id": "610",
        "subdistrict_name": "เมืองพาน"
    },
    {
        "subdistrict_id": "4896",
        "district_id": "610",
        "subdistrict_name": "ทานตะวัน"
    },
    {
        "subdistrict_id": "4897",
        "district_id": "610",
        "subdistrict_name": "เวียงห้าว"
    },
    {
        "subdistrict_id": "4898",
        "district_id": "611",
        "subdistrict_name": "ป่าแดด"
    },
    {
        "subdistrict_id": "4899",
        "district_id": "611",
        "subdistrict_name": "ป่าแงะ"
    },
    {
        "subdistrict_id": "4900",
        "district_id": "611",
        "subdistrict_name": "สันมะค่า"
    },
    {
        "subdistrict_id": "4901",
        "district_id": "611",
        "subdistrict_name": "โรงช้าง"
    },
    {
        "subdistrict_id": "4902",
        "district_id": "611",
        "subdistrict_name": "ศรีโพธิ์เงิน"
    },
    {
        "subdistrict_id": "4903",
        "district_id": "612",
        "subdistrict_name": "แม่จัน"
    },
    {
        "subdistrict_id": "4904",
        "district_id": "612",
        "subdistrict_name": "จันจว้า"
    },
    {
        "subdistrict_id": "4905",
        "district_id": "612",
        "subdistrict_name": "แม่คำ"
    },
    {
        "subdistrict_id": "4906",
        "district_id": "612",
        "subdistrict_name": "ป่าซาง"
    },
    {
        "subdistrict_id": "4907",
        "district_id": "612",
        "subdistrict_name": "สันทราย"
    },
    {
        "subdistrict_id": "4908",
        "district_id": "612",
        "subdistrict_name": "ท่าข้าวเปลือก"
    },
    {
        "subdistrict_id": "4909",
        "district_id": "612",
        "subdistrict_name": "ป่าตึง"
    },
    {
        "subdistrict_id": "4910",
        "district_id": "612",
        "subdistrict_name": "แม่ไร่"
    },
    {
        "subdistrict_id": "4911",
        "district_id": "612",
        "subdistrict_name": "ศรีค้ำ"
    },
    {
        "subdistrict_id": "4912",
        "district_id": "612",
        "subdistrict_name": "จันจว้าใต้"
    },
    {
        "subdistrict_id": "4913",
        "district_id": "612",
        "subdistrict_name": "จอมสวรรค์"
    },
    {
        "subdistrict_id": "4914",
        "district_id": "613",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4915",
        "district_id": "613",
        "subdistrict_name": "ป่าสัก"
    },
    {
        "subdistrict_id": "4916",
        "district_id": "613",
        "subdistrict_name": "บ้านแซว"
    },
    {
        "subdistrict_id": "4917",
        "district_id": "613",
        "subdistrict_name": "ศรีดอนมูล"
    },
    {
        "subdistrict_id": "4918",
        "district_id": "613",
        "subdistrict_name": "แม่เงิน"
    },
    {
        "subdistrict_id": "4919",
        "district_id": "613",
        "subdistrict_name": "โยนก"
    },
    {
        "subdistrict_id": "4920",
        "district_id": "614",
        "subdistrict_name": "แม่สาย"
    },
    {
        "subdistrict_id": "4921",
        "district_id": "614",
        "subdistrict_name": "ห้วยไคร้"
    },
    {
        "subdistrict_id": "4922",
        "district_id": "614",
        "subdistrict_name": "เกาะช้าง"
    },
    {
        "subdistrict_id": "4923",
        "district_id": "614",
        "subdistrict_name": "โป่งผา"
    },
    {
        "subdistrict_id": "4924",
        "district_id": "614",
        "subdistrict_name": "ศรีเมืองชุม"
    },
    {
        "subdistrict_id": "4925",
        "district_id": "614",
        "subdistrict_name": "เวียงพางคำ"
    },
    {
        "subdistrict_id": "4926",
        "district_id": "614",
        "subdistrict_name": "บ้านด้าย"
    },
    {
        "subdistrict_id": "4927",
        "district_id": "614",
        "subdistrict_name": "โป่งงาม"
    },
    {
        "subdistrict_id": "4928",
        "district_id": "615",
        "subdistrict_name": "แม่สรวย"
    },
    {
        "subdistrict_id": "4929",
        "district_id": "615",
        "subdistrict_name": "ป่าแดด"
    },
    {
        "subdistrict_id": "4930",
        "district_id": "615",
        "subdistrict_name": "แม่พริก"
    },
    {
        "subdistrict_id": "4931",
        "district_id": "615",
        "subdistrict_name": "ศรีถ้อย"
    },
    {
        "subdistrict_id": "4932",
        "district_id": "615",
        "subdistrict_name": "ท่าก๊อ"
    },
    {
        "subdistrict_id": "4933",
        "district_id": "615",
        "subdistrict_name": "วาวี"
    },
    {
        "subdistrict_id": "4934",
        "district_id": "615",
        "subdistrict_name": "เจดีย์หลวง"
    },
    {
        "subdistrict_id": "4935",
        "district_id": "616",
        "subdistrict_name": "สันสลี"
    },
    {
        "subdistrict_id": "4936",
        "district_id": "616",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "4937",
        "district_id": "616",
        "subdistrict_name": "บ้านโป่ง"
    },
    {
        "subdistrict_id": "4938",
        "district_id": "616",
        "subdistrict_name": "ป่างิ้ว"
    },
    {
        "subdistrict_id": "4939",
        "district_id": "616",
        "subdistrict_name": "เวียงกาหลง"
    },
    {
        "subdistrict_id": "4940",
        "district_id": "616",
        "subdistrict_name": "แม่เจดีย์"
    },
    {
        "subdistrict_id": "4941",
        "district_id": "616",
        "subdistrict_name": "แม่เจดีย์ใหม่"
    },
    {
        "subdistrict_id": "4942",
        "district_id": "617",
        "subdistrict_name": "แม่เปา"
    },
    {
        "subdistrict_id": "4943",
        "district_id": "617",
        "subdistrict_name": "แม่ต๋ำ"
    },
    {
        "subdistrict_id": "4944",
        "district_id": "617",
        "subdistrict_name": "ไม้ยา"
    },
    {
        "subdistrict_id": "4945",
        "district_id": "617",
        "subdistrict_name": "เม็งราย"
    },
    {
        "subdistrict_id": "4946",
        "district_id": "617",
        "subdistrict_name": "ตาดควัน"
    },
    {
        "subdistrict_id": "4947",
        "district_id": "618",
        "subdistrict_name": "ม่วงยาย"
    },
    {
        "subdistrict_id": "4948",
        "district_id": "618",
        "subdistrict_name": "ปอ"
    },
    {
        "subdistrict_id": "4949",
        "district_id": "618",
        "subdistrict_name": "หล่ายงาว"
    },
    {
        "subdistrict_id": "4950",
        "district_id": "618",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "4951",
        "district_id": "619",
        "subdistrict_name": "ต้า"
    },
    {
        "subdistrict_id": "4952",
        "district_id": "619",
        "subdistrict_name": "ป่าตาล"
    },
    {
        "subdistrict_id": "4953",
        "district_id": "619",
        "subdistrict_name": "ยางฮอม"
    },
    {
        "subdistrict_id": "4954",
        "district_id": "620",
        "subdistrict_name": "เทอดไทย"
    },
    {
        "subdistrict_id": "4955",
        "district_id": "620",
        "subdistrict_name": "แม่สลองใน"
    },
    {
        "subdistrict_id": "4956",
        "district_id": "620",
        "subdistrict_name": "แม่สลองนอก"
    },
    {
        "subdistrict_id": "4957",
        "district_id": "620",
        "subdistrict_name": "แม่ฟ้าหลวง"
    },
    {
        "subdistrict_id": "4958",
        "district_id": "621",
        "subdistrict_name": "ดงมะดะ"
    },
    {
        "subdistrict_id": "4959",
        "district_id": "621",
        "subdistrict_name": "จอมหมอกแก้ว"
    },
    {
        "subdistrict_id": "4960",
        "district_id": "621",
        "subdistrict_name": "บัวสลี"
    },
    {
        "subdistrict_id": "4961",
        "district_id": "621",
        "subdistrict_name": "ป่าก่อดำ"
    },
    {
        "subdistrict_id": "4962",
        "district_id": "621",
        "subdistrict_name": "โป่งแพร่"
    },
    {
        "subdistrict_id": "4963",
        "district_id": "622",
        "subdistrict_name": "ทุ่งก่อ"
    },
    {
        "subdistrict_id": "4964",
        "district_id": "622",
        "subdistrict_name": "ดงมหาวัน"
    },
    {
        "subdistrict_id": "4965",
        "district_id": "622",
        "subdistrict_name": "ป่าซาง"
    },
    {
        "subdistrict_id": "4966",
        "district_id": "623",
        "subdistrict_name": "ปงน้อย"
    },
    {
        "subdistrict_id": "4967",
        "district_id": "623",
        "subdistrict_name": "โชคชัย"
    },
    {
        "subdistrict_id": "4968",
        "district_id": "623",
        "subdistrict_name": "หนองป่าก่อ"
    },
    {
        "subdistrict_id": "4969",
        "district_id": "624",
        "subdistrict_name": "จองคำ"
    },
    {
        "subdistrict_id": "4970",
        "district_id": "624",
        "subdistrict_name": "ห้วยโป่ง"
    },
    {
        "subdistrict_id": "4971",
        "district_id": "624",
        "subdistrict_name": "ผาบ่อง"
    },
    {
        "subdistrict_id": "4972",
        "district_id": "624",
        "subdistrict_name": "ปางหมู"
    },
    {
        "subdistrict_id": "4973",
        "district_id": "624",
        "subdistrict_name": "หมอกจำแป่"
    },
    {
        "subdistrict_id": "4974",
        "district_id": "624",
        "subdistrict_name": "ห้วยผา"
    },
    {
        "subdistrict_id": "4975",
        "district_id": "624",
        "subdistrict_name": "ห้วยปูลิง"
    },
    {
        "subdistrict_id": "4976",
        "district_id": "625",
        "subdistrict_name": "ขุนยวม"
    },
    {
        "subdistrict_id": "4977",
        "district_id": "625",
        "subdistrict_name": "แม่เงา"
    },
    {
        "subdistrict_id": "4978",
        "district_id": "625",
        "subdistrict_name": "เมืองปอน"
    },
    {
        "subdistrict_id": "4979",
        "district_id": "625",
        "subdistrict_name": "แม่ยวมน้อย"
    },
    {
        "subdistrict_id": "4980",
        "district_id": "625",
        "subdistrict_name": "แม่กิ๊"
    },
    {
        "subdistrict_id": "4981",
        "district_id": "625",
        "subdistrict_name": "แม่อูคอ"
    },
    {
        "subdistrict_id": "4982",
        "district_id": "626",
        "subdistrict_name": "เวียงใต้"
    },
    {
        "subdistrict_id": "4983",
        "district_id": "626",
        "subdistrict_name": "เวียงเหนือ"
    },
    {
        "subdistrict_id": "4984",
        "district_id": "626",
        "subdistrict_name": "แม่นาเติง"
    },
    {
        "subdistrict_id": "4985",
        "district_id": "626",
        "subdistrict_name": "แม่ฮี้"
    },
    {
        "subdistrict_id": "4986",
        "district_id": "626",
        "subdistrict_name": "ทุ่งยาว"
    },
    {
        "subdistrict_id": "4987",
        "district_id": "626",
        "subdistrict_name": "เมืองแปง"
    },
    {
        "subdistrict_id": "4988",
        "district_id": "626",
        "subdistrict_name": "โป่งสา"
    },
    {
        "subdistrict_id": "4989",
        "district_id": "627",
        "subdistrict_name": "บ้านกาศ"
    },
    {
        "subdistrict_id": "4990",
        "district_id": "627",
        "subdistrict_name": "แม่สะเรียง"
    },
    {
        "subdistrict_id": "4991",
        "district_id": "627",
        "subdistrict_name": "แม่คง"
    },
    {
        "subdistrict_id": "4992",
        "district_id": "627",
        "subdistrict_name": "แม่เหาะ"
    },
    {
        "subdistrict_id": "4993",
        "district_id": "627",
        "subdistrict_name": "แม่ยวม"
    },
    {
        "subdistrict_id": "4994",
        "district_id": "627",
        "subdistrict_name": "เสาหิน"
    },
    {
        "subdistrict_id": "4995",
        "district_id": "627",
        "subdistrict_name": "ป่าแป๋"
    },
    {
        "subdistrict_id": "4996",
        "district_id": "628",
        "subdistrict_name": "แม่ลาน้อย"
    },
    {
        "subdistrict_id": "4997",
        "district_id": "628",
        "subdistrict_name": "แม่ลาหลวง"
    },
    {
        "subdistrict_id": "4998",
        "district_id": "628",
        "subdistrict_name": "ท่าผาปุ้ม"
    },
    {
        "subdistrict_id": "4999",
        "district_id": "628",
        "subdistrict_name": "แม่โถ"
    },
    {
        "subdistrict_id": "5000",
        "district_id": "628",
        "subdistrict_name": "ห้วยห้อม"
    },
    {
        "subdistrict_id": "5001",
        "district_id": "628",
        "subdistrict_name": "แม่นาจาง"
    },
    {
        "subdistrict_id": "5002",
        "district_id": "628",
        "subdistrict_name": "สันติคีรี"
    },
    {
        "subdistrict_id": "5003",
        "district_id": "628",
        "subdistrict_name": "ขุนแม่ลาน้อย"
    },
    {
        "subdistrict_id": "5004",
        "district_id": "629",
        "subdistrict_name": "สบเมย"
    },
    {
        "subdistrict_id": "5005",
        "district_id": "629",
        "subdistrict_name": "แม่คะตวน"
    },
    {
        "subdistrict_id": "5006",
        "district_id": "629",
        "subdistrict_name": "กองก๋อย"
    },
    {
        "subdistrict_id": "5007",
        "district_id": "629",
        "subdistrict_name": "แม่สวด"
    },
    {
        "subdistrict_id": "5008",
        "district_id": "629",
        "subdistrict_name": "ป่าโปง"
    },
    {
        "subdistrict_id": "5009",
        "district_id": "629",
        "subdistrict_name": "แม่สามแลบ"
    },
    {
        "subdistrict_id": "5010",
        "district_id": "630",
        "subdistrict_name": "สบป่อง"
    },
    {
        "subdistrict_id": "5011",
        "district_id": "630",
        "subdistrict_name": "ปางมะผ้า"
    },
    {
        "subdistrict_id": "5012",
        "district_id": "630",
        "subdistrict_name": "ถ้ำลอด"
    },
    {
        "subdistrict_id": "5013",
        "district_id": "630",
        "subdistrict_name": "นาปู่ป้อม"
    },
    {
        "subdistrict_id": "5014",
        "district_id": "631",
        "subdistrict_name": "ปากน้ำโพ"
    },
    {
        "subdistrict_id": "5015",
        "district_id": "631",
        "subdistrict_name": "กลางแดด"
    },
    {
        "subdistrict_id": "5016",
        "district_id": "631",
        "subdistrict_name": "เกรียงไกร"
    },
    {
        "subdistrict_id": "5017",
        "district_id": "631",
        "subdistrict_name": "แควใหญ่"
    },
    {
        "subdistrict_id": "5018",
        "district_id": "631",
        "subdistrict_name": "ตะเคียนเลื่อน"
    },
    {
        "subdistrict_id": "5019",
        "district_id": "631",
        "subdistrict_name": "นครสวรรค์ตก"
    },
    {
        "subdistrict_id": "5020",
        "district_id": "631",
        "subdistrict_name": "นครสวรรค์ออก"
    },
    {
        "subdistrict_id": "5021",
        "district_id": "631",
        "subdistrict_name": "บางพระหลวง"
    },
    {
        "subdistrict_id": "5022",
        "district_id": "631",
        "subdistrict_name": "บางม่วง"
    },
    {
        "subdistrict_id": "5023",
        "district_id": "631",
        "subdistrict_name": "บ้านมะเกลือ"
    },
    {
        "subdistrict_id": "5024",
        "district_id": "631",
        "subdistrict_name": "บ้านแก่ง"
    },
    {
        "subdistrict_id": "5025",
        "district_id": "631",
        "subdistrict_name": "พระนอน"
    },
    {
        "subdistrict_id": "5026",
        "district_id": "631",
        "subdistrict_name": "วัดไทร"
    },
    {
        "subdistrict_id": "5027",
        "district_id": "631",
        "subdistrict_name": "หนองกรด"
    },
    {
        "subdistrict_id": "5028",
        "district_id": "631",
        "subdistrict_name": "หนองกระโดน"
    },
    {
        "subdistrict_id": "5029",
        "district_id": "631",
        "subdistrict_name": "หนองปลิง"
    },
    {
        "subdistrict_id": "5030",
        "district_id": "631",
        "subdistrict_name": "บึงเสนาท"
    },
    {
        "subdistrict_id": "5031",
        "district_id": "632",
        "subdistrict_name": "โกรกพระ"
    },
    {
        "subdistrict_id": "5032",
        "district_id": "632",
        "subdistrict_name": "ยางตาล"
    },
    {
        "subdistrict_id": "5033",
        "district_id": "632",
        "subdistrict_name": "บางมะฝ่อ"
    },
    {
        "subdistrict_id": "5034",
        "district_id": "632",
        "subdistrict_name": "บางประมุง"
    },
    {
        "subdistrict_id": "5035",
        "district_id": "632",
        "subdistrict_name": "นากลาง"
    },
    {
        "subdistrict_id": "5036",
        "district_id": "632",
        "subdistrict_name": "ศาลาแดง"
    },
    {
        "subdistrict_id": "5037",
        "district_id": "632",
        "subdistrict_name": "เนินกว้าว"
    },
    {
        "subdistrict_id": "5038",
        "district_id": "632",
        "subdistrict_name": "เนินศาลา"
    },
    {
        "subdistrict_id": "5039",
        "district_id": "632",
        "subdistrict_name": "หาดสูง"
    },
    {
        "subdistrict_id": "5040",
        "district_id": "633",
        "subdistrict_name": "ชุมแสง"
    },
    {
        "subdistrict_id": "5041",
        "district_id": "633",
        "subdistrict_name": "ทับกฤช"
    },
    {
        "subdistrict_id": "5042",
        "district_id": "633",
        "subdistrict_name": "พิกุล"
    },
    {
        "subdistrict_id": "5043",
        "district_id": "633",
        "subdistrict_name": "เกยไชย"
    },
    {
        "subdistrict_id": "5044",
        "district_id": "633",
        "subdistrict_name": "ท่าไม้"
    },
    {
        "subdistrict_id": "5045",
        "district_id": "633",
        "subdistrict_name": "บางเคียน"
    },
    {
        "subdistrict_id": "5046",
        "district_id": "633",
        "subdistrict_name": "หนองกระเจา"
    },
    {
        "subdistrict_id": "5047",
        "district_id": "633",
        "subdistrict_name": "พันลาน"
    },
    {
        "subdistrict_id": "5048",
        "district_id": "633",
        "subdistrict_name": "โคกหม้อ"
    },
    {
        "subdistrict_id": "5049",
        "district_id": "633",
        "subdistrict_name": "ไผ่สิงห์"
    },
    {
        "subdistrict_id": "5050",
        "district_id": "633",
        "subdistrict_name": "ฆะมัง"
    },
    {
        "subdistrict_id": "5051",
        "district_id": "633",
        "subdistrict_name": "ทับกฤชใต้"
    },
    {
        "subdistrict_id": "5052",
        "district_id": "634",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "5053",
        "district_id": "634",
        "subdistrict_name": "หนองกลับ"
    },
    {
        "subdistrict_id": "5054",
        "district_id": "634",
        "subdistrict_name": "ธารทหาร"
    },
    {
        "subdistrict_id": "5055",
        "district_id": "634",
        "subdistrict_name": "ห้วยร่วม"
    },
    {
        "subdistrict_id": "5056",
        "district_id": "634",
        "subdistrict_name": "ห้วยถั่วใต้"
    },
    {
        "subdistrict_id": "5057",
        "district_id": "634",
        "subdistrict_name": "ห้วยถั่วเหนือ"
    },
    {
        "subdistrict_id": "5058",
        "district_id": "634",
        "subdistrict_name": "ห้วยใหญ่"
    },
    {
        "subdistrict_id": "5059",
        "district_id": "634",
        "subdistrict_name": "ทุ่งทอง"
    },
    {
        "subdistrict_id": "5060",
        "district_id": "634",
        "subdistrict_name": "วังบ่อ"
    },
    {
        "subdistrict_id": "5061",
        "district_id": "635",
        "subdistrict_name": "ท่างิ้ว"
    },
    {
        "subdistrict_id": "5062",
        "district_id": "635",
        "subdistrict_name": "บางตาหงาย"
    },
    {
        "subdistrict_id": "5063",
        "district_id": "635",
        "subdistrict_name": "หูกวาง"
    },
    {
        "subdistrict_id": "5064",
        "district_id": "635",
        "subdistrict_name": "อ่างทอง"
    },
    {
        "subdistrict_id": "5065",
        "district_id": "635",
        "subdistrict_name": "บ้านแดน"
    },
    {
        "subdistrict_id": "5066",
        "district_id": "635",
        "subdistrict_name": "บางแก้ว"
    },
    {
        "subdistrict_id": "5067",
        "district_id": "635",
        "subdistrict_name": "ตาขีด"
    },
    {
        "subdistrict_id": "5068",
        "district_id": "635",
        "subdistrict_name": "ตาสัง"
    },
    {
        "subdistrict_id": "5069",
        "district_id": "635",
        "subdistrict_name": "ด่านช้าง"
    },
    {
        "subdistrict_id": "5070",
        "district_id": "635",
        "subdistrict_name": "หนองกรด"
    },
    {
        "subdistrict_id": "5071",
        "district_id": "635",
        "subdistrict_name": "หนองตางู"
    },
    {
        "subdistrict_id": "5072",
        "district_id": "635",
        "subdistrict_name": "บึงปลาทู"
    },
    {
        "subdistrict_id": "5073",
        "district_id": "635",
        "subdistrict_name": "เจริญผล"
    },
    {
        "subdistrict_id": "5074",
        "district_id": "636",
        "subdistrict_name": "มหาโพธิ"
    },
    {
        "subdistrict_id": "5075",
        "district_id": "636",
        "subdistrict_name": "เก้าเลี้ยว"
    },
    {
        "subdistrict_id": "5076",
        "district_id": "636",
        "subdistrict_name": "หนองเต่า"
    },
    {
        "subdistrict_id": "5077",
        "district_id": "636",
        "subdistrict_name": "เขาดิน"
    },
    {
        "subdistrict_id": "5078",
        "district_id": "636",
        "subdistrict_name": "หัวดง"
    },
    {
        "subdistrict_id": "5079",
        "district_id": "637",
        "subdistrict_name": "ตาคลี"
    },
    {
        "subdistrict_id": "5080",
        "district_id": "637",
        "subdistrict_name": "ช่องแค"
    },
    {
        "subdistrict_id": "5081",
        "district_id": "637",
        "subdistrict_name": "จันเสน"
    },
    {
        "subdistrict_id": "5082",
        "district_id": "637",
        "subdistrict_name": "ห้วยหอม"
    },
    {
        "subdistrict_id": "5083",
        "district_id": "637",
        "subdistrict_name": "หัวหวาย"
    },
    {
        "subdistrict_id": "5084",
        "district_id": "637",
        "subdistrict_name": "หนองโพ"
    },
    {
        "subdistrict_id": "5085",
        "district_id": "637",
        "subdistrict_name": "หนองหม้อ"
    },
    {
        "subdistrict_id": "5086",
        "district_id": "637",
        "subdistrict_name": "สร้อยทอง"
    },
    {
        "subdistrict_id": "5087",
        "district_id": "637",
        "subdistrict_name": "ลาดทิพรส"
    },
    {
        "subdistrict_id": "5088",
        "district_id": "637",
        "subdistrict_name": "พรหมนิมิต"
    },
    {
        "subdistrict_id": "5089",
        "district_id": "638",
        "subdistrict_name": "ท่าตะโก"
    },
    {
        "subdistrict_id": "5090",
        "district_id": "638",
        "subdistrict_name": "พนมรอก"
    },
    {
        "subdistrict_id": "5091",
        "district_id": "638",
        "subdistrict_name": "หัวถนน"
    },
    {
        "subdistrict_id": "5092",
        "district_id": "638",
        "subdistrict_name": "สายลำโพง"
    },
    {
        "subdistrict_id": "5093",
        "district_id": "638",
        "subdistrict_name": "วังมหากร"
    },
    {
        "subdistrict_id": "5094",
        "district_id": "638",
        "subdistrict_name": "ดอนคา"
    },
    {
        "subdistrict_id": "5095",
        "district_id": "638",
        "subdistrict_name": "ทำนบ"
    },
    {
        "subdistrict_id": "5096",
        "district_id": "638",
        "subdistrict_name": "วังใหญ่"
    },
    {
        "subdistrict_id": "5097",
        "district_id": "638",
        "subdistrict_name": "พนมเศษ"
    },
    {
        "subdistrict_id": "5098",
        "district_id": "638",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "5099",
        "district_id": "639",
        "subdistrict_name": "โคกเดื่อ"
    },
    {
        "subdistrict_id": "5100",
        "district_id": "639",
        "subdistrict_name": "สำโรงชัย"
    },
    {
        "subdistrict_id": "5101",
        "district_id": "639",
        "subdistrict_name": "วังน้ำลัด"
    },
    {
        "subdistrict_id": "5102",
        "district_id": "639",
        "subdistrict_name": "ตะคร้อ"
    },
    {
        "subdistrict_id": "5103",
        "district_id": "639",
        "subdistrict_name": "โพธิ์ประสาท"
    },
    {
        "subdistrict_id": "5104",
        "district_id": "639",
        "subdistrict_name": "วังข่อย"
    },
    {
        "subdistrict_id": "5105",
        "district_id": "639",
        "subdistrict_name": "นาขอม"
    },
    {
        "subdistrict_id": "5106",
        "district_id": "639",
        "subdistrict_name": "ไพศาลี"
    },
    {
        "subdistrict_id": "5107",
        "district_id": "640",
        "subdistrict_name": "พยุหะ"
    },
    {
        "subdistrict_id": "5108",
        "district_id": "640",
        "subdistrict_name": "เนินมะกอก"
    },
    {
        "subdistrict_id": "5109",
        "district_id": "640",
        "subdistrict_name": "นิคมเขาบ่อแก้ว"
    },
    {
        "subdistrict_id": "5110",
        "district_id": "640",
        "subdistrict_name": "ม่วงหัก"
    },
    {
        "subdistrict_id": "5111",
        "district_id": "640",
        "subdistrict_name": "ยางขาว"
    },
    {
        "subdistrict_id": "5112",
        "district_id": "640",
        "subdistrict_name": "ย่านมัทรี"
    },
    {
        "subdistrict_id": "5113",
        "district_id": "640",
        "subdistrict_name": "เขาทอง"
    },
    {
        "subdistrict_id": "5114",
        "district_id": "640",
        "subdistrict_name": "ท่าน้ำอ้อย"
    },
    {
        "subdistrict_id": "5115",
        "district_id": "640",
        "subdistrict_name": "น้ำทรง"
    },
    {
        "subdistrict_id": "5116",
        "district_id": "640",
        "subdistrict_name": "เขากะลา"
    },
    {
        "subdistrict_id": "5117",
        "district_id": "640",
        "subdistrict_name": "สระทะเล"
    },
    {
        "subdistrict_id": "5118",
        "district_id": "641",
        "subdistrict_name": "ลาดยาว"
    },
    {
        "subdistrict_id": "5119",
        "district_id": "641",
        "subdistrict_name": "ห้วยน้ำหอม"
    },
    {
        "subdistrict_id": "5120",
        "district_id": "641",
        "subdistrict_name": "วังม้า"
    },
    {
        "subdistrict_id": "5121",
        "district_id": "641",
        "subdistrict_name": "วังเมือง"
    },
    {
        "subdistrict_id": "5122",
        "district_id": "641",
        "subdistrict_name": "สร้อยละคร"
    },
    {
        "subdistrict_id": "5123",
        "district_id": "641",
        "subdistrict_name": "มาบแก"
    },
    {
        "subdistrict_id": "5124",
        "district_id": "641",
        "subdistrict_name": "หนองยาว"
    },
    {
        "subdistrict_id": "5125",
        "district_id": "641",
        "subdistrict_name": "หนองนมวัว"
    },
    {
        "subdistrict_id": "5126",
        "district_id": "641",
        "subdistrict_name": "บ้านไร่"
    },
    {
        "subdistrict_id": "5127",
        "district_id": "641",
        "subdistrict_name": "เนินขี้เหล็ก"
    },
    {
        "subdistrict_id": "5128",
        "district_id": "641",
        "subdistrict_name": "ศาลเจ้าไก่ต่อ"
    },
    {
        "subdistrict_id": "5129",
        "district_id": "641",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "5130",
        "district_id": "642",
        "subdistrict_name": "ตากฟ้า"
    },
    {
        "subdistrict_id": "5131",
        "district_id": "642",
        "subdistrict_name": "ลำพยนต์"
    },
    {
        "subdistrict_id": "5132",
        "district_id": "642",
        "subdistrict_name": "สุขสำราญ"
    },
    {
        "subdistrict_id": "5133",
        "district_id": "642",
        "subdistrict_name": "หนองพิกุล"
    },
    {
        "subdistrict_id": "5134",
        "district_id": "642",
        "subdistrict_name": "พุนกยูง"
    },
    {
        "subdistrict_id": "5135",
        "district_id": "642",
        "subdistrict_name": "อุดมธัญญา"
    },
    {
        "subdistrict_id": "5136",
        "district_id": "642",
        "subdistrict_name": "เขาชายธง"
    },
    {
        "subdistrict_id": "5137",
        "district_id": "643",
        "subdistrict_name": "แม่วงก์"
    },
    {
        "subdistrict_id": "5138",
        "district_id": "643",
        "subdistrict_name": "แม่เล่ย์"
    },
    {
        "subdistrict_id": "5139",
        "district_id": "643",
        "subdistrict_name": "วังซ่าน"
    },
    {
        "subdistrict_id": "5140",
        "district_id": "643",
        "subdistrict_name": "เขาชนกัน"
    },
    {
        "subdistrict_id": "5141",
        "district_id": "644",
        "subdistrict_name": "แม่เปิน"
    },
    {
        "subdistrict_id": "5142",
        "district_id": "645",
        "subdistrict_name": "ชุมตาบง"
    },
    {
        "subdistrict_id": "5143",
        "district_id": "645",
        "subdistrict_name": "ปางสวรรค์"
    },
    {
        "subdistrict_id": "5144",
        "district_id": "646",
        "subdistrict_name": "อุทัยใหม่"
    },
    {
        "subdistrict_id": "5145",
        "district_id": "646",
        "subdistrict_name": "น้ำซึม"
    },
    {
        "subdistrict_id": "5146",
        "district_id": "646",
        "subdistrict_name": "สะแกกรัง"
    },
    {
        "subdistrict_id": "5147",
        "district_id": "646",
        "subdistrict_name": "ดอนขวาง"
    },
    {
        "subdistrict_id": "5148",
        "district_id": "646",
        "subdistrict_name": "หาดทนง"
    },
    {
        "subdistrict_id": "5149",
        "district_id": "646",
        "subdistrict_name": "เกาะเทโพ"
    },
    {
        "subdistrict_id": "5150",
        "district_id": "646",
        "subdistrict_name": "ท่าซุง"
    },
    {
        "subdistrict_id": "5151",
        "district_id": "646",
        "subdistrict_name": "หนองแก"
    },
    {
        "subdistrict_id": "5152",
        "district_id": "646",
        "subdistrict_name": "โนนเหล็ก"
    },
    {
        "subdistrict_id": "5153",
        "district_id": "646",
        "subdistrict_name": "หนองเต่า"
    },
    {
        "subdistrict_id": "5154",
        "district_id": "646",
        "subdistrict_name": "หนองไผ่แบน"
    },
    {
        "subdistrict_id": "5155",
        "district_id": "646",
        "subdistrict_name": "หนองพังค่า"
    },
    {
        "subdistrict_id": "5156",
        "district_id": "646",
        "subdistrict_name": "ทุ่งใหญ่"
    },
    {
        "subdistrict_id": "5157",
        "district_id": "646",
        "subdistrict_name": "เนินแจง"
    },
    {
        "subdistrict_id": "5158",
        "district_id": "647",
        "subdistrict_name": "ทัพทัน"
    },
    {
        "subdistrict_id": "5159",
        "district_id": "647",
        "subdistrict_name": "ทุ่งนาไทย"
    },
    {
        "subdistrict_id": "5160",
        "district_id": "647",
        "subdistrict_name": "เขาขี้ฝอย"
    },
    {
        "subdistrict_id": "5161",
        "district_id": "647",
        "subdistrict_name": "หนองหญ้าปล้อง"
    },
    {
        "subdistrict_id": "5162",
        "district_id": "647",
        "subdistrict_name": "โคกหม้อ"
    },
    {
        "subdistrict_id": "5163",
        "district_id": "647",
        "subdistrict_name": "หนองยายดา"
    },
    {
        "subdistrict_id": "5164",
        "district_id": "647",
        "subdistrict_name": "หนองกลางดง"
    },
    {
        "subdistrict_id": "5165",
        "district_id": "647",
        "subdistrict_name": "หนองกระทุ่ม"
    },
    {
        "subdistrict_id": "5166",
        "district_id": "647",
        "subdistrict_name": "หนองสระ"
    },
    {
        "subdistrict_id": "5167",
        "district_id": "647",
        "subdistrict_name": "ตลุกดู่"
    },
    {
        "subdistrict_id": "5168",
        "district_id": "648",
        "subdistrict_name": "สว่างอารมณ์"
    },
    {
        "subdistrict_id": "5169",
        "district_id": "648",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "5170",
        "district_id": "648",
        "subdistrict_name": "พลวงสองนาง"
    },
    {
        "subdistrict_id": "5171",
        "district_id": "648",
        "subdistrict_name": "ไผ่เขียว"
    },
    {
        "subdistrict_id": "5172",
        "district_id": "648",
        "subdistrict_name": "บ่อยาง"
    },
    {
        "subdistrict_id": "5173",
        "district_id": "649",
        "subdistrict_name": "หนองฉาง"
    },
    {
        "subdistrict_id": "5174",
        "district_id": "649",
        "subdistrict_name": "หนองยาง"
    },
    {
        "subdistrict_id": "5175",
        "district_id": "649",
        "subdistrict_name": "หนองนางนวล"
    },
    {
        "subdistrict_id": "5176",
        "district_id": "649",
        "subdistrict_name": "หนองสรวง"
    },
    {
        "subdistrict_id": "5177",
        "district_id": "649",
        "subdistrict_name": "บ้านเก่า"
    },
    {
        "subdistrict_id": "5178",
        "district_id": "649",
        "subdistrict_name": "อุทัยเก่า"
    },
    {
        "subdistrict_id": "5179",
        "district_id": "649",
        "subdistrict_name": "ทุ่งโพ"
    },
    {
        "subdistrict_id": "5180",
        "district_id": "649",
        "subdistrict_name": "ทุ่งพง"
    },
    {
        "subdistrict_id": "5181",
        "district_id": "649",
        "subdistrict_name": "เขาบางแกรก"
    },
    {
        "subdistrict_id": "5182",
        "district_id": "649",
        "subdistrict_name": "เขากวางทอง"
    },
    {
        "subdistrict_id": "5183",
        "district_id": "650",
        "subdistrict_name": "หนองขาหย่าง"
    },
    {
        "subdistrict_id": "5184",
        "district_id": "650",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "5185",
        "district_id": "650",
        "subdistrict_name": "ดอนกลอย"
    },
    {
        "subdistrict_id": "5186",
        "district_id": "650",
        "subdistrict_name": "ห้วยรอบ"
    },
    {
        "subdistrict_id": "5187",
        "district_id": "650",
        "subdistrict_name": "ทุ่งพึ่ง"
    },
    {
        "subdistrict_id": "5188",
        "district_id": "650",
        "subdistrict_name": "ท่าโพ"
    },
    {
        "subdistrict_id": "5189",
        "district_id": "650",
        "subdistrict_name": "หมกแถว"
    },
    {
        "subdistrict_id": "5190",
        "district_id": "650",
        "subdistrict_name": "หลุมเข้า"
    },
    {
        "subdistrict_id": "5191",
        "district_id": "650",
        "subdistrict_name": "ดงขวาง"
    },
    {
        "subdistrict_id": "5192",
        "district_id": "651",
        "subdistrict_name": "บ้านไร่"
    },
    {
        "subdistrict_id": "5193",
        "district_id": "651",
        "subdistrict_name": "ทัพหลวง"
    },
    {
        "subdistrict_id": "5194",
        "district_id": "651",
        "subdistrict_name": "ห้วยแห้ง"
    },
    {
        "subdistrict_id": "5195",
        "district_id": "651",
        "subdistrict_name": "คอกควาย"
    },
    {
        "subdistrict_id": "5196",
        "district_id": "651",
        "subdistrict_name": "วังหิน"
    },
    {
        "subdistrict_id": "5197",
        "district_id": "651",
        "subdistrict_name": "เมืองการุ้ง"
    },
    {
        "subdistrict_id": "5198",
        "district_id": "651",
        "subdistrict_name": "แก่นมะกรูด"
    },
    {
        "subdistrict_id": "5199",
        "district_id": "651",
        "subdistrict_name": "หนองจอก"
    },
    {
        "subdistrict_id": "5200",
        "district_id": "651",
        "subdistrict_name": "หูช้าง"
    },
    {
        "subdistrict_id": "5201",
        "district_id": "651",
        "subdistrict_name": "บ้านบึง"
    },
    {
        "subdistrict_id": "5202",
        "district_id": "651",
        "subdistrict_name": "บ้านใหม่คลองเคียน"
    },
    {
        "subdistrict_id": "5203",
        "district_id": "651",
        "subdistrict_name": "หนองบ่มกล้วย"
    },
    {
        "subdistrict_id": "5204",
        "district_id": "651",
        "subdistrict_name": "เจ้าวัด"
    },
    {
        "subdistrict_id": "5205",
        "district_id": "652",
        "subdistrict_name": "ลานสัก"
    },
    {
        "subdistrict_id": "5206",
        "district_id": "652",
        "subdistrict_name": "ประดู่ยืน"
    },
    {
        "subdistrict_id": "5207",
        "district_id": "652",
        "subdistrict_name": "ป่าอ้อ"
    },
    {
        "subdistrict_id": "5208",
        "district_id": "652",
        "subdistrict_name": "ระบำ"
    },
    {
        "subdistrict_id": "5209",
        "district_id": "652",
        "subdistrict_name": "น้ำรอบ"
    },
    {
        "subdistrict_id": "5210",
        "district_id": "652",
        "subdistrict_name": "ทุ่งนางาม"
    },
    {
        "subdistrict_id": "5211",
        "district_id": "653",
        "subdistrict_name": "สุขฤทัย"
    },
    {
        "subdistrict_id": "5212",
        "district_id": "653",
        "subdistrict_name": "ทองหลาง"
    },
    {
        "subdistrict_id": "5213",
        "district_id": "653",
        "subdistrict_name": "ห้วยคต"
    },
    {
        "subdistrict_id": "5214",
        "district_id": "654",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "5215",
        "district_id": "654",
        "subdistrict_name": "ไตรตรึงษ์"
    },
    {
        "subdistrict_id": "5216",
        "district_id": "654",
        "subdistrict_name": "อ่างทอง"
    },
    {
        "subdistrict_id": "5217",
        "district_id": "654",
        "subdistrict_name": "นาบ่อคำ"
    },
    {
        "subdistrict_id": "5218",
        "district_id": "654",
        "subdistrict_name": "นครชุม"
    },
    {
        "subdistrict_id": "5219",
        "district_id": "654",
        "subdistrict_name": "ทรงธรรม"
    },
    {
        "subdistrict_id": "5220",
        "district_id": "654",
        "subdistrict_name": "ลานดอกไม้"
    },
    {
        "subdistrict_id": "5221",
        "district_id": "654",
        "subdistrict_name": "หนองปลิง"
    },
    {
        "subdistrict_id": "5222",
        "district_id": "654",
        "subdistrict_name": "คณฑี"
    },
    {
        "subdistrict_id": "5223",
        "district_id": "654",
        "subdistrict_name": "นิคมทุ่งโพธิ์ทะเล"
    },
    {
        "subdistrict_id": "5224",
        "district_id": "654",
        "subdistrict_name": "เทพนคร"
    },
    {
        "subdistrict_id": "5225",
        "district_id": "654",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "5226",
        "district_id": "654",
        "subdistrict_name": "ท่าขุนราม"
    },
    {
        "subdistrict_id": "5227",
        "district_id": "654",
        "subdistrict_name": "คลองแม่ลาย"
    },
    {
        "subdistrict_id": "5228",
        "district_id": "654",
        "subdistrict_name": "ธำมรงค์"
    },
    {
        "subdistrict_id": "5229",
        "district_id": "654",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "5230",
        "district_id": "655",
        "subdistrict_name": "ไทรงาม"
    },
    {
        "subdistrict_id": "5231",
        "district_id": "655",
        "subdistrict_name": "หนองคล้า"
    },
    {
        "subdistrict_id": "5232",
        "district_id": "655",
        "subdistrict_name": "หนองทอง"
    },
    {
        "subdistrict_id": "5233",
        "district_id": "655",
        "subdistrict_name": "หนองไม้กอง"
    },
    {
        "subdistrict_id": "5234",
        "district_id": "655",
        "subdistrict_name": "มหาชัย"
    },
    {
        "subdistrict_id": "5235",
        "district_id": "655",
        "subdistrict_name": "พานทอง"
    },
    {
        "subdistrict_id": "5236",
        "district_id": "655",
        "subdistrict_name": "หนองแม่แตง"
    },
    {
        "subdistrict_id": "5237",
        "district_id": "656",
        "subdistrict_name": "คลองน้ำไหล"
    },
    {
        "subdistrict_id": "5238",
        "district_id": "656",
        "subdistrict_name": "โป่งน้ำร้อน"
    },
    {
        "subdistrict_id": "5239",
        "district_id": "656",
        "subdistrict_name": "คลองลานพัฒนา"
    },
    {
        "subdistrict_id": "5240",
        "district_id": "656",
        "subdistrict_name": "สักงาม"
    },
    {
        "subdistrict_id": "5241",
        "district_id": "657",
        "subdistrict_name": "ยางสูง"
    },
    {
        "subdistrict_id": "5242",
        "district_id": "657",
        "subdistrict_name": "ป่าพุทรา"
    },
    {
        "subdistrict_id": "5243",
        "district_id": "657",
        "subdistrict_name": "แสนตอ"
    },
    {
        "subdistrict_id": "5244",
        "district_id": "657",
        "subdistrict_name": "สลกบาตร"
    },
    {
        "subdistrict_id": "5245",
        "district_id": "657",
        "subdistrict_name": "บ่อถ้ำ"
    },
    {
        "subdistrict_id": "5246",
        "district_id": "657",
        "subdistrict_name": "ดอนแตง"
    },
    {
        "subdistrict_id": "5247",
        "district_id": "657",
        "subdistrict_name": "วังชะพลู"
    },
    {
        "subdistrict_id": "5248",
        "district_id": "657",
        "subdistrict_name": "โค้งไผ่"
    },
    {
        "subdistrict_id": "5249",
        "district_id": "657",
        "subdistrict_name": "ปางมะค่า"
    },
    {
        "subdistrict_id": "5250",
        "district_id": "657",
        "subdistrict_name": "วังหามแห"
    },
    {
        "subdistrict_id": "5251",
        "district_id": "657",
        "subdistrict_name": "เกาะตาล"
    },
    {
        "subdistrict_id": "5252",
        "district_id": "658",
        "subdistrict_name": "คลองขลุง"
    },
    {
        "subdistrict_id": "5253",
        "district_id": "658",
        "subdistrict_name": "ท่ามะเขือ"
    },
    {
        "subdistrict_id": "5254",
        "district_id": "658",
        "subdistrict_name": "ท่าพุทรา"
    },
    {
        "subdistrict_id": "5255",
        "district_id": "658",
        "subdistrict_name": "แม่ลาด"
    },
    {
        "subdistrict_id": "5256",
        "district_id": "658",
        "subdistrict_name": "วังยาง"
    },
    {
        "subdistrict_id": "5257",
        "district_id": "658",
        "subdistrict_name": "วังแขม"
    },
    {
        "subdistrict_id": "5258",
        "district_id": "658",
        "subdistrict_name": "หัวถนน"
    },
    {
        "subdistrict_id": "5259",
        "district_id": "658",
        "subdistrict_name": "วังไทร"
    },
    {
        "subdistrict_id": "5260",
        "district_id": "658",
        "subdistrict_name": "วังบัว"
    },
    {
        "subdistrict_id": "5261",
        "district_id": "658",
        "subdistrict_name": "คลองสมบูรณ์"
    },
    {
        "subdistrict_id": "5262",
        "district_id": "659",
        "subdistrict_name": "พรานกระต่าย"
    },
    {
        "subdistrict_id": "5263",
        "district_id": "659",
        "subdistrict_name": "หนองหัววัว"
    },
    {
        "subdistrict_id": "5264",
        "district_id": "659",
        "subdistrict_name": "ท่าไม้"
    },
    {
        "subdistrict_id": "5265",
        "district_id": "659",
        "subdistrict_name": "วังควง"
    },
    {
        "subdistrict_id": "5266",
        "district_id": "659",
        "subdistrict_name": "วังตะแบก"
    },
    {
        "subdistrict_id": "5267",
        "district_id": "659",
        "subdistrict_name": "เขาคีริส"
    },
    {
        "subdistrict_id": "5268",
        "district_id": "659",
        "subdistrict_name": "คุยบ้านโอง"
    },
    {
        "subdistrict_id": "5269",
        "district_id": "659",
        "subdistrict_name": "คลองพิไกร"
    },
    {
        "subdistrict_id": "5270",
        "district_id": "659",
        "subdistrict_name": "ถ้ำกระต่ายทอง"
    },
    {
        "subdistrict_id": "5271",
        "district_id": "659",
        "subdistrict_name": "ห้วยยั้ง"
    },
    {
        "subdistrict_id": "5272",
        "district_id": "660",
        "subdistrict_name": "ลานกระบือ"
    },
    {
        "subdistrict_id": "5273",
        "district_id": "660",
        "subdistrict_name": "ช่องลม"
    },
    {
        "subdistrict_id": "5274",
        "district_id": "660",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "5275",
        "district_id": "660",
        "subdistrict_name": "โนนพลวง"
    },
    {
        "subdistrict_id": "5276",
        "district_id": "660",
        "subdistrict_name": "ประชาสุขสันต์"
    },
    {
        "subdistrict_id": "5277",
        "district_id": "660",
        "subdistrict_name": "บึงทับแรต"
    },
    {
        "subdistrict_id": "5278",
        "district_id": "660",
        "subdistrict_name": "จันทิมา"
    },
    {
        "subdistrict_id": "5279",
        "district_id": "661",
        "subdistrict_name": "ทุ่งทราย"
    },
    {
        "subdistrict_id": "5280",
        "district_id": "661",
        "subdistrict_name": "ทุ่งทอง"
    },
    {
        "subdistrict_id": "5281",
        "district_id": "661",
        "subdistrict_name": "ถาวรวัฒนา"
    },
    {
        "subdistrict_id": "5282",
        "district_id": "662",
        "subdistrict_name": "โพธิ์ทอง"
    },
    {
        "subdistrict_id": "5283",
        "district_id": "662",
        "subdistrict_name": "หินดาต"
    },
    {
        "subdistrict_id": "5284",
        "district_id": "662",
        "subdistrict_name": "ปางตาไว"
    },
    {
        "subdistrict_id": "5285",
        "district_id": "663",
        "subdistrict_name": "บึงสามัคคี"
    },
    {
        "subdistrict_id": "5286",
        "district_id": "663",
        "subdistrict_name": "วังชะโอน"
    },
    {
        "subdistrict_id": "5287",
        "district_id": "663",
        "subdistrict_name": "ระหาน"
    },
    {
        "subdistrict_id": "5288",
        "district_id": "663",
        "subdistrict_name": "เทพนิมิต"
    },
    {
        "subdistrict_id": "5289",
        "district_id": "664",
        "subdistrict_name": "โกสัมพี"
    },
    {
        "subdistrict_id": "5290",
        "district_id": "664",
        "subdistrict_name": "เพชรชมภู"
    },
    {
        "subdistrict_id": "5291",
        "district_id": "664",
        "subdistrict_name": "ลานดอกไม้ตก"
    },
    {
        "subdistrict_id": "5292",
        "district_id": "665",
        "subdistrict_name": "ระแหง"
    },
    {
        "subdistrict_id": "5293",
        "district_id": "665",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "5294",
        "district_id": "665",
        "subdistrict_name": "เชียงเงิน"
    },
    {
        "subdistrict_id": "5295",
        "district_id": "665",
        "subdistrict_name": "หัวเดียด"
    },
    {
        "subdistrict_id": "5296",
        "district_id": "665",
        "subdistrict_name": "หนองบัวเหนือ"
    },
    {
        "subdistrict_id": "5297",
        "district_id": "665",
        "subdistrict_name": "ไม้งาม"
    },
    {
        "subdistrict_id": "5298",
        "district_id": "665",
        "subdistrict_name": "โป่งแดง"
    },
    {
        "subdistrict_id": "5299",
        "district_id": "665",
        "subdistrict_name": "น้ำรึม"
    },
    {
        "subdistrict_id": "5300",
        "district_id": "665",
        "subdistrict_name": "วังหิน"
    },
    {
        "subdistrict_id": "5301",
        "district_id": "665",
        "subdistrict_name": "แม่ท้อ"
    },
    {
        "subdistrict_id": "5302",
        "district_id": "665",
        "subdistrict_name": "ป่ามะม่วง"
    },
    {
        "subdistrict_id": "5303",
        "district_id": "665",
        "subdistrict_name": "หนองบัวใต้"
    },
    {
        "subdistrict_id": "5304",
        "district_id": "665",
        "subdistrict_name": "วังประจบ"
    },
    {
        "subdistrict_id": "5305",
        "district_id": "665",
        "subdistrict_name": "ตลุกกลางทุ่ง"
    },
    {
        "subdistrict_id": "5306",
        "district_id": "666",
        "subdistrict_name": "ตากออก"
    },
    {
        "subdistrict_id": "5307",
        "district_id": "666",
        "subdistrict_name": "สมอโคน"
    },
    {
        "subdistrict_id": "5308",
        "district_id": "666",
        "subdistrict_name": "แม่สลิด"
    },
    {
        "subdistrict_id": "5309",
        "district_id": "666",
        "subdistrict_name": "ตากตก"
    },
    {
        "subdistrict_id": "5310",
        "district_id": "666",
        "subdistrict_name": "เกาะตะเภา"
    },
    {
        "subdistrict_id": "5311",
        "district_id": "666",
        "subdistrict_name": "ทุ่งกระเชาะ"
    },
    {
        "subdistrict_id": "5312",
        "district_id": "666",
        "subdistrict_name": "ท้องฟ้า"
    },
    {
        "subdistrict_id": "5313",
        "district_id": "667",
        "subdistrict_name": "สามเงา"
    },
    {
        "subdistrict_id": "5314",
        "district_id": "667",
        "subdistrict_name": "วังหมัน"
    },
    {
        "subdistrict_id": "5315",
        "district_id": "667",
        "subdistrict_name": "ยกกระบัตร"
    },
    {
        "subdistrict_id": "5316",
        "district_id": "667",
        "subdistrict_name": "ย่านรี"
    },
    {
        "subdistrict_id": "5317",
        "district_id": "667",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "5318",
        "district_id": "667",
        "subdistrict_name": "วังจันทร์"
    },
    {
        "subdistrict_id": "5319",
        "district_id": "668",
        "subdistrict_name": "แม่ระมาด"
    },
    {
        "subdistrict_id": "5320",
        "district_id": "668",
        "subdistrict_name": "แม่จะเรา"
    },
    {
        "subdistrict_id": "5321",
        "district_id": "668",
        "subdistrict_name": "ขะเนจื้อ"
    },
    {
        "subdistrict_id": "5322",
        "district_id": "668",
        "subdistrict_name": "แม่ตื่น"
    },
    {
        "subdistrict_id": "5323",
        "district_id": "668",
        "subdistrict_name": "สามหมื่น"
    },
    {
        "subdistrict_id": "5324",
        "district_id": "668",
        "subdistrict_name": "พระธาตุ"
    },
    {
        "subdistrict_id": "5325",
        "district_id": "669",
        "subdistrict_name": "ท่าสองยาง"
    },
    {
        "subdistrict_id": "5326",
        "district_id": "669",
        "subdistrict_name": "แม่ต้าน"
    },
    {
        "subdistrict_id": "5327",
        "district_id": "669",
        "subdistrict_name": "แม่สอง"
    },
    {
        "subdistrict_id": "5328",
        "district_id": "669",
        "subdistrict_name": "แม่หละ"
    },
    {
        "subdistrict_id": "5329",
        "district_id": "669",
        "subdistrict_name": "แม่วะหลวง"
    },
    {
        "subdistrict_id": "5330",
        "district_id": "669",
        "subdistrict_name": "แม่อุสุ"
    },
    {
        "subdistrict_id": "5331",
        "district_id": "670",
        "subdistrict_name": "แม่สอด"
    },
    {
        "subdistrict_id": "5332",
        "district_id": "670",
        "subdistrict_name": "แม่กุ"
    },
    {
        "subdistrict_id": "5333",
        "district_id": "670",
        "subdistrict_name": "พะวอ"
    },
    {
        "subdistrict_id": "5334",
        "district_id": "670",
        "subdistrict_name": "แม่ตาว"
    },
    {
        "subdistrict_id": "5335",
        "district_id": "670",
        "subdistrict_name": "แม่กาษา"
    },
    {
        "subdistrict_id": "5336",
        "district_id": "670",
        "subdistrict_name": "ท่าสายลวด"
    },
    {
        "subdistrict_id": "5337",
        "district_id": "670",
        "subdistrict_name": "แม่ปะ"
    },
    {
        "subdistrict_id": "5338",
        "district_id": "670",
        "subdistrict_name": "มหาวัน"
    },
    {
        "subdistrict_id": "5339",
        "district_id": "670",
        "subdistrict_name": "ด่านแม่ละเมา"
    },
    {
        "subdistrict_id": "5340",
        "district_id": "670",
        "subdistrict_name": "พระธาตุผาแดง"
    },
    {
        "subdistrict_id": "5341",
        "district_id": "671",
        "subdistrict_name": "พบพระ"
    },
    {
        "subdistrict_id": "5342",
        "district_id": "671",
        "subdistrict_name": "ช่องแคบ"
    },
    {
        "subdistrict_id": "5343",
        "district_id": "671",
        "subdistrict_name": "คีรีราษฎร์"
    },
    {
        "subdistrict_id": "5344",
        "district_id": "671",
        "subdistrict_name": "วาเล่ย์"
    },
    {
        "subdistrict_id": "5345",
        "district_id": "671",
        "subdistrict_name": "รวมไทยพัฒนา"
    },
    {
        "subdistrict_id": "5346",
        "district_id": "672",
        "subdistrict_name": "อุ้มผาง"
    },
    {
        "subdistrict_id": "5347",
        "district_id": "672",
        "subdistrict_name": "หนองหลวง"
    },
    {
        "subdistrict_id": "5348",
        "district_id": "672",
        "subdistrict_name": "โมโกร"
    },
    {
        "subdistrict_id": "5349",
        "district_id": "672",
        "subdistrict_name": "แม่จัน"
    },
    {
        "subdistrict_id": "5350",
        "district_id": "672",
        "subdistrict_name": "แม่ละมุ้ง"
    },
    {
        "subdistrict_id": "5351",
        "district_id": "672",
        "subdistrict_name": "แม่กลอง"
    },
    {
        "subdistrict_id": "5352",
        "district_id": "673",
        "subdistrict_name": "เชียงทอง"
    },
    {
        "subdistrict_id": "5353",
        "district_id": "673",
        "subdistrict_name": "นาโบสถ์"
    },
    {
        "subdistrict_id": "5354",
        "district_id": "673",
        "subdistrict_name": "ประดาง"
    },
    {
        "subdistrict_id": "5355",
        "district_id": "674",
        "subdistrict_name": "ธานี"
    },
    {
        "subdistrict_id": "5356",
        "district_id": "674",
        "subdistrict_name": "บ้านสวน"
    },
    {
        "subdistrict_id": "5357",
        "district_id": "674",
        "subdistrict_name": "เมืองเก่า"
    },
    {
        "subdistrict_id": "5358",
        "district_id": "674",
        "subdistrict_name": "ปากแคว"
    },
    {
        "subdistrict_id": "5359",
        "district_id": "674",
        "subdistrict_name": "ยางซ้าย"
    },
    {
        "subdistrict_id": "5360",
        "district_id": "674",
        "subdistrict_name": "บ้านกล้วย"
    },
    {
        "subdistrict_id": "5361",
        "district_id": "674",
        "subdistrict_name": "บ้านหลุม"
    },
    {
        "subdistrict_id": "5362",
        "district_id": "674",
        "subdistrict_name": "ตาลเตี้ย"
    },
    {
        "subdistrict_id": "5363",
        "district_id": "674",
        "subdistrict_name": "ปากพระ"
    },
    {
        "subdistrict_id": "5364",
        "district_id": "674",
        "subdistrict_name": "วังทองแดง"
    },
    {
        "subdistrict_id": "5365",
        "district_id": "675",
        "subdistrict_name": "ลานหอย"
    },
    {
        "subdistrict_id": "5366",
        "district_id": "675",
        "subdistrict_name": "บ้านด่าน"
    },
    {
        "subdistrict_id": "5367",
        "district_id": "675",
        "subdistrict_name": "วังตะคร้อ"
    },
    {
        "subdistrict_id": "5368",
        "district_id": "675",
        "subdistrict_name": "วังน้ำขาว"
    },
    {
        "subdistrict_id": "5369",
        "district_id": "675",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "5370",
        "district_id": "675",
        "subdistrict_name": "หนองหญ้าปล้อง"
    },
    {
        "subdistrict_id": "5371",
        "district_id": "675",
        "subdistrict_name": "วังลึก"
    },
    {
        "subdistrict_id": "5372",
        "district_id": "676",
        "subdistrict_name": "โตนด"
    },
    {
        "subdistrict_id": "5373",
        "district_id": "676",
        "subdistrict_name": "ทุ่งหลวง"
    },
    {
        "subdistrict_id": "5374",
        "district_id": "676",
        "subdistrict_name": "บ้านป้อม"
    },
    {
        "subdistrict_id": "5375",
        "district_id": "676",
        "subdistrict_name": "สามพวง"
    },
    {
        "subdistrict_id": "5376",
        "district_id": "676",
        "subdistrict_name": "ศรีคีรีมาศ"
    },
    {
        "subdistrict_id": "5377",
        "district_id": "676",
        "subdistrict_name": "หนองจิก"
    },
    {
        "subdistrict_id": "5378",
        "district_id": "676",
        "subdistrict_name": "นาเชิงคีรี"
    },
    {
        "subdistrict_id": "5379",
        "district_id": "676",
        "subdistrict_name": "หนองกระดิ่ง"
    },
    {
        "subdistrict_id": "5380",
        "district_id": "676",
        "subdistrict_name": "บ้านน้ำพุ"
    },
    {
        "subdistrict_id": "5381",
        "district_id": "676",
        "subdistrict_name": "ทุ่งยางเมือง"
    },
    {
        "subdistrict_id": "5382",
        "district_id": "677",
        "subdistrict_name": "กง"
    },
    {
        "subdistrict_id": "5383",
        "district_id": "677",
        "subdistrict_name": "บ้านกร่าง"
    },
    {
        "subdistrict_id": "5384",
        "district_id": "677",
        "subdistrict_name": "ไกรนอก"
    },
    {
        "subdistrict_id": "5385",
        "district_id": "677",
        "subdistrict_name": "ไกรกลาง"
    },
    {
        "subdistrict_id": "5386",
        "district_id": "677",
        "subdistrict_name": "ไกรใน"
    },
    {
        "subdistrict_id": "5387",
        "district_id": "677",
        "subdistrict_name": "ดงเดือย"
    },
    {
        "subdistrict_id": "5388",
        "district_id": "677",
        "subdistrict_name": "ป่าแฝก"
    },
    {
        "subdistrict_id": "5389",
        "district_id": "677",
        "subdistrict_name": "กกแรต"
    },
    {
        "subdistrict_id": "5390",
        "district_id": "677",
        "subdistrict_name": "ท่าฉนวน"
    },
    {
        "subdistrict_id": "5391",
        "district_id": "677",
        "subdistrict_name": "หนองตูม"
    },
    {
        "subdistrict_id": "5392",
        "district_id": "677",
        "subdistrict_name": "บ้านใหม่สุขเกษม"
    },
    {
        "subdistrict_id": "5393",
        "district_id": "678",
        "subdistrict_name": "หาดเสี้ยว"
    },
    {
        "subdistrict_id": "5394",
        "district_id": "678",
        "subdistrict_name": "ป่างิ้ว"
    },
    {
        "subdistrict_id": "5395",
        "district_id": "678",
        "subdistrict_name": "แม่สำ"
    },
    {
        "subdistrict_id": "5396",
        "district_id": "678",
        "subdistrict_name": "แม่สิน"
    },
    {
        "subdistrict_id": "5397",
        "district_id": "678",
        "subdistrict_name": "บ้านตึก"
    },
    {
        "subdistrict_id": "5398",
        "district_id": "678",
        "subdistrict_name": "หนองอ้อ"
    },
    {
        "subdistrict_id": "5399",
        "district_id": "678",
        "subdistrict_name": "ท่าชัย"
    },
    {
        "subdistrict_id": "5400",
        "district_id": "678",
        "subdistrict_name": "ศรีสัชนาลัย"
    },
    {
        "subdistrict_id": "5401",
        "district_id": "678",
        "subdistrict_name": "ดงคู่"
    },
    {
        "subdistrict_id": "5402",
        "district_id": "678",
        "subdistrict_name": "บ้านแก่ง"
    },
    {
        "subdistrict_id": "5403",
        "district_id": "678",
        "subdistrict_name": "สารจิตร"
    },
    {
        "subdistrict_id": "5404",
        "district_id": "679",
        "subdistrict_name": "คลองตาล"
    },
    {
        "subdistrict_id": "5405",
        "district_id": "679",
        "subdistrict_name": "วังลึก"
    },
    {
        "subdistrict_id": "5406",
        "district_id": "679",
        "subdistrict_name": "สามเรือน"
    },
    {
        "subdistrict_id": "5407",
        "district_id": "679",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "5408",
        "district_id": "679",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "5409",
        "district_id": "679",
        "subdistrict_name": "นาขุนไกร"
    },
    {
        "subdistrict_id": "5410",
        "district_id": "679",
        "subdistrict_name": "เกาะตาเลี้ยง"
    },
    {
        "subdistrict_id": "5411",
        "district_id": "679",
        "subdistrict_name": "วัดเกาะ"
    },
    {
        "subdistrict_id": "5412",
        "district_id": "679",
        "subdistrict_name": "บ้านไร่"
    },
    {
        "subdistrict_id": "5413",
        "district_id": "679",
        "subdistrict_name": "ทับผึ้ง"
    },
    {
        "subdistrict_id": "5414",
        "district_id": "679",
        "subdistrict_name": "บ้านซ่าน"
    },
    {
        "subdistrict_id": "5415",
        "district_id": "679",
        "subdistrict_name": "วังใหญ่"
    },
    {
        "subdistrict_id": "5416",
        "district_id": "679",
        "subdistrict_name": "ราวต้นจันทร์"
    },
    {
        "subdistrict_id": "5417",
        "district_id": "680",
        "subdistrict_name": "เมืองสวรรคโลก"
    },
    {
        "subdistrict_id": "5418",
        "district_id": "680",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "5419",
        "district_id": "680",
        "subdistrict_name": "คลองกระจง"
    },
    {
        "subdistrict_id": "5420",
        "district_id": "680",
        "subdistrict_name": "วังพิณพาทย์"
    },
    {
        "subdistrict_id": "5421",
        "district_id": "680",
        "subdistrict_name": "วังไม้ขอน"
    },
    {
        "subdistrict_id": "5422",
        "district_id": "680",
        "subdistrict_name": "ย่านยาว"
    },
    {
        "subdistrict_id": "5423",
        "district_id": "680",
        "subdistrict_name": "นาทุ่ง"
    },
    {
        "subdistrict_id": "5424",
        "district_id": "680",
        "subdistrict_name": "คลองยาง"
    },
    {
        "subdistrict_id": "5425",
        "district_id": "680",
        "subdistrict_name": "เมืองบางยม"
    },
    {
        "subdistrict_id": "5426",
        "district_id": "680",
        "subdistrict_name": "ท่าทอง"
    },
    {
        "subdistrict_id": "5427",
        "district_id": "680",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "5428",
        "district_id": "680",
        "subdistrict_name": "ป่ากุมเกาะ"
    },
    {
        "subdistrict_id": "5429",
        "district_id": "680",
        "subdistrict_name": "เมืองบางขลัง"
    },
    {
        "subdistrict_id": "5430",
        "district_id": "680",
        "subdistrict_name": "หนองกลับ"
    },
    {
        "subdistrict_id": "5431",
        "district_id": "681",
        "subdistrict_name": "ศรีนคร"
    },
    {
        "subdistrict_id": "5432",
        "district_id": "681",
        "subdistrict_name": "นครเดิฐ"
    },
    {
        "subdistrict_id": "5433",
        "district_id": "681",
        "subdistrict_name": "น้ำขุม"
    },
    {
        "subdistrict_id": "5434",
        "district_id": "681",
        "subdistrict_name": "คลองมะพลับ"
    },
    {
        "subdistrict_id": "5435",
        "district_id": "681",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "5436",
        "district_id": "682",
        "subdistrict_name": "บ้านใหม่ไชยมงคล"
    },
    {
        "subdistrict_id": "5437",
        "district_id": "682",
        "subdistrict_name": "ไทยชนะศึก"
    },
    {
        "subdistrict_id": "5438",
        "district_id": "682",
        "subdistrict_name": "ทุ่งเสลี่ยม"
    },
    {
        "subdistrict_id": "5439",
        "district_id": "682",
        "subdistrict_name": "กลางดง"
    },
    {
        "subdistrict_id": "5440",
        "district_id": "682",
        "subdistrict_name": "เขาแก้วศรีสมบูรณ์"
    },
    {
        "subdistrict_id": "5441",
        "district_id": "683",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "5442",
        "district_id": "683",
        "subdistrict_name": "วังน้ำคู้"
    },
    {
        "subdistrict_id": "5443",
        "district_id": "683",
        "subdistrict_name": "วัดจันทร์"
    },
    {
        "subdistrict_id": "5444",
        "district_id": "683",
        "subdistrict_name": "วัดพริก"
    },
    {
        "subdistrict_id": "5445",
        "district_id": "683",
        "subdistrict_name": "ท่าทอง"
    },
    {
        "subdistrict_id": "5446",
        "district_id": "683",
        "subdistrict_name": "ท่าโพธิ์"
    },
    {
        "subdistrict_id": "5447",
        "district_id": "683",
        "subdistrict_name": "สมอแข"
    },
    {
        "subdistrict_id": "5448",
        "district_id": "683",
        "subdistrict_name": "ดอนทอง"
    },
    {
        "subdistrict_id": "5449",
        "district_id": "683",
        "subdistrict_name": "บ้านป่า"
    },
    {
        "subdistrict_id": "5450",
        "district_id": "683",
        "subdistrict_name": "ปากโทก"
    },
    {
        "subdistrict_id": "5451",
        "district_id": "683",
        "subdistrict_name": "หัวรอ"
    },
    {
        "subdistrict_id": "5452",
        "district_id": "683",
        "subdistrict_name": "จอมทอง"
    },
    {
        "subdistrict_id": "5453",
        "district_id": "683",
        "subdistrict_name": "บ้านกร่าง"
    },
    {
        "subdistrict_id": "5454",
        "district_id": "683",
        "subdistrict_name": "บ้านคลอง"
    },
    {
        "subdistrict_id": "5455",
        "district_id": "683",
        "subdistrict_name": "พลายชุมพล"
    },
    {
        "subdistrict_id": "5456",
        "district_id": "683",
        "subdistrict_name": "มะขามสูง"
    },
    {
        "subdistrict_id": "5457",
        "district_id": "683",
        "subdistrict_name": "อรัญญิก"
    },
    {
        "subdistrict_id": "5458",
        "district_id": "683",
        "subdistrict_name": "บึงพระ"
    },
    {
        "subdistrict_id": "5459",
        "district_id": "683",
        "subdistrict_name": "ไผ่ขอดอน"
    },
    {
        "subdistrict_id": "5460",
        "district_id": "683",
        "subdistrict_name": "งิ้วงาม"
    },
    {
        "subdistrict_id": "5461",
        "district_id": "684",
        "subdistrict_name": "นครไทย"
    },
    {
        "subdistrict_id": "5462",
        "district_id": "684",
        "subdistrict_name": "หนองกะท้าว"
    },
    {
        "subdistrict_id": "5463",
        "district_id": "684",
        "subdistrict_name": "บ้านแยง"
    },
    {
        "subdistrict_id": "5464",
        "district_id": "684",
        "subdistrict_name": "เนินเพิ่ม"
    },
    {
        "subdistrict_id": "5465",
        "district_id": "684",
        "subdistrict_name": "นาบัว"
    },
    {
        "subdistrict_id": "5466",
        "district_id": "684",
        "subdistrict_name": "นครชุม"
    },
    {
        "subdistrict_id": "5467",
        "district_id": "684",
        "subdistrict_name": "น้ำกุ่ม"
    },
    {
        "subdistrict_id": "5468",
        "district_id": "684",
        "subdistrict_name": "ยางโกลน"
    },
    {
        "subdistrict_id": "5469",
        "district_id": "684",
        "subdistrict_name": "บ่อโพธิ์"
    },
    {
        "subdistrict_id": "5470",
        "district_id": "684",
        "subdistrict_name": "บ้านพร้าว"
    },
    {
        "subdistrict_id": "5471",
        "district_id": "684",
        "subdistrict_name": "ห้วยเฮี้ย"
    },
    {
        "subdistrict_id": "5472",
        "district_id": "685",
        "subdistrict_name": "ป่าแดง"
    },
    {
        "subdistrict_id": "5473",
        "district_id": "685",
        "subdistrict_name": "ชาติตระการ"
    },
    {
        "subdistrict_id": "5474",
        "district_id": "685",
        "subdistrict_name": "สวนเมี่ยง"
    },
    {
        "subdistrict_id": "5475",
        "district_id": "685",
        "subdistrict_name": "บ้านดง"
    },
    {
        "subdistrict_id": "5476",
        "district_id": "685",
        "subdistrict_name": "บ่อภาค"
    },
    {
        "subdistrict_id": "5477",
        "district_id": "685",
        "subdistrict_name": "ท่าสะแก"
    },
    {
        "subdistrict_id": "5478",
        "district_id": "686",
        "subdistrict_name": "บางระกำ"
    },
    {
        "subdistrict_id": "5479",
        "district_id": "686",
        "subdistrict_name": "ปลักแรด"
    },
    {
        "subdistrict_id": "5480",
        "district_id": "686",
        "subdistrict_name": "พันเสา"
    },
    {
        "subdistrict_id": "5481",
        "district_id": "686",
        "subdistrict_name": "วังอิทก"
    },
    {
        "subdistrict_id": "5482",
        "district_id": "686",
        "subdistrict_name": "บึงกอก"
    },
    {
        "subdistrict_id": "5483",
        "district_id": "686",
        "subdistrict_name": "หนองกุลา"
    },
    {
        "subdistrict_id": "5484",
        "district_id": "686",
        "subdistrict_name": "ชุมแสงสงคราม"
    },
    {
        "subdistrict_id": "5485",
        "district_id": "686",
        "subdistrict_name": "นิคมพัฒนา"
    },
    {
        "subdistrict_id": "5486",
        "district_id": "686",
        "subdistrict_name": "บ่อทอง"
    },
    {
        "subdistrict_id": "5487",
        "district_id": "686",
        "subdistrict_name": "ท่านางงาม"
    },
    {
        "subdistrict_id": "5488",
        "district_id": "686",
        "subdistrict_name": "คุยม่วง"
    },
    {
        "subdistrict_id": "5489",
        "district_id": "687",
        "subdistrict_name": "บางกระทุ่ม"
    },
    {
        "subdistrict_id": "5490",
        "district_id": "687",
        "subdistrict_name": "บ้านไร่"
    },
    {
        "subdistrict_id": "5491",
        "district_id": "687",
        "subdistrict_name": "โคกสลุด"
    },
    {
        "subdistrict_id": "5492",
        "district_id": "687",
        "subdistrict_name": "สนามคลี"
    },
    {
        "subdistrict_id": "5493",
        "district_id": "687",
        "subdistrict_name": "ท่าตาล"
    },
    {
        "subdistrict_id": "5494",
        "district_id": "687",
        "subdistrict_name": "ไผ่ล้อม"
    },
    {
        "subdistrict_id": "5495",
        "district_id": "687",
        "subdistrict_name": "นครป่าหมาก"
    },
    {
        "subdistrict_id": "5496",
        "district_id": "687",
        "subdistrict_name": "เนินกุ่ม"
    },
    {
        "subdistrict_id": "5497",
        "district_id": "687",
        "subdistrict_name": "วัดตายม"
    },
    {
        "subdistrict_id": "5498",
        "district_id": "688",
        "subdistrict_name": "พรหมพิราม"
    },
    {
        "subdistrict_id": "5499",
        "district_id": "688",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "5500",
        "district_id": "688",
        "subdistrict_name": "วงฆ้อง"
    },
    {
        "subdistrict_id": "5501",
        "district_id": "688",
        "subdistrict_name": "มะตูม"
    },
    {
        "subdistrict_id": "5502",
        "district_id": "688",
        "subdistrict_name": "หอกลอง"
    },
    {
        "subdistrict_id": "5503",
        "district_id": "688",
        "subdistrict_name": "ศรีภิรมย์"
    },
    {
        "subdistrict_id": "5504",
        "district_id": "688",
        "subdistrict_name": "ตลุกเทียม"
    },
    {
        "subdistrict_id": "5505",
        "district_id": "688",
        "subdistrict_name": "วังวน"
    },
    {
        "subdistrict_id": "5506",
        "district_id": "688",
        "subdistrict_name": "หนองแขม"
    },
    {
        "subdistrict_id": "5507",
        "district_id": "688",
        "subdistrict_name": "มะต้อง"
    },
    {
        "subdistrict_id": "5508",
        "district_id": "688",
        "subdistrict_name": "ทับยายเชียง"
    },
    {
        "subdistrict_id": "5509",
        "district_id": "688",
        "subdistrict_name": "ดงประคำ"
    },
    {
        "subdistrict_id": "5510",
        "district_id": "689",
        "subdistrict_name": "วัดโบสถ์"
    },
    {
        "subdistrict_id": "5511",
        "district_id": "689",
        "subdistrict_name": "ท่างาม"
    },
    {
        "subdistrict_id": "5512",
        "district_id": "689",
        "subdistrict_name": "ท้อแท้"
    },
    {
        "subdistrict_id": "5513",
        "district_id": "689",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "5514",
        "district_id": "689",
        "subdistrict_name": "หินลาด"
    },
    {
        "subdistrict_id": "5515",
        "district_id": "689",
        "subdistrict_name": "คันโช้ง"
    },
    {
        "subdistrict_id": "5516",
        "district_id": "690",
        "subdistrict_name": "วังทอง"
    },
    {
        "subdistrict_id": "5517",
        "district_id": "690",
        "subdistrict_name": "พันชาลี"
    },
    {
        "subdistrict_id": "5518",
        "district_id": "690",
        "subdistrict_name": "แม่ระกา"
    },
    {
        "subdistrict_id": "5519",
        "district_id": "690",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "5520",
        "district_id": "690",
        "subdistrict_name": "วังพิกุล"
    },
    {
        "subdistrict_id": "5521",
        "district_id": "690",
        "subdistrict_name": "แก่งโสภา"
    },
    {
        "subdistrict_id": "5522",
        "district_id": "690",
        "subdistrict_name": "ท่าหมื่นราม"
    },
    {
        "subdistrict_id": "5523",
        "district_id": "690",
        "subdistrict_name": "วังนกแอ่น"
    },
    {
        "subdistrict_id": "5524",
        "district_id": "690",
        "subdistrict_name": "หนองพระ"
    },
    {
        "subdistrict_id": "5525",
        "district_id": "690",
        "subdistrict_name": "ชัยนาม"
    },
    {
        "subdistrict_id": "5526",
        "district_id": "690",
        "subdistrict_name": "ดินทอง"
    },
    {
        "subdistrict_id": "5527",
        "district_id": "691",
        "subdistrict_name": "ชมพู"
    },
    {
        "subdistrict_id": "5528",
        "district_id": "691",
        "subdistrict_name": "บ้านมุง"
    },
    {
        "subdistrict_id": "5529",
        "district_id": "691",
        "subdistrict_name": "ไทรย้อย"
    },
    {
        "subdistrict_id": "5530",
        "district_id": "691",
        "subdistrict_name": "วังโพรง"
    },
    {
        "subdistrict_id": "5531",
        "district_id": "691",
        "subdistrict_name": "บ้านน้อยซุ้มขี้เหล็ก"
    },
    {
        "subdistrict_id": "5532",
        "district_id": "691",
        "subdistrict_name": "เนินมะปราง"
    },
    {
        "subdistrict_id": "5533",
        "district_id": "691",
        "subdistrict_name": "วังยาง"
    },
    {
        "subdistrict_id": "5534",
        "district_id": "691",
        "subdistrict_name": "โคกแหลม"
    },
    {
        "subdistrict_id": "5535",
        "district_id": "692",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "5536",
        "district_id": "692",
        "subdistrict_name": "ไผ่ขวาง"
    },
    {
        "subdistrict_id": "5537",
        "district_id": "692",
        "subdistrict_name": "ย่านยาว"
    },
    {
        "subdistrict_id": "5538",
        "district_id": "692",
        "subdistrict_name": "ท่าฬ่อ"
    },
    {
        "subdistrict_id": "5539",
        "district_id": "692",
        "subdistrict_name": "ปากทาง"
    },
    {
        "subdistrict_id": "5540",
        "district_id": "692",
        "subdistrict_name": "คลองคะเชนทร์"
    },
    {
        "subdistrict_id": "5541",
        "district_id": "692",
        "subdistrict_name": "โรงช้าง"
    },
    {
        "subdistrict_id": "5542",
        "district_id": "692",
        "subdistrict_name": "เมืองเก่า"
    },
    {
        "subdistrict_id": "5543",
        "district_id": "692",
        "subdistrict_name": "ท่าหลวง"
    },
    {
        "subdistrict_id": "5544",
        "district_id": "692",
        "subdistrict_name": "บ้านบุ่ง"
    },
    {
        "subdistrict_id": "5545",
        "district_id": "692",
        "subdistrict_name": "ฆะมัง"
    },
    {
        "subdistrict_id": "5546",
        "district_id": "692",
        "subdistrict_name": "ดงป่าคำ"
    },
    {
        "subdistrict_id": "5547",
        "district_id": "692",
        "subdistrict_name": "หัวดง"
    },
    {
        "subdistrict_id": "5548",
        "district_id": "692",
        "subdistrict_name": "ป่ามะคาบ"
    },
    {
        "subdistrict_id": "5549",
        "district_id": "692",
        "subdistrict_name": "สายคำโห้"
    },
    {
        "subdistrict_id": "5550",
        "district_id": "692",
        "subdistrict_name": "ดงกลาง"
    },
    {
        "subdistrict_id": "5551",
        "district_id": "693",
        "subdistrict_name": "วังทรายพูน"
    },
    {
        "subdistrict_id": "5552",
        "district_id": "693",
        "subdistrict_name": "หนองปลาไหล"
    },
    {
        "subdistrict_id": "5553",
        "district_id": "693",
        "subdistrict_name": "หนองพระ"
    },
    {
        "subdistrict_id": "5554",
        "district_id": "693",
        "subdistrict_name": "หนองปล้อง"
    },
    {
        "subdistrict_id": "5555",
        "district_id": "694",
        "subdistrict_name": "โพธิ์ประทับช้าง"
    },
    {
        "subdistrict_id": "5556",
        "district_id": "694",
        "subdistrict_name": "ไผ่ท่าโพ"
    },
    {
        "subdistrict_id": "5557",
        "district_id": "694",
        "subdistrict_name": "วังจิก"
    },
    {
        "subdistrict_id": "5558",
        "district_id": "694",
        "subdistrict_name": "ไผ่รอบ"
    },
    {
        "subdistrict_id": "5559",
        "district_id": "694",
        "subdistrict_name": "ดงเสือเหลือง"
    },
    {
        "subdistrict_id": "5560",
        "district_id": "694",
        "subdistrict_name": "เนินสว่าง"
    },
    {
        "subdistrict_id": "5561",
        "district_id": "694",
        "subdistrict_name": "ทุ่งใหญ่"
    },
    {
        "subdistrict_id": "5562",
        "district_id": "695",
        "subdistrict_name": "ตะพานหิน"
    },
    {
        "subdistrict_id": "5563",
        "district_id": "695",
        "subdistrict_name": "งิ้วราย"
    },
    {
        "subdistrict_id": "5564",
        "district_id": "695",
        "subdistrict_name": "ห้วยเกตุ"
    },
    {
        "subdistrict_id": "5565",
        "district_id": "695",
        "subdistrict_name": "ไทรโรงโขน"
    },
    {
        "subdistrict_id": "5566",
        "district_id": "695",
        "subdistrict_name": "หนองพยอม"
    },
    {
        "subdistrict_id": "5567",
        "district_id": "695",
        "subdistrict_name": "ทุ่งโพธิ์"
    },
    {
        "subdistrict_id": "5568",
        "district_id": "695",
        "subdistrict_name": "ดงตะขบ"
    },
    {
        "subdistrict_id": "5569",
        "district_id": "695",
        "subdistrict_name": "คลองคูณ"
    },
    {
        "subdistrict_id": "5570",
        "district_id": "695",
        "subdistrict_name": "วังสำโรง"
    },
    {
        "subdistrict_id": "5571",
        "district_id": "695",
        "subdistrict_name": "วังหว้า"
    },
    {
        "subdistrict_id": "5572",
        "district_id": "695",
        "subdistrict_name": "วังหลุม"
    },
    {
        "subdistrict_id": "5573",
        "district_id": "695",
        "subdistrict_name": "ทับหมัน"
    },
    {
        "subdistrict_id": "5574",
        "district_id": "695",
        "subdistrict_name": "ไผ่หลวง"
    },
    {
        "subdistrict_id": "5575",
        "district_id": "696",
        "subdistrict_name": "บางมูลนาก"
    },
    {
        "subdistrict_id": "5576",
        "district_id": "696",
        "subdistrict_name": "บางไผ่"
    },
    {
        "subdistrict_id": "5577",
        "district_id": "696",
        "subdistrict_name": "หอไกร"
    },
    {
        "subdistrict_id": "5578",
        "district_id": "696",
        "subdistrict_name": "เนินมะกอก"
    },
    {
        "subdistrict_id": "5579",
        "district_id": "696",
        "subdistrict_name": "วังสำโรง"
    },
    {
        "subdistrict_id": "5580",
        "district_id": "696",
        "subdistrict_name": "ภูมิ"
    },
    {
        "subdistrict_id": "5581",
        "district_id": "696",
        "subdistrict_name": "วังกรด"
    },
    {
        "subdistrict_id": "5582",
        "district_id": "696",
        "subdistrict_name": "ห้วยเขน"
    },
    {
        "subdistrict_id": "5583",
        "district_id": "696",
        "subdistrict_name": "วังตะกู"
    },
    {
        "subdistrict_id": "5584",
        "district_id": "696",
        "subdistrict_name": "ลำประดา"
    },
    {
        "subdistrict_id": "5585",
        "district_id": "697",
        "subdistrict_name": "โพทะเล"
    },
    {
        "subdistrict_id": "5586",
        "district_id": "697",
        "subdistrict_name": "ท้ายน้ำ"
    },
    {
        "subdistrict_id": "5587",
        "district_id": "697",
        "subdistrict_name": "ทะนง"
    },
    {
        "subdistrict_id": "5588",
        "district_id": "697",
        "subdistrict_name": "ท่าบัว"
    },
    {
        "subdistrict_id": "5589",
        "district_id": "697",
        "subdistrict_name": "ทุ่งน้อย"
    },
    {
        "subdistrict_id": "5590",
        "district_id": "697",
        "subdistrict_name": "ท่าขมิ้น"
    },
    {
        "subdistrict_id": "5591",
        "district_id": "697",
        "subdistrict_name": "ท่าเสา"
    },
    {
        "subdistrict_id": "5592",
        "district_id": "697",
        "subdistrict_name": "บางคลาน"
    },
    {
        "subdistrict_id": "5593",
        "district_id": "697",
        "subdistrict_name": "ท่านั่ง"
    },
    {
        "subdistrict_id": "5594",
        "district_id": "697",
        "subdistrict_name": "บ้านน้อย"
    },
    {
        "subdistrict_id": "5595",
        "district_id": "697",
        "subdistrict_name": "วัดขวาง"
    },
    {
        "subdistrict_id": "5596",
        "district_id": "698",
        "subdistrict_name": "สามง่าม"
    },
    {
        "subdistrict_id": "5597",
        "district_id": "698",
        "subdistrict_name": "กำแพงดิน"
    },
    {
        "subdistrict_id": "5598",
        "district_id": "698",
        "subdistrict_name": "รังนก"
    },
    {
        "subdistrict_id": "5599",
        "district_id": "698",
        "subdistrict_name": "เนินปอ"
    },
    {
        "subdistrict_id": "5600",
        "district_id": "698",
        "subdistrict_name": "หนองโสน"
    },
    {
        "subdistrict_id": "5601",
        "district_id": "699",
        "subdistrict_name": "ทับคล้อ"
    },
    {
        "subdistrict_id": "5602",
        "district_id": "699",
        "subdistrict_name": "เขาทราย"
    },
    {
        "subdistrict_id": "5603",
        "district_id": "699",
        "subdistrict_name": "เขาเจ็ดลูก"
    },
    {
        "subdistrict_id": "5604",
        "district_id": "699",
        "subdistrict_name": "ท้ายทุ่ง"
    },
    {
        "subdistrict_id": "5605",
        "district_id": "700",
        "subdistrict_name": "สากเหล็ก"
    },
    {
        "subdistrict_id": "5606",
        "district_id": "700",
        "subdistrict_name": "ท่าเยี่ยม"
    },
    {
        "subdistrict_id": "5607",
        "district_id": "700",
        "subdistrict_name": "คลองทราย"
    },
    {
        "subdistrict_id": "5608",
        "district_id": "700",
        "subdistrict_name": "หนองหญ้าไทร"
    },
    {
        "subdistrict_id": "5609",
        "district_id": "700",
        "subdistrict_name": "วังทับไทร"
    },
    {
        "subdistrict_id": "5610",
        "district_id": "701",
        "subdistrict_name": "ห้วยแก้ว"
    },
    {
        "subdistrict_id": "5611",
        "district_id": "701",
        "subdistrict_name": "โพธิ์ไทรงาม"
    },
    {
        "subdistrict_id": "5612",
        "district_id": "701",
        "subdistrict_name": "แหลมรัง"
    },
    {
        "subdistrict_id": "5613",
        "district_id": "701",
        "subdistrict_name": "บางลาย"
    },
    {
        "subdistrict_id": "5614",
        "district_id": "701",
        "subdistrict_name": "บึงนาราง"
    },
    {
        "subdistrict_id": "5615",
        "district_id": "702",
        "subdistrict_name": "วังงิ้วใต้"
    },
    {
        "subdistrict_id": "5616",
        "district_id": "702",
        "subdistrict_name": "วังงิ้ว"
    },
    {
        "subdistrict_id": "5617",
        "district_id": "702",
        "subdistrict_name": "ห้วยร่วม"
    },
    {
        "subdistrict_id": "5618",
        "district_id": "702",
        "subdistrict_name": "ห้วยพุก"
    },
    {
        "subdistrict_id": "5619",
        "district_id": "702",
        "subdistrict_name": "สำนักขุนเณร"
    },
    {
        "subdistrict_id": "5620",
        "district_id": "703",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "5621",
        "district_id": "703",
        "subdistrict_name": "บึงบัว"
    },
    {
        "subdistrict_id": "5622",
        "district_id": "703",
        "subdistrict_name": "วังโมกข์"
    },
    {
        "subdistrict_id": "5623",
        "district_id": "703",
        "subdistrict_name": "หนองหลุม"
    },
    {
        "subdistrict_id": "5624",
        "district_id": "704",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "5625",
        "district_id": "704",
        "subdistrict_name": "ตะเบาะ"
    },
    {
        "subdistrict_id": "5626",
        "district_id": "704",
        "subdistrict_name": "บ้านโตก"
    },
    {
        "subdistrict_id": "5627",
        "district_id": "704",
        "subdistrict_name": "สะเดียง"
    },
    {
        "subdistrict_id": "5628",
        "district_id": "704",
        "subdistrict_name": "ป่าเลา"
    },
    {
        "subdistrict_id": "5629",
        "district_id": "704",
        "subdistrict_name": "นางั่ว"
    },
    {
        "subdistrict_id": "5630",
        "district_id": "704",
        "subdistrict_name": "ท่าพล"
    },
    {
        "subdistrict_id": "5631",
        "district_id": "704",
        "subdistrict_name": "ดงมูลเหล็ก"
    },
    {
        "subdistrict_id": "5632",
        "district_id": "704",
        "subdistrict_name": "บ้านโคก"
    },
    {
        "subdistrict_id": "5633",
        "district_id": "704",
        "subdistrict_name": "ชอนไพร"
    },
    {
        "subdistrict_id": "5634",
        "district_id": "704",
        "subdistrict_name": "นาป่า"
    },
    {
        "subdistrict_id": "5635",
        "district_id": "704",
        "subdistrict_name": "นายม"
    },
    {
        "subdistrict_id": "5636",
        "district_id": "704",
        "subdistrict_name": "วังชมภู"
    },
    {
        "subdistrict_id": "5637",
        "district_id": "704",
        "subdistrict_name": "น้ำร้อน"
    },
    {
        "subdistrict_id": "5638",
        "district_id": "704",
        "subdistrict_name": "ห้วยสะแก"
    },
    {
        "subdistrict_id": "5639",
        "district_id": "704",
        "subdistrict_name": "ห้วยใหญ่"
    },
    {
        "subdistrict_id": "5640",
        "district_id": "704",
        "subdistrict_name": "ระวิง"
    },
    {
        "subdistrict_id": "5641",
        "district_id": "705",
        "subdistrict_name": "ชนแดน"
    },
    {
        "subdistrict_id": "5642",
        "district_id": "705",
        "subdistrict_name": "ดงขุย"
    },
    {
        "subdistrict_id": "5643",
        "district_id": "705",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "5644",
        "district_id": "705",
        "subdistrict_name": "พุทธบาท"
    },
    {
        "subdistrict_id": "5645",
        "district_id": "705",
        "subdistrict_name": "ลาดแค"
    },
    {
        "subdistrict_id": "5646",
        "district_id": "705",
        "subdistrict_name": "บ้านกล้วย"
    },
    {
        "subdistrict_id": "5647",
        "district_id": "705",
        "subdistrict_name": "ซับพุทรา"
    },
    {
        "subdistrict_id": "5648",
        "district_id": "705",
        "subdistrict_name": "ตะกุดไร"
    },
    {
        "subdistrict_id": "5649",
        "district_id": "705",
        "subdistrict_name": "ศาลาลาย"
    },
    {
        "subdistrict_id": "5650",
        "district_id": "706",
        "subdistrict_name": "หล่มสัก"
    },
    {
        "subdistrict_id": "5651",
        "district_id": "706",
        "subdistrict_name": "วัดป่า"
    },
    {
        "subdistrict_id": "5652",
        "district_id": "706",
        "subdistrict_name": "ตาลเดี่ยว"
    },
    {
        "subdistrict_id": "5653",
        "district_id": "706",
        "subdistrict_name": "ฝายนาแซง"
    },
    {
        "subdistrict_id": "5654",
        "district_id": "706",
        "subdistrict_name": "หนองสว่าง"
    },
    {
        "subdistrict_id": "5655",
        "district_id": "706",
        "subdistrict_name": "น้ำเฮี้ย"
    },
    {
        "subdistrict_id": "5656",
        "district_id": "706",
        "subdistrict_name": "สักหลง"
    },
    {
        "subdistrict_id": "5657",
        "district_id": "706",
        "subdistrict_name": "ท่าอิบุญ"
    },
    {
        "subdistrict_id": "5658",
        "district_id": "706",
        "subdistrict_name": "บ้านโสก"
    },
    {
        "subdistrict_id": "5659",
        "district_id": "706",
        "subdistrict_name": "บ้านติ้ว"
    },
    {
        "subdistrict_id": "5660",
        "district_id": "706",
        "subdistrict_name": "ห้วยไร่"
    },
    {
        "subdistrict_id": "5661",
        "district_id": "706",
        "subdistrict_name": "น้ำก้อ"
    },
    {
        "subdistrict_id": "5662",
        "district_id": "706",
        "subdistrict_name": "ปากช่อง"
    },
    {
        "subdistrict_id": "5663",
        "district_id": "706",
        "subdistrict_name": "น้ำชุน"
    },
    {
        "subdistrict_id": "5664",
        "district_id": "706",
        "subdistrict_name": "หนองไขว่"
    },
    {
        "subdistrict_id": "5665",
        "district_id": "706",
        "subdistrict_name": "ลานบ่า"
    },
    {
        "subdistrict_id": "5666",
        "district_id": "706",
        "subdistrict_name": "บุ่งคล้า"
    },
    {
        "subdistrict_id": "5667",
        "district_id": "706",
        "subdistrict_name": "บุ่งน้ำเต้า"
    },
    {
        "subdistrict_id": "5668",
        "district_id": "706",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "5669",
        "district_id": "706",
        "subdistrict_name": "ช้างตะลูด"
    },
    {
        "subdistrict_id": "5670",
        "district_id": "706",
        "subdistrict_name": "บ้านไร่"
    },
    {
        "subdistrict_id": "5671",
        "district_id": "706",
        "subdistrict_name": "ปากดุก"
    },
    {
        "subdistrict_id": "5672",
        "district_id": "706",
        "subdistrict_name": "บ้านหวาย"
    },
    {
        "subdistrict_id": "5673",
        "district_id": "707",
        "subdistrict_name": "หล่มเก่า"
    },
    {
        "subdistrict_id": "5674",
        "district_id": "707",
        "subdistrict_name": "นาซำ"
    },
    {
        "subdistrict_id": "5675",
        "district_id": "707",
        "subdistrict_name": "หินฮาว"
    },
    {
        "subdistrict_id": "5676",
        "district_id": "707",
        "subdistrict_name": "บ้านเนิน"
    },
    {
        "subdistrict_id": "5677",
        "district_id": "707",
        "subdistrict_name": "ศิลา"
    },
    {
        "subdistrict_id": "5678",
        "district_id": "707",
        "subdistrict_name": "นาแซง"
    },
    {
        "subdistrict_id": "5679",
        "district_id": "707",
        "subdistrict_name": "วังบาล"
    },
    {
        "subdistrict_id": "5680",
        "district_id": "707",
        "subdistrict_name": "นาเกาะ"
    },
    {
        "subdistrict_id": "5681",
        "district_id": "707",
        "subdistrict_name": "ตาดกลอย"
    },
    {
        "subdistrict_id": "5682",
        "district_id": "708",
        "subdistrict_name": "ท่าโรง"
    },
    {
        "subdistrict_id": "5683",
        "district_id": "708",
        "subdistrict_name": "สระประดู่"
    },
    {
        "subdistrict_id": "5684",
        "district_id": "708",
        "subdistrict_name": "สามแยก"
    },
    {
        "subdistrict_id": "5685",
        "district_id": "708",
        "subdistrict_name": "โคกปรง"
    },
    {
        "subdistrict_id": "5686",
        "district_id": "708",
        "subdistrict_name": "น้ำร้อน"
    },
    {
        "subdistrict_id": "5687",
        "district_id": "708",
        "subdistrict_name": "บ่อรัง"
    },
    {
        "subdistrict_id": "5688",
        "district_id": "708",
        "subdistrict_name": "พุเตย"
    },
    {
        "subdistrict_id": "5689",
        "district_id": "708",
        "subdistrict_name": "พุขาม"
    },
    {
        "subdistrict_id": "5690",
        "district_id": "708",
        "subdistrict_name": "ภูน้ำหยด"
    },
    {
        "subdistrict_id": "5691",
        "district_id": "708",
        "subdistrict_name": "ซับสมบูรณ์"
    },
    {
        "subdistrict_id": "5692",
        "district_id": "708",
        "subdistrict_name": "บึงกระจับ"
    },
    {
        "subdistrict_id": "5693",
        "district_id": "708",
        "subdistrict_name": "วังใหญ่"
    },
    {
        "subdistrict_id": "5694",
        "district_id": "708",
        "subdistrict_name": "ยางสาว"
    },
    {
        "subdistrict_id": "5695",
        "district_id": "708",
        "subdistrict_name": "ซับน้อย"
    },
    {
        "subdistrict_id": "5696",
        "district_id": "709",
        "subdistrict_name": "ศรีเทพ"
    },
    {
        "subdistrict_id": "5697",
        "district_id": "709",
        "subdistrict_name": "สระกรวด"
    },
    {
        "subdistrict_id": "5698",
        "district_id": "709",
        "subdistrict_name": "คลองกระจัง"
    },
    {
        "subdistrict_id": "5699",
        "district_id": "709",
        "subdistrict_name": "นาสนุ่น"
    },
    {
        "subdistrict_id": "5700",
        "district_id": "709",
        "subdistrict_name": "โคกสะอาด"
    },
    {
        "subdistrict_id": "5701",
        "district_id": "709",
        "subdistrict_name": "หนองย่างทอย"
    },
    {
        "subdistrict_id": "5702",
        "district_id": "709",
        "subdistrict_name": "ประดู่งาม"
    },
    {
        "subdistrict_id": "5703",
        "district_id": "710",
        "subdistrict_name": "กองทูล"
    },
    {
        "subdistrict_id": "5704",
        "district_id": "710",
        "subdistrict_name": "นาเฉลียง"
    },
    {
        "subdistrict_id": "5705",
        "district_id": "710",
        "subdistrict_name": "บ้านโภชน์"
    },
    {
        "subdistrict_id": "5706",
        "district_id": "710",
        "subdistrict_name": "ท่าแดง"
    },
    {
        "subdistrict_id": "5707",
        "district_id": "710",
        "subdistrict_name": "เพชรละคร"
    },
    {
        "subdistrict_id": "5708",
        "district_id": "710",
        "subdistrict_name": "บ่อไทย"
    },
    {
        "subdistrict_id": "5709",
        "district_id": "710",
        "subdistrict_name": "ห้วยโป่ง"
    },
    {
        "subdistrict_id": "5710",
        "district_id": "710",
        "subdistrict_name": "วังท่าดี"
    },
    {
        "subdistrict_id": "5711",
        "district_id": "710",
        "subdistrict_name": "บัววัฒนา"
    },
    {
        "subdistrict_id": "5712",
        "district_id": "710",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "5713",
        "district_id": "710",
        "subdistrict_name": "วังโบสถ์"
    },
    {
        "subdistrict_id": "5714",
        "district_id": "710",
        "subdistrict_name": "ยางงาม"
    },
    {
        "subdistrict_id": "5715",
        "district_id": "710",
        "subdistrict_name": "ท่าด้วง"
    },
    {
        "subdistrict_id": "5716",
        "district_id": "711",
        "subdistrict_name": "ซับสมอทอด"
    },
    {
        "subdistrict_id": "5717",
        "district_id": "711",
        "subdistrict_name": "ซับไม้แดง"
    },
    {
        "subdistrict_id": "5718",
        "district_id": "711",
        "subdistrict_name": "หนองแจง"
    },
    {
        "subdistrict_id": "5719",
        "district_id": "711",
        "subdistrict_name": "กันจุ"
    },
    {
        "subdistrict_id": "5720",
        "district_id": "711",
        "subdistrict_name": "วังพิกุล"
    },
    {
        "subdistrict_id": "5721",
        "district_id": "711",
        "subdistrict_name": "พญาวัง"
    },
    {
        "subdistrict_id": "5722",
        "district_id": "711",
        "subdistrict_name": "ศรีมงคล"
    },
    {
        "subdistrict_id": "5723",
        "district_id": "711",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "5724",
        "district_id": "711",
        "subdistrict_name": "บึงสามพัน"
    },
    {
        "subdistrict_id": "5725",
        "district_id": "712",
        "subdistrict_name": "น้ำหนาว"
    },
    {
        "subdistrict_id": "5726",
        "district_id": "712",
        "subdistrict_name": "หลักด่าน"
    },
    {
        "subdistrict_id": "5727",
        "district_id": "712",
        "subdistrict_name": "วังกวาง"
    },
    {
        "subdistrict_id": "5728",
        "district_id": "712",
        "subdistrict_name": "โคกมน"
    },
    {
        "subdistrict_id": "5729",
        "district_id": "713",
        "subdistrict_name": "วังโป่ง"
    },
    {
        "subdistrict_id": "5730",
        "district_id": "713",
        "subdistrict_name": "ท้ายดง"
    },
    {
        "subdistrict_id": "5731",
        "district_id": "713",
        "subdistrict_name": "ซับเปิบ"
    },
    {
        "subdistrict_id": "5732",
        "district_id": "713",
        "subdistrict_name": "วังหิน"
    },
    {
        "subdistrict_id": "5733",
        "district_id": "713",
        "subdistrict_name": "วังศาล"
    },
    {
        "subdistrict_id": "5734",
        "district_id": "714",
        "subdistrict_name": "ทุ่งสมอ"
    },
    {
        "subdistrict_id": "5735",
        "district_id": "714",
        "subdistrict_name": "แคมป์สน"
    },
    {
        "subdistrict_id": "5736",
        "district_id": "714",
        "subdistrict_name": "เขาค้อ"
    },
    {
        "subdistrict_id": "5737",
        "district_id": "714",
        "subdistrict_name": "ริมสีม่วง"
    },
    {
        "subdistrict_id": "5738",
        "district_id": "714",
        "subdistrict_name": "สะเดาะพง"
    },
    {
        "subdistrict_id": "5739",
        "district_id": "714",
        "subdistrict_name": "หนองแม่นา"
    },
    {
        "subdistrict_id": "5740",
        "district_id": "714",
        "subdistrict_name": "เข็กน้อย"
    },
    {
        "subdistrict_id": "5741",
        "district_id": "715",
        "subdistrict_name": "หน้าเมือง"
    },
    {
        "subdistrict_id": "5742",
        "district_id": "715",
        "subdistrict_name": "เจดีย์หัก"
    },
    {
        "subdistrict_id": "5743",
        "district_id": "715",
        "subdistrict_name": "ดอนตะโก"
    },
    {
        "subdistrict_id": "5744",
        "district_id": "715",
        "subdistrict_name": "หนองกลางนา"
    },
    {
        "subdistrict_id": "5745",
        "district_id": "715",
        "subdistrict_name": "ห้วยไผ่"
    },
    {
        "subdistrict_id": "5746",
        "district_id": "715",
        "subdistrict_name": "คุ้งน้ำวน"
    },
    {
        "subdistrict_id": "5747",
        "district_id": "715",
        "subdistrict_name": "คุ้งกระถิน"
    },
    {
        "subdistrict_id": "5748",
        "district_id": "715",
        "subdistrict_name": "อ่างทอง"
    },
    {
        "subdistrict_id": "5749",
        "district_id": "715",
        "subdistrict_name": "โคกหม้อ"
    },
    {
        "subdistrict_id": "5750",
        "district_id": "715",
        "subdistrict_name": "สามเรือน"
    },
    {
        "subdistrict_id": "5751",
        "district_id": "715",
        "subdistrict_name": "พิกุลทอง"
    },
    {
        "subdistrict_id": "5752",
        "district_id": "715",
        "subdistrict_name": "น้ำพุ"
    },
    {
        "subdistrict_id": "5753",
        "district_id": "715",
        "subdistrict_name": "ดอนแร่"
    },
    {
        "subdistrict_id": "5754",
        "district_id": "715",
        "subdistrict_name": "หินกอง"
    },
    {
        "subdistrict_id": "5755",
        "district_id": "715",
        "subdistrict_name": "เขาแร้ง"
    },
    {
        "subdistrict_id": "5756",
        "district_id": "715",
        "subdistrict_name": "เกาะพลับพลา"
    },
    {
        "subdistrict_id": "5757",
        "district_id": "715",
        "subdistrict_name": "หลุมดิน"
    },
    {
        "subdistrict_id": "5758",
        "district_id": "715",
        "subdistrict_name": "บางป่า"
    },
    {
        "subdistrict_id": "5759",
        "district_id": "715",
        "subdistrict_name": "พงสวาย"
    },
    {
        "subdistrict_id": "5760",
        "district_id": "715",
        "subdistrict_name": "คูบัว"
    },
    {
        "subdistrict_id": "5761",
        "district_id": "715",
        "subdistrict_name": "ท่าราบ"
    },
    {
        "subdistrict_id": "5762",
        "district_id": "715",
        "subdistrict_name": "บ้านไร่"
    },
    {
        "subdistrict_id": "5763",
        "district_id": "716",
        "subdistrict_name": "จอมบึง"
    },
    {
        "subdistrict_id": "5764",
        "district_id": "716",
        "subdistrict_name": "ปากช่อง"
    },
    {
        "subdistrict_id": "5765",
        "district_id": "716",
        "subdistrict_name": "เบิกไพร"
    },
    {
        "subdistrict_id": "5766",
        "district_id": "716",
        "subdistrict_name": "ด่านทับตะโก"
    },
    {
        "subdistrict_id": "5767",
        "district_id": "716",
        "subdistrict_name": "แก้มอ้น"
    },
    {
        "subdistrict_id": "5768",
        "district_id": "716",
        "subdistrict_name": "รางบัว"
    },
    {
        "subdistrict_id": "5769",
        "district_id": "717",
        "subdistrict_name": "สวนผึ้ง"
    },
    {
        "subdistrict_id": "5770",
        "district_id": "717",
        "subdistrict_name": "ป่าหวาย"
    },
    {
        "subdistrict_id": "5771",
        "district_id": "717",
        "subdistrict_name": "ท่าเคย"
    },
    {
        "subdistrict_id": "5772",
        "district_id": "717",
        "subdistrict_name": "ตะนาวศรี"
    },
    {
        "subdistrict_id": "5773",
        "district_id": "718",
        "subdistrict_name": "ดำเนินสะดวก"
    },
    {
        "subdistrict_id": "5774",
        "district_id": "718",
        "subdistrict_name": "ประสาทสิทธิ์"
    },
    {
        "subdistrict_id": "5775",
        "district_id": "718",
        "subdistrict_name": "ศรีสุราษฎร์"
    },
    {
        "subdistrict_id": "5776",
        "district_id": "718",
        "subdistrict_name": "ตาหลวง"
    },
    {
        "subdistrict_id": "5777",
        "district_id": "718",
        "subdistrict_name": "ดอนกรวย"
    },
    {
        "subdistrict_id": "5778",
        "district_id": "718",
        "subdistrict_name": "ดอนคลัง"
    },
    {
        "subdistrict_id": "5779",
        "district_id": "718",
        "subdistrict_name": "บัวงาม"
    },
    {
        "subdistrict_id": "5780",
        "district_id": "718",
        "subdistrict_name": "บ้านไร่"
    },
    {
        "subdistrict_id": "5781",
        "district_id": "718",
        "subdistrict_name": "แพงพวย"
    },
    {
        "subdistrict_id": "5782",
        "district_id": "718",
        "subdistrict_name": "สี่หมื่น"
    },
    {
        "subdistrict_id": "5783",
        "district_id": "718",
        "subdistrict_name": "ท่านัด"
    },
    {
        "subdistrict_id": "5784",
        "district_id": "718",
        "subdistrict_name": "ขุนพิทักษ์"
    },
    {
        "subdistrict_id": "5785",
        "district_id": "718",
        "subdistrict_name": "ดอนไผ่"
    },
    {
        "subdistrict_id": "5786",
        "district_id": "719",
        "subdistrict_name": "บ้านโป่ง"
    },
    {
        "subdistrict_id": "5787",
        "district_id": "719",
        "subdistrict_name": "ท่าผา"
    },
    {
        "subdistrict_id": "5788",
        "district_id": "719",
        "subdistrict_name": "กรับใหญ่"
    },
    {
        "subdistrict_id": "5789",
        "district_id": "719",
        "subdistrict_name": "ปากแรต"
    },
    {
        "subdistrict_id": "5790",
        "district_id": "719",
        "subdistrict_name": "หนองกบ"
    },
    {
        "subdistrict_id": "5791",
        "district_id": "719",
        "subdistrict_name": "หนองอ้อ"
    },
    {
        "subdistrict_id": "5792",
        "district_id": "719",
        "subdistrict_name": "ดอนกระเบื้อง"
    },
    {
        "subdistrict_id": "5793",
        "district_id": "719",
        "subdistrict_name": "สวนกล้วย"
    },
    {
        "subdistrict_id": "5794",
        "district_id": "719",
        "subdistrict_name": "นครชุมน์"
    },
    {
        "subdistrict_id": "5795",
        "district_id": "719",
        "subdistrict_name": "บ้านม่วง"
    },
    {
        "subdistrict_id": "5796",
        "district_id": "719",
        "subdistrict_name": "คุ้งพยอม"
    },
    {
        "subdistrict_id": "5797",
        "district_id": "719",
        "subdistrict_name": "หนองปลาหมอ"
    },
    {
        "subdistrict_id": "5798",
        "district_id": "719",
        "subdistrict_name": "เขาขลุง"
    },
    {
        "subdistrict_id": "5799",
        "district_id": "719",
        "subdistrict_name": "เบิกไพร"
    },
    {
        "subdistrict_id": "5800",
        "district_id": "719",
        "subdistrict_name": "ลาดบัวขาว"
    },
    {
        "subdistrict_id": "5801",
        "district_id": "720",
        "subdistrict_name": "บางแพ"
    },
    {
        "subdistrict_id": "5802",
        "district_id": "720",
        "subdistrict_name": "วังเย็น"
    },
    {
        "subdistrict_id": "5803",
        "district_id": "720",
        "subdistrict_name": "หัวโพ"
    },
    {
        "subdistrict_id": "5804",
        "district_id": "720",
        "subdistrict_name": "วัดแก้ว"
    },
    {
        "subdistrict_id": "5805",
        "district_id": "720",
        "subdistrict_name": "ดอนใหญ่"
    },
    {
        "subdistrict_id": "5806",
        "district_id": "720",
        "subdistrict_name": "ดอนคา"
    },
    {
        "subdistrict_id": "5807",
        "district_id": "720",
        "subdistrict_name": "โพหัก"
    },
    {
        "subdistrict_id": "5808",
        "district_id": "721",
        "subdistrict_name": "โพธาราม"
    },
    {
        "subdistrict_id": "5809",
        "district_id": "721",
        "subdistrict_name": "ดอนกระเบื้อง"
    },
    {
        "subdistrict_id": "5810",
        "district_id": "721",
        "subdistrict_name": "หนองโพ"
    },
    {
        "subdistrict_id": "5811",
        "district_id": "721",
        "subdistrict_name": "บ้านเลือก"
    },
    {
        "subdistrict_id": "5812",
        "district_id": "721",
        "subdistrict_name": "คลองตาคต"
    },
    {
        "subdistrict_id": "5813",
        "district_id": "721",
        "subdistrict_name": "บ้านฆ้อง"
    },
    {
        "subdistrict_id": "5814",
        "district_id": "721",
        "subdistrict_name": "บ้านสิงห์"
    },
    {
        "subdistrict_id": "5815",
        "district_id": "721",
        "subdistrict_name": "ดอนทราย"
    },
    {
        "subdistrict_id": "5816",
        "district_id": "721",
        "subdistrict_name": "เจ็ดเสมียน"
    },
    {
        "subdistrict_id": "5817",
        "district_id": "721",
        "subdistrict_name": "คลองข่อย"
    },
    {
        "subdistrict_id": "5818",
        "district_id": "721",
        "subdistrict_name": "ชำแระ"
    },
    {
        "subdistrict_id": "5819",
        "district_id": "721",
        "subdistrict_name": "สร้อยฟ้า"
    },
    {
        "subdistrict_id": "5820",
        "district_id": "721",
        "subdistrict_name": "ท่าชุมพล"
    },
    {
        "subdistrict_id": "5821",
        "district_id": "721",
        "subdistrict_name": "บางโตนด"
    },
    {
        "subdistrict_id": "5822",
        "district_id": "721",
        "subdistrict_name": "เตาปูน"
    },
    {
        "subdistrict_id": "5823",
        "district_id": "721",
        "subdistrict_name": "นางแก้ว"
    },
    {
        "subdistrict_id": "5824",
        "district_id": "721",
        "subdistrict_name": "ธรรมเสน"
    },
    {
        "subdistrict_id": "5825",
        "district_id": "721",
        "subdistrict_name": "เขาชะงุ้ม"
    },
    {
        "subdistrict_id": "5826",
        "district_id": "721",
        "subdistrict_name": "หนองกวาง"
    },
    {
        "subdistrict_id": "5827",
        "district_id": "722",
        "subdistrict_name": "ทุ่งหลวง"
    },
    {
        "subdistrict_id": "5828",
        "district_id": "722",
        "subdistrict_name": "วังมะนาว"
    },
    {
        "subdistrict_id": "5829",
        "district_id": "722",
        "subdistrict_name": "ดอนทราย"
    },
    {
        "subdistrict_id": "5830",
        "district_id": "722",
        "subdistrict_name": "หนองกระทุ่ม"
    },
    {
        "subdistrict_id": "5831",
        "district_id": "722",
        "subdistrict_name": "ปากท่อ"
    },
    {
        "subdistrict_id": "5832",
        "district_id": "722",
        "subdistrict_name": "ป่าไก่"
    },
    {
        "subdistrict_id": "5833",
        "district_id": "722",
        "subdistrict_name": "วัดยางงาม"
    },
    {
        "subdistrict_id": "5834",
        "district_id": "722",
        "subdistrict_name": "อ่างหิน"
    },
    {
        "subdistrict_id": "5835",
        "district_id": "722",
        "subdistrict_name": "บ่อกระดาน"
    },
    {
        "subdistrict_id": "5836",
        "district_id": "722",
        "subdistrict_name": "ยางหัก"
    },
    {
        "subdistrict_id": "5837",
        "district_id": "722",
        "subdistrict_name": "วันดาว"
    },
    {
        "subdistrict_id": "5838",
        "district_id": "722",
        "subdistrict_name": "ห้วยยางโทน"
    },
    {
        "subdistrict_id": "5839",
        "district_id": "723",
        "subdistrict_name": "เกาะศาลพระ"
    },
    {
        "subdistrict_id": "5840",
        "district_id": "723",
        "subdistrict_name": "จอมประทัด"
    },
    {
        "subdistrict_id": "5841",
        "district_id": "723",
        "subdistrict_name": "วัดเพลง"
    },
    {
        "subdistrict_id": "5842",
        "district_id": "724",
        "subdistrict_name": "บ้านคา"
    },
    {
        "subdistrict_id": "5843",
        "district_id": "724",
        "subdistrict_name": "บ้านบึง"
    },
    {
        "subdistrict_id": "5844",
        "district_id": "724",
        "subdistrict_name": "หนองพันจันทร์"
    },
    {
        "subdistrict_id": "5845",
        "district_id": "726",
        "subdistrict_name": "บ้านเหนือ"
    },
    {
        "subdistrict_id": "5846",
        "district_id": "726",
        "subdistrict_name": "บ้านใต้"
    },
    {
        "subdistrict_id": "5847",
        "district_id": "726",
        "subdistrict_name": "ปากแพรก"
    },
    {
        "subdistrict_id": "5848",
        "district_id": "726",
        "subdistrict_name": "ท่ามะขาม"
    },
    {
        "subdistrict_id": "5849",
        "district_id": "726",
        "subdistrict_name": "แก่งเสี้ยน"
    },
    {
        "subdistrict_id": "5850",
        "district_id": "726",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "5851",
        "district_id": "726",
        "subdistrict_name": "ลาดหญ้า"
    },
    {
        "subdistrict_id": "5852",
        "district_id": "726",
        "subdistrict_name": "วังด้ง"
    },
    {
        "subdistrict_id": "5853",
        "district_id": "726",
        "subdistrict_name": "ช่องสะเดา"
    },
    {
        "subdistrict_id": "5854",
        "district_id": "726",
        "subdistrict_name": "หนองหญ้า"
    },
    {
        "subdistrict_id": "5855",
        "district_id": "726",
        "subdistrict_name": "เกาะสำโรง"
    },
    {
        "subdistrict_id": "5856",
        "district_id": "726",
        "subdistrict_name": "บ้านเก่า"
    },
    {
        "subdistrict_id": "5857",
        "district_id": "726",
        "subdistrict_name": "วังเย็น"
    },
    {
        "subdistrict_id": "5858",
        "district_id": "727",
        "subdistrict_name": "ลุ่มสุ่ม"
    },
    {
        "subdistrict_id": "5859",
        "district_id": "727",
        "subdistrict_name": "ท่าเสา"
    },
    {
        "subdistrict_id": "5860",
        "district_id": "727",
        "subdistrict_name": "สิงห์"
    },
    {
        "subdistrict_id": "5861",
        "district_id": "727",
        "subdistrict_name": "ไทรโยค"
    },
    {
        "subdistrict_id": "5862",
        "district_id": "727",
        "subdistrict_name": "วังกระแจะ"
    },
    {
        "subdistrict_id": "5863",
        "district_id": "727",
        "subdistrict_name": "ศรีมงคล"
    },
    {
        "subdistrict_id": "5864",
        "district_id": "727",
        "subdistrict_name": "บ้องตี้"
    },
    {
        "subdistrict_id": "5865",
        "district_id": "728",
        "subdistrict_name": "บ่อพลอย"
    },
    {
        "subdistrict_id": "5866",
        "district_id": "728",
        "subdistrict_name": "หนองกุ่ม"
    },
    {
        "subdistrict_id": "5867",
        "district_id": "728",
        "subdistrict_name": "หนองรี"
    },
    {
        "subdistrict_id": "5868",
        "district_id": "728",
        "subdistrict_name": "หลุมรัง"
    },
    {
        "subdistrict_id": "5869",
        "district_id": "728",
        "subdistrict_name": "ช่องด่าน"
    },
    {
        "subdistrict_id": "5870",
        "district_id": "728",
        "subdistrict_name": "หนองกร่าง"
    },
    {
        "subdistrict_id": "5871",
        "district_id": "729",
        "subdistrict_name": "นาสวน"
    },
    {
        "subdistrict_id": "5872",
        "district_id": "729",
        "subdistrict_name": "ด่านแม่แฉลบ"
    },
    {
        "subdistrict_id": "5873",
        "district_id": "729",
        "subdistrict_name": "หนองเป็ด"
    },
    {
        "subdistrict_id": "5874",
        "district_id": "729",
        "subdistrict_name": "ท่ากระดาน"
    },
    {
        "subdistrict_id": "5875",
        "district_id": "729",
        "subdistrict_name": "เขาโจด"
    },
    {
        "subdistrict_id": "5876",
        "district_id": "729",
        "subdistrict_name": "แม่กระบุง"
    },
    {
        "subdistrict_id": "5877",
        "district_id": "730",
        "subdistrict_name": "พงตึก"
    },
    {
        "subdistrict_id": "5878",
        "district_id": "730",
        "subdistrict_name": "ยางม่วง"
    },
    {
        "subdistrict_id": "5879",
        "district_id": "730",
        "subdistrict_name": "ดอนชะเอม"
    },
    {
        "subdistrict_id": "5880",
        "district_id": "730",
        "subdistrict_name": "ท่าไม้"
    },
    {
        "subdistrict_id": "5881",
        "district_id": "730",
        "subdistrict_name": "ตะคร้ำเอน"
    },
    {
        "subdistrict_id": "5882",
        "district_id": "730",
        "subdistrict_name": "ท่ามะกา"
    },
    {
        "subdistrict_id": "5883",
        "district_id": "730",
        "subdistrict_name": "ท่าเรือ"
    },
    {
        "subdistrict_id": "5884",
        "district_id": "730",
        "subdistrict_name": "โคกตะบอง"
    },
    {
        "subdistrict_id": "5885",
        "district_id": "730",
        "subdistrict_name": "ดอนขมิ้น"
    },
    {
        "subdistrict_id": "5886",
        "district_id": "730",
        "subdistrict_name": "อุโลกสี่หมื่น"
    },
    {
        "subdistrict_id": "5887",
        "district_id": "730",
        "subdistrict_name": "เขาสามสิบหาบ"
    },
    {
        "subdistrict_id": "5888",
        "district_id": "730",
        "subdistrict_name": "พระแท่น"
    },
    {
        "subdistrict_id": "5889",
        "district_id": "730",
        "subdistrict_name": "หวายเหนียว"
    },
    {
        "subdistrict_id": "5890",
        "district_id": "730",
        "subdistrict_name": "แสนตอ"
    },
    {
        "subdistrict_id": "5891",
        "district_id": "730",
        "subdistrict_name": "สนามแย้"
    },
    {
        "subdistrict_id": "5892",
        "district_id": "730",
        "subdistrict_name": "ท่าเสา"
    },
    {
        "subdistrict_id": "5893",
        "district_id": "730",
        "subdistrict_name": "หนองลาน"
    },
    {
        "subdistrict_id": "5894",
        "district_id": "731",
        "subdistrict_name": "ท่าม่วง"
    },
    {
        "subdistrict_id": "5895",
        "district_id": "731",
        "subdistrict_name": "วังขนาย"
    },
    {
        "subdistrict_id": "5896",
        "district_id": "731",
        "subdistrict_name": "วังศาลา"
    },
    {
        "subdistrict_id": "5897",
        "district_id": "731",
        "subdistrict_name": "ท่าล้อ"
    },
    {
        "subdistrict_id": "5898",
        "district_id": "731",
        "subdistrict_name": "หนองขาว"
    },
    {
        "subdistrict_id": "5899",
        "district_id": "731",
        "subdistrict_name": "ทุ่งทอง"
    },
    {
        "subdistrict_id": "5900",
        "district_id": "731",
        "subdistrict_name": "เขาน้อย"
    },
    {
        "subdistrict_id": "5901",
        "district_id": "731",
        "subdistrict_name": "ม่วงชุม"
    },
    {
        "subdistrict_id": "5902",
        "district_id": "731",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "5903",
        "district_id": "731",
        "subdistrict_name": "พังตรุ"
    },
    {
        "subdistrict_id": "5904",
        "district_id": "731",
        "subdistrict_name": "ท่าตะคร้อ"
    },
    {
        "subdistrict_id": "5905",
        "district_id": "731",
        "subdistrict_name": "รางสาลี่"
    },
    {
        "subdistrict_id": "5906",
        "district_id": "731",
        "subdistrict_name": "หนองตากยา"
    },
    {
        "subdistrict_id": "5907",
        "district_id": "732",
        "subdistrict_name": "ท่าขนุน"
    },
    {
        "subdistrict_id": "5908",
        "district_id": "732",
        "subdistrict_name": "ปิล๊อก"
    },
    {
        "subdistrict_id": "5909",
        "district_id": "732",
        "subdistrict_name": "หินดาด"
    },
    {
        "subdistrict_id": "5910",
        "district_id": "732",
        "subdistrict_name": "ลิ่นถิ่น"
    },
    {
        "subdistrict_id": "5911",
        "district_id": "732",
        "subdistrict_name": "ชะแล"
    },
    {
        "subdistrict_id": "5912",
        "district_id": "732",
        "subdistrict_name": "ห้วยเขย่ง"
    },
    {
        "subdistrict_id": "5913",
        "district_id": "732",
        "subdistrict_name": "สหกรณ์นิคม"
    },
    {
        "subdistrict_id": "5914",
        "district_id": "733",
        "subdistrict_name": "หนองลู"
    },
    {
        "subdistrict_id": "5915",
        "district_id": "733",
        "subdistrict_name": "ปรังเผล"
    },
    {
        "subdistrict_id": "5916",
        "district_id": "733",
        "subdistrict_name": "ไล่โว่"
    },
    {
        "subdistrict_id": "5917",
        "district_id": "734",
        "subdistrict_name": "พนมทวน"
    },
    {
        "subdistrict_id": "5918",
        "district_id": "734",
        "subdistrict_name": "หนองโรง"
    },
    {
        "subdistrict_id": "5919",
        "district_id": "734",
        "subdistrict_name": "ทุ่งสมอ"
    },
    {
        "subdistrict_id": "5920",
        "district_id": "734",
        "subdistrict_name": "ดอนเจดีย์"
    },
    {
        "subdistrict_id": "5921",
        "district_id": "734",
        "subdistrict_name": "พังตรุ"
    },
    {
        "subdistrict_id": "5922",
        "district_id": "734",
        "subdistrict_name": "รางหวาย"
    },
    {
        "subdistrict_id": "5923",
        "district_id": "734",
        "subdistrict_name": "หนองสาหร่าย"
    },
    {
        "subdistrict_id": "5924",
        "district_id": "734",
        "subdistrict_name": "ดอนตาเพชร"
    },
    {
        "subdistrict_id": "5925",
        "district_id": "735",
        "subdistrict_name": "เลาขวัญ"
    },
    {
        "subdistrict_id": "5926",
        "district_id": "735",
        "subdistrict_name": "หนองโสน"
    },
    {
        "subdistrict_id": "5927",
        "district_id": "735",
        "subdistrict_name": "หนองประดู่"
    },
    {
        "subdistrict_id": "5928",
        "district_id": "735",
        "subdistrict_name": "หนองปลิง"
    },
    {
        "subdistrict_id": "5929",
        "district_id": "735",
        "subdistrict_name": "หนองนกแก้ว"
    },
    {
        "subdistrict_id": "5930",
        "district_id": "735",
        "subdistrict_name": "ทุ่งกระบ่ำ"
    },
    {
        "subdistrict_id": "5931",
        "district_id": "735",
        "subdistrict_name": "หนองฝ้าย"
    },
    {
        "subdistrict_id": "5932",
        "district_id": "736",
        "subdistrict_name": "ด่านมะขามเตี้ย"
    },
    {
        "subdistrict_id": "5933",
        "district_id": "736",
        "subdistrict_name": "กลอนโด"
    },
    {
        "subdistrict_id": "5934",
        "district_id": "736",
        "subdistrict_name": "จรเข้เผือก"
    },
    {
        "subdistrict_id": "5935",
        "district_id": "736",
        "subdistrict_name": "หนองไผ่"
    },
    {
        "subdistrict_id": "5936",
        "district_id": "737",
        "subdistrict_name": "หนองปรือ"
    },
    {
        "subdistrict_id": "5937",
        "district_id": "737",
        "subdistrict_name": "หนองปลาไหล"
    },
    {
        "subdistrict_id": "5938",
        "district_id": "737",
        "subdistrict_name": "สมเด็จเจริญ"
    },
    {
        "subdistrict_id": "5939",
        "district_id": "738",
        "subdistrict_name": "ห้วยกระเจา"
    },
    {
        "subdistrict_id": "5940",
        "district_id": "738",
        "subdistrict_name": "วังไผ่"
    },
    {
        "subdistrict_id": "5941",
        "district_id": "738",
        "subdistrict_name": "ดอนแสลบ"
    },
    {
        "subdistrict_id": "5942",
        "district_id": "738",
        "subdistrict_name": "สระลงเรือ"
    },
    {
        "subdistrict_id": "5943",
        "district_id": "739",
        "subdistrict_name": "ท่าพี่เลี้ยง"
    },
    {
        "subdistrict_id": "5944",
        "district_id": "739",
        "subdistrict_name": "รั้วใหญ่"
    },
    {
        "subdistrict_id": "5945",
        "district_id": "739",
        "subdistrict_name": "ทับตีเหล็ก"
    },
    {
        "subdistrict_id": "5946",
        "district_id": "739",
        "subdistrict_name": "ท่าระหัด"
    },
    {
        "subdistrict_id": "5947",
        "district_id": "739",
        "subdistrict_name": "ไผ่ขวาง"
    },
    {
        "subdistrict_id": "5948",
        "district_id": "739",
        "subdistrict_name": "โคกโคเฒ่า"
    },
    {
        "subdistrict_id": "5949",
        "district_id": "739",
        "subdistrict_name": "ดอนตาล"
    },
    {
        "subdistrict_id": "5950",
        "district_id": "739",
        "subdistrict_name": "ดอนมะสังข์"
    },
    {
        "subdistrict_id": "5951",
        "district_id": "739",
        "subdistrict_name": "พิหารแดง"
    },
    {
        "subdistrict_id": "5952",
        "district_id": "739",
        "subdistrict_name": "ดอนกำยาน"
    },
    {
        "subdistrict_id": "5953",
        "district_id": "739",
        "subdistrict_name": "ดอนโพธิ์ทอง"
    },
    {
        "subdistrict_id": "5954",
        "district_id": "739",
        "subdistrict_name": "บ้านโพธิ์"
    },
    {
        "subdistrict_id": "5955",
        "district_id": "739",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "5956",
        "district_id": "739",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "5957",
        "district_id": "739",
        "subdistrict_name": "บางกุ้ง"
    },
    {
        "subdistrict_id": "5958",
        "district_id": "739",
        "subdistrict_name": "ศาลาขาว"
    },
    {
        "subdistrict_id": "5959",
        "district_id": "739",
        "subdistrict_name": "สวนแตง"
    },
    {
        "subdistrict_id": "5960",
        "district_id": "739",
        "subdistrict_name": "สนามชัย"
    },
    {
        "subdistrict_id": "5961",
        "district_id": "739",
        "subdistrict_name": "โพธิ์พระยา"
    },
    {
        "subdistrict_id": "5962",
        "district_id": "739",
        "subdistrict_name": "สนามคลี"
    },
    {
        "subdistrict_id": "5963",
        "district_id": "740",
        "subdistrict_name": "เขาพระ"
    },
    {
        "subdistrict_id": "5964",
        "district_id": "740",
        "subdistrict_name": "เดิมบาง"
    },
    {
        "subdistrict_id": "5965",
        "district_id": "740",
        "subdistrict_name": "นางบวช"
    },
    {
        "subdistrict_id": "5966",
        "district_id": "740",
        "subdistrict_name": "เขาดิน"
    },
    {
        "subdistrict_id": "5967",
        "district_id": "740",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "5968",
        "district_id": "740",
        "subdistrict_name": "ทุ่งคลี"
    },
    {
        "subdistrict_id": "5969",
        "district_id": "740",
        "subdistrict_name": "โคกช้าง"
    },
    {
        "subdistrict_id": "5970",
        "district_id": "740",
        "subdistrict_name": "หัวเขา"
    },
    {
        "subdistrict_id": "5971",
        "district_id": "740",
        "subdistrict_name": "หัวนา"
    },
    {
        "subdistrict_id": "5972",
        "district_id": "740",
        "subdistrict_name": "บ่อกรุ"
    },
    {
        "subdistrict_id": "5973",
        "district_id": "740",
        "subdistrict_name": "วังศรีราช"
    },
    {
        "subdistrict_id": "5974",
        "district_id": "740",
        "subdistrict_name": "ป่าสะแก"
    },
    {
        "subdistrict_id": "5975",
        "district_id": "740",
        "subdistrict_name": "ยางนอน"
    },
    {
        "subdistrict_id": "5976",
        "district_id": "740",
        "subdistrict_name": "หนองกระทุ่ม"
    },
    {
        "subdistrict_id": "5977",
        "district_id": "741",
        "subdistrict_name": "หนองมะค่าโมง"
    },
    {
        "subdistrict_id": "5978",
        "district_id": "741",
        "subdistrict_name": "ด่านช้าง"
    },
    {
        "subdistrict_id": "5979",
        "district_id": "741",
        "subdistrict_name": "ห้วยขมิ้น"
    },
    {
        "subdistrict_id": "5980",
        "district_id": "741",
        "subdistrict_name": "องค์พระ"
    },
    {
        "subdistrict_id": "5981",
        "district_id": "741",
        "subdistrict_name": "วังคัน"
    },
    {
        "subdistrict_id": "5982",
        "district_id": "741",
        "subdistrict_name": "นิคมกระเสียว"
    },
    {
        "subdistrict_id": "5983",
        "district_id": "741",
        "subdistrict_name": "วังยาว"
    },
    {
        "subdistrict_id": "5984",
        "district_id": "742",
        "subdistrict_name": "โคกคราม"
    },
    {
        "subdistrict_id": "5985",
        "district_id": "742",
        "subdistrict_name": "บางปลาม้า"
    },
    {
        "subdistrict_id": "5986",
        "district_id": "742",
        "subdistrict_name": "ตะค่า"
    },
    {
        "subdistrict_id": "5987",
        "district_id": "742",
        "subdistrict_name": "บางใหญ่"
    },
    {
        "subdistrict_id": "5988",
        "district_id": "742",
        "subdistrict_name": "กฤษณา"
    },
    {
        "subdistrict_id": "5989",
        "district_id": "742",
        "subdistrict_name": "สาลี"
    },
    {
        "subdistrict_id": "5990",
        "district_id": "742",
        "subdistrict_name": "ไผ่กองดิน"
    },
    {
        "subdistrict_id": "5991",
        "district_id": "742",
        "subdistrict_name": "องครักษ์"
    },
    {
        "subdistrict_id": "5992",
        "district_id": "742",
        "subdistrict_name": "จรเข้ใหญ่"
    },
    {
        "subdistrict_id": "5993",
        "district_id": "742",
        "subdistrict_name": "บ้านแหลม"
    },
    {
        "subdistrict_id": "5994",
        "district_id": "742",
        "subdistrict_name": "มะขามล้ม"
    },
    {
        "subdistrict_id": "5995",
        "district_id": "742",
        "subdistrict_name": "วังน้ำเย็น"
    },
    {
        "subdistrict_id": "5996",
        "district_id": "742",
        "subdistrict_name": "วัดโบสถ์"
    },
    {
        "subdistrict_id": "5997",
        "district_id": "742",
        "subdistrict_name": "วัดดาว"
    },
    {
        "subdistrict_id": "5998",
        "district_id": "743",
        "subdistrict_name": "ศรีประจันต์"
    },
    {
        "subdistrict_id": "5999",
        "district_id": "743",
        "subdistrict_name": "บ้านกร่าง"
    },
    {
        "subdistrict_id": "6000",
        "district_id": "743",
        "subdistrict_name": "มดแดง"
    },
    {
        "subdistrict_id": "6001",
        "district_id": "743",
        "subdistrict_name": "บางงาม"
    },
    {
        "subdistrict_id": "6002",
        "district_id": "743",
        "subdistrict_name": "ดอนปรู"
    },
    {
        "subdistrict_id": "6003",
        "district_id": "743",
        "subdistrict_name": "ปลายนา"
    },
    {
        "subdistrict_id": "6004",
        "district_id": "743",
        "subdistrict_name": "วังหว้า"
    },
    {
        "subdistrict_id": "6005",
        "district_id": "743",
        "subdistrict_name": "วังน้ำซับ"
    },
    {
        "subdistrict_id": "6006",
        "district_id": "743",
        "subdistrict_name": "วังยาง"
    },
    {
        "subdistrict_id": "6007",
        "district_id": "744",
        "subdistrict_name": "ดอนเจดีย์"
    },
    {
        "subdistrict_id": "6008",
        "district_id": "744",
        "subdistrict_name": "หนองสาหร่าย"
    },
    {
        "subdistrict_id": "6009",
        "district_id": "744",
        "subdistrict_name": "ไร่รถ"
    },
    {
        "subdistrict_id": "6010",
        "district_id": "744",
        "subdistrict_name": "สระกระโจม"
    },
    {
        "subdistrict_id": "6011",
        "district_id": "744",
        "subdistrict_name": "ทะเลบก"
    },
    {
        "subdistrict_id": "6012",
        "district_id": "745",
        "subdistrict_name": "สองพี่น้อง"
    },
    {
        "subdistrict_id": "6013",
        "district_id": "745",
        "subdistrict_name": "บางเลน"
    },
    {
        "subdistrict_id": "6014",
        "district_id": "745",
        "subdistrict_name": "บางตาเถร"
    },
    {
        "subdistrict_id": "6015",
        "district_id": "745",
        "subdistrict_name": "บางตะเคียน"
    },
    {
        "subdistrict_id": "6016",
        "district_id": "745",
        "subdistrict_name": "บ้านกุ่ม"
    },
    {
        "subdistrict_id": "6017",
        "district_id": "745",
        "subdistrict_name": "หัวโพธิ์"
    },
    {
        "subdistrict_id": "6018",
        "district_id": "745",
        "subdistrict_name": "บางพลับ"
    },
    {
        "subdistrict_id": "6019",
        "district_id": "745",
        "subdistrict_name": "เนินพระปรางค์"
    },
    {
        "subdistrict_id": "6020",
        "district_id": "745",
        "subdistrict_name": "บ้านช้าง"
    },
    {
        "subdistrict_id": "6021",
        "district_id": "745",
        "subdistrict_name": "ต้นตาล"
    },
    {
        "subdistrict_id": "6022",
        "district_id": "745",
        "subdistrict_name": "ศรีสำราญ"
    },
    {
        "subdistrict_id": "6023",
        "district_id": "745",
        "subdistrict_name": "ทุ่งคอก"
    },
    {
        "subdistrict_id": "6024",
        "district_id": "745",
        "subdistrict_name": "หนองบ่อ"
    },
    {
        "subdistrict_id": "6025",
        "district_id": "745",
        "subdistrict_name": "บ่อสุพรรณ"
    },
    {
        "subdistrict_id": "6026",
        "district_id": "745",
        "subdistrict_name": "ดอนมะนาว"
    },
    {
        "subdistrict_id": "6027",
        "district_id": "746",
        "subdistrict_name": "ย่านยาว"
    },
    {
        "subdistrict_id": "6028",
        "district_id": "746",
        "subdistrict_name": "วังลึก"
    },
    {
        "subdistrict_id": "6029",
        "district_id": "746",
        "subdistrict_name": "สามชุก"
    },
    {
        "subdistrict_id": "6030",
        "district_id": "746",
        "subdistrict_name": "หนองผักนาก"
    },
    {
        "subdistrict_id": "6031",
        "district_id": "746",
        "subdistrict_name": "บ้านสระ"
    },
    {
        "subdistrict_id": "6032",
        "district_id": "746",
        "subdistrict_name": "หนองสะเดา"
    },
    {
        "subdistrict_id": "6033",
        "district_id": "746",
        "subdistrict_name": "กระเสียว"
    },
    {
        "subdistrict_id": "6034",
        "district_id": "747",
        "subdistrict_name": "อู่ทอง"
    },
    {
        "subdistrict_id": "6035",
        "district_id": "747",
        "subdistrict_name": "สระยายโสม"
    },
    {
        "subdistrict_id": "6036",
        "district_id": "747",
        "subdistrict_name": "จรเข้สามพัน"
    },
    {
        "subdistrict_id": "6037",
        "district_id": "747",
        "subdistrict_name": "บ้านดอน"
    },
    {
        "subdistrict_id": "6038",
        "district_id": "747",
        "subdistrict_name": "ยุ้งทะลาย"
    },
    {
        "subdistrict_id": "6039",
        "district_id": "747",
        "subdistrict_name": "ดอนมะเกลือ"
    },
    {
        "subdistrict_id": "6040",
        "district_id": "747",
        "subdistrict_name": "หนองโอ่ง"
    },
    {
        "subdistrict_id": "6041",
        "district_id": "747",
        "subdistrict_name": "ดอนคา"
    },
    {
        "subdistrict_id": "6042",
        "district_id": "747",
        "subdistrict_name": "พลับพลาไชย"
    },
    {
        "subdistrict_id": "6043",
        "district_id": "747",
        "subdistrict_name": "บ้านโข้ง"
    },
    {
        "subdistrict_id": "6044",
        "district_id": "747",
        "subdistrict_name": "เจดีย์"
    },
    {
        "subdistrict_id": "6045",
        "district_id": "747",
        "subdistrict_name": "สระพังลาน"
    },
    {
        "subdistrict_id": "6046",
        "district_id": "747",
        "subdistrict_name": "กระจัน"
    },
    {
        "subdistrict_id": "6047",
        "district_id": "748",
        "subdistrict_name": "หนองหญ้าไซ"
    },
    {
        "subdistrict_id": "6048",
        "district_id": "748",
        "subdistrict_name": "หนองราชวัตร"
    },
    {
        "subdistrict_id": "6049",
        "district_id": "748",
        "subdistrict_name": "หนองโพธิ์"
    },
    {
        "subdistrict_id": "6050",
        "district_id": "748",
        "subdistrict_name": "แจงงาม"
    },
    {
        "subdistrict_id": "6051",
        "district_id": "748",
        "subdistrict_name": "หนองขาม"
    },
    {
        "subdistrict_id": "6052",
        "district_id": "748",
        "subdistrict_name": "ทัพหลวง"
    },
    {
        "subdistrict_id": "6053",
        "district_id": "749",
        "subdistrict_name": "พระปฐมเจดีย์"
    },
    {
        "subdistrict_id": "6054",
        "district_id": "749",
        "subdistrict_name": "บางแขม"
    },
    {
        "subdistrict_id": "6055",
        "district_id": "749",
        "subdistrict_name": "พระประโทน"
    },
    {
        "subdistrict_id": "6056",
        "district_id": "749",
        "subdistrict_name": "ธรรมศาลา"
    },
    {
        "subdistrict_id": "6057",
        "district_id": "749",
        "subdistrict_name": "ตาก้อง"
    },
    {
        "subdistrict_id": "6058",
        "district_id": "749",
        "subdistrict_name": "มาบแค"
    },
    {
        "subdistrict_id": "6059",
        "district_id": "749",
        "subdistrict_name": "สนามจันทร์"
    },
    {
        "subdistrict_id": "6060",
        "district_id": "749",
        "subdistrict_name": "ดอนยายหอม"
    },
    {
        "subdistrict_id": "6061",
        "district_id": "749",
        "subdistrict_name": "ถนนขาด"
    },
    {
        "subdistrict_id": "6062",
        "district_id": "749",
        "subdistrict_name": "บ่อพลับ"
    },
    {
        "subdistrict_id": "6063",
        "district_id": "749",
        "subdistrict_name": "นครปฐม"
    },
    {
        "subdistrict_id": "6064",
        "district_id": "749",
        "subdistrict_name": "วังตะกู"
    },
    {
        "subdistrict_id": "6065",
        "district_id": "749",
        "subdistrict_name": "หนองปากโลง"
    },
    {
        "subdistrict_id": "6066",
        "district_id": "749",
        "subdistrict_name": "สามควายเผือก"
    },
    {
        "subdistrict_id": "6067",
        "district_id": "749",
        "subdistrict_name": "ทุ่งน้อย"
    },
    {
        "subdistrict_id": "6068",
        "district_id": "749",
        "subdistrict_name": "หนองดินแดง"
    },
    {
        "subdistrict_id": "6069",
        "district_id": "749",
        "subdistrict_name": "วังเย็น"
    },
    {
        "subdistrict_id": "6070",
        "district_id": "749",
        "subdistrict_name": "โพรงมะเดื่อ"
    },
    {
        "subdistrict_id": "6071",
        "district_id": "749",
        "subdistrict_name": "ลำพยา"
    },
    {
        "subdistrict_id": "6072",
        "district_id": "749",
        "subdistrict_name": "สระกะเทียม"
    },
    {
        "subdistrict_id": "6073",
        "district_id": "749",
        "subdistrict_name": "สวนป่าน"
    },
    {
        "subdistrict_id": "6074",
        "district_id": "749",
        "subdistrict_name": "ห้วยจรเข้"
    },
    {
        "subdistrict_id": "6075",
        "district_id": "749",
        "subdistrict_name": "ทัพหลวง"
    },
    {
        "subdistrict_id": "6076",
        "district_id": "749",
        "subdistrict_name": "หนองงูเหลือม"
    },
    {
        "subdistrict_id": "6077",
        "district_id": "749",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "6078",
        "district_id": "750",
        "subdistrict_name": "ทุ่งกระพังโหม"
    },
    {
        "subdistrict_id": "6079",
        "district_id": "750",
        "subdistrict_name": "กระตีบ"
    },
    {
        "subdistrict_id": "6080",
        "district_id": "750",
        "subdistrict_name": "ทุ่งลูกนก"
    },
    {
        "subdistrict_id": "6081",
        "district_id": "750",
        "subdistrict_name": "ห้วยขวาง"
    },
    {
        "subdistrict_id": "6082",
        "district_id": "750",
        "subdistrict_name": "ทุ่งขวาง"
    },
    {
        "subdistrict_id": "6083",
        "district_id": "750",
        "subdistrict_name": "สระสี่มุม"
    },
    {
        "subdistrict_id": "6084",
        "district_id": "750",
        "subdistrict_name": "ทุ่งบัว"
    },
    {
        "subdistrict_id": "6085",
        "district_id": "750",
        "subdistrict_name": "ดอนข่อย"
    },
    {
        "subdistrict_id": "6086",
        "district_id": "750",
        "subdistrict_name": "สระพัฒนา"
    },
    {
        "subdistrict_id": "6087",
        "district_id": "750",
        "subdistrict_name": "ห้วยหมอนทอง"
    },
    {
        "subdistrict_id": "6088",
        "district_id": "750",
        "subdistrict_name": "ห้วยม่วง"
    },
    {
        "subdistrict_id": "6089",
        "district_id": "750",
        "subdistrict_name": "กำแพงแสน"
    },
    {
        "subdistrict_id": "6090",
        "district_id": "750",
        "subdistrict_name": "รางพิกุล"
    },
    {
        "subdistrict_id": "6091",
        "district_id": "750",
        "subdistrict_name": "หนองกระทุ่ม"
    },
    {
        "subdistrict_id": "6092",
        "district_id": "750",
        "subdistrict_name": "วังน้ำเขียว"
    },
    {
        "subdistrict_id": "6093",
        "district_id": "751",
        "subdistrict_name": "นครชัยศรี"
    },
    {
        "subdistrict_id": "6094",
        "district_id": "751",
        "subdistrict_name": "บางกระเบา"
    },
    {
        "subdistrict_id": "6095",
        "district_id": "751",
        "subdistrict_name": "วัดแค"
    },
    {
        "subdistrict_id": "6096",
        "district_id": "751",
        "subdistrict_name": "ท่าตำหนัก"
    },
    {
        "subdistrict_id": "6097",
        "district_id": "751",
        "subdistrict_name": "บางแก้ว"
    },
    {
        "subdistrict_id": "6098",
        "district_id": "751",
        "subdistrict_name": "ท่ากระชับ"
    },
    {
        "subdistrict_id": "6099",
        "district_id": "751",
        "subdistrict_name": "ขุนแก้ว"
    },
    {
        "subdistrict_id": "6100",
        "district_id": "751",
        "subdistrict_name": "ท่าพระยา"
    },
    {
        "subdistrict_id": "6101",
        "district_id": "751",
        "subdistrict_name": "พะเนียด"
    },
    {
        "subdistrict_id": "6102",
        "district_id": "751",
        "subdistrict_name": "บางระกำ"
    },
    {
        "subdistrict_id": "6103",
        "district_id": "751",
        "subdistrict_name": "โคกพระเจดีย์"
    },
    {
        "subdistrict_id": "6104",
        "district_id": "751",
        "subdistrict_name": "ศรีษะทอง"
    },
    {
        "subdistrict_id": "6105",
        "district_id": "751",
        "subdistrict_name": "แหลมบัว"
    },
    {
        "subdistrict_id": "6106",
        "district_id": "751",
        "subdistrict_name": "ศรีมหาโพธิ์"
    },
    {
        "subdistrict_id": "6107",
        "district_id": "751",
        "subdistrict_name": "สัมปทวน"
    },
    {
        "subdistrict_id": "6108",
        "district_id": "751",
        "subdistrict_name": "วัดสำโรง"
    },
    {
        "subdistrict_id": "6109",
        "district_id": "751",
        "subdistrict_name": "ดอนแฝก"
    },
    {
        "subdistrict_id": "6110",
        "district_id": "751",
        "subdistrict_name": "ห้วยพลู"
    },
    {
        "subdistrict_id": "6111",
        "district_id": "751",
        "subdistrict_name": "วัดละมุด"
    },
    {
        "subdistrict_id": "6112",
        "district_id": "751",
        "subdistrict_name": "บางพระ"
    },
    {
        "subdistrict_id": "6113",
        "district_id": "751",
        "subdistrict_name": "บางแก้วฟ้า"
    },
    {
        "subdistrict_id": "6114",
        "district_id": "751",
        "subdistrict_name": "ลานตากฟ้า"
    },
    {
        "subdistrict_id": "6115",
        "district_id": "751",
        "subdistrict_name": "งิ้วราย"
    },
    {
        "subdistrict_id": "6116",
        "district_id": "751",
        "subdistrict_name": "ไทยาวาส"
    },
    {
        "subdistrict_id": "6117",
        "district_id": "752",
        "subdistrict_name": "สามง่าม"
    },
    {
        "subdistrict_id": "6118",
        "district_id": "752",
        "subdistrict_name": "ห้วยพระ"
    },
    {
        "subdistrict_id": "6119",
        "district_id": "752",
        "subdistrict_name": "ลำเหย"
    },
    {
        "subdistrict_id": "6120",
        "district_id": "752",
        "subdistrict_name": "ดอนพุทรา"
    },
    {
        "subdistrict_id": "6121",
        "district_id": "752",
        "subdistrict_name": "บ้านหลวง"
    },
    {
        "subdistrict_id": "6122",
        "district_id": "752",
        "subdistrict_name": "ดอนรวก"
    },
    {
        "subdistrict_id": "6123",
        "district_id": "752",
        "subdistrict_name": "ห้วยด้วน"
    },
    {
        "subdistrict_id": "6124",
        "district_id": "752",
        "subdistrict_name": "ลำลูกบัว"
    },
    {
        "subdistrict_id": "6125",
        "district_id": "753",
        "subdistrict_name": "บางเลน"
    },
    {
        "subdistrict_id": "6126",
        "district_id": "753",
        "subdistrict_name": "บางปลา"
    },
    {
        "subdistrict_id": "6127",
        "district_id": "753",
        "subdistrict_name": "บางหลวง"
    },
    {
        "subdistrict_id": "6128",
        "district_id": "753",
        "subdistrict_name": "บางภาษี"
    },
    {
        "subdistrict_id": "6129",
        "district_id": "753",
        "subdistrict_name": "บางระกำ"
    },
    {
        "subdistrict_id": "6130",
        "district_id": "753",
        "subdistrict_name": "บางไทรป่า"
    },
    {
        "subdistrict_id": "6131",
        "district_id": "753",
        "subdistrict_name": "หินมูล"
    },
    {
        "subdistrict_id": "6132",
        "district_id": "753",
        "subdistrict_name": "ไทรงาม"
    },
    {
        "subdistrict_id": "6133",
        "district_id": "753",
        "subdistrict_name": "ดอนตูม"
    },
    {
        "subdistrict_id": "6134",
        "district_id": "753",
        "subdistrict_name": "นิลเพชร"
    },
    {
        "subdistrict_id": "6135",
        "district_id": "753",
        "subdistrict_name": "บัวปากท่า"
    },
    {
        "subdistrict_id": "6136",
        "district_id": "753",
        "subdistrict_name": "คลองนกกระทุง"
    },
    {
        "subdistrict_id": "6137",
        "district_id": "753",
        "subdistrict_name": "นราภิรมย์"
    },
    {
        "subdistrict_id": "6138",
        "district_id": "753",
        "subdistrict_name": "ลำพญา"
    },
    {
        "subdistrict_id": "6139",
        "district_id": "753",
        "subdistrict_name": "ไผ่หูช้าง"
    },
    {
        "subdistrict_id": "6140",
        "district_id": "754",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "6141",
        "district_id": "754",
        "subdistrict_name": "ทรงคนอง"
    },
    {
        "subdistrict_id": "6142",
        "district_id": "754",
        "subdistrict_name": "หอมเกร็ด"
    },
    {
        "subdistrict_id": "6143",
        "district_id": "754",
        "subdistrict_name": "บางกระทึก"
    },
    {
        "subdistrict_id": "6144",
        "district_id": "754",
        "subdistrict_name": "บางเตย"
    },
    {
        "subdistrict_id": "6145",
        "district_id": "754",
        "subdistrict_name": "สามพราน"
    },
    {
        "subdistrict_id": "6146",
        "district_id": "754",
        "subdistrict_name": "บางช้าง"
    },
    {
        "subdistrict_id": "6147",
        "district_id": "754",
        "subdistrict_name": "ไร่ขิง"
    },
    {
        "subdistrict_id": "6148",
        "district_id": "754",
        "subdistrict_name": "ท่าตลาด"
    },
    {
        "subdistrict_id": "6149",
        "district_id": "754",
        "subdistrict_name": "กระทุ่มล้ม"
    },
    {
        "subdistrict_id": "6150",
        "district_id": "754",
        "subdistrict_name": "คลองใหม่"
    },
    {
        "subdistrict_id": "6151",
        "district_id": "754",
        "subdistrict_name": "ตลาดจินดา"
    },
    {
        "subdistrict_id": "6152",
        "district_id": "754",
        "subdistrict_name": "คลองจินดา"
    },
    {
        "subdistrict_id": "6153",
        "district_id": "754",
        "subdistrict_name": "ยายชา"
    },
    {
        "subdistrict_id": "6154",
        "district_id": "754",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "6155",
        "district_id": "754",
        "subdistrict_name": "อ้อมใหญ่"
    },
    {
        "subdistrict_id": "6156",
        "district_id": "755",
        "subdistrict_name": "ศาลายา"
    },
    {
        "subdistrict_id": "6157",
        "district_id": "755",
        "subdistrict_name": "คลองโยง"
    },
    {
        "subdistrict_id": "6158",
        "district_id": "755",
        "subdistrict_name": "มหาสวัสดิ์"
    },
    {
        "subdistrict_id": "6159",
        "district_id": "756",
        "subdistrict_name": "มหาชัย"
    },
    {
        "subdistrict_id": "6160",
        "district_id": "756",
        "subdistrict_name": "ท่าฉลอม"
    },
    {
        "subdistrict_id": "6161",
        "district_id": "756",
        "subdistrict_name": "โกรกกราก"
    },
    {
        "subdistrict_id": "6162",
        "district_id": "756",
        "subdistrict_name": "บ้านบ่อ"
    },
    {
        "subdistrict_id": "6163",
        "district_id": "756",
        "subdistrict_name": "บางโทรัด"
    },
    {
        "subdistrict_id": "6164",
        "district_id": "756",
        "subdistrict_name": "กาหลง"
    },
    {
        "subdistrict_id": "6165",
        "district_id": "756",
        "subdistrict_name": "นาโคก"
    },
    {
        "subdistrict_id": "6166",
        "district_id": "756",
        "subdistrict_name": "ท่าจีน"
    },
    {
        "subdistrict_id": "6167",
        "district_id": "756",
        "subdistrict_name": "นาดี"
    },
    {
        "subdistrict_id": "6168",
        "district_id": "756",
        "subdistrict_name": "ท่าทราย"
    },
    {
        "subdistrict_id": "6169",
        "district_id": "756",
        "subdistrict_name": "คอกกระบือ"
    },
    {
        "subdistrict_id": "6170",
        "district_id": "756",
        "subdistrict_name": "บางน้ำจืด"
    },
    {
        "subdistrict_id": "6171",
        "district_id": "756",
        "subdistrict_name": "พันท้ายนรสิงห์"
    },
    {
        "subdistrict_id": "6172",
        "district_id": "756",
        "subdistrict_name": "โคกขาม"
    },
    {
        "subdistrict_id": "6173",
        "district_id": "756",
        "subdistrict_name": "บ้านเกาะ"
    },
    {
        "subdistrict_id": "6174",
        "district_id": "756",
        "subdistrict_name": "บางกระเจ้า"
    },
    {
        "subdistrict_id": "6175",
        "district_id": "756",
        "subdistrict_name": "บางหญ้าแพรก"
    },
    {
        "subdistrict_id": "6176",
        "district_id": "756",
        "subdistrict_name": "ชัยมงคล"
    },
    {
        "subdistrict_id": "6177",
        "district_id": "757",
        "subdistrict_name": "ตลาดกระทุ่มแบน"
    },
    {
        "subdistrict_id": "6178",
        "district_id": "757",
        "subdistrict_name": "อ้อมน้อย"
    },
    {
        "subdistrict_id": "6179",
        "district_id": "757",
        "subdistrict_name": "ท่าไม้"
    },
    {
        "subdistrict_id": "6180",
        "district_id": "757",
        "subdistrict_name": "สวนหลวง"
    },
    {
        "subdistrict_id": "6181",
        "district_id": "757",
        "subdistrict_name": "บางยาง"
    },
    {
        "subdistrict_id": "6182",
        "district_id": "757",
        "subdistrict_name": "คลองมะเดื่อ"
    },
    {
        "subdistrict_id": "6183",
        "district_id": "757",
        "subdistrict_name": "หนองนกไข่"
    },
    {
        "subdistrict_id": "6184",
        "district_id": "757",
        "subdistrict_name": "ดอนไก่ดี"
    },
    {
        "subdistrict_id": "6185",
        "district_id": "757",
        "subdistrict_name": "แคราย"
    },
    {
        "subdistrict_id": "6186",
        "district_id": "757",
        "subdistrict_name": "ท่าเสา"
    },
    {
        "subdistrict_id": "6187",
        "district_id": "758",
        "subdistrict_name": "บ้านแพ้ว"
    },
    {
        "subdistrict_id": "6188",
        "district_id": "758",
        "subdistrict_name": "หลักสาม"
    },
    {
        "subdistrict_id": "6189",
        "district_id": "758",
        "subdistrict_name": "ยกกระบัตร"
    },
    {
        "subdistrict_id": "6190",
        "district_id": "758",
        "subdistrict_name": "โรงเข้"
    },
    {
        "subdistrict_id": "6191",
        "district_id": "758",
        "subdistrict_name": "หนองสองห้อง"
    },
    {
        "subdistrict_id": "6192",
        "district_id": "758",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "6193",
        "district_id": "758",
        "subdistrict_name": "หลักสอง"
    },
    {
        "subdistrict_id": "6194",
        "district_id": "758",
        "subdistrict_name": "เจ็ดริ้ว"
    },
    {
        "subdistrict_id": "6195",
        "district_id": "758",
        "subdistrict_name": "คลองตัน"
    },
    {
        "subdistrict_id": "6196",
        "district_id": "758",
        "subdistrict_name": "อำแพง"
    },
    {
        "subdistrict_id": "6197",
        "district_id": "758",
        "subdistrict_name": "สวนส้ม"
    },
    {
        "subdistrict_id": "6198",
        "district_id": "758",
        "subdistrict_name": "เกษตรพัฒนา"
    },
    {
        "subdistrict_id": "6199",
        "district_id": "759",
        "subdistrict_name": "แม่กลอง"
    },
    {
        "subdistrict_id": "6200",
        "district_id": "759",
        "subdistrict_name": "บางขันแตก"
    },
    {
        "subdistrict_id": "6201",
        "district_id": "759",
        "subdistrict_name": "ลาดใหญ่"
    },
    {
        "subdistrict_id": "6202",
        "district_id": "759",
        "subdistrict_name": "บ้านปรก"
    },
    {
        "subdistrict_id": "6203",
        "district_id": "759",
        "subdistrict_name": "บางแก้ว"
    },
    {
        "subdistrict_id": "6204",
        "district_id": "759",
        "subdistrict_name": "ท้ายหาด"
    },
    {
        "subdistrict_id": "6205",
        "district_id": "759",
        "subdistrict_name": "แหลมใหญ่"
    },
    {
        "subdistrict_id": "6206",
        "district_id": "759",
        "subdistrict_name": "คลองเขิน"
    },
    {
        "subdistrict_id": "6207",
        "district_id": "759",
        "subdistrict_name": "คลองโคน"
    },
    {
        "subdistrict_id": "6208",
        "district_id": "759",
        "subdistrict_name": "นางตะเคียน"
    },
    {
        "subdistrict_id": "6209",
        "district_id": "759",
        "subdistrict_name": "บางจะเกร็ง"
    },
    {
        "subdistrict_id": "6210",
        "district_id": "760",
        "subdistrict_name": "กระดังงา"
    },
    {
        "subdistrict_id": "6211",
        "district_id": "760",
        "subdistrict_name": "บางสะแก"
    },
    {
        "subdistrict_id": "6212",
        "district_id": "760",
        "subdistrict_name": "บางยี่รงค์"
    },
    {
        "subdistrict_id": "6213",
        "district_id": "760",
        "subdistrict_name": "โรงหีบ"
    },
    {
        "subdistrict_id": "6214",
        "district_id": "760",
        "subdistrict_name": "บางคนที"
    },
    {
        "subdistrict_id": "6215",
        "district_id": "760",
        "subdistrict_name": "ดอนมะโนรา"
    },
    {
        "subdistrict_id": "6216",
        "district_id": "760",
        "subdistrict_name": "บางพรม"
    },
    {
        "subdistrict_id": "6217",
        "district_id": "760",
        "subdistrict_name": "บางกุ้ง"
    },
    {
        "subdistrict_id": "6218",
        "district_id": "760",
        "subdistrict_name": "จอมปลวก"
    },
    {
        "subdistrict_id": "6219",
        "district_id": "760",
        "subdistrict_name": "บางนกแขวก"
    },
    {
        "subdistrict_id": "6220",
        "district_id": "760",
        "subdistrict_name": "ยายแพง"
    },
    {
        "subdistrict_id": "6221",
        "district_id": "760",
        "subdistrict_name": "บางกระบือ"
    },
    {
        "subdistrict_id": "6222",
        "district_id": "760",
        "subdistrict_name": "บ้านปราโมทย์"
    },
    {
        "subdistrict_id": "6223",
        "district_id": "761",
        "subdistrict_name": "อัมพวา"
    },
    {
        "subdistrict_id": "6224",
        "district_id": "761",
        "subdistrict_name": "สวนหลวง"
    },
    {
        "subdistrict_id": "6225",
        "district_id": "761",
        "subdistrict_name": "ท่าคา"
    },
    {
        "subdistrict_id": "6226",
        "district_id": "761",
        "subdistrict_name": "วัดประดู่"
    },
    {
        "subdistrict_id": "6227",
        "district_id": "761",
        "subdistrict_name": "เหมืองใหม่"
    },
    {
        "subdistrict_id": "6228",
        "district_id": "761",
        "subdistrict_name": "บางช้าง"
    },
    {
        "subdistrict_id": "6229",
        "district_id": "761",
        "subdistrict_name": "แควอ้อม"
    },
    {
        "subdistrict_id": "6230",
        "district_id": "761",
        "subdistrict_name": "ปลายโพงพาง"
    },
    {
        "subdistrict_id": "6231",
        "district_id": "761",
        "subdistrict_name": "บางแค"
    },
    {
        "subdistrict_id": "6232",
        "district_id": "761",
        "subdistrict_name": "แพรกหนามแดง"
    },
    {
        "subdistrict_id": "6233",
        "district_id": "761",
        "subdistrict_name": "ยี่สาร"
    },
    {
        "subdistrict_id": "6234",
        "district_id": "761",
        "subdistrict_name": "บางนางลี่"
    },
    {
        "subdistrict_id": "6235",
        "district_id": "762",
        "subdistrict_name": "ท่าราบ"
    },
    {
        "subdistrict_id": "6236",
        "district_id": "762",
        "subdistrict_name": "คลองกระแชง"
    },
    {
        "subdistrict_id": "6237",
        "district_id": "762",
        "subdistrict_name": "บางจาน"
    },
    {
        "subdistrict_id": "6238",
        "district_id": "762",
        "subdistrict_name": "นาพันสาม"
    },
    {
        "subdistrict_id": "6239",
        "district_id": "762",
        "subdistrict_name": "ธงชัย"
    },
    {
        "subdistrict_id": "6240",
        "district_id": "762",
        "subdistrict_name": "บ้านกุ่ม"
    },
    {
        "subdistrict_id": "6241",
        "district_id": "762",
        "subdistrict_name": "หนองโสน"
    },
    {
        "subdistrict_id": "6242",
        "district_id": "762",
        "subdistrict_name": "ไร่ส้ม"
    },
    {
        "subdistrict_id": "6243",
        "district_id": "762",
        "subdistrict_name": "เวียงคอย"
    },
    {
        "subdistrict_id": "6244",
        "district_id": "762",
        "subdistrict_name": "บางจาก"
    },
    {
        "subdistrict_id": "6245",
        "district_id": "762",
        "subdistrict_name": "บ้านหม้อ"
    },
    {
        "subdistrict_id": "6246",
        "district_id": "762",
        "subdistrict_name": "ต้นมะม่วง"
    },
    {
        "subdistrict_id": "6247",
        "district_id": "762",
        "subdistrict_name": "ช่องสะแก"
    },
    {
        "subdistrict_id": "6248",
        "district_id": "762",
        "subdistrict_name": "นาวุ้ง"
    },
    {
        "subdistrict_id": "6249",
        "district_id": "762",
        "subdistrict_name": "สำมะโรง"
    },
    {
        "subdistrict_id": "6250",
        "district_id": "762",
        "subdistrict_name": "โพพระ"
    },
    {
        "subdistrict_id": "6251",
        "district_id": "762",
        "subdistrict_name": "หาดเจ้าสำราญ"
    },
    {
        "subdistrict_id": "6252",
        "district_id": "762",
        "subdistrict_name": "หัวสะพาน"
    },
    {
        "subdistrict_id": "6253",
        "district_id": "762",
        "subdistrict_name": "ต้นมะพร้าว"
    },
    {
        "subdistrict_id": "6254",
        "district_id": "762",
        "subdistrict_name": "วังตะโก"
    },
    {
        "subdistrict_id": "6255",
        "district_id": "762",
        "subdistrict_name": "โพไร่หวาน"
    },
    {
        "subdistrict_id": "6256",
        "district_id": "762",
        "subdistrict_name": "ดอนยาง"
    },
    {
        "subdistrict_id": "6257",
        "district_id": "762",
        "subdistrict_name": "หนองขนาน"
    },
    {
        "subdistrict_id": "6258",
        "district_id": "762",
        "subdistrict_name": "หนองพลับ"
    },
    {
        "subdistrict_id": "6259",
        "district_id": "763",
        "subdistrict_name": "เขาย้อย"
    },
    {
        "subdistrict_id": "6260",
        "district_id": "763",
        "subdistrict_name": "สระพัง"
    },
    {
        "subdistrict_id": "6261",
        "district_id": "763",
        "subdistrict_name": "บางเค็ม"
    },
    {
        "subdistrict_id": "6262",
        "district_id": "763",
        "subdistrict_name": "ทับคาง"
    },
    {
        "subdistrict_id": "6263",
        "district_id": "763",
        "subdistrict_name": "หนองปลาไหล"
    },
    {
        "subdistrict_id": "6264",
        "district_id": "763",
        "subdistrict_name": "หนองปรง"
    },
    {
        "subdistrict_id": "6265",
        "district_id": "763",
        "subdistrict_name": "หนองชุมพล"
    },
    {
        "subdistrict_id": "6266",
        "district_id": "763",
        "subdistrict_name": "ห้วยโรง"
    },
    {
        "subdistrict_id": "6267",
        "district_id": "763",
        "subdistrict_name": "ห้วยท่าช้าง"
    },
    {
        "subdistrict_id": "6268",
        "district_id": "763",
        "subdistrict_name": "หนองชุมพลเหนือ"
    },
    {
        "subdistrict_id": "6269",
        "district_id": "764",
        "subdistrict_name": "หนองหญ้าปล้อง"
    },
    {
        "subdistrict_id": "6270",
        "district_id": "764",
        "subdistrict_name": "ยางน้ำกลัดเหนือ"
    },
    {
        "subdistrict_id": "6271",
        "district_id": "764",
        "subdistrict_name": "ยางน้ำกลัดใต้"
    },
    {
        "subdistrict_id": "6272",
        "district_id": "764",
        "subdistrict_name": "ท่าตะคร้อ"
    },
    {
        "subdistrict_id": "6273",
        "district_id": "765",
        "subdistrict_name": "ชะอำ"
    },
    {
        "subdistrict_id": "6274",
        "district_id": "765",
        "subdistrict_name": "บางเก่า"
    },
    {
        "subdistrict_id": "6275",
        "district_id": "765",
        "subdistrict_name": "นายาง"
    },
    {
        "subdistrict_id": "6276",
        "district_id": "765",
        "subdistrict_name": "เขาใหญ่"
    },
    {
        "subdistrict_id": "6277",
        "district_id": "765",
        "subdistrict_name": "หนองศาลา"
    },
    {
        "subdistrict_id": "6278",
        "district_id": "765",
        "subdistrict_name": "ห้วยทรายเหนือ"
    },
    {
        "subdistrict_id": "6279",
        "district_id": "765",
        "subdistrict_name": "ไร่ใหม่พัฒนา"
    },
    {
        "subdistrict_id": "6280",
        "district_id": "765",
        "subdistrict_name": "สามพระยา"
    },
    {
        "subdistrict_id": "6281",
        "district_id": "765",
        "subdistrict_name": "ดอนขุนห้วย"
    },
    {
        "subdistrict_id": "6282",
        "district_id": "766",
        "subdistrict_name": "ท่ายาง"
    },
    {
        "subdistrict_id": "6283",
        "district_id": "766",
        "subdistrict_name": "ท่าคอย"
    },
    {
        "subdistrict_id": "6284",
        "district_id": "766",
        "subdistrict_name": "ยางหย่อง"
    },
    {
        "subdistrict_id": "6285",
        "district_id": "766",
        "subdistrict_name": "หนองจอก"
    },
    {
        "subdistrict_id": "6286",
        "district_id": "766",
        "subdistrict_name": "มาบปลาเค้า"
    },
    {
        "subdistrict_id": "6287",
        "district_id": "766",
        "subdistrict_name": "ท่าไม้รวก"
    },
    {
        "subdistrict_id": "6288",
        "district_id": "766",
        "subdistrict_name": "วังไคร้"
    },
    {
        "subdistrict_id": "6289",
        "district_id": "766",
        "subdistrict_name": "กลัดหลวง"
    },
    {
        "subdistrict_id": "6290",
        "district_id": "766",
        "subdistrict_name": "ปึกเตียน"
    },
    {
        "subdistrict_id": "6291",
        "district_id": "766",
        "subdistrict_name": "เขากระปุก"
    },
    {
        "subdistrict_id": "6292",
        "district_id": "766",
        "subdistrict_name": "ท่าแลง"
    },
    {
        "subdistrict_id": "6293",
        "district_id": "766",
        "subdistrict_name": "บ้านในดง"
    },
    {
        "subdistrict_id": "6294",
        "district_id": "767",
        "subdistrict_name": "บ้านลาด"
    },
    {
        "subdistrict_id": "6295",
        "district_id": "767",
        "subdistrict_name": "บ้านหาด"
    },
    {
        "subdistrict_id": "6296",
        "district_id": "767",
        "subdistrict_name": "บ้านทาน"
    },
    {
        "subdistrict_id": "6297",
        "district_id": "767",
        "subdistrict_name": "ตำหรุ"
    },
    {
        "subdistrict_id": "6298",
        "district_id": "767",
        "subdistrict_name": "สมอพลือ"
    },
    {
        "subdistrict_id": "6299",
        "district_id": "767",
        "subdistrict_name": "ไร่มะขาม"
    },
    {
        "subdistrict_id": "6300",
        "district_id": "767",
        "subdistrict_name": "ท่าเสน"
    },
    {
        "subdistrict_id": "6301",
        "district_id": "767",
        "subdistrict_name": "หนองกระเจ็ด"
    },
    {
        "subdistrict_id": "6302",
        "district_id": "767",
        "subdistrict_name": "หนองกะปุ"
    },
    {
        "subdistrict_id": "6303",
        "district_id": "767",
        "subdistrict_name": "ลาดโพธิ์"
    },
    {
        "subdistrict_id": "6304",
        "district_id": "767",
        "subdistrict_name": "สะพานไกร"
    },
    {
        "subdistrict_id": "6305",
        "district_id": "767",
        "subdistrict_name": "ไร่โคก"
    },
    {
        "subdistrict_id": "6306",
        "district_id": "767",
        "subdistrict_name": "โรงเข้"
    },
    {
        "subdistrict_id": "6307",
        "district_id": "767",
        "subdistrict_name": "ไร่สะท้อน"
    },
    {
        "subdistrict_id": "6308",
        "district_id": "767",
        "subdistrict_name": "ห้วยข้อง"
    },
    {
        "subdistrict_id": "6309",
        "district_id": "767",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "6310",
        "district_id": "767",
        "subdistrict_name": "ถ้ำรงค์"
    },
    {
        "subdistrict_id": "6311",
        "district_id": "767",
        "subdistrict_name": "ห้วยลึก"
    },
    {
        "subdistrict_id": "6312",
        "district_id": "768",
        "subdistrict_name": "บ้านแหลม"
    },
    {
        "subdistrict_id": "6313",
        "district_id": "768",
        "subdistrict_name": "บางขุนไทร"
    },
    {
        "subdistrict_id": "6314",
        "district_id": "768",
        "subdistrict_name": "ปากทะเล"
    },
    {
        "subdistrict_id": "6315",
        "district_id": "768",
        "subdistrict_name": "บางแก้ว"
    },
    {
        "subdistrict_id": "6316",
        "district_id": "768",
        "subdistrict_name": "แหลมผักเบี้ย"
    },
    {
        "subdistrict_id": "6317",
        "district_id": "768",
        "subdistrict_name": "บางตะบูน"
    },
    {
        "subdistrict_id": "6318",
        "district_id": "768",
        "subdistrict_name": "บางตะบูนออก"
    },
    {
        "subdistrict_id": "6319",
        "district_id": "768",
        "subdistrict_name": "บางครก"
    },
    {
        "subdistrict_id": "6320",
        "district_id": "768",
        "subdistrict_name": "ท่าแร้ง"
    },
    {
        "subdistrict_id": "6321",
        "district_id": "768",
        "subdistrict_name": "ท่าแร้งออก"
    },
    {
        "subdistrict_id": "6322",
        "district_id": "769",
        "subdistrict_name": "แก่งกระจาน"
    },
    {
        "subdistrict_id": "6323",
        "district_id": "769",
        "subdistrict_name": "สองพี่น้อง"
    },
    {
        "subdistrict_id": "6324",
        "district_id": "769",
        "subdistrict_name": "วังจันทร์"
    },
    {
        "subdistrict_id": "6325",
        "district_id": "769",
        "subdistrict_name": "ป่าเด็ง"
    },
    {
        "subdistrict_id": "6326",
        "district_id": "769",
        "subdistrict_name": "พุสวรรค์"
    },
    {
        "subdistrict_id": "6327",
        "district_id": "769",
        "subdistrict_name": "ห้วยแม่เพรียง"
    },
    {
        "subdistrict_id": "6328",
        "district_id": "770",
        "subdistrict_name": "ประจวบคีรีขันธ์"
    },
    {
        "subdistrict_id": "6329",
        "district_id": "770",
        "subdistrict_name": "เกาะหลัก"
    },
    {
        "subdistrict_id": "6330",
        "district_id": "770",
        "subdistrict_name": "คลองวาฬ"
    },
    {
        "subdistrict_id": "6331",
        "district_id": "770",
        "subdistrict_name": "ห้วยทราย"
    },
    {
        "subdistrict_id": "6332",
        "district_id": "770",
        "subdistrict_name": "อ่าวน้อย"
    },
    {
        "subdistrict_id": "6333",
        "district_id": "770",
        "subdistrict_name": "บ่อนอก"
    },
    {
        "subdistrict_id": "6334",
        "district_id": "771",
        "subdistrict_name": "กุยบุรี"
    },
    {
        "subdistrict_id": "6335",
        "district_id": "771",
        "subdistrict_name": "กุยเหนือ"
    },
    {
        "subdistrict_id": "6336",
        "district_id": "771",
        "subdistrict_name": "เขาแดง"
    },
    {
        "subdistrict_id": "6337",
        "district_id": "771",
        "subdistrict_name": "ดอนยายหนู"
    },
    {
        "subdistrict_id": "6338",
        "district_id": "771",
        "subdistrict_name": "สามกระทาย"
    },
    {
        "subdistrict_id": "6339",
        "district_id": "771",
        "subdistrict_name": "หาดขาม"
    },
    {
        "subdistrict_id": "6340",
        "district_id": "772",
        "subdistrict_name": "ทับสะแก"
    },
    {
        "subdistrict_id": "6341",
        "district_id": "772",
        "subdistrict_name": "อ่างทอง"
    },
    {
        "subdistrict_id": "6342",
        "district_id": "772",
        "subdistrict_name": "นาหูกวาง"
    },
    {
        "subdistrict_id": "6343",
        "district_id": "772",
        "subdistrict_name": "เขาล้าน"
    },
    {
        "subdistrict_id": "6344",
        "district_id": "772",
        "subdistrict_name": "ห้วยยาง"
    },
    {
        "subdistrict_id": "6345",
        "district_id": "772",
        "subdistrict_name": "แสงอรุณ"
    },
    {
        "subdistrict_id": "6346",
        "district_id": "773",
        "subdistrict_name": "กำเนิดนพคุณ"
    },
    {
        "subdistrict_id": "6347",
        "district_id": "773",
        "subdistrict_name": "พงศ์ประศาสน์"
    },
    {
        "subdistrict_id": "6348",
        "district_id": "773",
        "subdistrict_name": "ร่อนทอง"
    },
    {
        "subdistrict_id": "6349",
        "district_id": "773",
        "subdistrict_name": "ธงชัย"
    },
    {
        "subdistrict_id": "6350",
        "district_id": "773",
        "subdistrict_name": "ชัยเกษม"
    },
    {
        "subdistrict_id": "6351",
        "district_id": "773",
        "subdistrict_name": "ทองมงคล"
    },
    {
        "subdistrict_id": "6352",
        "district_id": "773",
        "subdistrict_name": "แม่รำพึง"
    },
    {
        "subdistrict_id": "6353",
        "district_id": "774",
        "subdistrict_name": "ปากแพรก"
    },
    {
        "subdistrict_id": "6354",
        "district_id": "774",
        "subdistrict_name": "บางสะพาน"
    },
    {
        "subdistrict_id": "6355",
        "district_id": "774",
        "subdistrict_name": "ทรายทอง"
    },
    {
        "subdistrict_id": "6356",
        "district_id": "774",
        "subdistrict_name": "ช้างแรก"
    },
    {
        "subdistrict_id": "6357",
        "district_id": "774",
        "subdistrict_name": "ไชยราช"
    },
    {
        "subdistrict_id": "6358",
        "district_id": "775",
        "subdistrict_name": "ปราณบุรี"
    },
    {
        "subdistrict_id": "6359",
        "district_id": "775",
        "subdistrict_name": "เขาน้อย"
    },
    {
        "subdistrict_id": "6360",
        "district_id": "775",
        "subdistrict_name": "ปากน้ำปราณ"
    },
    {
        "subdistrict_id": "6361",
        "district_id": "775",
        "subdistrict_name": "หนองตาแต้ม"
    },
    {
        "subdistrict_id": "6362",
        "district_id": "775",
        "subdistrict_name": "วังก์พง"
    },
    {
        "subdistrict_id": "6363",
        "district_id": "775",
        "subdistrict_name": "เขาจ้าว"
    },
    {
        "subdistrict_id": "6364",
        "district_id": "776",
        "subdistrict_name": "หัวหิน"
    },
    {
        "subdistrict_id": "6365",
        "district_id": "776",
        "subdistrict_name": "หนองแก"
    },
    {
        "subdistrict_id": "6366",
        "district_id": "776",
        "subdistrict_name": "หินเหล็กไฟ"
    },
    {
        "subdistrict_id": "6367",
        "district_id": "776",
        "subdistrict_name": "หนองพลับ"
    },
    {
        "subdistrict_id": "6368",
        "district_id": "776",
        "subdistrict_name": "ทับใต้"
    },
    {
        "subdistrict_id": "6369",
        "district_id": "776",
        "subdistrict_name": "ห้วยสัตว์ใหญ่"
    },
    {
        "subdistrict_id": "6370",
        "district_id": "776",
        "subdistrict_name": "บึงนคร"
    },
    {
        "subdistrict_id": "6371",
        "district_id": "777",
        "subdistrict_name": "สามร้อยยอด"
    },
    {
        "subdistrict_id": "6372",
        "district_id": "777",
        "subdistrict_name": "ศิลาลอย"
    },
    {
        "subdistrict_id": "6373",
        "district_id": "777",
        "subdistrict_name": "ไร่เก่า"
    },
    {
        "subdistrict_id": "6374",
        "district_id": "777",
        "subdistrict_name": "ศาลาลัย"
    },
    {
        "subdistrict_id": "6375",
        "district_id": "777",
        "subdistrict_name": "ไร่ใหม่"
    },
    {
        "subdistrict_id": "6376",
        "district_id": "778",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "6377",
        "district_id": "778",
        "subdistrict_name": "ท่าวัง"
    },
    {
        "subdistrict_id": "6378",
        "district_id": "778",
        "subdistrict_name": "คลัง"
    },
    {
        "subdistrict_id": "6379",
        "district_id": "778",
        "subdistrict_name": "ท่าไร่"
    },
    {
        "subdistrict_id": "6380",
        "district_id": "778",
        "subdistrict_name": "ปากนคร"
    },
    {
        "subdistrict_id": "6381",
        "district_id": "778",
        "subdistrict_name": "นาทราย"
    },
    {
        "subdistrict_id": "6382",
        "district_id": "778",
        "subdistrict_name": "กำแพงเซา"
    },
    {
        "subdistrict_id": "6383",
        "district_id": "778",
        "subdistrict_name": "ไชยมนตรี"
    },
    {
        "subdistrict_id": "6384",
        "district_id": "778",
        "subdistrict_name": "มะม่วงสองต้น"
    },
    {
        "subdistrict_id": "6385",
        "district_id": "778",
        "subdistrict_name": "นาเคียน"
    },
    {
        "subdistrict_id": "6386",
        "district_id": "778",
        "subdistrict_name": "ท่างิ้ว"
    },
    {
        "subdistrict_id": "6387",
        "district_id": "778",
        "subdistrict_name": "โพธิ์เสด็จ"
    },
    {
        "subdistrict_id": "6388",
        "district_id": "778",
        "subdistrict_name": "บางจาก"
    },
    {
        "subdistrict_id": "6389",
        "district_id": "778",
        "subdistrict_name": "ปากพูน"
    },
    {
        "subdistrict_id": "6390",
        "district_id": "778",
        "subdistrict_name": "ท่าซัก"
    },
    {
        "subdistrict_id": "6391",
        "district_id": "778",
        "subdistrict_name": "ท่าเรือ"
    },
    {
        "subdistrict_id": "6392",
        "district_id": "779",
        "subdistrict_name": "พรหมโลก"
    },
    {
        "subdistrict_id": "6393",
        "district_id": "779",
        "subdistrict_name": "บ้านเกาะ"
    },
    {
        "subdistrict_id": "6394",
        "district_id": "779",
        "subdistrict_name": "อินคีรี"
    },
    {
        "subdistrict_id": "6395",
        "district_id": "779",
        "subdistrict_name": "ทอนหงส์"
    },
    {
        "subdistrict_id": "6396",
        "district_id": "779",
        "subdistrict_name": "นาเรียง"
    },
    {
        "subdistrict_id": "6397",
        "district_id": "780",
        "subdistrict_name": "เขาแก้ว"
    },
    {
        "subdistrict_id": "6398",
        "district_id": "780",
        "subdistrict_name": "ลานสกา"
    },
    {
        "subdistrict_id": "6399",
        "district_id": "780",
        "subdistrict_name": "ท่าดี"
    },
    {
        "subdistrict_id": "6400",
        "district_id": "780",
        "subdistrict_name": "กำโลน"
    },
    {
        "subdistrict_id": "6401",
        "district_id": "780",
        "subdistrict_name": "ขุนทะเล"
    },
    {
        "subdistrict_id": "6402",
        "district_id": "781",
        "subdistrict_name": "ฉวาง"
    },
    {
        "subdistrict_id": "6403",
        "district_id": "781",
        "subdistrict_name": "ละอาย"
    },
    {
        "subdistrict_id": "6404",
        "district_id": "781",
        "subdistrict_name": "นาแว"
    },
    {
        "subdistrict_id": "6405",
        "district_id": "781",
        "subdistrict_name": "ไม้เรียง"
    },
    {
        "subdistrict_id": "6406",
        "district_id": "781",
        "subdistrict_name": "กะเปียด"
    },
    {
        "subdistrict_id": "6407",
        "district_id": "781",
        "subdistrict_name": "นากะชะ"
    },
    {
        "subdistrict_id": "6408",
        "district_id": "781",
        "subdistrict_name": "ห้วยปริก"
    },
    {
        "subdistrict_id": "6409",
        "district_id": "781",
        "subdistrict_name": "ไสหร้า"
    },
    {
        "subdistrict_id": "6410",
        "district_id": "781",
        "subdistrict_name": "นาเขลียง"
    },
    {
        "subdistrict_id": "6411",
        "district_id": "781",
        "subdistrict_name": "จันดี"
    },
    {
        "subdistrict_id": "6412",
        "district_id": "782",
        "subdistrict_name": "พิปูน"
    },
    {
        "subdistrict_id": "6413",
        "district_id": "782",
        "subdistrict_name": "กะทูน"
    },
    {
        "subdistrict_id": "6414",
        "district_id": "782",
        "subdistrict_name": "เขาพระ"
    },
    {
        "subdistrict_id": "6415",
        "district_id": "782",
        "subdistrict_name": "ยางค้อม"
    },
    {
        "subdistrict_id": "6416",
        "district_id": "782",
        "subdistrict_name": "ควนกลาง"
    },
    {
        "subdistrict_id": "6417",
        "district_id": "783",
        "subdistrict_name": "เชียรใหญ่"
    },
    {
        "subdistrict_id": "6418",
        "district_id": "783",
        "subdistrict_name": "ท่าขนาน"
    },
    {
        "subdistrict_id": "6419",
        "district_id": "783",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "6420",
        "district_id": "783",
        "subdistrict_name": "บ้านเนิน"
    },
    {
        "subdistrict_id": "6421",
        "district_id": "783",
        "subdistrict_name": "ไสหมาก"
    },
    {
        "subdistrict_id": "6422",
        "district_id": "783",
        "subdistrict_name": "ท้องลำเจียก"
    },
    {
        "subdistrict_id": "6423",
        "district_id": "783",
        "subdistrict_name": "เสือหึง"
    },
    {
        "subdistrict_id": "6424",
        "district_id": "783",
        "subdistrict_name": "การะเกด"
    },
    {
        "subdistrict_id": "6425",
        "district_id": "783",
        "subdistrict_name": "เขาพระบาท"
    },
    {
        "subdistrict_id": "6426",
        "district_id": "783",
        "subdistrict_name": "แม่เจ้าอยู่หัว"
    },
    {
        "subdistrict_id": "6427",
        "district_id": "784",
        "subdistrict_name": "ชะอวด"
    },
    {
        "subdistrict_id": "6428",
        "district_id": "784",
        "subdistrict_name": "ท่าเสม็ด"
    },
    {
        "subdistrict_id": "6429",
        "district_id": "784",
        "subdistrict_name": "ท่าประจะ"
    },
    {
        "subdistrict_id": "6430",
        "district_id": "784",
        "subdistrict_name": "เคร็ง"
    },
    {
        "subdistrict_id": "6431",
        "district_id": "784",
        "subdistrict_name": "วังอ่าง"
    },
    {
        "subdistrict_id": "6432",
        "district_id": "784",
        "subdistrict_name": "บ้านตูล"
    },
    {
        "subdistrict_id": "6433",
        "district_id": "784",
        "subdistrict_name": "ขอนหาด"
    },
    {
        "subdistrict_id": "6434",
        "district_id": "784",
        "subdistrict_name": "เกาะขันธ์"
    },
    {
        "subdistrict_id": "6435",
        "district_id": "784",
        "subdistrict_name": "ควนหนองหงษ์"
    },
    {
        "subdistrict_id": "6436",
        "district_id": "784",
        "subdistrict_name": "เขาพระทอง"
    },
    {
        "subdistrict_id": "6437",
        "district_id": "784",
        "subdistrict_name": "นางหลง"
    },
    {
        "subdistrict_id": "6438",
        "district_id": "785",
        "subdistrict_name": "ท่าศาลา"
    },
    {
        "subdistrict_id": "6439",
        "district_id": "785",
        "subdistrict_name": "กลาย"
    },
    {
        "subdistrict_id": "6440",
        "district_id": "785",
        "subdistrict_name": "ท่าขึ้น"
    },
    {
        "subdistrict_id": "6441",
        "district_id": "785",
        "subdistrict_name": "หัวตะพาน"
    },
    {
        "subdistrict_id": "6442",
        "district_id": "785",
        "subdistrict_name": "สระแก้ว"
    },
    {
        "subdistrict_id": "6443",
        "district_id": "785",
        "subdistrict_name": "โมคลาน"
    },
    {
        "subdistrict_id": "6444",
        "district_id": "785",
        "subdistrict_name": "ไทยบุรี"
    },
    {
        "subdistrict_id": "6445",
        "district_id": "785",
        "subdistrict_name": "ดอนตะโก"
    },
    {
        "subdistrict_id": "6446",
        "district_id": "785",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "6447",
        "district_id": "785",
        "subdistrict_name": "โพธิ์ทอง"
    },
    {
        "subdistrict_id": "6448",
        "district_id": "786",
        "subdistrict_name": "ปากแพรก"
    },
    {
        "subdistrict_id": "6449",
        "district_id": "786",
        "subdistrict_name": "ชะมาย"
    },
    {
        "subdistrict_id": "6450",
        "district_id": "786",
        "subdistrict_name": "หนองหงส์"
    },
    {
        "subdistrict_id": "6451",
        "district_id": "786",
        "subdistrict_name": "ควนกรด"
    },
    {
        "subdistrict_id": "6452",
        "district_id": "786",
        "subdistrict_name": "นาไม้ไผ่"
    },
    {
        "subdistrict_id": "6453",
        "district_id": "786",
        "subdistrict_name": "นาหลวงเสน"
    },
    {
        "subdistrict_id": "6454",
        "district_id": "786",
        "subdistrict_name": "เขาโร"
    },
    {
        "subdistrict_id": "6455",
        "district_id": "786",
        "subdistrict_name": "กะปาง"
    },
    {
        "subdistrict_id": "6456",
        "district_id": "786",
        "subdistrict_name": "ที่วัง"
    },
    {
        "subdistrict_id": "6457",
        "district_id": "786",
        "subdistrict_name": "น้ำตก"
    },
    {
        "subdistrict_id": "6458",
        "district_id": "786",
        "subdistrict_name": "ถ้ำใหญ่"
    },
    {
        "subdistrict_id": "6459",
        "district_id": "786",
        "subdistrict_name": "นาโพธิ์"
    },
    {
        "subdistrict_id": "6460",
        "district_id": "786",
        "subdistrict_name": "เขาขาว"
    },
    {
        "subdistrict_id": "6461",
        "district_id": "787",
        "subdistrict_name": "นาบอน"
    },
    {
        "subdistrict_id": "6462",
        "district_id": "787",
        "subdistrict_name": "ทุ่งสง"
    },
    {
        "subdistrict_id": "6463",
        "district_id": "787",
        "subdistrict_name": "แก้วแสน"
    },
    {
        "subdistrict_id": "6464",
        "district_id": "788",
        "subdistrict_name": "ท่ายาง"
    },
    {
        "subdistrict_id": "6465",
        "district_id": "788",
        "subdistrict_name": "ทุ่งสัง"
    },
    {
        "subdistrict_id": "6466",
        "district_id": "788",
        "subdistrict_name": "ทุ่งใหญ่"
    },
    {
        "subdistrict_id": "6467",
        "district_id": "788",
        "subdistrict_name": "กุแหระ"
    },
    {
        "subdistrict_id": "6468",
        "district_id": "788",
        "subdistrict_name": "ปริก"
    },
    {
        "subdistrict_id": "6469",
        "district_id": "788",
        "subdistrict_name": "บางรูป"
    },
    {
        "subdistrict_id": "6470",
        "district_id": "788",
        "subdistrict_name": "กรุงหยัน"
    },
    {
        "subdistrict_id": "6471",
        "district_id": "789",
        "subdistrict_name": "ปากพนัง"
    },
    {
        "subdistrict_id": "6472",
        "district_id": "789",
        "subdistrict_name": "คลองน้อย"
    },
    {
        "subdistrict_id": "6473",
        "district_id": "789",
        "subdistrict_name": "ป่าระกำ"
    },
    {
        "subdistrict_id": "6474",
        "district_id": "789",
        "subdistrict_name": "ชะเมา"
    },
    {
        "subdistrict_id": "6475",
        "district_id": "789",
        "subdistrict_name": "คลองกระบือ"
    },
    {
        "subdistrict_id": "6476",
        "district_id": "789",
        "subdistrict_name": "เกาะทวด"
    },
    {
        "subdistrict_id": "6477",
        "district_id": "789",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "6478",
        "district_id": "789",
        "subdistrict_name": "หูล่อง"
    },
    {
        "subdistrict_id": "6479",
        "district_id": "789",
        "subdistrict_name": "แหลมตะลุมพุก"
    },
    {
        "subdistrict_id": "6480",
        "district_id": "789",
        "subdistrict_name": "ปากพนังฝั่งตะวันตก"
    },
    {
        "subdistrict_id": "6481",
        "district_id": "789",
        "subdistrict_name": "บางศาลา"
    },
    {
        "subdistrict_id": "6482",
        "district_id": "789",
        "subdistrict_name": "บางพระ"
    },
    {
        "subdistrict_id": "6483",
        "district_id": "789",
        "subdistrict_name": "บางตะพง"
    },
    {
        "subdistrict_id": "6484",
        "district_id": "789",
        "subdistrict_name": "ปากพนังฝั่งตะวันออก"
    },
    {
        "subdistrict_id": "6485",
        "district_id": "789",
        "subdistrict_name": "บ้านเพิง"
    },
    {
        "subdistrict_id": "6486",
        "district_id": "789",
        "subdistrict_name": "ท่าพยา"
    },
    {
        "subdistrict_id": "6487",
        "district_id": "789",
        "subdistrict_name": "ปากแพรก"
    },
    {
        "subdistrict_id": "6488",
        "district_id": "789",
        "subdistrict_name": "ขนาบนาก"
    },
    {
        "subdistrict_id": "6489",
        "district_id": "790",
        "subdistrict_name": "ร่อนพิบูลย์"
    },
    {
        "subdistrict_id": "6490",
        "district_id": "790",
        "subdistrict_name": "หินตก"
    },
    {
        "subdistrict_id": "6491",
        "district_id": "790",
        "subdistrict_name": "เสาธง"
    },
    {
        "subdistrict_id": "6492",
        "district_id": "790",
        "subdistrict_name": "ควนเกย"
    },
    {
        "subdistrict_id": "6493",
        "district_id": "790",
        "subdistrict_name": "ควนพัง"
    },
    {
        "subdistrict_id": "6494",
        "district_id": "790",
        "subdistrict_name": "ควนชุม"
    },
    {
        "subdistrict_id": "6495",
        "district_id": "791",
        "subdistrict_name": "สิชล"
    },
    {
        "subdistrict_id": "6496",
        "district_id": "791",
        "subdistrict_name": "ทุ่งปรัง"
    },
    {
        "subdistrict_id": "6497",
        "district_id": "791",
        "subdistrict_name": "ฉลอง"
    },
    {
        "subdistrict_id": "6498",
        "district_id": "791",
        "subdistrict_name": "เสาเภา"
    },
    {
        "subdistrict_id": "6499",
        "district_id": "791",
        "subdistrict_name": "เปลี่ยน"
    },
    {
        "subdistrict_id": "6500",
        "district_id": "791",
        "subdistrict_name": "สี่ขีด"
    },
    {
        "subdistrict_id": "6501",
        "district_id": "791",
        "subdistrict_name": "เทพราช"
    },
    {
        "subdistrict_id": "6502",
        "district_id": "791",
        "subdistrict_name": "เขาน้อย"
    },
    {
        "subdistrict_id": "6503",
        "district_id": "791",
        "subdistrict_name": "ทุ่งใส"
    },
    {
        "subdistrict_id": "6504",
        "district_id": "792",
        "subdistrict_name": "ขนอม"
    },
    {
        "subdistrict_id": "6505",
        "district_id": "792",
        "subdistrict_name": "ควนทอง"
    },
    {
        "subdistrict_id": "6506",
        "district_id": "792",
        "subdistrict_name": "ท้องเนียน"
    },
    {
        "subdistrict_id": "6507",
        "district_id": "793",
        "subdistrict_name": "หัวไทร"
    },
    {
        "subdistrict_id": "6508",
        "district_id": "793",
        "subdistrict_name": "หน้าสตน"
    },
    {
        "subdistrict_id": "6509",
        "district_id": "793",
        "subdistrict_name": "ทรายขาว"
    },
    {
        "subdistrict_id": "6510",
        "district_id": "793",
        "subdistrict_name": "แหลม"
    },
    {
        "subdistrict_id": "6511",
        "district_id": "793",
        "subdistrict_name": "เขาพังไกร"
    },
    {
        "subdistrict_id": "6512",
        "district_id": "793",
        "subdistrict_name": "บ้านราม"
    },
    {
        "subdistrict_id": "6513",
        "district_id": "793",
        "subdistrict_name": "บางนบ"
    },
    {
        "subdistrict_id": "6514",
        "district_id": "793",
        "subdistrict_name": "ท่าซอม"
    },
    {
        "subdistrict_id": "6515",
        "district_id": "793",
        "subdistrict_name": "ควนชะลิก"
    },
    {
        "subdistrict_id": "6516",
        "district_id": "793",
        "subdistrict_name": "รามแก้ว"
    },
    {
        "subdistrict_id": "6517",
        "district_id": "793",
        "subdistrict_name": "เกาะเพชร"
    },
    {
        "subdistrict_id": "6518",
        "district_id": "794",
        "subdistrict_name": "บางขัน"
    },
    {
        "subdistrict_id": "6519",
        "district_id": "794",
        "subdistrict_name": "บ้านลำนาว"
    },
    {
        "subdistrict_id": "6520",
        "district_id": "794",
        "subdistrict_name": "วังหิน"
    },
    {
        "subdistrict_id": "6521",
        "district_id": "794",
        "subdistrict_name": "บ้านนิคม"
    },
    {
        "subdistrict_id": "6522",
        "district_id": "795",
        "subdistrict_name": "ถ้ำพรรณรา"
    },
    {
        "subdistrict_id": "6523",
        "district_id": "795",
        "subdistrict_name": "คลองเส"
    },
    {
        "subdistrict_id": "6524",
        "district_id": "795",
        "subdistrict_name": "ดุสิต"
    },
    {
        "subdistrict_id": "6525",
        "district_id": "796",
        "subdistrict_name": "บ้านควนมุด"
    },
    {
        "subdistrict_id": "6526",
        "district_id": "796",
        "subdistrict_name": "บ้านชะอวด"
    },
    {
        "subdistrict_id": "6527",
        "district_id": "796",
        "subdistrict_name": "ควนหนองคว้า"
    },
    {
        "subdistrict_id": "6528",
        "district_id": "796",
        "subdistrict_name": "ทุ่งโพธิ์"
    },
    {
        "subdistrict_id": "6529",
        "district_id": "796",
        "subdistrict_name": "นาหมอบุญ"
    },
    {
        "subdistrict_id": "6530",
        "district_id": "796",
        "subdistrict_name": "สามตำบล"
    },
    {
        "subdistrict_id": "6531",
        "district_id": "797",
        "subdistrict_name": "นาพรุ"
    },
    {
        "subdistrict_id": "6532",
        "district_id": "797",
        "subdistrict_name": "นาสาร"
    },
    {
        "subdistrict_id": "6533",
        "district_id": "797",
        "subdistrict_name": "ท้ายสำเภา"
    },
    {
        "subdistrict_id": "6534",
        "district_id": "797",
        "subdistrict_name": "ช้างซ้าย"
    },
    {
        "subdistrict_id": "6535",
        "district_id": "798",
        "subdistrict_name": "นบพิตำ"
    },
    {
        "subdistrict_id": "6536",
        "district_id": "798",
        "subdistrict_name": "กรุงชิง"
    },
    {
        "subdistrict_id": "6537",
        "district_id": "798",
        "subdistrict_name": "กะหรอ"
    },
    {
        "subdistrict_id": "6538",
        "district_id": "798",
        "subdistrict_name": "นาเหรง"
    },
    {
        "subdistrict_id": "6539",
        "district_id": "799",
        "subdistrict_name": "ช้างกลาง"
    },
    {
        "subdistrict_id": "6540",
        "district_id": "799",
        "subdistrict_name": "หลักช้าง"
    },
    {
        "subdistrict_id": "6541",
        "district_id": "799",
        "subdistrict_name": "สวนขัน"
    },
    {
        "subdistrict_id": "6542",
        "district_id": "800",
        "subdistrict_name": "เชียรเขา"
    },
    {
        "subdistrict_id": "6543",
        "district_id": "800",
        "subdistrict_name": "ดอนตรอ"
    },
    {
        "subdistrict_id": "6544",
        "district_id": "800",
        "subdistrict_name": "สวนหลวง"
    },
    {
        "subdistrict_id": "6545",
        "district_id": "800",
        "subdistrict_name": "ทางพูน"
    },
    {
        "subdistrict_id": "6546",
        "district_id": "801",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "6547",
        "district_id": "801",
        "subdistrict_name": "กระบี่ใหญ่"
    },
    {
        "subdistrict_id": "6548",
        "district_id": "801",
        "subdistrict_name": "กระบี่น้อย"
    },
    {
        "subdistrict_id": "6549",
        "district_id": "801",
        "subdistrict_name": "เขาคราม"
    },
    {
        "subdistrict_id": "6550",
        "district_id": "801",
        "subdistrict_name": "เขาทอง"
    },
    {
        "subdistrict_id": "6551",
        "district_id": "801",
        "subdistrict_name": "ทับปริก"
    },
    {
        "subdistrict_id": "6552",
        "district_id": "801",
        "subdistrict_name": "ไสไทย"
    },
    {
        "subdistrict_id": "6553",
        "district_id": "801",
        "subdistrict_name": "อ่าวนาง"
    },
    {
        "subdistrict_id": "6554",
        "district_id": "801",
        "subdistrict_name": "หนองทะเล"
    },
    {
        "subdistrict_id": "6555",
        "district_id": "801",
        "subdistrict_name": "คลองประสงค์"
    },
    {
        "subdistrict_id": "6556",
        "district_id": "802",
        "subdistrict_name": "เขาพนม"
    },
    {
        "subdistrict_id": "6557",
        "district_id": "802",
        "subdistrict_name": "เขาดิน"
    },
    {
        "subdistrict_id": "6558",
        "district_id": "802",
        "subdistrict_name": "สินปุน"
    },
    {
        "subdistrict_id": "6559",
        "district_id": "802",
        "subdistrict_name": "พรุเตียว"
    },
    {
        "subdistrict_id": "6560",
        "district_id": "802",
        "subdistrict_name": "หน้าเขา"
    },
    {
        "subdistrict_id": "6561",
        "district_id": "802",
        "subdistrict_name": "โคกหาร"
    },
    {
        "subdistrict_id": "6562",
        "district_id": "803",
        "subdistrict_name": "เกาะลันตาใหญ่"
    },
    {
        "subdistrict_id": "6563",
        "district_id": "803",
        "subdistrict_name": "เกาะลันตาน้อย"
    },
    {
        "subdistrict_id": "6564",
        "district_id": "803",
        "subdistrict_name": "เกาะกลาง"
    },
    {
        "subdistrict_id": "6565",
        "district_id": "803",
        "subdistrict_name": "คลองยาง"
    },
    {
        "subdistrict_id": "6566",
        "district_id": "803",
        "subdistrict_name": "ศาลาด่าน"
    },
    {
        "subdistrict_id": "6567",
        "district_id": "804",
        "subdistrict_name": "คลองท่อมใต้"
    },
    {
        "subdistrict_id": "6568",
        "district_id": "804",
        "subdistrict_name": "คลองท่อมเหนือ"
    },
    {
        "subdistrict_id": "6569",
        "district_id": "804",
        "subdistrict_name": "คลองพน"
    },
    {
        "subdistrict_id": "6570",
        "district_id": "804",
        "subdistrict_name": "ทรายขาว"
    },
    {
        "subdistrict_id": "6571",
        "district_id": "804",
        "subdistrict_name": "ห้วยน้ำขาว"
    },
    {
        "subdistrict_id": "6572",
        "district_id": "804",
        "subdistrict_name": "พรุดินนา"
    },
    {
        "subdistrict_id": "6573",
        "district_id": "804",
        "subdistrict_name": "เพหลา"
    },
    {
        "subdistrict_id": "6574",
        "district_id": "805",
        "subdistrict_name": "อ่าวลึกใต้"
    },
    {
        "subdistrict_id": "6575",
        "district_id": "805",
        "subdistrict_name": "แหลมสัก"
    },
    {
        "subdistrict_id": "6576",
        "district_id": "805",
        "subdistrict_name": "นาเหนือ"
    },
    {
        "subdistrict_id": "6577",
        "district_id": "805",
        "subdistrict_name": "คลองหิน"
    },
    {
        "subdistrict_id": "6578",
        "district_id": "805",
        "subdistrict_name": "อ่าวลึกน้อย"
    },
    {
        "subdistrict_id": "6579",
        "district_id": "805",
        "subdistrict_name": "อ่าวลึกเหนือ"
    },
    {
        "subdistrict_id": "6580",
        "district_id": "805",
        "subdistrict_name": "เขาใหญ่"
    },
    {
        "subdistrict_id": "6581",
        "district_id": "805",
        "subdistrict_name": "คลองยา"
    },
    {
        "subdistrict_id": "6582",
        "district_id": "805",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "6583",
        "district_id": "806",
        "subdistrict_name": "ปลายพระยา"
    },
    {
        "subdistrict_id": "6584",
        "district_id": "806",
        "subdistrict_name": "เขาเขน"
    },
    {
        "subdistrict_id": "6585",
        "district_id": "806",
        "subdistrict_name": "เขาต่อ"
    },
    {
        "subdistrict_id": "6586",
        "district_id": "806",
        "subdistrict_name": "คีรีวง"
    },
    {
        "subdistrict_id": "6587",
        "district_id": "807",
        "subdistrict_name": "ลำทับ"
    },
    {
        "subdistrict_id": "6588",
        "district_id": "807",
        "subdistrict_name": "ดินอุดม"
    },
    {
        "subdistrict_id": "6589",
        "district_id": "807",
        "subdistrict_name": "ทุ่งไทรทอง"
    },
    {
        "subdistrict_id": "6590",
        "district_id": "807",
        "subdistrict_name": "ดินแดง"
    },
    {
        "subdistrict_id": "6591",
        "district_id": "808",
        "subdistrict_name": "เหนือคลอง"
    },
    {
        "subdistrict_id": "6592",
        "district_id": "808",
        "subdistrict_name": "เกาะศรีบอยา"
    },
    {
        "subdistrict_id": "6593",
        "district_id": "808",
        "subdistrict_name": "คลองขนาน"
    },
    {
        "subdistrict_id": "6594",
        "district_id": "808",
        "subdistrict_name": "คลองเขม้า"
    },
    {
        "subdistrict_id": "6595",
        "district_id": "808",
        "subdistrict_name": "โคกยาง"
    },
    {
        "subdistrict_id": "6596",
        "district_id": "808",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "6597",
        "district_id": "808",
        "subdistrict_name": "ปกาสัย"
    },
    {
        "subdistrict_id": "6598",
        "district_id": "808",
        "subdistrict_name": "ห้วยยูง"
    },
    {
        "subdistrict_id": "6599",
        "district_id": "809",
        "subdistrict_name": "ท้ายช้าง"
    },
    {
        "subdistrict_id": "6600",
        "district_id": "809",
        "subdistrict_name": "นบปริง"
    },
    {
        "subdistrict_id": "6601",
        "district_id": "809",
        "subdistrict_name": "ถ้ำน้ำผุด"
    },
    {
        "subdistrict_id": "6602",
        "district_id": "809",
        "subdistrict_name": "บางเตย"
    },
    {
        "subdistrict_id": "6603",
        "district_id": "809",
        "subdistrict_name": "ตากแดด"
    },
    {
        "subdistrict_id": "6604",
        "district_id": "809",
        "subdistrict_name": "สองแพรก"
    },
    {
        "subdistrict_id": "6605",
        "district_id": "809",
        "subdistrict_name": "ทุ่งคาโงก"
    },
    {
        "subdistrict_id": "6606",
        "district_id": "809",
        "subdistrict_name": "เกาะปันหยี"
    },
    {
        "subdistrict_id": "6607",
        "district_id": "809",
        "subdistrict_name": "ป่ากอ"
    },
    {
        "subdistrict_id": "6608",
        "district_id": "810",
        "subdistrict_name": "เกาะยาวน้อย"
    },
    {
        "subdistrict_id": "6609",
        "district_id": "810",
        "subdistrict_name": "เกาะยาวใหญ่"
    },
    {
        "subdistrict_id": "6610",
        "district_id": "810",
        "subdistrict_name": "พรุใน"
    },
    {
        "subdistrict_id": "6611",
        "district_id": "811",
        "subdistrict_name": "กะปง"
    },
    {
        "subdistrict_id": "6612",
        "district_id": "811",
        "subdistrict_name": "ท่านา"
    },
    {
        "subdistrict_id": "6613",
        "district_id": "811",
        "subdistrict_name": "เหมาะ"
    },
    {
        "subdistrict_id": "6614",
        "district_id": "811",
        "subdistrict_name": "เหล"
    },
    {
        "subdistrict_id": "6615",
        "district_id": "811",
        "subdistrict_name": "รมณีย์"
    },
    {
        "subdistrict_id": "6616",
        "district_id": "812",
        "subdistrict_name": "ถ้ำ"
    },
    {
        "subdistrict_id": "6617",
        "district_id": "812",
        "subdistrict_name": "กระโสม"
    },
    {
        "subdistrict_id": "6618",
        "district_id": "812",
        "subdistrict_name": "กะไหล"
    },
    {
        "subdistrict_id": "6619",
        "district_id": "812",
        "subdistrict_name": "ท่าอยู่"
    },
    {
        "subdistrict_id": "6620",
        "district_id": "812",
        "subdistrict_name": "หล่อยูง"
    },
    {
        "subdistrict_id": "6621",
        "district_id": "812",
        "subdistrict_name": "โคกกลอย"
    },
    {
        "subdistrict_id": "6622",
        "district_id": "812",
        "subdistrict_name": "คลองเคียน"
    },
    {
        "subdistrict_id": "6623",
        "district_id": "813",
        "subdistrict_name": "ตะกั่วป่า"
    },
    {
        "subdistrict_id": "6624",
        "district_id": "813",
        "subdistrict_name": "บางนายสี"
    },
    {
        "subdistrict_id": "6625",
        "district_id": "813",
        "subdistrict_name": "บางไทร"
    },
    {
        "subdistrict_id": "6626",
        "district_id": "813",
        "subdistrict_name": "บางม่วง"
    },
    {
        "subdistrict_id": "6627",
        "district_id": "813",
        "subdistrict_name": "ตำตัว"
    },
    {
        "subdistrict_id": "6628",
        "district_id": "813",
        "subdistrict_name": "โคกเคียน"
    },
    {
        "subdistrict_id": "6629",
        "district_id": "813",
        "subdistrict_name": "คึกคัก"
    },
    {
        "subdistrict_id": "6630",
        "district_id": "813",
        "subdistrict_name": "เกาะคอเขา"
    },
    {
        "subdistrict_id": "6631",
        "district_id": "814",
        "subdistrict_name": "คุระ"
    },
    {
        "subdistrict_id": "6632",
        "district_id": "814",
        "subdistrict_name": "บางวัน"
    },
    {
        "subdistrict_id": "6633",
        "district_id": "814",
        "subdistrict_name": "เกาะพระทอง"
    },
    {
        "subdistrict_id": "6634",
        "district_id": "814",
        "subdistrict_name": "แม่นางขาว"
    },
    {
        "subdistrict_id": "6635",
        "district_id": "815",
        "subdistrict_name": "ทับปุด"
    },
    {
        "subdistrict_id": "6636",
        "district_id": "815",
        "subdistrict_name": "มะรุ่ย"
    },
    {
        "subdistrict_id": "6637",
        "district_id": "815",
        "subdistrict_name": "บ่อแสน"
    },
    {
        "subdistrict_id": "6638",
        "district_id": "815",
        "subdistrict_name": "ถ้ำทองหลาง"
    },
    {
        "subdistrict_id": "6639",
        "district_id": "815",
        "subdistrict_name": "โคกเจริญ"
    },
    {
        "subdistrict_id": "6640",
        "district_id": "815",
        "subdistrict_name": "บางเหรียง"
    },
    {
        "subdistrict_id": "6641",
        "district_id": "816",
        "subdistrict_name": "ท้ายเหมือง"
    },
    {
        "subdistrict_id": "6642",
        "district_id": "816",
        "subdistrict_name": "นาเตย"
    },
    {
        "subdistrict_id": "6643",
        "district_id": "816",
        "subdistrict_name": "บางทอง"
    },
    {
        "subdistrict_id": "6644",
        "district_id": "816",
        "subdistrict_name": "ทุ่งมะพร้าว"
    },
    {
        "subdistrict_id": "6645",
        "district_id": "816",
        "subdistrict_name": "ลำภี"
    },
    {
        "subdistrict_id": "6646",
        "district_id": "816",
        "subdistrict_name": "ลำแก่น"
    },
    {
        "subdistrict_id": "6647",
        "district_id": "817",
        "subdistrict_name": "ตลาดใหญ่"
    },
    {
        "subdistrict_id": "6648",
        "district_id": "817",
        "subdistrict_name": "ตลาดเหนือ"
    },
    {
        "subdistrict_id": "6649",
        "district_id": "817",
        "subdistrict_name": "เกาะแก้ว"
    },
    {
        "subdistrict_id": "6650",
        "district_id": "817",
        "subdistrict_name": "รัษฎา"
    },
    {
        "subdistrict_id": "6651",
        "district_id": "817",
        "subdistrict_name": "วิชิต"
    },
    {
        "subdistrict_id": "6652",
        "district_id": "817",
        "subdistrict_name": "ฉลอง"
    },
    {
        "subdistrict_id": "6653",
        "district_id": "817",
        "subdistrict_name": "ราไวย์"
    },
    {
        "subdistrict_id": "6654",
        "district_id": "817",
        "subdistrict_name": "กะรน"
    },
    {
        "subdistrict_id": "6655",
        "district_id": "818",
        "subdistrict_name": "กะทู้"
    },
    {
        "subdistrict_id": "6656",
        "district_id": "818",
        "subdistrict_name": "ป่าตอง"
    },
    {
        "subdistrict_id": "6657",
        "district_id": "818",
        "subdistrict_name": "กมลา"
    },
    {
        "subdistrict_id": "6658",
        "district_id": "819",
        "subdistrict_name": "เทพกระษัตรี"
    },
    {
        "subdistrict_id": "6659",
        "district_id": "819",
        "subdistrict_name": "ศรีสุนทร"
    },
    {
        "subdistrict_id": "6660",
        "district_id": "819",
        "subdistrict_name": "เชิงทะเล"
    },
    {
        "subdistrict_id": "6661",
        "district_id": "819",
        "subdistrict_name": "ป่าคลอก"
    },
    {
        "subdistrict_id": "6662",
        "district_id": "819",
        "subdistrict_name": "ไม้ขาว"
    },
    {
        "subdistrict_id": "6663",
        "district_id": "819",
        "subdistrict_name": "สาคู"
    },
    {
        "subdistrict_id": "6664",
        "district_id": "820",
        "subdistrict_name": "ตลาด"
    },
    {
        "subdistrict_id": "6665",
        "district_id": "820",
        "subdistrict_name": "มะขามเตี้ย"
    },
    {
        "subdistrict_id": "6666",
        "district_id": "820",
        "subdistrict_name": "วัดประดู่"
    },
    {
        "subdistrict_id": "6667",
        "district_id": "820",
        "subdistrict_name": "ขุนทะเล"
    },
    {
        "subdistrict_id": "6668",
        "district_id": "820",
        "subdistrict_name": "บางใบไม้"
    },
    {
        "subdistrict_id": "6669",
        "district_id": "820",
        "subdistrict_name": "บางชนะ"
    },
    {
        "subdistrict_id": "6670",
        "district_id": "820",
        "subdistrict_name": "คลองน้อย"
    },
    {
        "subdistrict_id": "6671",
        "district_id": "820",
        "subdistrict_name": "บางไทร"
    },
    {
        "subdistrict_id": "6672",
        "district_id": "820",
        "subdistrict_name": "บางโพธิ์"
    },
    {
        "subdistrict_id": "6673",
        "district_id": "820",
        "subdistrict_name": "บางกุ้ง"
    },
    {
        "subdistrict_id": "6674",
        "district_id": "820",
        "subdistrict_name": "คลองฉนาก"
    },
    {
        "subdistrict_id": "6675",
        "district_id": "821",
        "subdistrict_name": "ท่าทองใหม่"
    },
    {
        "subdistrict_id": "6676",
        "district_id": "821",
        "subdistrict_name": "ท่าทอง"
    },
    {
        "subdistrict_id": "6677",
        "district_id": "821",
        "subdistrict_name": "กะแดะ"
    },
    {
        "subdistrict_id": "6678",
        "district_id": "821",
        "subdistrict_name": "ทุ่งกง"
    },
    {
        "subdistrict_id": "6679",
        "district_id": "821",
        "subdistrict_name": "กรูด"
    },
    {
        "subdistrict_id": "6680",
        "district_id": "821",
        "subdistrict_name": "ช้างซ้าย"
    },
    {
        "subdistrict_id": "6681",
        "district_id": "821",
        "subdistrict_name": "พลายวาส"
    },
    {
        "subdistrict_id": "6682",
        "district_id": "821",
        "subdistrict_name": "ป่าร่อน"
    },
    {
        "subdistrict_id": "6683",
        "district_id": "821",
        "subdistrict_name": "ตะเคียนทอง"
    },
    {
        "subdistrict_id": "6684",
        "district_id": "821",
        "subdistrict_name": "ช้างขวา"
    },
    {
        "subdistrict_id": "6685",
        "district_id": "821",
        "subdistrict_name": "ท่าอุแท"
    },
    {
        "subdistrict_id": "6686",
        "district_id": "821",
        "subdistrict_name": "ทุ่งรัง"
    },
    {
        "subdistrict_id": "6687",
        "district_id": "821",
        "subdistrict_name": "คลองสระ"
    },
    {
        "subdistrict_id": "6688",
        "district_id": "822",
        "subdistrict_name": "ดอนสัก"
    },
    {
        "subdistrict_id": "6689",
        "district_id": "822",
        "subdistrict_name": "ชลคราม"
    },
    {
        "subdistrict_id": "6690",
        "district_id": "822",
        "subdistrict_name": "ไชยคราม"
    },
    {
        "subdistrict_id": "6691",
        "district_id": "822",
        "subdistrict_name": "ปากแพรก"
    },
    {
        "subdistrict_id": "6692",
        "district_id": "823",
        "subdistrict_name": "อ่างทอง"
    },
    {
        "subdistrict_id": "6693",
        "district_id": "823",
        "subdistrict_name": "ลิปะน้อย"
    },
    {
        "subdistrict_id": "6694",
        "district_id": "823",
        "subdistrict_name": "ตลิ่งงาม"
    },
    {
        "subdistrict_id": "6695",
        "district_id": "823",
        "subdistrict_name": "หน้าเมือง"
    },
    {
        "subdistrict_id": "6696",
        "district_id": "823",
        "subdistrict_name": "มะเร็ต"
    },
    {
        "subdistrict_id": "6697",
        "district_id": "823",
        "subdistrict_name": "บ่อผุด"
    },
    {
        "subdistrict_id": "6698",
        "district_id": "823",
        "subdistrict_name": "แม่น้ำ"
    },
    {
        "subdistrict_id": "6699",
        "district_id": "824",
        "subdistrict_name": "เกาะพะงัน"
    },
    {
        "subdistrict_id": "6700",
        "district_id": "824",
        "subdistrict_name": "บ้านใต้"
    },
    {
        "subdistrict_id": "6701",
        "district_id": "824",
        "subdistrict_name": "เกาะเต่า"
    },
    {
        "subdistrict_id": "6702",
        "district_id": "825",
        "subdistrict_name": "ตลาดไชยา"
    },
    {
        "subdistrict_id": "6703",
        "district_id": "825",
        "subdistrict_name": "พุมเรียง"
    },
    {
        "subdistrict_id": "6704",
        "district_id": "825",
        "subdistrict_name": "เลม็ด"
    },
    {
        "subdistrict_id": "6705",
        "district_id": "825",
        "subdistrict_name": "เวียง"
    },
    {
        "subdistrict_id": "6706",
        "district_id": "825",
        "subdistrict_name": "ทุ่ง"
    },
    {
        "subdistrict_id": "6707",
        "district_id": "825",
        "subdistrict_name": "ป่าเว"
    },
    {
        "subdistrict_id": "6708",
        "district_id": "825",
        "subdistrict_name": "ตะกรบ"
    },
    {
        "subdistrict_id": "6709",
        "district_id": "825",
        "subdistrict_name": "โมถ่าย"
    },
    {
        "subdistrict_id": "6710",
        "district_id": "825",
        "subdistrict_name": "ปากหมาก"
    },
    {
        "subdistrict_id": "6711",
        "district_id": "826",
        "subdistrict_name": "ท่าชนะ"
    },
    {
        "subdistrict_id": "6712",
        "district_id": "826",
        "subdistrict_name": "สมอทอง"
    },
    {
        "subdistrict_id": "6713",
        "district_id": "826",
        "subdistrict_name": "ประสงค์"
    },
    {
        "subdistrict_id": "6714",
        "district_id": "826",
        "subdistrict_name": "คันธุลี"
    },
    {
        "subdistrict_id": "6715",
        "district_id": "826",
        "subdistrict_name": "วัง"
    },
    {
        "subdistrict_id": "6716",
        "district_id": "826",
        "subdistrict_name": "คลองพา"
    },
    {
        "subdistrict_id": "6717",
        "district_id": "827",
        "subdistrict_name": "ท่าขนอน"
    },
    {
        "subdistrict_id": "6718",
        "district_id": "827",
        "subdistrict_name": "บ้านยาง"
    },
    {
        "subdistrict_id": "6719",
        "district_id": "827",
        "subdistrict_name": "น้ำหัก"
    },
    {
        "subdistrict_id": "6720",
        "district_id": "827",
        "subdistrict_name": "กะเปา"
    },
    {
        "subdistrict_id": "6721",
        "district_id": "827",
        "subdistrict_name": "ท่ากระดาน"
    },
    {
        "subdistrict_id": "6722",
        "district_id": "827",
        "subdistrict_name": "ย่านยาว"
    },
    {
        "subdistrict_id": "6723",
        "district_id": "827",
        "subdistrict_name": "ถ้ำสิงขร"
    },
    {
        "subdistrict_id": "6724",
        "district_id": "827",
        "subdistrict_name": "บ้านทำเนียบ"
    },
    {
        "subdistrict_id": "6725",
        "district_id": "828",
        "subdistrict_name": "เขาวง"
    },
    {
        "subdistrict_id": "6726",
        "district_id": "828",
        "subdistrict_name": "พะแสง"
    },
    {
        "subdistrict_id": "6727",
        "district_id": "828",
        "subdistrict_name": "พรุไทย"
    },
    {
        "subdistrict_id": "6728",
        "district_id": "828",
        "subdistrict_name": "เขาพัง"
    },
    {
        "subdistrict_id": "6729",
        "district_id": "829",
        "subdistrict_name": "พนม"
    },
    {
        "subdistrict_id": "6730",
        "district_id": "829",
        "subdistrict_name": "ต้นยวน"
    },
    {
        "subdistrict_id": "6731",
        "district_id": "829",
        "subdistrict_name": "คลองศก"
    },
    {
        "subdistrict_id": "6732",
        "district_id": "829",
        "subdistrict_name": "พลูเถื่อน"
    },
    {
        "subdistrict_id": "6733",
        "district_id": "829",
        "subdistrict_name": "พังกาญจน์"
    },
    {
        "subdistrict_id": "6734",
        "district_id": "829",
        "subdistrict_name": "คลองชะอุ่น"
    },
    {
        "subdistrict_id": "6735",
        "district_id": "830",
        "subdistrict_name": "ท่าฉาง"
    },
    {
        "subdistrict_id": "6736",
        "district_id": "830",
        "subdistrict_name": "ท่าเคย"
    },
    {
        "subdistrict_id": "6737",
        "district_id": "830",
        "subdistrict_name": "คลองไทร"
    },
    {
        "subdistrict_id": "6738",
        "district_id": "830",
        "subdistrict_name": "เขาถ่าน"
    },
    {
        "subdistrict_id": "6739",
        "district_id": "830",
        "subdistrict_name": "เสวียด"
    },
    {
        "subdistrict_id": "6740",
        "district_id": "830",
        "subdistrict_name": "ปากฉลุย"
    },
    {
        "subdistrict_id": "6741",
        "district_id": "831",
        "subdistrict_name": "นาสาร"
    },
    {
        "subdistrict_id": "6742",
        "district_id": "831",
        "subdistrict_name": "พรุพี"
    },
    {
        "subdistrict_id": "6743",
        "district_id": "831",
        "subdistrict_name": "ทุ่งเตา"
    },
    {
        "subdistrict_id": "6744",
        "district_id": "831",
        "subdistrict_name": "ลำพูน"
    },
    {
        "subdistrict_id": "6745",
        "district_id": "831",
        "subdistrict_name": "ท่าชี"
    },
    {
        "subdistrict_id": "6746",
        "district_id": "831",
        "subdistrict_name": "ควนศรี"
    },
    {
        "subdistrict_id": "6747",
        "district_id": "831",
        "subdistrict_name": "ควนสุบรรณ"
    },
    {
        "subdistrict_id": "6748",
        "district_id": "831",
        "subdistrict_name": "คลองปราบ"
    },
    {
        "subdistrict_id": "6749",
        "district_id": "831",
        "subdistrict_name": "น้ำพุ"
    },
    {
        "subdistrict_id": "6750",
        "district_id": "831",
        "subdistrict_name": "ทุ่งเตาใหม่"
    },
    {
        "subdistrict_id": "6751",
        "district_id": "831",
        "subdistrict_name": "เพิ่มพูนทรัพย์"
    },
    {
        "subdistrict_id": "6752",
        "district_id": "832",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "6753",
        "district_id": "832",
        "subdistrict_name": "ท่าเรือ"
    },
    {
        "subdistrict_id": "6754",
        "district_id": "832",
        "subdistrict_name": "ทรัพย์ทวี"
    },
    {
        "subdistrict_id": "6755",
        "district_id": "832",
        "subdistrict_name": "นาใต้"
    },
    {
        "subdistrict_id": "6756",
        "district_id": "833",
        "subdistrict_name": "เคียนซา"
    },
    {
        "subdistrict_id": "6757",
        "district_id": "833",
        "subdistrict_name": "พ่วงพรมคร"
    },
    {
        "subdistrict_id": "6758",
        "district_id": "833",
        "subdistrict_name": "เขาตอก"
    },
    {
        "subdistrict_id": "6759",
        "district_id": "833",
        "subdistrict_name": "อรัญคามวารี"
    },
    {
        "subdistrict_id": "6760",
        "district_id": "833",
        "subdistrict_name": "บ้านเสด็จ"
    },
    {
        "subdistrict_id": "6761",
        "district_id": "834",
        "subdistrict_name": "เวียงสระ"
    },
    {
        "subdistrict_id": "6762",
        "district_id": "834",
        "subdistrict_name": "บ้านส้อง"
    },
    {
        "subdistrict_id": "6763",
        "district_id": "834",
        "subdistrict_name": "คลองฉนวน"
    },
    {
        "subdistrict_id": "6764",
        "district_id": "834",
        "subdistrict_name": "ทุ่งหลวง"
    },
    {
        "subdistrict_id": "6765",
        "district_id": "834",
        "subdistrict_name": "เขานิพันธ์"
    },
    {
        "subdistrict_id": "6766",
        "district_id": "835",
        "subdistrict_name": "อิปัน"
    },
    {
        "subdistrict_id": "6767",
        "district_id": "835",
        "subdistrict_name": "สินปุน"
    },
    {
        "subdistrict_id": "6768",
        "district_id": "835",
        "subdistrict_name": "บางสวรรค์"
    },
    {
        "subdistrict_id": "6769",
        "district_id": "835",
        "subdistrict_name": "ไทรขึง"
    },
    {
        "subdistrict_id": "6770",
        "district_id": "835",
        "subdistrict_name": "สินเจริญ"
    },
    {
        "subdistrict_id": "6771",
        "district_id": "835",
        "subdistrict_name": "ไทรโสภา"
    },
    {
        "subdistrict_id": "6772",
        "district_id": "835",
        "subdistrict_name": "สาคู"
    },
    {
        "subdistrict_id": "6773",
        "district_id": "836",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "6774",
        "district_id": "836",
        "subdistrict_name": "ท่าสะท้อน"
    },
    {
        "subdistrict_id": "6775",
        "district_id": "836",
        "subdistrict_name": "ลีเล็ด"
    },
    {
        "subdistrict_id": "6776",
        "district_id": "836",
        "subdistrict_name": "บางมะเดื่อ"
    },
    {
        "subdistrict_id": "6777",
        "district_id": "836",
        "subdistrict_name": "บางเดือน"
    },
    {
        "subdistrict_id": "6778",
        "district_id": "836",
        "subdistrict_name": "ท่าโรงช้าง"
    },
    {
        "subdistrict_id": "6779",
        "district_id": "836",
        "subdistrict_name": "กรูด"
    },
    {
        "subdistrict_id": "6780",
        "district_id": "836",
        "subdistrict_name": "พุนพิน"
    },
    {
        "subdistrict_id": "6781",
        "district_id": "836",
        "subdistrict_name": "บางงอน"
    },
    {
        "subdistrict_id": "6782",
        "district_id": "836",
        "subdistrict_name": "ศรีวิชัย"
    },
    {
        "subdistrict_id": "6783",
        "district_id": "836",
        "subdistrict_name": "น้ำรอบ"
    },
    {
        "subdistrict_id": "6784",
        "district_id": "836",
        "subdistrict_name": "มะลวน"
    },
    {
        "subdistrict_id": "6785",
        "district_id": "836",
        "subdistrict_name": "หัวเตย"
    },
    {
        "subdistrict_id": "6786",
        "district_id": "836",
        "subdistrict_name": "หนองไทร"
    },
    {
        "subdistrict_id": "6787",
        "district_id": "836",
        "subdistrict_name": "เขาหัวควาย"
    },
    {
        "subdistrict_id": "6788",
        "district_id": "836",
        "subdistrict_name": "ตะปาน"
    },
    {
        "subdistrict_id": "6789",
        "district_id": "837",
        "subdistrict_name": "สองแพรก"
    },
    {
        "subdistrict_id": "6790",
        "district_id": "837",
        "subdistrict_name": "ชัยบุรี"
    },
    {
        "subdistrict_id": "6791",
        "district_id": "837",
        "subdistrict_name": "คลองน้อย"
    },
    {
        "subdistrict_id": "6792",
        "district_id": "837",
        "subdistrict_name": "ไทรทอง"
    },
    {
        "subdistrict_id": "6793",
        "district_id": "838",
        "subdistrict_name": "ตะกุกใต้"
    },
    {
        "subdistrict_id": "6794",
        "district_id": "838",
        "subdistrict_name": "ตะกุกเหนือ"
    },
    {
        "subdistrict_id": "6795",
        "district_id": "840",
        "subdistrict_name": "เขานิเวศน์"
    },
    {
        "subdistrict_id": "6796",
        "district_id": "840",
        "subdistrict_name": "ราชกรูด"
    },
    {
        "subdistrict_id": "6797",
        "district_id": "840",
        "subdistrict_name": "หงาว"
    },
    {
        "subdistrict_id": "6798",
        "district_id": "840",
        "subdistrict_name": "บางริ้น"
    },
    {
        "subdistrict_id": "6799",
        "district_id": "840",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "6800",
        "district_id": "840",
        "subdistrict_name": "บางนอน"
    },
    {
        "subdistrict_id": "6801",
        "district_id": "840",
        "subdistrict_name": "หาดส้มแป้น"
    },
    {
        "subdistrict_id": "6802",
        "district_id": "840",
        "subdistrict_name": "ทรายแดง"
    },
    {
        "subdistrict_id": "6803",
        "district_id": "840",
        "subdistrict_name": "เกาะพยาม"
    },
    {
        "subdistrict_id": "6804",
        "district_id": "841",
        "subdistrict_name": "ละอุ่นใต้"
    },
    {
        "subdistrict_id": "6805",
        "district_id": "841",
        "subdistrict_name": "ละอุ่นเหนือ"
    },
    {
        "subdistrict_id": "6806",
        "district_id": "841",
        "subdistrict_name": "บางพระใต้"
    },
    {
        "subdistrict_id": "6807",
        "district_id": "841",
        "subdistrict_name": "บางพระเหนือ"
    },
    {
        "subdistrict_id": "6808",
        "district_id": "841",
        "subdistrict_name": "บางแก้ว"
    },
    {
        "subdistrict_id": "6809",
        "district_id": "841",
        "subdistrict_name": "ในวงเหนือ"
    },
    {
        "subdistrict_id": "6810",
        "district_id": "841",
        "subdistrict_name": "ในวงใต้"
    },
    {
        "subdistrict_id": "6811",
        "district_id": "842",
        "subdistrict_name": "ม่วงกลวง"
    },
    {
        "subdistrict_id": "6812",
        "district_id": "842",
        "subdistrict_name": "กะเปอร์"
    },
    {
        "subdistrict_id": "6813",
        "district_id": "842",
        "subdistrict_name": "เชี่ยวเหลียง"
    },
    {
        "subdistrict_id": "6814",
        "district_id": "842",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "6815",
        "district_id": "842",
        "subdistrict_name": "บางหิน"
    },
    {
        "subdistrict_id": "6816",
        "district_id": "843",
        "subdistrict_name": "น้ำจืด"
    },
    {
        "subdistrict_id": "6817",
        "district_id": "843",
        "subdistrict_name": "น้ำจืดน้อย"
    },
    {
        "subdistrict_id": "6818",
        "district_id": "843",
        "subdistrict_name": "มะมุ"
    },
    {
        "subdistrict_id": "6819",
        "district_id": "843",
        "subdistrict_name": "ปากจั่น"
    },
    {
        "subdistrict_id": "6820",
        "district_id": "843",
        "subdistrict_name": "ลำเลียง"
    },
    {
        "subdistrict_id": "6821",
        "district_id": "843",
        "subdistrict_name": "จ.ป.ร."
    },
    {
        "subdistrict_id": "6822",
        "district_id": "843",
        "subdistrict_name": "บางใหญ่"
    },
    {
        "subdistrict_id": "6823",
        "district_id": "844",
        "subdistrict_name": "นาคา"
    },
    {
        "subdistrict_id": "6824",
        "district_id": "844",
        "subdistrict_name": "กำพวน"
    },
    {
        "subdistrict_id": "6825",
        "district_id": "845",
        "subdistrict_name": "ท่าตะเภา"
    },
    {
        "subdistrict_id": "6826",
        "district_id": "845",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "6827",
        "district_id": "845",
        "subdistrict_name": "ท่ายาง"
    },
    {
        "subdistrict_id": "6828",
        "district_id": "845",
        "subdistrict_name": "บางหมาก"
    },
    {
        "subdistrict_id": "6829",
        "district_id": "845",
        "subdistrict_name": "นาทุ่ง"
    },
    {
        "subdistrict_id": "6830",
        "district_id": "845",
        "subdistrict_name": "นาชะอัง"
    },
    {
        "subdistrict_id": "6831",
        "district_id": "845",
        "subdistrict_name": "ตากแดด"
    },
    {
        "subdistrict_id": "6832",
        "district_id": "845",
        "subdistrict_name": "บางลึก"
    },
    {
        "subdistrict_id": "6833",
        "district_id": "845",
        "subdistrict_name": "หาดพันไกร"
    },
    {
        "subdistrict_id": "6834",
        "district_id": "845",
        "subdistrict_name": "วังไผ่"
    },
    {
        "subdistrict_id": "6835",
        "district_id": "845",
        "subdistrict_name": "วังใหม่"
    },
    {
        "subdistrict_id": "6836",
        "district_id": "845",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "6837",
        "district_id": "845",
        "subdistrict_name": "ขุนกระทิง"
    },
    {
        "subdistrict_id": "6838",
        "district_id": "845",
        "subdistrict_name": "ทุ่งคา"
    },
    {
        "subdistrict_id": "6839",
        "district_id": "845",
        "subdistrict_name": "วิสัยเหนือ"
    },
    {
        "subdistrict_id": "6840",
        "district_id": "845",
        "subdistrict_name": "หาดทรายรี"
    },
    {
        "subdistrict_id": "6841",
        "district_id": "845",
        "subdistrict_name": "ถ้ำสิงห์"
    },
    {
        "subdistrict_id": "6842",
        "district_id": "846",
        "subdistrict_name": "ท่าแซะ"
    },
    {
        "subdistrict_id": "6843",
        "district_id": "846",
        "subdistrict_name": "คุริง"
    },
    {
        "subdistrict_id": "6844",
        "district_id": "846",
        "subdistrict_name": "สลุย"
    },
    {
        "subdistrict_id": "6845",
        "district_id": "846",
        "subdistrict_name": "นากระตาม"
    },
    {
        "subdistrict_id": "6846",
        "district_id": "846",
        "subdistrict_name": "รับร่อ"
    },
    {
        "subdistrict_id": "6847",
        "district_id": "846",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "6848",
        "district_id": "846",
        "subdistrict_name": "หงษ์เจริญ"
    },
    {
        "subdistrict_id": "6849",
        "district_id": "846",
        "subdistrict_name": "หินแก้ว"
    },
    {
        "subdistrict_id": "6850",
        "district_id": "846",
        "subdistrict_name": "ทรัพย์อนันต์"
    },
    {
        "subdistrict_id": "6851",
        "district_id": "846",
        "subdistrict_name": "สองพี่น้อง"
    },
    {
        "subdistrict_id": "6852",
        "district_id": "847",
        "subdistrict_name": "บางสน"
    },
    {
        "subdistrict_id": "6853",
        "district_id": "847",
        "subdistrict_name": "ทะเลทรัพย์"
    },
    {
        "subdistrict_id": "6854",
        "district_id": "847",
        "subdistrict_name": "สะพลี"
    },
    {
        "subdistrict_id": "6855",
        "district_id": "847",
        "subdistrict_name": "ชุมโค"
    },
    {
        "subdistrict_id": "6856",
        "district_id": "847",
        "subdistrict_name": "ดอนยาง"
    },
    {
        "subdistrict_id": "6857",
        "district_id": "847",
        "subdistrict_name": "ปากคลอง"
    },
    {
        "subdistrict_id": "6858",
        "district_id": "847",
        "subdistrict_name": "เขาไชยราช"
    },
    {
        "subdistrict_id": "6859",
        "district_id": "848",
        "subdistrict_name": "หลังสวน"
    },
    {
        "subdistrict_id": "6860",
        "district_id": "848",
        "subdistrict_name": "ขันเงิน"
    },
    {
        "subdistrict_id": "6861",
        "district_id": "848",
        "subdistrict_name": "ท่ามะพลา"
    },
    {
        "subdistrict_id": "6862",
        "district_id": "848",
        "subdistrict_name": "นาขา"
    },
    {
        "subdistrict_id": "6863",
        "district_id": "848",
        "subdistrict_name": "นาพญา"
    },
    {
        "subdistrict_id": "6864",
        "district_id": "848",
        "subdistrict_name": "บ้านควน"
    },
    {
        "subdistrict_id": "6865",
        "district_id": "848",
        "subdistrict_name": "บางมะพร้าว"
    },
    {
        "subdistrict_id": "6866",
        "district_id": "848",
        "subdistrict_name": "บางน้ำจืด"
    },
    {
        "subdistrict_id": "6867",
        "district_id": "848",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "6868",
        "district_id": "848",
        "subdistrict_name": "พ้อแดง"
    },
    {
        "subdistrict_id": "6869",
        "district_id": "848",
        "subdistrict_name": "แหลมทราย"
    },
    {
        "subdistrict_id": "6870",
        "district_id": "848",
        "subdistrict_name": "วังตะกอ"
    },
    {
        "subdistrict_id": "6871",
        "district_id": "848",
        "subdistrict_name": "หาดยาย"
    },
    {
        "subdistrict_id": "6872",
        "district_id": "849",
        "subdistrict_name": "ละแม"
    },
    {
        "subdistrict_id": "6873",
        "district_id": "849",
        "subdistrict_name": "ทุ่งหลวง"
    },
    {
        "subdistrict_id": "6874",
        "district_id": "849",
        "subdistrict_name": "สวนแตง"
    },
    {
        "subdistrict_id": "6875",
        "district_id": "849",
        "subdistrict_name": "ทุ่งคาวัด"
    },
    {
        "subdistrict_id": "6876",
        "district_id": "850",
        "subdistrict_name": "พะโต๊ะ"
    },
    {
        "subdistrict_id": "6877",
        "district_id": "850",
        "subdistrict_name": "ปากทรง"
    },
    {
        "subdistrict_id": "6878",
        "district_id": "850",
        "subdistrict_name": "ปังหวาน"
    },
    {
        "subdistrict_id": "6879",
        "district_id": "850",
        "subdistrict_name": "พระรักษ์"
    },
    {
        "subdistrict_id": "6880",
        "district_id": "851",
        "subdistrict_name": "นาโพธิ์"
    },
    {
        "subdistrict_id": "6881",
        "district_id": "851",
        "subdistrict_name": "สวี"
    },
    {
        "subdistrict_id": "6882",
        "district_id": "851",
        "subdistrict_name": "ทุ่งระยะ"
    },
    {
        "subdistrict_id": "6883",
        "district_id": "851",
        "subdistrict_name": "ท่าหิน"
    },
    {
        "subdistrict_id": "6884",
        "district_id": "851",
        "subdistrict_name": "ปากแพรก"
    },
    {
        "subdistrict_id": "6885",
        "district_id": "851",
        "subdistrict_name": "ด่านสวี"
    },
    {
        "subdistrict_id": "6886",
        "district_id": "851",
        "subdistrict_name": "ครน"
    },
    {
        "subdistrict_id": "6887",
        "district_id": "851",
        "subdistrict_name": "วิสัยใต้"
    },
    {
        "subdistrict_id": "6888",
        "district_id": "851",
        "subdistrict_name": "นาสัก"
    },
    {
        "subdistrict_id": "6889",
        "district_id": "851",
        "subdistrict_name": "เขาทะลุ"
    },
    {
        "subdistrict_id": "6890",
        "district_id": "851",
        "subdistrict_name": "เขาค่าย"
    },
    {
        "subdistrict_id": "6891",
        "district_id": "852",
        "subdistrict_name": "ปากตะโก"
    },
    {
        "subdistrict_id": "6892",
        "district_id": "852",
        "subdistrict_name": "ทุ่งตะไคร"
    },
    {
        "subdistrict_id": "6893",
        "district_id": "852",
        "subdistrict_name": "ตะโก"
    },
    {
        "subdistrict_id": "6894",
        "district_id": "852",
        "subdistrict_name": "ช่องไม้แก้ว"
    },
    {
        "subdistrict_id": "6895",
        "district_id": "853",
        "subdistrict_name": "บ่อยาง"
    },
    {
        "subdistrict_id": "6896",
        "district_id": "853",
        "subdistrict_name": "เขารูปช้าง"
    },
    {
        "subdistrict_id": "6897",
        "district_id": "853",
        "subdistrict_name": "เกาะแต้ว"
    },
    {
        "subdistrict_id": "6898",
        "district_id": "853",
        "subdistrict_name": "พะวง"
    },
    {
        "subdistrict_id": "6899",
        "district_id": "853",
        "subdistrict_name": "ทุ่งหวัง"
    },
    {
        "subdistrict_id": "6900",
        "district_id": "853",
        "subdistrict_name": "เกาะยอ"
    },
    {
        "subdistrict_id": "6901",
        "district_id": "854",
        "subdistrict_name": "จะทิ้งพระ"
    },
    {
        "subdistrict_id": "6902",
        "district_id": "854",
        "subdistrict_name": "กระดังงา"
    },
    {
        "subdistrict_id": "6903",
        "district_id": "854",
        "subdistrict_name": "สนามชัย"
    },
    {
        "subdistrict_id": "6904",
        "district_id": "854",
        "subdistrict_name": "ดีหลวง"
    },
    {
        "subdistrict_id": "6905",
        "district_id": "854",
        "subdistrict_name": "ชุมพล"
    },
    {
        "subdistrict_id": "6906",
        "district_id": "854",
        "subdistrict_name": "คลองรี"
    },
    {
        "subdistrict_id": "6907",
        "district_id": "854",
        "subdistrict_name": "คูขุด"
    },
    {
        "subdistrict_id": "6908",
        "district_id": "854",
        "subdistrict_name": "ท่าหิน"
    },
    {
        "subdistrict_id": "6909",
        "district_id": "854",
        "subdistrict_name": "วัดจันทร์"
    },
    {
        "subdistrict_id": "6910",
        "district_id": "854",
        "subdistrict_name": "บ่อแดง"
    },
    {
        "subdistrict_id": "6911",
        "district_id": "854",
        "subdistrict_name": "บ่อดาน"
    },
    {
        "subdistrict_id": "6912",
        "district_id": "855",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "6913",
        "district_id": "855",
        "subdistrict_name": "ป่าชิง"
    },
    {
        "subdistrict_id": "6914",
        "district_id": "855",
        "subdistrict_name": "สะพานไม้แก่น"
    },
    {
        "subdistrict_id": "6915",
        "district_id": "855",
        "subdistrict_name": "สะกอม"
    },
    {
        "subdistrict_id": "6916",
        "district_id": "855",
        "subdistrict_name": "นาหว้า"
    },
    {
        "subdistrict_id": "6917",
        "district_id": "855",
        "subdistrict_name": "นาทับ"
    },
    {
        "subdistrict_id": "6918",
        "district_id": "855",
        "subdistrict_name": "น้ำขาว"
    },
    {
        "subdistrict_id": "6919",
        "district_id": "855",
        "subdistrict_name": "ขุนตัดหวาย"
    },
    {
        "subdistrict_id": "6920",
        "district_id": "855",
        "subdistrict_name": "ท่าหมอไทร"
    },
    {
        "subdistrict_id": "6921",
        "district_id": "855",
        "subdistrict_name": "จะโหนง"
    },
    {
        "subdistrict_id": "6922",
        "district_id": "855",
        "subdistrict_name": "คู"
    },
    {
        "subdistrict_id": "6923",
        "district_id": "855",
        "subdistrict_name": "แค"
    },
    {
        "subdistrict_id": "6924",
        "district_id": "855",
        "subdistrict_name": "คลองเปียะ"
    },
    {
        "subdistrict_id": "6925",
        "district_id": "855",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "6926",
        "district_id": "856",
        "subdistrict_name": "นาทวี"
    },
    {
        "subdistrict_id": "6927",
        "district_id": "856",
        "subdistrict_name": "ฉาง"
    },
    {
        "subdistrict_id": "6928",
        "district_id": "856",
        "subdistrict_name": "นาหมอศรี"
    },
    {
        "subdistrict_id": "6929",
        "district_id": "856",
        "subdistrict_name": "คลองทราย"
    },
    {
        "subdistrict_id": "6930",
        "district_id": "856",
        "subdistrict_name": "ปลักหนู"
    },
    {
        "subdistrict_id": "6931",
        "district_id": "856",
        "subdistrict_name": "ท่าประดู่"
    },
    {
        "subdistrict_id": "6932",
        "district_id": "856",
        "subdistrict_name": "สะท้อน"
    },
    {
        "subdistrict_id": "6933",
        "district_id": "856",
        "subdistrict_name": "ทับช้าง"
    },
    {
        "subdistrict_id": "6934",
        "district_id": "856",
        "subdistrict_name": "ประกอบ"
    },
    {
        "subdistrict_id": "6935",
        "district_id": "856",
        "subdistrict_name": "คลองกวาง"
    },
    {
        "subdistrict_id": "6936",
        "district_id": "857",
        "subdistrict_name": "เทพา"
    },
    {
        "subdistrict_id": "6937",
        "district_id": "857",
        "subdistrict_name": "ปากบาง"
    },
    {
        "subdistrict_id": "6938",
        "district_id": "857",
        "subdistrict_name": "เกาะสะบ้า"
    },
    {
        "subdistrict_id": "6939",
        "district_id": "857",
        "subdistrict_name": "ลำไพล"
    },
    {
        "subdistrict_id": "6940",
        "district_id": "857",
        "subdistrict_name": "ท่าม่วง"
    },
    {
        "subdistrict_id": "6941",
        "district_id": "857",
        "subdistrict_name": "วังใหญ่"
    },
    {
        "subdistrict_id": "6942",
        "district_id": "857",
        "subdistrict_name": "สะกอม"
    },
    {
        "subdistrict_id": "6943",
        "district_id": "858",
        "subdistrict_name": "สะบ้าย้อย"
    },
    {
        "subdistrict_id": "6944",
        "district_id": "858",
        "subdistrict_name": "ทุ่งพอ"
    },
    {
        "subdistrict_id": "6945",
        "district_id": "858",
        "subdistrict_name": "เปียน"
    },
    {
        "subdistrict_id": "6946",
        "district_id": "858",
        "subdistrict_name": "บ้านโหนด"
    },
    {
        "subdistrict_id": "6947",
        "district_id": "858",
        "subdistrict_name": "จะแหน"
    },
    {
        "subdistrict_id": "6948",
        "district_id": "858",
        "subdistrict_name": "คูหา"
    },
    {
        "subdistrict_id": "6949",
        "district_id": "858",
        "subdistrict_name": "เขาแดง"
    },
    {
        "subdistrict_id": "6950",
        "district_id": "858",
        "subdistrict_name": "บาโหย"
    },
    {
        "subdistrict_id": "6951",
        "district_id": "858",
        "subdistrict_name": "ธารคีรี"
    },
    {
        "subdistrict_id": "6952",
        "district_id": "859",
        "subdistrict_name": "ระโนด"
    },
    {
        "subdistrict_id": "6953",
        "district_id": "859",
        "subdistrict_name": "คลองแดน"
    },
    {
        "subdistrict_id": "6954",
        "district_id": "859",
        "subdistrict_name": "ตะเครียะ"
    },
    {
        "subdistrict_id": "6955",
        "district_id": "859",
        "subdistrict_name": "ท่าบอน"
    },
    {
        "subdistrict_id": "6956",
        "district_id": "859",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "6957",
        "district_id": "859",
        "subdistrict_name": "บ่อตรุ"
    },
    {
        "subdistrict_id": "6958",
        "district_id": "859",
        "subdistrict_name": "ปากแตระ"
    },
    {
        "subdistrict_id": "6959",
        "district_id": "859",
        "subdistrict_name": "พังยาง"
    },
    {
        "subdistrict_id": "6960",
        "district_id": "859",
        "subdistrict_name": "ระวะ"
    },
    {
        "subdistrict_id": "6961",
        "district_id": "859",
        "subdistrict_name": "วัดสน"
    },
    {
        "subdistrict_id": "6962",
        "district_id": "859",
        "subdistrict_name": "บ้านขาว"
    },
    {
        "subdistrict_id": "6963",
        "district_id": "859",
        "subdistrict_name": "แดนสงวน"
    },
    {
        "subdistrict_id": "6964",
        "district_id": "860",
        "subdistrict_name": "เกาะใหญ่"
    },
    {
        "subdistrict_id": "6965",
        "district_id": "860",
        "subdistrict_name": "โรง"
    },
    {
        "subdistrict_id": "6966",
        "district_id": "860",
        "subdistrict_name": "เชิงแส"
    },
    {
        "subdistrict_id": "6967",
        "district_id": "860",
        "subdistrict_name": "กระแสสินธุ์"
    },
    {
        "subdistrict_id": "6968",
        "district_id": "861",
        "subdistrict_name": "กำแพงเพชร"
    },
    {
        "subdistrict_id": "6969",
        "district_id": "861",
        "subdistrict_name": "ท่าชะมวง"
    },
    {
        "subdistrict_id": "6970",
        "district_id": "861",
        "subdistrict_name": "คูหาใต้"
    },
    {
        "subdistrict_id": "6971",
        "district_id": "861",
        "subdistrict_name": "ควนรู"
    },
    {
        "subdistrict_id": "6972",
        "district_id": "861",
        "subdistrict_name": "เขาพระ"
    },
    {
        "subdistrict_id": "6973",
        "district_id": "862",
        "subdistrict_name": "สะเดา"
    },
    {
        "subdistrict_id": "6974",
        "district_id": "862",
        "subdistrict_name": "ปริก"
    },
    {
        "subdistrict_id": "6975",
        "district_id": "862",
        "subdistrict_name": "พังลา"
    },
    {
        "subdistrict_id": "6976",
        "district_id": "862",
        "subdistrict_name": "สำนักแต้ว"
    },
    {
        "subdistrict_id": "6977",
        "district_id": "862",
        "subdistrict_name": "ทุ่งหมอ"
    },
    {
        "subdistrict_id": "6978",
        "district_id": "862",
        "subdistrict_name": "ท่าโพธิ์"
    },
    {
        "subdistrict_id": "6979",
        "district_id": "862",
        "subdistrict_name": "ปาดังเบซาร์"
    },
    {
        "subdistrict_id": "6980",
        "district_id": "862",
        "subdistrict_name": "สำนักขาม"
    },
    {
        "subdistrict_id": "6981",
        "district_id": "862",
        "subdistrict_name": "เขามีเกียรติ"
    },
    {
        "subdistrict_id": "6982",
        "district_id": "863",
        "subdistrict_name": "หาดใหญ่"
    },
    {
        "subdistrict_id": "6983",
        "district_id": "863",
        "subdistrict_name": "ควนลัง"
    },
    {
        "subdistrict_id": "6984",
        "district_id": "863",
        "subdistrict_name": "คูเต่า"
    },
    {
        "subdistrict_id": "6985",
        "district_id": "863",
        "subdistrict_name": "คอหงส์"
    },
    {
        "subdistrict_id": "6986",
        "district_id": "863",
        "subdistrict_name": "คลองแห"
    },
    {
        "subdistrict_id": "6987",
        "district_id": "863",
        "subdistrict_name": "คลองอู่ตะเภา"
    },
    {
        "subdistrict_id": "6988",
        "district_id": "863",
        "subdistrict_name": "ฉลุง"
    },
    {
        "subdistrict_id": "6989",
        "district_id": "863",
        "subdistrict_name": "ทุ่งใหญ่"
    },
    {
        "subdistrict_id": "6990",
        "district_id": "863",
        "subdistrict_name": "ทุ่งตำเสา"
    },
    {
        "subdistrict_id": "6991",
        "district_id": "863",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "6992",
        "district_id": "863",
        "subdistrict_name": "น้ำน้อย"
    },
    {
        "subdistrict_id": "6993",
        "district_id": "863",
        "subdistrict_name": "บ้านพรุ"
    },
    {
        "subdistrict_id": "6994",
        "district_id": "863",
        "subdistrict_name": "พะตง"
    },
    {
        "subdistrict_id": "6995",
        "district_id": "864",
        "subdistrict_name": "นาหม่อม"
    },
    {
        "subdistrict_id": "6996",
        "district_id": "864",
        "subdistrict_name": "พิจิตร"
    },
    {
        "subdistrict_id": "6997",
        "district_id": "864",
        "subdistrict_name": "ทุ่งขมิ้น"
    },
    {
        "subdistrict_id": "6998",
        "district_id": "864",
        "subdistrict_name": "คลองหรัง"
    },
    {
        "subdistrict_id": "6999",
        "district_id": "865",
        "subdistrict_name": "รัตภูมิ"
    },
    {
        "subdistrict_id": "7000",
        "district_id": "865",
        "subdistrict_name": "ควนโส"
    },
    {
        "subdistrict_id": "7001",
        "district_id": "865",
        "subdistrict_name": "ห้วยลึก"
    },
    {
        "subdistrict_id": "7002",
        "district_id": "865",
        "subdistrict_name": "บางเหรียง"
    },
    {
        "subdistrict_id": "7003",
        "district_id": "866",
        "subdistrict_name": "บางกล่ำ"
    },
    {
        "subdistrict_id": "7004",
        "district_id": "866",
        "subdistrict_name": "ท่าช้าง"
    },
    {
        "subdistrict_id": "7005",
        "district_id": "866",
        "subdistrict_name": "แม่ทอม"
    },
    {
        "subdistrict_id": "7006",
        "district_id": "866",
        "subdistrict_name": "บ้านหาร"
    },
    {
        "subdistrict_id": "7007",
        "district_id": "867",
        "subdistrict_name": "ชิงโค"
    },
    {
        "subdistrict_id": "7008",
        "district_id": "867",
        "subdistrict_name": "สทิงหม้อ"
    },
    {
        "subdistrict_id": "7009",
        "district_id": "867",
        "subdistrict_name": "ทำนบ"
    },
    {
        "subdistrict_id": "7010",
        "district_id": "867",
        "subdistrict_name": "รำแดง"
    },
    {
        "subdistrict_id": "7011",
        "district_id": "867",
        "subdistrict_name": "วัดขนุน"
    },
    {
        "subdistrict_id": "7012",
        "district_id": "867",
        "subdistrict_name": "ชะแล้"
    },
    {
        "subdistrict_id": "7013",
        "district_id": "867",
        "subdistrict_name": "ปากรอ"
    },
    {
        "subdistrict_id": "7014",
        "district_id": "867",
        "subdistrict_name": "ป่าขาด"
    },
    {
        "subdistrict_id": "7015",
        "district_id": "867",
        "subdistrict_name": "หัวเขา"
    },
    {
        "subdistrict_id": "7016",
        "district_id": "867",
        "subdistrict_name": "บางเขียด"
    },
    {
        "subdistrict_id": "7017",
        "district_id": "867",
        "subdistrict_name": "ม่วงงาม"
    },
    {
        "subdistrict_id": "7018",
        "district_id": "868",
        "subdistrict_name": "คลองหอยโข่ง"
    },
    {
        "subdistrict_id": "7019",
        "district_id": "868",
        "subdistrict_name": "ทุ่งลาน"
    },
    {
        "subdistrict_id": "7020",
        "district_id": "868",
        "subdistrict_name": "โคกม่วง"
    },
    {
        "subdistrict_id": "7021",
        "district_id": "868",
        "subdistrict_name": "คลองหลา"
    },
    {
        "subdistrict_id": "7022",
        "district_id": "870",
        "subdistrict_name": "พิมาน"
    },
    {
        "subdistrict_id": "7023",
        "district_id": "870",
        "subdistrict_name": "คลองขุด"
    },
    {
        "subdistrict_id": "7024",
        "district_id": "870",
        "subdistrict_name": "ควนขัน"
    },
    {
        "subdistrict_id": "7025",
        "district_id": "870",
        "subdistrict_name": "บ้านควน"
    },
    {
        "subdistrict_id": "7026",
        "district_id": "870",
        "subdistrict_name": "ฉลุง"
    },
    {
        "subdistrict_id": "7027",
        "district_id": "870",
        "subdistrict_name": "เกาะสาหร่าย"
    },
    {
        "subdistrict_id": "7028",
        "district_id": "870",
        "subdistrict_name": "ตันหยงโป"
    },
    {
        "subdistrict_id": "7029",
        "district_id": "870",
        "subdistrict_name": "เจ๊ะบิลัง"
    },
    {
        "subdistrict_id": "7030",
        "district_id": "870",
        "subdistrict_name": "ตำมะลัง"
    },
    {
        "subdistrict_id": "7031",
        "district_id": "870",
        "subdistrict_name": "ปูยู"
    },
    {
        "subdistrict_id": "7032",
        "district_id": "870",
        "subdistrict_name": "ควนโพธิ์"
    },
    {
        "subdistrict_id": "7033",
        "district_id": "870",
        "subdistrict_name": "เกตรี"
    },
    {
        "subdistrict_id": "7034",
        "district_id": "871",
        "subdistrict_name": "ควนโดน"
    },
    {
        "subdistrict_id": "7035",
        "district_id": "871",
        "subdistrict_name": "ควนสตอ"
    },
    {
        "subdistrict_id": "7036",
        "district_id": "871",
        "subdistrict_name": "ย่านซื่อ"
    },
    {
        "subdistrict_id": "7037",
        "district_id": "871",
        "subdistrict_name": "วังประจัน"
    },
    {
        "subdistrict_id": "7038",
        "district_id": "872",
        "subdistrict_name": "ทุ่งนุ้ย"
    },
    {
        "subdistrict_id": "7039",
        "district_id": "872",
        "subdistrict_name": "ควนกาหลง"
    },
    {
        "subdistrict_id": "7040",
        "district_id": "872",
        "subdistrict_name": "อุใดเจริญ"
    },
    {
        "subdistrict_id": "7041",
        "district_id": "873",
        "subdistrict_name": "ท่าแพ"
    },
    {
        "subdistrict_id": "7042",
        "district_id": "873",
        "subdistrict_name": "แป-ระ"
    },
    {
        "subdistrict_id": "7043",
        "district_id": "873",
        "subdistrict_name": "สาคร"
    },
    {
        "subdistrict_id": "7044",
        "district_id": "873",
        "subdistrict_name": "ท่าเรือ"
    },
    {
        "subdistrict_id": "7045",
        "district_id": "874",
        "subdistrict_name": "กำแพง"
    },
    {
        "subdistrict_id": "7046",
        "district_id": "874",
        "subdistrict_name": "ละงู"
    },
    {
        "subdistrict_id": "7047",
        "district_id": "874",
        "subdistrict_name": "เขาขาว"
    },
    {
        "subdistrict_id": "7048",
        "district_id": "874",
        "subdistrict_name": "ปากน้ำ"
    },
    {
        "subdistrict_id": "7049",
        "district_id": "874",
        "subdistrict_name": "น้ำผุด"
    },
    {
        "subdistrict_id": "7050",
        "district_id": "874",
        "subdistrict_name": "แหลมสน"
    },
    {
        "subdistrict_id": "7051",
        "district_id": "875",
        "subdistrict_name": "ทุ่งหว้า"
    },
    {
        "subdistrict_id": "7052",
        "district_id": "875",
        "subdistrict_name": "นาทอน"
    },
    {
        "subdistrict_id": "7053",
        "district_id": "875",
        "subdistrict_name": "ขอนคลาน"
    },
    {
        "subdistrict_id": "7054",
        "district_id": "875",
        "subdistrict_name": "ทุ่งบุหลัง"
    },
    {
        "subdistrict_id": "7055",
        "district_id": "875",
        "subdistrict_name": "ป่าแก่บ่อหิน"
    },
    {
        "subdistrict_id": "7056",
        "district_id": "876",
        "subdistrict_name": "ปาล์มพัฒนา"
    },
    {
        "subdistrict_id": "7057",
        "district_id": "876",
        "subdistrict_name": "นิคมพัฒนา"
    },
    {
        "subdistrict_id": "7058",
        "district_id": "877",
        "subdistrict_name": "ทับเที่ยง"
    },
    {
        "subdistrict_id": "7059",
        "district_id": "877",
        "subdistrict_name": "นาพละ"
    },
    {
        "subdistrict_id": "7060",
        "district_id": "877",
        "subdistrict_name": "บ้านควน"
    },
    {
        "subdistrict_id": "7061",
        "district_id": "877",
        "subdistrict_name": "นาบินหลา"
    },
    {
        "subdistrict_id": "7062",
        "district_id": "877",
        "subdistrict_name": "ควนปริง"
    },
    {
        "subdistrict_id": "7063",
        "district_id": "877",
        "subdistrict_name": "นาโยงใต้"
    },
    {
        "subdistrict_id": "7064",
        "district_id": "877",
        "subdistrict_name": "บางรัก"
    },
    {
        "subdistrict_id": "7065",
        "district_id": "877",
        "subdistrict_name": "โคกหล่อ"
    },
    {
        "subdistrict_id": "7066",
        "district_id": "877",
        "subdistrict_name": "นาโต๊ะหมิง"
    },
    {
        "subdistrict_id": "7067",
        "district_id": "877",
        "subdistrict_name": "หนองตรุด"
    },
    {
        "subdistrict_id": "7068",
        "district_id": "877",
        "subdistrict_name": "น้ำผุด"
    },
    {
        "subdistrict_id": "7069",
        "district_id": "877",
        "subdistrict_name": "นาตาล่วง"
    },
    {
        "subdistrict_id": "7070",
        "district_id": "877",
        "subdistrict_name": "บ้านโพธิ์"
    },
    {
        "subdistrict_id": "7071",
        "district_id": "877",
        "subdistrict_name": "นาท่ามเหนือ"
    },
    {
        "subdistrict_id": "7072",
        "district_id": "877",
        "subdistrict_name": "นาท่ามใต้"
    },
    {
        "subdistrict_id": "7073",
        "district_id": "878",
        "subdistrict_name": "กันตัง"
    },
    {
        "subdistrict_id": "7074",
        "district_id": "878",
        "subdistrict_name": "ควนธานี"
    },
    {
        "subdistrict_id": "7075",
        "district_id": "878",
        "subdistrict_name": "บางหมาก"
    },
    {
        "subdistrict_id": "7076",
        "district_id": "878",
        "subdistrict_name": "บางเป้า"
    },
    {
        "subdistrict_id": "7077",
        "district_id": "878",
        "subdistrict_name": "วังวน"
    },
    {
        "subdistrict_id": "7078",
        "district_id": "878",
        "subdistrict_name": "กันตังใต้"
    },
    {
        "subdistrict_id": "7079",
        "district_id": "878",
        "subdistrict_name": "โคกยาง"
    },
    {
        "subdistrict_id": "7080",
        "district_id": "878",
        "subdistrict_name": "คลองลุ"
    },
    {
        "subdistrict_id": "7081",
        "district_id": "878",
        "subdistrict_name": "ย่านซื่อ"
    },
    {
        "subdistrict_id": "7082",
        "district_id": "878",
        "subdistrict_name": "บ่อน้ำร้อน"
    },
    {
        "subdistrict_id": "7083",
        "district_id": "878",
        "subdistrict_name": "บางสัก"
    },
    {
        "subdistrict_id": "7084",
        "district_id": "878",
        "subdistrict_name": "นาเกลือ"
    },
    {
        "subdistrict_id": "7085",
        "district_id": "878",
        "subdistrict_name": "เกาะลิบง"
    },
    {
        "subdistrict_id": "7086",
        "district_id": "878",
        "subdistrict_name": "คลองชีล้อม"
    },
    {
        "subdistrict_id": "7087",
        "district_id": "879",
        "subdistrict_name": "ย่านตาขาว"
    },
    {
        "subdistrict_id": "7088",
        "district_id": "879",
        "subdistrict_name": "หนองบ่อ"
    },
    {
        "subdistrict_id": "7089",
        "district_id": "879",
        "subdistrict_name": "นาชุมเห็ด"
    },
    {
        "subdistrict_id": "7090",
        "district_id": "879",
        "subdistrict_name": "ในควน"
    },
    {
        "subdistrict_id": "7091",
        "district_id": "879",
        "subdistrict_name": "โพรงจระเข้"
    },
    {
        "subdistrict_id": "7092",
        "district_id": "879",
        "subdistrict_name": "ทุ่งกระบือ"
    },
    {
        "subdistrict_id": "7093",
        "district_id": "879",
        "subdistrict_name": "ทุ่งค่าย"
    },
    {
        "subdistrict_id": "7094",
        "district_id": "879",
        "subdistrict_name": "เกาะเปียะ"
    },
    {
        "subdistrict_id": "7095",
        "district_id": "880",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "7096",
        "district_id": "880",
        "subdistrict_name": "ทุ่งยาว"
    },
    {
        "subdistrict_id": "7097",
        "district_id": "880",
        "subdistrict_name": "ปะเหลียน"
    },
    {
        "subdistrict_id": "7098",
        "district_id": "880",
        "subdistrict_name": "บางด้วน"
    },
    {
        "subdistrict_id": "7099",
        "district_id": "880",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "7100",
        "district_id": "880",
        "subdistrict_name": "สุโสะ"
    },
    {
        "subdistrict_id": "7101",
        "district_id": "880",
        "subdistrict_name": "ลิพัง"
    },
    {
        "subdistrict_id": "7102",
        "district_id": "880",
        "subdistrict_name": "เกาะสุกร"
    },
    {
        "subdistrict_id": "7103",
        "district_id": "880",
        "subdistrict_name": "ท่าพญา"
    },
    {
        "subdistrict_id": "7104",
        "district_id": "880",
        "subdistrict_name": "แหลมสอม"
    },
    {
        "subdistrict_id": "7105",
        "district_id": "881",
        "subdistrict_name": "บ่อหิน"
    },
    {
        "subdistrict_id": "7106",
        "district_id": "881",
        "subdistrict_name": "เขาไม้แก้ว"
    },
    {
        "subdistrict_id": "7107",
        "district_id": "881",
        "subdistrict_name": "กะลาเส"
    },
    {
        "subdistrict_id": "7108",
        "district_id": "881",
        "subdistrict_name": "ไม้ฝาด"
    },
    {
        "subdistrict_id": "7109",
        "district_id": "881",
        "subdistrict_name": "นาเมืองเพชร"
    },
    {
        "subdistrict_id": "7110",
        "district_id": "882",
        "subdistrict_name": "ห้วยยอด"
    },
    {
        "subdistrict_id": "7111",
        "district_id": "882",
        "subdistrict_name": "หนองช้างแล่น"
    },
    {
        "subdistrict_id": "7112",
        "district_id": "882",
        "subdistrict_name": "บางดี"
    },
    {
        "subdistrict_id": "7113",
        "district_id": "882",
        "subdistrict_name": "บางกุ้ง"
    },
    {
        "subdistrict_id": "7114",
        "district_id": "882",
        "subdistrict_name": "เขากอบ"
    },
    {
        "subdistrict_id": "7115",
        "district_id": "882",
        "subdistrict_name": "เขาขาว"
    },
    {
        "subdistrict_id": "7116",
        "district_id": "882",
        "subdistrict_name": "เขาปูน"
    },
    {
        "subdistrict_id": "7117",
        "district_id": "882",
        "subdistrict_name": "ปากแจ่ม"
    },
    {
        "subdistrict_id": "7118",
        "district_id": "882",
        "subdistrict_name": "ปากคม"
    },
    {
        "subdistrict_id": "7119",
        "district_id": "882",
        "subdistrict_name": "ท่างิ้ว"
    },
    {
        "subdistrict_id": "7120",
        "district_id": "882",
        "subdistrict_name": "ลำภูรา"
    },
    {
        "subdistrict_id": "7121",
        "district_id": "882",
        "subdistrict_name": "นาวง"
    },
    {
        "subdistrict_id": "7122",
        "district_id": "882",
        "subdistrict_name": "ห้วยนาง"
    },
    {
        "subdistrict_id": "7123",
        "district_id": "882",
        "subdistrict_name": "ในเตา"
    },
    {
        "subdistrict_id": "7124",
        "district_id": "882",
        "subdistrict_name": "ทุ่งต่อ"
    },
    {
        "subdistrict_id": "7125",
        "district_id": "882",
        "subdistrict_name": "วังคีรี"
    },
    {
        "subdistrict_id": "7126",
        "district_id": "883",
        "subdistrict_name": "เขาวิเศษ"
    },
    {
        "subdistrict_id": "7127",
        "district_id": "883",
        "subdistrict_name": "วังมะปราง"
    },
    {
        "subdistrict_id": "7128",
        "district_id": "883",
        "subdistrict_name": "อ่าวตง"
    },
    {
        "subdistrict_id": "7129",
        "district_id": "883",
        "subdistrict_name": "ท่าสะบ้า"
    },
    {
        "subdistrict_id": "7130",
        "district_id": "883",
        "subdistrict_name": "วังมะปรางเหนือ"
    },
    {
        "subdistrict_id": "7131",
        "district_id": "884",
        "subdistrict_name": "นาโยงเหนือ"
    },
    {
        "subdistrict_id": "7132",
        "district_id": "884",
        "subdistrict_name": "ช่อง"
    },
    {
        "subdistrict_id": "7133",
        "district_id": "884",
        "subdistrict_name": "ละมอ"
    },
    {
        "subdistrict_id": "7134",
        "district_id": "884",
        "subdistrict_name": "โคกสะบ้า"
    },
    {
        "subdistrict_id": "7135",
        "district_id": "884",
        "subdistrict_name": "นาหมื่นศรี"
    },
    {
        "subdistrict_id": "7136",
        "district_id": "884",
        "subdistrict_name": "นาข้าวเสีย"
    },
    {
        "subdistrict_id": "7137",
        "district_id": "885",
        "subdistrict_name": "ควนเมา"
    },
    {
        "subdistrict_id": "7138",
        "district_id": "885",
        "subdistrict_name": "คลองปาง"
    },
    {
        "subdistrict_id": "7139",
        "district_id": "885",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "7140",
        "district_id": "885",
        "subdistrict_name": "หนองปรือ"
    },
    {
        "subdistrict_id": "7141",
        "district_id": "885",
        "subdistrict_name": "เขาไพร"
    },
    {
        "subdistrict_id": "7142",
        "district_id": "886",
        "subdistrict_name": "หาดสำราญ"
    },
    {
        "subdistrict_id": "7143",
        "district_id": "886",
        "subdistrict_name": "บ้าหวี"
    },
    {
        "subdistrict_id": "7144",
        "district_id": "886",
        "subdistrict_name": "ตะเสะ"
    },
    {
        "subdistrict_id": "7145",
        "district_id": "887",
        "subdistrict_name": "คูหาสวรรค์"
    },
    {
        "subdistrict_id": "7146",
        "district_id": "887",
        "subdistrict_name": "เขาเจียก"
    },
    {
        "subdistrict_id": "7147",
        "district_id": "887",
        "subdistrict_name": "ท่ามิหรำ"
    },
    {
        "subdistrict_id": "7148",
        "district_id": "887",
        "subdistrict_name": "โคกชะงาย"
    },
    {
        "subdistrict_id": "7149",
        "district_id": "887",
        "subdistrict_name": "นาท่อม"
    },
    {
        "subdistrict_id": "7150",
        "district_id": "887",
        "subdistrict_name": "ปรางหมู่"
    },
    {
        "subdistrict_id": "7151",
        "district_id": "887",
        "subdistrict_name": "ท่าแค"
    },
    {
        "subdistrict_id": "7152",
        "district_id": "887",
        "subdistrict_name": "ลำปำ"
    },
    {
        "subdistrict_id": "7153",
        "district_id": "887",
        "subdistrict_name": "ตำนาน"
    },
    {
        "subdistrict_id": "7154",
        "district_id": "887",
        "subdistrict_name": "ควนมะพร้าว"
    },
    {
        "subdistrict_id": "7155",
        "district_id": "887",
        "subdistrict_name": "ร่มเมือง"
    },
    {
        "subdistrict_id": "7156",
        "district_id": "887",
        "subdistrict_name": "ชัยบุรี"
    },
    {
        "subdistrict_id": "7157",
        "district_id": "887",
        "subdistrict_name": "นาโหนด"
    },
    {
        "subdistrict_id": "7158",
        "district_id": "887",
        "subdistrict_name": "พญาขัน"
    },
    {
        "subdistrict_id": "7159",
        "district_id": "888",
        "subdistrict_name": "กงหรา"
    },
    {
        "subdistrict_id": "7160",
        "district_id": "888",
        "subdistrict_name": "ชะรัด"
    },
    {
        "subdistrict_id": "7161",
        "district_id": "888",
        "subdistrict_name": "คลองเฉลิม"
    },
    {
        "subdistrict_id": "7162",
        "district_id": "888",
        "subdistrict_name": "คลองทรายขาว"
    },
    {
        "subdistrict_id": "7163",
        "district_id": "888",
        "subdistrict_name": "สมหวัง"
    },
    {
        "subdistrict_id": "7164",
        "district_id": "889",
        "subdistrict_name": "เขาชัยสน"
    },
    {
        "subdistrict_id": "7165",
        "district_id": "889",
        "subdistrict_name": "ควนขนุน"
    },
    {
        "subdistrict_id": "7166",
        "district_id": "889",
        "subdistrict_name": "จองถนน"
    },
    {
        "subdistrict_id": "7167",
        "district_id": "889",
        "subdistrict_name": "หานโพธิ์"
    },
    {
        "subdistrict_id": "7168",
        "district_id": "889",
        "subdistrict_name": "โคกม่วง"
    },
    {
        "subdistrict_id": "7169",
        "district_id": "890",
        "subdistrict_name": "แม่ขรี"
    },
    {
        "subdistrict_id": "7170",
        "district_id": "890",
        "subdistrict_name": "ตะโหมด"
    },
    {
        "subdistrict_id": "7171",
        "district_id": "890",
        "subdistrict_name": "คลองใหญ่"
    },
    {
        "subdistrict_id": "7172",
        "district_id": "891",
        "subdistrict_name": "ควนขนุน"
    },
    {
        "subdistrict_id": "7173",
        "district_id": "891",
        "subdistrict_name": "ทะเลน้อย"
    },
    {
        "subdistrict_id": "7174",
        "district_id": "891",
        "subdistrict_name": "นาขยาด"
    },
    {
        "subdistrict_id": "7175",
        "district_id": "891",
        "subdistrict_name": "พนมวังก์"
    },
    {
        "subdistrict_id": "7176",
        "district_id": "891",
        "subdistrict_name": "แหลมโตนด"
    },
    {
        "subdistrict_id": "7177",
        "district_id": "891",
        "subdistrict_name": "ปันแต"
    },
    {
        "subdistrict_id": "7178",
        "district_id": "891",
        "subdistrict_name": "โตนดด้วน"
    },
    {
        "subdistrict_id": "7179",
        "district_id": "891",
        "subdistrict_name": "ดอนทราย"
    },
    {
        "subdistrict_id": "7180",
        "district_id": "891",
        "subdistrict_name": "มะกอกเหนือ"
    },
    {
        "subdistrict_id": "7181",
        "district_id": "891",
        "subdistrict_name": "พนางตุง"
    },
    {
        "subdistrict_id": "7182",
        "district_id": "891",
        "subdistrict_name": "ชะมวง"
    },
    {
        "subdistrict_id": "7183",
        "district_id": "891",
        "subdistrict_name": "แพรกหา"
    },
    {
        "subdistrict_id": "7184",
        "district_id": "892",
        "subdistrict_name": "ปากพะยูน"
    },
    {
        "subdistrict_id": "7185",
        "district_id": "892",
        "subdistrict_name": "ดอนประดู่"
    },
    {
        "subdistrict_id": "7186",
        "district_id": "892",
        "subdistrict_name": "เกาะนางคำ"
    },
    {
        "subdistrict_id": "7187",
        "district_id": "892",
        "subdistrict_name": "เกาะหมาก"
    },
    {
        "subdistrict_id": "7188",
        "district_id": "892",
        "subdistrict_name": "ฝาละมี"
    },
    {
        "subdistrict_id": "7189",
        "district_id": "892",
        "subdistrict_name": "หารเทา"
    },
    {
        "subdistrict_id": "7190",
        "district_id": "892",
        "subdistrict_name": "ดอนทราย"
    },
    {
        "subdistrict_id": "7191",
        "district_id": "893",
        "subdistrict_name": "เขาย่า"
    },
    {
        "subdistrict_id": "7192",
        "district_id": "893",
        "subdistrict_name": "เขาปู่"
    },
    {
        "subdistrict_id": "7193",
        "district_id": "893",
        "subdistrict_name": "ตะแพน"
    },
    {
        "subdistrict_id": "7194",
        "district_id": "894",
        "subdistrict_name": "ป่าบอน"
    },
    {
        "subdistrict_id": "7195",
        "district_id": "894",
        "subdistrict_name": "โคกทราย"
    },
    {
        "subdistrict_id": "7196",
        "district_id": "894",
        "subdistrict_name": "หนองธง"
    },
    {
        "subdistrict_id": "7197",
        "district_id": "894",
        "subdistrict_name": "ทุ่งนารี"
    },
    {
        "subdistrict_id": "7198",
        "district_id": "894",
        "subdistrict_name": "วังใหม่"
    },
    {
        "subdistrict_id": "7199",
        "district_id": "895",
        "subdistrict_name": "ท่ามะเดื่อ"
    },
    {
        "subdistrict_id": "7200",
        "district_id": "895",
        "subdistrict_name": "นาปะขอ"
    },
    {
        "subdistrict_id": "7201",
        "district_id": "895",
        "subdistrict_name": "โคกสัก"
    },
    {
        "subdistrict_id": "7202",
        "district_id": "896",
        "subdistrict_name": "ป่าพะยอม"
    },
    {
        "subdistrict_id": "7203",
        "district_id": "896",
        "subdistrict_name": "ลานข่อย"
    },
    {
        "subdistrict_id": "7204",
        "district_id": "896",
        "subdistrict_name": "เกาะเต่า"
    },
    {
        "subdistrict_id": "7205",
        "district_id": "896",
        "subdistrict_name": "บ้านพร้าว"
    },
    {
        "subdistrict_id": "7206",
        "district_id": "897",
        "subdistrict_name": "ชุมพล"
    },
    {
        "subdistrict_id": "7207",
        "district_id": "897",
        "subdistrict_name": "บ้านนา"
    },
    {
        "subdistrict_id": "7208",
        "district_id": "897",
        "subdistrict_name": "อ่างทอง"
    },
    {
        "subdistrict_id": "7209",
        "district_id": "897",
        "subdistrict_name": "ลำสินธุ์"
    },
    {
        "subdistrict_id": "7210",
        "district_id": "898",
        "subdistrict_name": "สะบารัง"
    },
    {
        "subdistrict_id": "7211",
        "district_id": "898",
        "subdistrict_name": "อาเนาะรู"
    },
    {
        "subdistrict_id": "7212",
        "district_id": "898",
        "subdistrict_name": "จะบังติกอ"
    },
    {
        "subdistrict_id": "7213",
        "district_id": "898",
        "subdistrict_name": "บานา"
    },
    {
        "subdistrict_id": "7214",
        "district_id": "898",
        "subdistrict_name": "ตันหยงลุโละ"
    },
    {
        "subdistrict_id": "7215",
        "district_id": "898",
        "subdistrict_name": "คลองมานิง"
    },
    {
        "subdistrict_id": "7216",
        "district_id": "898",
        "subdistrict_name": "กะมิยอ"
    },
    {
        "subdistrict_id": "7217",
        "district_id": "898",
        "subdistrict_name": "บาราโหม"
    },
    {
        "subdistrict_id": "7218",
        "district_id": "898",
        "subdistrict_name": "ปะกาฮะรัง"
    },
    {
        "subdistrict_id": "7219",
        "district_id": "898",
        "subdistrict_name": "รูสะมิแล"
    },
    {
        "subdistrict_id": "7220",
        "district_id": "898",
        "subdistrict_name": "ตะลุโบะ"
    },
    {
        "subdistrict_id": "7221",
        "district_id": "898",
        "subdistrict_name": "บาราเฮาะ"
    },
    {
        "subdistrict_id": "7222",
        "district_id": "898",
        "subdistrict_name": "ปุยุด"
    },
    {
        "subdistrict_id": "7223",
        "district_id": "899",
        "subdistrict_name": "โคกโพธิ์"
    },
    {
        "subdistrict_id": "7224",
        "district_id": "899",
        "subdistrict_name": "มะกรูด"
    },
    {
        "subdistrict_id": "7225",
        "district_id": "899",
        "subdistrict_name": "บางโกระ"
    },
    {
        "subdistrict_id": "7226",
        "district_id": "899",
        "subdistrict_name": "ป่าบอน"
    },
    {
        "subdistrict_id": "7227",
        "district_id": "899",
        "subdistrict_name": "ทรายขาว"
    },
    {
        "subdistrict_id": "7228",
        "district_id": "899",
        "subdistrict_name": "นาประดู่"
    },
    {
        "subdistrict_id": "7229",
        "district_id": "899",
        "subdistrict_name": "ปากล่อ"
    },
    {
        "subdistrict_id": "7230",
        "district_id": "899",
        "subdistrict_name": "ทุ่งพลา"
    },
    {
        "subdistrict_id": "7231",
        "district_id": "899",
        "subdistrict_name": "ท่าเรือ"
    },
    {
        "subdistrict_id": "7232",
        "district_id": "899",
        "subdistrict_name": "นาเกตุ"
    },
    {
        "subdistrict_id": "7233",
        "district_id": "899",
        "subdistrict_name": "ควนโนรี"
    },
    {
        "subdistrict_id": "7234",
        "district_id": "899",
        "subdistrict_name": "ช้างให้ตก"
    },
    {
        "subdistrict_id": "7235",
        "district_id": "900",
        "subdistrict_name": "เกาะเปาะ"
    },
    {
        "subdistrict_id": "7236",
        "district_id": "900",
        "subdistrict_name": "คอลอตันหยง"
    },
    {
        "subdistrict_id": "7237",
        "district_id": "900",
        "subdistrict_name": "ดอนรัก"
    },
    {
        "subdistrict_id": "7238",
        "district_id": "900",
        "subdistrict_name": "ดาโต๊ะ"
    },
    {
        "subdistrict_id": "7239",
        "district_id": "900",
        "subdistrict_name": "ตุยง"
    },
    {
        "subdistrict_id": "7240",
        "district_id": "900",
        "subdistrict_name": "ท่ากำชำ"
    },
    {
        "subdistrict_id": "7241",
        "district_id": "900",
        "subdistrict_name": "บ่อทอง"
    },
    {
        "subdistrict_id": "7242",
        "district_id": "900",
        "subdistrict_name": "บางเขา"
    },
    {
        "subdistrict_id": "7243",
        "district_id": "900",
        "subdistrict_name": "บางตาวา"
    },
    {
        "subdistrict_id": "7244",
        "district_id": "900",
        "subdistrict_name": "ปุโละปุโย"
    },
    {
        "subdistrict_id": "7245",
        "district_id": "900",
        "subdistrict_name": "ยาบี"
    },
    {
        "subdistrict_id": "7246",
        "district_id": "900",
        "subdistrict_name": "ลิปะสะโง"
    },
    {
        "subdistrict_id": "7247",
        "district_id": "901",
        "subdistrict_name": "ปะนาเระ"
    },
    {
        "subdistrict_id": "7248",
        "district_id": "901",
        "subdistrict_name": "ท่าข้าม"
    },
    {
        "subdistrict_id": "7249",
        "district_id": "901",
        "subdistrict_name": "บ้านนอก"
    },
    {
        "subdistrict_id": "7250",
        "district_id": "901",
        "subdistrict_name": "ดอน"
    },
    {
        "subdistrict_id": "7251",
        "district_id": "901",
        "subdistrict_name": "ควน"
    },
    {
        "subdistrict_id": "7252",
        "district_id": "901",
        "subdistrict_name": "ท่าน้ำ"
    },
    {
        "subdistrict_id": "7253",
        "district_id": "901",
        "subdistrict_name": "คอกกระบือ"
    },
    {
        "subdistrict_id": "7254",
        "district_id": "901",
        "subdistrict_name": "พ่อมิ่ง"
    },
    {
        "subdistrict_id": "7255",
        "district_id": "901",
        "subdistrict_name": "บ้านกลาง"
    },
    {
        "subdistrict_id": "7256",
        "district_id": "901",
        "subdistrict_name": "บ้านน้ำบ่อ"
    },
    {
        "subdistrict_id": "7257",
        "district_id": "902",
        "subdistrict_name": "มายอ"
    },
    {
        "subdistrict_id": "7258",
        "district_id": "902",
        "subdistrict_name": "ถนน"
    },
    {
        "subdistrict_id": "7259",
        "district_id": "902",
        "subdistrict_name": "ตรัง"
    },
    {
        "subdistrict_id": "7260",
        "district_id": "902",
        "subdistrict_name": "กระหวะ"
    },
    {
        "subdistrict_id": "7261",
        "district_id": "902",
        "subdistrict_name": "ลุโบะยิไร"
    },
    {
        "subdistrict_id": "7262",
        "district_id": "902",
        "subdistrict_name": "ลางา"
    },
    {
        "subdistrict_id": "7263",
        "district_id": "902",
        "subdistrict_name": "กระเสาะ"
    },
    {
        "subdistrict_id": "7264",
        "district_id": "902",
        "subdistrict_name": "เกาะจัน"
    },
    {
        "subdistrict_id": "7265",
        "district_id": "902",
        "subdistrict_name": "ปะโด"
    },
    {
        "subdistrict_id": "7266",
        "district_id": "902",
        "subdistrict_name": "สาคอบน"
    },
    {
        "subdistrict_id": "7267",
        "district_id": "902",
        "subdistrict_name": "สาคอใต้"
    },
    {
        "subdistrict_id": "7268",
        "district_id": "902",
        "subdistrict_name": "สะกำ"
    },
    {
        "subdistrict_id": "7269",
        "district_id": "902",
        "subdistrict_name": "ปานัน"
    },
    {
        "subdistrict_id": "7270",
        "district_id": "903",
        "subdistrict_name": "ตะโละแมะนา"
    },
    {
        "subdistrict_id": "7271",
        "district_id": "903",
        "subdistrict_name": "พิเทน"
    },
    {
        "subdistrict_id": "7272",
        "district_id": "903",
        "subdistrict_name": "น้ำดำ"
    },
    {
        "subdistrict_id": "7273",
        "district_id": "903",
        "subdistrict_name": "ปากู"
    },
    {
        "subdistrict_id": "7274",
        "district_id": "904",
        "subdistrict_name": "ตะลุบัน"
    },
    {
        "subdistrict_id": "7275",
        "district_id": "904",
        "subdistrict_name": "ตะบิ้ง"
    },
    {
        "subdistrict_id": "7276",
        "district_id": "904",
        "subdistrict_name": "ปะเสยะวอ"
    },
    {
        "subdistrict_id": "7277",
        "district_id": "904",
        "subdistrict_name": "บางเก่า"
    },
    {
        "subdistrict_id": "7278",
        "district_id": "904",
        "subdistrict_name": "บือเระ"
    },
    {
        "subdistrict_id": "7279",
        "district_id": "904",
        "subdistrict_name": "เตราะบอน"
    },
    {
        "subdistrict_id": "7280",
        "district_id": "904",
        "subdistrict_name": "กะดุนง"
    },
    {
        "subdistrict_id": "7281",
        "district_id": "904",
        "subdistrict_name": "ละหาร"
    },
    {
        "subdistrict_id": "7282",
        "district_id": "904",
        "subdistrict_name": "มะนังดาลำ"
    },
    {
        "subdistrict_id": "7283",
        "district_id": "904",
        "subdistrict_name": "แป้น"
    },
    {
        "subdistrict_id": "7284",
        "district_id": "904",
        "subdistrict_name": "ทุ่งคล้า"
    },
    {
        "subdistrict_id": "7285",
        "district_id": "905",
        "subdistrict_name": "ไทรทอง"
    },
    {
        "subdistrict_id": "7286",
        "district_id": "905",
        "subdistrict_name": "ไม้แก่น"
    },
    {
        "subdistrict_id": "7287",
        "district_id": "905",
        "subdistrict_name": "ตะโละไกรทอง"
    },
    {
        "subdistrict_id": "7288",
        "district_id": "905",
        "subdistrict_name": "ดอนทราย"
    },
    {
        "subdistrict_id": "7289",
        "district_id": "906",
        "subdistrict_name": "ตะโละ"
    },
    {
        "subdistrict_id": "7290",
        "district_id": "906",
        "subdistrict_name": "ตะโละกาโปร์"
    },
    {
        "subdistrict_id": "7291",
        "district_id": "906",
        "subdistrict_name": "ตันหยงดาลอ"
    },
    {
        "subdistrict_id": "7292",
        "district_id": "906",
        "subdistrict_name": "ตันหยงจึงงา"
    },
    {
        "subdistrict_id": "7293",
        "district_id": "906",
        "subdistrict_name": "ตอหลัง"
    },
    {
        "subdistrict_id": "7294",
        "district_id": "906",
        "subdistrict_name": "ตาแกะ"
    },
    {
        "subdistrict_id": "7295",
        "district_id": "906",
        "subdistrict_name": "ตาลีอายร์"
    },
    {
        "subdistrict_id": "7296",
        "district_id": "906",
        "subdistrict_name": "ยามู"
    },
    {
        "subdistrict_id": "7297",
        "district_id": "906",
        "subdistrict_name": "บางปู"
    },
    {
        "subdistrict_id": "7298",
        "district_id": "906",
        "subdistrict_name": "หนองแรต"
    },
    {
        "subdistrict_id": "7299",
        "district_id": "906",
        "subdistrict_name": "ปิยามุมัง"
    },
    {
        "subdistrict_id": "7300",
        "district_id": "906",
        "subdistrict_name": "ปุลากง"
    },
    {
        "subdistrict_id": "7301",
        "district_id": "906",
        "subdistrict_name": "บาโลย"
    },
    {
        "subdistrict_id": "7302",
        "district_id": "906",
        "subdistrict_name": "สาบัน"
    },
    {
        "subdistrict_id": "7303",
        "district_id": "906",
        "subdistrict_name": "มะนังยง"
    },
    {
        "subdistrict_id": "7304",
        "district_id": "906",
        "subdistrict_name": "ราตาปันยัง"
    },
    {
        "subdistrict_id": "7305",
        "district_id": "906",
        "subdistrict_name": "จะรัง"
    },
    {
        "subdistrict_id": "7306",
        "district_id": "906",
        "subdistrict_name": "แหลมโพธิ์"
    },
    {
        "subdistrict_id": "7307",
        "district_id": "907",
        "subdistrict_name": "ยะรัง"
    },
    {
        "subdistrict_id": "7308",
        "district_id": "907",
        "subdistrict_name": "สะดาวา"
    },
    {
        "subdistrict_id": "7309",
        "district_id": "907",
        "subdistrict_name": "ประจัน"
    },
    {
        "subdistrict_id": "7310",
        "district_id": "907",
        "subdistrict_name": "สะนอ"
    },
    {
        "subdistrict_id": "7311",
        "district_id": "907",
        "subdistrict_name": "ระแว้ง"
    },
    {
        "subdistrict_id": "7312",
        "district_id": "907",
        "subdistrict_name": "ปิตูมุดี"
    },
    {
        "subdistrict_id": "7313",
        "district_id": "907",
        "subdistrict_name": "วัด"
    },
    {
        "subdistrict_id": "7314",
        "district_id": "907",
        "subdistrict_name": "กระโด"
    },
    {
        "subdistrict_id": "7315",
        "district_id": "907",
        "subdistrict_name": "คลองใหม่"
    },
    {
        "subdistrict_id": "7316",
        "district_id": "907",
        "subdistrict_name": "เมาะมาวี"
    },
    {
        "subdistrict_id": "7317",
        "district_id": "907",
        "subdistrict_name": "กอลำ"
    },
    {
        "subdistrict_id": "7318",
        "district_id": "907",
        "subdistrict_name": "เขาตูม"
    },
    {
        "subdistrict_id": "7319",
        "district_id": "908",
        "subdistrict_name": "กะรุบี"
    },
    {
        "subdistrict_id": "7320",
        "district_id": "908",
        "subdistrict_name": "ตะโละดือรามัน"
    },
    {
        "subdistrict_id": "7321",
        "district_id": "908",
        "subdistrict_name": "ปล่องหอย"
    },
    {
        "subdistrict_id": "7322",
        "district_id": "909",
        "subdistrict_name": "แม่ลาน"
    },
    {
        "subdistrict_id": "7323",
        "district_id": "909",
        "subdistrict_name": "ม่วงเตี้ย"
    },
    {
        "subdistrict_id": "7324",
        "district_id": "909",
        "subdistrict_name": "ป่าไร่"
    },
    {
        "subdistrict_id": "7325",
        "district_id": "910",
        "subdistrict_name": "สะเตง"
    },
    {
        "subdistrict_id": "7326",
        "district_id": "910",
        "subdistrict_name": "บุดี"
    },
    {
        "subdistrict_id": "7327",
        "district_id": "910",
        "subdistrict_name": "ยุโป"
    },
    {
        "subdistrict_id": "7328",
        "district_id": "910",
        "subdistrict_name": "ลิดล"
    },
    {
        "subdistrict_id": "7329",
        "district_id": "910",
        "subdistrict_name": "ยะลา"
    },
    {
        "subdistrict_id": "7330",
        "district_id": "910",
        "subdistrict_name": "ท่าสาป"
    },
    {
        "subdistrict_id": "7331",
        "district_id": "910",
        "subdistrict_name": "ลำใหม่"
    },
    {
        "subdistrict_id": "7332",
        "district_id": "910",
        "subdistrict_name": "หน้าถ้ำ"
    },
    {
        "subdistrict_id": "7333",
        "district_id": "910",
        "subdistrict_name": "ลำพะยา"
    },
    {
        "subdistrict_id": "7334",
        "district_id": "910",
        "subdistrict_name": "เปาะเส้ง"
    },
    {
        "subdistrict_id": "7335",
        "district_id": "910",
        "subdistrict_name": "พร่อน"
    },
    {
        "subdistrict_id": "7336",
        "district_id": "910",
        "subdistrict_name": "บันนังสาเรง"
    },
    {
        "subdistrict_id": "7337",
        "district_id": "910",
        "subdistrict_name": "สะเตงนอก"
    },
    {
        "subdistrict_id": "7338",
        "district_id": "910",
        "subdistrict_name": "ตาเซะ"
    },
    {
        "subdistrict_id": "7339",
        "district_id": "911",
        "subdistrict_name": "เบตง"
    },
    {
        "subdistrict_id": "7340",
        "district_id": "911",
        "subdistrict_name": "ยะรม"
    },
    {
        "subdistrict_id": "7341",
        "district_id": "911",
        "subdistrict_name": "ตาเนาะแมเราะ"
    },
    {
        "subdistrict_id": "7342",
        "district_id": "911",
        "subdistrict_name": "อัยเยอร์เวง"
    },
    {
        "subdistrict_id": "7343",
        "district_id": "911",
        "subdistrict_name": "ธารน้ำทิพย์"
    },
    {
        "subdistrict_id": "7344",
        "district_id": "912",
        "subdistrict_name": "บันนังสตา"
    },
    {
        "subdistrict_id": "7345",
        "district_id": "912",
        "subdistrict_name": "บาเจาะ"
    },
    {
        "subdistrict_id": "7346",
        "district_id": "912",
        "subdistrict_name": "ตาเนาะปูเต๊ะ"
    },
    {
        "subdistrict_id": "7347",
        "district_id": "912",
        "subdistrict_name": "ถ้ำทะลุ"
    },
    {
        "subdistrict_id": "7348",
        "district_id": "912",
        "subdistrict_name": "ตลิ่งชัน"
    },
    {
        "subdistrict_id": "7349",
        "district_id": "912",
        "subdistrict_name": "เขื่อนบางลาง"
    },
    {
        "subdistrict_id": "7350",
        "district_id": "913",
        "subdistrict_name": "ธารโต"
    },
    {
        "subdistrict_id": "7351",
        "district_id": "913",
        "subdistrict_name": "บ้านแหร"
    },
    {
        "subdistrict_id": "7352",
        "district_id": "913",
        "subdistrict_name": "แม่หวาด"
    },
    {
        "subdistrict_id": "7353",
        "district_id": "913",
        "subdistrict_name": "คีรีเขต"
    },
    {
        "subdistrict_id": "7354",
        "district_id": "914",
        "subdistrict_name": "ยะหา"
    },
    {
        "subdistrict_id": "7355",
        "district_id": "914",
        "subdistrict_name": "ละแอ"
    },
    {
        "subdistrict_id": "7356",
        "district_id": "914",
        "subdistrict_name": "ปะแต"
    },
    {
        "subdistrict_id": "7357",
        "district_id": "914",
        "subdistrict_name": "บาโร๊ะ"
    },
    {
        "subdistrict_id": "7358",
        "district_id": "914",
        "subdistrict_name": "ตาชี"
    },
    {
        "subdistrict_id": "7359",
        "district_id": "914",
        "subdistrict_name": "บาโงยซิแน"
    },
    {
        "subdistrict_id": "7360",
        "district_id": "914",
        "subdistrict_name": "กาตอง"
    },
    {
        "subdistrict_id": "7361",
        "district_id": "915",
        "subdistrict_name": "กายูบอเกาะ"
    },
    {
        "subdistrict_id": "7362",
        "district_id": "915",
        "subdistrict_name": "กาลูปัง"
    },
    {
        "subdistrict_id": "7363",
        "district_id": "915",
        "subdistrict_name": "กาลอ"
    },
    {
        "subdistrict_id": "7364",
        "district_id": "915",
        "subdistrict_name": "กอตอตือร๊ะ"
    },
    {
        "subdistrict_id": "7365",
        "district_id": "915",
        "subdistrict_name": "โกตาบารู"
    },
    {
        "subdistrict_id": "7366",
        "district_id": "915",
        "subdistrict_name": "เกะรอ"
    },
    {
        "subdistrict_id": "7367",
        "district_id": "915",
        "subdistrict_name": "จะกว๊ะ"
    },
    {
        "subdistrict_id": "7368",
        "district_id": "915",
        "subdistrict_name": "ท่าธง"
    },
    {
        "subdistrict_id": "7369",
        "district_id": "915",
        "subdistrict_name": "เนินงาม"
    },
    {
        "subdistrict_id": "7370",
        "district_id": "915",
        "subdistrict_name": "บาลอ"
    },
    {
        "subdistrict_id": "7371",
        "district_id": "915",
        "subdistrict_name": "บาโงย"
    },
    {
        "subdistrict_id": "7372",
        "district_id": "915",
        "subdistrict_name": "บือมัง"
    },
    {
        "subdistrict_id": "7373",
        "district_id": "915",
        "subdistrict_name": "ยะต๊ะ"
    },
    {
        "subdistrict_id": "7374",
        "district_id": "915",
        "subdistrict_name": "วังพญา"
    },
    {
        "subdistrict_id": "7375",
        "district_id": "915",
        "subdistrict_name": "อาซ่อง"
    },
    {
        "subdistrict_id": "7376",
        "district_id": "915",
        "subdistrict_name": "ตะโล๊ะหะลอ"
    },
    {
        "subdistrict_id": "7377",
        "district_id": "916",
        "subdistrict_name": "กาบัง"
    },
    {
        "subdistrict_id": "7378",
        "district_id": "916",
        "subdistrict_name": "บาละ"
    },
    {
        "subdistrict_id": "7379",
        "district_id": "917",
        "subdistrict_name": "กรงปินัง"
    },
    {
        "subdistrict_id": "7380",
        "district_id": "917",
        "subdistrict_name": "สะเอะ"
    },
    {
        "subdistrict_id": "7381",
        "district_id": "917",
        "subdistrict_name": "ห้วยกระทิง"
    },
    {
        "subdistrict_id": "7382",
        "district_id": "917",
        "subdistrict_name": "ปุโรง"
    },
    {
        "subdistrict_id": "7383",
        "district_id": "918",
        "subdistrict_name": "บางนาค"
    },
    {
        "subdistrict_id": "7384",
        "district_id": "918",
        "subdistrict_name": "ลำภู"
    },
    {
        "subdistrict_id": "7385",
        "district_id": "918",
        "subdistrict_name": "มะนังตายอ"
    },
    {
        "subdistrict_id": "7386",
        "district_id": "918",
        "subdistrict_name": "บางปอ"
    },
    {
        "subdistrict_id": "7387",
        "district_id": "918",
        "subdistrict_name": "กะลุวอ"
    },
    {
        "subdistrict_id": "7388",
        "district_id": "918",
        "subdistrict_name": "กะลุวอเหนือ"
    },
    {
        "subdistrict_id": "7389",
        "district_id": "918",
        "subdistrict_name": "โคกเคียน"
    },
    {
        "subdistrict_id": "7390",
        "district_id": "919",
        "subdistrict_name": "เจ๊ะเห"
    },
    {
        "subdistrict_id": "7391",
        "district_id": "919",
        "subdistrict_name": "ไพรวัน"
    },
    {
        "subdistrict_id": "7392",
        "district_id": "919",
        "subdistrict_name": "พร่อน"
    },
    {
        "subdistrict_id": "7393",
        "district_id": "919",
        "subdistrict_name": "ศาลาใหม่"
    },
    {
        "subdistrict_id": "7394",
        "district_id": "919",
        "subdistrict_name": "บางขุนทอง"
    },
    {
        "subdistrict_id": "7395",
        "district_id": "919",
        "subdistrict_name": "เกาะสะท้อน"
    },
    {
        "subdistrict_id": "7396",
        "district_id": "919",
        "subdistrict_name": "นานาค"
    },
    {
        "subdistrict_id": "7397",
        "district_id": "919",
        "subdistrict_name": "โฆษิต"
    },
    {
        "subdistrict_id": "7398",
        "district_id": "920",
        "subdistrict_name": "บาเจาะ"
    },
    {
        "subdistrict_id": "7399",
        "district_id": "920",
        "subdistrict_name": "ลุโบะสาวอ"
    },
    {
        "subdistrict_id": "7400",
        "district_id": "920",
        "subdistrict_name": "กาเยาะมาตี"
    },
    {
        "subdistrict_id": "7401",
        "district_id": "920",
        "subdistrict_name": "ปะลุกาสาเมาะ"
    },
    {
        "subdistrict_id": "7402",
        "district_id": "920",
        "subdistrict_name": "บาเระเหนือ"
    },
    {
        "subdistrict_id": "7403",
        "district_id": "920",
        "subdistrict_name": "บาเระใต้"
    },
    {
        "subdistrict_id": "7404",
        "district_id": "921",
        "subdistrict_name": "ยี่งอ"
    },
    {
        "subdistrict_id": "7405",
        "district_id": "921",
        "subdistrict_name": "ละหาร"
    },
    {
        "subdistrict_id": "7406",
        "district_id": "921",
        "subdistrict_name": "จอเบาะ"
    },
    {
        "subdistrict_id": "7407",
        "district_id": "921",
        "subdistrict_name": "ลุโบะบายะ"
    },
    {
        "subdistrict_id": "7408",
        "district_id": "921",
        "subdistrict_name": "ลุโบะบือซา"
    },
    {
        "subdistrict_id": "7409",
        "district_id": "921",
        "subdistrict_name": "ตะปอเยาะ"
    },
    {
        "subdistrict_id": "7410",
        "district_id": "922",
        "subdistrict_name": "ตันหยงมัส"
    },
    {
        "subdistrict_id": "7411",
        "district_id": "922",
        "subdistrict_name": "ตันหยงลิมอ"
    },
    {
        "subdistrict_id": "7412",
        "district_id": "922",
        "subdistrict_name": "บองอ"
    },
    {
        "subdistrict_id": "7413",
        "district_id": "922",
        "subdistrict_name": "กาลิซา"
    },
    {
        "subdistrict_id": "7414",
        "district_id": "922",
        "subdistrict_name": "บาโงสะโต"
    },
    {
        "subdistrict_id": "7415",
        "district_id": "922",
        "subdistrict_name": "เฉลิม"
    },
    {
        "subdistrict_id": "7416",
        "district_id": "922",
        "subdistrict_name": "มะรือโบตก"
    },
    {
        "subdistrict_id": "7417",
        "district_id": "923",
        "subdistrict_name": "รือเสาะ"
    },
    {
        "subdistrict_id": "7418",
        "district_id": "923",
        "subdistrict_name": "สาวอ"
    },
    {
        "subdistrict_id": "7419",
        "district_id": "923",
        "subdistrict_name": "เรียง"
    },
    {
        "subdistrict_id": "7420",
        "district_id": "923",
        "subdistrict_name": "สามัคคี"
    },
    {
        "subdistrict_id": "7421",
        "district_id": "923",
        "subdistrict_name": "บาตง"
    },
    {
        "subdistrict_id": "7422",
        "district_id": "923",
        "subdistrict_name": "ลาโละ"
    },
    {
        "subdistrict_id": "7423",
        "district_id": "923",
        "subdistrict_name": "รือเสาะออก"
    },
    {
        "subdistrict_id": "7424",
        "district_id": "923",
        "subdistrict_name": "โคกสะตอ"
    },
    {
        "subdistrict_id": "7425",
        "district_id": "923",
        "subdistrict_name": "สุวารี"
    },
    {
        "subdistrict_id": "7426",
        "district_id": "924",
        "subdistrict_name": "ซากอ"
    },
    {
        "subdistrict_id": "7427",
        "district_id": "924",
        "subdistrict_name": "ตะมะยูง"
    },
    {
        "subdistrict_id": "7428",
        "district_id": "924",
        "subdistrict_name": "ศรีสาคร"
    },
    {
        "subdistrict_id": "7429",
        "district_id": "924",
        "subdistrict_name": "เชิงคีรี"
    },
    {
        "subdistrict_id": "7430",
        "district_id": "924",
        "subdistrict_name": "กาหลง"
    },
    {
        "subdistrict_id": "7431",
        "district_id": "924",
        "subdistrict_name": "ศรีบรรพต"
    },
    {
        "subdistrict_id": "7432",
        "district_id": "925",
        "subdistrict_name": "แว้ง"
    },
    {
        "subdistrict_id": "7433",
        "district_id": "925",
        "subdistrict_name": "กายูคละ"
    },
    {
        "subdistrict_id": "7434",
        "district_id": "925",
        "subdistrict_name": "ฆอเลาะ"
    },
    {
        "subdistrict_id": "7435",
        "district_id": "925",
        "subdistrict_name": "โละจูด"
    },
    {
        "subdistrict_id": "7436",
        "district_id": "925",
        "subdistrict_name": "แม่ดง"
    },
    {
        "subdistrict_id": "7437",
        "district_id": "925",
        "subdistrict_name": "เอราวัณ"
    },
    {
        "subdistrict_id": "7438",
        "district_id": "926",
        "subdistrict_name": "มาโมง"
    },
    {
        "subdistrict_id": "7439",
        "district_id": "926",
        "subdistrict_name": "สุคิริน"
    },
    {
        "subdistrict_id": "7440",
        "district_id": "926",
        "subdistrict_name": "เกียร์"
    },
    {
        "subdistrict_id": "7441",
        "district_id": "926",
        "subdistrict_name": "ภูเขาทอง"
    },
    {
        "subdistrict_id": "7442",
        "district_id": "926",
        "subdistrict_name": "ร่มไทร"
    },
    {
        "subdistrict_id": "7443",
        "district_id": "927",
        "subdistrict_name": "สุไหงโก-ลก"
    },
    {
        "subdistrict_id": "7444",
        "district_id": "927",
        "subdistrict_name": "ปาเสมัส"
    },
    {
        "subdistrict_id": "7445",
        "district_id": "927",
        "subdistrict_name": "มูโนะ"
    },
    {
        "subdistrict_id": "7446",
        "district_id": "927",
        "subdistrict_name": "ปูโยะ"
    },
    {
        "subdistrict_id": "7447",
        "district_id": "928",
        "subdistrict_name": "ปะลุรู"
    },
    {
        "subdistrict_id": "7448",
        "district_id": "928",
        "subdistrict_name": "สุไหงปาดี"
    },
    {
        "subdistrict_id": "7449",
        "district_id": "928",
        "subdistrict_name": "โต๊ะเด็ง"
    },
    {
        "subdistrict_id": "7450",
        "district_id": "928",
        "subdistrict_name": "สากอ"
    },
    {
        "subdistrict_id": "7451",
        "district_id": "928",
        "subdistrict_name": "ริโก๋"
    },
    {
        "subdistrict_id": "7452",
        "district_id": "928",
        "subdistrict_name": "กาวะ"
    },
    {
        "subdistrict_id": "7453",
        "district_id": "929",
        "subdistrict_name": "จะแนะ"
    },
    {
        "subdistrict_id": "7454",
        "district_id": "929",
        "subdistrict_name": "ดุซงญอ"
    },
    {
        "subdistrict_id": "7455",
        "district_id": "929",
        "subdistrict_name": "ผดุงมาตร"
    },
    {
        "subdistrict_id": "7456",
        "district_id": "929",
        "subdistrict_name": "ช้างเผือก"
    },
    {
        "subdistrict_id": "7457",
        "district_id": "930",
        "subdistrict_name": "จวบ"
    },
    {
        "subdistrict_id": "7458",
        "district_id": "930",
        "subdistrict_name": "บูกิต"
    },
    {
        "subdistrict_id": "7459",
        "district_id": "930",
        "subdistrict_name": "มะรือโบออก"
    },
    {
        "subdistrict_id": "7460",
        "district_id": "26",
        "subdistrict_name": "วงศ์สว่าง"
    },
    {
        "subdistrict_id": "7461",
        "district_id": "43",
        "subdistrict_name": "รามอินทรา"
    },
    {
        "subdistrict_id": "7462",
        "district_id": "423",
        "subdistrict_name": "เซกา"
    },
    {
        "subdistrict_id": "7463",
        "district_id": "423",
        "subdistrict_name": "ซาง"
    },
    {
        "subdistrict_id": "7464",
        "district_id": "423",
        "subdistrict_name": "ท่ากกแดง"
    },
    {
        "subdistrict_id": "7465",
        "district_id": "423",
        "subdistrict_name": "บ้านต้อง"
    },
    {
        "subdistrict_id": "7466",
        "district_id": "423",
        "subdistrict_name": "ป่งไฮ"
    },
    {
        "subdistrict_id": "7467",
        "district_id": "423",
        "subdistrict_name": "น้ำจั้น"
    },
    {
        "subdistrict_id": "7468",
        "district_id": "423",
        "subdistrict_name": "ท่าสะอาด"
    },
    {
        "subdistrict_id": "7469",
        "district_id": "423",
        "subdistrict_name": "หนองทุ่ม"
    },
    {
        "subdistrict_id": "7470",
        "district_id": "423",
        "subdistrict_name": "โสกก่าม"
    },
    {
        "subdistrict_id": "7471",
        "district_id": "420",
        "subdistrict_name": "โซ่"
    },
    {
        "subdistrict_id": "7472",
        "district_id": "420",
        "subdistrict_name": "หนองพันทา"
    },
    {
        "subdistrict_id": "7473",
        "district_id": "420",
        "subdistrict_name": "ศรีชมภู"
    },
    {
        "subdistrict_id": "7474",
        "district_id": "420",
        "subdistrict_name": "คำแก้ว"
    },
    {
        "subdistrict_id": "7475",
        "district_id": "420",
        "subdistrict_name": "บัวตูม"
    },
    {
        "subdistrict_id": "7476",
        "district_id": "420",
        "subdistrict_name": "ถ้ำเจริญ"
    },
    {
        "subdistrict_id": "7477",
        "district_id": "420",
        "subdistrict_name": "เหล่าทอง"
    },
    {
        "subdistrict_id": "7478",
        "district_id": "36",
        "subdistrict_name": "ดอนเมือง"
    },
    {
        "subdistrict_id": "7479",
        "district_id": "36",
        "subdistrict_name": "สนามบิน"
    },
    {
        "subdistrict_id": "7480",
        "district_id": "29",
        "subdistrict_name": "วงศ์สว่าง"
    },
    {
        "subdistrict_id": "7481",
        "district_id": "27",
        "subdistrict_name": "นวมินทร์"
    },
    {
        "subdistrict_id": "7482",
        "district_id": "27",
        "subdistrict_name": "นวลจันทร์"
    },
    {
        "subdistrict_id": "7483",
        "district_id": "425",
        "subdistrict_name": "บึงโขงหลง"
    },
    {
        "subdistrict_id": "7484",
        "district_id": "425",
        "subdistrict_name": "โพธิ์หมากแข้ง"
    },
    {
        "subdistrict_id": "7485",
        "district_id": "425",
        "subdistrict_name": "ดงบัง"
    },
    {
        "subdistrict_id": "7486",
        "district_id": "425",
        "subdistrict_name": "ท่าดอกคำ"
    },
    {
        "subdistrict_id": "7487",
        "district_id": "427",
        "subdistrict_name": "บุ่งคล้า"
    },
    {
        "subdistrict_id": "7488",
        "district_id": "427",
        "subdistrict_name": "หนองเดิ่น"
    },
    {
        "subdistrict_id": "7489",
        "district_id": "427",
        "subdistrict_name": "โคกกว้าง"
    },
    {
        "subdistrict_id": "7490",
        "district_id": "424",
        "subdistrict_name": "ปากคาด"
    },
    {
        "subdistrict_id": "7491",
        "district_id": "424",
        "subdistrict_name": "หนองยอง"
    },
    {
        "subdistrict_id": "7492",
        "district_id": "424",
        "subdistrict_name": "นากั้ง"
    },
    {
        "subdistrict_id": "7493",
        "district_id": "424",
        "subdistrict_name": "โนนศิลา"
    },
    {
        "subdistrict_id": "7494",
        "district_id": "424",
        "subdistrict_name": "สมสนุก"
    },
    {
        "subdistrict_id": "7495",
        "district_id": "424",
        "subdistrict_name": "นาดง"
    },
    {
        "subdistrict_id": "7496",
        "district_id": "418",
        "subdistrict_name": "ศรีชมภู"
    },
    {
        "subdistrict_id": "7497",
        "district_id": "418",
        "subdistrict_name": "ดอนหญ้านาง"
    },
    {
        "subdistrict_id": "7498",
        "district_id": "418",
        "subdistrict_name": "พรเจริญ"
    },
    {
        "subdistrict_id": "7499",
        "district_id": "418",
        "subdistrict_name": "หนองหัวช้าง"
    },
    {
        "subdistrict_id": "7500",
        "district_id": "418",
        "subdistrict_name": "วังชมภู"
    },
    {
        "subdistrict_id": "7501",
        "district_id": "418",
        "subdistrict_name": "ป่าแฝก"
    },
    {
        "subdistrict_id": "7502",
        "district_id": "418",
        "subdistrict_name": "ศรีสำราญ"
    },
    {
        "subdistrict_id": "7503",
        "district_id": "582",
        "subdistrict_name": "บ่อสวก"
    },
    {
        "subdistrict_id": "7504",
        "district_id": "415",
        "subdistrict_name": "โพนสว่าง"
    },
    {
        "subdistrict_id": "7505",
        "district_id": "541",
        "subdistrict_name": "ทุ่งปี๊"
    },
    {
        "subdistrict_id": "7506",
        "district_id": "442",
        "subdistrict_name": "สร้างแซ่ง"
    },
    {
        "subdistrict_id": "7507",
        "district_id": "45",
        "subdistrict_name": "สะพานสอง"
    },
    {
        "subdistrict_id": "7508",
        "district_id": "45",
        "subdistrict_name": "คลองเจ้าคุณสิงห์"
    },
    {
        "subdistrict_id": "7509",
        "district_id": "45",
        "subdistrict_name": "พลับพลา"
    },
    {
        "subdistrict_id": "7510",
        "district_id": "304",
        "subdistrict_name": "ห้วยขะยุง"
    },
    {
        "subdistrict_id": "7511",
        "district_id": "426",
        "subdistrict_name": "ศรีวิไล"
    },
    {
        "subdistrict_id": "7512",
        "district_id": "426",
        "subdistrict_name": "ชุมภูพร"
    },
    {
        "subdistrict_id": "7513",
        "district_id": "426",
        "subdistrict_name": "นาแสง"
    },
    {
        "subdistrict_id": "7514",
        "district_id": "426",
        "subdistrict_name": "นาสะแบง"
    },
    {
        "subdistrict_id": "7515",
        "district_id": "426",
        "subdistrict_name": "นาสิงห์"
    },
    {
        "subdistrict_id": "7516",
        "district_id": "931",
        "subdistrict_name": "บ้านจันทร์"
    },
    {
        "subdistrict_id": "7517",
        "district_id": "931",
        "subdistrict_name": "แม่แดด"
    },
    {
        "subdistrict_id": "7518",
        "district_id": "931",
        "subdistrict_name": "แจ่มหลวง"
    },
    {
        "subdistrict_id": "7519",
        "district_id": "423",
        "subdistrict_name": "เซกา"
    },
    {
        "subdistrict_id": "7520",
        "district_id": "932",
        "subdistrict_name": "เซกา"
    },
    {
        "subdistrict_id": "7521",
        "district_id": "423",
        "subdistrict_name": "ซาง"
    },
    {
        "subdistrict_id": "7522",
        "district_id": "932",
        "subdistrict_name": "ซาง"
    },
    {
        "subdistrict_id": "7523",
        "district_id": "423",
        "subdistrict_name": "ท่ากกแดง"
    },
    {
        "subdistrict_id": "7524",
        "district_id": "932",
        "subdistrict_name": "ท่ากกแดง"
    },
    {
        "subdistrict_id": "7525",
        "district_id": "423",
        "subdistrict_name": "บ้านต้อง"
    },
    {
        "subdistrict_id": "7526",
        "district_id": "932",
        "subdistrict_name": "บ้านต้อง"
    },
    {
        "subdistrict_id": "7527",
        "district_id": "423",
        "subdistrict_name": "ป่งไฮ"
    },
    {
        "subdistrict_id": "7528",
        "district_id": "932",
        "subdistrict_name": "ป่งไฮ"
    },
    {
        "subdistrict_id": "7529",
        "district_id": "423",
        "subdistrict_name": "น้ำจั้น"
    },
    {
        "subdistrict_id": "7530",
        "district_id": "932",
        "subdistrict_name": "น้ำจั้น"
    },
    {
        "subdistrict_id": "7531",
        "district_id": "423",
        "subdistrict_name": "ท่าสะอาด"
    },
    {
        "subdistrict_id": "7532",
        "district_id": "932",
        "subdistrict_name": "ท่าสะอาด"
    },
    {
        "subdistrict_id": "7533",
        "district_id": "423",
        "subdistrict_name": "หนองทุ่ม"
    },
    {
        "subdistrict_id": "7534",
        "district_id": "932",
        "subdistrict_name": "หนองทุ่ม"
    },
    {
        "subdistrict_id": "7535",
        "district_id": "423",
        "subdistrict_name": "โสกก่าม"
    },
    {
        "subdistrict_id": "7536",
        "district_id": "932",
        "subdistrict_name": "โสกก่าม"
    },
    {
        "subdistrict_id": "7537",
        "district_id": "420",
        "subdistrict_name": "โซ่"
    },
    {
        "subdistrict_id": "7538",
        "district_id": "933",
        "subdistrict_name": "โซ่"
    },
    {
        "subdistrict_id": "7539",
        "district_id": "420",
        "subdistrict_name": "หนองพันทา"
    },
    {
        "subdistrict_id": "7540",
        "district_id": "933",
        "subdistrict_name": "หนองพันทา"
    },
    {
        "subdistrict_id": "7541",
        "district_id": "420",
        "subdistrict_name": "ศรีชมภู"
    },
    {
        "subdistrict_id": "7542",
        "district_id": "933",
        "subdistrict_name": "ศรีชมภู"
    },
    {
        "subdistrict_id": "7543",
        "district_id": "420",
        "subdistrict_name": "คำแก้ว"
    },
    {
        "subdistrict_id": "7544",
        "district_id": "933",
        "subdistrict_name": "คำแก้ว"
    },
    {
        "subdistrict_id": "7545",
        "district_id": "420",
        "subdistrict_name": "บัวตูม"
    },
    {
        "subdistrict_id": "7546",
        "district_id": "933",
        "subdistrict_name": "บัวตูม"
    },
    {
        "subdistrict_id": "7547",
        "district_id": "420",
        "subdistrict_name": "ถ้ำเจริญ"
    },
    {
        "subdistrict_id": "7548",
        "district_id": "933",
        "subdistrict_name": "ถ้ำเจริญ"
    },
    {
        "subdistrict_id": "7549",
        "district_id": "420",
        "subdistrict_name": "เหล่าทอง"
    },
    {
        "subdistrict_id": "7550",
        "district_id": "933",
        "subdistrict_name": "เหล่าทอง"
    },
    {
        "subdistrict_id": "7551",
        "district_id": "425",
        "subdistrict_name": "บึงโขงหลง"
    },
    {
        "subdistrict_id": "7552",
        "district_id": "934",
        "subdistrict_name": "บึงโขงหลง"
    },
    {
        "subdistrict_id": "7553",
        "district_id": "425",
        "subdistrict_name": "โพธิ์หมากแข้ง"
    },
    {
        "subdistrict_id": "7554",
        "district_id": "934",
        "subdistrict_name": "โพธิ์หมากแข้ง"
    },
    {
        "subdistrict_id": "7555",
        "district_id": "425",
        "subdistrict_name": "ดงบัง"
    },
    {
        "subdistrict_id": "7556",
        "district_id": "934",
        "subdistrict_name": "ดงบัง"
    },
    {
        "subdistrict_id": "7557",
        "district_id": "425",
        "subdistrict_name": "ท่าดอกคำ"
    },
    {
        "subdistrict_id": "7558",
        "district_id": "934",
        "subdistrict_name": "ท่าดอกคำ"
    },
    {
        "subdistrict_id": "7559",
        "district_id": "427",
        "subdistrict_name": "บุ่งคล้า"
    },
    {
        "subdistrict_id": "7560",
        "district_id": "935",
        "subdistrict_name": "บุ่งคล้า"
    },
    {
        "subdistrict_id": "7561",
        "district_id": "427",
        "subdistrict_name": "หนองเดิ่น"
    },
    {
        "subdistrict_id": "7562",
        "district_id": "935",
        "subdistrict_name": "หนองเดิ่น"
    },
    {
        "subdistrict_id": "7563",
        "district_id": "427",
        "subdistrict_name": "โคกกว้าง"
    },
    {
        "subdistrict_id": "7564",
        "district_id": "935",
        "subdistrict_name": "โคกกว้าง"
    },
    {
        "subdistrict_id": "7565",
        "district_id": "936",
        "subdistrict_name": "เสียว"
    },
    {
        "subdistrict_id": "7566",
        "district_id": "936",
        "subdistrict_name": "หนองหว้า"
    },
    {
        "subdistrict_id": "7567",
        "district_id": "936",
        "subdistrict_name": "หนองงูเหลือม"
    },
    {
        "subdistrict_id": "7568",
        "district_id": "936",
        "subdistrict_name": "หนองฮาง"
    },
    {
        "subdistrict_id": "7569",
        "district_id": "936",
        "subdistrict_name": "ท่าคล้อ"
    },
    {
        "subdistrict_id": "7570",
        "district_id": "424",
        "subdistrict_name": "ปากคาด"
    },
    {
        "subdistrict_id": "7571",
        "district_id": "937",
        "subdistrict_name": "ปากคาด"
    },
    {
        "subdistrict_id": "7572",
        "district_id": "424",
        "subdistrict_name": "หนองยอง"
    },
    {
        "subdistrict_id": "7573",
        "district_id": "937",
        "subdistrict_name": "หนองยอง"
    },
    {
        "subdistrict_id": "7574",
        "district_id": "424",
        "subdistrict_name": "นากั้ง"
    },
    {
        "subdistrict_id": "7575",
        "district_id": "937",
        "subdistrict_name": "นากั้ง"
    },
    {
        "subdistrict_id": "7576",
        "district_id": "424",
        "subdistrict_name": "โนนศิลา"
    },
    {
        "subdistrict_id": "7577",
        "district_id": "937",
        "subdistrict_name": "โนนศิลา"
    },
    {
        "subdistrict_id": "7578",
        "district_id": "424",
        "subdistrict_name": "สมสนุก"
    },
    {
        "subdistrict_id": "7579",
        "district_id": "937",
        "subdistrict_name": "สมสนุก"
    },
    {
        "subdistrict_id": "7580",
        "district_id": "424",
        "subdistrict_name": "นาดง"
    },
    {
        "subdistrict_id": "7581",
        "district_id": "937",
        "subdistrict_name": "นาดง"
    },
    {
        "subdistrict_id": "7582",
        "district_id": "418",
        "subdistrict_name": "ศรีชมภู"
    },
    {
        "subdistrict_id": "7583",
        "district_id": "938",
        "subdistrict_name": "ศรีชมภู"
    },
    {
        "subdistrict_id": "7584",
        "district_id": "418",
        "subdistrict_name": "ดอนหญ้านาง"
    },
    {
        "subdistrict_id": "7585",
        "district_id": "938",
        "subdistrict_name": "ดอนหญ้านาง"
    },
    {
        "subdistrict_id": "7586",
        "district_id": "418",
        "subdistrict_name": "พรเจริญ"
    },
    {
        "subdistrict_id": "7587",
        "district_id": "938",
        "subdistrict_name": "พรเจริญ"
    },
    {
        "subdistrict_id": "7588",
        "district_id": "418",
        "subdistrict_name": "หนองหัวช้าง"
    },
    {
        "subdistrict_id": "7589",
        "district_id": "938",
        "subdistrict_name": "หนองหัวช้าง"
    },
    {
        "subdistrict_id": "7590",
        "district_id": "418",
        "subdistrict_name": "วังชมภู"
    },
    {
        "subdistrict_id": "7591",
        "district_id": "938",
        "subdistrict_name": "วังชมภู"
    },
    {
        "subdistrict_id": "7592",
        "district_id": "418",
        "subdistrict_name": "ป่าแฝก"
    },
    {
        "subdistrict_id": "7593",
        "district_id": "938",
        "subdistrict_name": "ป่าแฝก"
    },
    {
        "subdistrict_id": "7594",
        "district_id": "418",
        "subdistrict_name": "ศรีสำราญ"
    },
    {
        "subdistrict_id": "7595",
        "district_id": "938",
        "subdistrict_name": "ศรีสำราญ"
    },
    {
        "subdistrict_id": "7596",
        "district_id": "939",
        "subdistrict_name": "บึงกาฬ"
    },
    {
        "subdistrict_id": "7597",
        "district_id": "939",
        "subdistrict_name": "โนนสมบูรณ์"
    },
    {
        "subdistrict_id": "7598",
        "district_id": "939",
        "subdistrict_name": "โนนสว่าง"
    },
    {
        "subdistrict_id": "7599",
        "district_id": "939",
        "subdistrict_name": "หอคำ"
    },
    {
        "subdistrict_id": "7600",
        "district_id": "939",
        "subdistrict_name": "หนองเลิง"
    },
    {
        "subdistrict_id": "7601",
        "district_id": "939",
        "subdistrict_name": "โคกก่อง"
    },
    {
        "subdistrict_id": "7602",
        "district_id": "939",
        "subdistrict_name": "นาสวรรค์"
    },
    {
        "subdistrict_id": "7603",
        "district_id": "939",
        "subdistrict_name": "ไคสี"
    },
    {
        "subdistrict_id": "7604",
        "district_id": "939",
        "subdistrict_name": "ชัยพร"
    },
    {
        "subdistrict_id": "7605",
        "district_id": "939",
        "subdistrict_name": "วิศิษฐ์"
    },
    {
        "subdistrict_id": "7606",
        "district_id": "939",
        "subdistrict_name": "คำนาดี"
    },
    {
        "subdistrict_id": "7607",
        "district_id": "939",
        "subdistrict_name": "โป่งเปือย"
    },
    {
        "subdistrict_id": "7608",
        "district_id": "426",
        "subdistrict_name": "ศรีวิไล"
    },
    {
        "subdistrict_id": "7609",
        "district_id": "940",
        "subdistrict_name": "ศรีวิไล"
    },
    {
        "subdistrict_id": "7610",
        "district_id": "426",
        "subdistrict_name": "ชุมภูพร"
    },
    {
        "subdistrict_id": "7611",
        "district_id": "940",
        "subdistrict_name": "ชุมภูพร"
    },
    {
        "subdistrict_id": "7612",
        "district_id": "426",
        "subdistrict_name": "นาแสง"
    },
    {
        "subdistrict_id": "7613",
        "district_id": "940",
        "subdistrict_name": "นาแสง"
    },
    {
        "subdistrict_id": "7614",
        "district_id": "426",
        "subdistrict_name": "นาสะแบง"
    },
    {
        "subdistrict_id": "7615",
        "district_id": "940",
        "subdistrict_name": "นาสะแบง"
    },
    {
        "subdistrict_id": "7616",
        "district_id": "426",
        "subdistrict_name": "นาสิงห์"
    },
    {
        "subdistrict_id": "7617",
        "district_id": "940",
        "subdistrict_name": "นาสิงห์"
    },
    {
        "subdistrict_id": "7618",
        "district_id": "941",
        "subdistrict_name": "ระแงง"
    },
    {
        "subdistrict_id": "7619",
        "district_id": "941",
        "subdistrict_name": "ตรึม"
    },
    {
        "subdistrict_id": "7620",
        "district_id": "941",
        "subdistrict_name": "จารพัต"
    },
    {
        "subdistrict_id": "7621",
        "district_id": "941",
        "subdistrict_name": "ยาง"
    },
    {
        "subdistrict_id": "7622",
        "district_id": "941",
        "subdistrict_name": "แตล"
    },
    {
        "subdistrict_id": "7623",
        "district_id": "941",
        "subdistrict_name": "หนองบัว"
    },
    {
        "subdistrict_id": "7624",
        "district_id": "941",
        "subdistrict_name": "คาละแมะ"
    },
    {
        "subdistrict_id": "7625",
        "district_id": "941",
        "subdistrict_name": "หนองเหล็ก"
    },
    {
        "subdistrict_id": "7626",
        "district_id": "941",
        "subdistrict_name": "หนองขวาว"
    },
    {
        "subdistrict_id": "7627",
        "district_id": "941",
        "subdistrict_name": "ช่างปี่"
    },
    {
        "subdistrict_id": "7628",
        "district_id": "941",
        "subdistrict_name": "กุดหวาย"
    },
    {
        "subdistrict_id": "7629",
        "district_id": "941",
        "subdistrict_name": "ขวาวใหญ่"
    },
    {
        "subdistrict_id": "7630",
        "district_id": "941",
        "subdistrict_name": "นารุ่ง"
    },
    {
        "subdistrict_id": "7631",
        "district_id": "941",
        "subdistrict_name": "ตรมไพร"
    },
    {
        "subdistrict_id": "7632",
        "district_id": "941",
        "subdistrict_name": "ผักไหม"
    },
    {
        "subdistrict_id": "7633",
        "district_id": "942",
        "subdistrict_name": "คู้ยายหมี"
    },
    {
        "subdistrict_id": "7634",
        "district_id": "942",
        "subdistrict_name": "ท่ากระดาน"
    },
    {
        "subdistrict_id": "7635",
        "district_id": "942",
        "subdistrict_name": "ทุ่งพระยา"
    },
    {
        "subdistrict_id": "7636",
        "district_id": "942",
        "subdistrict_name": "ท่าตะเกียบ"
    },
    {
        "subdistrict_id": "7637",
        "district_id": "942",
        "subdistrict_name": "ลาดกระทิง"
    },
    {
        "subdistrict_id": "7638",
        "district_id": "942",
        "subdistrict_name": "คลองตะเกรา"
    },
    {
        "subdistrict_id": "7639",
        "district_id": "944",
        "subdistrict_name": "หนองบุนนาก"
    },
    {
        "subdistrict_id": "7640",
        "district_id": "944",
        "subdistrict_name": "สารภี"
    },
    {
        "subdistrict_id": "7641",
        "district_id": "944",
        "subdistrict_name": "ไทยเจริญ"
    },
    {
        "subdistrict_id": "7642",
        "district_id": "944",
        "subdistrict_name": "หนองหัวแรต"
    },
    {
        "subdistrict_id": "7643",
        "district_id": "944",
        "subdistrict_name": "แหลมทอง"
    },
    {
        "subdistrict_id": "7644",
        "district_id": "944",
        "subdistrict_name": "หนองตะไก้"
    },
    {
        "subdistrict_id": "7645",
        "district_id": "944",
        "subdistrict_name": "ลุงเขว้า"
    },
    {
        "subdistrict_id": "7646",
        "district_id": "944",
        "subdistrict_name": "หนองไม้ไผ่"
    },
    {
        "subdistrict_id": "7647",
        "district_id": "944",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "7648",
        "district_id": "143",
        "subdistrict_name": "ยายร้า"
    },
    {
        "subdistrict_id": "7649",
        "district_id": "910",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "7650",
        "district_id": "787",
        "subdistrict_name": "ช้างกลาง"
    },
    {
        "subdistrict_id": "7651",
        "district_id": "147",
        "subdistrict_name": "ห้วยปราบ"
    },
    {
        "subdistrict_id": "7652",
        "district_id": "4",
        "subdistrict_name": "ศรีเวียง"
    },
    {
        "subdistrict_id": "7653",
        "district_id": "776",
        "subdistrict_name": "บ่อฝ้าย"
    },
    {
        "subdistrict_id": "7654",
        "district_id": "568",
        "subdistrict_name": "ท่าแฝก"
    },
    {
        "subdistrict_id": "7655",
        "district_id": "142",
        "subdistrict_name": "ในเมือง"
    },
    {
        "subdistrict_id": "7656",
        "district_id": "17",
        "subdistrict_name": "สามเสนใน"
    },
    {
        "subdistrict_id": "7657",
        "district_id": "945",
        "subdistrict_name": "ไทยเจริญ"
    },
    {
        "subdistrict_id": "7658",
        "district_id": "945",
        "subdistrict_name": "บ้านใหม่"
    },
    {
        "subdistrict_id": "7659",
        "district_id": "945",
        "subdistrict_name": "ลุงเขว้า"
    },
    {
        "subdistrict_id": "7660",
        "district_id": "945",
        "subdistrict_name": "สารภี"
    },
    {
        "subdistrict_id": "7661",
        "district_id": "945",
        "subdistrict_name": "หนองตะไก้"
    },
    {
        "subdistrict_id": "7662",
        "district_id": "945",
        "subdistrict_name": "หนองบุนนาก"
    },
    {
        "subdistrict_id": "7663",
        "district_id": "945",
        "subdistrict_name": "หนองไม้ไผ่"
    },
    {
        "subdistrict_id": "7664",
        "district_id": "945",
        "subdistrict_name": "หนองหัวแรต"
    },
    {
        "subdistrict_id": "7665",
        "district_id": "945",
        "subdistrict_name": "แหลมทอง"
    },
    {
        "subdistrict_id": "7666",
        "district_id": "939",
        "subdistrict_name": "หนองเข็ง"
    },
    {
        "subdistrict_id": "7667",
        "district_id": "235",
        "subdistrict_name": "หนองตระครอง"
    },
    {
        "subdistrict_id": "7668",
        "district_id": "574",
        "subdistrict_name": "วังหงษ์"
    },
    {
        "subdistrict_id": "7669",
        "district_id": "452",
        "subdistrict_name": "ขามเบี้ย"
    },
    {
        "subdistrict_id": "7670",
        "district_id": "272",
        "subdistrict_name": "ทุ่งใหญ่"
    },
    {
        "subdistrict_id": "7671",
        "district_id": "310",
        "subdistrict_name": "นิคมลำโดมน้อย"
    },
    {
        "subdistrict_id": "7672",
        "district_id": "50",
        "subdistrict_name": "บางบอนใต้"
    },
    {
        "subdistrict_id": "7673",
        "district_id": "50",
        "subdistrict_name": "คลองบางพราน"
    },
    {
        "subdistrict_id": "7674",
        "district_id": "9",
        "subdistrict_name": "พระโขนงใต้"
    },
    {
        "subdistrict_id": "7675",
        "district_id": "14",
        "subdistrict_name": "พญาไท"
    },
    {
        "subdistrict_id": "7676",
        "district_id": "26",
        "subdistrict_name": "รัชดาภิเษก"
    },
    {
        "subdistrict_id": "7677",
        "district_id": "34",
        "subdistrict_name": "อ่อนนุช"
    },
    {
        "subdistrict_id": "7678",
        "district_id": "34",
        "subdistrict_name": "พัฒนาการ"
    },
    {
        "subdistrict_id": "7679",
        "district_id": "44",
        "subdistrict_name": "ราษฎร์พัฒนา"
    },
    {
        "subdistrict_id": "7680",
        "district_id": "44",
        "subdistrict_name": "ทับช้าง"
    },
    {
        "subdistrict_id": "7681",
        "district_id": "47",
        "subdistrict_name": "บางนาเหนือ"
    },
    {
        "subdistrict_id": "7682",
        "district_id": "47",
        "subdistrict_name": "บางนาใต้"
    },
    {
        "subdistrict_id": "7683",
        "district_id": "50",
        "subdistrict_name": "บางบอนเหนือ"
    },
    {
        "subdistrict_id": "7684",
        "district_id": "50",
        "subdistrict_name": "คลองบางบอน"
    }
]