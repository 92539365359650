import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import numeral from 'numeral'
import "./vee-validate";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import JQuery from 'jquery'
import sweetalert from 'sweetalert2'
import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);


Vue.filter('numberFormat', function (value) {
  return numeral(value).format("0,0.00");
});
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.directive('uppercase',
  {
    inserted: function (el, _, vnode) {
      el.addEventListener('input', async function (e) {
        e.target.value = e.target.value.toUpperCase()
        vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
      })
    }
  })

Vue.prototype.$liff = window.liff
Vue.prototype.$swal = sweetalert
window.$ = JQuery

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  created() {
    return (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { return; }
      js = d.createElement(s); js.id = id;
      js.src = "//connect.facebook.com/en_US/messenger.Extensions.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'Messenger'));
  },
  render: h => h(App)
}).$mount('#app')
