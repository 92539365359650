<template>
  <div v-if="!isCheckLogin">
    <Header
      :text="`ยืนยันOTP`"
      detail=""
      :customStyle="'padding-top: 24px'"
      :headerImg="13"
    ></Header>
    <div style="position: relative">
      <div class="content">
        <div class="wrapper pt-6">
          <div class="custom-font">
            <h6 class="primary-title" style="padding: 0; margin-bottom: 12px">
              <span>Verify OTP</span>
            </h6>
            <template v-if="step == 1">
              <div class="text-center">
                <div class="font-weight-bold mb-1">กรุณากรอก เบอร์มือถือ</div>

                <ValidationProvider
                  name="เบอร์มือถือ"
                  :rules="{
                    required: true,
                    thai_phonenumber: true
                  }"
                  v-slot="{ errors, valid }"
                >
                  <ThaiPhoneNumber
                    v-model="mobileno"
                    :success="valid"
                    :errors="errors"
                    :label="'เบอร์มือถือ'"
                    :showIcon="false"
                    :hasClearable="true"
                  />
                </ValidationProvider>
                <v-btn @click="requestOtp" class="mt-9 primary-btn" dark height="45px" width="200px"
                  >ถัดไป</v-btn
                >
              </div>
            </template>
            <template v-if="step == 2">
              <div class="text-center">
                <div class="font-weight-bold mb-1">กรุณากรอก OTP ที่ได้รับทาง SMS</div>
                <div class="mb-2">(Ref: {{ otpref }})</div>
                <ValidationProvider
                  name="รหัส OTP"
                  :rules="{
                    required: true,
                    min: 6
                  }"
                  v-slot="{ errors, valid }"
                >
                  <v-text-field
                    v-model="otp"
                    maxlength="6"
                    :success="wrongotp ? false : valid"
                    :error-messages="wrongotp ? wrongotp : errors"
                    style="border-radius: 0px"
                    @input="wrongotp = ''"
                    v-uppercase
                    placeholder="OTP: XXXXXX"
                  ></v-text-field>
                </ValidationProvider>
                <a :style="sendedOtp ? `color: grey` : ''" @click="requestOtp">
                  Resend OTP
                  <div>(ขอส่งใหม่ได้ทุก 60 วินาที)</div>
                </a>
                <v-btn @click="verifyOtp" class="mt-9 primary-btn" dark height="45px" width="200px"
                  >ถัดไป</v-btn
                >
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <v-row justify="center" align="center" style="height: 100vh">
      <v-col class="text-center mt-7">
        <v-progress-circular indeterminate color="blue" size="80"></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios'
import queryString from 'query-string'
import ThaiPhoneNumber from '@/components/ThaiPhoneNumber.vue'
import otpApi from '@/api/otp.js'
import { sendErrorLog } from '@/utils/sendErrorLog'

export default {
  components: {
    Header,
    ThaiPhoneNumber
  },
  data() {
    return {
      type: null,
      loading: false,
      serverUrl: 'https://api-summit-cap.zwiz.app/',
      otp: '',
      running: 0,
      isRegister: false,
      wrongotp: '',
      isCheckLogin: true,
      step: 1,
      mobileno: '',
      pageid: '',
      otpref: '',
      sendedOtp: false
    }
  },
  computed: {
    token() {
      if (this.query.apptype == 'line') {
        return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      } else {
        return '1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B'
      }
    },
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    },
    userId() {
      let tempUserId = this.query.userid
      return tempUserId
    },
    transid() {
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    },
    userId() {
      let tempUserId = this.query.userid
      return tempUserId
    },
    title() {
      let tempTitle = this.query.title
      return tempTitle || ''
    }
  },
  async mounted() {
    this.queryinit()
    await this.fetchUser()
    this.checksession()
  },
  methods: {
    async verifyOtp() {
      this.loading = true
      const body = {
        user_id: this.userId,
        page_id: this.pageid,
        otpref: this.otpref,
        otpvalue: this.otp,
        title: this.title
      }
      try {
        await otpApi.verifyOtp({ body })
        if (this.$liff) {
          this.$liff.closeWindow()
        } else {
          window.close()
        }
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'liff otp verifyOtp',
          error,
          payload: body
        })
        this.showErrorAlert({ traceId })
      } finally {
        this.loading = false
      }
    },
    resendOtp() {
      if (!this.sendedOtp) {
        this.requestOtp()
        this.sendedOtp = true
        setInterval(() => {
          this.sendedOtp = false
        }, 60000)
      }
    },
    async requestOtp() {
      this.loading = true
      const body = {
        user_id: this.userId,
        page_id: this.pageid,
        mobileno: this.mobileno
      }
      try {
        const response = await otpApi.requestOtp({ body })
        if (response && response.data && response.data.otpref) {
          this.otpref = response.data.otpref
          this.step = 2
        } else {
          throw new Error('Ref not found')
        }
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'liff otp requestOtp',
          error,
          payload: body
        })
        this.showErrorAlert({ traceId })
      } finally {
        this.loading = false
      }
    },
    async checksession() {
      const vm = this
      try {
        const userid = this.userId
        const response = await axios.get(
          this.serverUrl + `checksessionexpire?userid=${userid}&pageid=${this.pageid}`,
          {
            headers: { AUTHORIZATION: 'Bearer xxx xxx' }
          }
        )
        if (
          response &&
          response.data &&
          response.data.responsecode &&
          response.data.responsecode == 400
        ) {
          if (this.isRegister) {
            vm.$router.push({
              path: '/',
              query: { ...this.query, view: 'login', back_to: 'MultipleChangeaddress' }
            })
          } else {
            vm.$router.push({
              path: '/',
              query: { ...this.query, view: 'register' }
            })
          }
          location.reload()
        } else {
          setTimeout(() => {
            vm.isCheckLogin = false
          }, 1500)
        }
      } catch (error) {
        vm.$swal.fire({
          icon: 'error',
          text: 'เรียกข้อมูลไม่สำเร็จ'
        })
      } finally {
        setTimeout(() => {
          vm.isCheckLogin = false
        }, 1500)
      }
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    },
    async fetchUser() {
      try {
        const { status, data } = await axios.get(
          this.serverUrl + 'summit-capital/liff/getuseronly',
          {
            params: { page_id: this.pageid, user_id: this.userId },
            headers: { AUTHORIZATION: 'Bearer VlRrNU1qWmtNek0zTVdFellXSTBNVEl4Wm1ZNVlUazVOakZt=' }
          }
        )

        if (status === 200) {
          if (Array.isArray(data.data)) {
            console.error('ไม่พบผู้ใช้งาน')
            return
          }

          if (data.data.sessionvalue) {
            this.isRegister = true
          }

          const { summitidcard, phoneno } = data.data
          this.user = {
            idCardNumber: summitidcard || '',
            phoneNumber: phoneno || '0000000000'
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    showCompleteAlert() {
      return this.$swal.fire({
        icon: 'success',
        text: 'อัพเดทข้อมูลสำเร็จ'
      })
    },
    showErrorAlert({ traceId = null }) {
      this.$swal.fire({
        icon: 'error',
        html: `กรุณาติดต่อเจ้าหน้าที่${
          traceId ? `<br /><small>(Trace ID: ${traceId})</small>` : ''
        }`
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
