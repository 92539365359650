var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isCheckLogin)?_c('div',[_c('Header',{attrs:{"text":'นัดชำระเงิน'}}),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"wrapper pt-6"},[_vm._m(0),_c('div',{staticClass:"pt-4 custom-font"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.summitP2P)}}},[_c('div',[_c('ValidationProvider',{attrs:{"name":"Contract ID","rules":{
                      required: true
                    }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.optionsContract,"label":"Contract ID"},on:{"change":_vm.contractidChange},model:{value:(_vm.input.contractid),callback:function ($$v) {_vm.$set(_vm.input, "contractid", $$v)},expression:"input.contractid"}})]}}],null,true)}),(_vm.input.contractid)?_c('div',[_c('div',{staticClass:"pt-3"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":true,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('ValidationProvider',{attrs:{"mode":"passive","name":"Date","rules":{
                              required: true
                            }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"value":_vm._f("dateFormat")(_vm.input.date,'DD/MM/YYYY'),"disabled":!_vm.input.contractid || !_vm.overdueamount,"label":"dd/mm/yyyy","clearable":"","clear-icon":"mdi-close-circle","readonly":""},on:{"click:clear":function($event){_vm.input.date = ''}}},on))]}}],null,true)})]}}],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"allowed-dates":_vm.alloweddates,"disabled":!_vm.overdueamount,"no-title":"","scrollable":""},model:{value:(_vm.input.date),callback:function ($$v) {_vm.$set(_vm.input, "date", $$v)},expression:"input.date"}})],1)],1),_c('div',{staticClass:"pt-3"},[_c('ValidationProvider',{attrs:{"mode":"passive","name":"amount","rules":{
                          required: true,
                          min_value: _vm.p2pData ? _vm.p2pData.installment : 99999
                        }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('v-text-field',{attrs:{"name":"amount","type":"number","step":"0.01","error-messages":errors,"disabled":!_vm.input.contractid,"label":"Amount","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.amount = ''}},model:{value:(_vm.input.amount),callback:function ($$v) {_vm.$set(_vm.input, "amount", $$v)},expression:"input.amount"}})]}}],null,true)})],1),_c('div',{staticClass:"text-center mt-4"},[_c('v-btn',{staticClass:"primary-btn",attrs:{"type":"submit","width":"200px","disabled":!_vm.overdueamount,"loading":_vm.loading,"height":"45px","dark":""}},[_vm._v("PROCEED")])],1)]):_vm._e()],1)])]}}],null,false,3362334678)})],1)])])])],1):_c('div',{staticClass:"text-center"},[_c('v-row',{staticStyle:{"height":"100vh"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center mt-7"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"blue","size":"80"}})],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"primary-title"},[_c('span',[_vm._v("Promise to pay")])])
}]

export { render, staticRenderFns }