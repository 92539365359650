<template>
  <div>
    <Header :text="'สมัครสินเชื่อ'"></Header>
    <div style="position: relative">
      <div class="content">
        <div class="wrapper pt-6">
          <h6 class="primary-title">
            <span v-if="step === 1">Personal Information</span>
            <span v-else-if="step === 2">Verify OTP</span>
            <span v-else-if="step === 3">Setup PIN</span>
          </h6>
          <div class="pt-4 custom-font">
            <ValidationObserver v-slot="{ handleSubmit }">
              <div class="slide-item">
                <form @submit.prevent="handleSubmit(nextStep)">
                  <!-- <h2 class="font-weight-black custom-font primary--text mb-3">
                    Personal Information
                  </h2> -->
                  <div class="pb-3">
                    <div class="mt-4 grey--text">Type</div>
                    <ValidationProvider
                      name="ประเภท"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-radio-group
                        v-model="type"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        row
                        class="mt-0"
                        color="primary"
                      >
                        <v-radio :label="'HP - hire purchase'" :value="'HP'"></v-radio>
                        <!-- <v-radio :label="'PL - ploan'" :value="'PL'"></v-radio> -->
                      </v-radio-group>
                    </ValidationProvider>
                  </div>
                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">First name</div> -->
                    <ValidationProvider
                      name="ชื่อจริง"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        class="custom-font"
                        :success="valid"
                        placeholder="ชื่อจริง"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        color="primary"
                        v-model="input.first_name"
                        label="First name"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.first_name = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Last name</div> -->
                    <ValidationProvider
                      name="นามสกุล"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :success="valid"
                        :error-messages="errors"
                        placeholder="นามสกุล"
                        v-model="input.last_name"
                        :hide-details="errors.length == 0"
                        label="Last name"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.last_name = ''"
                        color="primary"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>

                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Age</div> -->
                    <ValidationProvider
                      name="อายุ"
                      :rules="{
                        required: true,
                        min_value: 1
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :success="valid"
                        v-mask="'##'"
                        :error-messages="errors"
                        placeholder="อายุ"
                        v-model="input.age"
                        :hide-details="errors.length == 0"
                        color="primary"
                        label="Age"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.age = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>

                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Monthly income</div> -->
                    <ValidationProvider
                      name="รายได้ต่อเดือน"
                      :rules="{
                        required: true,
                        numeric: true,
                        minvalue_monthlyincome: type == 'PL' ? true : false
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :success="valid"
                        :error-messages="errors"
                        type="number"
                        placeholder="รายได้ต่อเดือน"
                        v-model="input.monthlyincome"
                        :hide-details="errors.length == 0"
                        color="primary"
                        label="Monthly income"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.monthlyincome = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Occupation</div> -->
                    <ValidationProvider
                      name="อาชีพ"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :success="valid"
                        :error-messages="errors"
                        placeholder="อาชีพ"
                        v-model="input.occupation"
                        :hide-details="errors.length == 0"
                        color="primary"
                        label="Occupation"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.occupation = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Mobile</div> -->
                    <ValidationProvider
                      name="เบอร์โทรศัพท์"
                      :rules="{
                        required: true,
                        numeric: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :error-messages="errors"
                        :success="valid"
                        placeholder="เบอร์โทรศัพท์"
                        type="tel"
                        v-mask="'##########'"
                        maxlength="10"
                        oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                        v-model="input.mobile"
                        :hide-details="errors.length == 0"
                        color="primary"
                        label="Mobile"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.mobile = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Province</div> -->
                    <ValidationProvider
                      name="จังหวัด"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :items="provinceOptions"
                        v-model="input.province"
                        color="primary"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        placeholder="จังหวัด"
                        item-text="province_name"
                        return-object
                        @change="handleChangeProvince"
                        label="Province"
                      ></v-select>
                    </ValidationProvider>
                  </div>

                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">District</div> -->
                    <ValidationProvider
                      name="ตำบล"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :items="districtOptions"
                        v-model="input.district"
                        color="primary"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        placeholder="ตำบล"
                        item-text="district_name"
                        return-object
                        @change="handleChangeDistrict"
                        :loading="loadingDistrict"
                        :disabled="!input.province"
                        label="District"
                      ></v-select>
                    </ValidationProvider>
                  </div>

                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Subdistrict</div> -->
                    <ValidationProvider
                      name="อำเภอ"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :items="subdistrictOptions"
                        v-model="input.subdistrict"
                        color="primary"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        placeholder="อำเภอ"
                        item-text="subdistrict_name"
                        return-object
                        @change="handleChangeSubdistrict"
                        :loading="loadingSubdistrict"
                        :disabled="!input.district"
                        label="Subdistrict"
                      ></v-select>
                    </ValidationProvider>
                  </div>

                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Zipcode</div> -->
                    <ValidationProvider
                      name="รหัสไปรษณีย์"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        readonly
                        v-model="input.zipcode"
                        color="primary"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        placeholder="รหัสไปรษณีย์"
                        :disabled="!input.subdistrict"
                        return-object
                        :loading="loadingZipcode"
                        label="Zipcode"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.zipcode = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>

                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">E-mail</div> -->
                    <ValidationProvider
                      name="อีเมล์"
                      :rules="{
                        email: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :success="valid"
                        :error-messages="errors"
                        placeholder="อีเมล์"
                        v-model="input.email"
                        :hide-details="errors.length == 0"
                        color="primary"
                        label="E-mail"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.email = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">ID card</div> -->
                    <ValidationProvider
                      name="เลขบัตรประชาชน"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        :success="valid"
                        :error-messages="errors"
                        placeholder="รหัสบัตรประชาชน"
                        v-mask="'#############'"
                        v-model="input.idcard"
                        :hide-details="errors.length == 0"
                        color="primary"
                        label="ID card"
                        clearable
                        clear-icon="mdi-close-circle"
                        @click:clear="input.idcard = ''"
                      ></v-text-field>
                    </ValidationProvider>
                  </div>
                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Contact time</div> -->
                    <ValidationProvider
                      name="เวลาที่สามารถติดต่อได้"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :items="timeoptions"
                        v-model="input.contact_time"
                        color="primary"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        placeholder="เวลาที่สามารถติดต่อได้"
                        label="Contact time"
                      ></v-select>
                    </ValidationProvider>
                  </div>

                  <div>
                    <div class="mt-4 grey--text">เคยขอสินเชื่ออื่นๆมาก่อนหรือไม่</div>

                    <ValidationProvider
                      name="เคยขอสินเชื่อ"
                      vid="loan_flag"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-layout>
                        <v-flex>
                          <v-checkbox
                            v-model="input.loan_flag"
                            :label="`เคย`"
                            :true-value="true"
                            :false-value="false"
                            color="primary"
                            :success="valid"
                          ></v-checkbox>
                        </v-flex>
                        <v-flex>
                          <v-checkbox
                            v-model="input.loan_flag"
                            :label="`ไม่เคย`"
                            :true-value="false"
                            :false-value="true"
                            color="primary"
                            :success="valid"
                          ></v-checkbox>
                        </v-flex>
                      </v-layout>
                      <span class="red--text custom-font" style="font-size: 12px">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>
                  <div>
                    <div class="mt-4 grey--text">เคยมีประวัติค้างชำระหรือไม่</div>

                    <ValidationProvider
                      name="เคยมีประวัติค้างชำระ"
                      vid="overdue_flag"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-layout>
                        <v-flex>
                          <v-checkbox
                            v-model="input.overdue_flag"
                            :label="`เคย`"
                            :true-value="true"
                            :false-value="false"
                            color="primary"
                            :success="valid"
                          ></v-checkbox>
                        </v-flex>
                        <v-flex>
                          <v-checkbox
                            v-model="input.overdue_flag"
                            :label="`ไม่เคย`"
                            :true-value="false"
                            :false-value="true"
                            color="primary"
                            :success="valid"
                          ></v-checkbox>
                        </v-flex>
                      </v-layout>
                      <span class="red--text custom-font" style="font-size: 12px">{{
                        errors[0]
                      }}</span>
                    </ValidationProvider>
                  </div>

                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Brand</div> -->
                    <ValidationProvider
                      name="ยี่ห้อรถที่สนใจเช่าซื้อ"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :items="brandOptions"
                        v-model="input.brand"
                        color="primary"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        placeholder="ยี่ห้อรถที่สนใจเช่าซื้อ"
                        item-text="brand_name_tha"
                        return-object
                        @change="handleChangeBrand"
                        label="Brand"
                      ></v-select>
                    </ValidationProvider>
                  </div>

                  <div class="pb-3">
                    <!-- <div class="mt-4 grey--text">Model</div> -->
                    <ValidationProvider
                      name="รุ่นรถ"
                      :rules="{
                        required: true
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-select
                        :items="modelOptions"
                        v-model="input.model"
                        color="primary"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        placeholder="รุ่นรถ"
                        item-text="model_series"
                        return-object
                        :disabled="!input.brand"
                        label="Model"
                      ></v-select>
                    </ValidationProvider>
                  </div>

                  <div>
                    <!-- <div class="mt-4 grey--text">Note</div> -->
                    <ValidationProvider
                      name="ข้อคิดเห็น"
                      :rules="{
                        required: false
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-textarea
                        style="border-radius: 0"
                        :success="valid"
                        :error-messages="errors"
                        :hide-details="errors.length == 0"
                        placeholder="ข้อคิดเห็น"
                        v-model="input.note"
                        color="primary"
                        label="Note"
                      />
                    </ValidationProvider>
                  </div>
                  <div class="red--text mt-5">
                    *แบบฟอร์มนี้ใช้สำหรับลูกค้าที่สนใจเช่าซื้อรถจักรยานยนต์ใหม่ กับบริษัทซัมมิท
                    แคปปิตอล ลีสซิ่ง เท่านั้น<br />
                    *กรุณากรอกข้อมูลให้ครบถ้วนเพื่อส่งแบบฟอร์ม<br />
                    หมายเหตุ : จะมีเจ้าหน้าที่จากทางบริษัทฯ ใช้เบอร์ 02-1072222 ติดต่อลูกค้า
                  </div>
                  <div class="text-center my-4">
                    <v-btn
                      type="submit"
                      width="200px"
                      :disabled="IDloading"
                      :loading="IDloading"
                      height="45px"
                      dark
                      class="primary-btn"
                      >ถัดไป</v-btn
                    >
                  </div>
                </form>
              </div>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import queryString from 'query-string'
import { mask } from 'vue-the-mask'
import prescreenApi from '@/api/prescreen'
import Header from '@/components/Header.vue'

export default {
  components: { Header },
  directives: { mask },
  data() {
    return {
      type: '',
      agreeterm: false,
      timeoptions: [
        'ติดต่อได้ทุกเวลา',
        '6.00 - 7.00',
        '7.00 - 8.00',
        '8.00 - 9.00',
        '9.00 - 10.00',
        '10.00 - 11.00',
        '11.00 - 12.00',
        '12.00 - 13.00',
        '13.00 - 14.00',
        '14.00 - 15.00',
        '15.00 - 16.00',
        '16.00 - 17.00',
        '17.00 - 18.00'
      ],
      errorterm: '',
      serverUrl: process.env.VUE_APP_SERVER_URL,
      step: 1,
      query: '',
      IDloading: false,
      loading: false,
      input: {
        first_name: '',
        last_name: '',
        age: '',
        occupation: '',
        monthlyincome: '',
        mobile: '',
        province: null,
        province_id: '',
        province_name: '',
        district: null,
        district_id: '',
        district_name: '',
        subdistrict: null,
        subdistrict_id: '',
        subdistrict_name: '',
        zipcode: null,
        zipcode: '',
        email: '',
        idcard: '',
        note: '',
        loan_flag: null,
        overdue_flag: null,
        contact_time: '',
        pdpa_flag: false,
        brand: null,
        brand_code: '',
        brand_name: '',
        model: null,
        model_name: ''
      },
      otp: '',
      otpref: '',
      sendedOtp: false,
      wrongotp: '',
      pinnumber: '',
      districtOptions: [],
      subdistrictOptions: [],
      provinceOptions: [],
      zipcodeOptions: [],
      brandOptions: [],
      modelOptions: [],
      loadingDistrict: false,
      loadingSubdistrict: false,
      loadingZipcode: false,
      loadingModel: false,
      pageid: '495271297476302'
    }
  },
  computed: {
    token() {
      if (this.query.apptype == 'line') {
        return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      } else {
        // return "1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B";
        return '1687C3B7-AEFF-45F7-97ED-4DF112E3AAF5'
      }
    },
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    },
    transid() {
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    }
  },
  async mounted() {
    this.queryinit()
    try {
      const provincePromise = prescreenApi.getProvince({
        data: { transid: this.transid, token: this.token }
      })
      const brandPromise = prescreenApi.getBrand({
        data: { transid: this.transid, token: this.token }
      })

      const provinceResponse = await provincePromise
      const brandResponse = await brandPromise
      if (provinceResponse) {
        if (provinceResponse.status == 200 && provinceResponse.data) {
          const { province = [] } = provinceResponse.data
          this.provinceOptions = province
        }
      }

      if (brandResponse) {
        if (brandResponse.status == 200 && brandResponse.data) {
          const { brand = [] } = brandResponse.data
          this.brandOptions = brand
        }
      }
    } catch (error) {
      console.error(error)
    }
  },
  methods: {
    async handleChangeProvince(val) {
      this.loadingDistrict = true
      this.loadingSubdistrict = true
      this.loadingZipcode = true
      try {
        this.districtOptions = []
        this.subdistrictOptions = []
        this.zipcodeOptions = []
        this.input.district = null
        this.input.subdistrict = null
        this.input.zipcode = null
        const districtResponse = await prescreenApi.getDistrict({
          data: { transid: this.transid, token: this.token, province_id: val.province_id }
        })
        if (districtResponse) {
          if (districtResponse.status == 200 && districtResponse.data) {
            const { district = [] } = districtResponse.data
            this.districtOptions = district.map((dist) => ({
              district_id: dist.distict_id,
              district_name: dist.distict_name
            }))
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.loadingSubdistrict = false
      this.loadingDistrict = false
      this.loadingZipcode = false
    },
    async handleChangeDistrict(val) {
      this.loadingSubdistrict = true
      this.loadingZipcode = true
      try {
        this.subdistrictOptions = []
        this.zipcodeOptions = []
        this.input.subdistrict = null
        this.input.zipcode = null
        const subdistrictResponse = await prescreenApi.getSubdistrict({
          data: { transid: this.transid, token: this.token, district_id: val.district_id }
        })
        if (subdistrictResponse) {
          if (subdistrictResponse.status == 200 && subdistrictResponse.data) {
            const { subdistrict = [] } = subdistrictResponse.data
            this.subdistrictOptions = subdistrict
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.loadingSubdistrict = false
      this.loadingZipcode = false
    },
    async handleChangeSubdistrict(val) {
      this.loadingZipcode = true
      try {
        this.zipcodeOptions = []
        this.input.zipcode = null
        const zipcodeResponse = await prescreenApi.getZipcode({
          data: { transid: this.transid, token: this.token, subdistrict_id: val.subdistrict_id }
        })
        if (zipcodeResponse) {
          if (zipcodeResponse.status == 200 && zipcodeResponse.data) {
            const { zipcode = [] } = zipcodeResponse.data
            this.zipcodeOptions = zipcode
            this.input.zipcode = zipcode.length ? zipcode[0].zipcode : null
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.loadingZipcode = false
    },
    async handleChangeBrand(val) {
      this.loadingModel = true
      try {
        this.modelOptions = []
        this.input.model = null
        const modelResponse = await prescreenApi.getModel({
          data: { transid: this.transid, token: this.token, brand_code: val.brand_code }
        })
        if (modelResponse) {
          if (modelResponse.status == 200 && modelResponse.data) {
            const { model = [] } = modelResponse.data
            this.modelOptions = model
          }
        }
      } catch (error) {
        console.log(error)
      }
      this.loadingModel = false
    },
    savePrescreenData() {
      var vm = this
      if (!this.agreeterm) this.errorterm = ' '
      else {
        this.loading = true
        this.input.contact_time = this.input.contact_time.replace(/.00/gi, '')
        if (!this.input.note) {
          this.input.note = '-'
        }
        const info = {
          ...this.input,
          age: parseInt(this.input.age),
          pdpa_flag: this.agreeterm || false,
          province_id: this.input.province.province_id,
          province_name: this.input.province.province_name,
          district_id: this.input.district.district_id,
          district_name: this.input.district.district_name,
          subdistrict_id: this.input.subdistrict.subdistrict_id,
          subdistrict_name: this.input.subdistrict.subdistrict_name,
          zipcode: this.input.zipcode,
          brand_code: this.input.brand.brand_code,
          brand_name: this.input.brand.brand_name_tha,
          model_name: this.input.model.model_series
        }

        const url = `${this.$store.state.summitServer}API/Register/SavePrescreenData2`
        const { province, district, subdistrict, brand, model, ...rest } = info
        axios
          .post(url, {
            type: this.type,
            data: rest,
            token: this.token,
            transid: this.transid
          })
          .then((response) => {
            if (response.data.status.status == 200) {
              this.$swal
                .fire({
                  icon: 'success',
                  html: `<div style="text-align:center;"><div>ข้อมูลถูกส่งแล้ว</div></div>`,
                  confirmButtonColor: '#0d4ca0',
                  confirmButtonText: 'จบ',
                  customClass: {
                    confirmButton:
                      'white--text v-btn v-btn--rounded elevation-2 v-size--default Continuebutton'
                  },
                  buttonsStyling: false
                })
                .then(async (result) => {
                  if (vm.query.apptype == 'line') {
                    // await vm.$liff.sendMessages([
                    //   {
                    //     type: "text",
                    //     text: "E-service"
                    //   }
                    // ]);
                    vm.$liff.closeWindow()
                  } else if (vm.query.apptype == 'facebook') {
                    MessengerExtensions.requestCloseBrowser(
                      function success() {},
                      function error() {
                        window.close()
                      }
                    )
                  }
                })
            }
          })
      }
    },
    nextStep() {
      this.step++
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    }
  }
}
</script>

<style lang="scss">
.slide-leave-active,
.slide-enter-active {
  transition: 0.6s ease-in-out;
}
.slide-enter {
  transform: translate(150%, 0);
}
.slide-leave-to {
  transform: translate(150%, 0);
}

.img-slider {
  position: relative;
  width: 100%;
}

.img-slider .slide-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.termncons {
  width: 100%;
  height: 50vh;
  border: solid 1px black;
  border-radius: 5px;
  overflow: auto;
  padding: 10px;
  text-align: left;
}
.Continuebutton {
  background-color: #0d4ca0;
  color: white;
}
</style>
