var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.isCheckLogin)?_c('div',[_c('Header',{attrs:{"text":`ยืนยันOTP`,"detail":"","customStyle":'padding-top: 24px',"headerImg":13}}),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"wrapper pt-6"},[_c('div',{staticClass:"custom-font"},[_vm._m(0),(_vm.step == 1)?[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v("กรุณากรอก เบอร์มือถือ")]),_c('ValidationProvider',{attrs:{"name":"เบอร์มือถือ","rules":{
                    required: true,
                    thai_phonenumber: true
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('ThaiPhoneNumber',{attrs:{"success":valid,"errors":errors,"label":'เบอร์มือถือ',"showIcon":false,"hasClearable":true},model:{value:(_vm.mobileno),callback:function ($$v) {_vm.mobileno=$$v},expression:"mobileno"}})]}}],null,false,2630577572)}),_c('v-btn',{staticClass:"mt-9 primary-btn",attrs:{"dark":"","height":"45px","width":"200px"},on:{"click":_vm.requestOtp}},[_vm._v("ถัดไป")])],1)]:_vm._e(),(_vm.step == 2)?[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"font-weight-bold mb-1"},[_vm._v("กรุณากรอก OTP ที่ได้รับทาง SMS")]),_c('div',{staticClass:"mb-2"},[_vm._v("(Ref: "+_vm._s(_vm.otpref)+")")]),_c('ValidationProvider',{attrs:{"name":"รหัส OTP","rules":{
                    required: true,
                    min: 6
                  }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{directives:[{name:"uppercase",rawName:"v-uppercase"}],staticStyle:{"border-radius":"0px"},attrs:{"maxlength":"6","success":_vm.wrongotp ? false : valid,"error-messages":_vm.wrongotp ? _vm.wrongotp : errors,"placeholder":"OTP: XXXXXX"},on:{"input":function($event){_vm.wrongotp = ''}},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}})]}}],null,false,3773251302)}),_c('a',{style:(_vm.sendedOtp ? `color: grey` : ''),on:{"click":_vm.requestOtp}},[_vm._v(" Resend OTP "),_c('div',[_vm._v("(ขอส่งใหม่ได้ทุก 60 วินาที)")])]),_c('v-btn',{staticClass:"mt-9 primary-btn",attrs:{"dark":"","height":"45px","width":"200px"},on:{"click":_vm.verifyOtp}},[_vm._v("ถัดไป")])],1)]:_vm._e()],2)])])])],1):_c('div',{staticClass:"text-center"},[_c('v-row',{staticStyle:{"height":"100vh"},attrs:{"justify":"center","align":"center"}},[_c('v-col',{staticClass:"text-center mt-7"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"blue","size":"80"}})],1)],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h6',{staticClass:"primary-title",staticStyle:{"padding":"0","margin-bottom":"12px"}},[_c('span',[_vm._v("Verify OTP")])])
}]

export { render, staticRenderFns }