<template>
  <div v-if="headerImg && headerImg >= 1 && headerImg <= 13">
    <v-img v-if="headerImg == 1" src="@/assets/header/01-เข้าสู่ระบบ.png"></v-img>
    <v-img v-if="headerImg == 2" src="@/assets/header/02-ลงทะเบียนลูกค้าปัจจุบัน.png"></v-img>
    <v-img v-if="headerImg == 3" src="@/assets/header/03-ข้อมูลทั่วไป.png"></v-img>
    <v-img v-if="headerImg == 4" src="@/assets/header/04-changeData.png"></v-img>
    <v-img v-if="headerImg == 5" src="@/assets/header/05-เปลี่ยนแปลงแก้ไขโทรศัพท์.png"></v-img>
    <v-img v-if="headerImg == 6" src="@/assets/header/06-เปลี่ยนแปลงแก้ไขที่อยู่.png"></v-img>
    <v-img v-if="headerImg == 7" src="@/assets/header/07-เปลี่ยนแปลงแก้ไขอีเมล์.png"></v-img>
    <v-img v-if="headerImg == 8" src="@/assets/header/08-ค่างวด.png"></v-img>
    <v-img v-if="headerImg == 9" src="@/assets/header/09-ข้อมูล-membership.png"></v-img>
    <v-img v-if="headerImg == 10" src="@/assets/header/10-ประวัติชำระย้อนหลัง.png"></v-img>
    <v-img v-if="headerImg == 11" src="@/assets/header/11-ค่าประกันและต่าบัตรสมาชิก.jpg"></v-img>
    <v-img v-if="headerImg == 12" src="@/assets/header/12-ค่างานทะเบียน.jpg"></v-img>
    <v-img v-if="headerImg == 13" src="@/assets/header/13-otp.jpg"></v-img>
  </div>
  <div class="header" v-else>
    <div class="wrapper pl-3">
      <v-layout>
        <v-flex
          xs="auto"
          style="display: flex; align-items: center; padding-bottom: 24px; flex-grow: 1"
        >
          <div>
            <h1 class="header-text" v-html="text"></h1>
            <h1
              v-if="detail"
              class="header-text"
              style="font-size: 16px; line-height: 118.75%; margin-top: 8px"
              v-html="detail"
            ></h1>
          </div>
        </v-flex>

        <v-flex xs="auto" style="width: 175px; flex: 0 0 auto">
          <div style="bottom: -32px; position: relative">
            <v-img max-width="175px" src="@/assets/summit-mascot.png"></v-img>
          </div>
        </v-flex>
      </v-layout>
    </div>
  </div>
</template>

<script>
export default {
  props: ['text', 'customStyle', 'detail', 'headerImg'],
  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped></style>
