import axios from 'axios'

const instance = () => {
  return axios.create({
    baseURL: `${process.env.VUE_APP_SERVER_URL}summit-capital`,
    timeout: 15000,
    headers: {
      authorization: 'Bearer VlRrNU1qWmtNek0zTVdFellXSTBNVEl4Wm1ZNVlUazVOakZt='
    }
  })
}

const otpApi = {
  requestOtp: (request) => {
    const { body, config = {} } = request
    return instance().post(`/liff/fh5/ticket/otp`, body, {
      ...config
    })
  },
  verifyOtp: (request) => {
    const { body, config = {} } = request
    return instance().post(`/liff/fh5/ticket/otp/verify`, body, {
      ...config
    })
  }
}

export default otpApi
