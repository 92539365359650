<template>
  <div v-if="!isCheckLogin">
    <Header :text="'ข้อมูลทั่วไป'" :headerImg="headerImg"></Header>
    <div style="position: relative">
      <div class="content">
        <div class="wrapper pt-6">
          <v-layout row wrap style="margin: 0">
            <v-flex>
              <h6 class="primary-title">
                <span>General Info</span>
              </h6>
            </v-flex>
            <v-flex
              v-if="selectContractIsExpried"
              style="text-align: end; color: red; font-size: 14px"
            >
              สิ้นสุดสัญญา
            </v-flex>
          </v-layout>
          <div class="pt-4 custom-font">
            <div>
              <v-select
                @change="getinfo"
                large
                style="border-radius: 0px"
                :items="contracts"
                placeholder="เลือกเลขที่สัญญา"
                :item-text="(item) => item.contractdesc || item.contractno"
                item-value="contractno"
                :loading="isContractListLoading"
                :readonly="isContractListLoading"
              ></v-select>
            </div>
            <div v-if="loading" class="text-center">
              <v-progress-circular indeterminate size="50"></v-progress-circular>
            </div>
            <div
              v-else-if="
                query && query.direct_to && query.direct_to === 'payment' && selectContractNo
              "
            >
              <div v-if="loading">
                <v-progress-circular indeterminate size="50"></v-progress-circular>
              </div>
              <v-expansion-panels v-else-if="info.paymentinfos" v-model="panel" focusable>
                <v-expansion-panel>
                  <v-expansion-panel-header
                    >ประวัติการชำระย้อนหลัง(สูงสุด 3 เดือน)</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="pt-5">
                    <div v-for="(item, k) in info.paymentinfos" :key="k">
                      <v-card outlined tile>
                        <v-card-text>
                          <div class="mylabel">ประเภทสินค้า</div>
                          <div class="mydata">
                            {{ item.producttype | productType }}
                          </div>
                          <div class="mylabel">เลขที่สัญญา</div>
                          <div class="mydata">{{ item.contractno | isExist }}</div>
                          <div class="mylabel">Seq</div>
                          <div class="mydata">{{ item.seq | isExist }}</div>
                          <div class="mylabel">Total Amount</div>
                          <div class="mydata">
                            {{ item.totalamount | numberFormat }}
                          </div>
                          <div class="mylabel">Transaction Date</div>
                          <div class="mydata">
                            <span v-if="item.transactiondate">
                              {{ item.transactiondate | dateFormat('DD/MM/YYYY') | datethformat }}
                            </span>
                            <span v-else>-</span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-layout
                class="mt-5"
                v-else
                row
                wrap
                justify-center
                align-center
                align-content-center
                >ไม่พบประวัติการชำระย้อนหลัง</v-layout
              >
            </div>
            <div
              v-else-if="
                query && query.direct_to && query.direct_to === 'member' && selectContractNo
              "
            >
              <div v-if="directLoading">
                <v-progress-circular indeterminate size="50"></v-progress-circular>
              </div>
              <v-layout
                v-else
                class="mt-5"
                row
                wrap
                justify-center
                align-center
                align-content-center
                >ไม่พบข้อมูลสมาชิก</v-layout
              >
            </div>
            <div v-else>
              <v-expansion-panels v-model="panel" focusable>
                <v-expansion-panel v-if="info.customerinfo">
                  <v-expansion-panel-header>ข้อมูลทั่วไปของลูกค้า</v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-5"
                    >ชื่อ {{ info.customerinfo.fullname | isExist }}
                    <v-btn
                      class="mt-2"
                      style="width: 100%"
                      color="primary"
                      @click="handleChangeData"
                      >เปลี่ยนแปลงข้อมูล</v-btn
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="info.contractinfo">
                  <v-expansion-panel-header>ข้อมูลสัญญา</v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-5">
                    <div>
                      <div class="mylabel">เลขที่สัญญา</div>
                      <div class="mydata">
                        {{ info.contractinfo.contractno | isExist }}
                      </div>
                      <div class="mylabel">ประเภทสินค้า</div>
                      <div class="mydata">
                        {{ info.contractinfo.producttype | productType }}
                      </div>
                      <div class="mylabel">
                        {{
                          info.contractinfo.producttype == 'PL'
                            ? 'Loan Amount'
                            : '	ยอดจัด(ไม่รวมดอกเบี้ย)/Finance Amount'
                        }}
                      </div>
                      <div class="mydata">
                        {{ info.contractinfo.contractamount | numberFormat }}
                      </div>
                      <div class="mylabel">
                        ยอดชำระคงเหลือ/Outstanding Balance
                        <span style="color: red">*</span>
                      </div>
                      <div class="mydata">
                        {{ info.contractinfo.contractbalance | numberFormat }}
                      </div>
                      <div class="mylabel">จำนวนงวด/Term</div>
                      <div class="mydata">
                        {{ info.contractinfo.mob | isExist }}
                      </div>
                      <div class="mylabel">
                        ยอดผ่อนชำระต่อเดือน/ Installment Amount
                        <span style="color: red">*</span>
                      </div>
                      <div class="mydata">
                        {{ info.contractinfo.installmentamount | numberFormat }}
                      </div>
                      <div class="mylabel">กำหนดชำระงวดถัดไป/Next Due Date</div>
                      <div class="mydata">
                        <span v-if="info.installmentinfo.nextduedate">
                          {{
                            info.installmentinfo.nextduedate
                              | dateFormat('DD/MM/YYYY')
                              | datethformat
                          }}
                        </span>
                        <span v-else>-</span>
                      </div>
                      <div class="mylabel">กำหนดชำระงวดสุดท้ายตามสัญญา/End Due Date</div>
                      <div class="mydata">
                        <span v-if="info.contractinfo.endduedate">
                          {{
                            info.contractinfo.endduedate | dateFormat('DD/MM/YYYY') | datethformat
                          }}
                        </span>
                        <span v-else>-</span>
                      </div>
                    </div>
                    <div>
                      <p style="color: red; font-size: 10px">
                        *หมายเหตุ: จำนวนเงินดังกล่าว ยังไม่รวมค่าบริการและค่าธรรมเนียมอื่นๆ (ถ้ามี)
                        หากต้องการสอบถามยอดชำระ กรุณาติดต่อ call center 02-107-2222
                      </p>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <!-- <v-expansion-panel v-if="info.installmentinfo">
              <v-expansion-panel-header>ค่างวดคงเหลือ</v-expansion-panel-header>
              <v-expansion-panel-content class="pt-5">
                <div>
                  <div class="mylabel">เลขที่สัญญา</div>
                  <div class="mydata">{{info.installmentinfo.contractno|isExist}}</div>
                  <div class="mylabel">ประเภทสินค้า</div>
                  <div class="mydata">{{info.installmentinfo.producttype| productType}}</div>
                  <div class="mylabel">Contract Balance</div>
                  <div class="mydata">{{info.installmentinfo.contractbalance|numberFormat}}</div>
                  <div class="mylabel">Next due date</div>
                  <div
                    class="mydata"
                  >{{info.installmentinfo.nextduedate| dateFormat('DD/MM/YYYY')|datethformat}}</div>
                  <div v-if="info.installmentinfo.producttype != 'PL'">
                    <div class="mylabel">ค่าภาษี</div>
                    <div class="mydata">{{info.installmentinfo.taxamt|numberFormat}}</div>
                    <div class="mylabel">Compulsoryamt</div>
                    <div class="mydata">{{info.installmentinfo.compulsoryamt|numberFormat}}</div>
                    <div class="mylabel">License no.</div>
                    <div class="mydata">{{info.installmentinfo.licenseno|isExist}}</div>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>-->
                <!-- <v-expansion-panel v-if="info.paymentinfos">
                  <v-expansion-panel-header
                    >ประวัติการชำระย้อนหลัง(สูงสุด 3 เดือน)</v-expansion-panel-header
                  >
                  <v-expansion-panel-content class="pt-5">
                    <div v-for="(item, k) in info.paymentinfos" :key="k">
                      <v-card outlined tile>
                        <v-card-text>
                          <div class="mylabel">ประเภทสินค้า</div>
                          <div class="mydata">
                            {{ item.producttype | productType }}
                          </div>
                          <div class="mylabel">เลขที่สัญญา</div>
                          <div class="mydata">{{ item.contractno | isExist }}</div>
                          <div class="mylabel">Seq</div>
                          <div class="mydata">{{ item.seq | isExist }}</div>
                          <div class="mylabel">Total Amount</div>
                          <div class="mydata">
                            {{ item.totalamount | numberFormat }}
                          </div>
                          <div class="mylabel">Transaction Date</div>
                          <div class="mydata">
                            <span v-if="item.transactiondate">
                              {{ item.transactiondate | dateFormat('DD/MM/YYYY') | datethformat }}
                            </span>
                            <span v-else>-</span>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel> -->
                <v-expansion-panel
                  v-if="info.insuranceinfo && info.insuranceinfo.producttype != 'PL'"
                >
                  <v-expansion-panel-header>ภาษีและพรบ.</v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-5">
                    <div class="mylabel">ประเภทสินค้า</div>
                    <div class="mydata">
                      {{ info.insuranceinfo.producttype | productType }}
                    </div>
                    <div class="mylabel">เลขที่สัญญา</div>
                    <div class="mydata">
                      {{ info.insuranceinfo.contractno | isExist }}
                    </div>
                    <div class="mylabel">วันหมดอายุของภาษี</div>
                    <div class="mydata">
                      <span v-if="info.insuranceinfo.taxexpirationdate">
                        {{
                          info.insuranceinfo.taxexpirationdate
                            | dateFormat('DD/MM/YYYY')
                            | datethformat
                        }}
                      </span>
                      <span v-else>-</span>
                    </div>
                    <div class="mylabel">วันหมดอายุของกรมธรรม์ภาคบังคับ</div>
                    <div class="mydata">
                      <span v-if="info.insuranceinfo.compulsorypolicyexpirationdate">
                        {{
                          info.insuranceinfo.compulsorypolicyexpirationdate
                            | dateFormat('DD/MM/YYYY')
                            | datethformat
                        }}
                      </span>
                      <span v-else>-</span>
                    </div>
                    <div class="mylabel">เลขทะเบียนรถ</div>
                    <div class="mydata">
                      {{ info.installmentinfo.licenseno | isExist }}
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="info.membershipUrl">
                  <v-expansion-panel-header>ข้อมูล Membership</v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-5">
                    <v-btn block dark @click="openMembershipUrl(info.membershipUrl)"
                      >ดูข้อมูล Membership</v-btn
                    >
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel v-if="info.xxxx">
                  <v-expansion-panel-header>วันที่หมดอายุ พรบ.</v-expansion-panel-header>
                  <v-expansion-panel-content class="pt-5">{{
                    info.contractinfo | isExist
                  }}</v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <v-row justify="center" align="center" style="height: 100vh">
      <v-col class="text-center mt-7">
        <v-progress-circular indeterminate color="blue" size="80"></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios'
import moment from 'moment'
import queryString from 'query-string'

export default {
  components: {
    Header
  },
  data() {
    return {
      loading: false,
      directLoading: false,
      contracts: [],
      query: '',
      serverUrl: 'https://api-summit-cap.zwiz.app/',
      panel: 0,
      pageid: '',
      selectContractNo: '',
      info: {
        customerinfo: '',
        contractinfo: '',
        installmentinfo: '',
        paymentinfos: '',
        insuranceinfo: '',
        membershipUrl: ''
      },
      isContractListLoading: false,
      transidFromQuery: null,
      isRegister: false,
      isCheckLogin: true,
      selectContractIsExpried: false
      //   selectedcontract: ""
    }
  },
  computed: {
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    },
    userId() {
      let tempUserId = this.query.userid
      return tempUserId
    },
    token() {
      if (this.query.apptype == 'line') {
        return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      } else {
        return '1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B'
      }
    },
    contract() {
      let contract = null
      const vm = this
      if (vm.selectContractNo) {
        contract = this.contracts.find((contract) => contract.contractno == vm.selectContractNo)
      }
      return contract
    },
    transid() {
      if (this.transidFromQuery) return this.transidFromQuery
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    },
    headerImg() {
      if (this.query.direct_to && this.query.direct_to == 'member') {
        return 9
      } else if (this.query.direct_to && this.query.direct_to == 'payment') {
        return 10
      }
      return 3
    }
  },
  async mounted() {
    this.queryinit()
    await this.fetchUser()
    await this.checksession()
    await this.getContract()
  },
  methods: {
    isExpire(textDate) {
      try {
        return moment(textDate).diff(moment(), 'days') < 0 ? 1 : 0
      } catch (error) {
        return false
      }
    },
    handleChangeData() {
      if (this.contract && this.contract.closestatusflag === 'Y') {
        this.$swal.fire({
          icon: 'error',
          text: 'ไม่สามารถเปลี่ยนแปลงข้อมูลได้กรุณาติดต่อ เจ้าหน้าที่ทาง LINE Chat หรือโทร 02-107-2222'
        })
      } else {
        this.$router.push({
          path: '/',
          query: {
            ...this.query,
            view: 'changeaddress',
            contract_no: this.selectContractNo
          }
        })
        location.reload()
      }
    },
    async fetchUser() {
      try {
        const { status, data } = await axios.get(
          this.serverUrl + 'summit-capital/liff/getuseronly',
          {
            params: { page_id: this.pageid, user_id: this.userId },
            headers: { AUTHORIZATION: 'Bearer VlRrNU1qWmtNek0zTVdFellXSTBNVEl4Wm1ZNVlUazVOakZt=' }
          }
        )

        if (status === 200) {
          if (Array.isArray(data.data)) {
            console.error('ไม่พบผู้ใช้งาน')
            return
          }

          if (data.data.sessionvalue) {
            this.isRegister = true
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    async checksession() {
      var vm = this
      var userid
      if (this.query.apptype == 'line') {
        userid = this.userid + '__' + this.pageid
      } else {
        userid = this.userid
      }
      if (!userid) {
        await vm.$swal.fire({
          icon: 'error',
          text: 'เกิดข้อผิดพลาด ไม่พบ userid กรุณาล็อกอินใหม่อีกครั้ง'
        })
        vm.handleCloseWindow()
        return false
      }
      const response = await axios.get(
        this.serverUrl + `checksessionexpire?userid=${userid}&pageid=${this.pageid}`,
        {
          headers: { AUTHORIZATION: 'Bearer xxx xxx' }
        }
      )
      if (
        response &&
        response.data &&
        response.data.responsecode &&
        response.data.responsecode == 400
      ) {
        if (this.isRegister) {
          vm.$router.push({
            path: '/',
            query: { ...this.query, view: 'login', back_to: 'MultipleChangeaddress' }
          })
        } else {
          vm.$router.push({
            path: '/',
            query: { ...this.query, view: 'register' }
          })
        }
        location.reload()
      } else {
        setTimeout(() => {
          vm.isCheckLogin = false
        }, 3000)
      }
    },
    handleCloseWindow() {
      if (this.query.apptype == 'line') {
        this.$liff.closeWindow()
      } else if (this.query.apptype == 'facebook') {
        MessengerExtensions.requestCloseBrowser(
          function success() {},
          function error() {
            window.close()
          }
        )
      }
    },
    getinfo(contractid) {
      var vm = this
      vm.loading = true

      vm.selectContractNo = contractid
      try {
        const contract = this.contract
        vm.selectContractIsExpried =
          contract.closestatusflag && contract.closestatusflag == 'Y' ? true : false
      } catch (error) {}
      vm.info = {
        customerinfo: '',
        contractinfo: '',
        installmentinfo: '',
        paymentinfos: '',
        insuranceinfo: '',
        membershipUrl: ''
      }

      for (let index = 1; index < 6; index++) {
        var data = {
          token: vm.token,
          contractid: contractid,
          lineid: this.userid,
          requestinfo: index.toString(),
          transid: this.transid
        }
        axios
          .post(this.$store.state.summitServer + 'API/CustomerInfo/InquiryUserInformation', data)
          .then((response) => {
            if (response.data.status.status == 200) {
              switch (index) {
                case 1:
                  vm.info.customerinfo = response.data.customerinfo
                  break
                case 2:
                  vm.info.contractinfo = response.data.contractinfo
                  break
                case 3:
                  vm.info.installmentinfo = response.data.installmentinfo
                  break
                case 4:
                  vm.info.paymentinfos = response.data.paymentinfos
                  break
                case 5:
                  vm.info.insuranceinfo = response.data.insuranceinfo
                  break
              }
            }
            vm.loading = false
          })
      }

      vm.loading = true

      {
        var userid
        if (this.query.apptype == 'line') {
          userid = this.userid + '__' + this.pageid
        } else {
          userid = this.userid
        }

        var data = {
          page_id: this.pageid,
          user_id: userid,
          contract_id: contractid
        }
        axios
          .post(this.serverUrl + 'summit-capital/liff/check-membership', data, {
            headers: { AUTHORIZATION: 'Bearer xxx xxx' }
          })
          .then((response) => {
            if (response.data.data.status.status == 200) {
              vm.info.membershipUrl = response.data.data.membershipUrl
              console.log(response.data.data.membershipUrl)
              console.log(vm.query.direct_to)
              if (
                response.data.data.membershipUrl &&
                vm.query.direct_to &&
                vm.query.direct_to == 'member'
              ) {
                vm.directLoading = true
                window.location.href = response.data.data.membershipUrl
              } else {
                vm.loading = false
              }
            } else {
              vm.loading = false
            }
          })
      }
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
      if (vm.query.transid) vm.transidFromQuery = vm.query.transid
    },
    async getContract() {
      var vm = this
      var data = {
        token: vm.token,
        transid: this.transid,
        lineid: this.userid
      }

      vm.isContractListLoading = true

      const response = await axios.post(
        this.$store.state.summitServer + 'API/CustomerInfo/GetAppContract',
        data
      )
      if (response.data.status.status == 200) {
        this.contracts = response.data.contracts
      } else {
        //Show error
        await this.$swal.fire({ text: 'No data available', icon: 'error' })
        this.handleCloseWindow()
      }
      vm.isContractListLoading = false
    },
    openMembershipUrl(url) {
      this.$liff.openWindow({
        url
      })
    }
  },
  filters: {
    datethformat(val) {
      if (val) {
        var splited = val.split('/')
        return [splited[0], splited[1], parseInt(splited[2]) + 543].join('/')
      } else {
        return '-'
      }
    },
    dateFormat: function (value, e) {
      if (value) {
        return moment(value).format(e)
      } else {
        return '-'
      }
    },
    productType(val) {
      if (val) {
        if (val == 'PL') {
          return 'สินเชื่อบุคคล'
        }
        if (val == 'HP') {
          return 'เช่าซื้อรถจักรยานยนต์'
        }
      } else {
        return '-'
      }
    },
    isExist(val) {
      if (!val) return '-'
      else return val
    }
  }
}
</script>

<style lang="scss" scoped>
.mylabel {
  font-size: 12px;
  line-height: 20px;
}
.mydata {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
}
</style>
