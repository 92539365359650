import Vue from 'vue'
import Vuex from 'vuex'
import Swal from 'sweetalert2'
import Axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    profile: {
      userId: ''
    },
    summitServer: ''
  },
  mutations: {},
  actions: {
    sendMessage({ state }, { userId, pageId, message }) {
      var url = `${process.env.VUE_APP_SERVER_URL}zwiz-pushmsg-webbrowser`
      var data = {
        pageid: pageId,
        userid: userId,
        message
      }
      if (window.liff.isInClient()) {
        try {
          window.liff.sendMessages([
            {
              text: message,
              type: 'text'
            }
          ])
        } catch (error) {
          alert(JSON.stringify(error))
        }
      } else {
        Axios.post(url, data, {
          headers: {
            Authorization: 'Bearer xxx xxx'
          }
        }).then((response) => {
          if (response.data.responsecode != 200) {
            if (response.data.reason) {
              Swal.fire({
                text: response.data.reason
              })
            }
          }
        })
      }
    }
  },
  modules: {}
})
