<template>
  <div v-if="!isCheckLogin">
    <Header
      :text="`เปลี่ยนแปลง <br/>${headertext}`"
      :detail="headerDetailText"
      :customStyle="'padding-top: 24px'"
      :headerImg="headerImg"
    ></Header>
    <div style="position: relative">
      <div class="content">
        <div class="wrapper pt-6">
          <h6
            v-if="!['email', 'channel', 'email-verification', 'channel_welcome_kit'].includes(view)"
            class="primary-title"
          >
            <span>Contract ID</span>
          </h6>
          <div class="pt-4 custom-font">
            <transition-group tag="div" class="img-slider" name="slide">
              <div v-if="step == 1" :key="1">
                <div class="slide-item">
                  <v-card
                    @click="selectContract(item)"
                    v-ripple
                    outlined
                    v-for="(item, k) in contracts"
                    :key="k"
                    class="mt-1"
                  >
                    <v-card-text>
                      <div style="color: black">{{ item.contractno }}</div>
                      <div>{{ item.contractdesc }}</div>
                    </v-card-text>
                  </v-card>
                </div>
              </div>
              <div v-if="step == 2" :key="2">
                <div v-if="selectedcontract.closestatusflag === 'Y'">
                  <v-alert density="compact" type="warning">
                    ไม่สามารถเปลี่ยนแปลงข้อมูลได้กรุณาติดต่อ เจ้าหน้าที่ทาง LINE Chat หรือโทร
                    02-107-2222</v-alert
                  >
                </div>
                <ValidationObserver
                  v-else-if="
                    [
                      'selection',
                      'name',
                      'address',
                      'address-estatement',
                      'phone',
                      'both'
                    ].includes(view)
                  "
                  v-slot="{ handleSubmit }"
                >
                  <form @submit.prevent="handleSubmit(submitChange)">
                    <v-container>
                      <div v-if="view == 'selection'">
                        <div>
                          <v-card
                            @click="handleSelectTypeAddress()"
                            ripple
                            flat
                            style="border-radius: 0px; margin-top: 1em; background-color: #0d4ca0"
                          >
                            <v-card-text style="color: white"
                              >เปลี่ยนแปลงที่อยู่และช่องทางการรับเอกสารใบแจ้งหนี้/ใบเสร็จรับเงิน/ใบกำกับภาษี</v-card-text
                            >
                          </v-card>
                        </div>
                        <div>
                          <v-card
                            @click="view = 'phone'"
                            ripple
                            flat
                            style="border-radius: 0px; margin-top: 1em; background-color: #0d4ca0"
                          >
                            <v-card-text style="color: white">แก้ไขเบอร์โทรศัพท์</v-card-text>
                          </v-card>
                        </div>
                        <!-- <div>
                          <v-card
                            @click="view = 'both'"
                            flat
                            ripple
                            style="border-radius: 0px; margin-top: 1em; background-color: #0d4ca0"
                          >
                            <v-card-text style="color: white"
                              >แก้ไขที่อยู่และเบอร์โทรศัพท์</v-card-text
                            >
                          </v-card>
                        </div> -->
                        <div>
                          <v-card
                            @click="view = 'email'"
                            flat
                            ripple
                            style="border-radius: 0px; margin-top: 1em; background-color: #0d4ca0"
                          >
                            <v-card-text style="color: white">แก้ไข E-mail</v-card-text>
                          </v-card>
                        </div>
                        <!-- <div>
                          <v-card
                            @click="view = 'channel'"
                            flat
                            ripple
                            style="border-radius: 0px; margin-top: 1em; background-color: #0d4ca0"
                          >
                            <v-card-text style="color: white"
                              >เปลี่ยนช่องทางการรับใบแจ้งหนี้/ใบเสร็จรับเงิน/ใบกำกับภาษี
                              (e-Statement)</v-card-text
                            >
                          </v-card>
                        </div>
                        <div>
                          <v-card
                            @click="view = 'channel_welcome_kit'"
                            flat
                            ripple
                            style="border-radius: 0px; margin-top: 1em; background-color: #0d4ca0"
                          >
                            <v-card-text style="color: white"
                              >ดูช่องทางการรับสัญญาเช่าซื้อ และ เอกสาร Welcome Kit</v-card-text
                            >
                          </v-card>
                        </div> -->
                      </div>
                      <div v-if="view == 'name'">
                        <div>
                          <!-- <label>ชื่อ</label> -->
                          <ValidationProvider
                            name="ชื่อ"
                            :rules="{
                              required: input.lastname ? true : false
                            }"
                            v-slot="{ error }"
                          >
                            <v-text-field
                              :error-messages="error"
                              v-model="input.firstname"
                              label="ชื่อ"
                            ></v-text-field>
                          </ValidationProvider>
                        </div>
                        <div>
                          <!-- <label>นามสกุล</label> -->
                          <ValidationProvider
                            name="นามสกุล"
                            :rules="{
                              required: input.firstname ? true : false
                            }"
                            v-slot="{ error }"
                          >
                            <v-text-field
                              :error-messages="error"
                              v-model="input.lastname"
                              label="นามสกุล"
                              clearable
                              clear-icon="mdi-close-circle"
                              @click:clear="input.lastname = ''"
                            ></v-text-field>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div v-if="['address', 'address-estatement', 'both'].includes(view)">
                        <div>
                          <!-- <label>ประเภทที่อยู่</label> -->
                          <ValidationProvider
                            name="ประเภทที่อยู่"
                            :rules="{
                              required: true
                            }"
                            v-slot="{ errors }"
                          >
                            <v-select
                              class="custom-font"
                              v-model="input.addresstype"
                              outlined
                              :error-messages="errors"
                              style="border-radius: 0px"
                              :items="addresstypeData"
                              item-text="label"
                              item-value="value"
                              label="ประเภทที่อยู่"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                        <div
                          v-if="input.addresstype && input.addresstype == 2 && addressInfo != {}"
                        >
                          <v-alert density="compact" type="info">
                            <label>ที่อยู่ในระบบ</label>
                            <div>{{ addressInfo['Current Address'] }}</div>
                          </v-alert>
                        </div>
                        <div
                          v-if="input.addresstype && input.addresstype == 3 && addressInfo != {}"
                        >
                          <v-alert density="compact" type="info">
                            <label>ที่อยู่ในระบบ</label>
                            <div>{{ addressInfo['Office Address'] }}</div>
                          </v-alert>
                        </div>
                        <div
                          v-if="input.addresstype && input.addresstype == 4 && addressInfo != {}"
                        >
                          <v-alert density="compact" type="info">
                            <label>ที่อยู่ในระบบ</label>
                            <div>{{ addressInfo['Statement Address'] }}</div>
                          </v-alert>
                        </div>
                        <div>
                          <!-- <label>ที่อยู่</label> -->
                          <ValidationProvider
                            name="ที่อยู่"
                            :rules="{
                              required: true
                            }"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              :error-messages="errors"
                              style="border-radius: 0px"
                              v-model="input.address"
                              label="ที่อยู่"
                              clearable
                              clear-icon="mdi-close-circle"
                              @click:clear="input.address = ''"
                            ></v-text-field>
                          </ValidationProvider>
                        </div>

                        <div>
                          <!-- <label>จังหวัด</label> -->
                          <ValidationProvider
                            name="จังหวัด"
                            :rules="{
                              required: true
                            }"
                            v-slot="{ errors }"
                          >
                            <v-select
                              class="custom-font"
                              v-model="input.province"
                              :error-messages="errors"
                              style="border-radius: 0px"
                              :items="provinceOptions"
                              item-text="province_name"
                              return-object
                              label="จังหวัด"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                        <div>
                          <!-- <label>อำเภอ/เขต</label> -->
                          <ValidationProvider
                            name="อำเภอ/เขต"
                            :rules="{
                              required: true
                            }"
                            v-slot="{ errors }"
                          >
                            <v-select
                              class="custom-font"
                              v-model="input.district"
                              :error-messages="errors"
                              style="border-radius: 0px"
                              :items="districtOptions"
                              item-text="district_name"
                              return-object
                              label="อำเภอ/เขต"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                        <div>
                          <!-- <label>ตำบล/แขวง</label> -->
                          <ValidationProvider
                            name="ตำบล/แขวง"
                            :rules="{
                              required: true
                            }"
                            v-slot="{ errors }"
                          >
                            <v-select
                              class="custom-font"
                              v-model="input.subdistrict"
                              :error-messages="errors"
                              :items="subdistrictOptions"
                              item-text="subdistrict_name"
                              return-object
                              label="ตำบล/แขวง"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                        <div>
                          <!-- <label>เลขไปรษณีย์</label> -->
                          <ValidationProvider
                            name="เลขไปรษณีย์"
                            :rules="{
                              required: true
                            }"
                            v-slot="{ errors }"
                          >
                            <v-select
                              class="custom-font"
                              v-model="input.zipcode"
                              :error-messages="errors"
                              :items="zipcodeOptions"
                              item-text="zipcode"
                              return-object
                              label="เลขไปรษณีย์"
                            ></v-select>
                          </ValidationProvider>
                        </div>
                      </div>

                      <div v-if="view == 'phone' || view == 'both'">
                        <!-- <label>เบอร์โทรศัพท์</label> -->
                        <ValidationProvider
                          name="เบอร์โทรศัพท์"
                          :rules="{
                            required: true
                          }"
                          v-slot="{ errors }"
                        >
                          <v-text-field
                            maxlength="10"
                            :error-messages="errors"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            type="number"
                            outlined
                            style="border-radius: 0px"
                            v-model="input.phoneno"
                            label="เบอร์โทรศัพท์"
                            clearable
                            clear-icon="mdi-close-circle"
                            @click:clear="input.phoneno = ''"
                          ></v-text-field>
                        </ValidationProvider>
                      </div>
                      <div v-if="view != 'selection'">
                        <v-btn
                          block
                          :loading="loading"
                          class="primary-btn mt-8 mb-3"
                          dark
                          type="submit"
                          >UPDATE</v-btn
                        >
                        <v-btn block outlined color="grey" @click="cancel">ยกเลิก</v-btn>
                      </div>
                    </v-container>
                  </form>
                </ValidationObserver>
                <template v-else-if="view === 'email'">
                  <h6 class="primary-title mb-8">แก้ไข E-mail</h6>
                  <EstatementChannelSelect
                    mode="email"
                    :token="token"
                    :transaction-id="transid"
                    :line-id="userid"
                    :request-number="selectedcontract.request_no"
                    :contract-id="selectedcontract.contractno"
                    :id-card-number="user.idCardNumber"
                    :mobile-number="user.phoneNumber"
                    @complete="handleEstatementComplete"
                    @cancel="view = 'selection'"
                    @edit:address="view = 'address-estatement'"
                    @verify:email="handleEstatementEmailVerify"
                  />
                </template>
                <template v-else-if="view === 'channel'">
                  <h6 class="primary-title mb-8">กรุณาเลือกช่องทางการรับ e-Statement</h6>
                  <EstatementChannelSelect
                    :token="token"
                    :transaction-id="transid"
                    :line-id="userid"
                    :request-number="selectedcontract.request_no"
                    :contract-id="selectedcontract.contractno"
                    :id-card-number="user.idCardNumber"
                    :mobile-number="user.phoneNumber"
                    @complete="handleEstatementComplete"
                    @cancel="view = 'selection'"
                    @edit:address="view = 'address-estatement'"
                    @verify:email="handleEstatementEmailVerify"
                  />
                </template>
                <template v-else-if="view === 'channel_welcome_kit'">
                  <h6 class="primary-title mb-8">กรุณาเลือกช่องทางการรับ Welcome Kit</h6>
                  <WelcomeKitChannelSelect
                    :token="token"
                    :transaction-id="transid"
                    :line-id="userid"
                    :request-number="selectedcontract.request_no"
                    :contract-id="selectedcontract.contractno"
                    :id-card-number="user.idCardNumber"
                    :mobile-number="user.phoneNumber"
                    @complete="handleEstatementComplete"
                    @cancel="view = 'selection'"
                    @edit:address="view = 'address-estatement'"
                    @verify:email="handleWelcomeKitEmailVerify"
                  />
                </template>
                <template v-else-if="view === 'email-verification'">
                  <h6 class="primary-title mb-8">Verify OTP</h6>
                  <EstatementEmailVerification
                    :email="verifiedEmail"
                    :line-id="userid"
                    :id-card-number="user.idCardNumber"
                    :mobile-number="user.phoneNumber"
                    :token="token"
                    :transaction-id="transid"
                    :contract-id="selectedcontract.contractno"
                    @complete="handleEstatementComplete"
                    @cancel="view = 'selection'"
                  />
                </template>
                <template v-else-if="view === 'email-verification-welcome-kit'">
                  <h6 class="primary-title mb-8">Verify OTP</h6>
                  <WelcomeKitEmailVerification
                    :email="verifiedEmail"
                    :line-id="userid"
                    :id-card-number="user.idCardNumber"
                    :mobile-number="user.phoneNumber"
                    :token="token"
                    :transaction-id="transid"
                    :contract-id="selectedcontract.contractno"
                    @complete="handleEstatementComplete"
                    @cancel="view = 'selection'"
                  />
                </template>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <v-row justify="center" align="center" style="height: 100vh">
      <v-col class="text-center mt-7">
        <v-progress-circular indeterminate color="blue" size="80"></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import districtOptions from '@/assets/js/district.js'
import provinceOptions from '@/assets/js/province.js'
import subdistrictOptions from '@/assets/js/subdistrict.js'
import zipcodeOptions from '@/assets/js/zipcode.js'
import Header from '@/components/Header.vue'
import ThaiAddressInput from '@/components/ThaiAddressInput.vue'
import addressApi from '@/api/address'
import axios from 'axios'
import queryString from 'query-string'
import { EstatementChannelSelect, EstatementEmailVerification } from '@/components/estatement'
import { WelcomeKitChannelSelect, WelcomeKitEmailVerification } from '@/components/welcomeKit'

export default {
  components: {
    ThaiAddressInput,
    Header,
    EstatementChannelSelect,
    EstatementEmailVerification,
    WelcomeKitChannelSelect,
    WelcomeKitEmailVerification
  },
  data() {
    return {
      bodyFormData: new FormData(),
      loading: false,
      fileNames: [],
      serverUrl: 'https://api-summit-cap.zwiz.app/',
      step: 1,
      prefix: '',
      contracts: '',
      files: [],
      query: '',
      pageid: '',
      view: 'selection',
      menu: false,
      userstatus: '',
      picfile: new FormData(),
      selectedcontract: {},
      input: {
        contractid: '',
        addresstype: 2,
        phoneno: '',
        firstname: '',
        lastname: '',
        province: {},
        district: {},
        zipcode: {},
        subdistrict: {}
      },
      addresstypeData: [
        // { label: "ที่อยู่ตามทะเบียนบ้าน", value: 1 },
        { label: 'ที่อยู่ปัจจุบัน', value: 2 },
        { label: 'ที่อยู่ที่ทำงาน', value: 3 },
        { label: 'ที่อยู่จัดส่งเอกสาร', value: 4 }
        // { label: "ที่อยู่การรับเล่มทะเบียน", value: 5 }
      ],
      addressInfo: {},
      changetypeData: [
        { label: 'ที่อยู่', value: 1 },
        { label: 'เบอร์โทร', value: 2 }
      ],
      verifiedEmail: '',
      user: {
        idCardNumber: '',
        phoneNumber: ''
      },
      isRegister: false,
      isCheckLogin: true
    }
  },
  computed: {
    token() {
      if (this.query.apptype == 'line') {
        return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      } else {
        return '1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B'
      }
    },
    headerImg() {
      switch (this.view) {
        case 'name':
          return 4
        case 'address':
        case 'address-estatement':
          return 6
        case 'phone':
          return 5
        case 'both':
          return 4
        case 'email':
          return 7
        case 'channel':
          return 4
        case 'channel_welcome_kit':
          return 4
        default:
          return 4
      }
    },
    headertext() {
      switch (this.view) {
        case 'name':
          return 'แก้ไขชื่อ-นามสกุล'
          break
        case 'address':
        case 'address-estatement':
          return 'แก้ไขที่อยู่'
          break
        case 'phone':
          return 'แก้ไขเบอร์โทรศัพท์'
          break
        case 'both':
          return 'แก้ไขที่อยู่และเบอร์โทรศัพท์'
          break
        case 'email':
          return 'แก้ไข E-mail'
        case 'channel':
          return 'ช่องทางรับ e-Statement'
          break
        case 'channel_welcome_kit':
          return 'ช่องทางรับ'
          break

        default:
          return 'แก้ไขข้อมูล'
          break
      }
    },
    headerDetailText() {
      switch (this.view) {
        case 'channel_welcome_kit':
          return 'สัญญาเช่าซื้อ<br />และเอกสาร Welcome Kit'
          break

        default:
          return ''
          break
      }
    },
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    },
    userId() {
      let tempUserId = this.query.userid
      return tempUserId
    },
    provinceOptions() {
      return provinceOptions.sort((a, b) => {
        if (a.province_name < b.province_name) {
          return -1
        }
        if (a.province_name > b.province_name) {
          return 1
        }
        return 0
      })
    },
    districtOptions() {
      var result
      if (this.input.province.province_id) {
        result = districtOptions.filter((el) => el.province_id == this.input.province.province_id)
      } else {
        result = districtOptions
      }

      return result.sort((a, b) => {
        if (a.district_name < b.district_name) {
          return -1
        }
        if (a.district_name > b.district_name) {
          return 1
        }
        return 0
      })
    },
    subdistrictOptions() {
      var result
      if (this.input.district.district_id) {
        result = subdistrictOptions.filter(
          (el) => el.district_id == this.input.district.district_id
        )
      } else {
        result = subdistrictOptions
      }
      return result.sort((a, b) => {
        if (a.subdistrict_name < b.subdistrict_name) {
          return -1
        }
        if (a.subdistrict_name > b.subdistrict_name) {
          return 1
        }
        return 0
      })
    },
    zipcodeOptions() {
      var result
      if (this.input.subdistrict.subdistrict_id) {
        result = zipcodeOptions.filter(
          (el) => el.subdistrict_id == this.input.subdistrict.subdistrict_id
        )
      } else {
        result = zipcodeOptions
      }
      return result.sort((a, b) => {
        if (a.zipcode < b.zipcode) {
          return -1
        }
        if (a.zipcode > b.zipcode) {
          return 1
        }
        return 0
      })
    },
    transid() {
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    }
  },
  async mounted() {
    this.queryinit()
    await this.fetchUser()
    this.checksession()
    this.getContract()
  },
  methods: {
    handleSelectTypeAddress() {
      this.view = 'address'
      this.fetchAddress()
    },
    async fetchAddress() {
      try {
        const configData = {
          body: {
            contract_no: this.selectedcontract.contractno
          }
        }
        const response = await addressApi.getAddress(configData)
        let addressObj = {}
        for (let i = 0; i < response.data.length; i++) {
          const addressInfo = response.data[i]
          addressObj[addressInfo.address_type_desc] = addressInfo.full_address
        }
        this.addressInfo = addressObj
      } catch (error) {
        console.error(error)
      }
    },
    clearicon() {},
    checksession() {
      var userid = this.userId
      var vm = this
      axios
        .get(this.serverUrl + `checksessionexpire?userid=${userid}&pageid=${this.pageid}`, {
          headers: { AUTHORIZATION: 'Bearer xxx xxx' }
        })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.responsecode &&
            response.data.responsecode == 400
          ) {
            if (this.isRegister) {
              vm.$router.push({
                path: '/',
                query: { ...this.query, view: 'login', back_to: 'ChangeAddress' }
              })
            } else {
              vm.$router.push({
                path: '/',
                query: { ...this.query, view: 'register' }
              })
            }
            location.reload()
          } else {
            setTimeout(() => {
              vm.isCheckLogin = false
            }, 3000)
          }
        })
    },
    onSelectAddress(e) {
      this.input.province = e.province
      this.input.amphoe = e.amphoe
      this.input.district = e.district
      this.input.zipcode = e.zipcode
    },
    cancel() {
      this.step = 2
      this.picfile = new FormData()
      this.input = {
        contractid: '',
        addresstype: 4,
        phoneno: '',
        firstname: '',
        lastname: '',
        province: {},
        district: {},
        zipcode: {},
        subdistrict: {}
      }
      this.view = this.view === 'address-estatement' ? 'channel' : 'selection'
      this.bodyFormData = new FormData()
    },
    fileUpload(e) {
      var files = e
      files.forEach((el) => {
        this.bodyFormData.append(el.name, el)
        this.fileNames.push(el.name)
      })
      if (this.filenames) {
        this.fileNames = this.fileNames.join('|')
      }
    },
    selectContract(data) {
      this.selectedcontract = data
      this.step = 2
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    },
    async fetchUser() {
      try {
        const { status, data } = await axios.get(
          this.serverUrl + 'summit-capital/liff/getuseronly',
          {
            params: { page_id: this.pageid, user_id: this.userId },
            headers: { AUTHORIZATION: 'Bearer VlRrNU1qWmtNek0zTVdFellXSTBNVEl4Wm1ZNVlUazVOakZt=' }
          }
        )

        if (status === 200) {
          if (Array.isArray(data.data)) {
            console.error('ไม่พบผู้ใช้งาน')
            return
          }

          if (data.data.sessionvalue) {
            this.isRegister = true
          }

          const { summitidcard, phoneno } = data.data

          this.user = {
            idCardNumber: summitidcard || '',
            phoneNumber: phoneno || '0000000000'
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    getContract() {
      const vm = this
      var data = {
        token: this.token,
        transid: this.transid,
        lineid: this.userid
      }
      axios
        .post(this.$store.state.summitServer + 'API/CustomerInfo/GetAppContract', data)
        .then((response) => {
          if (response.data.status.status == 200) {
            this.contracts = response.data.contracts
            if (vm.query.contract_no) {
              const selectContactData = this.contracts.find(
                (contract) => contract.contractno == vm.query.contract_no
              )
              selectContactData && vm.selectContract(selectContactData)
            }
          } else {
            //Show error
            this.$swal.fire(response.data.status.status + ': ' + response.data.status.status_desc)
          }
        })
    },
    async submitChange() {
      var vm = this

      var url = this.$store.state.summitServer + 'API/CustomerInfo/RequestChangeAddressMobile'
      if (!this.input.address && !this.input.phoneno && !this.firstname) {
        return this.$swal.fire({
          icon: 'error',
          text: 'กรุณากรอกข้อมูลก่อนทำรายการ'
        })
      }
      vm.loading = true
      var changeid, changetype, addresstype
      var vm = this
      if (['address', 'address-estatement', 'both'].includes(vm.view)) {
        changeid = 1
        changetype = 'ที่อยู่'
      }
      if (vm.view == 'phone') {
        changeid = 2
        changetype = 'เบอร์โทร'
      }
      if (vm.view == 'name') {
        changeid = 3
        changetype = 'ชื่อ-นามสกุล'
      }
      addresstype = this.addresstypeData.find((el) => el.value == vm.input.addresstype)
      // var fulladdress = `${this.input.address}, ${this.input.zipcode.zipcode} จังหวัด ${this.input.province.province_name} อำเภอ/เขต ${this.input.district.district_name} ตำบล/แขวง ${this.input.subdistrict.subdistrict_name}`;
      var data = {
        token: this.token,
        contractid: this.selectedcontract.contractno,
        lineid: this.userid,
        changeid: changeid,
        changetype: changetype,
        addressid: addresstype.value,
        addresstype: addresstype.label,
        newaddress: this.input.address ? this.input.address : '',
        provinceid: this.input.province.province_id,
        provincename: this.input.province.province_name,
        districtid: this.input.district.district_id,
        districtname: this.input.district.district_name,
        subdistrictid: this.input.subdistrict.subdistrict_id,
        subdistrictname: this.input.subdistrict.subdistrict_name,
        zipcode: this.input.zipcode.zipcode,
        newphone: this.input.phoneno ? this.input.phoneno : '',
        firstname: this.input.firstname,
        lastname: this.input.lastname,
        filename: this.fileNames,
        transid: this.transid
      }
      try {
        for (let [key, value] of Object.entries(data)) {
          this.bodyFormData.set(key, value ? value : '')
        }
      } catch (error) {
        alert(error)
      }

      axios
        .post(url, this.bodyFormData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then(async (response) => {
          vm.loading = false
          if (response.data.status.status == 200) {
            this.$store.dispatch('sendMessage', {
              userId: vm.userId,
              pageId: vm.pageid,
              message: 'send_update_data_survey'
            })
            await vm.$swal.fire({
              icon: 'success',
              text: 'ข้อมูลของท่านจะได้รับการปรับปรุงภายใน 2 วันทำการถัดไป'
            })
            vm.cancel()
          } else {
            vm.$swal
              .fire({
                icon: 'error',
                text: 'Something went wrong!'
              })
              .then(() => {
                vm.cancel()
              })
          }
        })
    },
    handleEstatementEmailVerify(email) {
      this.verifiedEmail = email
      this.view = 'email-verification'
    },
    handleWelcomeKitEmailVerify(email) {
      this.verifiedEmail = email
      this.view = 'email-verification-welcome-kit'
    },
    showCompleteAlert() {
      return this.$swal.fire({
        icon: 'success',
        text: 'อัพเดทข้อมูลสำเร็จ'
      })
    },
    async handleEstatementComplete() {
      this.$store.dispatch('sendMessage', {
        userId: this.userId,
        pageId: this.pageid,
        message: 'send_update_email_survey'
      })
      await this.showCompleteAlert()
      this.view = 'selection'
    }
  }
}
</script>

<style lang="scss" scoped></style>
