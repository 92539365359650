var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Header',{attrs:{"text":'สมัครสินเชื่อ'}}),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"wrapper pt-6"},[_c('h6',{staticClass:"primary-title"},[(_vm.step === 1)?_c('span',[_vm._v("Personal Information")]):(_vm.step === 2)?_c('span',[_vm._v("Verify OTP")]):(_vm.step === 3)?_c('span',[_vm._v("Setup PIN")]):_vm._e()]),_c('div',{staticClass:"pt-4 custom-font"},[_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('div',{staticClass:"slide-item"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.nextStep)}}},[_c('div',{staticClass:"pb-3"},[_c('div',{staticClass:"mt-4 grey--text"},[_vm._v("Type")]),_c('ValidationProvider',{attrs:{"name":"ประเภท","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"success":valid,"error-messages":errors,"hide-details":errors.length == 0,"row":"","color":"primary"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('v-radio',{attrs:{"label":'HP - hire purchase',"value":'HP'}})],1)]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"ชื่อจริง","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{staticClass:"custom-font",attrs:{"success":valid,"placeholder":"ชื่อจริง","error-messages":errors,"hide-details":errors.length == 0,"color":"primary","label":"First name","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.first_name = ''}},model:{value:(_vm.input.first_name),callback:function ($$v) {_vm.$set(_vm.input, "first_name", $$v)},expression:"input.first_name"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"นามสกุล","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"success":valid,"error-messages":errors,"placeholder":"นามสกุล","hide-details":errors.length == 0,"label":"Last name","clearable":"","clear-icon":"mdi-close-circle","color":"primary"},on:{"click:clear":function($event){_vm.input.last_name = ''}},model:{value:(_vm.input.last_name),callback:function ($$v) {_vm.$set(_vm.input, "last_name", $$v)},expression:"input.last_name"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"อายุ","rules":{
                        required: true,
                        min_value: 1
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##'),expression:"'##'"}],attrs:{"success":valid,"error-messages":errors,"placeholder":"อายุ","hide-details":errors.length == 0,"color":"primary","label":"Age","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.age = ''}},model:{value:(_vm.input.age),callback:function ($$v) {_vm.$set(_vm.input, "age", $$v)},expression:"input.age"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"รายได้ต่อเดือน","rules":{
                        required: true,
                        numeric: true,
                        minvalue_monthlyincome: _vm.type == 'PL' ? true : false
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"success":valid,"error-messages":errors,"type":"number","placeholder":"รายได้ต่อเดือน","hide-details":errors.length == 0,"color":"primary","label":"Monthly income","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.monthlyincome = ''}},model:{value:(_vm.input.monthlyincome),callback:function ($$v) {_vm.$set(_vm.input, "monthlyincome", $$v)},expression:"input.monthlyincome"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"อาชีพ","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"success":valid,"error-messages":errors,"placeholder":"อาชีพ","hide-details":errors.length == 0,"color":"primary","label":"Occupation","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.occupation = ''}},model:{value:(_vm.input.occupation),callback:function ($$v) {_vm.$set(_vm.input, "occupation", $$v)},expression:"input.occupation"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"เบอร์โทรศัพท์","rules":{
                        required: true,
                        numeric: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##########'),expression:"'##########'"}],attrs:{"error-messages":errors,"success":valid,"placeholder":"เบอร์โทรศัพท์","type":"tel","maxlength":"10","oninput":"javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);","hide-details":errors.length == 0,"color":"primary","label":"Mobile","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.mobile = ''}},model:{value:(_vm.input.mobile),callback:function ($$v) {_vm.$set(_vm.input, "mobile", $$v)},expression:"input.mobile"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"จังหวัด","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-select',{attrs:{"items":_vm.provinceOptions,"color":"primary","success":valid,"error-messages":errors,"hide-details":errors.length == 0,"placeholder":"จังหวัด","item-text":"province_name","return-object":"","label":"Province"},on:{"change":_vm.handleChangeProvince},model:{value:(_vm.input.province),callback:function ($$v) {_vm.$set(_vm.input, "province", $$v)},expression:"input.province"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"ตำบล","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-select',{attrs:{"items":_vm.districtOptions,"color":"primary","success":valid,"error-messages":errors,"hide-details":errors.length == 0,"placeholder":"ตำบล","item-text":"district_name","return-object":"","loading":_vm.loadingDistrict,"disabled":!_vm.input.province,"label":"District"},on:{"change":_vm.handleChangeDistrict},model:{value:(_vm.input.district),callback:function ($$v) {_vm.$set(_vm.input, "district", $$v)},expression:"input.district"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"อำเภอ","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-select',{attrs:{"items":_vm.subdistrictOptions,"color":"primary","success":valid,"error-messages":errors,"hide-details":errors.length == 0,"placeholder":"อำเภอ","item-text":"subdistrict_name","return-object":"","loading":_vm.loadingSubdistrict,"disabled":!_vm.input.district,"label":"Subdistrict"},on:{"change":_vm.handleChangeSubdistrict},model:{value:(_vm.input.subdistrict),callback:function ($$v) {_vm.$set(_vm.input, "subdistrict", $$v)},expression:"input.subdistrict"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"รหัสไปรษณีย์","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"readonly":"","color":"primary","success":valid,"error-messages":errors,"hide-details":errors.length == 0,"placeholder":"รหัสไปรษณีย์","disabled":!_vm.input.subdistrict,"return-object":"","loading":_vm.loadingZipcode,"label":"Zipcode","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.zipcode = ''}},model:{value:(_vm.input.zipcode),callback:function ($$v) {_vm.$set(_vm.input, "zipcode", $$v)},expression:"input.zipcode"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"อีเมล์","rules":{
                        email: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{attrs:{"success":valid,"error-messages":errors,"placeholder":"อีเมล์","hide-details":errors.length == 0,"color":"primary","label":"E-mail","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.email = ''}},model:{value:(_vm.input.email),callback:function ($$v) {_vm.$set(_vm.input, "email", $$v)},expression:"input.email"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"เลขบัตรประชาชน","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#############'),expression:"'#############'"}],attrs:{"success":valid,"error-messages":errors,"placeholder":"รหัสบัตรประชาชน","hide-details":errors.length == 0,"color":"primary","label":"ID card","clearable":"","clear-icon":"mdi-close-circle"},on:{"click:clear":function($event){_vm.input.idcard = ''}},model:{value:(_vm.input.idcard),callback:function ($$v) {_vm.$set(_vm.input, "idcard", $$v)},expression:"input.idcard"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"เวลาที่สามารถติดต่อได้","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-select',{attrs:{"items":_vm.timeoptions,"color":"primary","success":valid,"error-messages":errors,"hide-details":errors.length == 0,"placeholder":"เวลาที่สามารถติดต่อได้","label":"Contact time"},model:{value:(_vm.input.contact_time),callback:function ($$v) {_vm.$set(_vm.input, "contact_time", $$v)},expression:"input.contact_time"}})]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"mt-4 grey--text"},[_vm._v("เคยขอสินเชื่ออื่นๆมาก่อนหรือไม่")]),_c('ValidationProvider',{attrs:{"name":"เคยขอสินเชื่อ","vid":"loan_flag","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-layout',[_c('v-flex',[_c('v-checkbox',{attrs:{"label":`เคย`,"true-value":true,"false-value":false,"color":"primary","success":valid},model:{value:(_vm.input.loan_flag),callback:function ($$v) {_vm.$set(_vm.input, "loan_flag", $$v)},expression:"input.loan_flag"}})],1),_c('v-flex',[_c('v-checkbox',{attrs:{"label":`ไม่เคย`,"true-value":false,"false-value":true,"color":"primary","success":valid},model:{value:(_vm.input.loan_flag),callback:function ($$v) {_vm.$set(_vm.input, "loan_flag", $$v)},expression:"input.loan_flag"}})],1)],1),_c('span',{staticClass:"red--text custom-font",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',[_c('div',{staticClass:"mt-4 grey--text"},[_vm._v("เคยมีประวัติค้างชำระหรือไม่")]),_c('ValidationProvider',{attrs:{"name":"เคยมีประวัติค้างชำระ","vid":"overdue_flag","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-layout',[_c('v-flex',[_c('v-checkbox',{attrs:{"label":`เคย`,"true-value":true,"false-value":false,"color":"primary","success":valid},model:{value:(_vm.input.overdue_flag),callback:function ($$v) {_vm.$set(_vm.input, "overdue_flag", $$v)},expression:"input.overdue_flag"}})],1),_c('v-flex',[_c('v-checkbox',{attrs:{"label":`ไม่เคย`,"true-value":false,"false-value":true,"color":"primary","success":valid},model:{value:(_vm.input.overdue_flag),callback:function ($$v) {_vm.$set(_vm.input, "overdue_flag", $$v)},expression:"input.overdue_flag"}})],1)],1),_c('span',{staticClass:"red--text custom-font",staticStyle:{"font-size":"12px"}},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"ยี่ห้อรถที่สนใจเช่าซื้อ","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-select',{attrs:{"items":_vm.brandOptions,"color":"primary","success":valid,"error-messages":errors,"hide-details":errors.length == 0,"placeholder":"ยี่ห้อรถที่สนใจเช่าซื้อ","item-text":"brand_name_tha","return-object":"","label":"Brand"},on:{"change":_vm.handleChangeBrand},model:{value:(_vm.input.brand),callback:function ($$v) {_vm.$set(_vm.input, "brand", $$v)},expression:"input.brand"}})]}}],null,true)})],1),_c('div',{staticClass:"pb-3"},[_c('ValidationProvider',{attrs:{"name":"รุ่นรถ","rules":{
                        required: true
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-select',{attrs:{"items":_vm.modelOptions,"color":"primary","success":valid,"error-messages":errors,"hide-details":errors.length == 0,"placeholder":"รุ่นรถ","item-text":"model_series","return-object":"","disabled":!_vm.input.brand,"label":"Model"},model:{value:(_vm.input.model),callback:function ($$v) {_vm.$set(_vm.input, "model", $$v)},expression:"input.model"}})]}}],null,true)})],1),_c('div',[_c('ValidationProvider',{attrs:{"name":"ข้อคิดเห็น","rules":{
                        required: false
                      }},scopedSlots:_vm._u([{key:"default",fn:function({ errors, valid }){return [_c('v-textarea',{staticStyle:{"border-radius":"0"},attrs:{"success":valid,"error-messages":errors,"hide-details":errors.length == 0,"placeholder":"ข้อคิดเห็น","color":"primary","label":"Note"},model:{value:(_vm.input.note),callback:function ($$v) {_vm.$set(_vm.input, "note", $$v)},expression:"input.note"}})]}}],null,true)})],1),_c('div',{staticClass:"red--text mt-5"},[_vm._v(" *แบบฟอร์มนี้ใช้สำหรับลูกค้าที่สนใจเช่าซื้อรถจักรยานยนต์ใหม่ กับบริษัทซัมมิท แคปปิตอล ลีสซิ่ง เท่านั้น"),_c('br'),_vm._v(" *กรุณากรอกข้อมูลให้ครบถ้วนเพื่อส่งแบบฟอร์ม"),_c('br'),_vm._v(" หมายเหตุ : จะมีเจ้าหน้าที่จากทางบริษัทฯ ใช้เบอร์ 02-1072222 ติดต่อลูกค้า ")]),_c('div',{staticClass:"text-center my-4"},[_c('v-btn',{staticClass:"primary-btn",attrs:{"type":"submit","width":"200px","disabled":_vm.IDloading,"loading":_vm.IDloading,"height":"45px","dark":""}},[_vm._v("ถัดไป")])],1)])])]}}])})],1)])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }