<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <div
            style="font-weight: bold; text-decoration: underline;margin-bottom: 10px"
          >บริษัทประกันภัย จะชดใช้ค่าสินไหมทดแทน ตามทุนประกันภัยในปีที่เกิดเหตุ</div>
          <p>
            ความคุ้มครอง กรมธรรม์ประกันภัยนี้ชดใช้ค่าสินไหมทดแทนแก่ผู้เอาประกันภัยหรือผู้เช่าซื้อ อันเนื่องมาจากการสูญเสียทางการเงินของผู้เอาประกันภัย ในกรณีที่ผู้เช่าซื้อไม่ชำระค่าเช่าซื้อตามสัญญาเช่าซื้อ ทำให้ผู้เอาประกันภัยต้องสูญเสียรายได้ไปจากกรณีดังต่อไปนี้
            <br />1. กรณีสูญหายทั้งคัน เนื่องจากรถถูกโจรกรรม การลักทรัพย์ ชิงทรัพย์ ปล้นทรัพย์
            <br />2. กรณีที่ทรัพย์สินที่เอาประกันภัยได้รับความเสียหายสิ้นเชิง มูลค่าความเสียหายจากอุบัติเหตุเกินกว่า 60% ของทุนประกันภัยปีที่1 , เกินกว่า 80% ของทุน ประกันภัยปีที่ 2, เกินกว่า 85% ของทุนประกันภัยปีที่ 3 และเกินกว่า 90% ของทุนประกันภัยปีที่ 3 ขึ้นไป
          </p>
          <p style="color:red">** รายละเอียดความคุ้มครองเป็นไปตามที่ บ.ประกันภัยกำหนด</p>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>