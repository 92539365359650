<template>
  <div>
    <Header :text="'เข้าสู่ระบบ'" :headerImg="1"></Header>
    <div style="position: relative">
      <div class="content">
        <div class="wrapper pt-6">
          <h6 class="primary-title">
            <span>Enter PIN Number</span>
          </h6>
          <div class="pt-4 custom-font">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(verifyPin)">
                <div class="text-center pt-8">
                  <ValidationProvider
                    name="รหัส PIN"
                    :rules="{
                      required: true,
                      min: 6,
                      numeric: true
                    }"
                    v-slot="{ errors, valid }"
                  >
                    <v-text-field
                      :success="valid"
                      :error-messages="errors"
                      pattern="[0-9]*"
                      inputmode="numeric"
                      type="password"
                      placeholder="PIN number"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      maxlength="6"
                      v-model="input.pin"
                      :hide-details="errors.length ? false : true"
                      style="border-radius: 0px"
                      label="PIN number"
                      color="blue"
                    ></v-text-field>
                  </ValidationProvider>
                </div>
                <div class="text-center mt-4">
                  <v-progress-circular
                    v-if="forgetloading"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <a v-else @click="forgetPin">Forget PIN</a>
                  <div class="mx-5" style="margin-top: 80px">
                    <v-btn
                      type="submit"
                      width="200px"
                      :disabled="loading"
                      :loading="loading"
                      height="45px"
                      dark
                      class="primary-btn"
                      >LOGIN</v-btn
                    >
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios'
import queryString from 'query-string'

export default {
  components: {
    Header
  },
  data() {
    return {
      forgetloading: false,
      query: '',
      loading: false,
      serverUrl: 'https://api-summit-cap.zwiz.app/',
      input: {
        pin: ''
      }
    }
  },
  computed: {
    token() {
      if (this.query.apptype == 'line') {
        return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      } else {
        return '1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B'
      }
    },
    transid() {
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    },
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    }
  },
  mounted() {
    this.queryinit()
  },
  methods: {
    backTo() {
      if (this.query && this.query.back_to) {
        const { back_to, ...etc } = this.query
        this.$router.push({
          path: '/',
          query: { ...etc, view: this.query.back_to }
        })
        location.reload()
      }
    },
    forgetPin() {
      this.forgetloading = true
      var vm = this
      var userid
      if (this.query.apptype == 'line') {
        userid = this.userid + '__' + this.pageid
      } else {
        userid = this.userid
      }
      axios
        .get(this.serverUrl + `summitidcard?userid=${userid}&pageid=${this.pageid}`, {
          headers: {
            AUTHORIZATION: 'Bearer xxx xxx'
          }
        })
        .then((response) => {
          vm.forgetloading = false
          if (response.data.responsecode == 200) {
            var idcard = response.data.idcard
            vm.$route.query.idcard = idcard
            vm.$route.query.view = 'register'
          }
        })
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    },
    verifyPin() {
      var vm = this
      this.loading = true
      var data = {
        lineid: this.userid,
        pin: this.input.pin,
        transid: this.transid,
        token: vm.token
      }
      axios
        .post(this.$store.state.summitServer + 'API/ManagePinInfo/VerifyPinLineId', data)
        .then(async (response) => {
          this.loading = false
          if (response.data.status.status == 200) {
            if (vm.query.apptype == 'line') {
              await vm.$liff.sendMessages([
                {
                  type: 'text',
                  text: 'E-service logged'
                }
              ])
              if (this.query && this.query.back_to) {
                vm.backTo()
              } else {
                vm.$liff.closeWindow()
              }
            } else if (vm.query.apptype == 'facebook') {
              var response = await axios.get(vm.serverUrl + 'checkpinlineid', {
                params: {
                  userid: vm.userid,
                  pageid: vm.query.pageid,
                  pin: vm.input.pin
                },
                headers: { AUTHORIZATION: 'Bearer xxx xxx' }
              })
              if (this.query && this.query.back_to) {
                vm.backTo()
              } else {
                MessengerExtensions.requestCloseBrowser(
                  function success() {},
                  function error() {
                    window.close()
                  }
                )
              }
            }
          } else {
            vm.$swal.fire({
              icon: 'error',
              text: 'ข้อมูลไม่ถูกต้อง'
            })
          }
        })
    }
  }
}
</script>
