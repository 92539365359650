<template>
  <div v-if="!isCheckLogin">
    <Header
      :text="`เปลี่ยนแปลง <br/>${headertext}`"
      :detail="headerDetailText"
      :customStyle="'padding-top: 24px'"
      :headerImg="4"
    ></Header>
    <div style="position: relative">
      <div class="content">
        <div v-if="step == 1" class="wrapper pt-6">
          <h6 class="primary-title mb-8">กรุณาเลือกช่องทางการรับ e-Statement</h6>
          <EstatementChannelSelect
            v-if="user && user.idCardNumber"
            :onlyAction="true"
            :token="token"
            :transaction-id="transid"
            :line-id="userid"
            :id-card-number="user.idCardNumber"
            :mobile-number="user.phoneNumber"
            :disable-edit-address="true"
            :hide-edit-address="contracts && contracts.length ? true : false"
            @complete="handleSelectType"
            @cancel="view = 'selection'"
            @verify:email="handleEstatementEmailVerify"
          />
        </div>
        <div v-if="step == 2" class="wrapper pt-6">
          <template v-if="view === 'email-verification'">
            <h6 class="primary-title mb-8">Verify OTP</h6>
            <EstatementEmailVerification
              :onlyAction="true"
              :email="verifiedEmail"
              :line-id="userid"
              :id-card-number="user.idCardNumber"
              :mobile-number="user.phoneNumber"
              :token="token"
              :transaction-id="transid"
              :contract-id="selectedcontract.contractno"
              @complete="handleEstatementCompleteVerifyEmail"
              @cancel="view = 'selection'"
            />
          </template>
          <template v-else>
            <h6 class="primary-title mb-8">Contract ID</h6>
            <input
              style="margin: 8px"
              @change="handleSelectAllContact"
              type="checkbox"
              id="select-all"
              name="select-all"
            />
            <label for="select-all">เลือกทั้งหมด</label><br />
            <div class="slide-item">
              <v-card
                v-ripple
                outlined
                v-for="(item, k) in contracts"
                :key="k"
                class="mt-1"
                @click.stop="handleClickContactCard(k)"
              >
                <v-card-text>
                  <v-layout row wrap class="py-2">
                    <input
                      :id="`id_${running}_${k}`"
                      :ref="`contact_${k}`"
                      :style="{ margin: '12px' }"
                      type="checkbox"
                      @click.stop="(evt) => selectContract(evt, item)"
                      :value="selectedContractNos.includes(item.contractno)"
                    />
                    <div>
                      <div class="p-2" style="color: black">{{ item.contractno }}</div>
                      <div class="p-2">{{ item.contractdesc }}</div>
                      <div class="p-2">
                        {{ item.product_type }} {{ item.license }} {{ item.statement_channel }}
                      </div>
                    </div>
                  </v-layout>
                </v-card-text>
              </v-card>
            </div>
            <div class="mt-8">
              <v-btn
                @click="handleEstatementComplete(type)"
                :loading="loading"
                block
                dark
                :disabled="!type || selectedContractNos.length === 0"
                class="my-4 primary-btn mx-auto"
                >บันทึก</v-btn
              >
              <v-btn
                v-if="$listeners.cancel"
                block
                outlined
                color="grey"
                :disabled="loading"
                class="my-4 mx-auto"
                @click="step = 1"
                >ยกเลิก</v-btn
              >
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <v-row justify="center" align="center" style="height: 100vh">
      <v-col class="text-center mt-7">
        <v-progress-circular indeterminate color="blue" size="80"></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import districtOptions from '@/assets/js/district.js'
import provinceOptions from '@/assets/js/province.js'
import subdistrictOptions from '@/assets/js/subdistrict.js'
import zipcodeOptions from '@/assets/js/zipcode.js'
import Header from '@/components/Header.vue'
import ThaiAddressInput from '@/components/ThaiAddressInput.vue'
import axios from 'axios'
import queryString from 'query-string'
import { EstatementChannelSelect, EstatementEmailVerification } from '@/components/estatement'
import { WelcomeKitChannelSelect, WelcomeKitEmailVerification } from '@/components/welcomeKit'
import { sendErrorLog } from '@/utils/sendErrorLog'

export default {
  components: {
    ThaiAddressInput,
    Header,
    EstatementChannelSelect,
    EstatementEmailVerification,
    WelcomeKitChannelSelect,
    WelcomeKitEmailVerification
  },
  data() {
    return {
      type: null,
      headertext: 'แก้ไขข้อมูล',
      bodyFormData: new FormData(),
      loading: false,
      fileNames: [],
      serverUrl: 'https://api-summit-cap.zwiz.app/',
      step: 1,
      prefix: '',
      contracts: '',
      files: [],
      query: '',
      pageid: '',
      view: 'selection',
      menu: false,
      userstatus: '',
      picfile: new FormData(),
      selectedcontract: {},
      selectedContractNos: [],
      input: {
        contractid: '',
        addresstype: 4,
        phoneno: '',
        firstname: '',
        lastname: '',
        province: {},
        district: {},
        zipcode: {},
        subdistrict: {}
      },
      addresstypeData: [
        // { label: "ที่อยู่ตามทะเบียนบ้าน", value: 1 },
        { label: 'ที่อยู่ปัจจุบัน', value: 2 },
        { label: 'ที่อยู่ที่ทำงาน', value: 3 },
        { label: 'ที่อยู่จัดส่งเอกสาร', value: 4 }
        // { label: "ที่อยู่การรับเล่มทะเบียน", value: 5 }
      ],
      changetypeData: [
        { label: 'ที่อยู่', value: 1 },
        { label: 'เบอร์โทร', value: 2 }
      ],
      verifiedEmail: '',
      email: '',
      oldEmail: '',
      oldAddress: {},
      user: {
        idCardNumber: '',
        phoneNumber: ''
      },
      running: 0,
      isRegister: false,
      isCheckLogin: true
    }
  },
  computed: {
    token() {
      if (this.query.apptype == 'line') {
        return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      } else {
        return '1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B'
      }
    },
    headerDetailText() {
      switch (this.view) {
        case 'channel_welcome_kit':
          return 'สัญญาเช่าซื้อ<br />และเอกสาร Welcome Kit'
          break

        default:
          return ''
          break
      }
    },
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    },
    userId() {
      let tempUserId = this.query.userid
      return tempUserId
    },
    provinceOptions() {
      return provinceOptions.sort((a, b) => {
        if (a.province_name < b.province_name) {
          return -1
        }
        if (a.province_name > b.province_name) {
          return 1
        }
        return 0
      })
    },
    districtOptions() {
      var result
      if (this.input.province.province_id) {
        result = districtOptions.filter((el) => el.province_id == this.input.province.province_id)
      } else {
        result = districtOptions
      }

      return result.sort((a, b) => {
        if (a.district_name < b.district_name) {
          return -1
        }
        if (a.district_name > b.district_name) {
          return 1
        }
        return 0
      })
    },
    subdistrictOptions() {
      var result
      if (this.input.district.district_id) {
        result = subdistrictOptions.filter(
          (el) => el.district_id == this.input.district.district_id
        )
      } else {
        result = subdistrictOptions
      }
      return result.sort((a, b) => {
        if (a.subdistrict_name < b.subdistrict_name) {
          return -1
        }
        if (a.subdistrict_name > b.subdistrict_name) {
          return 1
        }
        return 0
      })
    },
    zipcodeOptions() {
      var result
      if (this.input.subdistrict.subdistrict_id) {
        result = zipcodeOptions.filter(
          (el) => el.subdistrict_id == this.input.subdistrict.subdistrict_id
        )
      } else {
        result = zipcodeOptions
      }
      return result.sort((a, b) => {
        if (a.zipcode < b.zipcode) {
          return -1
        }
        if (a.zipcode > b.zipcode) {
          return 1
        }
        return 0
      })
    },
    transid() {
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    }
  },
  async mounted() {
    this.queryinit()
    await this.fetchUser()
    this.checksession()
    this.getContract()
  },
  methods: {
    handleSelectAllContact(evt) {
      this.running++
      if (evt.target.checked) {
        for (let i = 0; i < this.contracts.length; i++) {
          if (!this.selectedContractNos.includes(this.contracts[i].contractno)) {
            this.handleClickContactCard(i)
          }
        }
      } else {
        for (let i = 0; i < this.contracts.length; i++) {
          if (this.selectedContractNos.includes(this.contracts[i].contractno)) {
            this.handleClickContactCard(i)
          }
        }
      }
    },
    handleSelectType(selectType) {
      this.type = selectType
      this.step = 2
    },
    handleClickContactCard(index) {
      this.$refs[`contact_${index}`][0].click()
    },
    clearicon() {},
    async fetchChannelStatement() {
      const payload = {
        token: this.token,
        transid: this.transid,
        lineid: this.userid,
        requestno: '0',
        contractid: '0',
        idcard: this.user.idCardNumber,
        doctype: '2'
      }

      try {
        const { statement_type, emailAddress, customer_address } = await axios
          .post(this.$store.state.summitServer + 'API/Statement/GetChannelStatement', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (message_code !== '200') throw new Error(message_name)
            return response.data
          })

        if (this.mode !== 'email') {
          this.type = statement_type
        }
        this.email = emailAddress
        this.oldEmail = emailAddress
        this.address = customer_address
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'GetChannelStatement',
          error,
          payload
        })
        this.showErrorAlert({ traceId })
      }
    },
    async checksession() {
      const vm = this
      try {
        const userid = this.userId
        const response = await axios.get(
          this.serverUrl + `checksessionexpire?userid=${userid}&pageid=${this.pageid}`,
          {
            headers: { AUTHORIZATION: 'Bearer xxx xxx' }
          }
        )
        if (
          response &&
          response.data &&
          response.data.responsecode &&
          response.data.responsecode == 400
        ) {
          if (this.isRegister) {
            vm.$router.push({
              path: '/',
              query: { ...this.query, view: 'login', back_to: 'MultipleChangeaddress' }
            })
          } else {
            vm.$router.push({
              path: '/',
              query: { ...this.query, view: 'register' }
            })
          }
          location.reload()
        } else {
          setTimeout(() => {
            vm.isCheckLogin = false
          }, 3000)
        }
      } catch (error) {
        vm.$swal.fire({
          icon: 'error',
          text: 'เรียกข้อมูลไม่สำเร็จ'
        })
      } finally {
        setTimeout(() => {
          vm.isCheckLogin = false
        }, 3000)
      }
    },
    onSelectAddress(e) {
      this.input.province = e.province
      this.input.amphoe = e.amphoe
      this.input.district = e.district
      this.input.zipcode = e.zipcode
    },
    cancel() {
      this.step = 2
      this.picfile = new FormData()
      this.input = {
        contractid: '',
        addresstype: 4,
        phoneno: '',
        firstname: '',
        lastname: '',
        province: {},
        district: {},
        zipcode: {},
        subdistrict: {}
      }
      this.view = this.view === 'address-estatement' ? 'channel' : 'selection'
      this.bodyFormData = new FormData()
    },
    fileUpload(e) {
      var files = e
      files.forEach((el) => {
        this.bodyFormData.append(el.name, el)
        this.fileNames.push(el.name)
      })
      if (this.filenames) {
        this.fileNames = this.fileNames.join('|')
      }
    },
    selectContract(evt, data) {
      if (data.contractno) {
        if (evt.target.checked) {
          let temp = [...this.selectedContractNos, data.contractno]
          this.selectedContractNos = [...new Set(temp)]
        } else {
          let index = this.selectedContractNos.indexOf(data.contractno)
          if (index > -1) {
            this.selectedContractNos.splice(index, 1)
          }
        }
      }
      // this.selectedcontract = data
      // this.step = 2
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    },
    async fetchUser() {
      try {
        const { status, data } = await axios.get(
          this.serverUrl + 'summit-capital/liff/getuseronly',
          {
            params: { page_id: this.pageid, user_id: this.userId },
            headers: { AUTHORIZATION: 'Bearer VlRrNU1qWmtNek0zTVdFellXSTBNVEl4Wm1ZNVlUazVOakZt=' }
          }
        )

        if (status === 200) {
          if (Array.isArray(data.data)) {
            console.error('ไม่พบผู้ใช้งาน')
            return
          }

          if (data.data.sessionvalue) {
            this.isRegister = true
          }

          const { summitidcard, phoneno } = data.data
          this.user = {
            idCardNumber: summitidcard || '',
            phoneNumber: phoneno || '0000000000'
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    getContract() {
      var data = {
        token: this.token,
        transid: this.transid,
        lineid: this.userid
      }
      axios
        .post(this.$store.state.summitServer + 'api/statement/GetAppContract', data)
        .then((response) => {
          if (
            (response.data.status &&
              response.data.status.status &&
              response.data.status.status == 200) ||
            (response.data.message_code && response.data.message_code == 200)
          ) {
            this.contracts = response.data.contracts
          } else {
            //Show error
            this.$swal.fire(response.data.status.status + ': ' + response.data.status.status_desc)
          }
        })
    },
    handleEstatementEmailVerify(email, type) {
      this.type = type
      this.verifiedEmail = email
      this.view = 'email-verification'
      this.step = 2
    },
    handleWelcomeKitEmailVerify(email) {
      this.verifiedEmail = email
      this.view = 'email-verification-welcome-kit'
    },
    showCompleteAlert() {
      return this.$swal.fire({
        icon: 'success',
        text: 'อัพเดทข้อมูลสำเร็จ'
      })
    },
    async handleEstatementComplete(type) {
      //
      const vm = this
      const payload = {
        token: this.token,
        transid: this.transid,
        lineid: this.userid,
        idcard: this.user.idCardNumber,
        contractid: this.selectedContractNos.join('|'),
        mobileno: this.user.phoneNumber,
        statementtype: type,
        emailaddress: type === '10' ? this.verifiedEmail : '',
        doctype: '2',
        lineidwelcomekit: '',
        emailaddresswelcomekit: '',
        existflag: '1'
      }
      try {
        this.loading = true
        const response = await axios.post(
          this.$store.state.summitServer + 'API/Statement/ConfirmChannelStatement',
          payload
        )
        const { message_code, message_name } = response.data
        if (message_code !== '200') throw new Error(message_name)
        this.$store.dispatch('sendMessage', {
          userId: this.userId,
          pageId: this.pageid,
          message: 'send_multiple_update_data_survey'
        })
        await vm.$swal.fire({
          icon: 'success',
          text: 'ข้อมูลของท่านจะได้รับการปรับปรุงภายใน 2 วันทำการถัดไป'
        })
        vm.cancel()
        if (this.query.apptype == 'line') {
          this.$liff.closeWindow()
        } else if (this.query.apptype == 'facebook') {
          MessengerExtensions.requestCloseBrowser(
            function success() {},
            function error() {
              window.close()
            }
          )
        }
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'ConfirmChannelStatement',
          error,
          payload
        })
        this.showErrorAlert({ traceId })
      } finally {
        this.loading = false
      }
    },
    handleEstatementCompleteVerifyEmail() {
      this.view = 'selection'
    },
    showErrorAlert({ traceId = null }) {
      this.$swal.fire({
        icon: 'error',
        html: `กรุณาติดต่อเจ้าหน้าที่${
          traceId ? `<br /><small>(Trace ID: ${traceId})</small>` : ''
        }`
      })
    }
  }
}
</script>

<style lang="scss" scoped></style>
