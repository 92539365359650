<template>
  <ValidationObserver tag="div" v-slot="{ handleSubmit }">
    <form class="text-center" @submit.prevent="handleSubmit(submit)">
      <div class="font-weight-bold mb-1">กรุณากรอก OTP ที่ได้รับทาง e-mail</div>
      <div class="mb-2">(Ref: {{ otpRef }})</div>
      <ValidationProvider
        name="รหัส OTP"
        :rules="{
          required: true,
          min: 6
        }"
        v-slot="{ errors, valid }"
      >
        <v-text-field
          v-model="otp"
          maxlength="6"
          :success="otpError ? false : valid"
          :error-messages="otpError ? otpError : errors"
          style="border-radius: 0px"
          @input="otpError = ''"
          v-uppercase
          placeholder="OTP: XXXXXX"
        ></v-text-field>
      </ValidationProvider>
      <a :style="isResendable ? '' : `color: grey`" @click="resendOtp">
        Resend OTP
        <div>(ขอส่งใหม่ได้ทุก 60 วินาที)</div>
      </a>
      <div class="mt-8">
        <v-btn :loading="isLoading" block dark class="my-4 primary-btn mx-auto" type="submit"
          >ยืนยัน</v-btn
        >
        <v-btn
          v-if="$listeners.cancel"
          block
          outlined
          color="grey"
          :disabled="isLoading"
          class="my-4 mx-auto"
          @click="$emit('cancel')"
          >ยกเลิก</v-btn
        >
      </div>
    </form>

    <v-dialog v-model="showResentDialog" persistent :max-width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <v-avatar rounded color="#5ecbf3" :size="64" class="mb-6">
            <v-icon dark large>mdi-mail</v-icon>
          </v-avatar>
          <h2 class="mb-3">Resent verification code</h2>
          <p class="ma-0">
            We have just sent an email with a new verification code to <strong>{{ email }}</strong
            >.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn dark large :min-width="120" class="primary-btn" @click="showResentDialog = false"
            >Got it</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import axios from 'axios'
import { sendErrorLog } from '@/utils/sendErrorLog'

export default {
  props: {
    email: {
      type: String,
      required: true
    },
    lineId: String,
    idCardNumber: String,
    mobileNumber: String,
    token: String,
    transactionId: String,
    contractId: String
  },
  data() {
    return {
      otp: '',
      otpRef: '',
      timeoutId: undefined,
      otpError: '',
      showResentDialog: false,
      isResendable: true,
      isLoading: false
    }
  },
  created() {
    this.requestOtp()
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId)
  },
  methods: {
    showErrorAlert({ traceId = null }) {
      this.$swal.fire({
        icon: 'error',
        html: `กรุณาติดต่อเจ้าหน้าที่${
          traceId ? `<br /><small>(Trace ID: ${traceId})</small>` : ''
        }`
      })
    },
    async requestOtp() {
      const payload = {
        token: this.token,
        transid: this.transactionId,
        emailAddress: '',
        emailaddresswelcomekit: this.email,
        lineid: this.lineId,
        idcard: this.idCardNumber,
        mobileno: this.mobileNumber
      }

      try {
        return await axios
          .post(this.$store.state.summitServer + 'API/Statement/SendOTPEmail', payload)
          .then((response) => {
            const { message_code, message_name, otp_ref = '' } = response.data
            if (!['200', '225'].includes(message_code)) throw new Error(message_name)
            this.otpRef = otp_ref
            return response.data
          })
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'SendOTPEmail',
          error,
          payload
        })
        this.showErrorAlert({ traceId })
      }
    },
    resendOtp() {
      if (!this.isResendable) return

      this.requestOtp()
      this.isResendable = false
      this.showResentDialog = true
      this.timeoutId = setTimeout(() => {
        this.isResendable = true
      }, 60000)
    },
    async confirmStatementChannel() {
      const payload = {
        token: this.token,
        transid: this.transactionId,
        lineid: '',
        lineidwelcomekit: this.lineId,
        idcard: this.idCardNumber,
        contractid: this.contractId,
        mobileno: this.mobileNumber,
        statementtypewelcomekit: '10',
        statementtype: '',
        emailaddress: '',
        emailaddresswelcomekit: this.email,
        doctype: '3',
        existflag: '1'
      }

      try {
        return await axios
          .post(this.$store.state.summitServer + 'API/Statement/ConfirmChannelStatement', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (message_code !== '200') throw new Error(message_name)
            return true
          })
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'ConfirmChannelStatement',
          error,
          payload
        })
        this.showErrorAlert({ traceId })
      }
    },
    async submit() {
      const payload = {
        token: this.token,
        transid: this.transactionId,
        lineid: this.lineId,
        idcard: this.idCardNumber,
        mobileno: this.mobileNumber,
        otp_ref: this.otpRef,
        otp_value: this.otp,
        emailAddress: this.email,
        contractId: this.contractId
      }

      try {
        this.isLoading = true

        await axios
          .post(this.$store.state.summitServer + 'API/Statement/ConfirmOTPEmail', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (!['200'].includes(message_code)) throw new Error(`Invalid OTP: ${message_name}`)
            return true
          })

        await this.confirmStatementChannel()

        this.$emit('complete')
      } catch (error) {
        if (error.message.startsWith('Invalid OTP:')) {
          this.otpError = 'รหัส OTP ไม่ถูกต้องกรุณาลองใหม่อีกครั้ง'
          return
        } else {
          const traceId = Date.now()
          sendErrorLog({
            trace_id: traceId,
            name: 'ConfirmOTPEmail',
            error,
            payload
          })
          this.showErrorAlert({ traceId })
        }
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
