import axios from 'axios'

export const sendErrorLog = ({ trace_id, name = 'Error', error, payload = null }) => {
  try {
    let body = {
      trace_id,
      name,
      error: JSON.stringify(error),
      payload
    }
    if (error instanceof Error) {
      const { stack, message, name } = error
      body = {
        name,
        message,
        stack: JSON.stringify(stack),
        trace_id,
        payload
      }
    }
    axios.post(`https://api-summit-cap.zwiz.app/logs`, body, {
      headers: { AUTHORIZATION: 'Bearer xxx xxx' }
    })
  } catch (error) {
    console.error(error)
  }
}
