<template>
  <div>
    <vue-element-loading
      is-full-screen
      :active="fullScreenLoading"
      spinner="bar-fade-scale"
      color="#152d51"
    />
    <Header :text="'ลงทะเบียนลูกค้าปัจจุบัน'" :headerImg="2"></Header>
    <div style="position: relative">
      <div class="content">
        <div class="wrapper pt-6">
          <div class="custom-font">
            <transition-group tag="div" class="img-slider" name="slide">
              <div v-if="step == 1" :key="1">
                <ValidationObserver v-slot="{ handleSubmit }">
                  <div class="slide-item">
                    <h6 class="primary-title" style="padding: 0; margin-bottom: 12px">
                      <span>ข้อมูลลูกค้า</span>
                    </h6>
                    <form @submit.prevent="handleSubmit(verifyIdMobileContract)">
                      <div class="pb-3">
                        <ValidationProvider
                          name="เลขบัตรประชาชน"
                          :rules="{
                            required: true,
                            digits: 13
                          }"
                          v-slot="{ errors, valid }"
                        >
                          <ThaiNationalId
                            v-model="inputID.idcard"
                            :success="valid"
                            :errors="errors"
                            :label="'เลขบัตรประชาชน'"
                            :showIcon="false"
                            :hasClearable="true"
                          ></ThaiNationalId>
                        </ValidationProvider>
                      </div>
                      <div class="pb-3">
                        <ValidationProvider rules="required" name="วันเกิด" v-slot="{ errors }">
                          <BirthdayPicker v-model="inputID.birthdate">
                            <v-text-field
                              readonly
                              :value="computedBirthdate"
                              :error-messages="errors"
                              :hide-details="errors.length ? false : true"
                              placeholder="วัน/เดือน/ปี เกิด (พ.ศ.)"
                              label="วัน/เดือน/ปี เกิด (พ.ศ.)"
                              clearable
                              clear-icon="mdi-close-circle"
                              @click:clear="inputID.birthdate = ''"
                            >
                            </v-text-field>
                          </BirthdayPicker>
                        </ValidationProvider>
                      </div>
                      <div class="pb-3">
                        <ValidationProvider
                          name="เบอร์มือถือ"
                          :rules="{
                            required: true,
                            thai_phonenumber: true
                          }"
                          v-slot="{ errors, valid }"
                        >
                          <ThaiPhoneNumber
                            v-model="inputID.mobileno"
                            :success="valid"
                            :errors="errors"
                            :label="'เบอร์มือถือ'"
                            :showIcon="false"
                            :hasClearable="true"
                          ></ThaiPhoneNumber>
                        </ValidationProvider>
                      </div>
                      <div class="text-center mt-9">
                        <v-btn
                          type="submit"
                          width="200px"
                          :disabled="IDloading"
                          :loading="IDloading"
                          height="45px"
                          dark
                          class="primary-btn"
                          >ลงทะเบียน</v-btn
                        >
                      </div>
                    </form>
                  </div>
                </ValidationObserver>
              </div>
              <div v-if="step == 2" :key="2">
                <div class="slide-item">
                  <h6 class="primary-title" style="padding: 0; margin-bottom: 12px">
                    <span>Verify OTP</span>
                  </h6>
                  <div class="text-center">
                    <div class="font-weight-bold mb-1">กรุณากรอก OTP ที่ได้รับทาง SMS</div>
                    <div class="mb-2">(Ref: {{ otpref }})</div>
                    <ValidationProvider
                      name="รหัส OTP"
                      :rules="{
                        required: true,
                        min: 6
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="otp"
                        maxlength="6"
                        :success="wrongotp ? false : valid"
                        :error-messages="wrongotp ? wrongotp : errors"
                        style="border-radius: 0px"
                        @input="wrongotp = ''"
                        v-uppercase
                        placeholder="OTP: XXXXXX"
                      ></v-text-field>
                    </ValidationProvider>
                    <a :style="sendedOtp ? `color: grey` : ''" @click="resendOtp">
                      Resend OTP
                      <div>(ขอส่งใหม่ได้ทุก 60 วินาที)</div>
                    </a>
                    <v-btn
                      @click="verifyOtp"
                      class="mt-9 primary-btn"
                      dark
                      height="45px"
                      width="200px"
                      >ถัดไป</v-btn
                    >
                  </div>
                </div>
              </div>
              <div v-if="step == 3" :key="3">
                <div class="slide-item">
                  <h6 class="primary-title" style="padding: 0; margin-bottom: 12px">
                    <span>Setup PIN</span>
                  </h6>
                  <!-- <h1 class="font-weight-black mb-9">Setup PIN</h1> -->

                  <div class="text-center">
                    <div class="font-weight-bold mb-2">กรุณาตั้งรหัส PIN 6 หลัก</div>
                    <ValidationObserver v-slot="{ handleSubmit }">
                      <form @submit.prevent="handleSubmit(savePinLineId)">
                        <ValidationProvider
                          name="รหัส PIN"
                          vid="pinnumber"
                          :rules="{ required: true, min: 6, numeric: true }"
                          v-slot="{ errors, valid }"
                        >
                          <v-text-field
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            type="password"
                            v-model="pinnumber"
                            maxlength="6"
                            :success="valid"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            :error-messages="errors"
                            style="border-radius: 0px"
                            placeholder="PIN number"
                            label="PIN number"
                          ></v-text-field>
                        </ValidationProvider>

                        <ValidationProvider
                          name="ยืนยันรหัส PIN"
                          v-slot="{ errors, valid }"
                          :rules="{ confirmed: 'pinnumber', required: true }"
                        >
                          <v-text-field
                            class="mt-2"
                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                            type="password"
                            pattern="[0-9]*"
                            inputmode="numeric"
                            v-model="pinconfirm"
                            maxlength="6"
                            :success="pinconfirm ? valid : false"
                            :error-messages="errors"
                            style="border-radius: 0px"
                            placeholder="PIN number confirmation"
                            label="PIN number confirmation"
                          ></v-text-field>
                        </ValidationProvider>

                        <v-btn
                          :loading="completeloading"
                          dark
                          class="mt-9 primary-btn"
                          type="submit"
                          height="45px"
                          width="200px"
                          >ถัดไป</v-btn
                        >
                      </form>
                    </ValidationObserver>
                  </div>
                </div>
              </div>
              <div v-if="step == 4" :key="4">
                <ValidationObserver v-slot="{ handleSubmit }" tag="div" class="slide-item">
                  <form @submit.prevent="handleSubmit(selectStatementChannel)">
                    <h6 class="primary-title" style="padding: 0; margin-bottom: 12px">
                      <span>1.สัญญาเช่าซื้อและเอกสาร Welcome Kit</span>
                    </h6>
                    <div class="mb-4">
                      <v-radio-group v-model="statementTypeWelcomeKit" hide-details>
                        <v-radio value="11" label="LINE OA Summit Capital" />
                        <!-- <p v-if="statementType === '11'" class="red--text">
                          สำหรับลูกค้าที่เลือกรับ e-Statement ผ่าน LINE OA 1,000 ท่านแรก รับส่วนลด
                          Shopee 50 บาท
                        </p> -->
                        <v-radio value="10" label="E-mail" />
                        <ValidationProvider
                          v-if="statementTypeWelcomeKit === '10'"
                          v-slot="{ errors }"
                          name="E-mail"
                          :rules="{ required: statementTypeWelcomeKit === '10', email: true }"
                          slim
                        >
                          <v-text-field
                            v-model="email"
                            label="กรอก e-mail*"
                            :error-messages="errors"
                            class="mb-2"
                          />
                        </ValidationProvider>
                        <v-radio value="12" label="ไปรษณีย์ ที่อยู่จัดส่งเอกสาร" />
                        <p v-if="statementTypeWelcomeKit === '12' && !!address">{{ address }}</p>
                      </v-radio-group>
                    </div>
                    <div>
                      <h6 class="primary-title" style="padding: 0; margin-bottom: 12px">
                        <span>2.ใบแจ้งหนี้/ใบเสร็จรับเงิน/ใบกำกับภาษี (e-Statement)</span>
                      </h6>
                      <v-radio-group v-model="statementType" hide-details>
                        <v-radio value="11" label="LINE OA Summit Capital" />
                        <!-- <p v-if="statementType === '11'" class="red--text">
                          สำหรับลูกค้าที่เลือกรับ e-Statement ผ่าน LINE OA 1,000 ท่านแรก รับส่วนลด
                          Shopee 50 บาท
                        </p> -->
                        <v-radio value="10" label="E-mail" />
                        <ValidationProvider
                          v-if="statementType === '10'"
                          v-slot="{ errors }"
                          name="E-mail"
                          :rules="{ required: statementType === '10', email: true }"
                          slim
                        >
                          <v-text-field
                            v-model="email"
                            label="กรอก e-mail*"
                            :error-messages="errors"
                            class="mb-2"
                          />
                        </ValidationProvider>
                        <v-radio value="12" label="ไปรษณีย์ ที่อยู่จัดส่งเอกสาร" />
                        <p v-if="statementType === '12' && !!address">{{ address }}</p>
                      </v-radio-group>
                    </div>

                    <v-btn
                      :loading="completeloading"
                      block
                      dark
                      class="mt-9 primary-btn mx-auto"
                      type="submit"
                      height="45px"
                      min-width="200px !important"
                      :disabled="!statementType"
                      >{{ statementType === '10' ? 'ถัดไป' : 'ยืนยัน' }}</v-btn
                    >

                    <p
                      class="body-2 primary--text my-8"
                      :style="{ fontFamily: 'inherit !important' }"
                    >
                      หมายเหตุ: การเลือกช่องทางข้างต้นนี้จะมีผลกับทุกสัญญา
                    </p>
                  </form>
                </ValidationObserver>
              </div>
              <div v-if="step == 5" :key="5">
                <div class="slide-item">
                  <h6 class="primary-title" style="padding: 0; padding-bottom: 12px">
                    <span>Verify OTP</span>
                  </h6>
                  <div class="text-center">
                    <div class="font-weight-bold mb-1">กรุณากรอก OTP ที่ได้รับทาง e-mail</div>
                    <div class="mb-2">(Ref: {{ emailOtpRef }})</div>
                    <ValidationProvider
                      name="รหัส OTP"
                      :rules="{
                        required: true,
                        min: 6
                      }"
                      v-slot="{ errors, valid }"
                    >
                      <v-text-field
                        v-model="emailOtp"
                        maxlength="6"
                        :success="wrongEmailOtp ? false : valid"
                        :error-messages="wrongEmailOtp ? wrongEmailOtp : errors"
                        style="border-radius: 0px"
                        @input="wrongEmailOtp = ''"
                        v-uppercase
                        placeholder="OTP: XXXXXX"
                      ></v-text-field>
                    </ValidationProvider>
                    <a :style="sendedOtp ? `color: grey` : ''" @click="resendEmailOtp">
                      Resend OTP
                      <div>(ขอส่งใหม่ได้ทุก 60 วินาที)</div>
                    </a>
                    <v-btn
                      @click="verifyEmailOtp"
                      class="mt-9 primary-btn"
                      dark
                      height="45px"
                      width="200px"
                      >ยืนยัน</v-btn
                    >
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>

    <v-dialog v-model="showResentEmailOtpDialog" persistent :max-width="500">
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text class="text-center">
          <v-avatar rounded color="#5ecbf3" :size="64" class="mb-6">
            <v-icon dark large>mdi-mail</v-icon>
          </v-avatar>
          <h2 class="mb-3">Resent verification code</h2>
          <p class="ma-0">
            We have just sent an email with a new verification code to <strong>{{ email }}</strong
            >.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            dark
            large
            :min-width="120"
            class="primary-btn"
            @click="showResentEmailOtpDialog = false"
            >Got it</v-btn
          >
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BirthdayPicker from '@/components/BirthdayPicker.vue'
import Header from '@/components/Header.vue'
import ThaiNationalId from '@/components/ThaiNationalId.vue'
import ThaiPhoneNumber from '@/components/ThaiPhoneNumber.vue'
import axios from 'axios'
import moment from 'moment'
import queryString from 'query-string'
import { mask } from 'vue-the-mask'
import VueElementLoading from 'vue-element-loading'

export default {
  directives: { mask },
  data() {
    return {
      welcomekit: null,
      fullScreenLoading: false,
      pinconfirm: '',
      serverUrl: 'https://api-summit-cap.zwiz.app/',
      step: 1,
      query: '',
      IDloading: false,
      completeloading: false,
      loading: '',
      inputID: {
        idcard: '',
        // contractid: '',
        mobileno: '',
        birthdate: ''
      },
      otp: '',
      otpref: '',
      sendedOtp: false,
      wrongotp: '',
      pinnumber: '',
      statementType: '11',
      statementTypeWelcomeKit: '11',
      email: '',
      emailOtp: '',
      emailOtpRef: '',
      wrongEmailOtp: '',
      showResentEmailOtpDialog: false,
      address: ''
    }
  },
  components: {
    Header,
    ThaiNationalId,
    ThaiPhoneNumber,
    BirthdayPicker,
    VueElementLoading
  },
  computed: {
    computedBirthdate() {
      moment.updateLocale('en', {
        calendar: {
          lastDay: '[Yesterday]',
          sameDay: '[Today]',
          nextDay: '[Tomorrow]',
          lastWeek: '[Last] dddd',
          nextWeek: 'dddd',
          sameElse: 'DD/MM/YYYY BE'
        },
        meridiem: () => ''
      })
      return this.inputID.birthdate
        ? this.toBuddhistYear(moment(this.inputID.birthdate), 'DD/MM/YYYY')
        : ''
    },
    moment() {
      return moment
    },
    token() {
      if (this.query.apptype == 'line') {
        return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      } else {
        return '1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B'
      }
    },
    transid() {
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    },
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    }
  },
  mounted() {
    this.queryinit()
    this.checksession()
    if (this.query.idcard) {
      this.inputID.idcard = this.query.idcard
    }
  },
  methods: {
    toBuddhistYear: (moment, format) => {
      var christianYear = moment.format('YYYY')
      var buddhishYear = (parseInt(christianYear) + 543).toString()
      return moment
        .format(format.replace('YYYY', buddhishYear).replace('YY', buddhishYear.substring(2, 4)))
        .replace(christianYear, buddhishYear)
    },
    resendOtp() {
      if (!this.sendedOtp) {
        this.SendOTP()
        this.sendedOtp = true
        setInterval(() => {
          this.sendedOtp = false
        }, 60000)
      }
    },
    verifyIdMobileContract() {
      this.IDloading = true

      let data = {
        ...this.inputID,
        transid: this.transid,
        token: this.token
      }

      const dobFormatted = this.toBuddhistYear(moment(data.birthdate), 'YYYY-MM-DD 00:00:00.000')
      data.birthdate = dobFormatted

      axios
        .post(this.$store.state.summitServer + 'api/AuthenInfo/verifyidmobiledateofbirth', data)
        .then(async (response) => {
          this.IDloading = false
          if (response.data.status.status == 200) {
            await this.SendOTP()
            this.step = 2
          } else {
            this.$swal.fire({
              icon: 'error',
              html:
                'ข้อมูลที่ท่านลงทะเบียนไม่ตรงกับในระบบของบริษัท<br/>' +
                'กรุณาติดต่อ Call Center ที่เบอร์ 02-107-2222',
              showconfirmButton: true,
              confirmButtonColor: '#5ECBF3'
            })
          }
        })
    },
    async fetchWelcomeKit() {
      const configWelcomeKit = {
        method: 'post',
        baseURL: this.$store.state.summitServer,
        url: 'API/Statement/GetChannelStatement',
        data: {
          token: this.token,
          transid: this.transid,
          lineid: this.userid,
          requestno: '0',
          contractid: '0',
          idcard: this.inputID.idcard,
          doctype: '3'
        }
      }
      try {
        const response = await axios(configWelcomeKit)
        const { message_code, message_name } = response.data
        if (!['200'].includes(message_code)) return null
        return response.data
      } catch (error) {
        console.error(error)
      }
    },
    SendOTP() {
      axios
        .post(
          this.$store.state.summitServer + 'api/AuthenInfo/sendotp',
          { ...this.inputID, transid: this.transid, token: this.token },
          { headers: { AUTHORIZATION: 'Bearer xxx xxx' } }
        )
        .then((response) => {
          this.otpref = response.data.otp.otpRef
        })
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    },
    verifyOtp() {
      var data = {
        otpref: this.otpref,
        otpvalue: this.otp,
        transid: this.transid,
        token: this.token
      }
      axios
        .post(this.$store.state.summitServer + 'api/AuthenInfo/verifyotp', data)
        .then(async (response) => {
          if (response.data.status.status == 200) {
            this.step = 3

            // this.welcomekit = await this.fetchWelcomeKit()
            // if (this.welcomekit.statement_type) {
            //   this.statementTypeWelcomeKit = this.welcomekit.statement_type
            //   this.email = this.welcomekit.emailAddress
            // }
          } else {
            this.wrongotp = 'รหัส OTP ไม่ถูกต้องกรุณาลองใหม่อีกครั้ง'
          }
        })
    },
    savePinLineId() {
      this.step = 4
    },
    async saveSummitPinLineId() {
      try {
        var vm = this
        vm.completeloading = true
        var data = {
          transid: this.transid,
          token: this.token,
          lineid: this.userid,
          pin: this.pinnumber,
          idcard: this.inputID.idcard,
          contractid: '',
          mobileno: this.inputID.mobileno
        }

        return await axios
          .post(this.$store.state.summitServer + 'API/ManagePinInfo/SavePinLineId', data)
          .then(async (response) => {
            vm.completeloading = false
            return response
          })
      } catch (error) {
        throw new Error('summit')
      }
    },
    async saveZwizPinLineId() {
      try {
        let userid
        if (this.query.apptype == 'line') {
          userid = this.userid + '__' + this.query.pageid
        } else {
          userid = this.userid
        }

        const statementType = {
          11: 'line_oa',
          10: 'email',
          12: 'address'
        }[this.statementType]

        return await axios.put(
          this.serverUrl +
            `summitsavepinlineid?userid=${userid}&pageid=${this.query.pageid}&statement_type=${statementType}`,
          { pin: this.pinnumber, idcard: this.inputID.idcard },
          {
            headers: {
              AUTHORIZATION: 'Bearer xxx xxx'
            }
          }
        )
      } catch (error) {
        throw new Error('zwiz')
      }
    },
    async sendEmailOtp() {
      try {
        const payload = {
          token: this.token,
          transid: this.transid,
          lineid: this.userid,
          idcard: this.inputID.idcard,
          mobileno: this.inputID.mobileno,
          emailAddress: '',
          emailaddresswelcomekit: ''
        }

        if (this.statementType === '10') {
          payload.emailAddress = this.email
        }
        if (this.statementTypeWelcomeKit === '10') {
          payload.emailaddresswelcomekit = this.email
        }

        return await axios
          .post(this.$store.state.summitServer + 'API/Statement/SendOTPEmail', payload)
          .then((response) => {
            const { message_code, message_name, otp_ref = '' } = response.data
            if (!['200', '225'].includes(message_code)) throw new Error(message_name)
            this.emailOtpRef = otp_ref
            return response.data
          })
      } catch (error) {
        throw new Error('summit')
      }
    },
    resendEmailOtp() {
      if (!this.sendedOtp) {
        this.sendEmailOtp()
        this.showResentEmailOtpDialog = true
        this.sendedOtp = true
        setInterval(() => {
          this.sendedOtp = false
        }, 60000)
      }
    },
    backTo() {
      if (this.query && this.query.back_to) {
        const { back_to, ...etc } = this.query
        this.$router.push({
          path: '/',
          query: { ...etc, view: this.query.back_to }
        })
        location.reload()
      }
    },
    async confirmStatementChannel() {
      try {
        const vm = this
        const payload = {
          token: vm.token,
          transid: vm.transid,
          lineid: vm.userid,
          idcard: vm.inputID.idcard,
          contractid: '',
          mobileno: vm.inputID.mobileno,
          statementtype: vm.statementType,
          emailaddress: vm.statementType === '10' ? vm.email : '',
          statementtypewelcomekit: vm.statementTypeWelcomeKit,
          emailaddresswelcomekit: vm.statementTypeWelcomeKit === '10' ? vm.email : '',
          lineidwelcomekit: vm.userid,
          doctype: '1',
          existflag: vm?.welcomekit?.exist_flag || '1'
        }

        return await axios
          .post(vm.$store.state.summitServer + 'API/Statement/ConfirmChannelStatement', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (message_code !== '200') throw new Error(message_name)
            if (vm.query && vm.query.back_to) {
              vm.backTo()
            } else {
              return true
            }
          })
      } catch (error) {
        throw new Error('summit')
      }
    },
    showCompleteRegistrationAlert() {
      this.$swal
        .fire({
          icon: 'success',
          text: 'ลงทะเบียนสำเร็จ'
        })
        .then(async () => {
          if (this.query.apptype == 'line') {
            await this.$liff.sendMessages([
              {
                type: 'text',
                text: 'ลงทะเบียนสำเร็จ'
              }
            ])
            this.$liff.closeWindow()
          } else if (this.query.apptype == 'facebook') {
            MessengerExtensions.requestCloseBrowser(
              function success() {},
              function error() {
                window.close()
              }
            )
          }
        })
    },
    async selectStatementChannel() {
      if (this.statementType === '10' || this.statementTypeWelcomeKit === '10') {
        try {
          this.completeloading = true

          await this.sendEmailOtp()

          this.step = 5
        } catch (error) {
          console.error(error)
        } finally {
          this.completeloading = false
        }

        return
      }

      try {
        this.completeloading = true

        await this.saveSummitPinLineId()
        await this.saveZwizPinLineId()
        await this.confirmStatementChannel()

        this.showCompleteRegistrationAlert()
      } catch (error) {
        if (error.message.startsWith('summit')) {
          this.$swal.fire({
            icon: 'error',
            text: 'กรุณาติดต่อเจ้าหน้าที่'
          })
          return
        }
        console.error(error)
      } finally {
        this.completeloading = false
      }
    },
    async verifyEmailOtp() {
      try {
        this.completeloading = true

        const payload = {
          token: this.token,
          transid: this.transid,
          lineid: this.userid,
          idcard: this.inputID.idcard,
          mobileno: this.inputID.mobileno,
          otp_ref: this.emailOtpRef,
          otp_value: this.emailOtp,
          emailAddress: this.email,
          contractid: '',
          doctype: '1'
        }

        await axios
          .post(this.$store.state.summitServer + 'API/Statement/ConfirmOTPEmail', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (!['200'].includes(message_code)) throw new Error(`Invalid OTP: ${message_name}`)
            return true
          })

        await this.saveSummitPinLineId()
        await this.saveZwizPinLineId()
        await this.confirmStatementChannel()

        this.showCompleteRegistrationAlert()
      } catch (error) {
        if (error.message.startsWith('Invalid OTP:')) {
          this.wrongEmailOtp = 'รหัส OTP ไม่ถูกต้องกรุณาลองใหม่อีกครั้ง'
          return
        }
        if (error.message.startsWith('summit')) {
          this.$swal.fire({
            icon: 'error',
            text: 'กรุณาติดต่อเจ้าหน้าที่'
          })
          return
        }
        console.error(error)
      } finally {
        this.completeloading = false
      }
    },
    async checksession() {
      const vm = this
      let userid
      if (this.query.apptype == 'line') {
        userid = this.userid + '__' + this.query.pageid
      } else {
        userid = this.userid
      }
      try {
        this.fullScreenLoading = true
        const response = await axios.get(
          this.serverUrl + `checksessionexpire?userid=${userid}&pageid=${this.query.pageid}`,
          {
            headers: { AUTHORIZATION: 'Bearer xxx xxx' }
          }
        )
        if (response && response.data && response.data.responsecode == 200) {
          // this.$route.push('/', { ...this.query, view: 'changeaddress' })
          this.$router.push({ path: '/', query: { ...this.query, view: 'changeaddress' } })
          location.reload()
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.fullScreenLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.slide-leave-active,
.slide-enter-active {
  transition: 0.6s ease-in-out;
}
.slide-enter {
  transform: translate(150%, 0);
}
.slide-leave-to {
  transform: translate(150%, 0);
}

.img-slider {
  position: relative;
  width: 100%;
}

.img-slider .slide-item {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
</style>
