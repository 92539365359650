<template>
  <v-app>
    <div class="text-center" v-if="loading">
      <v-row justify="center" align="center" style="height: 100vh">
        <v-col class="text-center mt-7">
          <v-progress-circular indeterminate color="blue" size="80"></v-progress-circular>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <component :is="view"></component>
    </div>
  </v-app>
</template>

<script>
import Register from './views/Register.vue'
import queryString from 'query-string'
import Login from './views/Login.vue'
import Prescreen from './views/Prescreen.vue'
import Barcode from './views/Barcode.vue'
import Insurance1 from './views/Insurance1.vue'
import Insurance2 from './views/Insurance2.vue'
import Payment from './views/Payment.vue'
import Changeaddress from './views/ChangeAddress.vue'
import Generalinfo from './views/GeneralInfo.vue'
import Qrcodepayment from './views/QrcodePayment.vue'
import MultipleChangeaddress from './views/MultipleChangeaddress.vue'
import Otp from './views/Otp.vue'

export default {
  name: 'App',

  components: {
    Register,
    Login,
    Prescreen,
    Barcode,
    Insurance1,
    Insurance2,
    Payment,
    Changeaddress,
    ChangeAddress: Changeaddress,
    changeaddress: Changeaddress,
    Generalinfo,
    generalinfo: Generalinfo,
    GeneralInfo: Generalinfo,
    Qrcodepayment,
    MultipleChangeaddress,
    multiplechangeaddress: MultipleChangeaddress,
    Otp,
    otp: Otp
  },

  data() {
    return { loading: true, query: '' }
  },
  mounted() {
    this.queryinit()
    const pageid = this.query.pageid

    if (pageid == '495271297476302') {
      this.$store.state.summitServer = 'https://cbs.summitcapital.co.th/'
    } else {
      this.$store.state.summitServer = 'https://uatcbs.summitcapital.co.th/'
    }

    if (!this.query.view.includes('insurance')) {
      this.init()
    } else {
      this.loading = false
    }
  },
  methods: {
    init() {
      var vm = this
      var liffid
      if (this.query.pageid == '495271297476302') {
        liffid = '1654450849-34xXnJq5' //production
        // liffid = '1654450849-o82z5qPp' // UAT
        // liffid = '1654450849-V5eyvNQD' //localhost
      } else {
        // liffid = '1653955695-AMR39K27' //production
        liffid = '1653955695-k2abxYJ8' //UAT
        // liffid = '1653955695-AZ2vOGRP' //localhost
      }
      if (this.query.apptype == 'line') {
        this.initializeLiff(liffid)
      } else {
        window.extAsyncInit = function () {
          // the Messenger Extensions JS SDK is done loading
          vm.loading = false
        }
      }
    },
    async initializeLiff(myLiffId) {
      var vm = this
      var redirectUri = window.location.href
      try {
        await this.$liff.init({
          liffId: myLiffId
        })
        if (await this.$liff.isLoggedIn()) {
          vm.$store.state.profile = await this.$liff.getProfile()
          vm.loading = false
        } else {
          await this.$liff.login({ redirectUri })
        }
      } catch (error) {
        // alert(error.message);
      }
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    }
  },
  computed: {
    view() {
      return this.query.view
    }
  }
}
</script>

<style>
.custom-font {
  font-family: 'Manrope', 'Kanit', sans-serif !important;
  letter-spacing: 0 !important;
}
.bgdiv {
  background-color: #0d4ca0;
  width: 100%;
}
.mybtn {
  /* background-image: linear-gradient(160deg, #00bbf3 0%, #0d4ca0 74%); */
  background-color: #0d4ca0 !important;
  margin-bottom: 50px;
  color: white !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.header {
  min-height: 200px;
  background: linear-gradient(
    45deg,
    #56ccf2 1.69%,
    rgba(47, 128, 237, 0) 173.95%,
    rgba(47, 128, 237, 0) 173.95%
  );
}
.wrapper {
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.header-text {
  color: #044ea2;
  font-family: 'Manrope', 'Kanit', sans-serif !important;
  text-shadow: 2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff,
    1px -1px #fff, -1px 1px #fff;
  filter: drop-shadow(0px 10px 4px rgba(0, 0, 0, 0.25));
  padding-left: 16px;
  word-break: break-word;
  letter-spacing: 0 !important;
  font-size: 30px;
  line-height: 38px;
}
.content {
  padding: 24px 16px;
  background: #fff;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
  position: absolute;
  top: -24px;
  width: 100%;
}
.primary-title {
  color: #009aef;
  font-family: 'Manrope', 'Kanit', sans-serif !important;
  font-size: 20px;
}
.primary-btn {
  background: linear-gradient(0deg, #5ecbf3, #5ecbf3);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Manrope', 'Kanit', sans-serif !important;
  color: #fff;
}
</style>
