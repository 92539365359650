<template>
  <div>
    <v-container>
      <v-card>
        <v-card-text>
          <div
            style="font-weight: bold; text-decoration: underline;margin-bottom: 10px"
          >ข้อยกเว้น กรมธรรม์ประกันภัยไม่คุ้มครอง</div>

          <p
            style="text-indent: 2em;"
          >1. การชำรุดเสียหายหรือการขัดข้องของระบบกลไกหรือระบบไฟฟ้า ของเครื่องจักรหรืออุปกรณ์ของทรัพย์สินที่เอาประกันภัย</p>
          <p
            style="text-indent: 2em;"
          >2. การสูญหายโดยไม่ทราบสาเหตุ หรือการขาดหายซึ่งพบเมื่อตรวจสอบจำนวนทรัพย์สิน การเก็บข้อมูลผิดพลาด การขาดหายในการรับหรือส่งมอบวัสดุไม่ครบจำนวนหรือเนื่องจากความผิดพลาดในการจดบันทึกทางบัญชี</p>
          <p
            style="text-indent: 2em;"
          >3. ความเสียหายต่อเนื่องใด ๆ ทุกชนิด เช่นการขาดประโยชน์จากการใช้งาน ค่าใช้จ่ายต่าง ๆ ที่เกิดขึ้นตามมา</p>
          <p
            style="text-indent: 2em;"
          >4. การกระทำอันมีลักษณะฉ้อโกงหรือไม่ซื่อสัตย์ของบุคคลภายในครอบครัวของผู้เอาประกันภัย</p>
          <p
            style="text-indent: 2em;"
          >5. การจงใจกระทำละเมิดของผู้เอาประกันภัยหรือมีส่วนรู้เห็น การจงใจกระทำละเมิดของลูกจ้างของผู้เอาประกันภัย เพื่อทำให้ยานพานะที่ใช้ในการขนส่ง หรือทรัพย์สินที่เอาประกันภัยไว้ ถูกทำลายหรือเสียหาย</p>
          <p style="text-indent: 2em;">6. การใช้รถจักรยานยนต์นอกอาณาเขตคุ้มครอง</p>
          <p
            style="text-indent: 2em;"
          >7. การใช้รถจักรยานยนต์ไปในทางที่ผิดกฎหมาย เช่น ใช้รถจักรยานยนต์ไปปล้นทรัพย์ ชิงทรัพย์หรือใช้ขนยาเสพติด เป็นต้น</p>
          <p
            style="text-indent: 2em;"
          >8. ขณะที่ผู้เช่าซื้อได้เข้าร่วมหรือมีส่วนร่วมในการแข่งขันรถจักรยานยนต์ทุกชนิด</p>
          <p
            style="text-indent: 2em;"
          >9. การกระทำของผู้เช่าซื้อ ขณะอยู่ภายใต้ฤทธิ์สุรา สารเสพติด หรือยาเสพติดให้โทษร้ายแรงจนไม่สามารถครองสติได้ คำว่า "ขณะอยู่ภายใต้ฤทธิ์สุรา" นั้น ในกรณีที่มีการตรวจเลือด ให้ถือเกณฑ์มีระดับแอลกอฮอล์ในเลือดตั้งแต่ 150 มิลลิกรัมเปอร์เซ็นต์ขึ้นไป</p>
          <p
            style="text-indent: 2em;"
          >10. ค่าซ่อมแซม ค่าเปลี่ยนอะไหล่รถจักรยานยนต์ที่เสียหายในทุกกรณี</p>
          <p style="text-indent: 2em;">11. ความสูญเสียหรือความเสียหายใด ๆ ที่เกิดขึ้นต่อบุคคลภายนอก</p>
          <p style="text-indent: 2em;">12. ความเสียหายหรือความสูญเสียใด ๆ อันมีสาเหตุมาจาก</p>
          <p
            style="text-indent: 2em;"
          >12.1 การเสื่อมสภาพหรือการเปลี่ยนสภาพโดยธรรมชาติ ของทรัพย์สินที่เอาประกันภัยนั้น</p>
          <p
            style="text-indent: 2em;"
          >12.2 การรั่วไหลหรือการสึกหรอตามปกติ การที่น้ำหนักหรือปริมาณสูญหายตามธรรมชาติของทรัพย์สินที่เอาประกันภัย</p>
          <p
            style="text-indent: 2em;"
          >13. ความเสียหายหรือความสูญเสียใด ๆ อันมีสาเหตุมาจากหรือสืบเนื่องจากสงคราม การรุกราน การกระทำที่มุ่งร้ายของศัตรูต่างชาติหรือการกระทำที่มุ่งร้ายคล้ายสงคราม(ไม่ว่าจะได้มีการประกาศหรือไม่ก็ตาม) หรือสงครามกลางเมือง การกำเริบ การก่อความไม่สงบของประชาชนถึงขนาดลุกฮือต่อต้านรัฐบาลการแข็งเมือง(แข็งข้อ) การกบฎ การจลาจล การนัดหยุดงาน การยึดอำนาจการก่อความวุ่นวาย การปฏิวัติ การประกาศกฎอัยการศึก หรือเหตุการณ์ใด ๆ ซึ่งจะเป็นเหตุให้มีการประกาศหรือคงไว้ซึ่งกฏอัยการศึก</p>
          <p
            style="text-indent: 2em;"
          >14. ความเสียหายหรือความสูญเสียใดๆ อันมีสาเหตุมาจากหรือสืบเนื่องมาจากการริบ ยึด เกณฑ์ทำลาย หรือทำความเสียหายแก่ทรัพย์สินใด โดยคำสั่งของรัฐบาล โดยนิตินัย หรือโดยพฤตินัย หรือของเจ้าหน้าที่ราชการเทศบาล หรือเจ้าหน้าที่ประจำท้องถิ่น ในราชอาณาจักรหรือในเขตที่ทรัพย์สินนั้นตั้งอยู่</p>
          <p
            style="text-indent: 2em;"
          >15. ความเสียหายหรือความสูญเสียใดๆ อันมีสาเหตุมาจากหรือสืบเนื่องมาจากอาวุธนิวเคลียร์ การแผ่รังสี หรือการแพร่กัมมันตภาพรังสีจากเชื้อเพลิงนิวเคลียร์ หรือจากการนิวเคลียร์ใด ๆ อันเนื่องมาจากการเผาไหม้ของเชื้อเพลิงนิวเคลียร์ และจากกรรมวิธีใด ๆ แห่งการแตกแยกตัวทางนิวเคลียร์ ซึ่งดำเนินติดต่อกันไปด้วยตัวเอง</p>
          <p>***ความคุ้มครองและเงื่อนไขอื่นๆ ที่ละเอียดครบถ้วนให้เป็นไปตามกรมธรรม์ประกันภัย ความเสียหายทางการเงินสำหรับสินค้าเช่าซื้อประเภทรถจักรยานยนต์ที่ได้รับความเห็นชอบจากสำนักงานคณะกรรมการกำกับและส่งเสริมการประกอบธุรกิจประกันภัย (คปภ.)</p>
          <p style="color:red">** รายละเอียดความคุ้มครองเป็นไปตามที่ บ.ประกันภัยกำหนด</p>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
</style>