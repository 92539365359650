import axios from 'axios'

const instance = () => {
  return axios.create({
    baseURL: process.env.VUE_APP_SUMMIT_SERVER_URL,
    timeout: 15000
  })
}

const addressApi = {
  getAddress: (request) => {
    const { body, config = {} } = request
    return instance().post(`api/CustomerInfo/GetCustomerAddress`, body, {
      ...config
    })
  }
}

export default addressApi
