<template>
  <div>
    <Header :text="title" :headerImg="headerImg"></Header>
    <div style="position: relative">
      <div class="content">
        <div class="wrapper pt-6">
          <h6 class="primary-title">
            <span>โปรดเลือกช่องทางการชำระเงิน </span>
          </h6>
          <div class="pt-4 custom-font">
            <template v-if="showTab">
              <v-tabs v-model="tab" background-color="transparent" color="primary" grow>
                <v-tab v-for="item in items" :key="item.title">
                  <v-icon>{{ item.icon }}</v-icon>
                  <span class="pl-2">{{ item.title }}</span>
                </v-tab>
              </v-tabs>

              <v-tabs-items v-model="tab">
                <v-tab-item v-for="item in items" :key="item.title">
                  <v-card flat style="min-height: 250px">
                    <div v-if="loading" class="text-center pt-5">
                      <v-progress-circular indeterminate size="24"></v-progress-circular>
                    </div>
                    <div v-else style="font-size: 14px; line-height: 1.5rem">
                      <GenerateBarcodeWithInfo
                        v-if="textScan"
                        :type="item.id"
                        :info="textScan"
                        :title="title"
                      />
                    </div>
                  </v-card>
                </v-tab-item>
              </v-tabs-items>
            </template>
            <template v-else>
              <GenerateBarcodeWithInfo
                v-if="textScan"
                :type="'barcode'"
                :info="textScan"
                :title="title"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import axios from 'axios'
import queryString from 'query-string'
import Header from '@/components/Header.vue'
import ThaiNationalId from '@/components/ThaiNationalId.vue'
import ThaiPhoneNumber from '@/components/ThaiPhoneNumber.vue'
import BirthdayPicker from '@/components/BirthdayPicker.vue'
import { mask } from 'vue-the-mask'
import VueBarcode from 'vue-barcode'
import GenerateBarcodeWithInfo from '@/components/GenerateBarcodeWithInfo.vue'

export default {
  directives: { mask },
  data() {
    return {
      tab: 0,
      items: [
        {
          id: 'barcode',
          icon: 'mdi-barcode-scan',
          title: 'Barcode'
        },
        {
          id: 'qrcode',
          icon: 'mdi-qrcode-scan',
          title: 'QR Code'
        }
      ],
      textScan: '',
      loading: false
    }
  },
  components: {
    Header,
    ThaiNationalId,
    ThaiPhoneNumber,
    BirthdayPicker,
    barcode: VueBarcode,
    GenerateBarcodeWithInfo
  },
  computed: {
    headerImg() {
      if (this.title && this.title == 'ค่าประกัน') {
        return 11
      } else if (this.title && this.title == 'ค่างานทะเบียน') {
        return 12
      }
      return 8
    },
    serverUrl() {
      return this.$store.state.summitServer
    },
    barcodeData() {
      let dataCode = ''
      if (this.textScan) {
        dataCode =
          this.tab === 0
            ? this.textScan.datatext
            : `data:image/png;base64, ${this.textScan.datacode}`
      }
      return dataCode
    },
    moment() {
      return moment
    },
    token() {
      // if (this.query.apptype == 'line') {
      return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      // } else {
      //   return '1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B'
      // }
      // return '1687C3B7-AEFF-45F7-97ED-4DF112E3AAF5' //production
    },
    transid() {
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    },
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    },
    title() {
      let tempTitle = ''
      if (this.$route.query && this.$route.query.typescan_name) {
        tempTitle = decodeURI(this.$route.query.typescan_name)
      }

      return tempTitle
    },
    showTab() {
      // return this.$route.query.typescan && this.$route.query.typescan === '01' ? true : false
      return true
    }
  },
  mounted() {
    this.queryinit()

    this.handleGetTextScan()
  },
  methods: {
    queryinit() {
      const vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    },
    async handleGetTextScan() {
      const vm = this
      try {
        const typeScan = vm.query && vm.query.typescan
        const contractNo = vm.query && vm.query.contractno
        const data = {
          token: vm.token,
          type: vm.tab === 0 ? 'barcode' : 'qrcode',
          typescan: typeScan,
          contract_no: contractNo,
          transid: vm.transid
          // transid: '999',
          // contract_no: '0502100108660'
        }
        const url = `${vm.serverUrl}api/CustomerInfo/GetTextScan`

        vm.loading = true
        const response = await axios.post(url, data)
        vm.loading = false

        if (response && response.status === 200) {
          vm.textScan = response.data.textscan[0]
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  watch: {
    tab: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.handleGetTextScan()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
