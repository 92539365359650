<template>
  <ValidationObserver v-slot="{ handleSubmit }" tag="div">
    <form @submit.prevent="handleSubmit(submit)">
      <ValidationProvider
        v-if="mode === 'email'"
        v-slot="{ errors }"
        name="E-mail"
        :rules="{ required: true, email: true }"
        slim
      >
        <v-text-field v-model="email" label="กรอก e-mail*" :error-messages="errors" class="mb-2" />
      </ValidationProvider>
      <v-radio-group v-else v-model="type" hide-details :disabled="exist_flag == 1">
        <v-radio value="11" label="LINE OA Summit Capital" />
        <v-radio value="10" label="E-mail" />
        <ValidationProvider
          v-if="type === '10'"
          v-slot="{ errors }"
          name="E-mail"
          :rules="{ required: type === '10', email: true }"
          slim
        >
          <v-text-field
            v-model="email"
            label="กรอก e-mail*"
            :error-messages="errors"
            class="mb-2"
          />
        </ValidationProvider>
        <v-radio value="12" label="ไปรษณีย์ ที่อยู่จัดส่งเอกสาร" />
        <template v-if="type === '12'">
          <v-textarea v-model="address" readonly no-resize auto-grow hide-details :rows="1" />
          <p class="body-2 my-4 text-center" :style="{ fontFamily: 'inherit !important' }">
            หากต้องการแก้ไขที่อยู่ในการจัดส่งเอกสาร<br />กรุณาคลิก
            <v-btn small text color="primary" @click="$emit('edit:address')">แก้ไขที่อยู่</v-btn>
          </p>
          <p class="body-2 my-4 red--text" :style="{ fontFamily: 'inherit !important' }">
            หมายเหตุ: การจัดส่งไปรษณีย์ มีระยะเวลาในการจัดส่ง 10-15 วันทำการ
          </p>
        </template>
      </v-radio-group>

      <div class="mt-8">
        <v-btn
          v-if="!(exist_flag == 1)"
          :loading="isLoading"
          block
          dark
          :disabled="!type"
          class="my-4 primary-btn mx-auto"
          type="submit"
          >{{ submitButtonText }}</v-btn
        >
        <p v-else style="color: red; font-size: 14px">
          หมายเหตุ : การจัดส่งไปรษณีย์ มีระยะเวลาในการจัดส่ง 10-15 วันทำการ
          หรือถ้าท่านต้องการเปลี่ยนช่องทางการรับเอกสารให้ติดต่อ Call Center 0-2107-2222
        </p>
        <v-btn
          v-if="$listeners.cancel"
          block
          outlined
          color="grey"
          :disabled="isLoading"
          class="my-4 mx-auto"
          @click="$emit('cancel')"
          >ยกเลิก</v-btn
        >
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import axios from 'axios'
import { sendErrorLog } from '@/utils/sendErrorLog'

export default {
  props: {
    mode: String,
    token: String,
    transactionId: String,
    lineId: String,
    requestNumber: String,
    contractId: String,
    idCardNumber: String,
    mobileNumber: String
  },
  data() {
    return {
      type: this.mode === 'email' ? '10' : undefined,
      email: '',
      oldEmail: '',
      address: '',
      isLoading: false,
      exist_flag: 0
    }
  },
  computed: {
    isSameEmail() {
      return this.email === this.oldEmail
    },
    isEmptyEmail() {
      return !this.email
    },
    submitButtonText() {
      if (this.type === '10' && (this.isEmptyEmail || !this.isSameEmail)) return 'ถัดไป'
      return 'ยืนยัน'
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    showErrorAlert({ traceId }) {
      this.$swal.fire({
        icon: 'error',
        html: `กรุณาติดต่อเจ้าหน้าที่<br /><small>(Trace ID: ${traceId})</small>`
      })
    },
    async fetchData() {
      const payload = {
        token: this.token,
        transid: this.transactionId,
        lineid: this.lineId,
        requestno: this.requestNumber,
        contractid: this.contractId,
        idcard: this.idCardNumber,
        doctype: '3'
      }

      try {
        const { statement_type, emailAddress, customer_address, exist_flag } = await axios
          .post(this.$store.state.summitServer + 'API/Statement/GetChannelStatement', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (message_code !== '200') throw new Error(message_name)
            return response.data
          })
        if (this.mode !== 'email') {
          this.type = statement_type
        }
        this.exist_flag = exist_flag
        this.email = emailAddress
        this.oldEmail = emailAddress
        this.address = customer_address
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'GetChannelStatement',
          error,
          payload
        })
        this.showErrorAlert({ traceId })
      }
    },
    async confirmStatementChannel() {
      const payload = {
        token: this.token,
        transid: this.transactionId,
        lineid: '',
        idcard: this.idCardNumber,
        contractid: this.contractId,
        mobileno: this.mobileNumber,
        statementtypewelcomekit: this.type,
        statementtype: '',
        emailaddress: '',
        doctype: '3',
        lineidwelcomekit: this.lineId,
        emailaddresswelcomekit: this.type === '10' ? this.email : '',
        existflag: '1'
      }

      try {
        return await axios
          .post(this.$store.state.summitServer + 'API/Statement/ConfirmChannelStatement', payload)
          .then((response) => {
            const { message_code, message_name } = response.data
            if (message_code !== '200') throw new Error(message_name)
            return true
          })
      } catch (error) {
        const traceId = Date.now()
        sendErrorLog({
          trace_id: traceId,
          name: 'ConfirmChannelStatement',
          error,
          payload
        })
        this.showErrorAlert({ traceId })
      }
    },
    async submit() {
      try {
        if (this.submitButtonText === 'ถัดไป') {
          this.$emit('verify:email', this.email)
          return
        }

        this.isLoading = true

        await this.confirmStatementChannel()
        this.$emit('complete')
      } catch (error) {
        this.showErrorAlert()
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
