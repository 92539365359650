import numeral from 'numeral'
import { extend, localize } from 'vee-validate'
import th from 'vee-validate/dist/locale/th'
import * as rules from 'vee-validate/dist/rules'

// loop over all rules
for (let rule in rules) {
  extend(rule, rules[rule])
}

localize({
  th: {
    messages: th.messages,
    names: {
      amount: 'Amount'
    },
    fields: {
      amount: {
        min_value: (field, param) =>
          `จำนวนที่ชำระต้องไม่ต่ำกว่าค่างวด ${numeral(param.min).format('0,0')} บาท`
      }
    }
  }
})

extend('minvalue_monthlyincome', {
  validate: (value) => {
    var income = 0
    if (value) {
      income = parseFloat(value)
    }
    if (income <= 30000) {
      return false
    } else {
      return true
    }
  },
  message: 'รายได้ขั้นต่ำ ต้องไม่น้อยกว่า 30,000 บาท'
})

extend('thai_idcard', {
  message: 'รูปแบบเลขบัตรประชาชนไม่ถูกต้อง',
  validate: (value) => {
    let result = ThaiNationalID(value)
    return result
  }
})
extend('thai_phonenumber', {
  message: 'รูปแบบเบอร์โทรศัพท์ไม่ถูกต้อง',
  validate: (value) => {
    var pattern = new RegExp(/^[0][3-9][0-9]{4}[0-9]{4}$/) // fragment locator
    return pattern.test(value)
  }
})

function ThaiNationalID(id) {
  if (id == null || id.length !== 13 || !/^[0-9]\d+$/.test(id)) {
    return false
  }
  let i
  let sum = 0
  for (i = 0, sum = 0; i < 12; i++) {
    sum += parseInt(id.charAt(i)) * (13 - i)
  }
  const check = (11 - (sum % 11)) % 10
  if (check === parseInt(id.charAt(12))) {
    return true
  }
  return false
}

localize('th')
