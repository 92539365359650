<template>
  <div v-if="!isCheckLogin">
    <Header :text="'นัดชำระเงิน'"></Header>
    <div style="position: relative">
      <div class="content">
        <div class="wrapper pt-6">
          <h6 class="primary-title">
            <span>Promise to pay</span>
          </h6>
          <div class="pt-4 custom-font">
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(summitP2P)">
                <div>
                  <!-- <h1 class="font-weight-black mb-9">Enter PIN Number</h1> -->
                  <!-- <label>Contract ID</label> -->
                  <ValidationProvider
                    name="Contract ID"
                    :rules="{
                      required: true
                    }"
                    v-slot="{ errors }"
                  >
                    <v-select
                      @change="contractidChange"
                      v-model="input.contractid"
                      :error-messages="errors"
                      :items="optionsContract"
                      label="Contract ID"
                    ></v-select>
                  </ValidationProvider>
                  <div v-if="input.contractid">
                    <div class="pt-3">
                      <!-- <label>Date</label> -->
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <ValidationProvider
                            mode="passive"
                            name="Date"
                            :rules="{
                              required: true
                            }"
                            v-slot="{ errors }"
                          >
                            <v-text-field
                              :error-messages="errors"
                              :value="input.date | dateFormat('DD/MM/YYYY')"
                              :disabled="!input.contractid || !overdueamount"
                              label="dd/mm/yyyy"
                              clearable
                              clear-icon="mdi-close-circle"
                              @click:clear="input.date = ''"
                              readonly
                              v-on="on"
                            ></v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker
                          :allowed-dates="alloweddates"
                          v-model="input.date"
                          :disabled="!overdueamount"
                          no-title
                          scrollable
                        ></v-date-picker>
                      </v-menu>
                    </div>
                    <div class="pt-3">
                      <!-- <label>Amount</label> -->
                      <ValidationProvider
                        mode="passive"
                        name="amount"
                        :rules="{
                          required: true,
                          min_value: p2pData ? p2pData.installment : 99999
                        }"
                        v-slot="{ errors }"
                      >
                        <v-text-field
                          v-model="input.amount"
                          name="amount"
                          type="number"
                          step="0.01"
                          :error-messages="errors"
                          :disabled="!input.contractid"
                          label="Amount"
                          clearable
                          clear-icon="mdi-close-circle"
                          @click:clear="input.amount = ''"
                        ></v-text-field>
                      </ValidationProvider>
                    </div>

                    <div class="text-center mt-4">
                      <v-btn
                        type="submit"
                        width="200px"
                        :disabled="!overdueamount"
                        :loading="loading"
                        height="45px"
                        dark
                        class="primary-btn"
                        >PROCEED</v-btn
                      >
                    </div>
                  </div>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center" v-else>
    <v-row justify="center" align="center" style="height: 100vh">
      <v-col class="text-center mt-7">
        <v-progress-circular indeterminate color="blue" size="80"></v-progress-circular>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import queryString from 'query-string'

export default {
  components: {
    Header
  },
  data() {
    return {
      query: '',
      pageid: '',
      serverUrl: 'https://api-summit-cap.zwiz.app/',
      menu: false,
      loading: false,
      overdueamount: '',
      userstatus: '',
      input: {
        contractid: '',
        amount: '',
        date: ''
      },
      isRegister: false,
      isCheckLogin: true,
      p2pData: null

      //   optionsContract: []
    }
  },
  computed: {
    token() {
      if (this.query.apptype == 'line') {
        return '830BE6D6-C4DA-410A-93EC-6634F5AE0457'
      } else {
        return '1AB1187F-5699-4E90-A6D7-1CEBDCA13E2B'
      }
    },
    userid() {
      if (this.query.apptype == 'line') {
        return this.$store.state.profile.userId
      } else {
        return this.query.userid
      }
    },
    userId() {
      let tempUserId = this.query.userid
      return tempUserId
    },
    producttype() {
      return this.userstatus.length
        ? this.userstatus.find((el) => this.input.contractid == el.contractno).producttype
        : []
    },
    optionsContract() {
      return this.userstatus.length ? this.userstatus.map((el) => el.contractno) : []
    },
    transid() {
      var d = new Date()
      var day = d.getDate() + ''
      var month = d.getMonth() + 1 + ''
      var year = d.getFullYear() + ''
      var hour = d.getHours() + ''
      var minute = d.getMinutes() + ''
      var second = d.getSeconds() + ''
      var mills = d.getMilliseconds() + ''
      var defaultFill = '00'

      mills = (defaultFill + mills).substr(mills.length)

      if (day.length < 2) day = '0' + day

      if (month.length < 2) month = '0' + month

      if (hour.length < 2) hour = '0' + hour

      if (minute.length < 2) minute = '0' + minute

      if (second.length < 2) second = '0' + second

      return year + month + day + hour + minute + second + mills
    },
    selectedContract() {
      return this.userstatus.length
        ? this.userstatus.find((el) => this.input.contractid == el.contractno)
        : null
    },
    summitServer() {
      return this.$store.state.summitServer
    }
  },
  async mounted() {
    this.loading = true
    this.queryinit()
    await this.fetchUser()
    this.checksession()
    await this.checkUserStatus()
    this.loading = false
  },
  methods: {
    async fetchUser() {
      try {
        const { status, data } = await axios.get(
          this.serverUrl + 'summit-capital/liff/getuseronly',
          {
            params: { page_id: this.pageid, user_id: this.userId },
            headers: { AUTHORIZATION: 'Bearer VlRrNU1qWmtNek0zTVdFellXSTBNVEl4Wm1ZNVlUazVOakZt=' }
          }
        )

        if (status === 200) {
          if (Array.isArray(data.data)) {
            console.error('ไม่พบผู้ใช้งาน')
            return
          }

          if (data.data.sessionvalue) {
            this.isRegister = true
          }
        }
      } catch (error) {
        console.error(error)
      }
    },
    checksession() {
      const vm = this
      let userid
      if (this.query.apptype == 'line') {
        userid = this.userid + '__' + this.pageid
      } else {
        userid = this.userid
      }
      axios
        .get(this.serverUrl + `checksessionexpire?userid=${userid}&pageid=${this.pageid}`, {
          headers: { AUTHORIZATION: 'Bearer xxx xxx' }
        })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.responsecode &&
            response.data.responsecode == 400
          ) {
            if (this.isRegister) {
              vm.$router.push({
                path: '/',
                query: { ...this.query, view: 'login', back_to: 'MultipleChangeaddress' }
              })
            } else {
              vm.$router.push({
                path: '/',
                query: { ...this.query, view: 'register' }
              })
            }
            location.reload()
          } else {
            setTimeout(() => {
              vm.isCheckLogin = false
            }, 3000)
          }
        })
    },
    contractidChange() {
      this.input.date = moment().format('YYYY-MM-DD')
      this.handleGetP2P()
    },
    async handleGetP2P() {
      const vm = this
      try {
        const data = {
          token: vm.token,
          request_no: vm.selectedContract && vm.selectedContract.request_no,
          transid: vm.transid,
          date_pay: vm.input.date,
          contract_no: vm.selectedContract && vm.selectedContract.contractno
        }
        const url = `${vm.summitServer}API/CustomerInfo/GetP2P`
        const response = await axios.post(url, data)

        if (response && response.status === 200 && response.data.status.status === '200') {
          const tempData = response.data.flags
          vm.input.amount = tempData.overdueamount
          vm.p2pData = tempData
          vm.overdueamount = JSON.parse(JSON.stringify(parseFloat(tempData.overdueamount)))
        } else {
          vm.overdueamount = false
          vm.input.amount = ''
          vm.input.date = ''
          vm.$swal.fire({ title: 'คุณไม่มียอดค้างชำระ' })
        }
      } catch (error) {
        console.error(error)
      }
    },
    alloweddates(val) {
      const end = new Date(this.p2pData.p2P_day)
      const diffDay = moment.duration(moment(end).diff(moment(), 'days', true)) + 1

      return (
        moment(val).diff(moment(), 'days') >= 0 &&
        moment(val).diff(moment().add(diffDay, 'days'), 'days') < 0 &&
        moment(val).isSame(new Date(), 'month')
      )
    },
    queryinit() {
      var vm = this
      vm.query = vm.$route.query['liff.state']
        ? queryString.parse(vm.$route.query['liff.state'])
        : vm.$route.query

      if (vm.query.pageid) vm.pageid = vm.query.pageid
    },
    async checkUserStatus() {
      try {
        const vm = this
        const data = {
          token: vm.token,
          lineid: vm.userid,
          transid: vm.transid
        }

        const url = `${vm.summitServer}API/CustomerInfo/GetAppContract`
        const response = await axios.post(url, data)

        if (response.data.status.status == 200) {
          vm.userstatus = response.data.contracts
        } else {
          vm.$swal.fire({
            icon: 'error',
            text: response.data.status.status_desc || 'Something went wrong'
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    summitP2P() {
      const vm = this
      try {
        vm.loading = true
        const data = {
          token: vm.token,
          contractid: vm.input.contractid,
          producttype: vm.selectedContract && vm.selectedContract.product_type,
          amount: vm.input.amount,
          date: vm.input.date,
          request_no: vm.selectedContract && vm.selectedContract.request_no,
          lineid: vm.userid,
          transid: vm.transid
        }

        if (!vm.overdueamount) {
          vm.$swal.fire({
            text: 'ท่านไม่มียอดค้างชำระ'
          })
        } else {
          const url = `${vm.summitServer}API/CustomerInfo/SubmitP2P`
          axios.post(url, data).then((response) => {
            vm.loading = false
            if (response.data.status.status == 200) {
              // vm.userstatus = response.data.flags;
              this.$swal.fire('Success').then(async () => {
                if (vm.query.apptype == 'line') {
                  await vm.$liff.sendMessages([
                    {
                      type: 'text',
                      text: `กำหนดวันนัดชำระเงินของท่านตามสัญญา ${
                        this.input.contractid
                      } \nจำนวน ${numeral(this.input.amount).format('0,0.00')} \nวันที่ ${moment(
                        vm.input.date
                      ).format('DD/MM/YYYY')}`
                    }
                  ])
                  vm.$liff.closeWindow()
                } else if (vm.query.apptype == 'facebook') {
                  MessengerExtensions.requestCloseBrowser(
                    function success() {},
                    function error() {
                      window.close()
                    }
                  )
                }
              })
            } else {
              vm.$swal.fire(response.data.status.status_desc)
            }
          })
        }
      } catch (error) {
        console.error(error)
      }
    }
  },
  filters: {
    dateFormat(e, f) {
      if (e) {
        return moment(e).format(f)
      } else return ''
    }
  }
}
</script>

<style lang="scss" scoped></style>
