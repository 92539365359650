<template>
  <div>
    <v-text-field
      v-mask="'#-####-#####-##-#'"
      :masked="false"
      :value="value"
      type="tel"
      :clearable="hasClearable ? true : false"
      clear-icon="mdi-close-circle"
      @click:clear="$emit('input', null)"
      :success="valid"
      :hide-details="errors.length ? false : true"
      :error-messages="errors"
      :prepend-inner-icon="showIcon ? 'mdi-card-text' : ''"
      :placeholder="placeholder ? placeholder : 'x-xxxx-xxxxx-xx-x'"
      :label="label ? label : ''"
      @input="onInput"
    ></v-text-field>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
export default {
  props: ['value', 'errors', 'placeholder', 'showIcon', 'valid', 'label', 'hasClearable'],
  directives: { mask },
  data() {
    return {}
  },
  methods: {
    onInput(event) {
      if (event) {
        let id_card = event.replace(/-/g, '')
        this.$emit('input', id_card)
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
