<template>
  <div>
    <div style="font-size: 14px; line-height: 1.5rem">
      <div class="pt-6" style="display: flex; justify-content: center">
        <div style="max-width: 350px; width: 100%" class="text-right">Line Chat Bot</div>
      </div>
      <template v-if="type === 'barcode'">
        <div class="text-center" style="padding-top: 24px" v-if="barcodeData">
          <barcode
            :text="barcodeData"
            height="60"
            width="1"
            v-bind:value="barcodeData"
            tag="svg"
            :displayValue="false"
            :options="{ format: 'CODE39' }"
          ></barcode>
          <div class="caption">{{ barcodeData }}</div>
        </div>
      </template>
      <template v-else-if="type === 'qrcode'">
        <div class="text-center" style="padding-top: 12px">
          <img :src="barcodeData" />
        </div>
      </template>
      <div style="display: flex; justify-content: center; padding-top: 24px">
        <div style="max-width: 350px">
          <v-layout wrap>
            <v-flex xs4 class="text-right font-weight-bold">ชำระ :</v-flex>
            <v-flex xs8 class="pl-2">
              {{ info.typescan }}
            </v-flex>
            <v-flex xs4 class="text-right font-weight-bold">สินเชื่อ :</v-flex>
            <v-flex xs8 class="pl-2">{{ info.producttype }}</v-flex>
            <v-flex xs4 class="text-right font-weight-bold">เลขที่สัญญา :</v-flex>
            <v-flex xs8 class="pl-2">{{ info.contract_no }}</v-flex>
            <v-flex xs4 class="text-right font-weight-bold">ชื่อลูกค้า :</v-flex>
            <v-flex xs8 class="pl-2">{{ info.customername }}</v-flex>
            <v-flex xs4 class="text-right font-weight-bold">ทะเบียนรถ :</v-flex>
            <v-flex xs8 class="pl-2">{{ info.license_no }}</v-flex>
            <v-flex xs4 class="text-right font-weight-bold">ยอดชำระปัจจุบัน</v-flex>
            <v-flex v-if="!['ค่าประกัน', 'ค่างานทะเบียน'].includes(title)" xs8 class="pl-2">{{
              info.installmentamount
            }}</v-flex>
            <v-flex v-else xs8 class="pl-2">โปรดติดต่อเจ้าหน้าที่ 02-107-2222</v-flex>
            <v-flex
              v-if="!['ค่าประกัน', 'ค่างานทะเบียน'].includes(title)"
              xs12
              class="pl-2"
              style="color: red; font-size: 12px; text-align: center"
              >ยอดชำระนี้ใช้ชำระ ณ วันที่ ({{ moment().format('DD/MM/YYYY') }}) เท่านั้น</v-flex
            >
          </v-layout>
        </div>
      </div>

      <div style="display: flex; justify-content: center; padding-top: 24px">
        <div style="max-width: 350px">
          <div class="pb-1 font-weight-bold">วิธีการชำระเงิน :</div>
          <ol>
            <li>
              {{ type === 'barcode' ? 'Barcode' : 'QR Code' }}
              นี้ใช้สำหรับชำระค่าบริการของทาง Summit Capital เท่านั้น
            </li>
            <li>
              {{ type === 'barcode' ? 'Barcode' : 'QR Code' }}
              นี้สามารถชำระเงินผ่านทาง Mobile application ได้ทุกธนาคาร
            </li>
            <li>กรุณาเก็บหลักฐานการชำระเงินทุกครั้ง</li>
          </ol>
          <div class="pt-2">
            ** เอกสารฉบับนี้ "ไม่สามารถทำรายการด้วยเงินสด ใช้สำหรับชำระเงินผ่าน Mobile Application
            เท่านั้น" **
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueBarcode from 'vue-barcode'
import moment from 'moment'

export default {
  props: ['type', 'info', 'title'],
  components: {
    barcode: VueBarcode
  },
  computed: {
    barcodeData() {
      let dataCode = ''
      if (this.info) {
        dataCode =
          this.type === 'barcode'
            ? this.info.datatext
            : `data:image/png;base64, ${this.info.datacode}`
      }
      return dataCode
    },
    moment() {
      return moment
    }
  }
}
</script>

<style lang="scss" scoped></style>
