<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on">
        <slot></slot>
      </div>
    </template>
    <v-date-picker
      no-title
      color="black"
      ref="picker"
      v-model="date"
      :active-picker.sync="activePicker"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01"
      locale="th"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: ['value'],
  data: () => ({
    activePicker: null,
    date: null,
    menu: false
  }),
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    }
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
      this.$emit('input', date)
    }
  }
}
</script>
